import React from "react";
import PropTypes from "prop-types";

const PageSizeOptions = ({ pageSize, onChange }) => {
  const pageSizes = [2, 5, 10, 25, 50, 100];
  return (
    <div className="form-inline">
      <label className="mr-2">Display</label>
      <select
        className="form-control form-control-sm"
        value={pageSize}
        onChange={onChange}
      >
        {pageSizes.map((size) => (
          <option value={size}>{size}</option>
        ))}
      </select>
      <label className="ml-2">Entries</label>
    </div>
  );
};

PageSizeOptions.propTypes = {};

export default PageSizeOptions;
