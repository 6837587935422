import React from "react";
import Form from "../../comman/form";
import ContactAdmin from "./ContactAdmin";
import ForgotUserName from "./ForgotUserName";
import http from "../../services/HttpService";
import config from "../../config.json";
import { Link } from "react-router-dom";
import Login from "../Login";
import LoginHeader from "./LoginHeader";

class ForgotValidID extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      forgotUser: false,
      forgotMain: true,
      thankYou: false,
      contactAdmin: false,
      clientDetails: "",
      vsScroll: 0,
    };
  }

  handleUserName = () => {
    this.setState({ forgotUser: true, forgotMain: false });
  };

  handleBackUser = () => {
    this.setState({ forgotMain: true, forgotUser: false, vsScroll: 0 });
  };

  handleContactAdmin = () => {
    this.setState({ contactAdmin: true, forgotMain: false });
  };

  handleContactBack = () => {
    this.setState({ forgotMain: true, contactAdmin: false });
  };

  handleScroll = (num) => {
    this.setState({ vsScroll: num });
  };

  render() {
    const {
      forgotMain,
      thankYou,
      contactAdmin,
      forgotUser,
      clientDetails,
      vsScroll,
    } = this.state;
    // const hasVScroll = document.body.clientHeight;
    // const vsScroll = document.body.scrollHeight - document.body.clientHeight;
    // console.log(hasVScroll);
    // console.log(vsScroll);
    return (
      <React.Fragment>
        <div className="col-md-12 h-100">
          <div className="row align-items-center" style={{ height: "calc(100vh - 276px)" }}>
            <div className="col-md-12 d-flex justify-content-center">
              <div
                className="nomar card shadow-none border w-50"
                style={{ backgroundColor: "#fafafa", marginTop: "0px" }}
              >
                <div className="pp-1 card-body ">
                  <div className="text-center text-white">
                    <h2 className="text-black">FORGOT VALID ID?</h2>
                  </div>
                  {forgotMain && (
                    <div className="row p-4">
                      <div className="form-group col-md-12">
                        <Link to="/Login" className="btn btn-link pl-0">
                          <i
                            className="fa fa-long-arrow-left"
                            aria-hidden="true"
                          ></i>
                          Back
                        </Link>
                      </div>
                      <div className="col-md-12 form-group">
                        <button
                          className="btn btn-danger w-100"
                          onClick={this.handleUserName}
                        >
                          I Forgot My Valid ID
                        </button>
                      </div>
                    </div>
                  )}

                  {forgotUser && (
                    <ForgotUserName
                      handleBackUser={this.handleBackUser}
                      forgotType="Valid ID"
                    ></ForgotUserName>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="col-md-12 d-flex justify-content-center">
              <div className="cust-card shadow-none border w-50 ">
                <div className="card-body p-1 text-center" >
                  <p>
                    Customer Support :{" "}
                    <a href="mailto:technicalsupport@workfinity.com">
                      Support1@workfinity.com
                    </a>
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotValidID;
