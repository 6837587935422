import React, { useEffect, useState } from "react";
import WorkflowElement from "../WorkflowElement";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLibraryFormById, loadLibraryFormById } from "../../store/forms";

const ViewFormLink = () => {

    const [wfTask, setWfTask] = useState(null);
    const location = useLocation();
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const libraryFormByIdSelector=useSelector(getLibraryFormById)

    useEffect(() => {
        const pathname = location.pathname.split("/");
        const frmId = pathname[pathname.length - 1];
        dispatch(loadLibraryFormById(frmId));
    }, []);

    useEffect(()=>{
        if(libraryFormByIdSelector){
            setWfTask({formId:libraryFormByIdSelector.formId,ciid:libraryFormByIdSelector.ciid});
        }
    },[libraryFormByIdSelector])

    const handleNextTask = () => {

    }

    const handleBack=()=>{
        window.open("about:blank","_self");
        window.close();
    }

    return <>
        <div className="col-md-12 text-right mb-2">
            <button className="btn btn-outline-default btn-sm btn-min-width" onClick={handleBack}>Close</button>
        </div>
        <div className="col-md-12">
            <div className="card card-body p-3 border shadow-none">
                {wfTask?.formId > 0 && <WorkflowElement
                    wfTask={wfTask}
                    handleNextTask={handleNextTask}
                    viewType={true}
                    appSummary={false}
                    codeVerified={false}
                    wfTaskType={"viewForm"}
                />}
            </div>
        </div>
    </>
}

export default ViewFormLink;