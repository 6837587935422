import React, { useDebugValue, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import ToolsPopup from "./ToolsPopup";
import SubRecCandidates from "../../ReviewTools/SubRecCandidates";
import Tools from "../../services/toolsJson.json"
import { getWfPhasesBgColor, getFieldData, getWfPhasesColor } from "../../services/appService";
import {
  loadCandidatesByFilter, getFilterCandidates,
  SendReminder, getReminderStatus,
  loadCandidateApps, getCandidateApps
} from "../../app/store/candidate";
import { getComponentUserStatus, getToolsCount, loadComponentUserStatus, loadToolsCount } from "../../app/store/tools";
import { getBprmForm, loadBprmForm, getStateCountyCity } from "../../app/store/bprmForm";
import { getApplication, loadApplication, getApplicationData } from "../../app/store/application";
import { getAlert } from "../../services/appAlerts";
import CandidateInfo from "../../app/components/CandidateInfo";
import ApplicationInfo from "../../app/components/ApplicationInfo";
import PartnerInfo from "../../app/components/PartnerInfo";
import ClientAgencyInfo from "../../app/components/ClientAgencyInfo";
import ProjectInfo from "../../app/components/ProjectInfo";
import ToolList from "../../app/components/ToolList";
import TaskInfo from "../../app/components/TaskInfo";
import WorkflowElement from "../../app/components/WorkflowElement";
import JourneySteps from "../../app/components/JourneySteps";
import Document from "../PortalForms/Document";
import TimeLogManagement from "../../ReviewTools/TimeLogManagement";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";
import { getDocuments, loadDocuments } from "../../app/store/documents";
import MessageWithApplicant from "../PortalForms/MessageWithApplicant";
import InternalReview from "../PortalForms/InternalReview";
import AchInformation from "../../ReviewTools/AchInformation";
import InsuranseInformation from "../../ReviewTools/InsuranseInformation";
import { getCurrentUser } from "../../app/store/auth";

export default function ReviewTask() {

  const newRoute = [
    { name: "Onboarding and Contracts", url: "" },
    { name: "Candidates", url: "/ListCondidate/application" },
    { name: "Work In Progress", url: "/CustomerApps" }
  ];

  const [selectedTask, setSelectedTask] = useState("");
  const [candidateObj, setCandidateObj] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [appType, setAppType] = useState("");
  const [type, setType] = useState("");
  const [module, setModule] = useState("");
  const [currentTask, setCurrentTask] = useState("");
  const [selectedApp, setSelectedApp] = useState("");
  const [parentApp, setParentApp] = useState("");

  const [appDocuments, setAppDocuments] = useState([]);
  const [componentList, setComponentList] = useState([]);

  const [reAssignShowHide, setReAssignShowHide] = useState(false);
  const [journeyShowHide, setJourneyShowHide] = useState(true);
  const [toolShowHide, setToolShowHide] = useState(false);
  const [sectionShowHide, setSectionShowHide] = useState(false);
  const [viewBprmFormShowHide, setViewBprmFormShowHide] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [toolObj, setToolObj] = useState("");
  const [uploadType, setUploadType] = useState("");
  const [apiCallCount, setApiCallCount] = useState(0);

  const candidatesSelector = useSelector(getFilterCandidates);
  const candidateAppsSelector = useSelector(getCandidateApps);
  const documentsSelector = useSelector(getDocuments);
  const toolsCountSelector = useSelector(getToolsCount);
  const componentUserStatusSelector = useSelector(getComponentUserStatus);


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currentUser = getCurrentUser();

  useEffect(() => {
    const state = location?.state;
    if (state?.item && state?.task)
      dispatch(loadCandidateApps(state?.item.id, "admin", 1, state?.task?.ciid));
    if (state?.appType)
      setAppType(state?.appType);
    if (state?.item)
      setSelectedItem(state?.item);
    if (state?.module)
      setModule(state?.module);
    if (state?.parentApplication)
      setParentApp(state?.parentApplication);
    if (state?.type)
      setType(state?.type);
    if (state?.task)
      setCurrentTask(state?.task);

    if (state?.module === "Subcontractor")
      newRoute[1] = { name: "Subcontractors", url: "/ListSubcontractor/application" };
    if (state?.module === "Recruiter")
      newRoute[1] = { name: "Recruiters", url: "/Recruiter/application" };

    dispatch(setCurrentRoute(newRoute));
    dispatch(setCurrentPageTitle("Review Workflow Task"));
  }, []);

  useEffect(() => {
    const state = location?.state;
    if (state?.appType !== "partner" && state?.item.id > 0)
      getCandidateDetails(state?.item.id);

    if (state?.task?.wiid > 0) {
      setSelectedTask(state.task.wiid);
      dispatch(loadDocuments(state.task.ciid));
      dispatch(loadToolsCount(state.task.ciid));
      dispatch(loadComponentUserStatus(currentUser.id, state.task.ciid));
    }
  }, [])

  const getCandidateDetails = async (candidateId) => {
    const frmBody = new FormData();
    frmBody.append("id", candidateId);
    frmBody.append("name", "");
    frmBody.append("email", "");
    frmBody.append("entity", "");
    frmBody.append("type", "candidate");
    frmBody.append("filterType", "single");
    dispatch(loadCandidatesByFilter(frmBody));
  };

  useEffect(() => {
    if (candidatesSelector.length > 0)
      setCandidateObj(candidatesSelector[0]);
    if (documentsSelector.length > 0)
      setAppDocuments(documentsSelector);
  }, [candidatesSelector, documentsSelector]);

  useEffect(() => {
    //console.log(componentUserStatusSelector);
    if (toolsCountSelector.length > 0) {
      // const newComp = Tools.filter(x => x.display == true).map((x) => {
      //   const count = toolsCountSelector.filter((t) => t.key == x.id);
      //   if (count.length > 0) {
      //     x.count = count[0].value;
      //   }
      //   return x;
      // });
      // setComponentList(newComp);
      setComponentList(Tools.filter(x => x.display === true));
    }
  }, [toolsCountSelector])

  const handleOnSelect = () => {
    setCurrentPage(1);
    //el.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const handleJorneySteps = (wiid) => {
    setSelectedTask(wiid);
  };

  useEffect(() => {
    if (candidateAppsSelector.length > 0) {
      const latestApp = candidateAppsSelector[0];
      setSelectedApp(latestApp);
      let wfTasks = latestApp.workflowTasks.filter(
        (x) => x.itemType === 1 && x.wiid > 0 && x.taskDoneStatus === 2 //&& x.formType === "WF"
      ).sort((a, b) => a.orderNumber - b.orderNumber);

      if (wfTasks.length === 0) {
        wfTasks = latestApp.workflowTasks.filter(
          (x) => x.itemType === 1 && x.wiid > 0 && x.taskDoneStatus === 4 //&& x.formType === "WF"
        ).sort((a, b) => b.orderNumber - a.orderNumber);
      }
      const latestTask = wfTasks[0];
      if ((currentTask?.id !== latestTask?.id) || apiCallCount > 2) {
        setSelectedTask(latestTask.wiid);
        setCurrentTask(latestTask);
      }
      else {
        if (apiCallCount > 0 && apiCallCount <= 2) {
          setApiCallCount(apiCallCount + 1);
          dispatch(loadCandidateApps(selectedItem.id, "admin", 1, currentTask.ciid));
        }
      }
    }
  }, [candidateAppsSelector])

  const handleToolShowHide = (r, type) => {
    setUploadType(type);
    setToolShowHide(true);
    const comp = Tools.filter(x => x.id === r);
    if (comp.length > 0)
      setToolObj(comp[0]);
  };

  const handleCompShowHide = (id) => {
    const comp = Tools.filter(x => x.id === id);
    if (comp.length > 0)
      setToolObj(comp[0]);
  }

  const handleToolObjShowHide = (r, p) => {
    const comp = Tools.filter(x => x.id === r);
    if (comp.length > 0) {
      const allTasks = app.workflowTasks.filter(x => x.wfPhase === p);
      if (allTasks.length > 0)
        setSelectedTask(0);

      setToolObj(comp[0]);
    }

  }

  const handleNextTask = () => {
    setTimeout(() => {
      setApiCallCount(1);
      dispatch(loadCandidateApps(selectedItem.id, "admin", 1, currentTask.ciid));
    }, 3000);
  };

  const handleRefreshJourney = () => {
    dispatch(loadCandidateApps(selectedItem.id, "admin", 1, currentTask.ciid));
  }

  const handleReAssignSubmit = () => {
    setReAssignShowHide(false);
    setApiCallCount(3);
    dispatch(loadCandidateApps(selectedItem.id, "admin", 1, currentTask.ciid));
  };

  const handleUpdateAgencyProjectInfo = () => {
    getCandidateDetails(candidateObj.id);
  }

  const handleJourneyShowHide = () => {
    setJourneyShowHide(!journeyShowHide);
  };

  const handleShowHideSection = (status) => {
    setSectionShowHide(status);
  }

  const handleToolHide = () => {
    setToolShowHide(false)
  }

  const handleToolRefresh = () => {
    const state = location?.state;
    if (state.task?.ciid > 0) {
      dispatch(loadToolsCount(state.task.ciid));
      dispatch(loadComponentUserStatus(currentUser.id, state.task.ciid));
    }
  }

  const getPhaseColor = (phase) => {
    const totalPhases = app?.allWfPhase.filter(x => x.wfPhase !== ""
      && x.wfPhase !== "Application"
      && x.wfPhase !== "Rejected");

    var keyCount = 0;

    let phaseBg = "btn-wf-default"
    totalPhases.map((item) => {
      let keyNum = 5;
      if (item.wfPhase === "Review" || item.wfPhase === "Onboarding")
        keyNum = 2;
      if (item.wfPhase === "Approved")
        keyNum = 3
      if (item.wfPhase === "Denied")
        keyNum = 4;
      if (keyNum === 5) {
        keyNum += keyCount;
        keyCount += 1;
      }

      if (item.wfPhase === phase) {
        phaseBg = `btn-wf${keyNum}-default`
      }

    })

    return phaseBg;
  }

  let procType = type;
  let item = "";
  let app = "";
  let wfTask = "";
  let entType = "";

  if (selectedItem !== "") {
    item = selectedItem;
    entType = getFieldData(
      selectedItem?.performerData,
      "candidateonboardingform",
      "CandidateEntity"
    );
  }

  if (selectedApp !== "")
    app = selectedApp;

  wfTask = currentTask;
  if (selectedTask > 0 && currentTask.wiid !== selectedTask)
    wfTask = app.workflowTasks.filter((x) => x.wiid === selectedTask)[0];

  if (selectedTask === 0) wfTask = "";

  return (
    <React.Fragment>
      <div className="col-md-12">
        <div class="d-flex flex-row-reverse mb-2">
          <button
            className="btn btn-sm btn-outline-default btn-min-width"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-arrow-left mr-2"></i>Back
          </button>
        </div>
        {item !== "" && (
          <div className="row">
            {appType === "partner" && (
              <div className="col-md-12">
                <PartnerInfo partner={item} type={type} appType={appType} />
              </div>
            )}

            {appType !== "partner" && (
              <div className="col-md-12">
                <CandidateInfo candidate={item} />
              </div>
            )}

            <div className="col-md-12 form-group">
              {app !== "" && <ApplicationInfo
                candidate={item}
                procType={procType}
                app={app}
                currentTask={currentTask} />}
            </div>
            <div className="col-md-12 text-center form-group">
              <button className="btn btn-sm btn-secondary bg-yellow border-yellow w-50">
                Review
              </button>
            </div>
            <div className="col-md-12 form-group">
              <button className="btn btn-sm btn-link pl-0" onClick={handleToolRefresh}>Refresh</button>
              <div className="card shadow-none border mb-0 w-100">
                <div className="card-body p-3">
                  <ToolList key={1}
                    compList={componentList.filter((x) => x.group === "Message" || x.group === "Interface" || x.group === "")}
                    handleToolShowHide={handleToolShowHide}
                  />

                  {toolShowHide &&
                    <> <div className="text-right">
                      <button className="btn btn-sm btn-outline-danger" onClick={handleToolHide}>
                        <i className="fa fa-times mr-2" aria-hidden="true"></i>Close
                      </button>
                    </div>
                      <ToolsPopup
                        toolShowHide={toolShowHide}
                        handleToolShowHide={handleToolShowHide}
                        toolObj={toolObj}
                        item={item}
                        task={wfTask}
                        app={app}
                        candidateObj={candidateObj}
                        uploadType={uploadType}
                        setViewBprmFormShowHide={setViewBprmFormShowHide}
                      ></ToolsPopup></>
                  }
                </div>
              </div>
            </div>
            {appType !== "partner" && !item?.partnerId && (
              <div className="col-md-7">
                <ClientAgencyInfo
                  candidateObj={candidateObj}
                  item={item}
                  appDocuments={appDocuments}
                  handleToolShowHide={handleToolShowHide}
                  handleUpdateAgencyProjectInfo={handleUpdateAgencyProjectInfo}
                />
              </div>
            )}
            {appType !== "partner" && !item?.partnerId && (
              <div className="col-md-5">
                <ProjectInfo
                  candidateObj={candidateObj}
                  item={item}
                  appDocuments={appDocuments}
                  handleToolShowHide={handleToolShowHide}
                  handleUpdateAgencyProjectInfo={handleUpdateAgencyProjectInfo}
                />
              </div>
            )}
          </div>
        )}
        {!toolShowHide &&
          <div className="row">

            <div className="col-md-12">
              {app !== "" && wfTask && <TaskInfo
                wfTask={wfTask}
                app={app}
                handleReAssignSubmit={handleReAssignSubmit}
                handleShowHideSection={handleShowHideSection}
              />
              }
            </div>
            {!sectionShowHide &&
              <div className="col-md-12 mb-9" style={{ overflow: "auto" }}>
                <button
                  className="btn btn-sm btn-link pl-0"
                  onClick={handleJourneyShowHide}
                >
                  {journeyShowHide ? "Hide" : "Show"} Journey Step
                </button>

                <table className="table table-bordered align-items-center">
                  <thead className="">
                    <tr>
                      {journeyShowHide && (
                        <th>Workflow Phases / Journey Steps</th>
                      )}
                      <th>Review
                        <button
                          className="btn btn-sm btn-link p-0 pl-2 mt--1"
                          onClick={handleRefreshJourney}
                        >
                          <i className="fa fa-refresh" aria-hidden="true"></i>
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {app !== "" &&
                      <tr>
                        {journeyShowHide && (
                          <td
                            className="bg-white pl-2 pr-4 pb-5"
                            style={{ minWidth: "30%" }}
                          >
                            <JourneySteps custApp={app}
                              handleJorneySteps={handleJorneySteps}
                              handleToolShowHide={handleToolShowHide}
                              handleToolObjShowHide={handleToolObjShowHide}
                              candidateObj={candidateObj}
                              compList={componentList.filter((x) => x.group === "Message" || x.group === "Interface" || x.group === "")}
                            />
                          </td>
                        )}
                        <td
                          className="p-3"
                          style={{ width: "80%", whiteSpace: "pre-wrap" }}
                        >
                          <div className="row">
                            <div className="col-md-3 form-group">
                              <button className={`${getPhaseColor(wfTask?.wfPhase)} btn-sm btn`}>
                                <i
                                  className="fa fa-arrow-right mr-2"
                                  aria-hidden="true"
                                ></i>
                                {selectedTask === 0 && toolObj?.name}
                                {wfTask?.itemName}
                              </button>
                              {/* <button className="btn btn-sm btn-outline-default">
                        <span> <i
                              className="fa fa-user mr-1"
                              aria-hidden="true"
                            ></i> Assigned To : {wfTask?.performerName} ({wfTask?.performerRole})</span>
                        </button> */}

                            </div>
                            <div className="col-md-9">
                              <table>
                                <thead>
                                  <tr>
                                    <td><span className="h5">
                                      <i
                                        className="fa fa-user mr-1"
                                        aria-hidden="true"
                                      ></i> Assigned To : {wfTask?.performerName} ({wfTask?.performerRole})</span></td>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12 form-group">
                                  {selectedTask === 0 && toolObj?.id === 1 && <InternalReview
                                    workflowTask={currentTask}
                                  //handleAppCommentCount={this.handleAppCommentCount}
                                  />}
                                  {wfTask?.itemName === "Contract Documents" && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-outline-default mb-3"
                                        onClick={() => handleCompShowHide(39)}
                                      >
                                        <i className="fa fa-file-text-o mr-1"></i>Contract Documents
                                      </button>
                                      {entType !== "C2C Basis" &&
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-outline-default mb-3"
                                          onClick={() => handleCompShowHide(42)
                                          }
                                        >
                                          <i className="fa fa-credit-card mr-1"></i>ACH Information
                                        </button>}
                                      {entType !== "C2C Basis" && entType !== "W2 Basis" && entType !== "1099 Basis" &&
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-outline-default mb-3"
                                          onClick={() => handleCompShowHide(43)}
                                        >
                                          <i className="fa fa-shield mr-1"></i>Insurance Information
                                        </button>}

                                      {toolObj?.id === 39 && <Document
                                        documents={appDocuments}
                                        workflowTask={currentTask}
                                        //handleAppDocCount={this.handleAppDocCount}
                                        uploadType={uploadType}
                                        setViewBprmFormShowHide={setViewBprmFormShowHide}
                                        app={app}
                                        item={item}
                                      />}

                                      {toolObj?.id === 42 && <AchInformation
                                        workflowTask={currentTask}
                                        app={app}
                                        candidateObj={candidateObj}
                                        item={item}
                                        setViewBprmFormShowHide={setViewBprmFormShowHide}
                                      />}

                                      {toolObj?.id === 43 && <InsuranseInformation
                                        workflowTask={currentTask}
                                        app={app}
                                        candidateObj={candidateObj}
                                        item={item}
                                        setViewBprmFormShowHide={setViewBprmFormShowHide}
                                      />}

                                    </>
                                  )}

                                  {appType !== "partner" &&
                                    (wfTask?.wfPhase === "Time Log" || wfTask?.wfPhase === "Invoice") &&
                                    !item?.partnerId && (
                                      <>
                                        <TimeLogManagement
                                          workflowTask={currentTask}
                                          app={app}
                                          candidateObj={candidateObj}
                                          item={item}
                                          pType={wfTask?.wfPhase === "Time Log" ? "timelog" : "invoice"}
                                        />
                                      </>
                                    )}

                                  {appType === "partner" &&
                                    (wfTask?.wfPhase === "Time Log" || wfTask?.wfPhase === "Invoice") &&
                                    item?.partnerId > 0 && (
                                      <SubRecCandidates
                                        workflowTask={wfTask}
                                        app={app}
                                        candidateObj={candidateObj}
                                        item={item}
                                        pType={wfTask?.wfPhase === "Time Log" ? "timelog" : "invoice"}
                                        module={module}
                                      />
                                    )}
                                </div>
                              </div>
                              {!viewBprmFormShowHide &&
                                <WorkflowElement wfTask={wfTask} handleNextTask={handleNextTask} actionType={"workflow"} />}
                            </div>
                          </div>
                        </td>
                      </tr>}
                  </tbody>
                </table>
              </div>}
          </div>}
      </div>

    </React.Fragment>
  );
}
