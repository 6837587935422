import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import WorkflowMapPopup from "../../components/WorkflowMapPopup";
import { SendReminder, getReminderStatus } from "../store/candidate";
import { getAlert } from "../../services/appAlerts";

export default function ApplicationInfo(props) {
    const { procType, app, candidate: item, currentTask } = props;

    const [workflowShowHide, setWorkflowShowHide] = useState(false);
    const [remainderMsgShowHide, setRemainderMsgShowHide] = useState(false);
    
    const remiderStatusSelector = useSelector(getReminderStatus);

    const dispatch = useDispatch();

    useEffect(() => {
        if (remiderStatusSelector === "success") {
            getAlert("", "Reminder Sent Successfully", "success");
        }
    }, [remiderStatusSelector]);

    const handleViewApplication = () => {
        // to={{
        //     pathname: "/ApplicationSummary",
        //     state: { task: app, viewType: "view" },
        // }}
    }

    const handleWorkflowModalShowHide = (obj) => {
        setWorkflowShowHide(!workflowShowHide);
    };

    const handleRemainder = async (id) => {
        dispatch(SendReminder(id));
    };

    const handleRemainderMessage = () => {
        setRemainderMsgShowHide(!remainderMsgShowHide);
    };

    return (
        <React.Fragment>
            <div className="table-responsive border border-top-0">
                <table className="table align-items-center table-flush table-hover table-striped bg-white mb-0">
                    {/* <thead className="thead-light-green">
                        <tr>
                            <th> {procType === 2 ? "Subprocess " : "App "}ID</th>
                            <th>Date & Time</th>
                            <th>{procType === 2 ? "Subprocess" : "Application"}</th>

                            <th>
                                {procType === 2 ? "Subprocess" : "Application"} Status
                            </th>
                            <th>Final Decision</th>
                        </tr>
                    </thead> */}
                    <tbody>
                        <tr>
                            <td>
                              App ID : {app.ciid}
                                {/* <br />
                                <button
                                    className="btn btn-sm btn-link p-0 text-underline"
                                    onClick={() => handleWorkflowModalShowHide(app)}
                                >
                                    <span>
                                        <img
                                            src="/assets/Images/WFAssociationIcons/process.gif"
                                            style={{
                                                height: "18px",
                                                marginRight: "5px",
                                                marginTop: "-3px",
                                            }}
                                        />
                                        WF ID: {app.workflowId}
                                    </span>
                                </button> */}
                            </td>
                            <td>
                            <button
                                    onClick={handleViewApplication}
                                    className="btn btn-sm btn-link p-0"
                                >
                                    Onboarding Form
                                </button>
                                <br/>
                                <Moment format="MMM Do YYYY">
                                    {app.addedDateTime}
                                </Moment>
                                <br />
                                <Moment format="h:mm a">{app.addedDateTime}</Moment>
                            </td>
                            <td>
                                {app.workflowTasks.filter(
                                    (x) =>
                                        x.itemType === 1 &&
                                        x.formType === "WF" &&
                                        x.taskDoneStatus > 0
                                ).length === 0 ? (
                                    <>
                                        <button className="btn btn-sm btn-link p-0">
                                            Application Incomplete
                                        </button>
                                        <br />
                                        <button
                                            className="btn btn-sm btn-link p-0 text-underline"
                                            onClick={() => handleRemainder(item.regId)}
                                        >
                                            Send Reminder
                                        </button>
                                        <button
                                            className="btn btn-sm btn-link p-0 text-underline"
                                            title="View message"
                                            onClick={handleRemainderMessage}
                                        >
                                            <span className="badge badge-warning rounded-0 text-red">
                                                <i
                                                    class="fa fa-envelope mr-1"
                                                    aria-hidden="true"
                                                ></i>
                                                {item.remainderCount === undefined
                                                    ? 0
                                                    : item.remainderCount}
                                            </span>
                                        </button>
                                    </>
                                ) : (
                                    <span>
                                        <i
                                            className="fa fa-arrow-right mr-1"
                                            aria-hidden="true"
                                        ></i> Review {app.status && " Completed"}
                                    </span>
                                )}
                            </td>
                            <td>
                                {app.status === 1 && (
                                    <button className="btn btn-sm btn-link p-0">
                                        Approved
                                    </button>
                                )}
                                {app.status === 0 && (
                                    <button className="btn btn-sm btn-link p-0">
                                        Denied
                                    </button>
                                )}
                            </td>
                            <td width="60%"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <WorkflowMapPopup
                workflowShowHide={workflowShowHide}
                handleWorkflowModalShowHide={handleWorkflowModalShowHide}
                selectedObj={currentTask}
            ></WorkflowMapPopup>
        </React.Fragment>
    )
}