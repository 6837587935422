import React, { Component } from 'react';
import {Pie} from 'react-chartjs-2';
import color from "rcolor";

export default class PieChart extends Component {
  getRandomColor(labels) {
    var colors = [];
      for (var i = 0; i < labels.length; i++) {
        colors.push(color());
      }
      return colors;
  }

  render() {
    const { graphData } = this.props;

    const data = {
      labels: graphData.label,
      datasets: [
        {
          data: graphData.data,
          backgroundColor: graphData.label != undefined ? this.getRandomColor(graphData.label) : [],
          hoverBackgroundColor: graphData.label != undefined ? this.getRandomColor(graphData.label) : [],
        },
      ],
    };

    return (
      <div className="chart">
        <Pie data={data} />
      </div>
    );
  }
};
