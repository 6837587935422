import React from "react";

const Input = ({ name, label, error, req, size, ...rest }) => {
  return (
    <div className={`${label ? "form-group" : ""} ${req ? " required" : ""}`}>
      {label &&
        <label className="form-control-label" htmlFor={name}>
          {label}
        </label>}
      <input
        {...rest}
        id={name}
        name={name}
        className={`form-control ${size === "s" ? "form-control-sm" : ""}`}
        placeholder={label}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Input;
