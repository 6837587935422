import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Table from "../comman/table";

class WorkflowRolesTable extends Component {
  
  columns = [
    { path: "roleid", label: "ID" },
    { path: "roleType", label: "Role Type" },
    { path: "rolename", label: "Role Name" },
    {
      path: "resourceAssigned",
      label: (
        <div>
          Assigned
          <br />
          Resource
        </div>
      ),
    },
    // { path: "description", label: "Description" },
    {
      path: "dateTimeChanged",
      label: "Date & Time",
      content: (obj) => (
        <div>
          <Moment format="MMM Do YYYY">{obj.dateTimeChanged}</Moment>
          <br />
          <Moment format="h:mm a">{obj.dateTimeChanged}</Moment>
        </div>
      ),
    },
    {
      path: "totalEntryRecords",
      label: (
        <div>
          Maximum
          <br />
          Users
        </div>
      ),
      content: (obj) => (
        <span>
          {obj.totalEntryRecords === 0 ? "Unrestricted" : obj.totalEntryRecords}
        </span>
      ),
    },
    {
      path: "activeUsers",
      label: (
        <div>
          Active
          <br />
          Users
        </div>
      ),
      content: (obj) => (
        <span
          className="badge badge-success w-100 rounded-0"
          style={{ fontWeight: "bold", fontSize: "12px" }}
        >
          {obj.activeUsers}
        </span>
      ),
    },
    // { path: "totalEntries", label: "Total Entries" },
    {
      path: "",
      label: "Action",
      content: (obj) => (
        <button
          // to={{
          //   pathname: "ManageWorkflowPerformer",
          //   state: { obj },
          // }}
          onClick={()=>this.props.handleManagePerformer(obj)}
          className="btn btn-link btn-sm p-0 text-underline"
          title="Manage Users"
        >
          Manage Users
        </button>
      ),
    },
    {
      path: "",
      label: "Permission",
      content: (obj) => (
        <button
          className="btn btn-link btn-sm p-0 text-underline"
          onClick={() => this.props.handlePermissionShowHide(obj)}
        >
          Add/Edit
        </button>
      ),
    },
  ];

  render() {
    const { data } = this.props;
    return <Table columns={this.columns} data={data}></Table>;
  }
}

export default WorkflowRolesTable;
