import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "purchaseOrders",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        poDoc: null,
        poSendStatus:false,
        poDeleteStatus:false,
        poAddedStatus:false,
        poUploadedStatus:false,
        poOrdersPdf:null,
    },
    reducers: {
        purchaseOrdersReceived: (purchaseOrders, action) => {
            purchaseOrders.list = action.payload;
            purchaseOrders.poSendStatus=false;
            purchaseOrders.poDeleteStatus=false;
            purchaseOrders.poAddedStatus=false;
            purchaseOrders.poUploadedStatus=false;
            purchaseOrders.poDoc=null;
            purchaseOrders.poOrdersPdf=null;
        },
        purchaseOrderDocReceived: (purchaseOrders, action) => {
            purchaseOrders.poDoc = action.payload;
        },
        purchaseOrderSent: (purchaseOrders, action) => {
            purchaseOrders.poSendStatus = action.payload;
        },
        purchaseOrderDeleted: (purchaseOrders, action) => {
            purchaseOrders.poDeleteStatus = action.payload;
        },
        purchaseOrderAdded: (purchaseOrders, action) => {
            purchaseOrders.poAddedStatus = action.payload;
        },
        purchaseOrderUploaded: (purchaseOrders, action) => {
            purchaseOrders.poUploadedStatus = action.payload;
        },
        generatedPoOrderPdfReceived: (partners, action) => {
            partners.poOrdersPdf = action.payload;
        },
    }
});

export const { purchaseOrdersReceived, 
    purchaseOrderDocReceived,purchaseOrderSent,purchaseOrderDeleted,
    purchaseOrderAdded,generatedPoOrderPdfReceived,purchaseOrderUploaded } = slice.actions;
export default slice.reducer;

export const loadPurchaseOrders = (id) => apiCallBegan({
    url: config.GetPurchaseOrders + id,
    onSuccess: purchaseOrdersReceived.type
});

export const loadPurchaseOrderDoc = (id, type) => apiCallBegan({
    url: config.GetPoOrderDocument + id + "&type=" + type,
    responseType: "blob",
    onSuccess: purchaseOrderDocReceived.type
});

export const sendPurchaseOrder = (id) => apiCallBegan({
    url: config.SendPoOrder + id,
    onSuccess: purchaseOrderSent.type
});

export const addNewPurchaseOrder = (data) => apiCallBegan({
    url: config.AddNewPurchaseOrder,
    method:"post",
    data:data,
    onSuccess: purchaseOrderAdded.type
});

export const uploadNewPurchaseOrder = (data) => apiCallBegan({
    url: config.UploadPurchaseOrder,
    method:"post",
    data:data,
    onSuccess: purchaseOrderUploaded.type
});

export const deletePurchaseOrder = (id) => apiCallBegan({
    url: config.DeletePoOrder + id,
    method:"delete",
    onSuccess: purchaseOrderDeleted.type
});

export const printGeneratedPoOrders = (data) => apiCallBegan({
    url: config.PrintTemplate,
    method: "post",
    data: data,
    responseType: "blob",
    onSuccess: generatedPoOrderPdfReceived.type
});

export const getPurchaseOrders = createSelector(
    state => state.entities.purchaseOrders,
    purchaseOrders => purchaseOrders.list
);

export const getPurchaseOrderDoc = createSelector(
    state => state.entities.purchaseOrders,
    purchaseOrders => purchaseOrders.poDoc
);

export const getPOSendStatus = createSelector(
    state => state.entities.purchaseOrders,
    purchaseOrders => purchaseOrders.poSendStatus
);

export const getPODeleteStatus = createSelector(
    state => state.entities.purchaseOrders,
    purchaseOrders => purchaseOrders.poDeleteStatus
);

export const getPOAddedStatus = createSelector(
    state => state.entities.purchaseOrders,
    purchaseOrders => purchaseOrders.poAddedStatus
);

export const getPOUploadedStatus = createSelector(
    state => state.entities.purchaseOrders,
    purchaseOrders => purchaseOrders.poUploadedStatus
);

export const getPoOrdersPdf = createSelector(
    state => state.entities.purchaseOrders,   
    purchaseOrders => purchaseOrders.poOrdersPdf
);

