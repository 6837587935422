import React, { Component } from "react";
import { Modal } from "react-bootstrap";

class ReminderAlertMessage extends Component {
  render() {
    const { handleRemainderMessage, remainderMsgShowHide } = this.props;
    return (
      <Modal show={remainderMsgShowHide}>
        <Modal.Header>
          <Modal.Title>Remainder Alert Message</Modal.Title>
          <button className="btn btn-sm" onClick={handleRemainderMessage}>
            <i className="fa fa-times"></i>
          </button>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          <div className="row">
            <div className="col-md-12">
              <p className="bg-white border p-3 rounded">
                You have registered with us but haven't returned to complete
                your application. Please do so by clicking on the link below:
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={handleRemainderMessage}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ReminderAlertMessage;
