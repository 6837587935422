import React, { Component, useEffect } from "react";
import PageHeader from "./PageHeader";
import Form from "../comman/form";
import InfoHeader from "../comman/infoHeader";
import Joi from "joi-browser";
import http from "../services/HttpService";
import config from "../config.json";
import Swal from "sweetalert2";
import useForm from "../app/hooks/useForm";
import useControls from "../app/hooks/useControls";
import { useDispatch, useSelector } from "react-redux";
import { getEmailConfig, getEmailConfigUpdateStatus, loadEmailConfig, updateEmailConfig } from "../app/store/users";
import { getAlert } from "../services/appAlerts";
import { setCurrentPageTitle, setCurrentRoute } from "../app/store/layoutUI";

const EmailSmtpSetup=()=> {
  
 const  route= [{ name: "Communication", url: "" }];

  const schema = {
    smtpHost: Joi.string(),
    smtpPort: Joi.number(),
    userName: Joi.string(),
    pwd: Joi.string(),
    email: Joi.string().email(),
  };

  const emailConfigSelector=useSelector(getEmailConfig);
  const emailConfigUpdateStatusSelector=useSelector(getEmailConfigUpdateStatus);

  const dispatch=useDispatch();

  useEffect(()=>{
    dispatch(setCurrentPageTitle("SMTP Setup"));
    dispatch(setCurrentRoute(route));
  },[])

  useEffect(()=>{
    dispatch(loadEmailConfig());
  },[]);

  useEffect(()=>{
    if(emailConfigSelector) setStateData(emailConfigSelector);
  },[emailConfigSelector]);

  const doSubmit = () => {
    dispatch(updateEmailConfig(stateData));
  };

  useEffect(()=>{
    if(emailConfigUpdateStatusSelector){
      getAlert("", "Saved Successfully", "success");
      dispatch(loadEmailConfig());
    }
  },[emailConfigUpdateStatusSelector])

  const { validate, handleChange, stateData, setStateData,
    stateErrors, setStateErrors, handleSubmit } = useForm(schema, doSubmit);

  const { renderSelect, renderInput, renderTextArea } = useControls(stateData, stateErrors, handleChange, schema);

  return (
    <React.Fragment>
      {/* <PageHeader
        route={this.state.route}
        parentTitle="SMTP Setup"
        headerTitle="SMTP Setup"
      ></PageHeader> */}
      <div className="col-md-12">
        <div className="card card-body shadow-none border p-3">
        <div className="row">
          <div className="col-lg-5">
            {renderInput("smtpHost", "SMTP Host")}
          </div>
          <div className="col-lg-2">
            {renderInput("smtpPort", "Port Number")}
          </div>
          <div className="col-lg-5">
            {renderInput("email", "Email")}
          </div>
          <div className="col-lg-6">
            {renderInput("userName", "User Name")}
          </div>
          <div className="col-lg-6">
            {renderInput("pwd", "Password", "password")}
          </div>
          <div className="col-md-12 text-center">
          <button
          className="btn btn-success"
          disabled={validate()}
          onClick={handleSubmit}
        >
          Save
        </button>
          </div>
        </div>
        </div>
      </div>
    </React.Fragment>
  );

}

export default EmailSmtpSetup;
