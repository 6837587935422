import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { DeleteEmailMessage, SendEmailMessage, getAllEmailHistory, getDeleteEmailStatus, getSentEmailStatus, loadAllEmailHistory } from "../../../app/store/users";
import Moment from "react-moment";
import { getAlert, getConfirmAlert } from "../../../services/appAlerts";
import SendEmail from "./SendEmail";

const MailHistory = (props) => {

    const { showHideMailHistory, handleShowHideMailHistory, 
        selectedObj,handleRefreshData,viewType } = props;


    const [allMailHistory, setAllMailHistory] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState([]);
    const [sendEmailShowHide,setSendEmailShowHide]=useState(false);
    const [selectedMailObj,setSelectedMailObj]=useState(null);

    const allEmailHistorySelector = useSelector(getAllEmailHistory);
    const sentEmailStatusSelector = useSelector(getSentEmailStatus);
    const deleteEmailStatusSelector = useSelector(getDeleteEmailStatus);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedObj?.performerid){
            dispatch(loadAllEmailHistory(selectedObj.performerid));
        } 
    }, [selectedObj]);

    useEffect(() => {
        if (allEmailHistorySelector.length > 0) {
            const dMails = allEmailHistorySelector.filter(x => x.sendStatus !== true);
            const sentMails = allEmailHistorySelector.filter(x => x.sendStatus === true);
            if (viewType === "draft")
                setAllMailHistory(dMails);
            else
                setAllMailHistory(sentMails);
        }
    }, [allEmailHistorySelector]);

    useEffect(()=>{
        if(sentEmailStatusSelector){
            getAlert("","Sent Successfully","success");
            if (selectedObj?.performerid) dispatch(loadAllEmailHistory(selectedObj.performerid));
            handleRefreshData();
        }
    },[sentEmailStatusSelector]);

    useEffect(()=>{
        if(deleteEmailStatusSelector){
            getAlert("","Deleted Successfully","success");
            if (selectedObj?.performerid) dispatch(loadAllEmailHistory(selectedObj.performerid));
            handleRefreshData();
        }
    },[deleteEmailStatusSelector])

    const handleSendEmail=(item)=>{
       dispatch(SendEmailMessage(item.id));
    }

    const handleDeleteEmail=async (item)=>{
        const result=await getConfirmAlert("Draft Email","Are you sure you want to Delete this Draft Email?","warning");
        if (result.isConfirmed)
            dispatch(DeleteEmailMessage(item.id))
    }

    const handleSendEmailShowHide=(item)=>{
        setSendEmailShowHide(!sendEmailShowHide);
        setSelectedMailObj(item);
    }

    const handleUpdateModalShowHide=()=>{
        setSendEmailShowHide(false);
        setSelectedMailObj(null);
        if (selectedObj?.performerid){
            dispatch(loadAllEmailHistory(selectedObj.performerid));
        } 
    }

    return <>
        <Modal show={showHideMailHistory} size="xl">
            <Modal.Header closeButton onHide={()=>handleShowHideMailHistory(null,"")}>
                <Modal.Title>Mail History</Modal.Title>
            </Modal.Header>
            <Modal.Body className="border">
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-bordered">
                            <tr>
                                <td> <label htmlFor="" className="form-control-label mb-0">Name :</label> {selectedObj?.performername} </td>
                                <td><label htmlFor="" className="form-control-label mb-0">Email :</label> {selectedObj?.performeremail}</td>
                            </tr>
                        </table>
                    </div>

                    <div className="col-md-12 mt-2" style={{maxHeight:"400px",overflow:"auto"}}>
                        <table className="table align-items-center table-flush table-striped">
                            <thead className="thead-light">
                                <tr>
                                    <th>S.No.</th>
                                    <th>Added Date Time</th>
                                    <th>Subject</th>
                                    <th>Body</th>
                                    <th>Status</th>
                                    <th>Sent Date Time</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allMailHistory.map(item =>
                                    <tr>
                                        <td>{item.id}</td>
                                        <td>
                                            <Moment format="MMM Do YYYY, h:mm">
                                                {item.addedDateTime}
                                            </Moment>
                                        </td>
                                        <td>{item.subject}</td>
                                        <td>{item.mailBody}</td>
                                        <td>
                                            {item.sendStatus ? <span>Sent</span> : <span>Draft</span>}
                                        </td>
                                        <td>
                                            {item.sendDateTime && <Moment format="MMM Do YYYY, h:mm a">
                                                {item.sendDateTime}
                                            </Moment>}
                                        </td>
                                        <td>
                                            {!item.sendStatus && <>
                                                <button className="btn btn-sm btn-link" 
                                                onClick={() => handleSendEmail(item)}>Send</button>
                                                <button className="btn btn-sm btn-link" 
                                                onClick={()=>handleSendEmailShowHide(item)}>Edit</button>
                                                <button className="btn btn-sm btn-link"
                                                 onClick={()=>handleDeleteEmail(item)}>Delete</button>
                                            </>}

                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-outline-danger" onClick={() => handleShowHideMailHistory(null, "")}>
                    <i class="fa fa-times"></i> Close
                </button>
            </Modal.Footer>
        </Modal>
        {sendEmailShowHide &&
            <SendEmail
                showHide={sendEmailShowHide}
                handleModalShowHide={handleSendEmailShowHide}
                handleUpdateModalShowHide={handleUpdateModalShowHide}
                selectedObj={selectedObj}
                selectedEmail={selectedEmail}
                selectedMailObj={selectedMailObj}
                viewType="edit"
            ></SendEmail>}
    </>
}

export default MailHistory;