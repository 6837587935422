import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import loadjs from "loadjs";
import auth from "../services/authService";
import MenuItem from "../comman/MenuItem";
import http from "../services/HttpService";
import config from "../config.json";
class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: "",
      user: "",
      isSelected: "",
      rolePermission: [],
    };
  }

  async componentDidMount() {
    const user = auth.getCurrentUser();
    //console.log(user);
    if (user === null || user === undefined) window.location = "/Login";
    let currentUser = "";
    if (user.type === "admin")
      currentUser = await auth.getAdminDetails(user.id);
    else currentUser = await auth.getCurrentUserDetails(user.id);
    //console.log(currentUser);
    if (user.type !== "admin") {
      const { data: permission } = await http.get(
        config.GetRolePermission + currentUser.roleId
      );
      //console.log(permission);
      this.setState({ rolePermission: permission });
    }
    this.setState({ user, currentUser });
  }

  handleClick = (e) => {
    this.setState({ isSelected: e.currentTarget.id });
  };

  checkMenuPermission = (mid) => {
    const { rolePermission } = this.state;
    if (!rolePermission) return;
    const user = auth.getCurrentUser();
    if (user.type === "admin") return true;
    const mm = mid.split("_");
    let chk = false;
    if (mm[0] === "m") {
      const mR = rolePermission.filter(
        (x) => x.id === parseInt(mm[1]) && x.isChecked === true
      );
      if (mR.length > 0) {
        chk = true;
      } else {
        rolePermission.filter((x) => x.id === parseInt(mm[1]))
          .map((menu) => {
            const sMenu = menu.menus.filter((x) => x.isChecked === true);
            if (sMenu.length > 0) {
              chk = true;
            } else {
              menu.menus.map((ssmenu) => {
                if (ssmenu?.menus) {
                  const ssMenu = ssmenu.menus.filter(
                    (x) => x.isChecked === true
                  );
                  if (ssMenu.length > 0) {
                    chk = true;
                  }
                }
              });
            }
          });
      }
    }
    if (mm[0] === "sm") {
      rolePermission
        .filter((x) => x.id === parseInt(mm[2]))
        .map((menu) => {
          const sMenu = menu.menus.filter(
            (x) => x.id === parseInt(mm[1]) && x.isChecked === true
          );
          if (sMenu.length > 0) {
            chk = true;
          } else {
            menu.menus.map((smenu) => {
              if (smenu?.menus) {
                const ssMenu = smenu.menus.filter((x) => x.isChecked === true);
                if (ssMenu.length > 0) {
                  chk = true;
                }
              } else {
                if (smenu?.isChecked) {
                  chk = true;
                }
              }
            });
          }
        });
    }
    if (mm[0] === "ssm") {
      rolePermission
        .filter((x) => x.id === parseInt(mm[3]))
        .map((menu) =>
          menu.menus
            .filter((x) => x.id === parseInt(mm[2]))
            .map((ssmenu) => {
              if (ssmenu?.menus) {
                const ssMenu = ssmenu.menus.filter(
                  (x) => x.id === parseInt(mm[1]) && x.isChecked === true
                );
                if (ssMenu.length > 0) {
                  chk = true;
                }
              } else {
                if (ssmenu?.isChecked) chk = true;
              }
            })
        );
    }
    return chk;
  };

  render() {
    const { user, currentUser, isSelected, rolePermission } = this.state;
    let loggedUser = user;
    if (
      loggedUser.type !== "admin" &&
      currentUser.roleType === "Complete Application Ownership"
    ) {
      loggedUser.type = "admin";
    }

    return (
      <nav
        className="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white shadow-none"
        id="sidenav-main"
      >
        <div className="scrollbar-inner">
          {/* <div className="sidenav-header d-flex align-items-center">
            <div className="navbar-brand p-0 ml-auto"><span className="h1 pl-4">Menu</span></div>
            <div className="ml-auto">
              <div
                className="sidenav-toggler d-none d-xl-block"
                data-action="sidenav-unpin"
                data-target="#sidenav-main"
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </div>
          </div> */}
          <div className="navbar-inner" style={{ marginTop: "-18px" }}>
            <div
              className="collapse navbar-collapse"
              id="sidenav-collapse-main"
            >
              <ul className="navbar-nav">
                {this.checkMenuPermission("m_1") && (
                  <React.Fragment>
                    <li className="nav-item d-flex justify-content-between align-items-center" style={{ border: "1px solid #ccc" }}>
                      <h6
                        className="navbar-heading mb-0 btn p-1 rounded-0 text-left text-dark border-right-0"
                        style={{ fontWeight: "bold" }}
                      >
                        SYSTEM ADMINISTRATION
                      </h6>
                      {/* Hamburger */}
                      <div >
                        <div
                          className="sidenav-toggler d-none d-block"
                          data-action="sidenav-unpin"
                          data-target="#sidenav-main"
                        >
                          <div className="sidenav-toggler-inner">
                            <i className="sidenav-toggler-line" />
                            <i className="sidenav-toggler-line" />
                            <i className="sidenav-toggler-line" />
                          </div>
                        </div>
                      </div>
                    </li>
                    {this.checkMenuPermission("sm_1_1") && (
                      <MenuItem
                        isSelected={isSelected === "setupUser" ? true : false}
                        handleClick={this.handleClick}
                        menuId="setupUser"
                        menuTitle="Authorized Users"
                        imageName="fa fa-user"
                        headingVisible={true}
                        subMenus={[
                          {
                            url: "/WorkflowUser",
                            name: "Main Users",
                            isChecked: this.checkMenuPermission("ssm_1_1_1"),
                          },
                        ]}
                      ></MenuItem>
                    )}
                    {this.checkMenuPermission("sm_2_1") && (
                      <MenuItem
                        isSelected={isSelected === "mngPartner" ? true : false}
                        handleClick={this.handleClick}
                        menuId="mngPartner"
                        menuTitle="Manage Entities"
                        imageName="fa fa-building"
                        headingVisible={true}
                        subMenus={[
                          {
                            url: "/ListSubcontractor/partner",
                            name: "Subcontractors",
                            isChecked: this.checkMenuPermission("ssm_1_2_1"),
                          },
                          {
                            url: "/Recruiter/partner",
                            name: "Recruiters",
                            isChecked: this.checkMenuPermission("ssm_2_2_1"),
                          },
                          {
                            url: "/ListCondidate/partner",
                            name: "Candidates",
                            isChecked: this.checkMenuPermission("ssm_3_2_1"),
                          },
                        ]}
                      ></MenuItem>
                    )}

                  </React.Fragment>
                )}
                {this.checkMenuPermission("m_2") && (
                  <>
                    <li className="nav-item">
                      <h6
                        className="navbar-heading mb-0 btn p-1 rounded-0 text-left text-dark border-right-0"
                        style={{ border: "1px solid #ccc", fontWeight: "bold" }}
                      >
                        Onboarding and Contracts
                      </h6>
                    </li>
                    {this.checkMenuPermission("sm_1_2") && (
                      <MenuItem
                        isSelected={
                          isSelected === "listCondidate" ? true : false
                        }
                        handleClick={this.handleClick}
                        menuId="listCondidate"
                        menuTitle="Working Hub"
                        imageName="fa fa-cubes"
                        headingVisible={false}
                        subMenus={[
                          {
                            url: "/ListSubcontractor/application",
                            name: "Subcontractors",
                            isChecked: this.checkMenuPermission("ssm_1_1_2"),
                          },
                          {
                            url: "/Recruiter/application",
                            name: "Recruiters",
                            isChecked: this.checkMenuPermission("ssm_2_2_2"),
                          },
                          {
                            url: "/ListCondidate/application",
                            name: "Candidates",
                            isChecked: this.checkMenuPermission("ssm_3_3_2"),
                          },
                          // {
                          //   url: "/ManageApplication",
                          //   name: "Application",
                          //   isChecked: this.checkMenuPermission("ssm_3_3_3"),
                          // },
                        ]}
                      />
                    )}
                  </>
                )}
                {this.checkMenuPermission("m_3") && (
                  <>
                    <li className="nav-item">
                      <h6
                        className="navbar-heading mb-0 btn p-1 rounded-0 text-left text-dark border-right-0"
                        style={{ border: "1px solid #ccc", fontWeight: "bold" }}
                      >
                        Invoices
                      </h6>
                    </li>
                    {this.checkMenuPermission("sm_1_3") && (
                      <MenuItem
                        isSelected={isSelected === "projectRpt" ? true : false}
                        handleClick={this.handleClick}
                        menuId="projectRpt"
                        menuTitle="Invoices"
                        imageName="fa fa-filter"
                        subMenus={[
                          {
                            url: "/ListCondidate/timelog",
                            name: "Time Log Management",
                            isChecked: this.checkMenuPermission("ssm_1_1_3"),
                          },
                          {
                            url: "/ListCondidate/invoice",
                            name: "Invoices and Payments",
                            isChecked: this.checkMenuPermission("ssm_2_2_3"),
                          },
                        ]}
                      ></MenuItem>
                    )}
                  </>
                )}
                {this.checkMenuPermission("m_4") && (
                  <>
                    <li className="nav-item">
                      <h6
                        className="navbar-heading mb-0 btn p-1 rounded-0 text-left text-dark border-right-0"
                        style={{ border: "1px solid #ccc", fontWeight: "bold" }}
                      >
                        Analytics
                      </h6>
                    </li>
                    {this.checkMenuPermission("sm_1_4") && (
                      <MenuItem
                        isSelected={isSelected === "report" ? true : false}
                        handleClick={this.handleClick}
                        menuId="report"
                        menuTitle="Reports"
                        imageName="fa fa-database"
                        subMenus={[
                          {
                            url: "/Dashboard",
                            name: "Dashboard",
                            isChecked: this.checkMenuPermission("ssm_1_1_4"),
                          },
                          {
                            url: "/ApplicationReport",
                            name: "Entity Data",
                            isChecked: this.checkMenuPermission("ssm_2_2_4"),
                          },

                          {
                            url: "/CustomReport/report",
                            name: "Business Reports",
                            isChecked: this.checkMenuPermission("ssm_3_3_4"),
                          },
                          // {
                          //   url: "/StandardReport",
                          //   name: "Query Based Reports",
                          //   isChecked: this.checkMenuPermission("ssm_4_4_4"),
                          // },
                          {
                            url: "/CustomReport/compliance",
                            name: "Compliance Reports/Docs",
                            isChecked: this.checkMenuPermission("ssm_5_5_4"),
                          },
                        ]}
                      />
                    )}
                  </>
                )}
                {this.checkMenuPermission("m_5") && (
                  <>
                    <li className="nav-item">
                      <h6
                        className="navbar-heading mb-0 btn p-1 rounded-0 text-left text-dark border-right-0"
                        style={{ border: "1px solid #ccc", fontWeight: "bold" }}
                      >
                        SECURITY
                      </h6>
                    </li>
                    {this.checkMenuPermission("sm_1_5") && (
                      <MenuItem
                        isSelected={
                          isSelected === "monitoringLogs" ? true : false
                        }
                        handleClick={this.handleClick}
                        menuId="monitoringLogs"
                        menuTitle="Security"
                        imageName="fa fa-clipboard"
                        headingVisible={false}
                        subMenus={[
                          {
                            url: "/MonitoringLogs",
                            name: "Monitoring Logs",
                            isChecked: this.checkMenuPermission("ssm_1_1_5"),
                          },
                          // {
                          //   url: "/SsoManager",
                          //   name: "Single Sign On",
                          //   isChecked: this.checkMenuPermission("ssm_2_2_5"),
                          // },
                        ]}
                      ></MenuItem>
                    )}
                  </>
                )}
                {this.checkMenuPermission("m_6") && (
                  <>
                    <li className="nav-item">
                      <h6
                        className="navbar-heading mb-0 btn p-1 rounded-0 text-left text-dark border-right-0"
                        style={{ border: "1px solid #ccc", fontWeight: "bold" }}
                      >
                        COMMUNICATIONS
                      </h6>
                    </li>
                    {this.checkMenuPermission("sm_1_6") && (
                      <MenuItem
                        isSelected={isSelected === "emailSetup" ? true : false}
                        handleClick={this.handleClick}
                        menuId="emailSetup"
                        menuTitle="Emails"
                        imageName="fa fa-envelope"
                        headingVisible={true}
                        subMenus={[

                          {
                            url: "/PartnerEmails/mail",
                            name: "Subcontractors",
                            isChecked: this.checkMenuPermission("ssm_3_3_6"),
                          },
                          {
                            url: "/AgencyEmails/mail",
                            name: "Recruiters",
                            isChecked: this.checkMenuPermission("ssm_4_4_6"),
                          },
                          {
                            url: "/ApplicantEmails/mail",
                            name: "Candidates",
                            isChecked: this.checkMenuPermission("ssm_2_2_6"),
                          },
                        ]}
                      ></MenuItem>
                    )}
                    {this.checkMenuPermission("sm_2_6") && (
                      <MenuItem
                        isSelected={isSelected === "textSetup" ? true : false}
                        handleClick={this.handleClick}
                        menuId="textSetup"
                        menuTitle="Text Messages"
                        imageName="fa fa-envelope"
                        headingVisible={true}
                        subMenus={[
                          {
                            url: "/PartnerEmails/text",
                            name: "Subcontractors",
                            isChecked: this.checkMenuPermission("ssm_3_3_6"),
                          },
                          {
                            url: "/AgencyEmails/text",
                            name: "Recruiters",
                            isChecked: this.checkMenuPermission("ssm_4_4_6"),
                          },
                          {
                            url: "/ApplicantEmails/text",
                            name: "Candidates",
                            isChecked: this.checkMenuPermission("ssm_2_2_6"),
                          },

                        ]}
                      ></MenuItem>
                    )}
                    {this.checkMenuPermission("sm_3_6") && (
                      <MenuItem
                        isSelected={isSelected === "surveySetup" ? true : false}
                        handleClick={this.handleClick}
                        menuId="surveySetup"
                        menuTitle="Surveys"
                        imageName="fa fa-envelope"
                        headingVisible={true}
                        subMenus={[
                          {
                            url: "/SurveyFeedback",
                            name: "Surveys",
                            isChecked: this.checkMenuPermission("ssm_5_5_6"),
                          },
                        ]}
                      ></MenuItem>
                    )}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Menu;
