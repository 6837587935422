import React, { useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import mammoth from "mammoth";
import download from "downloadjs";
import { useDispatch, useSelector } from "react-redux";
import {
    addWordTemplate, convertDocToDocx, convertHtmlToDocx, convertHtmlToPdf,
    docToDocxConvertedReceived, getDocToDocx, getHtmlToDocx, getHtmlToPdf,
    getWordTempAddedStatus,
    htmlToDocxConvertedReceived, htmlToPdfConvertedReceived
} from "../../store/wordProccessing";
import { getCurrentUser } from "../../store/auth";
import { getAlert } from "../../../services/appAlerts";
import Select from "react-select";
import ChipInput from 'material-ui-chip-input'

const AddWordTemplate = (props) => {
    const { workflowTask, handleSubmit, 
        selectedItem,verSelectedTemp } = props;

    const toolbar = [
        [
            "undo",
            "redo",
            "font",
            "fontSize",
            "formatBlock",
            "paragraphStyle",
            "blockquote",
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript",
            "fontColor",
            "hiliteColor",
            "textStyle",
            "removeFormat",
            "outdent",
            "indent",
            "align",
            "horizontalRule",
            "list",
            "lineHeight",
            "table",
            "link",
            "image",
            "showBlocks",
            "codeView",
            "preview",
            "print",
            "save",
            "template",
        ],
    ];

    const [content, setContent] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [templateNotes, setTemplateNotes] = useState("");
    const [oldVersion,setOldVersion]=useState(false);
    const [selectedTags,setSelectedTags]=useState([]);
    const [currentTag,setCurrentTag]=useState([]);

    const htmlToPdfSelector = useSelector(getHtmlToPdf);
    const docToDocxSelector = useSelector(getDocToDocx);
    const htmlToDocxSelector = useSelector(getHtmlToDocx);
    const wordTempAddedStatusSelector = useSelector(getWordTempAddedStatus);

    const currentUser = getCurrentUser();
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedItem?.id > 0 && !verSelectedTemp) { 
            setContent(selectedItem.templateData);
            setTemplateName(selectedItem.templateName);
            setTemplateNotes(selectedItem.notes);
            setOldVersion(false);
        }
        else if(verSelectedTemp?.id > 0){
            setContent(verSelectedTemp.templateData);
            setTemplateName(verSelectedTemp.templateName);
            setTemplateNotes(verSelectedTemp.notes);
            setOldVersion(true);
        }
        else{
            setContent("");
            setTemplateName("");
            setTemplateNotes("");
            setOldVersion(false);
        }
    }, [selectedItem,verSelectedTemp])

    const handleChange = (newContent) => {
        setContent(newContent);
    };

    const handleGeneratePDF = async () => {
        let html = content;
        html = html.replaceAll('<br>', '<br></br>');
        html = html.replaceAll('</figure>', '</img></figure>');
        const frmData = new FormData();
        frmData.append("html", html);
        dispatch(convertHtmlToPdf(frmData));
    };

    useEffect(() => {
        if (htmlToPdfSelector?.size > 0) {
            const url = window.URL.createObjectURL(htmlToPdfSelector);
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            dispatch(htmlToPdfConvertedReceived(null));
        }
    }, [htmlToPdfSelector])

    const handleImportDocs = async ({ target }) => {
        if (target.files.length > 0) {
            let docData = target.files[0].arrayBuffer();
            if (target.files[0].name?.split('.').pop() === "doc") {
                var frmData = new FormData()
                frmData.append("files", target.files[0]);
                dispatch(convertDocToDocx(frmData));
            }
            else {
                mammoth.convertToHtml({ arrayBuffer: docData })
                    .then(function (result) {
                        var html = result.value;
                        var messages = result.messages;
                        console.log(html);
                        setContent(html);
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            }

        }
    }

    useEffect(() => {
        if (docToDocxSelector) {
            mammoth.convertToHtml({ arrayBuffer: docToDocxSelector })
                .then(function (result) {
                    var html = result.value;
                    var messages = result.messages;
                    setContent(html);
                })
                .catch(function (error) {
                    console.error(error);
                });
            dispatch(docToDocxConvertedReceived(null));
        }
    }, [docToDocxSelector])

    const handleGenerateHtmlToDocx = async () => {
        var frmData = new FormData()
        frmData.append("html", content);
        dispatch(convertHtmlToDocx(frmData));
    }

    useEffect(() => {
        if (htmlToDocxSelector) {
            const mimeType =
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            download(htmlToDocxSelector, `HtmlToDocxExport.docx`, mimeType);
            dispatch(htmlToDocxConvertedReceived(null));
        }
    }, [htmlToDocxSelector]);

    const SaveNewVersion = (type) => {
        const temp = {
            "ciid": workflowTask.ciid,
            "wiid": workflowTask.wiid,
            "iiid": workflowTask.iiid,
            "senderId": workflowTask.performerId,
            "templateId": 0,
            "templateType": "Word",
            "templateName": templateName,
            "notes": templateNotes,
            "templateData": content,
            "version": 1,
            "createdBy": currentUser.id
        }
        if (selectedItem?.id > 0) {
            temp.id = selectedItem.id;
            temp.createdDateTime=selectedItem.createdDateTime;
        }

        if (selectedItem?.id > 0 && type === "new") {
            temp.id=0;
            temp.version = selectedItem.version + 1;
            if (selectedItem.templateId > 0)
                temp.templateId = selectedItem.templateId;
            else
                temp.templateId = selectedItem.id;
        }

        const frmData = new FormData();
        frmData.append("template", JSON.stringify(temp));
        dispatch(addWordTemplate(frmData))
        setTemplateName("")
        setTemplateNotes("")
        setCurrentTag([])
        setContent("")
        setSelectedTags([])
    }

    useEffect(() => {
        if (wordTempAddedStatusSelector) {

            if(selectedItem?.id > 0)
                getAlert("", "Updated Successfully", "success");
            else
                getAlert("", "Added Successfully", "success");  

            handleSubmit();
        }
    }, [wordTempAddedStatusSelector]);


    const handleTagChange = (newChips) => {
        setCurrentTag(newChips)
        // let allTags = [];
        // tags.map((item) =>
        //     allTags.push({ interfaceName: item.value, interfaceName: item.label })
        // );
        // setSelectedTags(allTags)
        
      };

      const handleKeyUp = (e) => {
		if (e.keyCode == 32) {
            setSelectedTags((selectedTags)=>[...selectedTags,e.target.value]);
            setCurrentTag("")
		}
	};

   

    return <div className="row">
        
        <div className="col-md-9">
            <SunEditor
                setOptions={{
                    height: 400,
                    buttonList: toolbar,
                }}
                //defaultValue=""
                onChange={handleChange}
                setContents={content}
            />
        </div>
        <div className="col-md-3">
            <div className="form-group">
                <label htmlFor="txtDocName" className="form-control-label">Document Name</label>
                <input type="text" placeholder="Document Name"
                    className="form-control" id="txtDocName" name="txtDocName"
                    value={templateName} onChange={({ currentTarget }) => setTemplateName(currentTarget.value)} />
            </div>
            <div className="form-group">
                <label htmlFor="txtDocNotes" className="form-control-label">Document Notes</label>
                <textarea type="text" placeholder="Document Notes"
                    className="form-control" id="txtDocNotes" name="txtDocNotes"
                    value={templateNotes} onChange={({ currentTarget }) => setTemplateNotes(currentTarget.value)} />
            </div>

            <div className="form-group">

                <label className="form-control-label" htmlFor="txtDocTag">
                    Document Tags
                </label>
                {/* <MuiChipsInput value={selectedTags} onChange={handleTagChange} /> */}
                {/* <Chips
                    value={selectedTags}
                    onChange={handleTagChange}
                    suggestions={["Your", "Data", "Here"]}
                /> */}
                <ChipInput
                    defaultValue={currentTag}
                    //className="form-control"
                    style={{width:"100%"}}
                    onChange={(chips) => handleTagChange(chips)}
                />
                {/* <input type="text" placeholder="Document Name"
                    className="form-control"
                    id="txtDocTag"
                    name="txtDocTag"
                    value={currentTag}
                    onChange={handleTagChange}
                    onKeyDown={handleKeyUp}

                />
                {selectedTags.map((item) => item + ", ")} */}
                {/* <Select
              value={selectedTags}
              onChange={handleTagChange}
              isMulti={true}
            /> */}
            </div>

            {!oldVersion &&
                <div className="form-group">
                    <label htmlFor="importDocx" className="form-control-label">Import From Docx</label>
                    <input type="file" id="importDocx" className="form-control" title="Import From Docx" onChange={handleImportDocs} />
                </div>}
            {!oldVersion &&
                <div class="btn-group w-100" role="group">
                    <button id="btnGroupDrop1" type="button" class="btn btn-outline-default dropdown-toggle"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Save
                    </button>
                    <div class="dropdown-menu w-100" aria-labelledby="btnGroupDrop1">
                        <button className="dropdown-item" onClick={() => SaveNewVersion("new")}>Save New Version</button>
                        <button className="dropdown-item" onClick={() => SaveNewVersion("")}>Save Current Version</button>
                        <button className="dropdown-item" onClick={handleGenerateHtmlToDocx}>Export To Word</button>
                        <button className="dropdown-item" onClick={handleGeneratePDF}>Export To PDF</button>
                    </div>
                </div>}
        </div>
    </div>
}

export default AddWordTemplate;