import React, { Component, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Moment from "react-moment";
import Swal from "sweetalert2";
import config from "../config.json";
import http from "../services/HttpService";
import PrintPurchaseOrder from "../components/PrintPurchaseOrder";
import ViewForm from "../components/PortalForms/ViewForm";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePurchaseOrder,
  getPODeleteStatus,
  getPOSendStatus, getPurchaseOrderDoc, getPurchaseOrders, loadPurchaseOrderDoc, loadPurchaseOrders,
  purchaseOrderDocReceived,
  sendPurchaseOrder
} from "../app/store/purchaseOrder";
import { getAlert, getConfirmAlert } from "../services/appAlerts";
import { getComponentUpdateStatus, getComponentUserStatus, getToolsCount,
   loadComponentUserStatus, loadToolsCount, UpdateComponentsUserStatus } from "../app/store/tools";
import { getCurrentUser } from "../app/store/auth";

const PurchaseOrderPopup = (props) => {
const { candidateObj, handlePurchaseShowHide,purchaseShowHide, 
  workflowTask,poOrderType,item,handleSignUnsignPo,handleRefreshPoOrders } = props;
  
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [allPoTypes, setAllPoTypes] = useState([]);
  const [genShowHide, setGenShowHide] = useState(false);
  const [poAction, setPoAction] = useState("");
  const [updateCompStatus, setUpdateCompStatus] = useState(false);

  const purchaseOrdersSelector = useSelector(getPurchaseOrders);
  const purchaseOrderDocSelector = useSelector(getPurchaseOrderDoc);
  const pOSendStatusSelector = useSelector(getPOSendStatus);
  const pODeleteStatusSelector = useSelector(getPODeleteStatus);

  const componentUserStatusSelector = useSelector(getComponentUserStatus);
  const componentUpdateStatusSelector = useSelector(getComponentUpdateStatus);

  const dispatch = useDispatch();
  const currentUser=getCurrentUser();

  useEffect(() => {
    if (candidateObj?.performerid > 0) {
      dispatch(loadPurchaseOrders(candidateObj.performerid));
      //setUpdateCompStatus(true);
    }

    const poTypes = [];
    if (candidateObj.subcontractor?.fuid > 0 && item?.partnerType === "Sub-Contractors") {
      poTypes.push({
        id: candidateObj?.subcontractor.fuid,
        name: "Subcontractor",
        value: "subcontractor",
      });
    }

    if (candidateObj.recruiter?.fuid > 0 && item?.partnerType !== "Sub-Contractors") {
      poTypes.push({
        id: candidateObj.recruiter.fuid,
        name: "Recruiter",
        value: "recruiter",
      });
    }

    setAllPoTypes(poTypes);

  }, [candidateObj]);

  useEffect(() => {
    if(purchaseOrdersSelector.length > 0){
      let poType = "recruiter";
      let compId=51;
      let compName="Recruiter";
      if (item?.partnerType === "Sub-Contractors"){
        poType = "subcontractor";
        compName = "Subcontractor";
        compId=50;
      }

      const filterPoOrder = purchaseOrdersSelector.filter(x=>x.type === poType);

      setPurchaseOrders(filterPoOrder);

    }
  }, [purchaseOrdersSelector])

  const handleGenShowHide = (action) => {
    if (action === "save") {
      setGenShowHide(false);
      setPoAction("");
      getAlert("", "Added Successfully", "success");
      if (candidateObj?.performerid > 0) {
        dispatch(loadPurchaseOrders(candidateObj.performerid));
        handleRefreshPoOrders();
      }
     
    } else {
      setGenShowHide(!genShowHide);
      setPoAction(action);
    }
  };

  const handleDownloadFile = async (item, type) => {
    dispatch(loadPurchaseOrderDoc(item.id, type));
  };

  useEffect(() => {
    if (purchaseOrderDocSelector?.size === 0) {
      getAlert("", "Document Not Found", "error");
      dispatch(purchaseOrderDocReceived(null))
    }
    else if (purchaseOrderDocSelector) {
      const url = window.URL.createObjectURL(purchaseOrderDocSelector);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      dispatch(purchaseOrderDocReceived(null))
    }

  }, [purchaseOrderDocSelector])

  const handleSendPoOrder = async (id) => {
    dispatch(sendPurchaseOrder(id));
  };

  useEffect(() => {
    if (pOSendStatusSelector) {
      getAlert("", "Sent Successfully", "success");
      if (candidateObj?.performerid > 0) {
        dispatch(loadPurchaseOrders(candidateObj.performerid));
      }
    }
  }, [pOSendStatusSelector])

  const handleDeletePoOrder = async (id) => {
    const result = await getConfirmAlert("Warning", "Are you sure you want to Delete this Purchase Order?", "warning");
    if (result.isConfirmed) {
      dispatch(deletePurchaseOrder(id));
    }
  };

  useEffect(() => {
    if (pODeleteStatusSelector) {
      getAlert("", "Deleted Successfully", "success");
      if (candidateObj?.performerid > 0) {
        dispatch(loadPurchaseOrders(candidateObj.performerid));
      }
    }
  }, [pODeleteStatusSelector]);


  return (
    <React.Fragment>
      <Modal show={purchaseShowHide} size="xl">
        <Modal.Header closeButton onHide={() => handlePurchaseShowHide("")}>
          <Modal.Title>Purchase Orders</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            {poOrderType === "add" &&
              <div className="col-md-12">
                <button
                  className="btn btn-outline-default btn-sm mb-2"
                  onClick={() => handleGenShowHide("generate")}
                >
                  <i className="fa fa-plus mr-1"></i> Add Purchase Order
                </button>
                <button
                  className="btn btn-outline-default btn-sm mb-2"
                  onClick={() => handleGenShowHide("upload")}
                >
                  <i className="fa fa-upload mr-1"></i> Upload Purchase Order
                </button>
              </div>}
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table align-items-center table-flush table-hover table-striped border">
                  <thead className="thead-light">
                    <tr>
                      <th>Id</th>
                      <th>Date & Time Sent</th>
                      <th>Download/View</th>
                      <th>Signed</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseOrders.map((item, key) => (
                      <tr>
                        <td>{item.id}</td>
                        <td>
                          <Moment format="MMM Do YYYY, h:mm a">
                            {item.addedDateTime}
                          </Moment>
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-link pt-0 pl-0"
                            onClick={() => handleDownloadFile(item, "unSign")}
                          >
                            View
                          </button>
                        </td>
                        <td>
                          {item.partnerSign && (
                            <button
                              className="btn btn-sm btn-link pt-0 pl-0"
                              onClick={() => handleDownloadFile(item, "signed")}
                            >
                              Download/View
                            </button>
                          )}

                          {item.partnerSign === null && (
                            "Pending"
                          )}
                        </td>
                        <td>
                          {item.sendStatus ? (
                            <span>Sent</span>
                          ) : (
                            <>
                              <button
                                className="btn btn-sm btn-link p-0"
                                onClick={() => handleSendPoOrder(item.id)}
                              >
                                Send
                              </button>
                              <button
                                className="btn btn-sm btn-link p-0"
                                onClick={() => handleDeletePoOrder(item.id)}
                              >
                                Delete
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => handlePurchaseShowHide("")}
          >
            <i className="fa fa-times" aria-hidden="true"></i> Close
          </button>
        </Modal.Footer>
      </Modal>


      <PrintPurchaseOrder
        genShowHide={genShowHide}
        handleGenShowHide={handleGenShowHide}
        candidateObj={candidateObj}
        workflowTask={workflowTask}
        poAction={poAction}
        allPoTypes={allPoTypes}
      ></PrintPurchaseOrder>
    </React.Fragment>
  );
}

export default PurchaseOrderPopup;
