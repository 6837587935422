import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "timeLogInvoice",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
    },
    reducers: {
        timeLogInvocieReceived: (timeLogInvoice, action) => {
            timeLogInvoice.list=action.payload;
        },
        taskDone: (timeLogInvoice, action) => {
            timeLogInvoice.loading = false;
        },
        ruleAppDataReceived: (timeLogInvoice, action) => {
            timeLogInvoice.ruleAppData = action.payload;
        }
    }
});

export const { timeLogInvocieReceived, taskDone, ruleAppDataReceived } = slice.actions;
export default slice.reducer;

export const loadTimeLogInvoice = (id) => apiCallBegan({
    url: config.GetTimeLogInvoice + id,
    onSuccess: timeLogInvocieReceived.type
});

export const getTimeLogInvoice = createSelector(
    state => state.entities.timeLogInvoice,
    timeLogInvoice => timeLogInvoice.list
);

