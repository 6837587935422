import React, { Component, useEffect, useState } from "react";
import Form from "../../comman/form";
import { Modal, Button } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";
import auth, { getCurrentUser } from "../../services/authService";
import Joi from "joi-browser";
import { toast, cssTransition } from "react-toastify";
import ToastTemplate from "../../comman/toastTemplate";
import Moment from "react-moment";
import useForm from "../../app/hooks/useForm";
import useControls from "../../app/hooks/useControls";
import { useDispatch, useSelector } from "react-redux";
import { addNewDuration, getAddedDurationObj, getAllDurations, getDurationAddedStatus, loadAllDurations } from "../../app/store/duration";
import { getMonthName } from "../../services/appService";
import moment from "moment";
import { getAlert } from "../../services/appAlerts";

const AddEditDuration = (props) => {
  const { editType, selectedObj,handleDurationShowHide } = props;

  const [durationHistory, setDurationHistory] = useState([]);
  const [extensionStatus, setExtensionStatus] = useState(null);
  const [durationEndDate, setDurationEndDate] = useState(null);
  const [compType, setCompType] = useState("");

  const allDurationsSelector = useSelector(getAllDurations);
  const durationAddedStatusSelector = useSelector(getDurationAddedStatus);
  
  const months = getMonthName();

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    if (selectedObj?.performerid > 0)
      dispatch(loadAllDurations(selectedObj.performerid));

    if (selectedObj?.duration?.endDate)
      setDurationEndDate(selectedObj.duration.endDate);
  }, []);

  useEffect(() => {
    if(allDurationsSelector.length > 0 && editType !== ""){
      setDurationHistory(allDurationsSelector);
      const terminatedDur = allDurationsSelector.filter(
        (x) => x.terminateStatus === true
      );
      if (terminatedDur.length > 0) {
        const trmDt = new Date(terminatedDur[0].terminateDate);
        stateData["terminateDate"] = moment(trmDt).format("MMM Do YYYY");
        stateData["notes"] = terminatedDur[0].notes;
        setStateData(stateData);
      }
      else{
        setStateData({});
      }
    }
  }, [allDurationsSelector,editType]);

  const handleRollbackTerminated = () => {
    // const { selectedObj } = this.props;
    // const { data } = await http.get(
    //   config.RollbackTerminatedCandidate + selectedObj.performerid
    // );
    // this.props.handleDurationChanges(data);
  };

  const schema = {};

  const doSubmit = () => {
    setCompType("editDur")
    const data = { ...stateData };
    const newDate = moment(durationEndDate);
    newDate.add(parseInt(data["duration"]), 'M');

    const frmObj = {
      performerId: selectedObj.performerid,
      addedBy: currentUser.id,
      duration: data["duration"],
      durationState: extensionStatus,
      endDate: newDate.format("MM-DD-YYYY"),
      notes: data["notes"],
      startDate: selectedObj.duration?.startDate,
      terminateDate: moment(data["terminateDate"]).format("MM-DD-YYYY"),
    };
    //console.log(frmObj);
    dispatch(addNewDuration(frmObj));
  };

  useEffect(()=>{
    if(durationAddedStatusSelector && compType === "editDur"){
      getAlert("","Saved Successfully","success");
      setCompType("");
      if(selectedObj?.performerid > 0) dispatch(loadAllDurations(selectedObj.performerid));
      setExtensionStatus(null);
      setStateData(null);
      handleDurationShowHide("", "save", -1)
    }
  },[durationAddedStatusSelector]);

  const { validate, handleChange, stateData, setStateData,
    stateErrors, setStateErrors, handleSubmit } = useForm(schema, doSubmit);

  const { renderSelect, renderInput, renderTextArea } = useControls(stateData, stateErrors, handleChange, schema);

  const handleExtensionChange = ({ currentTarget }) => {
    setExtensionStatus(JSON.parse(currentTarget.value));
  }

  if (stateData && parseInt(stateData["duration"]) > 0) {
    const newDate = moment(durationEndDate);
    newDate.add(parseInt(stateData["duration"]), 'M');
    //newDate.setMonth(newDate.getMonth() + parseInt(stateData["duration"]));
    stateData["endDate"] = newDate.format("MMM Do YYYY")
  }

  return (
    <>
      {editType === "duration" && stateData && <div className="p-3 border mt-2">
        <div className="row">
          <div className="col-md-12 text-right">
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => handleDurationShowHide("", "", -1)}
            >
              <i className="fa fa-times mr-2"></i>Close
            </button>
          </div>
          <div className="col-md-12 text-center">
            <h3>{editType === "duration" && "Edit Duration"}</h3>
          </div>
          {editType === "duration" && (
            <React.Fragment>
              <div className="col-md-12 form-inline form-group">
                <label htmlFor="" className="form-control-label mr-3">
                  Duration Extention :
                </label>
                <div className="custom-control custom-radio">
                  <input
                    name="status"
                    className="custom-control-input"
                    id="extend"
                    type="radio"
                    value={true}
                    onChange={handleExtensionChange}
                  />
                  <label
                    className="custom-control-label mr-3"
                    htmlFor="extend"
                  >
                    Extend
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    name="status"
                    className="custom-control-input"
                    id="notExent"
                    type="radio"
                    value={false}
                    onChange={handleExtensionChange}
                  />
                  <label className="custom-control-label" htmlFor="notExent">
                    Do Not Extend (Terminate)
                  </label>
                </div>
              </div>
              {extensionStatus && (
                <>
                  <div className="col-md-6">
                    {renderInput("duration", "Duration (In Month)", "number")}
                  </div>
                  <div className="col-md-6">
                    <fieldset disabled={true}>
                      {renderInput("endDate", "End Date")}
                    </fieldset>
                  </div>
                </>
              )}
              {extensionStatus === false && (
                <div className="col-md-12">
                  {renderInput(
                    "terminateDate",
                    "Terminate Date",
                    "date"
                  )}
                </div>
              )}
            </React.Fragment>
          )}

          {editType === "terminated" && (
            <div className="col-md-12">
              {renderInput("terminateDate", "Terminate Date", "date")}
            </div>
          )}
          <div className="col-md-12">
            {renderTextArea("notes", "Notes")}
          </div>

          {editType === "duration" && (
            <div className="col-md-12">
              <label htmlFor="" className="form-control-label">
                History
              </label>
              <table className="table table-bordered bg-white">
                <thead className="thead-light">
                  <tr>
                    <th>S.No.</th>
                    <th>Date & Time Added</th>
                    <th>Status</th>
                    <th>Duration</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {durationHistory.map((item, key) => (
                    <tr>
                      <td>{key + 1}</td>
                      <td>
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.addedDateTime}
                        </Moment>
                      </td>
                      <td>
                        {item.durationState && "Extended"}
                        {!item.durationState && "Not Extend"}
                      </td>
                      <td>`{item.duration} Months</td>
                      <td>
                        <Moment format="MMM Do YYYY">{item.startDate}</Moment>
                      </td>
                      <td>
                        <Moment format="MMM Do YYYY">{item.endDate}</Moment>
                      </td>
                      <td>{item.notes}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="col-md-12 text-center mt-2">
            {editType !== "terminated" && (
              <button className="btn btn-sm btn-outline-default"
                onClick={handleSubmit}
              >
                <i class="fa fa-check" aria-hidden="true"></i> Save
              </button>
            )}

            {editType === "terminated" && (
              <button className="btn btn-sm btn-outline-default"
              //onClick={handleRollbackTerminated}
              >
                <i class="fa fa-check" aria-hidden="true"></i> Rollback Termination
              </button>
            )}
          </div>
        </div>
      </div>}
    </>
  );
}

export default AddEditDuration;
