import http from "./HttpService";
import config from "../config.json";
import JwtDecode from "jwt-decode";

const apiEndPoint = "/account/";
const tokenKey = "token";
//const key=getJwt();
//console.log(key);
//http.setJwt(key);

export async function login(username, password, userType, partnerId) {
  const { data: user } = await http.post(apiEndPoint + "authenticate", {
    username,
    password,
    userType,
    partnerId,
  });
  localStorage.setItem("currentUser", JSON.stringify(user));
  return user;
}

export async function changePassword(oldPassword, newPassword, userId) {
  const { data } = await http.post(apiEndPoint + "ChangePassword", {
    oldPassword,
    newPassword,
    userId,
  });
  //console.log(data);
}

export async function changeEmail(email, userId) {
  const { data } = await http.post(apiEndPoint + "ChangeEmail", {
    email,
    userId,
  });
  //console.log(data);
  return data;
}

export async function verifyUser(frmData) {
  const { data } = await http.post(apiEndPoint + "VerifyUser", frmData);
  return data;
}

export async function forgotPassword(frmData) {
  const { data } = await http.post(apiEndPoint + "ForgotPassoword", frmData);
  return data;
}

export async function logout() {
  const user = getCurrentUser();
  localStorage.removeItem(tokenKey);
  const { data } = await http.get(
    config.UpdateLoginLogoutStatus + user.id + "&type=logout"
  );
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const decode = JwtDecode(jwt);
    const logType = localStorage.getItem("loginType");
    if (logType === "ad") {
      decode.id = 1;
      decode.type = "admin";
    }
    return decode;
  } catch (error) {
    return null;
  }
}

export async function getCurrentUserDetails(performerId) {
  const { data } = await http.get(
    apiEndPoint + "GetUser?performerId=" + performerId
  );
  return data;
}

export async function getAdminDetails(adminId) {
  const { data } = await http.get(
    apiEndPoint + "GetAdminDetails?adminId=" + adminId
  );
  return data;
}

export async function setAdToken(token) {
  localStorage.setItem(tokenKey, token);
  localStorage.setItem("loginType", "ad");
}

export function getJwt() {
  const token = localStorage.getItem("token");
  if (token === null) return "";
  return token;
}
export async function validateUser(userId, email, userType, pwd) {
  const { data: user } = await http.post(apiEndPoint + "ValidateUser", {
    userId,
    email,
    userType,
    password: pwd,
  });
  localStorage.setItem(tokenKey, user.token);
}

export function getLoginUser() {
  return localStorage.getItem("currentUser");
}

export default {
  login,
  logout,
  getCurrentUser,
  getJwt,
  getCurrentUserDetails,
  changePassword,
  changeEmail,
  verifyUser,
  forgotPassword,
  getAdminDetails,
  validateUser,
  getLoginUser,
  setAdToken,
};
