import React from "react";
import ViewForm from "../components/PortalForms/ViewForm";
import { Modal } from "react-bootstrap";

const ViewFormModal = (props) => {
    const { handleViewForm, viewFormShowHide, workflowTask, selectedForm } = props;
    return (
        <Modal show={viewFormShowHide} size="xl">
            <Modal.Header closeButton onHide={handleViewForm}>
                <Modal.Title>View Form</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-secondary">
                <div className="row" style={{ height: "600px", overflow: "auto" }}>
                    <div className="col-md-12">
                        <ViewForm
                            formShowHide={viewFormShowHide}
                            handleFormShowHide={viewFormShowHide}
                            workflowTask={workflowTask}
                            selectedForm={selectedForm}
                        ></ViewForm>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={handleViewForm}
                >
                    <i
                        class="fa fa-times mr-2"
                        aria-hidden="true"
                    ></i>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );

}

export default ViewFormModal;