import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "jspdf-autotable";
import WorkflowElement from "../../app/components/WorkflowElement";
import { addUpdateBprmFormHistory, getFormUpdateHistoryStatus } from "../../app/store/bprmForm";
import { getAlert } from "../../services/appAlerts";

const ViewForm=(props)=> {
  const {workflowTask, selectedForm } = props;
  const [wfTask,setWfTask]=useState(null);
  const [saveBtnProcessStatus,setSaveBtnProcessStatus]=useState(true);
  const [updatedStateData,setUpdatedStateData]=useState(null);

  const formUpdateHistoryStatusSelector=useSelector(getFormUpdateHistoryStatus)

  const dispatch=useDispatch();

  useEffect(()=>{
    if(selectedForm?.id && workflowTask?.ciid)
      setWfTask({formId:selectedForm.formId,ciid:workflowTask.ciid});
  },[selectedForm]);

  const handleNextTask=(frmData)=>{
    setSaveBtnProcessStatus(true);
    frmData.append("formLibrary", JSON.stringify(selectedForm));
    dispatch(addUpdateBprmFormHistory(frmData));
  }

  useEffect(()=>{
    if(formUpdateHistoryStatusSelector){
      getAlert("","Updated Successfully","success");
      setSaveBtnProcessStatus(false);
    }
  },[formUpdateHistoryStatusSelector]);

  return (
    <div>
         

      {wfTask?.formId > 0 && <WorkflowElement
        wfTask={wfTask}
        handleNextTask={handleNextTask}
        viewType={false}
        appSummary={false}
        codeVerified={true}
        wfTaskType={"viewForm"}
        saveBtnProcessStatus={saveBtnProcessStatus}
        setSaveBtnProcessStatus={setSaveBtnProcessStatus}
        updatedStateData={updatedStateData}
      />}

      {/* <DropdownPrompt
        dropdownShowHide={dropdownShowHide}
        handleDropdownShowHide={this.handleDropdownShowHide}
        dropdwonTitle={dropdwonTitle}
        selectedDropdwonData={selectedDropdwonData}
        handleDropdownSubmit={this.handleDropdownSubmit}
      ></DropdownPrompt>

      <ViewForm1
        formShowHide={fhowHide}
        handleFormShowHide={this.handleFShowHide}
        workflowTask={workflowTask}
        selectedForm={sForm}
      ></ViewForm1> */}

    </div>
  );
}

export default ViewForm;
