import React from "react";
import loadjs from "loadjs";
import * as serviceWorker from "./serviceWorker";
import ProtectedRoute from "./comman/protectedRoute";
import Logout from "./components/Logout";
import App from "./App";
import PartnerLogin from "./components/PartnerLogin";
import WorkflowLogin from "./components/WorkflowLogin";
import MyPatient from "./components/PartnerManage/MyPatient";
import PatientDetails from "./components/PartnerManage/PatientDetails";
import ExistingPatient from "./components/PartnerManage/ExistingPatient";
import ApplicantLogin from "./components/ApplicantLogin";
import AdminLogin1 from "./components/Auth/AdminLogin1";
import { msalConfig } from "./authConfig";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import "./index.css";
import "./App.css"

import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { Provider } from "react-redux";
import store, { persistor } from "./app/store/configureStore";

const pca = new PublicClientApplication(msalConfig);

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Root />,
//   },
// ]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <Provider store={store}>
      <MsalProvider instance={pca}>
        <Routes>
          {/* <Route path="/login" component={Login} />
          <Route path="/PartnerLogin" component={PartnerLogin} />
          <Route path="/WorkflowLogin" component={WorkflowLogin} />
          <Route path="/ApplicantLogin" component={ApplicantLogin} />
          <Route path="/AdminLogin1" component={AdminLogin1} />

          <Route path="/logout" element={<Logout />} />
          <Route path="/MyPatient" component={MyPatient} />
          <Route path="/ExistingPatient" component={ExistingPatient} />
          <Route path="/PatientDetails" component={PatientDetails} /> */}
          <Route path="/*" element={ <App />} />
        </Routes>
      </MsalProvider>
    </Provider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
