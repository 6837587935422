import React, { Component, useEffect, useState } from "react";
import {
  Popover,
  Button,
  OverlayTrigger,
  Nav,
  NavDropdown,
  Col,
  Tab,
  Row,
  Tabs,
} from "react-bootstrap";
import Moment from "react-moment";
import http from "../../services/HttpService";
import config from "../../config.json";
import auth from "../../services/authService";
import InternalReviewPopup from "./InternalReviewPopup";
import CandidateReqDoc from "../OMCRegistry/CandidateReqDoc";
import Swal from "sweetalert2";
import { getCurrentUser } from "../../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getAppMessages, loadAppMessages } from "../../app/store/messages";
import {
  addComment, getAppComments, getCommentAddedStatus, getCommentUpdatedStatus,
  loadAppComments, updateComment
} from "../../app/store/comments";
import {
  deleteDocument, documentDownloaded, documentRollBacked,
  downloadDocument, esignDocumentSent, getDocumentDeleteStatus,
  getDocumentPurgeStatus, getDocumentRollbackStatus, getDocuments,
  getDocumentsSendStatus, getDownloadedDocumentData, getEsignDocuments, getReqDocSendStatus,
  getReqDocuments, getSignedDocumentData, getesignDocumentsSendStatus, loadDocuments, loadEsignDocuments, loadReqDocuments,
  loadSignedDocument,
  purgeDocument, rollbackDocument, sendDocuments, sendReqDocuments,
  sendeSignDocuments,
  signedDocumentReceived
} from "../../app/store/documents";
import { getAlert, getConfirmAlert } from "../../services/appAlerts";
import {
  deleteFormLibrary, getAppFormLibrary, getAppFormLibraryDeleteStatus,
  getAppFormLibrarySendStatus,
  loadAppLibraryForms, sendFormLibraryToApplicant
} from "../../app/store/forms";
import AddFormLibrary from "./AddFormLibrary";
import ViewForm from "./ViewForm";
import useDocDownload from "../../app/hooks/useDocDownload";
import UploadOther from "../../app/components/Document/UploadOther";
import DocusignPopup from "./DocusignPopup";
import ViewFormModal from "../../ReviewTools/ViewFormModal";
import GenerateContract from "../../app/components/Contract/GenerateContract";
import HttpService from "../../services/HttpService";

const Document = (props) => {

  const { workflowTask, uploadType, setViewBprmFormShowHide, app, item: applicantObj } = props;

  const [appDocuments, setAppDocuments] = useState([]);
  const [appMessages, setAppMessages] = useState([]);
  const [appComments, setAppComments] = useState([]);
  const [appLibraryForms, setAppLibraryForms] = useState([]);
  const [reqDocuments, setReqDocuments] = useState([]);
  const [selectedDocIds, setSelectedDocIds] = useState([]);
  const [sendType, setSendType] = useState("");
  const [docShowHide, setDocShowHide] = useState(false);
  const [showHide, setShowHide] = useState(false);
  const [ViewformShowHide, setViewformShowHide] = useState(false);
  const [addFormShowHide, setAddFormShowHide] = useState(false);
  const [otherDocShowHide, setOtherDocShowHide] = useState(false);
  const [contractShowHide, setContractShowHide] = useState(false);
  const [selectedObj, setSelectedObj] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [copyItem, setCopyItem] = useState("");
  const [esignDocuments, setEsignDocuments] = useState([]);
  const [selectedSignedDoc, setSelectedSignedDoc] = useState(null);
  const [signLoading, setSignLoading] = useState(false);

  const appMessagesSelector = useSelector(getAppMessages);
  const appDocumentSelector = useSelector(getDocuments);
  const appCommentsSelector = useSelector(getAppComments);
  const updateCommentsSelector = useSelector(getCommentUpdatedStatus);
  const documentSendStatusSelector = useSelector(getDocumentsSendStatus);
  const esignDocumentSendStatusSelector = useSelector(getesignDocumentsSendStatus);
  const appFormLibrarySelector = useSelector(getAppFormLibrary);
  const appFormLibrarySendStatusSelector = useSelector(getAppFormLibrarySendStatus);
  const appFormLibraryDeleteStatusSelector = useSelector(getAppFormLibraryDeleteStatus);
  const documentDeleteStatusSelector = useSelector(getDocumentDeleteStatus);
  const documentPurgeStatusSelector = useSelector(getDocumentPurgeStatus);
  const documentRollbackStatusSelector = useSelector(getDocumentRollbackStatus);
  const downloadedDocumentDataSelector = useSelector(getDownloadedDocumentData);
  const reqDocumentsSelector = useSelector(getReqDocuments);
  const commentAddedStatusSelector = useSelector(getCommentAddedStatus);
  const reqDocSendStatusSelector = useSelector(getReqDocSendStatus);
  const esignDocumentsSelector = useSelector(getEsignDocuments);
  const signedDocumentDataSelector = useSelector(getSignedDocumentData);

  const [handleDownloadFile] = useDocDownload();

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    if (workflowTask?.ciid) {
      //dispatch(loadAppMessages(workflowTask.ciid));
      dispatch(loadDocuments(workflowTask.ciid));
      dispatch(loadAppComments("document", workflowTask.ciid));
      dispatch(loadAppLibraryForms(workflowTask.ciid));
      dispatch(loadReqDocuments(workflowTask.ciid));
    }
    if (workflowTask?.applicantId) {
      dispatch(loadEsignDocuments(workflowTask.applicantId));
    }
  }, []);

  // useEffect(() => {
  //   if (appMessagesSelector.length > 0)
  //     setAppMessages(appMessagesSelector);
  // }, [appMessagesSelector]);

  useEffect(() => {
    if (appDocumentSelector.length > 0) {
      const filterDocs = appDocumentSelector.filter(x => x.uploadType !== "req");
      setAppDocuments(filterDocs);
    }
  }, [appDocumentSelector]);

  useEffect(() => {
    if (appCommentsSelector.length > 0)
      setAppComments(appCommentsSelector);
  }, [appCommentsSelector]);

  useEffect(() => {
    if (appFormLibrarySelector.length > 0)
      setAppLibraryForms(appFormLibrarySelector);
  }, [appFormLibrarySelector]);

  useEffect(() => {
    if (documentSendStatusSelector && workflowTask?.ciid) {
      getAlert("", "Sent Successfully", "success");
      dispatch(loadDocuments(workflowTask.ciid))
    }
  }, [documentSendStatusSelector]);

  useEffect(() => {
    if (esignDocumentSendStatusSelector && workflowTask?.ciid) {
      getAlert("", "Sent Successfully", "success");
      dispatch(loadDocuments(workflowTask.ciid));
    }
  }, [esignDocumentSendStatusSelector]);

  useEffect(() => {
    setEsignDocuments(esignDocumentsSelector);
  }, [esignDocumentsSelector])

  useEffect(() => {
    if (updateCommentsSelector && workflowTask?.ciid) {
      dispatch(loadAppComments("document", workflowTask.ciid));
    }
  }, [updateCommentsSelector]);

  useEffect(() => {
    setReqDocuments(reqDocumentsSelector);
    console.log(reqDocumentsSelector)
  }, [reqDocumentsSelector])

  const handleModalShowHide = (item) => {
    setShowHide(!showHide);
    setSelectedObj(item);
  };

  const handleSubmit = async ({ comments }) => {
    const wfTask = { ...workflowTask };
    wfTask.performerId = currentUser.id;
    const frmData = new FormData();
    frmData.append("comment", comments);
    frmData.append("type", "document");
    frmData.append("workflowTask", JSON.stringify(wfTask));
    dispatch(addComment(frmData));
    setShowHide(false);
  };

  useEffect(() => {
    if (commentAddedStatusSelector && workflowTask?.ciid) {
      dispatch(loadAppComments("document", workflowTask.ciid));
    }

  }, [commentAddedStatusSelector])

  const handleEditComment = async (item) => {
    dispatch(updateComment(item));
    setShowHide(false);
  };

  const handleDeleteComment = async (item) => {
    dispatch(deletedDocumnets(item));
    setShowHide(false);
  };

  const handleDocShowHide = (type) => {
    if (type === "save") {
      setDocShowHide(false);
      setSendType("")
      if (workflowTask?.ciid)
        dispatch(loadDocuments(workflowTask.ciid))
    } else {
      setDocShowHide(!docShowHide);
      setSendType(type);
    }
  };

  const handleDocumentLoad = () => {
    if (workflowTask?.ciid)
      dispatch(loadDocuments(workflowTask.ciid))
  };

  const handleSendSelect = ({ currentTarget }) => {
    const id = currentTarget.id.replace("chk", "");
    const filterData = selectedDocIds.filter((x) => x !== id);
    if (currentTarget.checked) filterData.push(id);
    setSelectedDocIds(filterData);
  };

  const handleSendDoc = async (id) => {
    const docIds = [id];
    const frm = new FormData();
    frm.append("ids", docIds);
    frm.append("userId", currentUser.id);
    dispatch(sendDocuments(frm));
  };

  const handleSendeSignDoc = async (id) => {
    const docIds = [id];
    const frm = new FormData();
    frm.append("ids", docIds);
    frm.append("userId", currentUser.id);
    frm.append("ciid", workflowTask.ciid);
    dispatch(sendeSignDocuments(frm));
  };

  const handleSendAllDoc = async () => {
    if (selectedDocIds.length > 0) {
      const frm = new FormData();
      frm.append("ids", selectedDocIds);
      frm.append("userId", currentUser.id);
      dispatch(sendDocuments(frm))
    } else {
      getAlert("", "Selected documents are sent", "error");
    }
  };

  const handleDeleteDocument = async (id) => {
    dispatch(deleteDocument(id));
  };

  useEffect(() => {
    if (documentDeleteStatusSelector && workflowTask?.ciid) {
      getAlert("", "Deleted Successfully", "success");
      dispatch(loadDocuments(workflowTask.ciid));
    }
  }, [documentDeleteStatusSelector])

  const handlePurgeDocument = async (id) => {
    const result = await getConfirmAlert("Warning", "Are you sure you want to Purge this Document?", "warning");
    if (result.isConfirmed) dispatch(purgeDocument(id));
  };

  useEffect(() => {
    if (documentPurgeStatusSelector && workflowTask?.ciid) {
      getAlert("", "Purged Successfully", "success");
      dispatch(loadDocuments(workflowTask.ciid));
    }
  }, [documentPurgeStatusSelector])

  const handleRollbackDocument = async (id) => {
    dispatch(rollbackDocument(id));
  };

  useEffect(() => {
    if (documentRollbackStatusSelector && workflowTask?.ciid) {
      getAlert("", "Rollback Successfully", "success");
      dispatch(loadDocuments(workflowTask.ciid));
    }
  }, [documentRollbackStatusSelector])


  const handleViewFormShowHide = (f) => {
    setViewformShowHide(!ViewformShowHide);
    setViewBprmFormShowHide(!ViewformShowHide);
    setSelectedForm(f);
  };

  const handleSendForm = async (id) => {
    dispatch(sendFormLibraryToApplicant(id));
  };

  useEffect(() => {
    if (appFormLibrarySendStatusSelector && workflowTask?.ciid) {
      getAlert("", "Sent Successfully", "success");
      dispatch(loadAppLibraryForms(workflowTask.ciid));
    }
  }, [appFormLibrarySendStatusSelector])

  const handleDeleteForm = async (f) => {
    const result = await getConfirmAlert("Warning", "Are you sure you want to Delete this Form Library?", "warning")
    if (result.isConfirmed) dispatch(deleteFormLibrary(f.id))
  };

  useEffect(() => {
    if (appFormLibraryDeleteStatusSelector && workflowTask?.ciid) {
      getAlert("", "Deleted Successfully", "success");
      dispatch(loadAppLibraryForms(workflowTask.ciid));
    }
  }, [appFormLibraryDeleteStatusSelector])

  const handleAddLibraryShowHide = () => {
    setAddFormShowHide(!addFormShowHide);
    setSelectedForm(null)
  };

  const handleOtherDocShowHide = (type) => {
    setOtherDocShowHide(!otherDocShowHide);
    if (type === "save" && workflowTask?.ciid) {
      dispatch(loadDocuments(workflowTask.ciid));
      dispatch(loadReqDocuments(workflowTask.ciid))
    }
  }

  const getReqUploadedDocs = (item,type) => {

    const achDocs = appDocuments.filter(
      (x) => x.updType === `req_${!item.id ? item.docId : item.id}`
    );
    if (achDocs.length === 0) {
      return <>
        <td></td>
        <td>
          {type === "req" ? "Pending" : "Done"}
        </td>
      </>
    }

    if (achDocs.length > 0) {
      const doc = achDocs[0];
      return <>
        <td>
          <button
            className="btn btn-link btn-sm p-0"
            title="Download Document"
            onClick={() => handleDownloadFile(doc)
            }
          >
            {doc.docName}
          </button>
          {getPopover(doc.remarks)}
        </td>
        <td>Done</td>
      </>
    }
  }

  const handleViewSignedDoc = (item) => {
    setSelectedSignedDoc(item);
    setSignLoading(true);
    dispatch(loadSignedDocument(item.id));
  }

  useEffect(() => {
    if (signedDocumentDataSelector && selectedSignedDoc) {
      const url = window.URL.createObjectURL(signedDocumentDataSelector);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";

      if (selectedSignedDoc?.docName)
        link.setAttribute("download", selectedSignedDoc.docName);
      else if (selectedSignedDoc?.documentName)
        link.setAttribute("download", selectedSignedDoc.documentName);
      else
        link.setAttribute("download", "ipcs_document");

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setSelectedSignedDoc(null);
      setSignLoading(false);
      dispatch(signedDocumentReceived(null));
    }
  }, [signedDocumentDataSelector])

  const getSignedDocuments = (item) => {
    const signDoc = esignDocuments.filter(x => x.docData === item.docData && x.status === true);
    if (signDoc.length === 0) {
      return <>
        <td></td>
        <td>Pending</td>
      </>
    }

    if (signDoc.length > 0) {
      const doc = signDoc[0];
      return <>
        <td>
          <button
            className="btn btn-link btn-sm p-0"
            title="Download Document"
            onClick={() => handleViewSignedDoc(doc)
            }
          >
            {doc.documentName}
          </button>
          {getPopover(doc.remarks)}
        </td>
        <td>Signed</td>
      </>
    }
  }

  const getReqRefDocs = (item) => {
    if (item.refDocId > 0) {
      const findDocs = appDocumentSelector.filter(x => x.uploadId === item.refDocId);
      if (findDocs.length > 0) {
        return <>
          {findDocs.map(doc =>
            <td>
              <button
                className="btn btn-link btn-sm p-0"
                title="Download Document"
                onClick={() => handleDownloadFile(doc)}
              >
                {doc.docName}
              </button>
              {getPopover(item.notes)}
              <br />
              {item.documnetName}
              
            </td>
          )}
        </>
      }
    }

    return <td>
      {item.documnetName}{getPopover(item.notes)}
    </td>

  }

  const getPopover = (notes) => {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h4">Notes</Popover.Title>
        <Popover.Content>{notes}</Popover.Content>
      </Popover>
    );
    return (
      <React.Fragment>
        <OverlayTrigger trigger="click" placement="top" overlay={popover}>
        <i className="fa fa-info-circle btn btn-sm" style={{cursor:"pointer"}}></i>
        </OverlayTrigger>
      </React.Fragment>
    );
  };

  const handleContractShowHide = () => {
    setContractShowHide(!contractShowHide);
  }

  const handleSendReqDocument = (id) => {
    dispatch(sendReqDocuments(id));
  }

  useEffect(() => {
    if (reqDocSendStatusSelector && workflowTask?.ciid) {
      getAlert("", "Sent Successfully", "success");
      dispatch(loadReqDocuments(workflowTask.ciid));
    }
  }, [reqDocSendStatusSelector]);

  const handleCopyClipboard = (text) => {
    navigator.clipboard.writeText(window.location.origin + text);
    setCopyItem(window.location.origin + text);
    setTimeout(() => {
      setCopyItem("");
    }, 3000);
  }

  const messagesDocs = appDocuments.filter((x) => x.updType === "Messaging");
  const projDocs = appDocuments.filter((x) => x.uploadType === "project");
  const clientDocs = appDocuments.filter((x) => x.uploadType === "client");
  let filterDocs = appDocuments.filter((x) => x.updType !== "Messaging" && x.deleteFlag !== true);

  let receviedDocumnets = filterDocs.filter((x) => x.uploaderId !== parseInt(currentUser.id) && !x.uploadType?.includes("_c2c_"))
  const ppDocs = filterDocs.filter((x) => x.uploadType?.includes("_c2c_") && x.sentStatus === true);
  if (ppDocs.length > 0) {
    receviedDocumnets = [...receviedDocumnets, ...ppDocs];
  }
  const sentDocuments = filterDocs.filter(
    (x) => x.uploaderId === parseInt(currentUser.id) && x.deleteFlag !== true
  )

  const deletedDocumnets = appDocuments.filter((x) => x.deleteFlag === true)

  return (
    <React.Fragment>
      <Tabs defaultActiveKey={2} id="uncontrolled-tab-example">
        
        <Tab
          eventKey={2}
          title={"Upload"}
          className="p-3 border"
          tabClassName="h4"
        >
          <div className="row">
            <div className="col-md-4 text-left">
              <button
                className="btn btn-sm btn-link pl-0 mr-0"
                onClick={handleDocumentLoad}
              >
                Refresh
              </button>
              |
              <button
                className="btn btn-sm btn-link"
                onClick={handleSendAllDoc}
              >
                Send All Selected Documents
              </button>
            </div>
            <div className="col-md-8 text-right">
              <div className="dropdown">
                <button
                  className="btn btn-outline-default btn-sm dropdown-toggle btn-min-width mr-0"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-paperclip mr-2" aria-hidden="true"></i>
                  Upload
                </button>

                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => handleContractShowHide()}
                  >

                    Generate Contract
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => handleDocShowHide("repository")}
                  >

                    Upload from DDRMS Repository
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => setOtherDocShowHide("other")}
                  >

                    Upload Required Document
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => handleDocShowHide("upload")}
                  >

                    Upload from Local Drive
                  </button>
                  {/* <button
                type="button"
                className="dropdown-item"
                onClick={() => handleAddLibraryShowHide()}
              >
                
                Upload from Forms library
              </button> */}

                </div>
              </div>
            </div>
            <div className="col-md-12 mt-1">
              <div
                className="table-responsive"
              //style={{ maxHeight: "500px", overflow: "auto" }}
              >
                <table className="table table-striped align-items-center table-flush border">
                  <thead className="thead-light-green">
                    <tr>
                      <th>Select</th>
                      <th>Date & Time</th>
                      <th>Document Name</th>
                      <th></th>
                      <th>Type</th>
                      <th>Action</th>
                      <th>Upload</th>
                      <th>Received</th>
                      {/* <th>Status</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {sentDocuments
                      .filter(
                        (x) =>
                          x.uploadType !== "client" &&
                          x.uploadType !== "reporting" &&
                          x.uploadType !== "project" &&
                          x.updType !== "internal"
                      )
                      .map((item, key) => (
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              id={`chk${item.docId}`}
                              name={`chk${item.docId}`}
                              onChange={handleSendSelect}
                              checked={item.sentStatus}

                            />
                          </td>
                          <td>
                            <Moment format="MMM Do YYYY">
                              {item.dateTime}
                            </Moment>
                            <br />
                            <Moment format="h:mm a">
                              {item.dateTime}
                            </Moment>
                          </td>
                          <td style={{ whiteSpace: "pre-wrap" }}>
                           
                            <button
                              className="btn btn-link btn-sm text-left p-0"
                              title="Download File"
                              onClick={() => handleDownloadFile(item)}
                            >
                              {item.docName}
                            </button>
                            {getPopover(item.remarks)}
                          </td>
                          <td>
                            <button
                              className="btn btn-link btn-sm text-left p-0"
                              title="Copy Link"
                              onClick={() => handleCopyClipboard(`DownloadDocLink/${item.docId}`)}
                            >

                              {copyItem === `${window.location.origin}/DownloadDocLink/${item.docId}` ? <span>Copied!</span>
                                : <i class="fa fa-link" aria-hidden="true"></i>}
                            </button>
                          </td>
                          <td>
                            {item.docModifiedName === "esign"
                              ? "WF eSign"
                              : "Document"}
                          </td>
                          <td>
                            {item.sentStatus ? (
                              <>
                                <i
                                  className="fa fa-check mr-1"
                                  aria-hidden="true"
                                ></i>
                                {item.docModifiedName === "esign"
                                  ? "Sent by WF eSign"
                                  : "Sent"}

                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-sm btn-link p-0"
                                  onClick={() => handleSendDoc(item.docId)}
                                >
                                  Send
                                </button>
                                {item.docType === "application/pdf" && (
                                  <button
                                    className="btn btn-sm btn-link p-0"
                                    onClick={() => handleSendeSignDoc(item.docId)}
                                  >
                                    WF eSign
                                  </button>
                                )}
                              </>
                            )}
                            {!item.sentStatus && (
                              <button
                                className="btn btn-sm btn-link p-0"
                                onClick={() => handleDeleteDocument(item.docId)}
                              >
                                Delete
                              </button>
                            )}
                            <br />
                            {item.sentDateTime && (
                              <Moment format="MMM Do YYYY">
                                {item.sentDateTime}
                              </Moment>
                            )}
                          </td>
                          {item.docModifiedName === "esign" ? getSignedDocuments(item) : getReqUploadedDocs(item,"sent")}
                          {/* <td>
                            <button className="btn btn-sm btn-primary btn-min-width">Upload</button>
                          </td>
                          <td></td> */}
                        </tr>
                      ))}
                    {/* {appLibraryForms.map((f, key) =>
                      <tr>
                        <td>
                          <input
                            type="checkbox"
                            id={`chk${f.id}`}
                            name={`chk${f.id}`}
                            onChange={handleSendSelect}
                            checked={f.sentStatus}
                          />
                        </td>
                        <td>
                          <Moment format="MMM Do YYYY">
                            {f.createdDateTime}
                          </Moment>
                          <br/>
                          <Moment format="h:mm a">
                            {f.createdDateTime}
                          </Moment>
                        </td>
                        <td>

                          <button
                            className="btn btn-link btn-sm text-left p-0"
                            onClick={() => handleViewFormShowHide(f)}
                          >
                            {f.formName}
                          </button>
                        </td>
                        <td>
                          <button
                              className="btn btn-link btn-sm text-left p-0"
                              title="Copy Link"
                              onClick={()=>handleCopyClipboard(`/ViewFormLink/${f.id}`)}
                            >
                               {copyItem === `/ViewFormLink/${f.id}` ? <span>Copied!</span> 
                              : <i class="fa fa-link" aria-hidden="true"></i>}
                            </button>
                        </td>
                        <td>Fillable</td>
                        <td>
                          {f.sendStatus ? (
                            "Sent"
                          ) : (
                            <button
                              className="btn btn-link btn-sm p-0 text-underline"
                              onClick={() => handleSendForm(f.id)}
                            >
                              Send
                            </button>
                          )}
                        </td>
                        <td>
                          <button className="btn btn-sm btn-primary btn-min-width">Complete and Upload</button>
                        </td>
                        <td>
                          {f.updatedCount === 0 ? (
                            "Pending"
                          ) : (
                            <button
                              className="btn btn-link btn-sm text-left text-underline"
                              onClick={() => handleViewFormShowHide(f)}
                            >
                              {f.formName}
                            </button>
                          )}
                        </td>

                      </tr>)} */}
                    {reqDocuments.map((f, key) =>
                      <tr>
                        <td>
                          <input
                            type="checkbox"
                            id={`chk${f.id}`}
                            name={`chk${f.id}`}
                            onChange={handleSendSelect}
                            checked={f.sendStatus}
                          />
                        </td>
                        <td>
                          <Moment format="MMM Do YYYY">
                            {f.addedDatetime}
                          </Moment>
                          <br />
                          <Moment format="h:mm a">
                            {f.addedDatetime}
                          </Moment>
                        </td>
                        {getReqRefDocs(f)}
                        <td><button
                          className="btn btn-link btn-sm text-left p-0"
                          title="Copy Link"
                        >
                          <i class="fa fa-link" aria-hidden="true"></i>
                        </button></td>
                        <td>Requirement</td>
                        <td>
                          {f.sendStatus ? (
                            "Sent"
                          ) : (
                            <button
                              className="btn btn-link btn-sm p-0"
                              onClick={() => handleSendReqDocument(f.id)}
                            >
                              Send
                            </button>
                          )}
                        </td>
                        {getReqUploadedDocs(f,"req")}
                      </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey={4}
          title={"Received"}
          className="p-3 border"
          tabClassName="h4"
        >
          <div className="row">
            <div className="col-md-12">
              <div
                className="table-responsive"
              //style={{ maxHeight: "500px", overflow: "auto" }}
              >
                <table className="table table-striped align-items-center table-flush border">
                  <thead className="thead-light-green">
                    <tr>
                      <th>S.No.</th>
                      <th>Date & Time</th>
                      <th>From</th>
                      {/* <th>Role</th> */}
                      <th>Document Name</th>
                      {/* <th>Type</th>
              <th>Size</th> */}
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {receviedDocumnets.map((item, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>
                          {
                            <Moment format="MMM Do YYYY, h:mm a">
                              {item.dateTime}
                            </Moment>
                          }
                        </td>
                        <td>
                          {item.uploaderName}
                          <br />
                          {item.uploaderEmail}
                        </td>
                        <td style={{ whiteSpace: "pre-wrap" }}>
                          <button
                            className="btn btn-link btn-sm text-left"
                            title="Download File"
                            onClick={() => handleDownloadFile(item)}
                          >
                            {item.docName}
                          </button>
                        </td>
                        <td>{item.remarks}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-link p-0"
                            onClick={() =>
                              handleDeleteDocument(item.docId)
                            }
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey={5}
          title={"Internal"}
          className="p-3 border"
          tabClassName="h4"
        >
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn btn-sm btn-link pl-0"
                onClick={handleDocumentLoad}
              >
                Refresh
              </button>
            </div>
            <div className="col-md-12">
              <div
                className="table-responsive"
              //style={{ maxHeight: "500px", overflow: "auto" }}
              >
                <table className="table table-striped align-items-center table-flush border">
                  <thead className="thead-light-green">
                    <tr>
                      <th>S.No.</th>
                      <th>Date & Time</th>
                      <th>Document Name</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sentDocuments
                      .filter(
                        (x) =>
                          x.uploadType !== "client" &&
                          x.uploadType !== "reporting" &&
                          x.uploadType !== "project" &&
                          x.updType === "internal"
                      )
                      .map((item, key) => (
                        <tr>
                          <td>{key + 1}</td>
                          <td>
                            <Moment format="MMM Do YYYY, h:mm a">
                              {item.dateTime}
                            </Moment>
                          </td>
                          <td style={{ whiteSpace: "pre-wrap" }}>
                            <button
                              className="btn btn-link btn-sm text-left"
                              title="Download File"
                              onClick={() => handleDownloadFile(item)}
                            >
                              {item.docName}
                            </button>
                          </td>
                          <td>{item.remarks}</td>

                          <td>
                            <button
                              className="btn btn-sm btn-link p-0"
                              onClick={() => handleDeleteDocument(item.docId)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Tab>

        <Tab
          eventKey={3}
          title={"Deleted"}
          className="p-3 border"
          tabClassName="h4"
        >
          <div className="row">
            <div className="col-md-12">
              <div
                className="table-responsive"
              //style={{ maxHeight: "500px", overflow: "auto" }}
              >
                <table className="table table-striped align-items-center table-flush border">
                  <thead className="thead-light-green">
                    <tr>
                      <th>S.No.</th>
                      <th>Date & Time</th>
                      <th>From</th>
                      <th>Document Name</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deletedDocumnets.map((item, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>
                          {
                            <Moment format="MMM Do YYYY, h:mm a">
                              {item.dateTime}
                            </Moment>
                          }
                        </td>
                        <td>
                          {item.uploaderName}
                          <br />
                          {item.uploaderEmail}
                        </td>
                        <td style={{ whiteSpace: "pre-wrap" }}>
                          <button
                            className="btn btn-link btn-sm text-left"
                            title="Download File"
                            onClick={() => handleDownloadFile(item)}
                          >
                            {item.docName}
                          </button>
                        </td>
                        <td>{item.remarks}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-link p-0"
                            onClick={() =>
                              handleRollbackDocument(item.docId)
                            }
                          >
                            Rollback
                          </button>
                          <button
                            className="btn btn-sm btn-link p-0"
                            onClick={() =>
                              handlePurgeDocument(item.docId)
                            }
                          >
                            Purge
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey={6}
          title={"History"}
          className="p-3 border"
          tabClassName="h4"
        >
          <div
            className="row"
          //style={{ maxHeight: "500px", overflow: "auto" }}
          >
            <div className="col-md-4">
              <div className="card shadow-none border">
                <div className="card-header p-3">
                  <span className="h4">Received</span>
                </div>
                <div className="card-body p-3">
                  <table>
                    <tbody>
                      {receviedDocumnets.map((doc) => (
                        <tr>
                          <td>
                            <Moment format="MMM Do YYYY">
                              {doc.dateTime}
                            </Moment>
                          </td>
                          <td>
                            <button
                              className="btn btn-link btn-sm text-left"
                              title="Download File"
                              onClick={() => handleDownloadFile(doc)}
                            >
                              {doc.docName}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow-none border">
                <div className="card-header p-3">
                  <span className="h4">Sent</span>
                </div>
                <div className="card-body p-3">
                  <table>
                    <tbody>
                      {sentDocuments
                        .filter(
                          (x) =>
                            x.uploadType !== "client" &&
                            x.uploadType !== "reporting" &&
                            x.uploadType !== "project" &&
                            x.updType !== "internal" &&
                            x.sentStatus === true
                        )
                        .map((doc) => (
                          <tr>
                            <td>
                              <Moment format="MMM Do YYYY">
                                {doc.dateTime}
                              </Moment>
                            </td>
                            <td>
                              <button
                                className="btn btn-link btn-sm text-left"
                                title="Download File"
                                onClick={() => handleDownloadFile(doc)}
                              >
                                {doc.docName}
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow-none border">
                <div className="card-header p-3">
                  <span className="h4">Internal</span>
                </div>
                <div className="card-body p-3">
                  <table>
                    <tbody>
                      {sentDocuments
                        .filter(
                          (x) =>
                            x.updType === "internal" &&
                            x.uploadType !== "reporting" &&
                            x.uploadType !== "project"
                        )
                        .map((doc) => (
                          <tr>
                            <td>
                              <Moment format="MMM Do YYYY">
                                {doc.dateTime}
                              </Moment>
                            </td>
                            <td>
                              <button
                                className="btn btn-link btn-sm text-left"
                                title="Download File"
                                onClick={() => handleDownloadFile(doc)}
                              >
                                {doc.docName}
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow-none border">
                <div className="card-header p-3">
                  <span className="h4">Messages</span>
                </div>
                <div className="card-body p-3">
                  <table>
                    <tbody>
                      {messagesDocs.map((doc) => (
                        <tr>
                          <td>
                            <Moment format="MMM Do YYYY">
                              {doc.dateTime}
                            </Moment>
                          </td>
                          <td>
                            <button
                              className="btn btn-link btn-sm text-left"
                              title="Download File"
                              onClick={() => handleDownloadFile(doc)}
                            >
                              {doc.docName}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow-none border">
                <div className="card-header p-3">
                  <span className="h4">Project Engagement</span>
                </div>
                <div className="card-body p-3">
                  <table>
                    <tbody>
                      {projDocs.map((doc) => (
                        <tr>
                          <td>
                            <Moment format="MMM Do YYYY">
                              {doc.dateTime}
                            </Moment>
                          </td>
                          <td>
                            <button
                              className="btn btn-link btn-sm text-left"
                              title="Download File"
                              onClick={() => handleDownloadFile(doc)}
                            >
                              {doc.docName}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow-none border">
                <div className="card-header p-3">
                  <span className="h4">Agency/Client</span>
                </div>
                <div className="card-body p-3">
                  <table>
                    <tbody>
                      {clientDocs.map((doc) => (
                        <tr>
                          <td>
                            <Moment format="MMM Do YYYY">
                              {doc.dateTime}
                            </Moment>
                          </td>
                          <td>
                            <button
                              className="btn btn-link btn-sm text-left"
                              title="Download File"
                              onClick={() => handleDownloadFile(doc)}
                            >
                              {doc.docName}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>

      <CandidateReqDoc
        docShowHide={docShowHide}
        handleDocShowHide={handleDocShowHide}
        workItem={workflowTask}
        sendType={sendType}
        uploadType={uploadType}
      ></CandidateReqDoc>

      <InternalReviewPopup
        showHide={showHide}
        handleModalShowHide={handleModalShowHide}
        handleSubmit={handleSubmit}
        selectedItem={selectedObj}
        handleEditComment={handleEditComment}
      ></InternalReviewPopup>


      <ViewFormModal
        viewFormShowHide={ViewformShowHide}
        handleViewForm={handleViewFormShowHide}
        workflowTask={workflowTask}
        selectedForm={selectedForm}
      ></ViewFormModal>
     
      <UploadOther
        handleOtherDocShowHide={handleOtherDocShowHide}
        otherDocShowHide={otherDocShowHide}
        workflowTask={workflowTask}
      />

      {contractShowHide && <GenerateContract
        showHide={contractShowHide}
        handleModalShowHide={handleContractShowHide}
        workflowTask={workflowTask}
        app={app}
        applicantObj={applicantObj}
      ></GenerateContract>}
    </React.Fragment>
  );
}

export default Document;
