import React, { useEffect, useState } from "react";
import { Modal, Tab, Tabs,Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getDdrmsRepositoryDocuments, loadDdrmsRepositoryDocs } from "../../store/ddrmsDocument";
import DataMapping from "./DataMapping";

const GenerateContract = (props) => {
    const { showHide, handleModalShowHide, workflowTask, app, applicantObj } = props;

    const [repositoryDocs, setRepositoryDocs] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [selectedDocId, setSelectedDocId] = useState(null);
    const [selectedKey, setSelectedKey] = useState("home");

    const ddrmsRepositoryDocumentsSelector = useSelector(getDdrmsRepositoryDocuments);

    const dispatch = useDispatch();

    useEffect(() => {
        if (workflowTask?.ciid > 0) {
            dispatch(loadDdrmsRepositoryDocs(workflowTask.ciid));
        }
    }, []);

    useEffect(() => {
        if(ddrmsRepositoryDocumentsSelector.length > 0){
            const filterDocx=ddrmsRepositoryDocumentsSelector.filter(x=>x.fileExtension === "docx");
            setRepositoryDocs(filterDocx);
        }
    }, [ddrmsRepositoryDocumentsSelector]);

    const handleResetSelection = () => {
        setSelectedDocId(0);
        setSelectedDoc(null);
    }

    const handleSelection = (item) => {
        setSelectedDoc(item);
        setSelectedDocId(item.id);
    };

    const handleCloseMapping = () => {
        setSelectedDocId(0);
        setSelectedDoc(null);
        setSelectedKey("home")
    }

    const handleSelectedKey = (key) => {
        setSelectedKey(key)
    }

    return <>

        <Modal show={showHide} size="xl">
            <Modal.Header closeButton onHide={handleModalShowHide}>
                <Modal.Title>Generate Contract</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    onSelect={handleSelectedKey}
                    activeKey={selectedKey}
                    id="uncontrolled-tab-example"
                    className="h3 border border-right-0 border-left-0 border-top-0 font-weight-normal"
                >
                    <Tab eventKey="home" title="DDRMS Repository" tabClassName="page-tab border border-left-0 border-top-0">
                        <Tab.Content className="border p-3 border-top-0">
                            <div className="text-danger">Note : Only Supported Docx file</div>
                            <button className="btn btn-sm btn-link pl-0" onClick={handleResetSelection}>Reset Selection</button>
                            <div className="table-responsive bg-white"
                                style={{ maxHeight: "500px", overflow: "auto" }}>
                                <table className="table align-items-center table-flush table-hover table-striped border">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Select</th>
                                            <th>ID</th>
                                            <th>Contract Template</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {repositoryDocs.map((item) => (
                                            <tr>
                                                <td>
                                                    <div className="custom-control custom-radio">
                                                        <input
                                                            className="custom-control-input"
                                                            type="radio"
                                                            id={item.id}
                                                            name="role"
                                                            onChange={() => handleSelection(item)}
                                                            value={selectedDocId}
                                                            checked={selectedDocId === item.id}
                                                            disabled={item.fileExtension !== "docx"}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor={item.id}
                                                        >
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {/* <Pagination
                    currentPage={currentPage}
                    pageSize={pageSize}
                    itemsCount={rowsCount}
                    onPageChange={handlePageChange}
                    startIndex={startIndex}
                    endIndex={endIndex}
                  ></Pagination> */}
                        </Tab.Content>
                    </Tab>

                    <Tab eventKey="status" title="Generate" tabClassName="page-tab border border-left-0 border-right-0 border-top-0">
                        <Tab.Content className="border p-3 border-top-0">
                            {selectedDocId > 0 ?
                                <DataMapping workflowTask={workflowTask}
                                    app={app}
                                    selectedObj={selectedDoc}
                                    applicantObj={applicantObj}
                                    handleCloseMapping={handleCloseMapping} /> :
                                <h3 className="text-danger mb-0"> Alert : Please select the Document first</h3>
                            }
                        </Tab.Content>
                    </Tab>

                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-outline-danger" onClick={handleModalShowHide}>
                <i class="fa fa-times" aria-hidden="true"></i> Close
                </button>
            </Modal.Footer>
        </Modal>
    </>

}

export default GenerateContract;