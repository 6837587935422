import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import http from "../../services/HttpService";
import config from "../../config.json";
import PageHeader from "../PageHeader";
import PageSizeOptions from "../PageSizeOptions";
import AddCandidate from "./AddCandidate";
import CandidateReqDoc from "./CandidateReqDoc";
import Moment from "react-moment";
import ActiveDeactiveHistory from "../SystemAdministration/ManagePartner/ActiveDeactiveHistory";
import { paginate, calculateIndex } from "../../utlis/paginate";
import ReminderAlertMessage from "../ReminderAlertMessage";
import Swal from "sweetalert2";
import ViewCandidate from "./ViewCandidate";
import Pagination from "../../comman/pagination";
import { ButtonToolbar, PageItem, Spinner, Tab, Tabs } from "react-bootstrap";
import AddEditDuration from "./AddEditDuration";
import authService from "../../services/authService";
import TableLoader from "../../comman/TableLoader";
import {
  loadCandidates, getAllCandidates,
  getFilterAllCandidates,
  loadCandidatesByFilter, SendReminder, getReminderStatus,
  loadAllCandidatesByFilter
} from "../../app/store/candidate";
import { getCurrentUser } from "../../app/store/auth";
import { getFieldData, getFormatedNumber } from "../../services/appService";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";
import { getAlert } from "../../services/appAlerts";
import AddEditRates from "./AddEditRates";

export default function ListCondidate(props) {

  const param = useParams();
  let type = param?.type;

  let candidateType = "";
  const filterTypes = type.split('-')
  if (filterTypes.length > 1) {
    candidateType = filterTypes[1];
    type = filterTypes[0];
  }

  let route = [
    { name: "Onboarding and Contracts", url: "" }
  ];
  let pgTitle = "Candidates";
  if (type === "partner") {
    route = [
      { name: "System Administration", url: "" },
      { name: "Manage Entities", url: "" },
    ];
  }

  if (type === "timelog" || type === "invoice") {
    route = [{ name: "Invoices", url: "" }];
    if (type === "timelog") pgTitle = "Time Log Management";
    if (type === "invoice") pgTitle = "Invoices and Payments";
  }

  const candidateEntity = [
    { id: 1, name: "C2C Basis" },
    { id: 2, name: "1099 Basis" },
    { id: 3, name: "W2 Basis" },
  ];

  const currentUser = getCurrentUser();

  const [applicantType, setApplicantType] = useState("candidate");
  const [actionType, setActionType] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [rowsCount, setRowsCount] = useState(0);
  const [applicantCount, setApplicantCount] = useState(0);
  const [allTypesCount, setAllTypesCount] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);

  const [showHide, setShowHide] = useState(false);
  const [logShowHide, setLogShowHide] = useState(false);
  const [remainderMsgShowHide, setRemainderMsgShowHide] = useState(false);
  const [viewShowHide, setViewShowHide] = useState(false);
  const [durationShowHide, setDurationShowHide] = useState(false);
  const [docShowHide, setDocShowHide] = useState(false);

  const [selectedObj, setSelectedObj] = useState("");
  const [viewType, setViewType] = useState("");
  const [editDurType, setEditDurType] = useState("");

  const [canId, setCanId] = useState("");
  const [canName, setCanName] = useState("");
  const [canEmail, setCanEmail] = useState("");
  const [canEntity, setCanEntity] = useState("");

  const [dataType, setDataType] = useState("");
  const [selectedCandidateId, setSelectedCandidateId] = useState(0);
  const [selectedKey, setSelectedKey] = useState(-1);
  const [selectedTabKey, setSelectedTabKey] = useState("home");

  const allCandidates = useSelector(getAllCandidates);
  const filterAllCandidates = useSelector(getFilterAllCandidates);
  const remiderStatus = useSelector(getReminderStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setCurrentRoute(route));
    dispatch(setCurrentPageTitle(pgTitle));
    if (candidateType === "c2c")
      setCanEntity("C2C Basis");

    if (candidateType === "w2")
      setCanEntity("W2 Basis");

    if (candidateType === "1099")
      setCanEntity("1099 Basis");

  }, [])

  useEffect(() => {
    setSelectedTabKey("home");
    setSelectedKey(-1);
    setSelectedCandidateId(0);
    setSelectedObj("")
  }, [type])

  useEffect(() => {
    handleLoadFilterCandidates(pageSize, 1);
  }, [candidateType]);

  const handleLoadFilterCandidates = (pages, index) => {
    if (candidateType === "c2c")
      dispatch(loadCandidates(pages, index, applicantType + "-C2C Basis"));

    if (candidateType === "w2")
      dispatch(loadCandidates(pages, index, applicantType + "-W2 Basis"));

    if (candidateType === "1099")
      dispatch(loadCandidates(pages, index, applicantType + "-1099 Basis"));

    if (candidateType === "")
      dispatch(loadCandidates(pages, index, applicantType));
  }

  const handleAddEditCandidate = () => {
    if (dataType === "filter")
      handleFilter();
    else
      handleLoadFilterCandidates(pageSize, currentPage);

    //setSelectedCandidateId(0);
    //setSelectedTabKey("home")
  }

  useEffect(() => {
    if (allCandidates.length > 0) {
      if (allCandidates.length > 0)
        setRowsCount(allCandidates[0].totalApplicants);

      setCandidates(allCandidates);
    }
  }, [allCandidates]);

  useEffect(() => {
    setPageSize(5);
    setCurrentPage(1);
    getPagedData(filterAllCandidates, 1, 5);
  }, [filterAllCandidates]);

  useEffect(() => {
    if (remiderStatus === "success") {
      getAlert("", "Reminder Sent Successfully", "success");
      if (dataType === "filter")
        getPagedData(filterAllCandidates, currentPage, pageSize);
      else
        handleLoadFilterCandidates(pageSize, currentPage);
    }
  }, [remiderStatus]);

  const handlePageChange = (page) => {
    const { startIndex: start, endIndex: end } = calculateIndex(page, startIndex, endIndex);
    setCurrentPage(page);
    setStartIndex(start);
    setEndIndex(end);
    if (dataType === "filter")
      getPagedData(filterAllCandidates, page, pageSize)
    else
      handleLoadFilterCandidates(pageSize, page);
  };


  const handlePageSizeChanged = ({ currentTarget }) => {
    setPageSize(currentTarget.value);
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(5);
    if (dataType === "filter")
      getPagedData(filterAllCandidates, currentPage, currentTarget.value);
    else
      handleLoadFilterCandidates(currentTarget.value, currentPage);
  };

  const handleModalShowHide = (obj, type) => {
    if (obj === "save") {
      if (dataType === "filter")
        handleFilter();
      else
        dispatch(loadCandidates(pageSize, currentPage, applicantType));

    } else {
      setShowHide(!showHide);
      setSelectedObj(obj);
      setActionType(type);
    }
  };

  const handleViewShowHide = (obj, view, key) => {
    setSelectedObj(obj);
    setEditDurType("")

    if (viewType === view) {
      setViewShowHide(false);
      setSelectedKey(-1);
      setViewType("");
    }
    else {
      setViewShowHide(true);
      setViewType(view);
      setSelectedKey(key)
    }
  };

  const handleLogShowHide = (item, actionType) => {
    if (item === "save") {
      setLogShowHide(false);
      setSelectedObj("");
      setActionType(actionType);

      if (dataType === "filter")
        handleFilter();
      else
        dispatch(loadCandidates(pageSize, currentPage, applicantType));

    } else {
      setLogShowHide(!logShowHide);
      setSelectedObj(item);
      setActionType(actionType);

    }
  };

  const getPagedData = (list, currentPage, pageSize) => {
    const items = paginate(list, currentPage, pageSize);
    if (items.length > 0)
      setRowsCount(items[0].totalApplicants);

    setCandidates(items);
  };

  const handleRemainder = async (id) => {
    dispatch(SendReminder(id));
  };

  const handleRemainderMessage = () => {
    setRemainderMsgShowHide(!remainderMsgShowHide)
  };

  const handleFilter = async () => {
    setDataType("filter");
    const frmBody = new FormData();
    frmBody.append("id", canId);
    frmBody.append("name", canName);
    frmBody.append("email", canEmail);
    frmBody.append("entity", canEntity);
    frmBody.append("type", applicantType);
    frmBody.append("filterType", "search");
    dispatch(loadAllCandidatesByFilter(frmBody));
  };

  const handleFilterCancel = async () => {
    setDataType("");
    setCanId("");
    setCanName("");
    setCanEmail("");
    setCanEntity("");
    setCurrentPage(1);
    setPageSize(5);
    dispatch(loadCandidates(5, 1, applicantType))
  };

  const handleDurationAndRateShowHide = (obj, type, key) => {

    if (type === "save") {
      if (dataType === "filter") handleFilter();
      else dispatch(loadCandidates(pageSize, currentPage, applicantType))
    }

    setSelectedObj(obj);
    setViewShowHide(false);
    if (editDurType === type) {
      setEditDurType("");
      setSelectedKey(-1)
    }
    else {
      setEditDurType(type);
      setSelectedKey(key)
    }
  };

  const checkTerminateDuration = (item, key) => {
    if (item.duration === null) return "";
    if (item.duration?.terminateStatus)
      return (
        <>
          <br />
          <button
            className="btn btn-sm mt-2 btn-danger"
            onClick={() => handleDurationAndRateShowHide(item, "terminated", key)}
          >
            Terminated
          </button>
        </>
      );
    const newStartDate = new Date();
    const newEndDate = new Date(item.duration?.endDate);
    const one_day = 1000 * 60 * 60 * 24;
    let result;
    result = Math.ceil(
      (newEndDate.getTime() - newStartDate.getTime()) / one_day
    );
    if (result < 45 && result > 0)
      return (
        <>
          <br />
          <button className="btn btn-sm mt-2 invalid text-white">
            Expiring Soon...
          </button>
        </>
      );

    if (result < 0)
      return (
        <>
          <br />
          <button className="btn btn-sm mt-2 invalid text-white">
            Expired
          </button>
        </>
      );
    return "";
  };

  const handleViewApplication = (item, app) => {
    navigate("/CustomerApps", {
      state: {
        item: item,
        currentUser: currentUser,
        type: type,
        selectedApp: app.applicationId,
        module: "Candidate",
        appType: "Candidate",
      }
    })
  }

  const handleViewCandidateDetails = (item, type) => {
    navigate("/CandidateDetails", { state: { item, type } });
  }

  const handleResetSelection = () => {
    setSelectedCandidateId(0);
    setSelectedObj(null);
  }

  const handleSelectCandidate = (item) => {
    setSelectedCandidateId(item.performerid)
    setSelectedObj(item);
  }

  const handlePermissionShowHide2 = () => {
    dispatch(loadCandidates(pageSize, currentPage, applicantType))
    setSelectedTabKey("home")
  };

  return (
    <React.Fragment>

      <div className="col-md-12">
        <div className="card mb-4 shadow-none">
          <div className="card-header border-0 p-2">
            <div className="row">
              <div className="col-md-4">
                <PageSizeOptions
                  pageSize={pageSize}
                  onChange={handlePageSizeChanged}
                ></PageSizeOptions>
              </div>
              <div className="col-md-4 text-center">
                <h5 className="mb-0">
                  {allTypesCount.map((item, key) => (
                    <React.Fragment>
                      {key > 0 && ", "}{item.name} :{" "}
                      <span className="font-weight-bolder">{item.count}</span>
                    </React.Fragment>
                  ))}
                </h5>
              </div>
              <div className="col-md-4 text-right">
                <button
                  className="btn btn-sm btn-outline-default btn-min-width"
                  data-toggle="collapse"
                  data-target="#collapseExample"
                >
                  <i className="fa fa-search" aria-hidden="true"></i> Search
                </button>
              </div>
              <div className="col-md-12">
                <div className="collapse p-2 bg-secondary border mt-2" id="collapseExample">
                  <div className="row">
                    <div className="col-md-1">
                      <div className="form-group">
                        <label
                          htmlFor="canId"
                          className="form-control-label"
                        >
                          IPCS ID
                        </label>
                        <input
                          type="text"
                          name="canId"
                          id="canId"
                          className="form-control"
                          placeholder="ID"
                          onChange={({ currentTarget }) => setCanId(currentTarget.value)}
                          value={canId}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="canName"
                          className="form-control-label"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          name="canName"
                          id="canName"
                          className="form-control"
                          placeholder="Name"
                          onChange={({ currentTarget }) => setCanName(currentTarget.value)}
                          value={canName}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="canEmail"
                          className="form-control-label"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          name="canEmail"
                          id="canEmail"
                          className="form-control"
                          placeholder="Email"
                          onChange={({ currentTarget }) => setCanEmail(currentTarget.value)}
                          value={canEmail}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          htmlFor="canEntity"
                          className="form-control-label"
                        >
                          Entity type
                        </label>
                        <select
                          name="canEntity"
                          id="canEntity"
                          className="form-control"
                          disabled={candidateType !== ""}
                          onChange={({ currentTarget }) => setCanEntity(currentTarget.value)}
                          value={canEntity}
                        >
                          <option value="">Select</option>
                          {candidateEntity.map((item) => (
                            <option value={item.name}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12 text-center ">
                      <button
                        className="btn-sm btn-outline-default mr-2"
                        onClick={() => handleFilter()}
                      >
                        <i className="fa fa-search mr-2" aria-hidden="true"></i>Filter
                      </button>
                      <button
                        className="btn-sm btn-outline-default mr-2"
                        onClick={handleFilterCancel}
                      >
                        <i className="fa fa-refresh mr-2" aria-hidden="true"></i>Refresh
                      </button>
                      <button
                        className="btn-sm btn-outline-danger mr-2"
                        data-toggle="collapse"
                        data-target="#collapseExample"
                        onClick={handleFilterCancel}
                      >
                        <i className="fa fa-close mr-2" aria-hidden="true"></i>Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-2">
            <Tabs
              activeKey={selectedTabKey}
              onSelect={(key) => setSelectedTabKey(key)}
              id="uncontrolled-tab-example"
              className="h3 border border-right-0 border-left-0 border-top-0 font-weight-normal"
            >
              <Tab eventKey="home" title="Candidates"
                tabClassName="page-tab border border-left-0 border-top-0">
                <Tab.Content className="border border-top-0">
                  <div className="table-responsive">
                    {/* <button className="btn btn-sm btn-link" onClick={handleResetSelection}>Reset Selection</button> */}
                    <table className="table align-items-center table-flush table-hover table-striped mb-2">
                      <thead className="thead-light">
                        <tr>
                          {type !== "timelog" && type !== "invoice" &&
                            <th>Select <span style={{ cursor: "pointer" }} onClick={handleResetSelection}>
                              <i className="fa fa-refresh ml-2 text-primary" aria-hidden="true"></i>
                            </span></th>
                          }

                          <th>
                            Candidate #
                            <br />IPCS ID
                          </th>
                          <th>Candidate Details</th>
                          <th>Onboarding</th>
                          <th>Entity Type</th>
                          {type !== "partner" && (
                            <React.Fragment>
                              <th>Subcontractor</th>
                              <th>Recruiter</th>
                              <th>Agency/Client</th>
                              <th>MSP Mode</th>
                              <th>Tech Fee</th>
                              <th>
                                Duration
                                <br />
                                Modify Rate
                              </th>
                            </React.Fragment>
                          )}
                          {type !== "timelog" && type !== "invoice" && (
                            <>
                              <th>
                                Onboarding
                                <br />
                                Status
                              </th>
                              <th>Status</th>
                            </>
                          )}
                          {type === "timelog" && (
                            <th>
                              Time Log
                              <br />
                              Management
                            </th>
                          )}
                          {type === "invoice" && (
                            <th>
                              Invoices and <br />
                              Payments
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {candidates.map((item, key) => (
                          <>
                            <tr className={`${(selectedKey !== key && selectedKey !== -1) ? "d-none" : ""}`}>

                              {type !== "timelog" && type !== "invoice" &&
                                <td>
                                  <div className="custom-control custom-radio">
                                    <input
                                      className="custom-control-input"
                                      type="radio"
                                      id={item.performerid}
                                      name="recruiter"
                                      onChange={() => handleSelectCandidate(item)}
                                      value={selectedCandidateId}
                                      checked={selectedCandidateId === item.performerid}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={item.performerid}
                                    >
                                    </label>
                                  </div>
                                </td>}

                              <td>
                                {item.performerid}
                                <br />
                                {item.maxloans}
                              </td>
                              <td>
                                {/* <button
                              className="btn btn-sm btn-link p-0 text-underline text-uppercase"
                              onClick={() =>
                                handleViewShowHide(item, "candidate",key)
                              }
                            >
                              {item.performername} {item.lastName}
                            </button> */}
                                <span className="text-uppercase">{item.performername} {item.lastName}</span>
                                <br />
                                {item.performeremail}
                                <br />
                                {item.contactNo && getFormatedNumber(item.contactNo)}
                                <br />
                                <Moment format="MMM Do YYYY, h:mm">
                                  {item.addedDateTime}
                                </Moment>
                              </td>
                              <td>
                                <table className="table table-bordered">
                                  {item.applications
                                    .filter((x) => x.processType === 1)
                                    .map((app) => (
                                      <React.Fragment key={app.applicationId}>
                                        <tr>
                                          <td className="p-1">
                                            {type === "application" ? (
                                              <button
                                                onClick={() => handleViewApplication(item, app)}
                                                className="btn btn-sm btn-link p-0"
                                                title="Application"
                                              >
                                                {app.applicationId} - {app.applicationType}
                                              </button>
                                            ) : (
                                              <span className="text-dark">
                                                {app.applicationId} - {app.applicationType}
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td width={"10%"}>
                                            {app.appSubmitted && (
                                              <span className="badge badge-success rounded-0 btn-min-width">
                                                Complete
                                              </span>
                                            )}

                                            {(app.appSubmitted === "" || app.appSubmitted === null) && (
                                              <span className="badge badge-success rounded-0 bg-yellow btn-min-width">
                                                Review
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    ))}
                                </table>

                              </td>

                              <td>
                                {getFieldData(
                                  item.performerData,
                                  "candidateonboardingform",
                                  "CandidateEntity"
                                ) === "1099 Basis" && "1099"}
                                {getFieldData(
                                  item.performerData,
                                  "candidateonboardingform",
                                  "CandidateEntity"
                                ) === "C2C Basis" && "C2C"}
                                {getFieldData(
                                  item.performerData,
                                  "candidateonboardingform",
                                  "CandidateEntity"
                                ) === "W2 Basis" && "W2"}
                                <br />
                                {getFieldData(
                                  item.performerData,
                                  "candidateonboardingform",
                                  "CandidateEntity"
                                ) === "1099 Basis" &&
                                  type !== "partner" && (
                                    <React.Fragment>
                                      Rate :{" "}
                                      <b>
                                        $
                                        {item.rates !== null &&
                                          parseFloat(
                                            item.rates.candidateRate
                                          ).toFixed(2)}
                                        /Hr
                                      </b>
                                    </React.Fragment>
                                  )}
                                {getFieldData(
                                  item.performerData,
                                  "candidateonboardingform",
                                  "CandidateEntity"
                                ) === "W2 Basis" && (
                                    <React.Fragment>
                                      {getFieldData(
                                        item.performerData,
                                        "candidateonboardingform",
                                        "w9_Type"
                                      )}
                                      {type !== "partner" && (
                                        <React.Fragment>
                                          <br /> Rate :{" "}
                                          <b>
                                            $
                                            {item.rates !== null &&
                                              parseFloat(
                                                item.rates.candidateRate
                                              ).toFixed(2)}
                                            /Hr
                                          </b>
                                          <br />
                                          Per Diem Rate :{" "}
                                          <b>
                                            {getFieldData(
                                              item.performerData,
                                              "candidateonboardingform",
                                              "Per_Diem_Rate"
                                            )}
                                          </b>
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  )}
                              </td>
                              {type !== "partner" && (
                                <React.Fragment>
                                  <td>
                                    {getFieldData(
                                      item.performerData,
                                      "candidateonboardingform",
                                      "CandidateEntity"
                                    ) === "C2C Basis" ? (
                                      <React.Fragment>
                                        <button
                                          className="btn btn-sm btn-link p-0 text-uppercase"
                                          onClick={() =>
                                            handleViewShowHide(
                                              item,
                                              "subcontractor",
                                              key
                                            )
                                          }
                                        >
                                          {item.subcontractor?.label}
                                        </button>
                                        <br />
                                        ID : <b>{item.validId}</b>, Rate :{" "}
                                        <b>
                                          $
                                          {item.rates !== null &&
                                            parseFloat(
                                              item.rates.subcontractorRate
                                            ).toFixed(2)}
                                          /Hr
                                        </b>
                                        <br />
                                        {item.subcontractor !== null &&
                                          item.subcontractor.email}
                                        <br />
                                        {item?.subcontractor && (
                                          <Moment format="MMM Do YYYY, h:mm">
                                            {item.subcontractor.createdDate}
                                          </Moment>
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      "NA"
                                    )}
                                  </td>
                                  <td>
                                    {item.recruiter !== null ? (
                                      <React.Fragment>
                                        <button
                                          className="btn btn-sm btn-link p-0 text-uppercase"
                                          onClick={() =>
                                            handleViewShowHide(
                                              item,
                                              "recruiter",
                                              key
                                            )
                                          }
                                        >
                                          {item.recruiter?.label}
                                        </button>
                                        <br />
                                        ID : <b>{item.recruiter?.fuid}</b>, Rate :{" "}
                                        <b>
                                          $
                                          {item.rates !== null &&
                                            parseFloat(
                                              item.rates.recruiterRate
                                            ).toFixed(2)}
                                          /Hr
                                        </b>
                                        <br />
                                        {item.recruiter.email}
                                        <br />
                                        <Moment format="MMM Do YYYY, h:mm">
                                          {item.recruiter.createdDate}
                                        </Moment>
                                      </React.Fragment>
                                    ) : (
                                      "NA"
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-link btn-sm p-0"
                                      onClick={() =>
                                        handleViewShowHide(item, "agency", key)
                                      }
                                    >
                                      {getFieldData(
                                        item.performerData,
                                        "projectengagement",
                                        "AgencyName"
                                      )}
                                    </button>
                                    <br />
                                    Rate :{" "}
                                    <b>
                                      $
                                      {item.rates !== null &&
                                        parseFloat(item.rates.agencyRate).toFixed(
                                          2
                                        )}
                                      /Hr
                                    </b>
                                  </td>
                                  <td>
                                    {getFieldData(
                                      item.performerData,
                                      "candidateonboardingform",
                                      "Msp_Mode"
                                    ) !== "" && (
                                        <>
                                          <button
                                            className="btn btn-sm btn-link p-0"
                                            onClick={() =>
                                              handleViewShowHide(item, "msp", key)
                                            }
                                          >
                                            {getFieldData(
                                              item.performerData,
                                              "candidateonboardingform",
                                              "Msp_Mode"
                                            )}
                                          </button>
                                          <br />
                                        </>
                                      )}
                                    State/MSP
                                    <br /> Admin Fee :{" "}
                                    <b>
                                      {getFieldData(
                                        item.performerData,
                                        "projectengagement",
                                        "State_Admin_Fee"
                                      )}
                                      %
                                    </b>

                                  </td>
                                  <td>

                                    <b>
                                      $
                                      {getFieldData(
                                        item.performerData,
                                        "projectengagement",
                                        "Tech_Fee"
                                      )}
                                      /Hr
                                    </b>
                                  </td>
                                  <td>
                                    {item.duration !== null && (
                                      <React.Fragment>
                                        Start :{" "}
                                        <Moment format="MMM Do YYYY">
                                          {item.duration.startDate}
                                        </Moment>
                                        <br />
                                        End :{" "}
                                        <Moment format="MMM Do YYYY">
                                          {item.duration.endDate}
                                        </Moment>
                                      </React.Fragment>
                                    )}
                                    <br />
                                    <button
                                      className="btn btn-sm btn-link p-0"
                                      onClick={() =>
                                        handleDurationAndRateShowHide(
                                          item,
                                          "duration", key
                                        )
                                      }
                                    >
                                      Edit Duration
                                    </button>
                                    <button
                                      className="btn btn-sm btn-link p-0"
                                      onClick={() =>
                                        handleDurationAndRateShowHide(item, "rate", key)
                                      }
                                    >
                                      Modify Rate
                                    </button>
                                    {checkTerminateDuration(item, key)}
                                  </td>
                                </React.Fragment>
                              )}
                              {type !== "timelog" && type !== "invoice" && (
                                <>
                                  <td>
                                    {item.onboardingStatus ? "Done" : "Pending"}
                                  </td>
                                  <td>
                                    {item.isActive === null && (
                                      <Spinner animation="border" role="status">
                                        <i className="fa fa-spinner fa-spin fa-2x fa-fw mb-2"></i>
                                      </Spinner>
                                    )}
                                    <React.Fragment>
                                      {item.isActive === true && (
                                        <>
                                          <i
                                            className="fa fa-check text-success"
                                            style={{ fontSize: "15px" }}
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Activated
                                          {item.isActiveDateTime && (
                                            <>
                                              <br />
                                              {new Date(item.isActiveDateTime).toLocaleString()}
                                            </>
                                          )}
                                        </>
                                      )}
                                      {item.isActive === false && (
                                        <>
                                          <i
                                            className="fa fa-times text-danger"
                                            style={{ fontSize: "15px" }}
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Deactivated
                                          {item.isActiveDateTime && (
                                            <>
                                              <br />
                                              {new Date(item.isActiveDateTime).toLocaleString()}
                                            </>
                                          )}
                                        </>
                                      )}
                                      {item.isActive === null && (
                                        <>
                                          To Activate
                                          {item.isActiveDateTime && (
                                            <>
                                              <br />
                                              {new Date(item.isActiveDateTime).toLocaleString()}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </React.Fragment>
                                  </td>

                                </>
                              )}
                              {(type === "timelog" || type === "invoice") && (
                                <td>
                                  <button
                                    onClick={() => handleViewCandidateDetails(item, type)}
                                    className="btn btn-sm btn-link p-0"
                                  >
                                    Go
                                  </button>
                                </td>
                              )}
                            </tr>
                            <tr className={`${selectedKey === key ? "" : "d-none"}`}>
                              {viewShowHide && editDurType === "" && selectedKey === key &&
                                <td colSpan={7} className="bg-white">
                                  <ViewCandidate
                                    handleDurationShowHide={handleDurationAndRateShowHide}
                                    viewShowHide={viewShowHide}
                                    handleViewShowHide={handleViewShowHide}
                                    selectedObj={selectedObj}
                                    viewType={viewType}
                                  ></ViewCandidate>
                                </td>
                              }
                              {editDurType === "duration" && selectedKey === key && <td colSpan={7} className="bg-white">
                                <AddEditDuration
                                  handleDurationShowHide={handleDurationAndRateShowHide}
                                  selectedObj={selectedObj}
                                  editType={editDurType}
                                ></AddEditDuration>
                              </td>}
                              {editDurType === "rate" && selectedKey === key && <td colSpan={7} className="bg-white">
                                <AddEditRates
                                  handleDurationShowHide={handleDurationAndRateShowHide}
                                  selectedObj={selectedObj}
                                  editType={editDurType}
                                ></AddEditRates>
                              </td>}
                            </tr>
                          </>
                        ))}

                      </tbody>
                    </table>

                  </div>
                  <Pagination
                    currentPage={currentPage}
                    pageSize={pageSize}
                    itemsCount={rowsCount}
                    onPageChange={handlePageChange}
                    startIndex={startIndex}
                    endIndex={endIndex}
                  ></Pagination>

                </Tab.Content>
              </Tab>
              {type === "application" &&
                <Tab eventKey="addEditCandidate" title={`${selectedCandidateId === 0 ? "Add" : "Edit"} Candidate & Entities`}
                  tabClassName="page-tab border border-left-0 border-right-0 border-top-0">
                  <Tab.Content className="border p-3 border-top-0">
                    <AddCandidate
                      selectedObj={selectedObj}
                      setSelectedTabKey={setSelectedTabKey}
                      setSelectedCandidateId={setSelectedCandidateId}
                      selectedTabKey={selectedTabKey}
                      actType={selectedCandidateId === 0 ? "add" : "edit"}
                      handleAddEditCandidate={handleAddEditCandidate}
                    />
                  </Tab.Content>
                </Tab>
              }
              {type === "partner" &&
                <Tab eventKey="status" title="Activate/Deactivate" tabClassName="page-tab border border-left-0 border-right-0 border-top-0">
                  <Tab.Content className="border p-3 border-top-0">
                    {selectedCandidateId === 0 && <div className="">
                      <h3 className="text-danger mt-1"> Alert : Please select the Candidate first</h3>
                    </div>}

                    {selectedCandidateId > 0 &&
                      <ActiveDeactiveHistory selectedObj={selectedObj} type="performer" typeName="Candidate" actionType={actionType} handlePermissionShowHide2={handlePermissionShowHide2} />}
                  </Tab.Content>
                </Tab>
              }

            </Tabs>
          </div>
        </div>
      </div>
      {candidates.length > 0 &&
        <React.Fragment>

          {remainderMsgShowHide &&
            <ReminderAlertMessage
              remainderMsgShowHide={remainderMsgShowHide}
              handleRemainderMessage={handleRemainderMessage}
            ></ReminderAlertMessage>
          }
        </React.Fragment>}
    </React.Fragment>
  );
}

