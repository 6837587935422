import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Table from "../comman/table";

class PartnerPerformerTable extends Component {
  columns = [
    { path: "id", label: "ID" },
    {
      path: "addedDateTime",
      label: "Date & Time",
      content: (obj) => (
        <React.Fragment>
          <Moment format="MMM Do YYYY">{obj.addedDateTime}</Moment>
          <br />
          <Moment format="h:mm a">{obj.addedDateTime}</Moment>
        </React.Fragment>
      ),
    },
    {
      path: "name",
      label: "User Details",
      content: (obj) => (
        <span className="text-uppercase">
          {obj.name}
          <br />
          {obj.email}
          <br />
          {obj.contact && obj.contact.slice(0, 3)}-
          {obj.contact && obj.contact.slice(3, 6)}-
          {obj.contact && obj.contact.slice(6)}
        </span>
      ),
    },
    // {
    //   path: "roleName",
    //   label: "Role",
    //   content: (obj) => (
    //     <React.Fragment>
    //       {obj.isTeamLead ? "Super Admin" : obj.roleName}
    //     </React.Fragment>
    //   ),
    // },
    // { path: "title", label: "Title" },
    // { path: "email", label: "Email" },
    // {
    //   path: "contact",
    //   label: "Mobile No.",
    //   content: (obj) => (
    //     <div>
    //       {obj.contact && obj.contact.slice(0, 3)}-
    //       {obj.contact && obj.contact.slice(3, 6)}-
    //       {obj.contact && obj.contact.slice(6)}
    //     </div>
    //   ),
    // },
    {
      path: "",
      label: "State",
      content: (obj) => <div>{obj.state}</div>,
    },
    // {
    //   path: "contact",
    //   label: "Mobile No.",
    //   content: (obj) => (
    //     <div>
    //       {obj.contact.slice(0, 3)}-{obj.contact.slice(3, 6)}-
    //       {obj.contact.slice(6)}
    //     </div>
    //   ),
    // },
    { path: "username", label: "Username" },

    {
      path: "status",
      label: "Status",
      content: (item) => (
        <React.Fragment>
          {item.status === true && (
            <button
              className="btn btn-link btn-sm p-0 text-underline"
              onClick={() => this.props.handleLogShowHide(item, "action")}
            >
              <i className="fa fa-check-square" aria-hidden="true"></i>{" "}
              Activated
            </button>
          )}

          {item.status === false && (
            <button
              className="btn btn-link btn-sm p-0 text-underline"
              onClick={() => this.props.handleLogShowHide(item, "action")}
            >
              Deactivated
            </button>
          )}
          {item.status === null && (
            <button
              className="btn btn-link btn-sm p-0 text-underline"
              onClick={() => this.props.handleLogShowHide(item, "action")}
            >
              To Activate
            </button>
          )}

          <br />
          <button
            className="btn btn-link btn-sm p-0 text-underline"
            onClick={() => this.props.handleLogShowHide(item, "log")}
          >
            Log History
          </button>
        </React.Fragment>
      ),
    },
    {
      path: "",
      label: "Action",
      content: (obj) => (
        <button
          type="button"
          className="btn btn-link btn-sm p-0 text-underline"
          title="Edit User"
          onClick={() => this.props.handleModalShowHide(obj)}
        >
          Edit
        </button>
      ),
    },
  ];
  render() {
    const { data } = this.props;
    return <Table columns={this.columns} data={data}></Table>;
  }
}

export default PartnerPerformerTable;
