import React from "react"
import { Nav } from "react-bootstrap"
import { useSelector } from "react-redux";
import { getComponentUserStatus, getToolsCount } from "../store/tools";

export default function ToolList(props) {
    const { handleToolShowHide, compList } = props;
    const toolsCountSelector = useSelector(getToolsCount);
    const componentUserStatusSelector = useSelector(getComponentUserStatus);

    const getCount = (id) => {
        const toolObj = toolsCountSelector.filter(x => x.key == id);
        if (toolObj.length > 0) return toolObj[0].value;
        return 0;
    }

    const getReadUnreadBgColor = (id) => {
        const compObj = componentUserStatusSelector.filter(x => x.compId === id);
        if (compObj.length > 0) {
            const unreadComps = compObj[0].unreadCompItems.split(',').sort();
            const readComps = compObj[0].compItems.split(',').sort();
            let unReadCount = 0;
            unreadComps.filter(x=>x !== "").map(x => {
                const rs = readComps.filter(y => y === x);
                if (rs.length === 0) {
                    unReadCount += 1;
                }
            });

            if (unReadCount === 0) {
                return <span className="badge badge-pill badge-success position-absolute mt--2">
                    {getCount(id)}
                </span>
            }
            else {
                return <span className="badge badge-pill badge-danger position-absolute mt--2">
                    {unReadCount}
                </span>
            }
        } else {
            return <span className="badge badge-pill badge-danger position-absolute mt--2">
                {getCount(id)}
            </span>
        }
    }

    return (
        <Nav variant="pills" className="flex-row">
            {compList.map((r, key) => (
                <Nav.Item>
                    <Nav.Link
                        eventKey={r.id}
                        className="pt-1 pb-1 pl-2 pr-2 mr-3 mt-2"
                        style={{ color: "black" }}
                        onClick={() => handleToolShowHide(r.id, "")}
                    >
                        {r.name}
                        {getReadUnreadBgColor(r.id)}
                    </Nav.Link>
                </Nav.Item>
            ))}
        </Nav>
    )
}