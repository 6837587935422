import React from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "../../comman/form";
import UploadDocument from "../../comman/UploadDocument";
import Joi, { options } from "joi-browser";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import config from "../../config.json";
import Swal from "sweetalert2";

class DocusignPopup extends Form {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      data: {},
      errors: {},
      positions: [
        {
          id: 1,
          type: "top",
          name: "Top",
        },
        {
          id: 2,
          type: "bottom",
          name: "Bottom",
        },
        {
          id: 3,
          type: "left",
          name: "Left",
        },
        {
          id: 4,
          type: "right",
          name: "Right",
        },
      ],
      btnProcess: false,
      contractTemp: [
        { id: "1", name: "Consulting Services Agreement (C2C)" },
        { id: "2", name: "Consulting Services Agreement (Recruiter)" },
        { id: "3", name: "Independent Contractor Agreement (1099 Basis)" },
        {
          id: "4",
          name: "Consulting Services Agreement (W2-Basis with no benefits)",
        },
      ],
    };
  }

  schema = {
    signPlace: Joi.string().required().label("Sign Place"),
    signPosition: Joi.string().required().label("Sign Position"),
    documents: Joi.string().required().label("Document"),
    remarks: Joi.string().required().label("Remarks"),
  };

  validateDocuments = (documents) => {
    const { data, errors } = this.state;
    if (documents.length > 0) {
      data["documents"] = documents.length.toString();
      delete errors["documents"];
    } else {
      const obj = { documents: "" };
      const schema = { documents: this.schema["documents"] };
      const { error } = Joi.validate(obj, schema);
      errors["documents"] = error.details[0].message;
      data["documents"] = "";
    }
    this.setState({ data, errors });
  };

  doSubmit = async () => {
    const { data, documents } = this.state;
    const { workflowTask } = this.props;
    const wfTask={...workflowTask};
    const user = auth.getCurrentUser();
    wfTask.performerId = user.id;
    const frmData = new FormData();
    frmData.append("signPlace", data["signPlace"]);
    frmData.append("signPosition", data["signPosition"]);
    frmData.append("remarks", data["remarks"]);
    frmData.append("workflowTask", JSON.stringify(wfTask));
    documents.map((file, key) =>
      frmData.append("files[" + key + "]", file.file)
    );
    const result = await http.post(config.AddDocusignDocuments, frmData);
    // console.log(result);
    if (result.data === "success") {
      this.props.handleModalShowHide("success");
    } else {
      Swal.fire({
        icon: "error",
        text: result.data,
        showCancelButton: true,
      });
    }
  };

  handlePrintPdf = async (bodyType) => {
    this.setState({ btnProcess: true });
    //console.log(bodyType);
    const logImg = this.getEmergencyFoundImg();
    let htmlBody = "";
    if (bodyType === "0") {
      htmlBody =
        "<div style='text-align:center;padding-bottom:10px'>" +
        logImg.outerHTML +
        "</img></div>" +
        this.refs.printBody.innerHTML;
    }

    if (bodyType === "1") {
      htmlBody =
        "<div style='text-align:center;padding-bottom:10px'>" +
        logImg.outerHTML +
        "</img></div>" +
        this.refs.printBody1.innerHTML;
    }

    if (bodyType === "2") {
      htmlBody =
        "<div style='text-align:center;padding-bottom:10px'>" +
        logImg.outerHTML +
        "</img></div>" +
        this.refs.printBody2.innerHTML;
    }

    if (bodyType === "3") {
      htmlBody =
        "<div style='text-align:center;padding-bottom:10px'>" +
        logImg.outerHTML +
        "</img></div>" +
        this.refs.printBody3.innerHTML;
    }

    this.handleGeneratePdf(htmlBody);
  };

  handleGeneratePdf = async (htmlBody) => {
    const frmData = new FormData();
    frmData.append("html", htmlBody);
    const { data } = await http.post(config.PrintTemplate, frmData, {
      responseType: "blob",
    });
    this.setState({ btnProcess: false });
    if (data.size > 0) {
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };

  getEmergencyFoundImg = () => {
    var c = document.createElement("canvas");
    var img = document.getElementById("Img1");
    c.height = img.naturalHeight;
    c.width = img.naturalWidth;
    var ctx = c.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var base64String = c.toDataURL();
    let img2 = document.createElement("img");
    img2.height = img.naturalHeight;
    img2.width = img.naturalWidth;
    img2.src = base64String;
    return img2;
  };

  eSignFileChange = async (event) => {
    //console.log(event.target.files);
    const { documents: docs, errors } = this.state;
    //const docs = [...this.state.documents];
    for (const file of event.target.files) {
      if (file.type !== "application/pdf") {
        errors["documents"] = "Only Pdf File Accepted";
      } else {
        errors["documents"] = "";
        var doc = {
          name: file.name,
          type: file.type,
          file: file,
        };
        docs.push(doc);
      }
    }

    event.target.value = "";
    this.validateDocuments(docs);
    this.setState({ documents: docs, errors });
  };

  render() {
    const { documents, errors, positions, btnProcess, contractTemp, data } =
      this.state;
    const { showHide, handleModalShowHide, signType, docType } = this.props;
    const textStyle = {
      fontSize: "13px",
      lineHeight: "20px",
      marginTop: "15px",
      textAlign: "justify",
      textJustify: "inter-word",
    };
    const linkStyle = { color: "blue", textDecoration: "underline" };
    return (
      <Modal show={showHide} size="lg">
        <Modal.Header closeButton onHide={handleModalShowHide}>
          <Modal.Title>{signType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {docType !== "contract" && (
            <div className="row">
              <div className="col-md-12">
                <UploadDocument
                  documents={documents}
                  onFileChange={this.eSignFileChange}
                  handleFileRemove={this.handleFileRemove}
                  //acceptedFile={"application/pdf"}
                ></UploadDocument>
                {errors["documents"] && (
                  <div className="alert alert-danger">
                    {errors["documents"]}
                  </div>
                )}
              </div>
              <div className="col-md-12">
                {this.renderTextArea("signPlace", "Sign Place Text")}
              </div>
              <div className="col-md-12">
                {this.renderSelect(
                  "signPosition",
                  "Select Sign Position",
                  positions,
                  "name",
                  "type"
                )}
              </div>
              <div className="col-md-12">
                {this.renderTextArea("remarks", "Remarks")}
              </div>
            </div>
          )}
          {docType === "contract" && (
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  {this.renderSelect(
                    "tempType",
                    "Contract Type",
                    contractTemp,
                    "name",
                    "id"
                  )}
                </div>
              </div>
              {data["tempType"] === "1" && (
                <div className="card card-body bg-secondary border shadow-none p-5">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <img id="Img1" src={`/assets/Img/logo.png`} />
                    </div>
                    <div className="col-md-12">
                      <div id="printBody" ref="printBody">
                        <h1 style={{ textAlign: "center" }}>
                          <b> CONSULTING SERVICES AGREEMENT (C2C)</b>
                        </h1>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "13px",
                            marginTop: "5px",
                          }}
                        >
                          Effective Date : ##CurrentDate##
                        </p>
                        <p style={textStyle}>
                          This Consulting Services Agreement ('Agreement') is
                          made by
                          <b>
                            {" "}
                            International Projects Consultancy Services (IPCS),
                            Inc.
                          </b>
                          (hereinafter '<b>IPCS</b>') with its office at
                          METROPOINT, Suite 1595, 600 South Highway 169,
                          Minneapolis, MN 55426 and ##Name## (hereinafter '
                          <b>SUBCONTRACTOR</b>') with its Registered office at
                          ##Address##.
                        </p>
                        <p style={textStyle}>
                          This Agreement sets forth the exclusive terms and
                          conditions by which IPCS may acquire SUBCONTRACTOR's
                          services on a temporary basis to perform on IPCS's
                          Federal and State Government projects (CLIENT
                          (AGENCY)) during the term of this Agreement. In
                          consideration of the mutual obligations specified in
                          this Agreement, and any compensation paid to the
                          SUBCONTRACTOR for its services, the parties agree as
                          follows:
                        </p>
                        <ol style={{ marginLeft: "-20px" }}>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>Term.</b>{" "}
                            This agreement has a term of Two (2) years from the
                            effective date of this Agreement and shall continue
                            to be in force thereafter unless and until
                            terminated as provided herein.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Scope.
                            </b>{" "}
                            The SUBCONTRACTOR agrees to provide the services of
                            Information Technology Candidates on IPCS's
                            project(s) which are further confirmed by written
                            Purchase Orders specifying the CLIENT where the
                            Candidate will perform, the nature of the services
                            to be performed, engagement duration, location, fee
                            for services, and any special contractual provisions
                            pertaining to that specific project assignment. The
                            Purchase Order forms an integral part of this
                            Agreement, the terms and conditions of which are
                            incorporated herein by reference.
                            <p style={textStyle}>
                              The SUBCONTRACTOR confirms and assures delivery of
                              the selected Candidates on the agreed to date as
                              specified in the Purchase Order as this is essence
                              to this agreement, and any breach of this
                              understanding will have grave consequence
                              resulting in cost implications to IPCS and CLIENT
                              having to redo the whole sourcing procurement
                              process resulting in project delays. The
                              SUBCONTRACTOR recognizes the essentiality of this
                              stipulation. The SUBCONTRACTOR also confirms that
                              it has duly executed contract agreement with the
                              Candidate(s) who have been selected by IPCS to
                              perform on IPCS projects under this agreement and
                              that the Candidate(s) are fully aware of the
                              applicable contractual obligations as stated
                              herein and required to be fulfilled for the
                              satisfactory completion of tasks and deliverables
                              for the duration as detailed in the purchase order
                              and for any extensions.
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>Fee.</b>{" "}
                            For satisfactory and timely completion and delivery
                            of service rendered by the Candidates, IPCS will
                            compensate SUBCONTRACTOR as per the rates/hour or
                            compensation agreed upon in each Purchase Order
                            issued pursuant to this Agreement, and forming part
                            of this Consulting Services Agreement.
                            <p style={textStyle}>
                              It is understood that IPCS has no obligation under
                              local, state or federal laws regarding the
                              Candidates and that IPCS shall have no obligation
                              under this Agreement except as provided herein.
                              All taxes applicable to any amounts paid by IPCS
                              to SUBCONTRACTOR for the services of the
                              Candidate(s) under this Agreement will be
                              SUBCONTRACTOR's liability and IPCS shall not
                              withhold nor pay any amounts for federal, state or
                              municipal income tax, social security,
                              unemployment or worker's compensation with regard
                              to SUBCONTRACTOR's Candidate while on the project.
                            </p>
                            <p style={textStyle}>
                              As the hourly rates to IPCS on the CLIENT executed
                              Master Contracts are fixed, the hourly rate or
                              compensation agreed to in the Purchase Orders
                              shall remain firm and will not increase during the
                              term of the contract engagement.{" "}
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Payment.
                            </b>{" "}
                            The SUBCONTRACTOR shall invoice IPCS monthly for the
                            services of the Candidates performed hereunder. IPCS
                            will make payment net thirty (30) days (by the 7th
                            of the month) from the date invoice is received for
                            the services performed for the actual approved hours
                            worked by the Candidate on the project. The
                            SUBCONTRACTOR will submit Invoices with approved
                            timesheets to IPCS by email to{" "}
                            <span style={linkStyle}>accounts@ipcs.net</span> All
                            payments to the SUBCONTRACTOR will be paid via ACH
                            for which required formalities will be completed.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Working Hours, Timing and Work Standards, Vacation
                              Requests.
                            </b>{" "}
                            It is expected that the Candidates will work normal
                            working hours of 8 hours/day, 40 hours/week. Any
                            overtime, with proper advance authorizations, worked
                            will be billed as regular time. Further Candidates
                            will follow and adhere to CLIENT-specific policies,
                            time zones, working standards and procedures in
                            professional manner, and maintain confidentiality of
                            information and data at all times.
                            <p style={textStyle}>
                              In regard to yearly vacation requests the
                              Candidates are required to complete Vacation
                              Request Form which they need to complete and
                              submit to IPCS's Email at{" "}
                              <span style={linkStyle}>support@ipcs.net</span>.
                              Based on this IPCS will take necessary steps to
                              discuss the same with Candidate's Manager's and
                              obtain the required approval.
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Agency Equipment.
                            </b>{" "}
                            In the event CLIENT ships the security cleared
                            computer/laptop to the Candidate for work, the
                            Candidate will maintain the equipment as if his/her
                            own. The SUBCONTRACTOR will be responsible for the
                            return shipment of the same to the CLIENT on
                            completion of the project engagement.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Independent Contractor.
                            </b>{" "}
                            It is expressly understood and agreed that
                            SUBCONTRACTOR is an independent entity and nothing
                            herein contained or implied will at any time be
                            construed to create the relationship of employer and
                            employee, partnership, principal and agent, or joint
                            venture between IPCS and the SUBCONTRACTOR, and/or
                            between IPCS and the CLIENT, and the Candidates
                            provided hereunder. The Candidates assigned on the
                            project are not entitled to any benefits (including
                            medical insurance, life insurance, retirement plans,
                            etc.) provided by IPCS to its employees.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Confidentiality and Non-Disclosure Obligations.
                            </b>{" "}
                            The SUBCONTRACTOR shall regard and preserve as
                            confidential any and all information and trade
                            secrets shared by IPCS and CLIENT which are not
                            generally available in the marketplace. The
                            SUBCONTRACTOR shall ensure that its Candidates
                            observes the same obligation. The SUBCONTRACTOR
                            acknowledges that any information received from IPCS
                            or from CLIENT is the sole property of IPCS and/or
                            the CLIENT, and the SUBCONTRACTOR or its
                            representatives will not utilize such information
                            except in the performance of this Agreement. As part
                            of protecting CLIENT interests, Candidates will be
                            required to sign CLIENT-specific Non-Disclosure and
                            Confidentiality Agreements as part of onboarding
                            process.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Work Performance.
                            </b>{" "}
                            <p style={{ lineHeight: "20px" }}>
                              <span style={{ textDecoration: "underline" }}>
                                During the Project Engagement Duration
                              </span>{" "}
                              - In accordance with the contract obligations IPCS
                              has on IT Master Contracts with the CLIENT(s), the
                              SUBCONTRACTOR understands that work performance of
                              its Candidates will be closely monitored by the
                              CLIENT Managers, and hence the need to impress
                              upon the Candidates to perform satisfactorily,
                              demonstrate effective use of skills, technologies
                              and techniques, providing timely deliverables,
                              work updates, and meeting the CLIENT's overall
                              expectations. In the event the CLIENT identifies
                              any performance related issues which are not
                              improved or corrected by the Candidate, CLIENT may
                              terminate the project engagement with no advance
                              notice, and any compensation with-held by the
                              CLIENT for any wrong work performed that needs to
                              be redone by the CLIENT will be notified to the
                              SUBCONTRACTOR. Payments if advanced or overpaid
                              for will be promptly refunded to IPCS by the
                              SUBCONTRACTOR.
                            </p>
                            <p style={textStyle}>
                              <span style={{ textDecoration: "underline" }}>
                                First Two Weeks
                              </span>{" "}
                              - As is a standard process, this first two-weeks
                              of Candidate's services will be closely monitored
                              by the CLIENT and the SUBCONTRACTOR should impress
                              upon the Candidate(s) the need to perform and
                              delivery in line with CLIENT's expectation. Any
                              inadequacies noticed will result in immediate
                              termination (without any notice) of the Candidate
                              with no compensation.
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Project Assignment.
                            </b>{" "}
                            The SUBCONTRACTOR ensures that its Candidate
                            assigned on the CLIENT project is committed to
                            perform the contracted work for the duration as
                            required and agreed-to in the Purchase Order, and
                            for any additional/extended period the CLIENT may
                            need for completing the tasks or deliverables. The
                            SUBCONTRACTOR will ensure Candidate(s) do not
                            abandon the project and will not remove the
                            Candidate for another position during the contract
                            duration as agreed to as it will impact the CLIENT's
                            project schedule and budget. Any such breach will
                            result in additional costs to IPCS for rehire,
                            administration and sourcing, interviews including
                            compensation for delays in work and impact on time
                            line etc. which will be estimated and the
                            SUBCONTRACTOR will reimburse the same to IPCS.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Restriction on Solicitation.
                            </b>{" "}
                            Under no circumstances, during the currency of the
                            contract engagement and/or for one year after the
                            termination of the engagement, the SUBCONTRACTOR or
                            Candidate will contact or solicit IPCS's CLIENT or
                            CLIENT's Managed Service Provider (MSP) or any other
                            vendor providing similar services to the CLIENT -on
                            any matter including any discussion on this contract
                            or Candidates engaged or solicit employment or new
                            contract or bypass IPCS on any matter including
                            contract transfer to any other vendor or interact or
                            establish any relationship with IPCS's CLIENT
                            directly or indirectly where IPCS is using or has
                            used the services of the SUBCONTRACTOR's Candidate's
                            on a project engagement. The SUBCONTRACTOR
                            acknowledges that any breach of this restriction
                            will do a great harm to IPCS and accordingly the
                            SUBCONTRACTOR and Candidate(s) will refrain from any
                            such violation of this contract condition which, if
                            violated, will result in cost implications the
                            amounts of such costs as estimated at the time of
                            the breach will be payable by the SUBCONTRACTOR.
                            Also, either party will not hire, employ or solicit
                            each other's Candidates directly or in-directly
                            during the term of this project and for a period of
                            one year after the termination.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Termination of Purchase Order.
                            </b>{" "}
                            IPCS may terminate any Purchase Order hereunder at
                            any time without notice should the CLIENT elects to
                            do so for any reason including non-performance of
                            the Candidate or any budget cancellation that the
                            CLIENT may experience or for any other reason CLIENT
                            may face due any legislative mandates, etc. Despite
                            such provision, IPCS will use its best efforts to
                            provide as much advance notice as possible and
                            immediately upon notification of any termination of
                            the Candidate by the CLIENT. Further the purchase
                            order could be terminated immediately without any
                            prior notice for any reasons including the
                            following:
                            <ol style={{ listStyleType: "upper-roman" }}>
                              <li style={textStyle}>
                                Any guilt of improper conduct or dishonesty or
                                other breach of duty of the Candidate affecting
                                IPCS, IPCS's associate/MSP or CLIENT where the
                                Candidate(s) are assigned on the project.
                              </li>
                              <li style={textStyle}>
                                Any fraudulent violation with regard to
                                Candidate's Identity Verification (Passport,
                                H1B, Green Card, Driver License, State ID's,
                                work experience, technical skills or reference
                                misrepresentation, etc.). Further in such
                                situations if found by IPCS or the CLIENT, the
                                SUBCONTRACTOR will refund compensation already
                                paid to the SUBCONTRACTOR for reimbursement to
                                the CLIENT.
                              </li>
                              <li style={textStyle}>
                                Use of alcohol or other drugs in a manner, which
                                affects the performance of Candidate's duties,
                                responsibilities and obligations while engaged
                                on the project.
                              </li>
                              <li style={textStyle}>
                                Conviction of Candidate of a felony or of any
                                crime involving misrepresentation, moral
                                turpitude or fraud.
                              </li>
                              <li style={textStyle}>
                                Commission by Candidate of any other willful or
                                intentional act which could reasonably be
                                expected to injure the reputation, business or
                                business relationships of IPCS, MSP or the
                                CLIENT.
                              </li>
                              <li style={textStyle}>
                                The existence of any court order or settlement
                                agreement prohibiting the Candidate continued
                                engagement with IPCS.
                              </li>
                              <li style={textStyle}>
                                Any written or oral non-competition agreement,
                                confidentiality agreement, proprietary
                                information agreement, trade secret agreement or
                                any other agreement signed or executed by the
                                Candidate with any entity preventing the
                                Candidate from working on the project
                                engagement.
                              </li>
                              <li style={textStyle}>
                                Non-performance of the Candidate as may be
                                deemed by the CLIENT at any time during the
                                project engagement.
                              </li>
                            </ol>
                            <p style={textStyle}>
                              It is imperative for the SUBCONTRACTOR to ensure
                              their Candidates are aware of their
                              responsibilities and obligations including
                              implications of any such violation.
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Termination of Agreement.
                            </b>{" "}
                            This Agreement may be terminated: (a) by mutual
                            agreement of the parties at any time; (b) upon
                            fifteen (15) days prior written notice by either
                            party; or (c) by the aggrieved party without advance
                            notice in the event of a breach of this Agreement by
                            the other party.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Remedies for Breach.
                            </b>{" "}
                            In the event of a breach of this Agreement, the
                            aggrieved party may, in addition to any remedy
                            provided in this Agreement or under law or at
                            equity, discontinue performance without incurring
                            any liability to the breaching party of any sort and
                            without further obligation to the breaching party.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Warranties.
                            </b>{" "}
                            The SUBCONTRACTOR warrants to IPCS that the
                            services, and any materials developed hereunder,
                            shall (a) be performed in a workmanlike and
                            professional manner and be free of defects; (b)
                            comply with any applicable law, rule or regulation;
                            and (c) not violate or infringe upon any copyright,
                            patent, trade secret or other property, contractual,
                            employment or confidentiality right of a third
                            party.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Liabilities.
                            </b>{" "}
                            Under no circumstances IPCS will be responsible for
                            any and all claims, judgments, demands or
                            liabilities for federal employment taxes, state
                            /county taxes and fees, Candidate's compensation
                            claims and/or any previous non-compete or other
                            violation of intellectual property rights filed
                            against the SUBCONTRACTOR by any third-party or its
                            Candidate(s). The SUBCONTRACTOR agrees to indemnify
                            and hold IPCS, MSP and CLIENT harmless from and
                            against such claims, if any. IPCS liability if any
                            shall be limited to maximum of one hundred dollars
                            ($100).
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Insurance.
                            </b>{" "}
                            During the term of this Agreement, the SUBCONTRACTOR
                            shall acquire and be responsible for maintaining
                            insurance, including worker's compensation where
                            required by law:
                            <ol style={{ listStyleType: "lower-alpha" }}>
                              <li style={textStyle}>
                                Comprehensive automobile liability (in the
                                minimum amount of $1,000,000)
                              </li>
                              <li>
                                Comprehensive general liability (in the minimum
                                amount of $1,000,000)
                              </li>
                              <li>
                                Errors and Omissions in the amount of $5,000,000
                              </li>
                              <li>
                                Umbrella policy in the amount of $5,000,000
                              </li>
                            </ol>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Adherence to Standards and Discipline.
                            </b>{" "}
                            The SUBCONTRACTOR acknowledges that its Candidates
                            shall observe all applicable disciplinary rules and
                            regulations while engaged at the CLIENT or any other
                            premise of the CLIENT as may be required by this
                            Agreement. If the SUBCONTRACTOR's assigned Candidate
                            pursuant hereto shall be involved in any criminal
                            activity or any act of disloyalty towards IPCS or
                            its associate (MSP) or CLIENT or in the sole
                            judgment of IPCS be or become incompetent or
                            incapable of maintaining the accepted standards
                            required from IPCS, or if the CLIENT shall at any
                            time cease to be satisfied with the quality or
                            quantity of the work done by the said Candidate or
                            cease to require his services, IPCS may forthwith
                            and without notice terminate the assignment of the
                            Candidate.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Force Majeure.
                            </b>{" "}
                            Neither party shall be under any liability to the
                            other party for any delay or failure to perform
                            under this Agreement to the extent such delay is
                            wholly or partially caused by strikes, labor
                            disputes, acts of God, or similar circumstances
                            beyond its control.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Notices.
                            </b>{" "}
                            Any notices required or permitted under this
                            Agreement shall be deemed to have been duly given
                            when personally delivered or sent by certified mail,
                            return receipt, requested to the party entitled
                            thereto at the address set forth on this Agreement
                            or as thereafter supplied by such party from
                            time-to-time.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Immigration Laws Compliance.
                            </b>{" "}
                            The SUBCONTRACTOR warrants that it will not
                            knowingly assign to perform services under this
                            Agreement of Candidates who are unauthorized aliens
                            under the Immigration Reform and Control Act of 1986
                            or its implementing regulations. The SUBCONTRACTOR,
                            on demand, will furnish all the required USCIS work
                            authorization documents of the Candidates including
                            applicable LCA's for the locations where the
                            Candidates are assigned on the IPCS projects. In the
                            event of any breach or violation of this
                            stipulation, IPCS has the right to remove the
                            Candidate immediately from performing services
                            hereunder, notify appropriate law enforcement
                            agencies including USCIS, withhold payments, seek
                            refund of already made payments, and pursue legal
                            action against the SUBCONTRACTOR and seek monetary
                            damages resulting from such a breach/violation.
                            <p style={textStyle}>
                              As required by the Government Agencies (CLIENT),
                              the SUBCONTRACTOR is also required to show proof
                              that it uses E-Verify to screen all employees at
                              hire and produces copies of E-Verification Report
                              for each Candidate.
                            </p>
                            <p style={textStyle}>
                              Further all requests for VISA support letters as
                              may be required for extension/renewals of USCIS
                              petitions for the Candidates must be processed
                              through IPCS and not sent directly to the CLIENT.
                              IPCS and the CLIENT has a defined process in place
                              which prohibits Candidates from approaching the
                              CLIENT however IPCS will coordinate with the
                              appropriate CLIENT's department and provide the
                              support documentation as may be required and as
                              permissible by legal requirements.
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Background and Drug Check.
                            </b>{" "}
                            As part of the onboarding process with the Agencies
                            (CLIENT), the SUBCONTRACTOR is required to perform a
                            background check of the Candidates including 7 year
                            National Criminal Felony and Misdemeanor by
                            Counties, 7 year Statewide Criminal Search, 7 year
                            Employment History, Drug Testing (if specifically
                            requested by the State CLIENT), Education Report,
                            Employee Credit, Federal Criminal, National Sex
                            Offender Registry, Professional Reference Check
                            Report by Outside CLIENT, SSN Trace, SSN Validation,
                            SSN Verification, and a Drug Test before starting on
                            the project with the CLIENT. The SUBCONTRACTOR will
                            provide a copy of the same to IPCS which requires to
                            be sent to the CLIENT for them to provide the start
                            date of the Candidate's engagement. For some
                            Agencies (CLIENT), Candidates may be also required
                            to do CLIENT-specific background checks. In the
                            event of some Agencies (CLIENT) requiring IPCS to
                            directly perform the background check of the
                            Candidates, IPCS will do so however fee for the same
                            will be charged to the SUBCONTRACTOR and will be
                            deducted from the SUBCONTRACTOR's invoice.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Assignment.
                            </b>{" "}
                            Neither IPCS nor the SUBCONTRACTOR may assign any
                            part or whole of this Agreement or any rights
                            hereunder, without written permission of the other
                            party.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              No Waiver.
                            </b>{" "}
                            No failure of either party to enforce any rights
                            hereunder shall be deemed a waiver thereof.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Governing Law.
                            </b>{" "}
                            This Agreement shall be governed by and construed in
                            accordance with the Laws of the State of Minnesota.
                            Any controversies and/or litigation shall be
                            submitted to Hennepin County District Court.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Rights to Development.
                            </b>{" "}
                            All software documentation, programs /computer code,
                            and products developed by SUBCONTRACTOR's Candidate
                            under this Agreement shall be the sole property of
                            the CLIENT for whom the Candidate's services are or
                            were engaged.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Entire Agreement.
                            </b>{" "}
                            This Agreement represents the entire agreement and
                            supersedes all previous agreements and
                            understandings between the parties relating to the
                            subject matter hereof, and may be changed only in
                            writing, signed by both parties.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Successors and Assigns.
                            </b>{" "}
                            The provisions of this Agreement shall be binding
                            upon and shall be to the benefit of the parties,
                            their respective successors, and assigns.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Counterparts.
                            </b>{" "}
                            This Agreement may be executed in separate
                            counterparts, each of which when so executed and
                            delivered shall be an original for all purposes, but
                            all counterparts together shall constitute on
                            instrument.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Legal / Authorized Contact Details of the Parties.
                            </b>{" "}
                            To ensure true representation of the parties in the
                            execution of this contract and for purposes of
                            smooth implementation of the project engagement as
                            per the purchase order issued, both IPCS and the
                            SUBCONTRACTOR legal/authorized signatory details are
                            provided below:
                            <table style={{ fontSize: "13px" }} cellPadding="2">
                              <tr>
                                <td>
                                  <b>IPCS</b>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Name :</td>
                                <td>Kuldeep Dhar</td>
                              </tr>
                              <tr>
                                <td>Title :</td>
                                <td>Sr. Vice President</td>
                              </tr>
                              <tr>
                                <td>Phone :</td>
                                <td>952-541-4888</td>
                              </tr>
                              <tr>
                                <td>Eamil :</td>
                                <td>
                                  <span
                                    style={{
                                      color: "blue",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Kuldeep@ipcs.net
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>Registered Office :</td>
                                <td>
                                  METROPOINT, Suite 1595, 600 South Highway 169,
                                  Minneapolis MN 55426
                                </td>
                              </tr>
                            </table>
                            <table
                              style={{ fontSize: "13px", marginTop: "10px" }}
                              cellPadding="2"
                            >
                              <tr>
                                <td>
                                  <b>SUBCONTRACTOR</b>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Name :</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Title :</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Phone :</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Mobile :</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Eamil :</td>
                                <td>
                                  <span
                                    style={{
                                      color: "blue",
                                      textDecoration: "underline",
                                    }}
                                  ></span>
                                </td>
                              </tr>
                              <tr>
                                <td>Registered Office :</td>
                                <td></td>
                              </tr>
                            </table>
                            <p style={textStyle}>
                              All correspondence on the contract will be
                              exchanged between the above authorized signatories
                              on the Contract.
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Other Contract Requirements.
                            </b>{" "}
                            Any additional CLIENT-specific contract requirement
                            as may be required for the project engagement will
                            be included as Exhibit-2 and attached to the
                            Purchase Order.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Miscellaneous.
                            </b>{" "}
                            The Clauses 3, 4, 8, 11, 15, 16, 20, 25, 27, 28 and
                            30 shall survive the termination or expiration of
                            this Agreement.
                          </li>
                        </ol>
                        <p style={textStyle}>
                          In WITNESS the due execution of this Agreement this
                          date #CurrentDate# intending to be legally bound
                          hereby.
                        </p>
                        <table style={textStyle} width="100%" cellPadding="1">
                          <tr>
                            <td>
                              <h3>
                                <b>INTERNATIONAL PROJECTS</b>
                              </h3>
                              <h3>
                                <b>CONSULTANCY SERVICES (IPCS) INC.</b>
                              </h3>
                            </td>
                            <td>
                              <h3>
                                <b>SUBCONTRACTOR</b>
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td height={80} style={{ verticalAlign: "bottom" }}>
                              By : __________________
                            </td>
                            <td height={80} style={{ verticalAlign: "bottom" }}>
                              By : __________________
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>(Duly authorized official)</td>
                          </tr>
                          <tr>
                            <td>Name : KULDEEP DHAR</td>
                            <td>Name : </td>
                          </tr>
                          <tr>
                            <td>Title : Sr. Vice President</td>
                            <td>Title : </td>
                          </tr>
                        </table>
                        <p style={{ marginTop: "600px" }}>&nbsp;</p>
                        <p style={textStyle}>Date : #CurrentDate#</p>
                        <p style={textStyle}>To :</p>
                        <p style={textStyle}>The Contracts Administrator</p>
                        <p style={{ fontSize: "13px" }}>
                          International Projects Consultancy Services Inc.
                          (IPCS)
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          METROPOINT, Suite 1595
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          600 South Highway 169, Minneapolis MN 55426
                        </p>
                        <p style={textStyle}>
                          RE: IPCS's Consulting Services Agreement (C2C), dated
                          #CurrentDate#
                          <ol>
                            <li style={textStyle}>
                              This is to confirm that Mahalani Circle, LLC
                              (SUBCONTRACTOR) is a US Registered Company
                              incorporated in the State of
                              #state_in_corporation# in the year
                              #year_of_corporation#.
                            </li>
                            <li style={textStyle}>
                              The Company's Registered Office is located at
                              ##Address##
                            </li>
                            <li style={textStyle}>
                              The Federal ID of our Company is #federal_id#
                            </li>
                          </ol>
                        </p>
                        <p style={textStyle}>
                          I #subcontractor#, undersigned is the Title: Vice
                          President and authorized signatory for the above
                          Company and solemnly affirm that I am a permitted to
                          sign and execute the Consulting Services Agreement
                          (C2C) with IPCS.
                        </p>
                        <p style={{ fontSize: "13px", marginTop: "60px" }}>
                          Signature : ____________________________________
                        </p>
                        <p style={{ fontSize: "13px", marginTop: "5px" }}>
                          Title : (Owner / Executive Director - Authorized
                          Title)
                        </p>
                        <p style={{ fontSize: "13px", marginTop: "30px" }}>
                          <b>Contact Details : </b>
                        </p>
                        <p style={{ fontSize: "13px", marginTop: "5px" }}>
                          Mobile :{" "}
                        </p>
                        <p style={{ fontSize: "13px", marginTop: "5px" }}>
                          Email :{" "}
                        </p>
                        <p style={textStyle}>Date : #sign_date#</p>
                      </div>
                    </div>
                    <div className="col-md-12 text-center mt-3">
                      <Button
                        variant="primary"
                        //disabled={btnProcess || this.validate()}
                        onClick={() => this.handlePrintPdf("0")}
                      >
                        {btnProcess && (
                          <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
                        )}
                        Print PDF
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {data["tempType"] === "2" && (
                <div className="card card-body bg-secondary border shadow-none p-5">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <img id="Img1" src={`/assets/Img/logo.png`} />
                    </div>
                    <div className="col-md-12">
                      <div id="printBody1" ref="printBody1">
                        <h1 style={{ textAlign: "center" }}>
                          <b> CONSULTING SERVICES AGREEMENT (Recruiter)</b>
                        </h1>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "13px",
                            marginTop: "5px",
                          }}
                        >
                          Effective Date : #CurrentDate#
                        </p>
                        <p style={textStyle}>
                          This Consulting Services Agreement ('Agreement') is
                          made by
                          <b>
                            {" "}
                            International Projects Consultancy Services (IPCS),
                            Inc.
                          </b>
                          (hereinafter '<b>IPCS</b>') with its office at
                          METROPOINT, Suite 1595, 600 South Highway 169,
                          Minneapolis, MN 55426 and ##Name# (hereinafter '
                          <b>RECRUITER</b>') with its Registered office at
                          ##Address##.
                        </p>
                        <p style={textStyle}>
                          This Agreement sets forth the exclusive terms and
                          conditions by which IPCS may acquire RECRUITER's
                          services on a temporary basis to perform on IPCS's
                          Federal and State Government projects (CLIENT
                          (AGENCY)) during the term of this Agreement. In
                          consideration of the mutual obligations specified in
                          this Agreement, and any compensation paid to the
                          RECRUITER for its services, the parties agree as
                          follows:
                        </p>
                        <ol style={{ marginLeft: "-20px" }}>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>Term.</b>{" "}
                            This agreement has a term of Two (2) years from the
                            effective date of this Agreement and shall continue
                            to be in force thereafter unless and until
                            terminated as provided herein.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Scope.
                            </b>{" "}
                            The RECRUITER agrees to identify and provide
                            Information Technology Candidates on IPCS's
                            project(s) which are further confirmed by written
                            Purchase Orders specifying the CLIENT where the
                            Candidate will perform, the nature of the services
                            to be performed, engagement duration, location, fee
                            for services, and any special contractual provisions
                            pertaining to that specific project assignment. The
                            Purchase Order forms an integral part of this
                            Agreement, the terms and conditions of which are
                            incorporated herein by reference.
                            <p style={textStyle}>
                              The RECRUITER will coordinate with SUBCONTRACTORS
                              and selected Candidates in the enrolment and
                              onboarding process, obtain all the required
                              documents as appropriate for each project
                              engagement, perform follow ups etc. for
                              expeditious onboarding completion so that
                              Candidate's start on the project on the agreed-to
                              date as per the Purchase Order - this is essence
                              to this agreement.
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>Fee.</b>{" "}
                            For satisfactory and timely service rendered by the
                            RECRUITER's Candidates, IPCS will compensate
                            RECRUITER as per the rate/hour agreed upon in each
                            Purchase Order issued pursuant to this Agreement,
                            and forming part of this Consulting Services
                            Agreement.
                            <p style={textStyle}>
                              All taxes applicable to any amounts paid by IPCS
                              to RECRUITER for the services of the Candidate(s)
                              under this Agreement will be RECRUITER's liability
                              and IPCS shall not withhold nor pay any amounts
                              for federal, state or municipal income tax, social
                              security, unemployment or worker's compensation.
                            </p>
                            <p style={textStyle}>
                              As the hourly rates to IPCS on the CLIENT executed
                              Master Contracts are fixed, the hourly rate agreed
                              to in the Purchase Order for each Candidate shall
                              remain firm and will not increase during the term
                              of the contract engagement.{" "}
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Payment.
                            </b>{" "}
                            The RECRUITER shall invoice IPCS monthly for the
                            services of the Candidates performed hereunder. IPCS
                            will make payment net thirty (30) days (by the 7th
                            of the month) from the date invoice is received for
                            the services performed for the actual approved hours
                            worked by the Candidate on the project. The
                            RECRUITER will submit Invoices with approved
                            timesheets to IPCS by email to{" "}
                            <span style={linkStyle}>accounts@ipcs.net</span> All
                            payments to the RECRUITER will be paid via ACH for
                            which required formalities will be completed.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Confidentiality and Non-Disclosure Obligations.
                            </b>{" "}
                            The RECRUITER shall regard and preserve as
                            confidential any and all information and trade
                            secrets shared by IPCS and CLIENT which are not
                            generally available in the marketplace. The
                            RECRUITER acknowledges that any information received
                            from IPCS or from CLIENT is the sole property of
                            IPCS and/or the CLIENT, and the RECRUITER or its
                            representatives will not utilize such information
                            except in the performance of this Agreement.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Restriction on Solicitation.
                            </b>{" "}
                            Under no circumstances, during the currency of the
                            contract and/or for one year after the termination
                            of the contract, the RECRUITER will contact or
                            solicit IPCS's CLIENT or CLIENT's Managed Service
                            Provider (MSP) or any other vendor providing similar
                            services to the CLIENT -on any matter including any
                            discussion on this contract or solicit new contract
                            or bypass IPCS on any matter including contract
                            transfer to any other vendor or interact or
                            establish any relationship with IPCS's CLIENT
                            directly or indirectly where IPCS is using or has
                            used the services of RECRUITER's Candidate's on a
                            project engagement. The RECRUITER acknowledges that
                            any breach of this restriction will do a great harm
                            to IPCS and accordingly the RECRUITER will refrain
                            from any such violation of this contract condition
                            which, if violated, will result in cost implications
                            the amounts of such costs as estimated at the time
                            of the breach will be payable by the RECRUITER.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Termination of Purchase Order.
                            </b>{" "}
                            IPCS may terminate any Purchase Order hereunder at
                            any time without notice should the CLIENT elects to
                            do so for any reason including non-performance of
                            the Candidate or any budget cancellation that the
                            CLIENT may experience or for any other reason CLIENT
                            may face due any legislative mandates, etc. Despite
                            such provision, IPCS will use its best efforts to
                            provide as much advance notice as possible and
                            immediately upon notification of any termination of
                            the Candidate by the CLIENT.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Termination of Agreement.
                            </b>{" "}
                            This Agreement may be terminated: (a) by mutual
                            agreement of the parties at any time; (b) upon
                            fifteen (15) days prior written notice by either
                            party; or (c) by the aggrieved party without advance
                            notice in the event of a breach of this Agreement by
                            the other party.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Remedies for Breach.
                            </b>{" "}
                            <p style={{ lineHeight: "20px" }}>
                              In the event of a breach of this Agreement, the
                              aggrieved party may, in addition to any remedy
                              provided in this Agreement or under law or at
                              equity, discontinue performance without incurring
                              any liability to the breaching party of any sort
                              and without further obligation to the breaching
                              party.
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Liabilities.
                            </b>{" "}
                            Under no circumstances IPCS will be responsible for
                            any and all claims, judgments, demands or
                            liabilities for federal employment taxes, state
                            /county taxes and fees, compensation claims and/or
                            any previous non-compete or other violation of
                            intellectual property rights filed against the
                            RECRUITER by any third-party or its Candidate(s).
                            The RECRUITER agrees to indemnify and hold IPCS, MSP
                            and CLIENT harmless from and against such claims, if
                            any. IPCS liability if any shall be limited to
                            maximum of one hundred dollars ($100).
                          </li>

                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Insurance.
                            </b>{" "}
                            During the term of this Agreement, the RECRUITER
                            shall acquire and be responsible for maintaining
                            insurance, including worker's compensation where
                            required by law:
                            <ol style={{ listStyleType: "lower-alpha" }}>
                              <li style={textStyle}>
                                Comprehensive automobile liability (in the
                                minimum amount of $1,000,000)
                              </li>
                              <li>
                                Comprehensive general liability (in the minimum
                                amount of $1,000,000)
                              </li>
                              <li>
                                Errors and Omissions in the amount of $5,000,000
                              </li>
                              <li>
                                Umbrella policy in the amount of $5,000,000
                              </li>
                            </ol>
                            <p style={textStyle}>
                              During the term of this Agreement, the RECRUITER
                              shall acquire and be responsible for maintaining
                              insurance, including worker's compensation where
                              required by law:
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Force Majeure.
                            </b>{" "}
                            Neither party shall be under any liability to the
                            other party for any delay or failure to perform
                            under this Agreement to the extent such delay is
                            wholly or partially caused by strikes, labor
                            disputes, acts of God, or similar circumstances
                            beyond its control.
                          </li>

                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Notices.
                            </b>{" "}
                            Any notices required or permitted under this
                            Agreement shall be deemed to have been duly given
                            when personally delivered or sent by certified mail,
                            return receipt, requested to the party entitled
                            thereto at the address set forth on this Agreement
                            or as thereafter supplied by such party from
                            time-to-time.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Immigration Laws Compliance.
                            </b>{" "}
                            The RECRUITER warrants that it will not knowingly
                            identify and recommend Candidates who are
                            unauthorized aliens under the Immigration Reform and
                            Control Act of 1986 or its implementing regulations.
                            The RECRUITER will ensure to screen all the
                            Candidates and obtain required USCIS work
                            authorization documents from the SUBCONTRACTOR's and
                            Candidates including applicable LCA's for the
                            locations where the Candidates are assigned on the
                            IPCS projects. In the event of any breach or
                            violation of this stipulation, IPCS has the right to
                            remove the Candidate immediately from performing
                            services hereunder, notify appropriate law
                            enforcement agencies including USCIS, withhold
                            payments, seek refund of already made payments, and
                            pursue legal action against the RECRUITER and seek
                            monetary damages resulting from such a
                            breach/violation.
                          </li>

                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Assignment.
                            </b>{" "}
                            Neither IPCS nor the RECRUITER may assign any part
                            or whole of this Agreement or any rights hereunder,
                            without written permission of the other party.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              No Waiver.
                            </b>{" "}
                            No failure of either party to enforce any rights
                            hereunder shall be deemed a waiver thereof
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Governing Law.
                            </b>{" "}
                            This Agreement shall be governed by and construed in
                            accordance with the Laws of the State of Minnesota.
                            Any controversies and/or litigation shall be
                            submitted to Hennepin County District Court.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Entire Agreement.
                            </b>{" "}
                            This Agreement represents the entire agreement and
                            supersedes all previous agreements and
                            understandings between the parties relating to the
                            subject matter hereof, and may be changed only in
                            writing, signed by both parties.
                          </li>

                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Successors and Assigns.
                            </b>{" "}
                            The provisions of this Agreement shall be binding
                            upon and shall be to the benefit of the parties,
                            their respective successors, and assigns.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Counterparts.
                            </b>{" "}
                            This Agreement may be executed in separate
                            counterparts, each of which when so executed and
                            delivered shall be an original for all purposes, but
                            all counterparts together shall constitute on
                            instrument.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Legal / Authorized Contact Details of the Parties.
                            </b>{" "}
                            To ensure true representation of the parties in the
                            execution of this contract and for purposes of
                            smooth implementation of the project engagement as
                            per the purchase order issued, both IPCS and the
                            SUBCONTRACTOR legal/authorized signatory details are
                            provided below:
                            <table style={{ fontSize: "13px" }} cellPadding="2">
                              <tr>
                                <td>
                                  <b>IPCS</b>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Name :</td>
                                <td>Kuldeep Dhar</td>
                              </tr>
                              <tr>
                                <td>Title :</td>
                                <td>Sr. Vice President</td>
                              </tr>
                              <tr>
                                <td>Phone :</td>
                                <td>952-541-4888</td>
                              </tr>
                              <tr>
                                <td>Eamil :</td>
                                <td>
                                  <span
                                    style={{
                                      color: "blue",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Kuldeep@ipcs.net
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>Registered Office :</td>
                                <td>
                                  METROPOINT, Suite 1595, 600 South Highway 169,
                                  Minneapolis MN 55426
                                </td>
                              </tr>
                            </table>
                            <table
                              style={{ fontSize: "13px", marginTop: "10px" }}
                              cellPadding="2"
                            >
                              <tr>
                                <td>
                                  <b>RECRUITER</b>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Name :</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Title :</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Phone :</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Mobile :</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>Eamil :</td>
                                <td>
                                  <span
                                    style={{
                                      color: "blue",
                                      textDecoration: "underline",
                                    }}
                                  ></span>
                                </td>
                              </tr>
                              <tr>
                                <td>Registered Office :</td>
                                <td></td>
                              </tr>
                            </table>
                            <p style={textStyle}>
                              All correspondence on the contract will be
                              exchanged between the above authorized signatories
                              on the Contract.
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Other Contract Requirements.
                            </b>{" "}
                            Any additional CLIENT-specific contract requirement
                            as may be required for the project engagement will
                            be included as Exhibit-2 and attached to the
                            Purchase Order.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Miscellaneous.
                            </b>{" "}
                            The Clauses 4, 5, 6, 10, 13, 15, 17, 18, and 21
                            shall survive the termination or expiration of this
                            Agreement.
                          </li>
                        </ol>
                        <p style={textStyle}>
                          In WITNESS the due execution of this Agreement this
                          date #CurrentDate# intending to be legally bound
                          hereby.
                        </p>
                        <p style={{ marginTop: "50px" }}>&nbsp;</p>
                        <table style={textStyle} width="100%" cellPadding="1">
                          <tr>
                            <td>
                              <h3>
                                <b>INTERNATIONAL PROJECTS</b>
                              </h3>
                              <h3>
                                <b>CONSULTANCY SERVICES (IPCS) INC.</b>
                              </h3>
                            </td>
                            <td>
                              <h3>
                                <b>RECRUITER</b>
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td height={80} style={{ verticalAlign: "bottom" }}>
                              By : __________________
                            </td>
                            <td height={80} style={{ verticalAlign: "bottom" }}>
                              By : __________________
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>(Duly authorized official)</td>
                          </tr>
                          <tr>
                            <td>Name : KULDEEP DHAR</td>
                            <td>Name : </td>
                          </tr>
                          <tr>
                            <td>Title : Sr. Vice President</td>
                            <td>Title : </td>
                          </tr>
                        </table>
                        <p style={{ marginTop: "800px" }}>&nbsp;</p>
                        <p style={textStyle}>Date : #CurrentDate#</p>
                        <p style={textStyle}>To :</p>
                        <p style={textStyle}>The Contracts Administrator</p>
                        <p style={{ fontSize: "13px" }}>
                          International Projects Consultancy Services Inc.
                          (IPCS)
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          METROPOINT, Suite 1595
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          600 South Highway 169, Minneapolis MN 55426
                        </p>
                        <p style={textStyle}>
                          RE: IPCS's Consulting Services Agreement (C2C), dated
                          #CurrentDate#
                          <ol>
                            <li style={textStyle}>
                              This is to confirm that Mahalani Circle, LLC
                              (RECRUITER) is a US Registered Company
                              incorporated in the State of
                              #state_in_corporation# in the year
                              #year_of_corporation#.
                            </li>
                            <li style={textStyle}>
                              The Company's Registered Office is located at
                              ##Address##
                            </li>
                            <li style={textStyle}>
                              The Federal ID of our Company is #federal_id#
                            </li>
                          </ol>
                        </p>
                        <p style={textStyle}>
                          I #subcontractor#, undersigned is the Title: Vice
                          President and authorized signatory for the above
                          Company and solemnly affirm that I am a permitted to
                          sign and execute the Consulting Services Agreement
                          (C2C) with IPCS.
                        </p>
                        <p style={{ fontSize: "13px", marginTop: "60px" }}>
                          Signature : ____________________________________
                        </p>
                        <p style={{ fontSize: "13px", marginTop: "5px" }}>
                          Title : (Owner / Executive Director - Authorized
                          Title)
                        </p>
                        <p style={{ fontSize: "13px", marginTop: "30px" }}>
                          <b>Contact Details : </b>
                        </p>
                        <p style={{ fontSize: "13px", marginTop: "5px" }}>
                          Mobile :{" "}
                        </p>
                        <p style={{ fontSize: "13px", marginTop: "5px" }}>
                          Email :{" "}
                        </p>
                        <p style={textStyle}>Date : #sign_date#</p>
                      </div>
                    </div>
                    <div className="col-md-12 text-center mt-3">
                      <Button
                        variant="primary"
                        //disabled={btnProcess || this.validate()}
                        onClick={() => this.handlePrintPdf("1")}
                      >
                        {btnProcess && (
                          <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
                        )}
                        Print PDF
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {data["tempType"] === "3" && (
                <div className="card card-body bg-secondary border shadow-none p-5">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <img id="Img1" src={`/assets/Img/logo.png`} />
                    </div>
                    <div className="col-md-12">
                      <div id="printBody2" ref="printBody2">
                        <h1 style={{ textAlign: "center" }}>
                          <b> Independent Contractor Agreement (1099 Basis)</b>
                        </h1>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "13px",
                            marginTop: "5px",
                          }}
                        >
                          Effective Date : #CurrentDate#
                        </p>
                        <p style={textStyle}>
                          This agreement is entered into between International
                          Projects Consultancy Services (IPCS) Inc., a Minnesota
                          Corporation (hereinafter referred to as “IPCS”),
                          Registered Office: METROPOINT, Suite 1595, 600 South
                          Highway 169, Minneapolis, MN 55426, and
                          #candidate_name# residing at: ##Address## (hereinafter
                          referred to as 'CONSULTANT').
                        </p>
                        <p style={textStyle}>
                          WHEREAS, IPCS is engaging the CONSULTANT, on 1099
                          basis, to perform and function as Role/Title on the IT
                          project engagement with #agency_name# under ID
                          #position_id#. IPCS is on the IT Master Contract(s)
                          with the State for providing IT services to state
                          government agencies.
                        </p>
                        <p style={textStyle}>
                          WHEREAS, the CONSULTANT has agreed to provide such
                          services in return for compensation as set forth in
                          Section 2 below;
                        </p>
                        <p style={textStyle}>
                          NOW THEREFORE, intending to be legally bound, the
                          parties hereby agree as follows:
                        </p>
                        <ol style={{ marginLeft: "-20px" }}>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>Term.</b>{" "}
                            The term of this Agreement will begin on the start
                            date of the project engagement which is provided in{" "}
                            <b style={{ textDecoration: "underline" }}>
                              Exhibit-1
                            </b>{" "}
                            attached to this Agreement, and will continue in
                            accordance with the Client/Agency requirements with
                            possible further renewal(s) through timely contract
                            extension amendments.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Scope of Work/Responsibilities.
                            </b>{" "}
                            The CONSULTANT shall provide services in line with
                            the scope of work requirements/responsibilities for
                            the role defined and as communicated and will bring
                            requisite experience and expertise for successfully
                            completing the tasks and duties to the satisfaction
                            of Agency stakeholders. Details of the Scope of
                            Work/Responsibilities are detailed in Exhibit-1
                            attached to this Agreement.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Work Location.
                            </b>{" "}
                            The work location for the services to be provided
                            are detailed in Exhibit-1.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Fee, Work Hours, Time Sheets, Payment.
                            </b>{" "}
                            In full consideration of the services provided IPCS
                            shall compensate the CONSULTANT at the rate of $ per
                            hour (all-inclusive) for the hours worked and
                            approved by the Agency. The CONSULTANT shall work 8
                            hours/day, maximum of 40 hours/week. As the hourly
                            rates on the Government Contracts are fixed, the
                            hourly rate paid by IPCS to the CONSULTANT shall not
                            increase during the term of the project engagement.
                            Further the CONSULTANT is not entitled to any
                            benefits (including medical insurance, life
                            insurance, retirement plans, etc.) provided by IPCS
                            to its employees.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Time Sheets.
                            </b>{" "}
                            The CONSULTANT shall submit weekly timesheets to the
                            Agency for approval through Agency designated portal
                            and forward a copy of approved time sheets to IPCS
                            Accounts by email to{" "}
                            <span style={linkStyle}>accounts@ipcs.net</span> .
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Payment.
                            </b>{" "}
                            The CONSULTANT shall be paid on a monthly basis.
                            IPCS shall make payment net fifteen (15) days from
                            the date invoice is received by IPCS for the actual
                            approved hours logged by the CONSULTANT supported by
                            Client signed and approved time sheets. All payments
                            to the CONSULTANT shall be paid via ACH for which
                            the CONSULTANT shall complete the required
                            formalities.
                            <p style={textStyle}>
                              It is understood that IPCS has no obligation under
                              local, state or federal laws regarding the taxes
                              paid by the CONSULTANT on the compensation
                              received for the services rendered. All taxes
                              applicable to any amounts paid by IPCS to
                              CONSULTANT for the services rendered shall be
                              CONSULTANT's liability and IPCS shall not withhold
                              nor pay any amounts for federal, state or
                              municipal income tax, social security,
                              unemployment or worker's compensation.
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Consultant Status.
                            </b>{" "}
                            It is expressly understood and agreed by IPCS and
                            the CONSULTANT that CONSULTANT is an independent
                            consultant and nothing herein contained or implied
                            shall at any time be construed to create the
                            relationship of employer and employee, partnership,
                            principal and agent, or joint venture between IPCS
                            and CONSULTANT. Further CONSULTANT shall follow and
                            adhere to Agency specific policies, working
                            standards and procedures in professional manner.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Confidentiality / Nondisclosure Obligations.
                            </b>{" "}
                            The CONSULTANT shall regard and preserve as
                            confidential any and all information shared by IPCS
                            with the CONSULTANT which is not generally available
                            in the marketplace. The CONSULTANT acknowledges that
                            any information received from IPCS and/or IPCS's
                            Client/Agency which is not generally available to
                            the public is the sole property of IPCS and/or
                            Client/Agency, and the CONSULTANT shall not utilize
                            any such information for his own benefit or the
                            benefit of third parties except in the performance
                            of the services as are required under this
                            Agreement.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Work Performance.
                            </b>{" "}
                            <p style={{ lineHeight: "20px" }}>
                              Adherence to Standards and Discipline. The
                              CONSULTANT acknowledges that it shall observe all
                              the applicable disciplinary rules and regulations
                              while engaged on the project.
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Completion of Assignment.
                            </b>{" "}
                            Considering the nature of the project engagement and
                            business need of the services on the project, the
                            CONSULTANT confirms being committed to perform
                            contracted work for duration of the project
                            engagement and for extended periods as the
                            Client/Agency may require.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Background and Drug Check.
                            </b>{" "}
                            As part of the onboarding process, the CONSULTANT
                            shall be required to undergo Client-specific
                            background check which may include - 7-year Criminal
                            Felony and Misdemeanor by Counties, 7 year Statewide
                            Criminal Search, 7-year Employment History, Drug
                            Testing, Education Report, Employee Credit, Federal
                            Criminal, National Sex Offender Registry,
                            Professional Reference Check Report by Outside
                            Agency, SSN Trace, SSN Validation, SSN Verification,
                            and Finger Print check before starting on the
                            project.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Non-compete.
                            </b>{" "}
                            Under no circumstances, during the currency of the
                            contract engagement and for one year after the
                            termination of the engagement, the CONSULTANT shall
                            solicit IPCS's Client/Agency on any matter or
                            solicit employment or new contract or bypass IPCS on
                            any matter including contract transfer to any other
                            vendor or interact or establish any relationship
                            with IPCS's Client/Agency directly or indirectly.
                            CONSULTANT acknowledges that any breach of this
                            restriction will do a great harm to IPCS and
                            accordingly the CONSULTANT shall refrain from any
                            such violation of this contract condition which
                            could result in legal action.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Liabilities.
                            </b>{" "}
                            The CONSULTANT agrees to indemnify and hold IPCS
                            harmless from and against any and all claims,
                            judgments, demands or liabilities arising out of or
                            in connection with the performances of services of
                            this Agreement, including but not limited to any
                            claim for federal employment taxes, state and county
                            taxes/fees, and/or any previous non-compete etc. and
                            any claims arising from contracts between CONSULTANT
                            and any third parties. In the event of any
                            limitation of damages held unenforceable, then the
                            parties agree that by reason of the difficulty in
                            foreseeing possible damages, all liability to IPCS
                            shall be limited to one hundred dollars ($100) as
                            liquidated damages and not as penalty and shall in
                            no event include incidental and consequential
                            damages.
                          </li>

                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Termination of Services.
                            </b>{" "}
                            IPCS may terminate the consulting services agreement
                            hereunder at any time without notice should the
                            Client/Agency for whom the CONSULTANT has provided
                            service elects to do so for any reason including due
                            to non-availability of funds, abrupt cancellation of
                            federal funds or non-performance and convenience.
                            Despite such provision, IPCS shall use its best
                            efforts to provide as much advance notice as
                            possible and immediately upon notification of any
                            such termination by the Client.
                            <p style={textStyle}>
                              Further the consulting services agreement shall be
                              terminated immediately without any prior notice
                              for any reasons including the following:
                            </p>
                            <ol style={{ listStyleType: "lower-roman" }}>
                              <li style={textStyle}>
                                Any guilt of improper conduct or dishonesty or
                                other breach of duty of CONSULTANT affecting
                                IPCS, and Agency where CONSULTANT is assigned on
                                the project.
                              </li>
                              <li>
                                Any fraudulent violation with regard to Identity
                                Verification (Passport, Driver's License, State
                                ID's) and/or misrepresentation.
                              </li>
                              <li>
                                Use of alcohol or other drugs in a manner, which
                                affects the performance of duties,
                                responsibilities and obligations while engaged
                                on the project.
                              </li>
                              <li>
                                Conviction of CONSULTANT of a felony or of any
                                crime involving misrepresentation, moral
                                turpitude or fraud.
                              </li>
                              <li>
                                Commission by CONSULTANT of any other willful or
                                intentional act which could reasonably be
                                expected to injure the reputation, business or
                                business relationships of IPCS and/or Agency.
                              </li>
                              <li>
                                The existence of any court order or settlement
                                agreement prohibiting CONSULTANT to work due to
                                any non-competes.
                              </li>
                            </ol>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Termination of Agreement.
                            </b>{" "}
                            This Agreement may be terminated: (a) by mutual
                            agreement of the parties at any time; (b) upon
                            two-week prior written notice by either party.
                            Termination shall be effective as of the date of
                            notice of termination. In the event Client elects to
                            terminate the engagement for whatever reasons with
                            no advance notice, this agreement will also be
                            terminated accordingly for such situations without
                            any advance notice.
                          </li>

                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Remedies for Breach.
                            </b>{" "}
                            In the event of a breach of this Agreement, the
                            aggrieved party may, in addition to any remedy
                            provided in this Agreement or under law or at
                            equity, discontinue performance without incurring
                            any liability to the breaching party of any sort and
                            without further obligation to the breaching party.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Warranties.
                            </b>{" "}
                            The CONSULTANT warrants to IPCS that the services,
                            and any materials developed hereunder, shall (a) be
                            performed in a workmanlike and professional manner
                            and be free of defects; (b) comply with any
                            applicable law, rule or regulation; and (c) not
                            violate or infringe upon any copyright, patent,
                            trade secret or other property, contractual,
                            employment or confidentiality right of a third
                            party.
                          </li>

                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Assignment.
                            </b>{" "}
                            Neither IPCS nor the CONSULTANT may assign any part
                            or whole of this Agreement or any rights hereunder,
                            without written permission of the other party.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Rights to Development.
                            </b>{" "}
                            All software documentation, modifications to
                            software, and products developed by the CONSULTANT
                            under this Agreement shall be the sole property of
                            IPCS's Client/Agency.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Governing Law.
                            </b>{" "}
                            This Agreement is made and entered into the parties
                            in the State of Minnesota and shall be construed
                            according to the laws of that state, with the
                            exception of its conflicts law.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Force Majeure.
                            </b>{" "}
                            Neither party shall be under any liability to the
                            other party for any delay or failure to perform
                            under this Agreement to the extent such delay is
                            wholly or partially caused by strikes, labor
                            disputes, acts of God, or similar circumstances
                            beyond its control.
                          </li>

                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Entire Agreement.
                            </b>{" "}
                            This Agreement represents the entire agreement and
                            supersedes all previous agreements and
                            understandings between the parties relating to the
                            subject matter hereof, and may be changed only in
                            writing, signed by both parties.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Successors and Assigns.
                            </b>{" "}
                            The provisions of this Agreement shall be binding
                            upon and shall inure to the benefit of IPCS and its
                            successors and assigns.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Counterparts.
                            </b>{" "}
                            This Agreement may be executed in separate
                            counterparts, each of which when so executed and
                            delivered shall be an original for all purposes, but
                            all counterparts together shall constitute on
                            instrument.
                          </li>

                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Miscellaneous.
                            </b>{" "}
                            Clauses 4, 7, 8, 12, 13, 15 shall survive the
                            termination or expiration of this Agreement.
                          </li>
                        </ol>
                        <p style={{ marginTop: "120px" }}>&nbsp;</p>
                        <p style={textStyle}>
                          In WITNESS the due execution of this Agreement this
                          date #CurrentDate# intending to be legally bound
                          hereby.
                        </p>
                        <p style={{ marginTop: "30px" }}>&nbsp;</p>

                        <table style={textStyle} width="100%" cellPadding="1">
                          <tr>
                            <td>
                              <h3>
                                <b>INTERNATIONAL PROJECTS</b>
                              </h3>
                              <h3>
                                <b>CONSULTANCY SERVICES (IPCS) INC.</b>
                              </h3>
                            </td>
                            <td>
                              <h3>
                                <b>CONSULTANT</b>
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td height={80} style={{ verticalAlign: "bottom" }}>
                              By : __________________
                            </td>
                            <td height={80} style={{ verticalAlign: "bottom" }}>
                              By : __________________
                            </td>
                          </tr>
                          <tr>
                            <td>(Signature)</td>
                            <td>(Signature)</td>
                          </tr>
                          <tr>
                            <td height={40}>Name : KULDEEP DHAR</td>
                            <td height={40}>Name : </td>
                          </tr>
                          <tr>
                            <td height={40}>Title : Sr. Vice President</td>
                            <td height={40}>
                              Last Four Digits of Social Security #ssn_number#{" "}
                            </td>
                          </tr>
                          <tr>
                            <td height={40}>Data : __________________</td>
                            <td height={40}>Data : __________________</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-12 text-center mt-3">
                      <Button
                        variant="primary"
                        //disabled={btnProcess || this.validate()}
                        onClick={() => this.handlePrintPdf("2")}
                      >
                        {btnProcess && (
                          <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
                        )}
                        Print PDF
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {data["tempType"] === "4" && (
                <div className="card card-body bg-secondary border shadow-none p-5">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <img id="Img1" src={`/assets/Img/logo.png`} />
                    </div>
                    <div className="col-md-12">
                      <div id="printBody3" ref="printBody3">
                        <h2 style={{ textAlign: "center" }}>
                          <b>
                            {" "}
                            CONSULTING SERVICES AGREEMENT (W2-Basis with no
                            benefits)
                          </b>
                        </h2>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "13px",
                            marginTop: "5px",
                          }}
                        >
                          Effective Date : #CurrentDate#
                        </p>
                        <p style={textStyle}>
                          This Consulting Services Agreement ('Agreement') is
                          made by International Projects Consultancy Services
                          (IPCS), Inc. (hereinafter referred to as “IPCS”) with
                          its office at METROPOINT, Suite 1595, 600 South
                          Highway 169, Minneapolis, MN 55426 and Candidate Name
                          (last 4 digits of Social Security Number #ssn_number#
                          ) (hereinafter referred to as 'CONSULTANT') residing
                          at ##Address##.
                        </p>
                        <p style={textStyle}>
                          This Agreement sets forth the exclusive terms and
                          conditions by which IPCS is acquiring CONSULTANT's
                          services on a temporary basis on W2- hourly (No
                          benefits) to perform as Role/Title #position_title# on
                          IPCS's State Government project with Name of
                          Client/Agency_ (CLIENT) during the term of this
                          Agreement. In consideration of the mutual obligations
                          specified in this Agreement, and any compensation paid
                          to the CONSULTANT for the services, the parties agree
                          as follows:
                        </p>

                        <ol style={{ marginLeft: "-20px" }}>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Engagement Term.
                            </b>{" "}
                            The term of the project engagement of the CONSULTANT
                            shall begin on the date which shall be immediately
                            after completion of the background checks and shall
                            continue for the duration in accordance with the
                            service requirements with possible extensions for
                            additional periods. Exhibit-1, attached to this
                            Agreement provides this information. As engagement
                            term is the essence of this agreement, CONSULTANT
                            recognizes and agrees to work on the project
                            engagement for the contract duration and for any
                            extended periods as may be required by the
                            CLIENT/Agency.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Scope of Services/Duties.
                            </b>{" "}
                            The CONSULTANT shall render all reasonable duties as
                            expected in line with CLIENT/Agency requirements and
                            as detailed in Exhibit-1 attached to this agreement.
                            The CONSULTANT shall devote full abilities in the
                            performance of tasks and duties, and complete tasks
                            and deliverables to the satisfaction of the
                            CLIENT/Agency.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Compensation.
                            </b>{" "}
                            For the satisfactory and timely completion of
                            services rendered, IPCS shall compensate the
                            CONSULTANT at an all-inclusive hourly rate of #rate#
                            rate/hr from the application form
                            #project_startDate# on W2 (with no benefits) for all
                            hours worked and approved by the CLIENT/Agency.
                            <p style={textStyle}>
                              This compensation is based on the understanding
                              that CONSULTANT shall work 8 hours/day, max. 40
                              hours/week. For any extra hours over and above 40
                              hours/week, if required to be worked, the
                              CONSULTANT shall seek prior approval from
                              CLIENT/Agency and all such hours shall be
                              compensated at the flat rate as indicated above.
                              As the hourly rates to IPCS are fixed on the
                              government IT Master Contracts, the hourly rate
                              paid to the CONSULTANT shall remain firm and shall
                              not increase during the term of the project
                              engagement.
                            </p>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Taxes and withholding.
                            </b>{" "}
                            IPCS's payroll is managed by ADP. IPCS, as employer,
                            shall perform the responsibility of withholding all
                            applicable taxes on earnings for the CONSULTANT
                            based on the W4 form completed and signed by the
                            CONSULTANT.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Payment.
                            </b>{" "}
                            Compensation shall be paid on a monthly basis in
                            accordance with the payroll IPCS has set-up with
                            ADP. For each month, payment shall be made by the
                            7th of the next month. All payments to the
                            CONSULTANT shall be paid via ACH directly to
                            CONSULTANT’s designated Bank Account for which the
                            CONSULTANT shall provide the required ACH
                            information and Voided Check for completing the
                            required formalities.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Benefits.
                            </b>{" "}
                            Under this agreement. CONSULTANT is not entitled to
                            any company benefits including vacation, standard
                            holidays, insurance and healthcare. In the event the
                            CONSULTANT desires to avail of these benefits the
                            hourly rate as indicated in Clause 3 above shall be
                            readjusted downward to factor the costs to IPCS for
                            such benefits.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Working Hours.
                            </b>{" "}
                            It is expected that the CONSULTANT shall work the
                            normal working hours of 8 hours/day, max. 40
                            hours/week.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Reporting.
                            </b>{" "}
                            IPCS shall provide the reporting details to the
                            CONSULTANT by email after completing all the
                            required onboarding and background check
                            formalities. The CONSULTANT shall make all necessary
                            arrangements to ensure on-time reporting in line
                            with the CLIENT/Agency stipulations.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Work Location.
                            </b>{" "}
                            The CONSULTANT shall perform tasks and discharge
                            responsibilities on time and in a quality manner and
                            to the satisfaction of the Agency Stakeholders
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Adherence to Standards and Policies.
                            </b>{" "}
                            The CONSULTANT shall follow and adhere to
                            CLIENT/Agency-specific policies, working standards
                            and procedures in professional manner, and maintain
                            confidentiality of information and data at all
                            times.
                          </li>

                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Timesheets.
                            </b>{" "}
                            The CONSULTANT shall submit weekly timesheets to the
                            CLIENT/Agency for approval for the hours worked and
                            forward a copy of such approved time sheets to IPCS
                            by email to{" "}
                            <span style={linkStyle}>accounts@ipcs.net</span> If
                            required, CONSULTANT shall provide IPCS with any
                            reports IPCS deems necessary, including, but not
                            limited to, summaries of work-related activities,
                            project deliverable completion dates, problems, if
                            any, experienced and any other important matters
                            related to a particular project or projects.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Confidentiality and Non-Disclosure Obligations.
                            </b>{" "}
                            The CONSULTANT agrees to keep the terms of this
                            agreement confidential and may not disclose the
                            terms hereto except with IPCS's written consent.
                            Also, the CONSULTANT shall regard and preserve as
                            confidential any and all information and trade
                            secrets shared by IPCS and by CLIENT/Agency which
                            are not generally available in the marketplace. The
                            CONSULTANT acknowledges that any information
                            received from CLIENT/Agency during the currency of
                            the project engagement which is not generally
                            available to the public is the sole property of the
                            CLIENT/Agency, and the CONSULTANT shall not utilize
                            such information for own benefit or the benefit of
                            third parties, except in the performance of the
                            services as are required under the engagement on the
                            project with the CLIENT/Agency.
                          </li>

                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Non-Competition /Restriction on Non solicitation.
                            </b>{" "}
                            Considering the nature of the project engagement
                            with the government agencies, the CONSULTANT
                            acknowledges and agrees that while on the project
                            engagement CONSULTANT shall a) not approach or
                            solicit directly or indirectly any employment or
                            consulting opportunities with the CLIENT/Agency in
                            an effort to compete with IPCS b) not to
                            independently contract directly or indirectly with
                            any other CLIENT/Agency vendor(s) and switch
                            employer in order to continue working on the
                            CLIENT/Agency project. This restriction is for a
                            period of one year from the last date the CONSULTANT
                            provides services to the CLIENT/Agency on behalf of
                            IPCS. The CONSULTANT ensures not to violate this
                            clause, and if violated IPCS shall seek legal
                            injunction with compensation damages.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Work Performance.
                            </b>{" "}
                            As required, CONSULTANT shall perform satisfactorily
                            on the project and demonstrate effective use of
                            skills, knowledge, experience and expertise and
                            provide required deliverables, work updates, and
                            overall meeting the CLIENT/Agency's expectations.
                          </li>

                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Adherence to Project Schedule.
                            </b>{" "}
                            The CONSULTANT agrees to provide services as
                            required by the CLIENT/Agency for the duration as
                            stated in the Exhibit-1 and for any extended periods
                            as may be required by the CLIENT/Agency. This is the
                            essence of the agreement. Any deviation to this
                            shall result in cost implications to IPCS and loss
                            of goodwill with the CLIENT/Agency which if violated
                            shall result in costs as estimated to be borne by
                            the CONSULTANT.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Termination of Engagement.
                            </b>{" "}
                            IPCS may terminate the agreement hereunder at any
                            time without notice should CLIENT elect to do so for
                            any reason including due to non-availability of
                            funds, abrupt cancellation of federal funds or
                            non-performance and convenience. Despite such
                            provision, IPCS shall use its best efforts to
                            provide as much advance notice as possible and
                            immediately upon notification of any termination by
                            the CLIENT/Agency. The agreement could also be
                            terminated without any prior notice any reasons
                            including the following:
                            <ol style={{ listStyleType: "lower-roman" }}>
                              <li style={textStyle}>
                                Any guilt of improper conduct or dishonesty or
                                other breach of duty of CONSULTANT affecting the
                                CLIENT/Agency.
                              </li>
                              <li>
                                Any fraudulent violation with regard to
                                CONSULTANT's Identity Verification (Passport,
                                Visa for Work, Green Card, Driver License, State
                                ID's, and experience, technical skills or
                                reference misrepresentation, etc.).
                              </li>
                              <li>
                                Use of alcohol or other drugs in a manner, which
                                affects the performance of CONSULTANT or duties,
                                responsibilities and obligations while engaged
                                on the project.
                              </li>
                              <li>
                                Conviction of CONSULTANT of a felony or of any
                                crime involving misrepresentation, moral
                                turpitude or fraud.
                              </li>
                              <li>
                                Commission by CONSULTANT of any other willful or
                                intentional act which could reasonably be
                                expected to injure the reputation, business or
                                business relationships of IPCS or CLIENT/Agency.
                              </li>
                              <li>
                                The existence of any court order or settlement
                                agreement prohibiting CONSULTANT continued
                                engagement with IPCS.
                              </li>
                              <li>
                                If CONSULTANT has signed and/or entered into a
                                written or oral non-competition agreement,
                                confidentiality agreement, proprietary
                                information agreement, trade secret agreement or
                                any other agreement which would prevent
                                CONSULTANT from working for IPCS's project
                                engagement.
                              </li>
                              <li>
                                Non-performance of CONSULTANT as may be deemed
                                by CLIENT/Agency at any time during the project
                                engagement.
                              </li>
                            </ol>
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Remedies for Breach.
                            </b>{" "}
                            In the event of a breach of this Agreement, the
                            aggrieved party may, in addition to any remedy
                            provided in this Agreement or under law or at
                            equity, discontinue performance without incurring
                            any liability to the breaching party of any sort and
                            without further obligation to the breaching party.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Warranties.
                            </b>{" "}
                            The CONSULTANT warrants to IPCS that the services,
                            and any materials developed hereunder, shall (a) be
                            performed in a workmanlike and professional manner
                            and be free of defects; (b) comply with any
                            applicable law, rule or regulation; and (c) not
                            violate or infringe upon any copyright, patent,
                            trade secret or other property, contractual,
                            employment or confidentiality right of a third
                            party.
                          </li>

                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Force Majeure.
                            </b>{" "}
                            Neither party shall be under any liability to the
                            other party for any delay or failure to perform
                            under this Agreement to the extent such delay is
                            wholly or partially caused by strikes, labor
                            disputes, acts of God, or similar circumstances
                            beyond its control.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Notices.
                            </b>{" "}
                            Any notices required or permitted under this
                            Agreement shall be deemed to have been duly given
                            when personally delivered or sent by certified mail,
                            return receipt, requested to the party entitled
                            thereto at the address set forth on this Agreement
                            or as thereafter supplied by such party from
                            time-to-time.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Background and Drug Check.
                            </b>{" "}
                            Considering the security requirements on the
                            projects with Government Agencies, the CONSULTANT is
                            required to undergo background check as part of the
                            onboarding requirements.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Rights to Development.
                            </b>{" "}
                            All software documentation, modifications to
                            software, or documentation and other products
                            developed by CONSULTANT under this Agreement shall
                            be the sole property of the CLIENT/Agency.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Entire Agreement.
                            </b>{" "}
                            This Agreement represents the entire agreement and
                            supersedes all previous agreements and
                            understandings between the parties relating to the
                            subject matter hereof, and may be changed only in
                            writing, signed by both parties.
                          </li>
                          <li style={textStyle}>
                            <b style={{ textDecoration: "underline" }}>
                              Successors and Assigns.
                            </b>{" "}
                            The provisions of this Agreement shall be binding
                            upon and shall be to the benefit of the parties,
                            their respective successors, and assigns.
                          </li>
                        </ol>
                        <p style={{ marginTop: "150px" }}>&nbsp;</p>
                        <p style={textStyle}>
                          In WITNESS the due execution of this Agreement this
                          date #CurrentDate# intending to be legally bound
                          hereby.
                        </p>
                        <p style={{ marginTop: "20px" }}>&nbsp;</p>
                        <table style={textStyle} width="100%" cellPadding="1">
                          <tr>
                            <td>
                              <h3>
                                <b>REVIEWED, UNDERSTOOD and AGREED</b>
                              </h3>
                              <h3>
                                <b>CONSULTANT (Employee)</b>
                              </h3>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td height={80} style={{ verticalAlign: "bottom" }}>
                              ______________________
                            </td>
                            <td height={80} style={{ verticalAlign: "bottom" }}>
                              Dated : __________________
                            </td>
                          </tr>
                          <tr>
                            <td>Signature</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td height={40}>Name : </td>
                            <td height={40}></td>
                          </tr>
                          <tr>
                            <td height={40}>Current Address : </td>
                            <td height={40}></td>
                          </tr>
                          <tr>
                            <td height={40}>Phone : </td>
                            <td height={40}></td>
                          </tr>
                          <tr>
                            <td height={40}>Email : </td>
                            <td height={40}></td>
                          </tr>
                        </table>
                        <table style={textStyle} width="100%" cellPadding="1">
                          <tr>
                            <td>
                              <h3>
                                <b>EMPLOYER</b>
                              </h3>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <h3>
                                <b>INTERNATIONAL PROJECTS</b>
                              </h3>
                              <h3>
                                <b>CONSULTANCY SERVICES (IPCS) INC.</b>
                              </h3>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td height={80} style={{ verticalAlign: "bottom" }}>
                              By : ______________________
                            </td>
                            <td
                              height={80}
                              style={{ verticalAlign: "bottom" }}
                            ></td>
                          </tr>
                          <tr>
                            <td height={40}>Name : KULDEEP DHAR</td>
                            <td height={40}></td>
                          </tr>
                          <tr>
                            <td height={40}>Title : Sr. Vice President</td>
                            <td height={40}></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-12 text-center mt-3">
                      <Button
                        variant="primary"
                        //disabled={btnProcess || this.validate()}
                        onClick={() => this.handlePrintPdf("3")}
                      >
                        {btnProcess && (
                          <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
                        )}
                        Print PDF
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleModalShowHide}>
            Close
          </Button>
          {docType !== "contract" &&
            <Button
              variant="primary"
              disabled={this.validate()}
              onClick={this.handleSubmit}
            >
              Save
            </Button>}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DocusignPopup;
