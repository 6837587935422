import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import http from "../../services/HttpService";
import config from "../../config.json";

class DispencingPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPartnerType: "Distributer",
      partners: [],
      currentPage: 1,
      pageSize: 13,
      startIndex: 0,
      endIndex: 5,
    };
  }

  async componentDidMount() {
    const { currentPage, pageSize, selectedPartnerType } = this.state;
    this.loadPartners(currentPage, pageSize, selectedPartnerType);
  }

  loadPartners = async (currentPage, pageSize, partnerType) => {
    const { data: partners } = await http.get(
      config.SetupUsers.GetPartners +
        pageSize +
        "&pageNumber=" +
        currentPage +
        "&type=" +
        partnerType
    );
    console.log(partners);
    this.setState({ partners });
  };

  render() {
    const { partners } = this.state;
    const {
      dispencingShowHide,
      handleDispencingModalShowHide,
      selectedObj,
    } = this.props;
    return (
      <Modal show={dispencingShowHide} size="lg">
        <Modal.Header closeButton onClick={handleDispencingModalShowHide}>
          <Modal.Title>List of Dispensing Centers - View</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          <div className="row">
            <div className="col-md-12 form-group">
              <p>
                Advance informational update to Cannabis Patient Dispensing
                Centers of patient’s approval of receiving the Cannabis, patient
                scheduling an appointment and meeting with the pharmacist.
              </p>
            </div>
            <div className="col-md-12 form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-control-label"
              >
                Notes
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="2"
              ></textarea>
            </div>
            <div className="col-md-12 form-group">
              <div className="table-responsive bg-white">
                <table className="table align-items-center table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Address</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Pharmacist</th>
                    </tr>
                  </thead>
                  <tbody>
                    {partners.map((p, key) => (
                      <tr>
                        <td>{++key}</td>
                        <td>{p.label}</td>
                        <td>{p.description}</td>
                        <td>{p.portalUrl}</td>
                        <td>{p.email}</td>
                        <td>{p.zip}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="danger" onClick={handleDispencingModalShowHide}>
            <i className="fa fa-times" aria-hidden="true"></i> Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DispencingPopup;
