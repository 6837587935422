import React, { Component } from "react";
import { Card } from "react-bootstrap";
import http from "../../services/HttpService";
import config from "../../config";
import Moment from "react-moment";

class TimerMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tmrMessages: [],
    };
  }

  async componentDidMount() {
    const { workflowTask } = this.props;
    const { data: tmrMessages } = await http.get(
      config.GetTimerMessages + workflowTask.ciid
    );
    this.setState({ tmrMessages });
  }

  render() {
    const { tmrMessages } = this.state;
    return (
      <React.Fragment>
        {/* <table
          className="table table-striped align-items-center table-flush"
          id="tbltimersetting"
        >
          <tbody>
            <tr>
              <td colSpan="4">
                <b>Default Settings</b>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "middle", width: "28%" }}>
                Duration : &nbsp; <label id="lblDuration" />
              </td>
              <td style={{ verticalAlign: "middle", width: "28%" }}>
                Occurances : &nbsp; <label id="lblOccurance" />
              </td>
              <td style={{ verticalAlign: "middle" }}>
                Default Messages (s) :
              </td>
              <td style={{ verticalAlign: "middle", width: "30%" }}>
                <select
                  className="form-control form-control-sm"
                  name="ddlDefaultMessage"
                />
              </td>
            </tr>
          </tbody>
        </table> */}
        <div className="table-responsive">
          <table className="table table-striped align-items-center table-flush">
            <thead className="thead-light-green">
              <tr>
                <th>S.No.</th>
                <th>Date & Time</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {tmrMessages.map((item, key) => (
                <tr>
                  <td>{key + 1}</td>
                  <td>
                    <Moment format="MMM Do YYYY, h:mm a">
                      {item.startDateTime}
                    </Moment>
                  </td>
                  <td style={{ whiteSpace: "pre-wrap" }}>
                    {decodeURIComponent(item.message)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default TimerMessage;
