import React, { Component } from "react";
import PropTypes from "prop-types";

class ToastTemplate extends Component {
  render() {
    const { type, title, message } = this.props;
    return (
      <div
        data-notify="container"
        className={`alert alert-dismissible alert-${type} alert-notify animated`}
        role="alert"
        style={{ marginBottom: 0 }}
      >
        <span className="alert-icon ni ni-bell-55" data-notify="icon"></span>
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
        <button
          type="button"
          className="close"
          data-notify="dismiss"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

ToastTemplate.propTypes = {};

export default ToastTemplate;
