import React, { Component } from "react";
import ClientHeader from "../ClientHeader";
import Footer from "../Footer";
import PageHeader from "../PageHeader";
import { Card, Accordion } from "react-bootstrap";
import Document from "../PortalForms/Document";
import MessageWithApplicant from "../PortalForms/MessageWithApplicant";
import PhoneCallApplicant from "../PortalForms/PhoneCallApplicant";
import ScheduleMeeting from "../PortalForms/ScheduleMeeting";
import GroupMessaging from "../GroupMessaging";
import GroupDocuments from "../GroupDocuments";
import FormsLibrary from "../PortalForms/FormsLibrary";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import http from "../../services/HttpService";
import config from "../../config.json";
import AddMedicine from "./AddMedicine";
import PatientEvalForm from "./PatientEvalForm";

class PatientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: [{ name: "My Patients", url: "/MyPatient" }],
      hideShowAll: false,
      notification: 0,
      accordianData: {},
      allApps: [],
      paymentShowHide: false,
      selectedWorkflowTask: {},
      mdCond: "",
      emailMsgShowHide: false,
      runningApplication: [],
      showHide: false,
      medicineTransaction: [],
      documents: [],
    };
  }

  handleSurveyShowHide = () => {
    this.setState({ surveyShowHide: !this.state.surveyShowHide });
  };

  async componentDidMount() {
    const { obj: item } =
      this.props.location.state === undefined ? {} : this.props.location.state;
    const { data: medicineTransaction } = await http.get(
      config.GetMedicineTransactions + item.regId
    );
    console.log(medicineTransaction);
    this.setState({ medicineTransaction });
  }

  handleSelect = (index, name) => {
    const accordianData = { ...this.state.accordianData };
    const checkIndex = accordianData[name];
    if (checkIndex === index) {
      accordianData[name] = null;
      this.setState({ accordianData });
    } else {
      accordianData[name] = index;
      this.setState({ accordianData });
    }
  };

  getWfPhasesColor(phase) {
    switch (phase) {
      case "Closed":
        return "btn-default";
      case "Review":
        return "btn-secondary bg-yellow border-0";
      case "Application":
        return "btn-primary";
      case "Pending":
        return "btn-info";
      case "Approved":
        return "btn-success";
      case "Denied":
        return "btn-danger";
      case "Withdrawn":
        return "btn-warning";
    }
  }

  handlePaymentModalShowHide = (obj) => {
    this.setState({
      paymentShowHide: !this.state.paymentShowHide,
      selectedWorkflowTask: obj,
    });
  };

  getApprovalId = (item) => {
    const dt = new Date(item.appStartDateTime);
    return (
      "PT" +
      dt.getDate() +
      "" +
      (dt.getMonth() + 1) +
      "" +
      dt.getHours() +
      "" +
      dt.getMinutes()
    );
  };

  getCaregiverDetail = (appData) => {
    const aWorkfinityApp = JSON.parse(appData);
    const appShortName = Object.keys(aWorkfinityApp)[1];
    const aWorkfinityAppEntity = aWorkfinityApp[appShortName];
    const cvData = aWorkfinityAppEntity["patientinformationself"];
    if (cvData !== null || cvData !== "") {
      return cvData["@Have_Caregiver"];
    }
    //console.log(aWorkfinityAppEntity);
    return "";
  };

  getMedicalConditions = async () => {
    const { data: application } = await http.get(config.GetApplication + 100);
    const aWorkfinityApp = JSON.parse(application.applicationData);
    const appShortName = Object.keys(aWorkfinityApp)[1];
    const aWorkfinityAppEntity = aWorkfinityApp[appShortName];
    console.log(aWorkfinityAppEntity);
    let mdCond = "";
    Object.keys(aWorkfinityAppEntity["patientmedicaldiagnosis"]).forEach(
      function (keyValue1, index1, map1) {
        //console.log(keyValue1);
        if (keyValue1 !== "EntityId") {
          //console.log(aWorkfinityAppEntity["patientmedicaldiagnosis"][keyValue1]);
          mdCond += aWorkfinityAppEntity["patientmedicaldiagnosis"][
            keyValue1
          ].replace("patientmedicaldiagnosis.", "");
          mdCond += " <br/> ";
        }
      }
    );

    //mdCond.replace('patientmedicaldiagnosis.','');

    this.setState({ mdCond });
  };

  handleEmailMsgShowHide = () => {
    this.setState({ emailMsgShowHide: !this.state.emailMsgShowHide });
  };

  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  getMedicinList = (item) => {
    const mdList = JSON.parse(item);
    const tbl = (
      <table className="table table-bordered">
        <thead>
          <th>Medicine</th>
          <th>Dosage</th>
          <th>Notes</th>
        </thead>
        <tbody>
          {mdList.map((x) => (
            <tr>
              <td>{x.medicine}</td>
              <td>{x.dosage}</td>
              <td>{x.notes}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );

    return tbl;
  };

  handleDownloadFile = async (item) => {
    try {
      const { data } = await http.get(config.DownloadDocument + item.docId, {
        responseType: "blob",
      });
      if (data.size > 0) {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", item.docName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }

    // const { data } = await http.get(config.DownloadDocument + item.docId);
    // if (data !== "Fail") {
    //   this.download(item.docName.trim(), data);
    // }
  };

  render() {
    const {
      route,
      hideShowAll,
      notification,
      accordianData,
      documents,
      showHide,
      medicineTransaction,
      surveyShowHide,
    } = this.state;
    const bgColor = "bg-admin-light border-admin-left";
    const bgColor1 = "bg-admin-sub-light border-admin-sub-left";

    const { obj: item } =
      this.props.location.state === undefined ? {} : this.props.location.state;

    //console.log(obj);
    const task = { ciid: item.appId };
    return (
      <React.Fragment>
        <div className="container border p-0 mb-4 bg-white">
          <ClientHeader></ClientHeader>

          <PageHeader
            route={route}
            parentTitle="Patient Details"
            headerTitle="Patient Details"
          ></PageHeader>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table align-items-center table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th>
                          Date
                          <br />
                          Enrolled
                        </th>
                        <th>Patient ID</th>
                        <th>Patient</th>
                        <th>
                          Caregiver
                          <br /> Allowed
                        </th>
                        <th>
                          Program Status
                          <br /> Documents
                        </th>
                        <th>
                          Medical <br />
                          Condition
                        </th>
                        <th>
                          Date
                          <br />
                          Approved
                        </th>
                        <th>
                          Survey
                          <br />
                          Due
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="align-top">
                          <Moment format="MMM Do YYYY">
                            {item.regDateTime}
                          </Moment>
                          <br></br>
                          <button
                            className="btn btn-link btn-sm p-0 m-0"
                            onClick={this.handleEmailMsgShowHide}
                          >
                            View Message
                          </button>
                        </td>
                        <td className="align-top">
                          {item.regId} <br></br>
                          {item.appStatus === 1 && this.getApprovalId(item)}
                        </td>
                        <td className="align-top">
                          {item.applicantName} <br></br>
                          Phone : {item.phone} <br></br>
                          Email : {item.email} <br></br>
                          Address : {item.state} {item.county}
                        </td>
                        <td className="align-top">
                          {this.getCaregiverDetail(item.appData)}
                        </td>
                        <td className="align-top">
                          {item.appStartDateTime !== null && (
                            <React.Fragment>
                              <Moment format="MMM Do YYYY">
                                {item.appStartDateTime}
                              </Moment>
                              <br></br>
                              <Moment format="h:mm A">
                                {item.appStartDateTime}
                              </Moment>
                              <br></br>
                              <Link
                                to={{
                                  pathname: "/ApplicationSummary",
                                  state: { task: item },
                                }}
                                className="btn btn-link btn-sm p-0 m-0"
                              >
                                Application Forms
                              </Link>
                              <br />
                              {item.paymentDetails !== null && (
                                <React.Fragment>
                                  <button
                                    className="btn btn-link btn-sm p-0 m-0"
                                    onClick={() =>
                                      this.handlePaymentModalShowHide(item)
                                    }
                                  >
                                    Payment Receipt
                                  </button>
                                  <br />
                                </React.Fragment>
                              )}

                              {item.appDocuments !== null &&
                                item.appDocuments.map((doc) => (
                                  <React.Fragment>
                                    <button
                                      className="btn btn-link p-0 m-0 btn-sm"
                                      onClick={() =>
                                        this.handleDownloadFile(doc)
                                      }
                                    >
                                      {doc.docName}
                                    </button>
                                    <br></br>
                                  </React.Fragment>
                                ))}
                            </React.Fragment>
                          )}
                        </td>
                        <td className="align-top">
                          {this.state.mdCond.split("<br/>").map((x) => (
                            <React.Fragment>
                              {x} <br></br>
                            </React.Fragment>
                          ))}
                        </td>
                        <td className="align-top">
                          {item.appStatus === 1 && (
                            <React.Fragment>
                              <Moment format="MMM Do YYYY">
                                {item.appStartDateTime}
                              </Moment>
                              <br></br>
                              <Moment format="h:mm A">
                                {item.appStartDateTime}
                              </Moment>
                            </React.Fragment>
                          )}
                        </td>
                        <td className="align-top">
                          {item.isSurveyAvailable ? (
                            item.isSurveyStarted ? (
                              <React.Fragment>
                                <span
                                  className="badge rounded-0 pt-2 pb-2 text-white"
                                  style={{
                                    fontSize: "11px",
                                    backgroundColor: "#4caf50",
                                  }}
                                >
                                  Started
                                </span>
                                <br />
                                <button
                                  className="btn btn-link btn-sm p-0 m-0 text-underline"
                                  onClick={() =>
                                    this.handleRemainder(item.regId)
                                  }
                                >
                                  Send Remainder
                                </button>
                                <br />
                                <button
                                  className="btn btn-link btn-sm p-0 m-0"
                                  title="View message"
                                  onClick={this.handleRemainderMessage}
                                >
                                  <span className="badge badge-warning">
                                    <i
                                      class="fa fa-envelope mr-1"
                                      aria-hidden="true"
                                    ></i>
                                    {item.remainderCount === null
                                      ? 0
                                      : item.remainderCount}
                                  </span>
                                </button>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <span
                                  className="badge rounded-0 pt-2 pb-2 text-white"
                                  style={{
                                    fontSize: "11px",
                                    backgroundColor: "#ec0c38",
                                  }}
                                >
                                  Not Started
                                </span>
                                <br />
                                <button
                                  className="btn btn-success btn-sm mt-2"
                                  onClick={() =>
                                    this.handleSurveyApplication(item.appId)
                                  }
                                >
                                  Send
                                </button>
                              </React.Fragment>
                            )
                          ) : (
                            <React.Fragment>
                              <span
                                className="badge rounded-0 pt-2 pb-2 text-white"
                                style={{
                                  fontSize: "11px",
                                  backgroundColor: "#ec0c38",
                                }}
                              >
                                Not Started
                              </span>
                              <br />
                              <button
                                className="btn border btn-sm mt-2"
                                title="Send Survey after application closed"
                              >
                                Send
                              </button>
                            </React.Fragment>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-md-12">
                <div className="card shadow-none border">
                  <div className="card-header">
                    <h3 className="mb-0">
                      Prior Patient Self-Evaluation Reports
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-items-center table-hover">
                        <thead className="thead-light">
                          <tr>
                            <th>Patient Name</th>
                            <th>Status</th>
                            <th>Approval Date</th>
                            <th>Approved By</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{item.applicantName}</td>
                            <td>
                              <button className="btn btn-success btn-sm">
                                Approved
                              </button>
                            </td>
                            <td>
                              <Moment format="MMM Do YYYY">
                                {item.regDateTime}
                              </Moment>
                            </td>
                            <td>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={this.handleSurveyShowHide}
                              >
                                View Report
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card shadow-none border">
                  <div className="card-header p-3">
                    <h3 className="mb-0 float-left">
                      Medicine Dispensing Transaction
                    </h3>
                    <button
                      className="btn btn-default btn-sm float-right"
                      onClick={this.handleModalShowHide}
                    >
                      <i className="fa fa-plus"></i> Add
                    </button>
                  </div>
                  <div className="card-body">
                    {medicineTransaction.length <= 0 && (
                      <p>
                        Patient does not have any medicine dispensing
                        transaction
                      </p>
                    )}
                    <div className="table-responsive">
                      <table className="table align-items-center table-hover">
                        <thead className="thead-light">
                          <tr>
                            <th>
                              Updated
                              <br></br> Date and Time
                            </th>
                            <th>
                              Updated By Pharmicst<br></br>
                              ID and Location
                            </th>
                            <th>
                              Distributor/Dispencing Center
                              <br></br> Name , Email, Phone
                            </th>
                            <th>Medication Prescribed</th>
                            <th>Manufacture</th>
                          </tr>
                        </thead>
                        <tbody>
                          {medicineTransaction.map((x) => (
                            <tr>
                              <td>
                                <Moment format="MMM Do YYYY">
                                  {x.addedDateTime}
                                </Moment>
                                <br></br>
                                <Moment format="h:mm A">
                                  {x.addedDateTime}
                                </Moment>
                              </td>
                              <td>
                                {x.pharmacistId} &nbsp;&nbsp;
                                {x.pharmacistDetails !== null &&
                                  x.pharmacistDetails.performername}
                                <br></br>
                                {x.pharmacistDetails !== null &&
                                  x.pharmacistDetails.location}
                              </td>
                              <td>
                                {x.dispensingCenter}
                                <br></br>
                                {x.dispencingDetails !== null &&
                                  x.dispencingDetails.email}
                                <br></br>
                                {x.dispencingDetails !== null &&
                                  x.dispencingDetails.portalUrl}
                              </td>
                              <td>{this.getMedicinList(x.medicationList)}</td>
                              <td>{x.manufacturer}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <Accordion
                  activeKey={
                    accordianData["mngDocuments"] === 2 || hideShowAll
                      ? "2"
                      : ""
                  }
                  onSelect={() => this.handleSelect(2, "mngDocuments")}
                >
                  <Card className="shadow-none">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="2"
                      className={`p-3 card-header1 ${bgColor}`}
                      aria-expanded={
                        accordianData["mngDocuments"] === 2 || hideShowAll
                          ? "true"
                          : "false"
                      }
                    >
                      <h4 className="mb-0">
                        <i
                          className="fa fa-hand-o-right mr-2"
                          aria-hidden="true"
                        ></i>
                        Document - Send/Received
                        <span className="ml-3" style={{ position: "absolute" }}>
                          <i
                            class="fa fa-bell text-red"
                            aria-hidden="true"
                            style={{ fontSize: 20 }}
                          >
                            <span
                              className="text-white"
                              style={{
                                position: "absolute",
                                padding: "0px",
                                borderRadius: "10px",
                                fontSize: "12px",
                                bottom: "7px",
                                marginLeft: "-13px",
                                fontWeight: "bold",
                              }}
                            >
                              {notification}
                            </span>
                          </i>
                        </span>
                      </h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Document documents={documents} workflowTask={task} />
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <hr />
                <Accordion
                  activeKey={
                    accordianData["msgPatient"] === 3 || hideShowAll ? "3" : ""
                  }
                  onSelect={() => this.handleSelect(3, "msgPatient")}
                >
                  <Card className="shadow-none">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="3"
                      className={`p-3 card-header1 ${bgColor}`}
                      aria-expanded={
                        accordianData["msgPatient"] === 3 || hideShowAll
                          ? "true"
                          : "false"
                      }
                    >
                      <h4 className="mb-0">
                        <i
                          className="fa fa-hand-o-right mr-2"
                          aria-hidden="true"
                        ></i>
                        Patient Messaging - Chat
                        <span className="ml-3" style={{ position: "absolute" }}>
                          <i
                            class="fa fa-bell text-red"
                            aria-hidden="true"
                            style={{ fontSize: 20 }}
                          >
                            <span
                              className="text-white"
                              style={{
                                position: "absolute",
                                padding: "0px",
                                borderRadius: "10px",
                                fontSize: "12px",
                                bottom: "7px",
                                marginLeft: "-13px",
                                fontWeight: "bold",
                              }}
                            >
                              {notification}
                            </span>
                          </i>
                        </span>
                      </h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <MessageWithApplicant
                        WorkflowTask={task}
                      ></MessageWithApplicant>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <hr />
                <Accordion
                  activeKey={
                    accordianData["phoneCall"] === 4 || hideShowAll ? "4" : ""
                  }
                  onSelect={() => this.handleSelect(4, "phoneCall")}
                >
                  <Card className="shadow-none">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="4"
                      className={`p-3 card-header1 ${bgColor}`}
                      aria-expanded={
                        accordianData["phoneCall"] === 4 || hideShowAll
                          ? "true"
                          : "false"
                      }
                    >
                      <h4 className="mb-0">
                        <i
                          className="fa fa-hand-o-right mr-2"
                          aria-hidden="true"
                        ></i>
                        Phone call recordings
                        <span className="ml-3" style={{ position: "absolute" }}>
                          <i
                            class="fa fa-bell text-red"
                            aria-hidden="true"
                            style={{ fontSize: 20 }}
                          >
                            <span
                              className="text-white"
                              style={{
                                position: "absolute",
                                padding: "0px",
                                borderRadius: "10px",
                                fontSize: "12px",
                                bottom: "7px",
                                marginLeft: "-13px",
                                fontWeight: "bold",
                              }}
                            >
                              {notification}
                            </span>
                          </i>
                        </span>
                      </h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                      <PhoneCallApplicant workflowTask={task} />
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <hr />
                <Accordion
                  activeKey={
                    accordianData["scheduleMeeting"] === 5 || hideShowAll
                      ? "5"
                      : ""
                  }
                  onSelect={() => this.handleSelect(5, "scheduleMeeting")}
                >
                  <Card className="shadow-none">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="5"
                      className={`p-3 card-header1 ${bgColor}`}
                      aria-expanded={
                        accordianData["scheduleMeeting"] === 5 || hideShowAll
                          ? "true"
                          : "false"
                      }
                    >
                      <h4 className="mb-0">
                        <i
                          className="fa fa-hand-o-right mr-2"
                          aria-hidden="true"
                        ></i>
                        Schedule appointment
                        <span className="ml-3" style={{ position: "absolute" }}>
                          <i
                            class="fa fa-bell text-red"
                            aria-hidden="true"
                            style={{ fontSize: 20 }}
                          >
                            <span
                              className="text-white"
                              style={{
                                position: "absolute",
                                padding: "0px",
                                borderRadius: "10px",
                                fontSize: "12px",
                                bottom: "7px",
                                marginLeft: "-13px",
                                fontWeight: "bold",
                              }}
                            >
                              {notification}
                            </span>
                          </i>
                        </span>
                      </h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="5">
                      <ScheduleMeeting workflowTask={task} />
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <hr />
                <Accordion
                  activeKey={
                    accordianData["GroupMsg"] === 6 || hideShowAll ? "6" : ""
                  }
                  onSelect={() => this.handleSelect(6, "GroupMsg")}
                >
                  <Card className="shadow-none">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="6"
                      className={`p-3 card-header1 ${bgColor}`}
                      aria-expanded={
                        accordianData["GroupMsg"] === 6 || hideShowAll
                          ? "true"
                          : "false"
                      }
                    >
                      <h4 className="mb-0">
                        <i
                          className="fa fa-hand-o-right mr-2"
                          aria-hidden="true"
                        ></i>
                        Group Messaging - Chat
                        <span className="ml-3" style={{ position: "absolute" }}>
                          <i
                            class="fa fa-bell text-red"
                            aria-hidden="true"
                            style={{ fontSize: 20 }}
                          >
                            <span
                              className="text-white"
                              style={{
                                position: "absolute",
                                padding: "0px",
                                borderRadius: "10px",
                                fontSize: "12px",
                                bottom: "7px",
                                marginLeft: "-13px",
                                fontWeight: "bold",
                              }}
                            >
                              {notification}
                            </span>
                          </i>
                        </span>
                      </h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="6">
                      <GroupMessaging WorkflowTask={task}></GroupMessaging>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <hr />
                <Accordion
                  activeKey={
                    accordianData["GroupDocs"] === 7 || hideShowAll ? "7" : ""
                  }
                  onSelect={() => this.handleSelect(7, "GroupDocs")}
                >
                  <Card className="shadow-none">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="7"
                      className={`p-3 card-header1 ${bgColor}`}
                      aria-expanded={
                        accordianData["GroupDocs"] === 7 || hideShowAll
                          ? "true"
                          : "false"
                      }
                    >
                      <h4 className="mb-0">
                        <i
                          className="fa fa-hand-o-right mr-2"
                          aria-hidden="true"
                        ></i>
                        Group Document Sharing - Upload Document
                        <span className="ml-3" style={{ position: "absolute" }}>
                          <i
                            class="fa fa-bell text-red"
                            aria-hidden="true"
                            style={{ fontSize: 20 }}
                          >
                            <span
                              className="text-white"
                              style={{
                                position: "absolute",
                                padding: "0px",
                                borderRadius: "10px",
                                fontSize: "12px",
                                bottom: "7px",
                                marginLeft: "-13px",
                                fontWeight: "bold",
                              }}
                            >
                              {notification}
                            </span>
                          </i>
                        </span>
                      </h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="7">
                      <GroupDocuments WorkflowTask={task}></GroupDocuments>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <hr />
                <Accordion
                  activeKey={
                    accordianData["formLibrary"] === 8 || hideShowAll ? "8" : ""
                  }
                  onSelect={() => this.handleSelect(8, "formLibrary")}
                >
                  <Card className="shadow-none">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="8"
                      className={`p-3 card-header1 ${bgColor}`}
                      aria-expanded={
                        accordianData["formLibrary"] === 8 || hideShowAll
                          ? "true"
                          : "false"
                      }
                    >
                      <h4 className="mb-0">
                        <i
                          className="fa fa-hand-o-right mr-2"
                          aria-hidden="true"
                        ></i>
                        Forms Library
                        <span className="ml-3" style={{ position: "absolute" }}>
                          <i
                            class="fa fa-bell text-red"
                            aria-hidden="true"
                            style={{ fontSize: 20 }}
                          >
                            <span
                              className="text-white"
                              style={{
                                position: "absolute",
                                padding: "0px",
                                borderRadius: "10px",
                                fontSize: "12px",
                                bottom: "7px",
                                marginLeft: "-13px",
                                fontWeight: "bold",
                              }}
                            >
                              {notification}
                            </span>
                          </i>
                        </span>
                      </h4>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="8">
                      <FormsLibrary workflowTask={task}></FormsLibrary>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <AddMedicine
          showHide={showHide}
          handleModalShowHide={this.handleModalShowHide}
          patientInfo={item}
        ></AddMedicine>
        <PatientEvalForm
          surveyShowHide={surveyShowHide}
          handleSurveyShowHide={this.handleSurveyShowHide}
          patientInfo={item}
        ></PatientEvalForm>
      </React.Fragment>
    );
  }
}

export default PatientDetails;
