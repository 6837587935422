import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import useDocDownload from "../../hooks/useDocDownload";
import { useDispatch, useSelector } from "react-redux";
import { deleteBackgroundChecks, getBackgroundChecks, getBcDeleteStatus, loadBackgroundChecks } from "../../store/candidate";
import AddBcChecks from "../../../components/OMCRegistry/AddBcChecks";
import { getAlert, getConfirmAlert } from "../../../services/appAlerts";

const BcChecks = (props) => {

    const { subProccessTask, candidateObj } = props;

    const [bcCheckShowHide, setBcCheckShowHide] = useState(false);
    const [backgroundChecks, setBackgroundChecks] = useState([]);
    const [bcObject, setBcObject] = useState(null);
    const [bcType, setBcType] = useState(null);

    const backgroundChecksSelector = useSelector(getBackgroundChecks);
    const bcDeleteStatusSelector = useSelector(getBcDeleteStatus);

    const handleDownloadFile = useDocDownload();

    const dispatch = useDispatch();

    useEffect(() => {
        if (subProccessTask?.applicantId > 0) {
            dispatch(loadBackgroundChecks(subProccessTask.applicantId));
        }
    }, [subProccessTask]);

    useEffect(() => {
        setBackgroundChecks(backgroundChecksSelector);
    }, [backgroundChecksSelector])

    const handleBcCheckShowHide = (obj, type) => {
        if (obj === "save") {
            if (subProccessTask?.applicantId > 0)
                dispatch(loadBackgroundChecks(subProccessTask.applicantId));

            setBcType(null);
            setBcObject(null);
            setBcCheckShowHide(false);

        } else {
            setBcCheckShowHide(!bcCheckShowHide);
            setBcObject(obj);
            setBcType(type);
        }
    }

    const handleDeleteBcDoc = () => {

    }

    const handleDeleteBcChecks = async (id) => {
        const result = await getConfirmAlert("", "Are you sure you want to Delete this Background Check?", "warning");
        if (result.isConfirmed) {
            dispatch(deleteBackgroundChecks(id))
        }
    }

    useEffect(() => {
        if (bcDeleteStatusSelector) {
            getAlert("", "Deleted Successfully", "success");
            
            if (subProccessTask?.applicantId > 0)
                dispatch(loadBackgroundChecks(subProccessTask.applicantId));
        }
    }, [bcDeleteStatusSelector])

    return <>
        <div className="table-responsive">
            <table className="table table-bordered">
                <thead className="thead-light">
                    <tr>
                        <th>S.No.</th>
                        <th>Date & Time</th>
                        <th>
                            Background
                            <br />
                            Check Name
                        </th>
                        <th>
                            Internal
                            <br />
                            Notes
                        </th>
                        <th>Internal Documents</th>
                        <th>Status</th>
                        <th>
                            Notify
                            <br /> Candidate
                        </th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {backgroundChecks.map((item, key) => (
                        <tr>
                            <td>{key + 1}</td>
                            <td>
                                <Moment format="MMM Do YYYY, h:mm a">
                                    {item.addedDateTime}
                                </Moment>
                            </td>
                            <td>{item.name}</td>
                            <td>
                                <button
                                    className="btn btn-link btn-sm p-0 text-underline"
                                    onClick={() => handleBcCheckShowHide(item, "edit")
                                    }
                                >
                                    View/Edit
                                </button>
                            </td>
                            <td className="p-2">
                                {item.updatedDocList.map((doc) => (
                                    <React.Fragment>
                                        <li className="list-group-item p-1">
                                            <button
                                                className="btn btn-link btn-sm p-0 text-underline"
                                                onClick={() => handleDownloadFile(doc)}
                                            >
                                                {doc.documentName}
                                            </button>
                                            <button
                                                className="float-right mr-2 btn btn-sm"
                                                onClick={() => handleDeleteBcDoc(item.id)}
                                            >
                                                <i
                                                    className="fa fa-trash text-red"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        </li>
                                    </React.Fragment>
                                ))}
                            </td>
                            <td>
                                {item.status === null && (
                                    <span>
                                        Pending
                                    </span>
                                )}
                                {item.status && (
                                    <span>
                                        Passed
                                    </span>
                                )}

                                {!item.status && item.status !== null && (
                                    <span>
                                        Failed
                                    </span>
                                )}
                                <br />
                                {item.updatedDateTime !== null && (
                                    <Moment format="MMM Do YYYY, h:mm a">
                                        {item.updatedDateTime}
                                    </Moment>
                                )}
                            </td>
                            <td>
                                {!item.sendStatus && (
                                    <button
                                        className="btn btn-link btn-sm p-0 text-underline"
                                        onClick={() => handleBcCheckShowHide(item, "notify")}
                                    >
                                        Notify
                                    </button>
                                )}
                                {item.sendStatus && (
                                    <span>
                                        Notified
                                    </span>
                                )}
                                <br />
                                {item.notifyDateTime !== null && (
                                    <Moment format="MMM Do YYYY, h:mm a">
                                        {item.notifyDateTime}
                                    </Moment>
                                )}
                                <br />
                                {item.docList.map((doc) => (
                                    <li className="list-group-item p-1">
                                        <button
                                            className="btn btn-link btn-sm p-0 text-underline"
                                            onClick={() => handleDownloadFile(doc)}
                                        >
                                            {doc.documentName}
                                        </button>
                                    </li>
                                ))}
                            </td>
                            <td>
                                <button
                                    className="btn btn-link btn-sm p-0 text-underline"
                                    onClick={() => handleDeleteBcChecks(item.id)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

        <AddBcChecks
            bcCheckShowHide={bcCheckShowHide}
            handleBcCheckShowHide={handleBcCheckShowHide}
            candidateObj={candidateObj}
            workItem={subProccessTask}
            bcObject={bcObject}
            bcType={bcType}
            //selectedObj={selectedObj}
            workflowTask={subProccessTask}
            backgroundCheckApp={subProccessTask}
        ></AddBcChecks>
    </>
}

export default BcChecks;