import React, { Component } from "react";

class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordType: "password",
    };
  }

  togglePassword = () => {
    const { passwordType } = this.state;
    if (passwordType === "password") {
      this.setState({ passwordType: "text" });
    } else this.setState({ passwordType: "password" });
  };

  render() {
    const { passwordType } = this.state;
    const { name, label, error, req, ...rest } = this.props;
    return (
      <div className={`form-group ${req && "required"}`}>
        <label className="form-control-label" htmlFor={name}>
          {label}
        </label>
        <div className="input-group">
          <input
            {...rest}
            type={passwordType}
            name={name}
            className="form-control"
            placeholder={label}
          />
          <div className="input-group-btn ml-2">
            <button
              className="btn btn-outline-primary"
              onClick={this.togglePassword}
            >
              {passwordType === "password" ? (
                <i className="fa fa-eye-slash"></i>
              ) : (
                <i className="fa fa-eye"></i>
              )}
            </button>
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    );
  }
}

export default PasswordInput;
