import React, { Component } from "react";
import auth from "../../services/authService";
import CallCenterHexagon from "./CallCenterHexagon";
import Hexagon from "./Hexagon";
import LoanServicingHexagon from "./LoanServicingHexagon";
import TitleCompanyHexagon from "./TitleCompanyHexagon";

class HCPHexagon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      userApp: {},
      showHide: false,
    };
  }

  render() {
    const user = auth.getCurrentUser();
    const partnerType = localStorage.getItem("partnerType");
    return user.type === "admin" || user.type === "workflow" ? (
      <Hexagon></Hexagon>
    ) : (
      <React.Fragment>
        {partnerType === "Loan Servicing" && <LoanServicingHexagon />}
        {partnerType === "Call Center" && <CallCenterHexagon />}
        {partnerType === "Title Company" && <TitleCompanyHexagon />}
      </React.Fragment>
    );
  }
}

export default HCPHexagon;
