import React, { useEffect, useState, useSyncExternalStore } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getAllMonths, getAllYears } from "../../../../services/appService";
import { useDispatch, useSelector } from "react-redux";
import { getMonthlyGrossProfitCandidates, loadMonthlyGrossProfitCandidates } from "../../../store/reports";
import GeneratePdf from "../../../../components/GeneratePdf";

const GrossProfitCandidates = () => {

    const years = getAllYears();
    const months = getAllMonths();

    const candidateEntity = [
        { id: 1, name: "C2C Basis" },
        { id: 2, name: "W2 Basis" },
        { id: 3, name: "1099 Basis" },
    ];

    const [pdfShowHide, setPdfShowHide] = useState(false);
    const [rptShowHide, setRptShowHide] = useState(false);
    const [selectedCol, setSelectedCol] = useState([]);
    const [selectedColData, setSelectedColData] = useState([]);

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [grossProfitRpt, setGrossProfitRpt] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState("");

    const monthlyGrossProfitCandidatesSelector = useSelector(getMonthlyGrossProfitCandidates);

    const dispatch = useDispatch();

    const GenerateGrossProfitRpt = () => {
        if (year && month)
            dispatch(loadMonthlyGrossProfitCandidates(year, month))
    };

    useEffect(() => {
        if (selectedEntity) {
            const filterData = monthlyGrossProfitCandidatesSelector.filter(
                (x) => x.candidateType === selectedEntity
            );
            setGrossProfitRpt(filterData);
        }
        else {
            setGrossProfitRpt(monthlyGrossProfitCandidatesSelector)
        }
    }, [monthlyGrossProfitCandidatesSelector]);

    const handlepdfShowHide = () => {
        setPdfShowHide(false);
        setSelectedCol([]);
        setSelectedColData([]);
    };

    const generateGrossProfitPdf = () => {
        const headers = [
            [
                "#",
                "Candidate Name",
                "Entity",
                "Subcontractor Name",
                "Recruiter Name",
                "Amount Invoiced $",
                "Amount Received $",
                "Payable to Candidate $",
                "Payable to Subcontractor $",
                "Payable to Recruiter $",
                "Gross Profit",
            ],
        ];

        const colData = [];
        grossProfitRpt.map((item) => {
            const dt = [];
            dt.push(
                item.candidateId,
                item.candidateName,
                item.candidateType,
                item.subcontractor,
                item.recruiter,
                parseFloat(item.agencyAmount).toFixed(2),
                parseFloat(item.amountReceived).toFixed(2),
                parseFloat(item.candidateAmount).toFixed(2),
                parseFloat(item.subonctractorAmount).toFixed(2),
                parseFloat(item.recruiterAmount).toFixed(2),
                parseFloat(item.grossProfit).toFixed(2)
            );
            colData.push(dt);
        });

        setSelectedCol(headers);
        setSelectedColData(colData);
        setPdfShowHide(true);
    }

    return <>
        <div className="card shadow-none border">
            <div className="card-header text-center p-3 bg-lighter">
                <h4 className="mb-0">
                    Monthly Gross Profit Reports - Per Candidate
                    Basis
                </h4>
            </div>
            <div className="card-body p-3">
                <div className="row">
                    <div className="col-md-2">
                        <select
                            name="year"
                            id="year"
                            className="form-control form-control-sm"
                            onChange={({ currentTarget }) => setYear(currentTarget.value)}
                            value={year}
                        >
                            <option value="">Year</option>
                            {years.map((y) => (
                                <option value={y.name}>
                                    {y.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <select
                            name="month"
                            id="month"
                            className="form-control form-control-sm"
                            onChange={({ currentTarget }) => setMonth(currentTarget.value)}
                            value={month}
                        >
                            <option value="">Month</option>
                            {months.map((m) => (
                                <option value={m.id}>{m.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">

                        <select
                            name="CandidateEntity"
                            id="CandidateEntity"
                            className="form-control form-control-sm"
                            onChange={({ currentTarget }) => setSelectedEntity(currentTarget.value)}
                            value={selectedEntity}
                        >
                            <option value="">All</option>
                            {candidateEntity.map((item) => (
                                <option value={item.name}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <button
                            className="btn btn-sm btn-outline-default"
                            disabled={!(year && month)}
                            onClick={GenerateGrossProfitRpt}
                        >
                            Generate
                        </button>
                    </div>
                    <div className="col-md-3 text-right">
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-sm btn-outline-default"
                            table="gross_profit_table"
                            filename="Monthly_Gross_Profit"
                            sheet="Monthly_Gross_Profit"
                            buttonText="Export to Excel"
                        />
                        <button
                            type="button"
                            disabled={grossProfitRpt.length === 0}
                            className="btn btn-sm btn-outline-default"
                            onClick={generateGrossProfitPdf}
                        >
                            Generate PDF
                        </button>
                    </div>
                    <div className="col-md-12 mt-2">
                        <div className="table-responsive">
                            <table
                                className="table align-items-center table-flush table-hover table-striped mb-2 border"
                                id="gross_profit_table"
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th colSpan={5}></th>
                                        <th
                                            colSpan={2}
                                            className="bg-white text-center"
                                        >
                                            Accounts Receivable
                                        </th>
                                        <th
                                            colSpan={3}
                                            className="bg-lighter text-center"
                                        >
                                            Accounts Payable
                                        </th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th>#</th>
                                        <th>Candidate Name</th>
                                        <th>Entity</th>
                                        <th>Subcontractor Name</th>
                                        <th>Recruiter Name</th>
                                        <th className="bg-white">
                                            Amount Invoiced $
                                        </th>
                                        <th className="bg-white">
                                            Amount Received $
                                        </th>
                                        <th className="bg-lighter">
                                            Payable to Candidate $
                                        </th>
                                        <th className="bg-lighter">
                                            Payable to Subcontractor $
                                        </th>
                                        <th className="bg-lighter">
                                            Payable to Recruiter $
                                        </th>
                                        <th>Gross Profit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {grossProfitRpt.map((item) => (
                                        <tr>
                                            <td>{item.candidateId}</td>
                                            <td>{item.candidateName}</td>
                                            <td>{item.candidateType}</td>
                                            <td>{item.subcontractor}</td>
                                            <td>{item.recruiter}</td>
                                            <td className="text-right">{parseFloat(item.agencyAmount).toFixed(2)}</td>
                                            <td className="text-right">{parseFloat(item.amountReceived).toFixed(2)}</td>
                                            <td className="text-right">{parseFloat(item.candidateAmount).toFixed(2)}</td>
                                            <td className="text-right">
                                                {parseFloat(item.subonctractorAmount).toFixed(2)}
                                            </td>
                                            <td className="text-right">{parseFloat(item.recruiterAmount).toFixed(2)}</td>
                                            <td className="text-right">{parseFloat(item.grossProfit).toFixed(2)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <GeneratePdf
            handlepdfShowHide={handlepdfShowHide}
            pdfShowHide={pdfShowHide}
            selectedCol={selectedCol}
            selectedColData={selectedColData}
        ></GeneratePdf>
    </>
}

export default GrossProfitCandidates;