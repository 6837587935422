import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import http from "../../services/HttpService";
import config from "../../config.json";
import Form from "../../comman/form";
import auth from "../../services/authService";

class AddFormLibrary extends Form {
  constructor(props) {
    super(props);
    this.state = {
      forms: [],
      data: {},
      errors: {},
      documentTypes: [
        {
          id: 1,
          name: "ACH Information",
        },
        {
          id: 2,
          name: "Insurance Information",
        },
        {
          id: 3,
          name: "Purchase Order",
        },
      ],
      cannedMessages: [],
    };
  }

  schema = {};

  async componentDidMount() {
    const { data: forms } = await http.get(
      config.GetFormsByType + "Library Form"
    );
    // const newForms = forms.filter(
    //   (x) => x.formName !== "AchInfo" && x.formName !== "InsuranceDetails"
    // );
    const { data: cannedMessages } = await http.get(
      config.GetCodemanager + "Canned Message"
    );
    const { data: documentTypes } = await http.get(
      config.GetCodemanager + "Document Type 2"
    );
    const lastObj = documentTypes[documentTypes.length - 1];
    documentTypes.push({
      id: lastObj.id + 1,
      type: "Document Type 2",
      code: lastObj.code + 1,
      name: "Insurance Information",
    });
    documentTypes.push({
      id: lastObj.id + 2,
      type: "Document Type 2",
      code: lastObj.code + 2,
      name: "ACH Information",
    });
    //console.log(documentTypes);
    this.setState({ forms, cannedMessages, documentTypes });
  }

  doSubmit = async () => {
    const user = auth.getCurrentUser();
    const { workflowTask } = this.props;
    const wfTask = { ...workflowTask };
    const { data, forms } = this.state;
    const selectedForm = forms.filter((x) => x.id === parseInt(data["formId"]));
    //console.log(workflowTask);
    //data["formType"] = "Insurance Information";
    //data["formType"] = "ACH Information";
    wfTask.performerId = user.id;
    const frmData = new FormData();
    frmData.append("workflowTask", JSON.stringify(wfTask));
    frmData.append("formId", data["formId"]);
    frmData.append("formName", selectedForm[0].formTitle);
    frmData.append("remarks", data["remarks"]);
    frmData.append("id", data["id"]);
    frmData.append("formType", data["formType"]);
    const { data: result } = await http.post(config.AddFormLibrary, frmData);
    this.props.handleAddFormLibrary();
  };

  componentWillReceiveProps = (nextProps) => {
    const { selectedForm, workflowTask } = nextProps;
    //console.log(workflowTask);
    const { data } = this.state;
    if (selectedForm && selectedForm !== "") {
      data["formId"] = selectedForm.formId;
      data["remarks"] = selectedForm.remarks;
      data["id"] = selectedForm.id;
      data["formType"] = selectedForm.formType;
      this.setState({ data });
    }
    else {
      data["remarks"] = "";
      data["formType"] = "";
      data["formId"] = "";
      data["id"] = "";
      this.setState({ data })
    }
  };

  handleCannedMessage = ({ currentTarget }) => {
    const { data } = this.state;
    data["remarks"] = currentTarget.value;
    this.setState({ data });
  };

  render() {
    const { forms, documentTypes, cannedMessages, data } = this.state;
    const { showHide, handleModalShowHide } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            {this.renderSelect(
              "formId",
              "Select the form(s) to send to the applicant",
              forms,
              "formTitle",
              "id"
            )}
          </div>
          <div className="col-md-12">
            {this.renderSelect(
              "formType",
              "Form Type",
              documentTypes,
              "name",
              "name"
            )}
          </div>
          <div className="col-md-12 form-group">
            <label htmlFor="cannedMessage" className="form-control-label">
              Select Default Message
            </label>
            <select
              name="cannedMessage"
              id="cannedMessage"
              className="form-control"
              onChange={this.handleCannedMessage}
              value={data["remarks"]}
            >
              <option value="">Select</option>
              {cannedMessages.map((item) => (
                <option value={item.name}>{item.name}</option>
              ))}
            </select>
          </div>
          <div className="col-md-12">
            {this.renderTextArea("remarks", "Remarks")}
          </div>
          <div className="col-md-12 text-center">
            <button className="btn btn-sm btn-outline-default"
              onClick={this.handleSubmit}>
              <i className="fa fa-check" aria-hidden="true"></i> Save
            </button>

          </div>
        </div>

      </>
    );
  }
}

export default AddFormLibrary;
