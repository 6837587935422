import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "../../comman/form";
import UploadDocument from "./UploadDocument";
import Joi from "joi-browser";
import http from "../../services/HttpService";
import config from "../../config.json";
import auth from "../../services/authService";

class UploadDocPopup extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      documents: [],
    };
  }

  schema = {
    //selectedApp: Joi.string().required().label("Application"),
    message: Joi.string().required().label("Message"),
    docs: Joi.string().required().label("Document"),
  };

  onFileChange = async (event) => {
    //console.log(event.target.files);
    const docs = [...this.state.documents];
    for (const file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        file: file,
      };
      docs.push(doc);
    }
    event.target.value = "";
    this.validateDocuments(docs);
    this.setState({ documents: docs });
  };

  handleFileRemove = (item) => {
    const index = this.state.documents.indexOf(item);
    let documents = [...this.state.documents];
    if (index !== -1) {
      documents.splice(index, 1);
      this.validateDocuments(documents);
      this.setState({ documents });
    }
  };

  validateDocuments = (documents) => {
    const { data, errors } = this.state;
    if (documents.length > 0) {
      data["docs"] = documents.length.toString();
      delete errors["docs"];
    } else {
      const obj = { schedule: "" };
      const schema = { schedule: this.schema["docs"] };
      const { error } = Joi.validate(obj, schema);
      errors["docs"] = error.details[0].message;
      data["docs"] = "";
    }
    this.setState({ data, errors });
  };

  doSubmit = async () => {
    const { data, documents } = this.state;
    const { runningApplication, type } = this.props;
    const user = auth.getCurrentUser();
    let selectedApplication = runningApplication.filter(
      (x) => x.ciid === parseInt(data["selectedApp"])
    );
    let userId = user.id;
    if (type === "review") {
      selectedApplication = runningApplication;
      userId = selectedApplication[0].performerId;
      selectedApplication[0].performerId = selectedApplication[0].applicantId;
    }

    const frmData = new FormData();
    frmData.append("message", data["message"]);
    frmData.append("workflowTask", JSON.stringify(selectedApplication[0]));
    frmData.append("userId", userId);
    documents.map((file, key) =>
      frmData.append("files[" + key + "]", file.file)
    );
    const { data: result } = await http.post(
      config.ApplicantUploadDocument,
      frmData
    );
    //console.log(result);
    this.props.handleUploadDocModalShowHide("submit");
  };

  render() {
    const { data, documents, errors } = this.state;
    const {
      uploadDocShowHide,
      handleUploadDocModalShowHide,
      runningApplication,
      type,
    } = this.props;
    //console.log(runningApplication);
    return (
      <Modal show={uploadDocShowHide} size="lg">
        <Modal.Header closeButton onClick={handleUploadDocModalShowHide}>
          <Modal.Title>Upload Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary pb-2">
          <div className="row">
            {type !== "review" && (
              <div className="col-md-12">
                {this.renderSelect(
                  "selectedApp",
                  "Select Application",
                  runningApplication,
                  "ciid",
                  "ciid"
                )}
              </div>
            )}
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="message" className="form-control-label">
                  Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  cols="2"
                  rows="2"
                  className="form-control"
                  value={data["message"]}
                  onChange={this.handleChange}
                ></textarea>
                {errors["message"] && (
                  <div className="alert alert-danger">{errors["message"]}</div>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <UploadDocument
                documents={documents}
                onFileChange={this.onFileChange}
                handleFileRemove={this.handleFileRemove}
              ></UploadDocument>
              {errors["docs"] && (
                <div className="alert alert-danger">{errors["docs"]}</div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleUploadDocModalShowHide}>
            <i className="fa fa-times" aria-hidden="true"></i> Close
          </Button>
          <Button
            variant="primary"
            disabled={this.validate()}
            onClick={this.handleSubmit}
          >
            <i className="fa fa-check" aria-hidden="true"></i> Save And Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default UploadDocPopup;
