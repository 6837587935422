import React, { Component } from "react";
import auth from "../services/authService";
import http from "../services/HttpService";
import config from "../config.json";

class ClientHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {},
      user: {},
      clientDetails: "",
    };
  }

  async componentDidMount() {
    // const user = auth.getCurrentUser();
    // if (user !== null && user !== undefined) {
    //   const { data: currentUser } = await http.get(
    //     config.GetPerformer + user.id
    //   );
    // }
    // const { data: clientDetails } = await http.get(config.GetClientDetails);
    // this.setState({ clientDetails });
  }

  render() {
    const { currentUser, user, clientDetails } = this.state;
    const userType = localStorage.getItem("userType");
    //console.log(currentUser);
    return (
      <React.Fragment>
        <div
          className="col-md-12"
          style={{
            backgroundColor: "white",
            textAlign: "left",
            borderBottom: "1px solid #ccc",
            backgroundColor: "white",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <div className="row">
            <div className="col-12 text-left">
              <img
                src={`/assets/Img/logo.png`}
                alt=""
                style={{
                  //objectFit: "cover",
                  height: "62px",
                  //width: "100%",
                  //objectPosition: "left",
                  //marginLeft: "5px",
                  //marginTop: "5px",
                  //marginBottom: "-20px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 bg-white">
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="text-uppercase mb-0 p-1">
                HUMAN RESOURCES ONBOARDING AND CONTRACT CASE MANAGEMENT SYSTEM
              </h3>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ClientHeader;
