import React, { Component } from "react";

class LoanServicingHexagon extends Component {
  render() {
    return (
      <div id="content">
        <div id="page">
          <div id="data_wrapper">
            <div id="data">
              <div>
                <div className="item main r1 c2 even_row pre" rel="pre">
                  <a href="!#">&nbsp;</a>
                </div>
                <div className="item pre r2 c2">
                  <div className="image">
                    <a href="/ManagePartnerPerformer">
                      <img
                        src="../../assets/HexagonDesign/cells/1_1.png"
                        title="User Authorization Access"
                      />
                    </a>
                  </div>
                </div>
                <div className="item pre r2 c3">
                  <div className="image">
                    <a href="/HCPSurvey">
                      <img
                        src="../../assets/HexagonDesign/cells/1_20.png"
                        title="Manage Survey"
                      />
                    </a>
                  </div>
                </div>
                <div className="item even_row pre r3 c2">
                  <div className="image">
                    <a href="/ChatMsg">
                      <img
                        src="../../assets/HexagonDesign/cells/1_13.png"
                        title="Messaging"
                      />
                    </a>
                  </div>
                </div>
                <div className="item even_row pre r3 c1">
                  <div className="image">
                    <a href="/MngEmail">
                      <img
                        src="../../assets/HexagonDesign/cells/1_9.png"
                        title="Manage Emails"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="item main r1 c3 even_row create" rel="create">
                  <a href="!#">&nbsp;</a>
                </div>
                <div className="item create r2 c4">
                  <div className="image">
                    <a href="/LoanServicing">
                      <img
                        src="../../assets/HexagonDesign/cells/2_24.png"
                        title="Loan Service Applications"
                      />
                    </a>
                  </div>
                </div>
                <div className="item create even_row r3 c3">
                  <div className="image">
                    <a href="/CallCenter">
                      <img
                        src="../../assets/HexagonDesign/cells/2_25.png"
                        title="Call Center Applications"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div>
                <div className="item main r1 c4 even_row build" rel="build">
                  <a href="!#">&nbsp;</a>
                </div>
                <div className="item build r2 c5">
                  <div className="image">
                    <a href="/Dashboard">
                      <img
                        src="../../assets/HexagonDesign/cells/3_8.png"
                        title="Dashboard"
                      />
                    </a>
                  </div>
                </div>
                <div className="item build even_row r3 c5">
                  <div className="image">
                    <a href="/ApplicationReport">
                      <img
                        src="../../assets/HexagonDesign/cells/3_9.png"
                        title="Application Report"
                      />
                    </a>
                  </div>
                </div>
                <div className="item build r4 c4">
                  <div className="image">
                    <a href="/MngStandardReports">
                      <img
                        src="../../assets/HexagonDesign/cells/3_10.png"
                        title="Standard Report"
                      />
                    </a>
                  </div>
                </div>
                <div className="item build even_row r3 c4">
                  <div className="image">
                    <a href="/MngCustomReports">
                      <img
                        src="../../assets/HexagonDesign/cells/3_11.png"
                        title="Custom Report"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoanServicingHexagon;
