import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class EmailMsgPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { emailMsgShowHide, handleEmailMsgShowHide } = this.props;
    return (
      <Modal show={emailMsgShowHide} size="lg">
        <Modal.Header closeButton onClick={handleEmailMsgShowHide}>
          <Modal.Title>Patient Email Message</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
        <div className="row">
            <div className="col-md-6 form-group">
              <h3 className="mb-0">MN Department of Health</h3>
            </div>
            <div className="col-md-6 text-right form-group">
              <h3 className="mb-0">Office of Medical Cannabis</h3>
            </div>
            <div className="col-md-12 form-group">
              Date:<br></br>
              Patient ID: ____<br></br>
              Dear Patient,<br></br>
              Your health care practitioner (HCP) has certified that you qualify
              for medical cannabis.
            </div>
            <div className="col-md-12 form-group">
              You must complete your registration via the link below, provide
              payment and have your registry status changed to APPROVED before
              you can visit a Cannabis Patient Center. You will receive another
              update via the portal – Check Your Application Status, and by
              email notifying you when your application has been reviewed by the
              MN Office of Medical Cannabis and you are APPROVED to participate
              in the MN Medical Cannabis Program.
            </div>
            <div className="col-md-12 form-group">
              Link:{" "}
              <a href="https://www.workfinity/Patient/enrollent">
                www.workfinity/Patient/enrollent
              </a>
            </div>
            <div className="col-md-12 form-group">
              When you login to the portal, to complete your enrollment, please
              ensure you have the following documents available to be uploaded:
            </div>
            <div className="col-md-12 form-group">
              <ol>
                <li>
                  A photo or image of the parent/legal guardian’s
                  government-issued photo identification, such as a Minnesota
                  identification/driver’s license. Image file type must be JPG,
                  GIF, TIF or PNG and smaller than 4 MB.
                </li>
                <li>
                  A photo or image of the patient’s birth certificate or legal
                  guardianship paperwork verifying the relationship.
                </li>
                <li>
                  A credit/debit card or check (routing and account numbers) for
                  the online annual enrollment fee of $200 or $50 (discounted
                  fee).
                </li>
                <li>
                  <b>
                    If applicable and you would like to enroll the patient with
                    the discounted fee of $50
                  </b>
                  , a photo or image of one of the following proofs under the
                  patient’s name must be provided:
                  <br></br>
                  <ul>
                    <li>
                      Supplemental Security Insurance (SSI)/Social Security
                      Disability (SSD) including those transitioned to
                      retirement benefits. A letter from Social Security
                      verifying your transition from SSD to retirement or the
                      SSD/SSI Benefit Verification letter dated within the last
                      90 days is acceptable. Medicare is not a qualifier for the
                      discounted fee.
                    </li>
                    <li>
                      Current medical assistance (MA), MinnesotaCare or Indian
                      Health Services (IHS) card. An enrollment letter from the
                      county or state dated within the last 90 days is also
                      acceptable.{" "}
                    </li>
                    <li>
                      Railroad disability. A benefit verification letter for
                      Railroad disability dated within the last 90 days is
                      acceptable.{" "}
                    </li>
                    <li>
                      Veteran’s disability. A valid VA service-connected card or
                      VA disability benefit letter dated within the last 90 days
                      is acceptable.
                    </li>
                    <li>
                      VA dependency and indemnity compensation (VA DIC). An
                      approval letter for VA dependency and indemnity
                      compensation dated within the last 90 days is acceptable.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
            <div className="col-md-12 form-group">
              Your enrollment and annual fee for registration is good for one
              year.
            </div>
            <div className="col-md-12 form-group">
              You may log in to MN Medical Cannabis Registry Sign -In to review
              your information.
            </div>
            <div className="col-md-12 form-group">
              If you need assistance, please contact the Minnesota Department of
              Health/Office of Medical Cannabis at 651-201-5598 (Metro) or
              1-844-879-3381 (Non-Metro)
            </div>
            <div className="col-md-12 form-group">
              <b>Thank you</b>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleEmailMsgShowHide}>
            Close
          </Button>
          <Button variant="primary">Save</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EmailMsgPopup;
