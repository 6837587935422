import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "duration",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        addedStatus:false,
        deletedStatus:false,
        updatedStatus:false,
        addedDurationObj:null
    },
    reducers: {
        durationsReceived:(duration,action)=>{
            duration.list=action.payload;
            duration.addedStatus=false;
            duration.deletedStatus=false;
            duration.updatedStatus=false;
            duration.addedDurationObj=null;
        },
        durationAdded:(duration,action)=>{
            duration.addedDurationObj = action.payload;
            duration.addedStatus=true;
        },
        durationDeleted:(duration,action)=>{
            duration.deletedStatus=true;
        },
        durationUpdated:(duration,action)=>{
            duration.updatedStatus=true;
        },
        
    }
});

export const { durationsReceived, durationAdded,
    durationDeleted,durationUpdated } = slice.actions;

export default slice.reducer;

export const loadAllDurations = (id) => apiCallBegan({
    url: config.GetDurationHistory + id,
    onSuccess: durationsReceived.type
});

export const addNewDuration = (data) => apiCallBegan({
    url: config.AddCandidateDuration,
    method:"post",
    data:data,
    onSuccess: durationAdded.type
});

export const updateRate = (data) => apiCallBegan({
    url: config.UpdateRates,
    method:"post",
    data:data,
    onSuccess: durationUpdated.type
});

export const deleteRate = (data) => apiCallBegan({
    url: config.DeletePhoneNotes,
    method:"post",
    data:data,
    onSuccess: durationDeleted.type
});

export const getAllDurations = createSelector(
    state => state.entities.duration,
    duration => duration.list
);

export const getDurationAddedStatus = createSelector(
    state => state.entities.duration,
    duration => duration.addedStatus
);

export const getAddedDurationObj = createSelector(
    state => state.entities.duration,
    duration => duration.addedDurationObj
);

export const getDurationDeletedStatus = createSelector(
    state => state.entities.duration,
    duration => duration.deletedStatus
);

export const getDurationUpdatedStatus = createSelector(
    state => state.entities.duration,
    duration => duration.updatedStatus
);

