import React, { Component, useEffect, useState } from "react";
import InfoHeader from "../../../comman/infoHeader";
import PageHeader from "../../PageHeader";
import WorkflowRolesTable from "../../WorkflowRolesTable";
import http from "../../../services/HttpService";
import config from "../../../config.json";
import PageSizeOptions from "../../PageSizeOptions";
import Pagination from "../../../comman/pagination";
import { paginate, calculateIndex } from "../../../utlis/paginate";
import AddNewDdrmsAccess from "./AddNewDdrmsAccess";
import { setCurrentPageTitle, setCurrentRoute } from "../../../app/store/layoutUI";
import { useDispatch } from "react-redux";

const AccessDdrms = () => {

  const route = [
    { url: "", name: "System Administration" },
    { url: "", name: "Access To External Systems" },
  ];

  const [pageSize, setPageSize] = useState(10);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [bprmPermissions, setBprmPermissions] = useState([]);
  const [showHide, setShowHide] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentPageTitle("WorkFinity DDRMS"));
    dispatch(setCurrentRoute(route));
  }, [])

  const handlePageChange = (page) => {
    const { startIndex: start, endIndex: end } = this.state;
    const { startIndex, endIndex } = calculateIndex(page, start, end);
    setCurrentPage(page);
    setStartIndex(startIndex);
    setEndIndex(endIndex);
  };

  const handlePageSizeChanged = ({ currentTarget }) => {
    setPageSize(currentTarget.value);
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(5);

  };

  const handleModalShowHide = () => {
    setShowHide(!showHide);
  };

  return (
    <React.Fragment>
      <div className="col-md-12">
        <div className="card mb-4 shadow-none">
          <div className="card-header border-0 p-2">
            <div className="row">
              <div className="col-md-8">
                <PageSizeOptions
                  pageSize={pageSize}
                  onChange={handlePageSizeChanged}
                ></PageSizeOptions>
              </div>
              <div className="col-md-4 text-right">
                <button
                  className="btn btn-default btn-sm"
                  onClick={handleModalShowHide}
                >
                  <i className="fa fa-plus"></i> New Login Request
                </button>
                <a
                  className="btn btn-sm btn-warning btn-round btn-icon mr-0"
                  data-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <i className="fa fa-filter" />
                  Filter
                </a>
              </div>
              <div className="col-md-12">
                <div className="collapse p-2 bg-secondary border mt-2" id="collapseExample">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="token" className="form-control-label">
                          Token ID
                        </label>
                        <input
                          type="text"
                          name="token"
                          id="token"
                          className="form-control"
                          placeholder="Token Id"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          htmlFor="accessBy"
                          className="form-control-label"
                        >
                          Access By
                        </label>
                        <input
                          type="text"
                          name="accessBy"
                          id="accessBy"
                          className="form-control"
                          placeholder="Access By"
                        />
                      </div>
                    </div>

                    <div className="col-md-12 text-center">
                      <button className="btn btn-outline-primary"><i className="fa fa-search fa-fa-icon-font-size" aria-hidden="true"></i></button>
                      <button className="btn btn-outline-danger"
                        data-toggle="collapse"
                        data-target="#collapseExample"><i className="fa fa-close fa-fa-icon-font-size" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-2">
            <div className="table-responsive border">
              <table className="table align-items-center table-flush table-striped mb-4">
                <thead className="thead-light">
                  <tr>
                    <th>S.No.</th>
                    <th>Date & Time Access</th>
                    <th>Accessed By</th>
                    <th>Token Expiry Date & Time</th>
                    <th>Notes</th>
                    <th>DDRMS Access</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>January 2nd 2021, 5:00 pm</td>
                    <td>JUSTIN DAWN</td>
                    <td>January 3rd 2021, 5:00</td>
                    <td>Create Folder</td>
                    <td>
                      <a
                        href="http://ipcs-ddrms.azurewebsites.us/"
                        target="_blank"
                      >
                        Connect
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                pageSize={pageSize}
                itemsCount={totalCount}
                onPageChange={handlePageChange}
                startIndex={startIndex}
                endIndex={endIndex}
              ></Pagination>
            </div>

          </div>
        </div>

        <AddNewDdrmsAccess
          showHide={showHide}
          handleModalShowHide={handleModalShowHide}
        ></AddNewDdrmsAccess>
      </div>
    </React.Fragment>
  );
}

export default AccessDdrms;
