import React, { Component } from "react";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap";
import config from "../config.json";
import http from "../services/HttpService";
import auth from "../services/authService";

class AddNewGroupMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      message: "",
      documents: [],
      performerList: [],
      selectedOption: "",
      selectedApp: "",
    };
  }

  async componentDidMount() {
    const { data } = await http.get(config.GetPerformerGroup + 0);
    this.setState({ performerList: data });
  }

  onFileChange = async (event) => {
    //console.log(event.target.files);
    const docs = [...this.state.documents];
    for (const file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        file: file,
      };
      docs.push(doc);
    }
    this.setState({ documents: docs });
  };

  handleFileRemove = (item) => {
    const index = this.state.documents.indexOf(item);
    let documents = [...this.state.documents];
    if (index !== -1) {
      documents.splice(index, 1);
      this.setState({ documents });
    }
  };

  handleSubmit = () => {
    this.props.handleAddNewMessage(this.state.message, this.state.documents);
  };

  handleChange1 = (selectedOption) => {
    if (selectedOption === null) {
      this.setState({ selectedOption: [] });
      return;
    }
    const { data } = this.state;
    let performers = [];
    selectedOption.map((item) =>
      performers.push({
        performerid: item.performerid,
        performername: item.performername,
      })
    );
    data["performers"] = performers;
    this.setState({ data, selectedOption });
  };

  handleAppChange = ({ currentTarget }) => {
    const { runningApplication } = this.props;
    const user = auth.getCurrentUser();
    const filterApp = runningApplication.filter(
      (x) =>
        x.ciid === parseInt(currentTarget.value) &&
        x.performerId !== parseInt(user.id)
    );
    console.log(filterApp);
    const { data } = this.state;
    data["selectedApp"] = currentTarget.value;
    this.setState({
      performers: filterApp,
      selectedApp: currentTarget.value,
      data,
    });
  };

  render() {
    const {
      showHide,
      handleModalShowHide,
      WorkflowTask,
      runningApplication,
    } = this.props;
    const {
      documents,
      selectedOption,
      performerList,
      selectedApp,
    } = this.state;

    let options = [];

    performerList.map((item) => {

      const lbl =
        item.department === null
          ? item.partner === null
            ? item.roleName == null
              ? ""
              : item.roleName
            : item.partner
          : item.department;
      options.push({ value: item.performerid, label: item.performername + ", " + lbl });
    });

    return (
      <Modal show={showHide} size={"lg"}>
        <Modal.Header closeButton onClick={handleModalShowHide}>
          <Modal.Title>Add New Group Messaging</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="selectedApp" className="form-control-label">
                  Select Application
                </label>
                <select
                  name="selectedApp"
                  id="selectedApp"
                  className="form-control"
                  value={selectedApp}
                  onChange={this.handleAppChange}
                >
                  <option value="">Select</option>
                  {runningApplication !== undefined && runningApplication.length > 0 &&
                    runningApplication.map((app) => (
                      <option value={app.ciid}>{app.ciid}</option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-md-12 form-group">
            <label htmlFor="performer" className="form-control-label">
                Select Performer
              </label>
              <Select
                value={selectedOption}
                onChange={this.handleChange1}
                options={options}
                isMulti={true}
              />
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="message" className="form-control-label">
                Message
              </label>
              <textarea
                name="message"
                id="message"
                cols="5"
                rows="3"
                className="form-control"
                value={this.state.message}
                onChange={({ currentTarget }) =>
                  this.setState({ message: currentTarget.value })
                }
              ></textarea>
            </div>
            <div className="col-md-12 form-group">
              <div className="dropdown">
                <button
                  className="btn btn-success dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-paperclip mr-2" aria-hidden="true"></i>
                  Attachment
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item" href="!#">
                    <label
                      htmlFor="AdhocMessagedocName"
                      className="custom-file-upload mb-0"
                    >
                      <i className="fa fa-desktop mr-2" aria-hidden="true" />
                      Upload Form Computer
                    </label>
                    <input
                      id="AdhocMessagedocName"
                      type="file"
                      style={{ display: "none" }}
                      onChange={this.onFileChange}
                      multiple
                      name="files"
                    />
                  </a>
                  <a className="dropdown-item" href="!#">
                    <i class="fa fa-database mr-2" aria-hidden="true"></i>
                    Upload From DDRMS
                  </a>
                  <a className="dropdown-item" href="!#">
                    <i class="fa fa-dropbox mr-2" aria-hidden="true"></i>
                    Upload From Dropbox
                  </a>
                  <a className="dropdown-item" href="!#">
                    <i class="fa fa-google mr-2" aria-hidden="true"></i>
                    Upload From Google Drive
                  </a>
                </div>
              </div>
              <table className="table table-striped align-items-center table-flush mt-2">
                <thead className="thead-light">
                  <tr>
                    <th>S.No</th>
                    <th>Document</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((item, key) => (
                    <tr>
                      <td>{key + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-link"
                          title="Remove"
                          onClick={() => this.handleFileRemove(item)}
                        >
                          <i
                            class="fa fa-times text-red"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalShowHide}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddNewGroupMessage;
