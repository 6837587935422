
export const getWfPhasesBgColor = (phase) => {
    switch (phase) {
        case "Closed":
            return "default text-white";
        case "Review":
            return "yellow text-dark";
        case "Application":
            return "primary text-white";
        case "Pending":
            return "info text-dark";
        case "Approved":
            return "success text-white";
        case "Denied":
            return "danger text-white";
        case "Withdrawn":
            return "warning text-white";
    }
}

export const getWfPhasesColor = (phase) => {
    switch (phase) {
        case "Closed":
            return "btn-default";
        case "Review":
            return "btn-wf2-default";
        case "Onboarding":
            return "btn-wf2-default";
        case "Application":
            return "btn-primary";
        case "Pending":
            return "btn-info";
        case "Approved":
            return "btn-wf3-default";
        case "Denied":
            return "btn-wf4-default";
        case "Withdrawn":
            return "btn-warning";
        case "Compliance":
            return "btn-wf5-default";
        case "Time Log":
            return "btn-wf5-default";
        case "Invoice":
            return "btn-wf6-default";
        default:
            return "btn-wf-default";
    }
}

export const getFieldData = (aWorkfinityApp, attribue, filedName) => {
    if (aWorkfinityApp === undefined) return "";
    const appEntity = JSON.parse(aWorkfinityApp);
    const appShortName = Object.keys(appEntity)[1];
    const aWorkfinityAppEntity = appEntity[appShortName];
    const obj = aWorkfinityAppEntity[attribue];
    if (!obj) return "";
    if (!obj["@" + filedName]) return "";
    return obj["@" + filedName];
};

export const getFormatedNumber = (number) => {
    if(!number) return;
    return number?.slice(0, 3) + "-" + number?.slice(3, 6) + "-" + number?.slice(6)
}

export const formatSSNNumber = (value) => {
    if (!value) return value;
    let phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 6) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        5
    )}-${phoneNumber.slice(5)}`;
}

export const formatPhoneNumber = (value) => {
    if (!value) return value;
    let phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6)}`;
}

export const getAddressGroup = () => {
    const group = ["apistatecountycity",
        "apistatecounty",
        "apicountrystate",
        "apiuscountycity",
        "apiusstatecounty",
        "apicountrystatecountycity"];
    return group;
}

export const getAllYears = () => {
    const years = [];
    for (let index = 1; index < 36; index++) {
        const element = { id: index, name: 2022 + index };
        years.push(element);
    }
    return years;
}

export const getMonthName = (num) => {
    switch (num) {
        case 1:
            return "January";
        case 2:
            return "February";
        case 3:
            return "March";
        case 4:
            return "April";
        case 5:
            return "May";
        case 6:
            return "June";
        case 7:
            return "July";
        case 8:
            return "Auguest";
        case 9:
            return "September";
        case 10:
            return "October";
        case 11:
            return "November";
        case 12:
            return "December";
        default:
            return "";
    }
}

export const getAllMonths = () => {
    const months = [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" },
    ];
    return months;
}

export const getTotalHours = (hrs) => {
    if (!hrs) return 0;
    const wt = JSON.parse(hrs);
    let totalHours = 0;
    let totalMinutes = 0;
    let totalDays = 0;
    wt.filter((x) => x.title !== null).map((t) => {
        const hrs = t.title.toString().split(".");
        totalHours += parseInt(hrs[0]);
        totalDays += 1;
        if (hrs.length > 1) {
            totalMinutes += parseInt(hrs[1]);
        }
    });
    totalHours += parseInt(totalMinutes / 60);
    return totalHours + "." + (totalMinutes % 60).toString();
};

export const getTotalDays = (hrs) => {
    if (!hrs) return 0;
    const wt = JSON.parse(hrs);
    const days = wt.filter((x) => x.title !== null && x.type !== "m").length;
    if (days === 0) {
        const totalDays = wt.filter((x) => x.title !== null && x.type === "m");
        if (totalDays.length > 0) {
            return totalDays[0].days === undefined ? 0 : totalDays[0].days;
        }
    }
    return days;
};

export const getMonthLastDay = (d) => {
    const dt = new Date(d);
    return new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
};