import React, { Component } from "react";
import Graph from "./Graph";


export default class CrazyLine extends Component {
  render() {
    return (
      <div className="bg-white"><Graph /></div>
    );
  }
}
