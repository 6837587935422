import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getAllDataTypes, getDataTypeMappings, loadAllDataTypeMappings, loadAllDataTypes } from "../../store/dataTypes";
import XMLParser from "react-xml-parser";
import { getFieldData } from "../../../services/appService";
import { generatedContractReceived, getGeneratedDocument, loadGenerateContractDocument } from "../../store/ddrmsDocument";
import AddDataTypeMapping from "./AddDataTypeMapping";
import moment from "moment";

const DataMapping = (props) => {
    const { app, handleCloseMapping, selectedObj, workflowTask, applicantObj } = props;

    const [allMapObjects, setAllMapObjects] = useState([]);
    const [dataTypeMapping, setDataTypeMapping] = useState([]);
    const [newMappingShowHide, setNewMappingShowHide] = useState(false);
    const [btnProcessStatus, setBtnProcessStatus] = useState(false);


    const getGeneratedDocumentSelector = useSelector(getGeneratedDocument);
    const dataTypeMappingsSelector = useSelector(getDataTypeMappings);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAllDataTypeMappings());
    }, []);

    useEffect(() => {
        setDataTypeMapping(dataTypeMappingsSelector);
    }, [dataTypeMappingsSelector])

    const handleGenerate = () => {
        setBtnProcessStatus(true);
        var mapData = {};
        allMapObjects.map(item => {
            let ddValue = "";
            item.replaceDataTypes.filter(x => x.attValue !== "").map(v => {
                ddValue += v.attValue + " ";
            });
            mapData[item.field] = ddValue;
        })
        var frmData = new FormData();
        frmData.append("ciid", workflowTask.ciid);
        frmData.append("docId", selectedObj.id);
        frmData.append("mapData", JSON.stringify(mapData))
        dispatch(loadGenerateContractDocument(frmData));
    }

    useEffect(() => {
        if (getGeneratedDocumentSelector) {
            const url = window.URL.createObjectURL(getGeneratedDocumentSelector);
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";

            if (selectedObj?.name)
                link.setAttribute("download", selectedObj.name);
            else
                link.setAttribute("download", "ipcs_document");

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            dispatch(generatedContractReceived(null));
            setBtnProcessStatus(false);
        }
    }, [getGeneratedDocumentSelector]);

    const handleAddNewMapping = () => {
        setNewMappingShowHide(!newMappingShowHide);
    }

    const handleChangeMapping = ({ currentTarget }) => {
        const ddMaps = dataTypeMapping.filter(x => x.id === parseInt(currentTarget.selectedOptions[0].value));
        if (ddMaps.length > 0) {
            const mpJs = JSON.parse(ddMaps[0].mappingJson);
            console.log(mpJs);
            let att = "";
            let vl = "";
            if (applicantObj.partnerType) {
                att = applicantObj.partnerType;
                vl = applicantObj.fuid;
            }
            else {
                att = applicantObj.registrationType;
                vl = applicantObj.id;
            }

            const updMpjs = mpJs.map(x => {
                x.replaceDataTypes.map(d => {
                    if (d.attType === "currentDate" && d.attObj === "date") {
                        d.attValue = moment(new Date()).format("MM-DD-YYYY");
                    }
                    else if (d.attType === att && d.attObj === "id") {
                        d.attValue = vl;
                    }
                    else {
                        const attValue = getFieldData(app.applicationData, d.attType.toLowerCase(), d.attObj);
                        d.attValue = attValue;
                    }
                    return d;
                })
                return x;
            })
            setAllMapObjects(updMpjs)
        }
    }

    const handleDeleteMappItem=(item)=>{
        const ddMapps=[...allMapObjects];
        const index=ddMapps.findIndex(x=>x.field === item.field);
        if(index > -1){
            ddMapps.splice(index,1);
            setAllMapObjects(ddMapps);
        }
    }

    return <>
        {selectedObj && <>
            <div className="col-md-12 border p-2 form-group">Document : <b>({selectedObj.id}) {selectedObj.name}</b></div>
        </>}

        {!newMappingShowHide &&
            <div className="row">
                <div className="col-md-12 form-group">
                    <label htmlFor="ddMapping" className="form-control-label">Existing DataType Mapping</label>
                    <button className="btn btn-link btn-sm float-right mr-0 pr-0" onClick={handleAddNewMapping}>Add New Mapping</button>
                    <select
                        className="form-control"
                        name="ddMapping"
                        id="ddMapping"
                        onChange={handleChangeMapping}
                    >
                        <option value="">Select</option>
                        {dataTypeMapping.map(x =>
                            <option value={x.id}>{x.name}</option>
                        )}
                    </select>
                </div>
                <div className="col-md-12 form-group">
                    <div className="table-responsive bg-white">
                        <table className="table align-items-center table-flush table-hover table-striped border">
                            <thead className="thead-light">
                                <tr>
                                    <th>Field</th>
                                    <th>DataTypes</th>
                                    <th>Replace Value</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allMapObjects.map((item) => (
                                    <tr>
                                        <td>{item.field}</td>
                                        <td>
                                            {item.replaceDataTypes.map(m =>
                                                <p>{m.attType}.{m.attObj}</p>
                                            )}
                                        </td>
                                        <td>
                                            {item.replaceDataTypes.map(m =>
                                                <p>{m.attValue}</p>
                                            )}
                                        </td>
                                        <td>
                                            <button className="btn btn-link btn-sm" 
                                            onClick={()=>handleDeleteMappItem(item)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-md-12 text-center">
                    {/* <button className="btn btn-sm btn-outline-danger" onClick={handleCloseMapping}>
                        <i className="fa fa-times" aria-hidden="true"></i> Close
                    </button> */}
                    <button className="btn btn-sm btn-outline-default"
                        disabled={allMapObjects.length === 0 || btnProcessStatus}
                        onClick={handleGenerate}
                    >
                        {btnProcessStatus ? <i className="fa fa-spinner fa-spin mr-2"></i>
                            : <i className="fa fa-cog mr-2" aria-hidden="true"></i>}
                        Generate
                    </button>
                </div>
            </div>}

        {newMappingShowHide && <AddDataTypeMapping app={app} applicantObj={applicantObj} handleAddNewMapping={handleAddNewMapping} />}

    </>

}

export default DataMapping;