import React from "react";
import Form from "../../comman/form";
import SecurityQuestion from "./SecurityQuestion";
import http from "../../services/HttpService";
import config from "../../config.json";
import Joi from "joi-browser";
import ThankYou from "./ThankYou";

class ForgotUserName extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      securityQuestion: false,
      userNameBlock: true,
      userTypes: [],
      secQuestion: "",
      emailAuth: false,
      phoneAuth: false,
      securityAuth: false,
      thankYou: false,
      questionAuth: false,
      sendCodeBtnLoding: false,
      verifyBtnLoding: false,
      reSendCodeBtnLoding: false,
      reSendCodeMsg: false,
    };
  }

  schema = {
    //firstName: Joi.string().required().label("First Name"),
    //lastName: Joi.string().required().label("Last Name"),
    email: Joi.string().email().required().label("Email"),
    //userType: Joi.string().required().label("User Type"),
  };

  handleSecurityQuestion = (question) => {
    this.setState({
      securityQuestion: true,
      userNameBlock: false,
      secQuestion: question,
      questionAuth: false,
    });
  };

  doSubmit = async () => {
    const { data, errors } = this.state;
    const { data: result } = await http.post(config.VerifyUserByEmail, data);
    if (result === "") {
      errors["email"] = "Invalid Details";
      this.setState({ errors });
    } else if (result.length > 0) {
      this.handleSecurityQuestion(result);
    } else {
      errors["email"] = "Security question not set!";
      this.setState({ errors });
    }
  };

  handleEmailAuth = () => {
    this.setState({ emailAuth: true, phoneAuth: false, errors: {}, data: {} });
  };
  handlePhoneAuth = () => {
    this.setState({ phoneAuth: true, emailAuth: false, errors: {}, data: {} });
  };

  handleSecurityCode = async () => {
    const { data, emailAuth, phoneAuth, errors } = this.state;
    const { forgotType } = this.props;
    if (
      forgotType !== "Username" &&
      (data["userName"] === "" || data["userName"] === undefined)
    ) {
      if (emailAuth) errors["userName"] = "Please enter username";
      if (phoneAuth) errors["phone"] = "Please enter username";
      this.setState({ errors });
    } else if (
      data["email"] === "" ||
      (data["email"] === undefined && emailAuth)
    ) {
      errors["email"] = "Please enter email";
      this.setState({ errors });
    } else if (
      data["phone"] === "" ||
      (data["phone"] === undefined && phoneAuth)
    ) {
    } else {
      this.setState({ sendCodeBtnLoding: true });
      if (emailAuth && !phoneAuth) {
        const { data: result } = await http.get(
          config.SendSecurityCodeToEmail +
          data["userName"] +
          "&email=" +
          data["email"] +
          "&type=" +
          forgotType
        );
        if (result === "fail") {
          errors["email"] = "Invalid Details";
          this.setState({ errors, sendCodeBtnLoding: false });
        } else {
          this.setState({
            securityAuth: true,
            sendCodeBtnLoding: false,
            errors: {},
          });
        }
      }

      if (phoneAuth && !emailAuth) {
        const { data: result } = await http.get(
          config.SendSecurityCodeToPhone +
          data["userName"] +
          "&phone=" +
          encodeURIComponent(data["phone"]) +
          "&type=" +
          forgotType
        );
        if (result === "fail") {
          errors["phone"] = "Invalid Details";
          this.setState({ errors, sendCodeBtnLoding: false });
        } else {
          this.setState({
            securityAuth: true,
            sendCodeBtnLoding: false,
            errors: {},
          });
        }
      }
    }
  };
  handleVerifyCode = async () => {
    const { data, errors, emailAuth, phoneAuth } = this.state;
    const { forgotType } = this.props;
    if (data["securityCode"] === "" || data["securityCode"] === undefined) {
      errors["securityCode"] = "Please enter security code";
      this.setState({ errors });
    } else {
      this.setState({ verifyBtnLoding: true });
      let email = data["email"];
      if (email) email = email.toLowerCase();
      if (phoneAuth) email = data["phone"];
      const { data: result } = await http.get(
        config.VerifySecurityCode +
        email +
        "&code=" +
        data["securityCode"] +
        "&type=" +
        forgotType
      );
      if (result === "success") {
        this.setState({
          thankYou: true,
          userNameBlock: false,
          verifyBtnLoding: false,
        });
      } else {
        errors["securityCode"] = "Invalid Code";
        this.setState({ errors, verifyBtnLoding: false });
      }
    }
  };

  handleQuestionAuth = () => {
    this.setState({
      questionAuth: true,
      emailAuth: false,
      phoneAuth: false,
      data: {},
      errors: {},
    });
  };

  handleQuestionBack = () => {
    this.setState({
      securityQuestion: false,
      questionAuth: true,
      userNameBlock: true,
    });
  };

  handleBack = () => {
    const { emailAuth, phoneAuth, questionAuth, securityAuth } = this.state;
    if (!securityAuth && emailAuth) {
      this.setState({
        emailAuth: !emailAuth,
      });
    }
    if (!securityAuth && phoneAuth) {
      this.setState({
        phoneAuth: !phoneAuth,
      });
    }
    if (!securityAuth && questionAuth) {
      this.setState({
        questionAuth: !questionAuth,
      });
    }

    if (emailAuth || phoneAuth || questionAuth)
      this.setState({
        securityAuth: false,
      });
    else this.props.handleBackUser();
  };

  handleResendCode = async () => {
    const { data, emailAuth, phoneAuth, errors } = this.state;
    const { forgotType } = this.props;
    this.setState({ reSendCodeBtnLoding: true });
    if (emailAuth && !phoneAuth) {
      const { data: result } = await http.get(
        config.SendSecurityCodeToEmail +
        data["userName"] +
        "&email=" +
        data["email"] +
        "&type=" +
        forgotType
      );
      if (result === "fail") {
        errors["email"] = "Invalid Details";
        this.setState({
          errors,
          reSendCodeBtnLoding: false,
          reSendCodeMsg: false,
        });
      } else {
        this.setState({
          reSendCodeBtnLoding: false,
          reSendCodeMsg: true,
        });
      }
    }

    if (phoneAuth && !emailAuth) {
      const { data: result } = await http.get(
        config.SendSecurityCodeToPhone +
        data["userName"] +
        "&phone=" +
        encodeURIComponent(data["phone"]) +
        "&type=" +
        forgotType
      );
      if (result === "fail") {
        errors["phone"] = "Invalid Details";
        this.setState({
          errors,
          reSendCodeBtnLoding: false,
          reSendCodeMsg: false,
        });
      } else {
        this.setState({ reSendCodeBtnLoding: false, reSendCodeMsg: true });
      }
    }
  };

  render() {
    const {
      userNameBlock,
      securityQuestion,
      userTypes,
      secQuestion,
      data,
      emailAuth,
      phoneAuth,
      securityAuth,
      thankYou,
      questionAuth,
      sendCodeBtnLoding,
      verifyBtnLoding,
      reSendCodeBtnLoding,
      reSendCodeMsg,
    } = this.state;
    const { handleBackUser, forgotType, urlData } = this.props;
    return (
      <React.Fragment>
        {userNameBlock && (
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn btn-link pl-0 btn-sm"
                onClick={this.handleBack}
              >
                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                Back
              </button>
            </div>
            {questionAuth && (
              <React.Fragment>
                <div className="col-md-12">
                  {this.renderInput("email", "Enter Your Registered Email")}
                </div>
                <div className="col-md-12">
                  <button
                    className="btn btn-danger w-100"
                    disabled={this.validate()}
                    onClick={this.handleSubmit}
                  >
                    NEXT
                  </button>
                </div>
              </React.Fragment>
            )}
            {!emailAuth && !phoneAuth && !questionAuth && (
              <div className="col-md-12 d-flex flex-column align-items-center text-center">
                <h3>Verify Your Identity</h3>
                <p>
                  You will now be required to enter One-Time Security Code to
                  verify your identity.
                </p>
                <br />
                <h3>How would you like to receive the code?</h3>
                <br />
                <div className="d-flex flex-row align-items-center text-center ">
                  <button
                    className="btn btn-danger mr-2"
                    onClick={this.handleEmailAuth}
                  >
                    Send To My Email
                  </button>
                  <button
                    className="btn btn-danger mr-2"
                    onClick={this.handlePhoneAuth}
                  >
                    Text To My Mobile
                  </button>
                  <button
                    className="btn btn-danger mr-2"
                    onClick={this.handleQuestionAuth}
                  >
                    Security Question
                  </button>
                </div>
              </div>
            )}
            {forgotType !== "Username" && (emailAuth || phoneAuth) && (
              <div className="col-md-12">
                {this.renderInput("userName", "Enter Your Username")}
              </div>
            )}
            {emailAuth && (
              <div className="col-md-12">
                {this.renderInput("email", "Enter Your Registered Email")}
              </div>
            )}
            {phoneAuth && (
              <div className="col-md-12">
                {this.renderInput(
                  "phone",
                  "Enter Your Registered Mobile Number"
                )}
              </div>
            )}
            {securityAuth && (
              <div className="col-md-12">
                {this.renderInput(
                  "securityCode",
                  "Enter Your Code Below To Continue"
                )}
              </div>
            )}
            {(emailAuth || phoneAuth) && !securityAuth && (
              <div className="col-md-12 d-flex flex-row justify-content-center align-items-center text-center">
                <button
                  className="btn btn-danger"
                  disabled={sendCodeBtnLoding}
                  onClick={this.handleSecurityCode}
                >
                  {sendCodeBtnLoding && (
                    <i className="fa fa-refresh fa-spin mr-2"></i>
                  )}
                  Send Security Code To My {emailAuth && "email"}{" "}
                  {phoneAuth && "mobile"}
                </button>
              </div>
            )}
            {securityAuth && (
              <div className="col-md-12 d-flex flex-column align-items-center">
                <button
                  className="btn btn-success"
                  disabled={verifyBtnLoding}
                  onClick={this.handleVerifyCode}
                >
                  {verifyBtnLoding && (
                    <i className="fa fa-refresh fa-spin mr-2"></i>
                  )}
                  Verify
                </button>
                {/* <br />
                <br /> */}
                <p>Didn't receive the code?</p>
                <button
                  className="btn btn-sm btn-link pl-0"
                  disabled={reSendCodeBtnLoding}
                  onClick={this.handleResendCode}
                >
                  {reSendCodeBtnLoding && (
                    <i className="fa fa-refresh fa-spin mr-2"></i>
                  )}
                  Resend Code
                </button>
                {reSendCodeMsg && (
                  <span className="text-red">
                    Security Code Sent Successfully
                  </span>
                )}
              </div>
            )}
          </div>
        )}
        {thankYou && (
          <ThankYou forgotType={forgotType} urlData={urlData}></ThankYou>
        )}

        {securityQuestion && (
          <SecurityQuestion
            handleQuestionBack={this.handleQuestionBack}
            secQuestion={secQuestion}
            userType={data["userType"]}
            forgotType={forgotType}
            securityQuestion={securityQuestion}
            urlData={urlData}
          ></SecurityQuestion>
        )}
      </React.Fragment>
    );
  }
}

export default ForgotUserName;
