import { createSlice,createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "workflowRoles",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        roleTypes:[],
        performers:[],
        filterList:[],
        allRolesPerformers:[],
        allRoles:[]
    },
    reducers: {
        roleAdded: (workflowRoles, action) => {
            workflowRoles.list.push(action.payload);
        },
        allRolesReceived: (workflowRoles, action) => {
            workflowRoles.allRoles = action.payload;
        },
        rolesReceived: (workflowRoles, action) => {
            workflowRoles.list = action.payload;
        },
        filterRolesReceived: (workflowRoles, action) => {
            workflowRoles.filterList = action.payload;
        },
        roleTypesReceived: (workflowRoles, action) => {
            workflowRoles.roleTypes = action.payload;
        },
        rolePerformersReceived: (workflowRoles, action) => {
            workflowRoles.performers = action.payload;
        },
        allRolesPerformersReceived: (workflowRoles, action) => {
            workflowRoles.allRolesPerformers = action.payload;
        },
    }
});

export const { roleAdded,allRolesReceived, rolesReceived,roleTypesReceived,
    rolePerformersReceived,filterRolesReceived,allRolesPerformersReceived } = slice.actions;

export default slice.reducer;

export const loadWorkflowRoles = (pageSize, currentPage) => apiCallBegan({
    url: config.SetupUsers.GetWorkflowRoles + pageSize + "&currentPage=" + currentPage,
    onSuccess: rolesReceived.type
});

export const loadAllWorkflowRoles = () => apiCallBegan({
    url: config.SetupUsers.GetAllWorkflowRoles,
    onSuccess: allRolesReceived.type
});

export const loadAllWorkflowRolesPerformers = () => apiCallBegan({
    url: config.GetAllWorkflowPerformers,
    onSuccess: allRolesPerformersReceived.type
});

export const loadWorkflowRolesByFilter = (data) => apiCallBegan({
    url: config.SetupUsers.GetWorkflowRolesByFilter,
    method: "post",
    data: data,
    onSuccess: filterRolesReceived.type
});

export const loadRoleTypes = (name) => apiCallBegan({
    url: `${config.GetCodemanager}${name}`,
    onSuccess: roleTypesReceived.type
});

export const loadRolePerformers = (id) => apiCallBegan({
    url: `${config.SetupUsers.GetWorkflowPerformers}${id}`,
    onSuccess: rolePerformersReceived.type
});

export const getAllRoles= createSelector(
    state => state.entities.workflowRoles,
    workflowRoles => workflowRoles.list
);

export const getAllWorkfllowRoles= createSelector(
    state => state.entities.workflowRoles,
    workflowRoles => workflowRoles.allRoles
);

export const getFilterAllRoles= createSelector(
    state => state.entities.workflowRoles,
    workflowRoles => workflowRoles.filterList
);

export const getRoleTypes = createSelector(
    state => state.entities.workflowRoles,
    workflowRoles => workflowRoles.roleTypes
);

export const getRolePerformers = createSelector(
    state => state.entities.workflowRoles,
    workflowRoles => workflowRoles.performers
);

export const getAllRolesPerformers = createSelector(
    state => state.entities.workflowRoles,
    workflowRoles => workflowRoles.allRolesPerformers
);