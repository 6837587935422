import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useDocDownload from "../../hooks/useDocDownload";
import { useDispatch, useSelector } from "react-redux";
import { documentByIdReceived, documentDownloaded, downloadDocument,
     getDocumentById, getDownloadedDocumentData, loadDocumentById } from "../../store/documents";
import { getAlert } from "../../../services/appAlerts";

const DownloadDocLink = (props) => {

    const [doc,setDoc]=useState(null);
    const documentByIdSelector=useSelector(getDocumentById);
    const downloadedDocumentDataSelector = useSelector(getDownloadedDocumentData);
    
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch=useDispatch();

    useEffect(() => {
        const pathname = location.pathname.split("/");
        const docId = pathname[pathname.length - 1];
        if(docId){
            dispatch(loadDocumentById(docId));
        }
    }, []);

    useEffect(() => {
        if (downloadedDocumentDataSelector && doc?.docId > 0) {
            try {
                const url = window.URL.createObjectURL(downloadedDocumentDataSelector);
                const link = document.createElement("a");
                link.href = url;
                link.target = "_blank";
                link.setAttribute("download", doc.docName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                dispatch(documentDownloaded(null));
                dispatch(documentByIdReceived(null));
            } catch (error) {
                getAlert("", error?.message, "error");
            }
            navigate(-1);
        }
    }, [downloadedDocumentDataSelector]);

    useEffect(()=>{
        if(documentByIdSelector){
            setDoc(documentByIdSelector);
            dispatch(downloadDocument(documentByIdSelector?.docId));
        }
    },[documentByIdSelector]);
}

export default DownloadDocLink;