import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import _ from "lodash";
import { getAgenciesPartners, loadAgencies } from "../../../store/partner";
import geoService from "../../../../services/geoService";
import { getMonthlyInvoiceAgencyCandidates, loadMonthlyInvoiceAgencyCandidates } from "../../../store/reports";

const AgencyInvoicePayment = () => {

    const [usStates, setUsStates] = useState([]);
    const [allAgencies, setAllAgencies] = useState([]);
    const [stateAgencies, setSateAgencies] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [selectedStateAgency,setSelectedStateAgency]=useState("");
    const [allAgenciesCandidates, setAllAgenciesCandidates] = useState([]);
    const [agencyInvoicesPayments, setAgencyInvoicesPayments] = useState([]);

    const agenciesPartnersSelector = useSelector(getAgenciesPartners);
    const monthlyInvoiceAgencyCandidates = useSelector(getMonthlyInvoiceAgencyCandidates);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAgencies())
        loadUsStates();
    }, []);

    const loadUsStates = async () => {
        const states = await geoService.getStateList();
        setUsStates(states);
    }

    useEffect(() => {
        if (agenciesPartnersSelector.length > 0) {
            setAllAgenciesCandidates(agenciesPartnersSelector);
            const list = _.unionBy(agenciesPartnersSelector, "title");
            setAllAgencies(list);
        }
    }, [agenciesPartnersSelector]);

    useEffect(() => {
        if (monthlyInvoiceAgencyCandidates.length > 0) {
            const agencyRpt = [...agencyInvoicesPayments, ...monthlyInvoiceAgencyCandidates];
            setAgencyInvoicesPayments(agencyRpt);
        }
    }, [monthlyInvoiceAgencyCandidates]);

    const handleAgencyStateChange = ({ currentTarget }) => {
        const agencies = allAgencies.filter(
            (x) => x.state === currentTarget.value
        );
        setSateAgencies(agencies)
    };

    const handleAgencyChange = ({ currentTarget }) => {
        const selectedAgency = stateAgencies.filter(
            (x) => x.title === currentTarget.value
        )[0];
        
        setSelectedStateAgency(currentTarget.value);
        setSelectedAgency(selectedAgency);

        let agencyCandidates = [];
        if (currentTarget.value === "all") {
            stateAgencies.map((item) => {
                const cand = allAgenciesCandidates.filter((x) => x.title === item.title);
                agencyCandidates = [...agencyCandidates, ...cand];
            });
            //console.log(agencyCandidates);
        } else {
            agencyCandidates = allAgenciesCandidates.filter(
                (x) => x.title === currentTarget.value
            );
        }
        console.log(agencyCandidates);
        agencyCandidates.map(async (item) => {
            dispatch(loadMonthlyInvoiceAgencyCandidates(item.performerid));
        });
    };

    return <>
        <div className="card shadow-none border mb-2">
            <div className="card-header text-center p-3 bg-lighter">
                <h4 className="mb-0">
                    State - Invoice, Payments and Fees
                    (Monthly/Quarterly/Yearly)
                </h4>
            </div>
            <div className="card-body p-3">
                <div className="row">
                    <div className="col-md-3">
                        <label
                            htmlFor="usState"
                            className="form-control-label"
                        >
                            US State
                        </label>
                        <select
                            name="usState"
                            id="usState"
                            className="form-control form-control-sm"
                            onChange={handleAgencyStateChange}
                            //value={stateAgencies}
                        >
                            <option value="">Select</option>
                            {usStates.map((item) => (
                                <option value={item.name}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label
                            htmlFor="agency"
                            className="form-control-label"
                        >
                            Agency/Client
                        </label>
                        <select
                            name="agency"
                            id="agency"
                            className="form-control form-control-sm"
                            value={selectedStateAgency}
                            onChange={handleAgencyChange}
                        >
                            <option value="">Select</option>
                            {stateAgencies.map((item) => (
                                <option value={item.title}>
                                    {item.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label
                            htmlFor="agencyStartDate"
                            className="form-control-label"
                        >
                            Start Date
                        </label>
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            name="agencyStartDate"
                            id="agencyStartDate"
                        />
                    </div>
                    <div className="col-md-2">
                        <label
                            htmlFor="agencyEndDate"
                            className="form-control-label"
                        >
                            End Date
                        </label>
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            name="agencyEndDate"
                            id="agencyEndDate"
                        />
                    </div>
                    <div className="col-md-1 text-center mt-4 pt-1">
                        <button className="btn btn-sm btn-outline-default">
                            Generate
                        </button>
                       
                    </div>
                </div>
            </div>
        </div>
        <div className=" text-right mb-2">
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-sm btn-outline-default"
                table="table-to-xls-6"
                filename="Client_Monthly_Pay"
                sheet="Client_Monthly_Pay"
                buttonText="Export to Excel"
            />
            <button
                type="button"
                className="btn btn-sm btn-outline-default"
            //onClick={() => this.exportPDF(t)}
            >
                Generate PDF
            </button>
        </div>
        <div className="table-responsive">
            <table
                className="table align-items-center table-flush table-hover table-striped mb-2 border"
                id="table-to-xls-7"
            >
                <thead className="thead-light">
                    <th>#</th>
                    <th>Name of Candidate</th>
                    <th>IPCS Invoice #</th>
                    <th>Date Invoiced</th>
                    <th>Date Paid</th>
                    <th>Amount</th>
                    <th>
                        Tax ({selectedAgency ? selectedAgency.clientId : 0}%)
                    </th>
                </thead>
                <tbody>
                    {agencyInvoicesPayments.map((item) => (
                        <tr>
                            <td>{item.candidateId}</td>
                            <td>{item.candidateName}</td>
                            <td>{item.invoiceNo}</td>
                            <td>
                                {item.invoiceDate && (
                                    <Moment format="MMM Do YYYY, h:mm a">
                                        {item.invoiceDate}
                                    </Moment>
                                )}
                            </td>
                            <td>
                                {item.paymentDate && (
                                    <Moment format="MMM Do YYYY, h:mm a">
                                        {item.paymentDate}
                                    </Moment>
                                )}
                            </td>
                            <td>{item.totalAmount}</td>
                            <td>
                                {(item.totalAmount * (selectedAgency ? selectedAgency.clientId : 0)) / 100}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </>
}

export default AgencyInvoicePayment;