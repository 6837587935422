import React, { useState } from "react"
import Moment from "react-moment"
import Tools from "../../services/toolsJson.json";
import { getFieldData } from "../../services/appService"
import AddEditClient from "../../components/OMCRegistry/AddEditClient";

export default function ProjectInfo(props) {
    const { handleUpdateAgencyProjectInfo, candidateObj, appDocuments } = props;

    const [clientShowHide, setClientShowHide] = useState(false);
    const [noteShowHide, setNoteShowHide] = useState(false);
    const [editType, setEditType] = useState("");
    const [notesType, setNotesType] = useState("");
    const [timeLogObj, setTimeLogObj] = useState("");


    const handleClientShowHide = (type, objData) => {
        if (objData !== "" && objData !== "fail") {
            const objItem = { ...candidateObj };
            objItem.performerData = JSON.stringify(objData);
            //setSelectedItem(objItem);
            handleUpdateAgencyProjectInfo();
            setClientShowHide(false);
            setEditType("");
        } else {
            setClientShowHide(!clientShowHide);
            setEditType(type);
        }
    };

    const handleNoteShowHide = (type, obj) => {
        let tp = type;
        if (type === "save") tp = "";
        setNoteShowHide(!noteShowHide);
        setNotesType(tp);
        //setSelectedObj(obj);
        //setTimeLogObj("");
        //const { candidateObj: objItem } = state;
        //if (type === "save") loadComments(objItem.performerid);
    };

    const handleDownloadFile = async (item) => {
        // const { data } = await http.get(config.DownloadDocument + item.docId, {
        //   responseType: "blob",
        // });
        // if (data.size > 0) {
        //   const url = window.URL.createObjectURL(data);
        //   const link = document.createElement("a");
        //   link.href = url;
        //   link.target = "_blank";
        //   link.setAttribute("download", item.docName);
        //   document.body.appendChild(link);
        //   link.click();
        //   link.parentNode.removeChild(link);
        // }
    };

    let projectComp = "";
    const projectTools = Tools.filter(x => x.id === 39);
    if (projectTools.length > 0)
        projectComp = projectTools[0];

    return (
        <React.Fragment>
            <div className="card shadow-none border">
                <div className="card-header p-2">
                    <div className="row">
                        <div className="col-md-7">
                        <button className="btn btn-sm btn-link text-uppercase pl-0" data-toggle="collapse"
                                data-target="#agencyCollapse">
                                Project Engagement Details
                            </button>
                           
                        </div>
                        <div className="col-md-5 text-right">
                            {/* <button
                                className="btn btn-sm btn-link p-0"
                                onClick={() => handleToolShowHide(39, "project")}
                            >
                                Upload
                            </button> */}
                            <button
                                className="btn btn-sm btn-link p-0"
                                onClick={() =>
                                    handleClientShowHide("project", "")
                                }
                            >
                                Edit
                            </button>
                            {/* <button
                                className="btn btn-sm btn-link p-0"
                                onClick={() =>
                                    handleNoteShowHide("project", "")
                                }
                            >
                                Notes
                            </button> */}
                        </div>
                    </div>
                </div>
                <div className="card-body p-3 collapse" id="agencyCollapse">
                    <table className="table table-bordered mb-2">
                        <tbody>
                            <tr>
                                <th>Position Title</th>
                                <td style={{ whiteSpace: "pre-wrap" }}>
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "PositionTitle"
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Position ID</th>
                                <td>
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "PositionID"
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Duration</th>
                                <td>
                                    {candidateObj?.duration?.duration} Month
                                </td>
                            </tr>

                            <tr>
                                <th>Rate/Hour-Received By IPCS $</th>
                                <td>
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "RatePayableIpcs"
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Start Date</th>
                                <td>
                                    {candidateObj?.duration?.startDate && (
                                      <Moment format="MMM Do YYYY">
                                      {candidateObj.duration.startDate}
                                  </Moment>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>End Date</th>
                                <td>
                                    {candidateObj?.duration?.endDate && (
                                        <Moment format="MMM Do YYYY">
                                        {candidateObj.duration.endDate}
                                    </Moment>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th>Project Description</th>
                                <td style={{ whiteSpace: "pre-wrap" }}>
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "ProjectDescription"
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ul
                        className="list-group"
                        style={{ maxHeight: "150px", overflow: "auto" }}
                    >
                        {appDocuments
                            .filter((x) => x.uploadType === "project")
                            .map((doc) => (
                                <li className="list-group-item p-1">
                                    <span className="p-2">
                                        <Moment format="MMM Do YYYY">
                                            {doc.dateTime}
                                        </Moment>
                                    </span>
                                    <button
                                        className="btn btn-sm btn-link p-0 text-underline"
                                        onClick={() => handleDownloadFile(doc)}
                                    >
                                        {doc.docName}
                                    </button>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
            <AddEditClient
                clientShowHide={clientShowHide}
                handleClientShowHide={handleClientShowHide}
                candidateObj={candidateObj}
                getFieldData={getFieldData}
                editType={editType}
            ></AddEditClient>
        </React.Fragment>
    )
}