import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "schedules",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        addedStatus:false,
        deletedStatus:false,
        updatedStatus:false,
    },
    reducers: {
        appSchedulesReceived:(schedules,action)=>{
            schedules.list=action.payload;
            schedules.addedStatus=false;
            schedules.deletedStatus=false;
        },
        appScheduleAdded:(schedules,action)=>{
            schedules.addedStatus=true;
        },
        scheduleDeleted:(schedules,action)=>{
            schedules.deletedStatus=true;
        },
        scheduleUpdated:(schedules,action)=>{
            schedules.updatedStatus=true;
        },
        
    }
});

export const { appSchedulesReceived,appScheduleAdded,
    scheduleDeleted,scheduleUpdated } = slice.actions;
export default slice.reducer;

export const loadAppSchedules = (id) => apiCallBegan({
    url: config.GetScheduleMeeting + id,
    onSuccess: appSchedulesReceived.type
});

export const addAppSchedule = (data) => apiCallBegan({
    url: config.AddMeetingSchedule,
    method:"post",
    data:data,
    onSuccess: appScheduleAdded.type
});

export const updateAppSchedule = (data) => apiCallBegan({
    url: config.UpdateAppointment,
    method:"post",
    data:data,
    onSuccess: scheduleUpdated.type
});

export const deleteAppSchedule = (data) => apiCallBegan({
    url: config.DeleteAppointment,
    method:"post",
    data:data,
    onSuccess: scheduleDeleted.type
});

export const getAppSchedules = createSelector(
    state => state.entities.schedules,
    schedules => schedules.list
);

export const getAppScheduleAddedStatus = createSelector(
    state => state.entities.schedules,
    schedules => schedules.addedStatus
);

export const getAppScheduleDeletedStatus = createSelector(
    state => state.entities.schedules,
    schedules => schedules.deletedStatus
);

export const getAppScheduleUpdatedStatus = createSelector(
    state => state.entities.schedules,
    schedules => schedules.updatedStatus
);

