import React, { Component, useEffect, useState } from "react";
import Form from "../../../comman/form";
import http from "../../../services/HttpService";
import config from "../../../config.json";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import useForm from "../../../app/hooks/useForm";
import useControls from "../../../app/hooks/useControls";
import { candidateUpdated, getCandidateUpatedStatus, updateNewCandidate } from "../../../app/store/candidate";
import { useDispatch, useSelector } from "react-redux";
import { getAlert } from "../../../services/appAlerts";
import { getApplicationData } from "../../../app/store/application";

const Msp=(props)=> {
  
  const { candidateObj, updateSelectedObject, setSelectedTabKey,
    actType, setSelectedCandidateId } = props;

  const mspMode = [
    { id: 1, name: "Direct" },
    { id: 2, name: "MSP-DotStaff" },
    { id: 3, name: "MSP-ACRO" },
    { id: 4, name: "MSP-CAI" },
    { id: 5, name: "MSP-TAFFIN" },
    { id: 6, name: "Others" },
  ];

  const schema = {
    Msp_Mode: Joi.string().required().label("MSP Mode"),
  };

  const schema1 = {
    Msp_Contact1_Name: Joi.string().required().label("Name"),
    Msp_Contact1_Email: Joi.string()
      .email()
      .required()
      .label("Email Address")
      .allow(""),
    Msp_Contact1_Phone: Joi.string()
      .required()
      .length(10)
      .options({
        language: {
          string: {
            length: "Phone number must be 10 digits.",
          },
        },
      })
      .label("Phone Number"),
    Msp_Contact2_Name: Joi.string().required().label("Name"),
    Msp_Contact2_Email: Joi.string().email().required().label("Email Address"),
    Msp_Contact2_Phone: Joi.string()
      .required()
      .length(10)
      .options({
        language: {
          string: {
            length: "Phone number must be 10 digits.",
          },
        },
      })
      .label("Phone Number"),
  };

  const [aworkfinityApp, setAworkfinityApp] = useState(null);
  const [compName, setCompName] = useState("");

  const candidateUpatedStatusSelector=useSelector(getCandidateUpatedStatus)

  const dispatch=useDispatch();

  useEffect(() => {    
    if (candidateObj?.performerData) {
      const obj = getApplicationData(candidateObj.performerData);
      if (obj?.aWorkfinityApp){
        setAworkfinityApp(obj.aWorkfinityApp);
        const appName = Object.keys(obj.aWorkfinityApp)[1];
        const aWorkfinityAppEntity = obj.aWorkfinityApp[appName];
        const mspData = aWorkfinityAppEntity["candidateonboardingform"];
        const data={};
        if (mspData) {
          Object.keys(mspData).forEach(function (keyValue1) {
            if (keyValue1 !== "EntityId") {
              var item = mspData[keyValue1];
              if (!Array.isArray(item)) data[keyValue1.replace("@", "")] = item;
            }
          });
          setStateData(data);
        }
      }
    }
    else{
      setStateData({});
      setAworkfinityApp(null);
    }

  }, [candidateObj]);

  const doSubmit = () => {
    const NewAWorkfinityApp = { ...aworkfinityApp };
    const appName = Object.keys(NewAWorkfinityApp)[1];
    const aWorkfinityAppEntity = NewAWorkfinityApp[appName];
    const mspData = aWorkfinityAppEntity["candidateonboardingform"];
    Object.keys(stateData).forEach(function (keyValue) {
      mspData["@" + keyValue] = stateData[keyValue];
    });
    aWorkfinityAppEntity["candidateonboardingform"] = mspData;
    NewAWorkfinityApp[appName] = aWorkfinityAppEntity;
    const formData = new FormData();
    formData.append("appEntity", JSON.stringify(NewAWorkfinityApp));
    formData.append("recruiter", "");
    formData.append("subcontractor", "");
    formData.append("perfId", candidateObj.performerid);
    dispatch(updateNewCandidate(formData));
    setCompName("msp");
  }

  useEffect(()=>{
    if(candidateUpatedStatusSelector){
      if (actType === "edit"){
        getAlert("", "Updated Successfully", "success");
      }
      else{
        getAlert("", "Added Successfully", "success");
      }
      if (candidateObj?.performerid)
        updateSelectedObject(candidateObj.performerid);

      dispatch(candidateUpdated(null));

      if (compName === "msp") {
        setSelectedTabKey("home");
        setSelectedCandidateId(0);
      }
      
    }
  },[candidateUpatedStatusSelector]);

  const { validate, handleChange, stateData, setStateData,
    stateErrors, setStateErrors, handleSubmit } = useForm(schema, doSubmit);

  const { renderSelect, renderInput, renderTextArea } = useControls(stateData, stateErrors, handleChange, schema);

  return (
   <div className="col-md-12">
     <div className="row">
      <div className="col-md-12">
        {renderSelect("Msp_Mode", "MSP Mode", mspMode, "name", "name")}
      </div>
      <div className="col-md-12">
        <label
          htmlFor=""
          className="form-control-label bg-admin-light border-admin-left p-2 w-100"
        >
          MSP Contact 1
        </label>
      </div>
      <div className="col-md-4">
        {renderInput("Msp_Contact1_Name", "Name")}
      </div>
      <div className="col-md-4">
        {renderInput("Msp_Contact1_Email", "Email")}
      </div>
      <div className="col-md-4">
        {renderInput("Msp_Contact1_Phone", "Phone No.", "phone")}
      </div>
      <div className="col-md-12">
        <label
          htmlFor=""
          className="form-control-label bg-admin-light border-admin-left p-2 w-100"
        >
          MSP Contact 2
        </label>
      </div>
      <div className="col-md-4">
        {renderInput("Msp_Contact2_Name", "Name")}
      </div>
      <div className="col-md-4">
        {renderInput("Msp_Contact2_Email", "Email")}
      </div>
      <div className="col-md-4">
        {renderInput("Msp_Contact2_Phone", "Phone No.", "phone")}
      </div>
      <div className="col-md-12">
        {renderTextArea("mspNotes", "Notes")}
      </div>
      <div className="col-md-12 text-center form-group">
        <button
          className="btn btn-sm btn-outline-default"
          disabled={validate()}
          onClick={handleSubmit}
        >
          <i className="fa fa-check" aria-hidden="true"></i> Save and Done
        </button>
      </div>
    </div>
   </div>
  );
}

export default Msp;
