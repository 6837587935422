import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "phoneCalls",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        addedStatus:false,
        deletedStatus:false,
        updatedStatus:false,
    },
    reducers: {
        phoneCallsReceived:(phoneCalls,action)=>{
            phoneCalls.list=action.payload;
            phoneCalls.addedStatus=false;
            phoneCalls.deletedStatus=false;
            phoneCalls.updatedStatus=false;
        },
        phoneCallAdded:(phoneCalls,action)=>{
            phoneCalls.addedStatus=true;
        },
        phoneCallDeleted:(phoneCalls,action)=>{
            phoneCalls.deletedStatus=true;
        },
        phoneCallUpdated:(phoneCalls,action)=>{
            phoneCalls.updatedStatus=true;
        },
        
    }
});

export const { phoneCallsReceived, phoneCallAdded,
    phoneCallDeleted,phoneCallUpdated } = slice.actions;
export default slice.reducer;

export const loadAppPhoneCalls = (id) => apiCallBegan({
    url: config.GetPhoneCallHistory + id,
    onSuccess: phoneCallsReceived.type
});

export const addAppPhoneCall = (data) => apiCallBegan({
    url: config.AddPhoneCall,
    method:"post",
    data:data,
    onSuccess: phoneCallAdded.type
});

export const updateAppPhoneCall = (data) => apiCallBegan({
    url: config.UpdatePhoneNotes,
    method:"post",
    data:data,
    onSuccess: phoneCallUpdated.type
});

export const deleteAppPhoneCall = (data) => apiCallBegan({
    url: config.DeletePhoneNotes,
    method:"post",
    data:data,
    onSuccess: phoneCallDeleted.type
});

export const getAppPhoneCalls = createSelector(
    state => state.entities.phoneCalls,
    phoneCalls => phoneCalls.list
);

export const getAppPhoneCallsAddedStatus = createSelector(
    state => state.entities.phoneCalls,
    phoneCalls => phoneCalls.addedStatus
);

export const getAppPhoneCallsDeletedStatus = createSelector(
    state => state.entities.phoneCalls,
    phoneCalls => phoneCalls.deletedStatus
);

export const getAppPhoneCallsUpdatedStatus = createSelector(
    state => state.entities.phoneCalls,
    phoneCalls => phoneCalls.updatedStatus
);

