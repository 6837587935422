import React, { useEffect, useState } from "react";
import PageHeader from "./PageHeader";
import AddWorkflowPerformer from "./AddWorkflowPerformer";
import http from "../services/HttpService";
import auth from "../services/authService";
import config from "../config.json";
import Pagination from "../comman/pagination";
import { paginate, calculateIndex } from "../utlis/paginate";
import WfPerformerTable from "./WfPerformerTable";
import ActiveDeactiveHistory from "./SystemAdministration/ManagePartner/ActiveDeactiveHistory";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRolePerformers, loadRolePerformers } from "../app/store/workflowRole";
import { getLoggedUserDetails, loadUserDetails, getCurrentUser } from "../app/store/auth";
import { setCurrentPageTitle, setCurrentRoute } from "../app/store/layoutUI";
import { Tab, Tabs } from "react-bootstrap";
import { getFormatedNumber } from "../services/appService";
import Moment from "react-moment";

export default function ManageWorkflowPerformer(props) {
  const route = [
    { url: "/", name: "System Administration" },
    { url: "/", name: "Authorized Users" },
    { url: "/WorkflowUser", name: "Main Users" },
  ];

  const [performers, setPerformers] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [rowsCount, setRowsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);
  const [showHide, setShowHide] = useState(false);
  const [selectedObj, setSelectedObj] = useState(null);
  const [currentUser, setCurrentUser] = useState("");
  const [loggedUser, setLoggedUser] = useState("");
  const [performerId, setPerformerId] = useState("");
  const [performerName, setPerformerName] = useState("");
  const [mobile, setMobile] = useState("");
  const [logShowHide, setLogShowHide] = useState(false);
  const [actionType, setActionType] = useState("action");
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [selectedKey, setSelectedKey] = useState("home");

  const performerList = useSelector(getRolePerformers);
  const userDetails = useSelector(getLoggedUserDetails);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setCurrentRoute(route));
    dispatch(setCurrentPageTitle("Manage Workflow Role Users"))
  }, [])

  useEffect(() => {
    const obj = location?.state;
    if (obj?.roleid) {
      dispatch(loadRolePerformers(obj.roleid));
      setCurrentUser(obj);
    }
  }, []);

  useEffect(() => {
    getPagedData(performerList, currentPage, pageSize);
  }, [performerList]);

  useEffect(() => {
    const user = getCurrentUser();
    if (user?.id)
      dispatch(loadUserDetails(user.id))
  }, [])

  useEffect(() => {
    const user = getCurrentUser();
    if (user?.type === "admin" && userDetails !== null) {
      const usrDetails = { ...userDetails, roleType: "admin" };
      setLoggedUser(usrDetails);
    }
  }, [userDetails])

  const handleModalShowHide = () => {
    setShowHide(!showHide);
    const obj = performers.filter(x => x.id === selectedUserId);
    if (obj.length > 0)
      setSelectedObj(obj[0]);
  };

  const handleFormSubmit = async (performer) => {
    if (currentUser?.roleid > 0) {
      performer["roleId"] = currentUser.roleid;
      const { data } = await http.post(
        config.SetupUsers.AddWorkflowPerformer,
        performer
      );
      setShowHide(!showHide);
      dispatch(loadRolePerformers(currentUser.roleid));
    }

  };

  const getPagedData = (list, currentPage, pageSize) => {
    const items = paginate(list, currentPage, pageSize);
    setRowsCount(list.length);
    setPerformers(items);
  };

  const handleLogShowHide = (item, actionType) => {
    if (item === "save") {
      setLogShowHide(false);
      setSelectedObj("");
      setActionType(actionType);
      if (currentUser?.roleid)
        dispatch(loadRolePerformers(currentUser.roleid));
    } else {
      setLogShowHide(!logShowHide);
      setSelectedObj(item);
      setActionType(actionType);
    }
  };

  const handleFilter = async () => {
    let filtered =
      performerId !== ""
        ? performers.filter((p) => p.id === parseInt(performerId))
        : performers;

    filtered =
      performerName !== ""
        ? filtered.filter((x) =>
          x.name.toLowerCase().includes(performerName.toLowerCase())
        )
        : filtered;

    filtered =
      mobile !== ""
        ? filtered.filter((x) => x.contact.includes(mobile))
        : filtered;

    getPagedData(filtered, 1, pageSize)
  };

  const handleFilterCancel = () => {
    getPagedData(performerList, 1, pageSize);
    setPerformerId("");
    setPerformerName("");
    setMobile("");
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const handlePageChange = (page) => {
    const { startIndex: start, endIndex: end } = calculateIndex(page, startIndex, endIndex);
    setCurrentPage(page);
    setStartIndex(start);
    setEndIndex(end);
    getPagedData(performerList, page, pageSize);
  };

  const handlePageSizeChanged = ({ currentTarget }) => {
    setPageSize(currentTarget.value);
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(5);
    getPagedData(performerList, currentPage, currentTarget.value);
  };

  const handleSelectUser = (item) => {
    setSelectedUserId(item.id)
    setSelectedObj(item);
  }

  const handleResetSelection = () => {
    setSelectedUserId(0);
    setSelectedObj(null);
  }

  const handlePermissionShowHide = () => {
    dispatch(loadRolePerformers(currentUser.roleid));
    handleResetSelection()
    setSelectedKey("home");
  };

  const handlePermissionShowHide2 = () => {
    dispatch(loadRolePerformers(currentUser.roleid));
    setSelectedKey("home");
  };

  const handleTabSelect = (tab) => {
    setSelectedKey(tab);
  }

  let item = "";
  if (currentUser) item = currentUser;

  return (
    <React.Fragment>
      {/* <PageHeader
        route={route}
        parentTitle="Manage Users"
        headerTitle="Manage Users"
      ></PageHeader> */}
      <div className="col-md-12 text-right mb-2">
        <button
          onClick={() => navigate(-1)}
          className="btn btn-sm btn-outline-default btn-min-width"
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
        </button>
        {/* <button
                  className="btn btn-sm btn-default"
                  onClick={() => handleModalShowHide()}
                  disabled={
                    currentUser.totalEntryRecords > 0 &&
                    currentUser.activeUsers === currentUser.totalEntryRecords
                  }
                >
                  Add User
                </button> */}
      </div>
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table align-items-center table-flush table-striped border">
            <thead className="thead-light">
              <tr>
                <th>ID</th>
                <th>Role Type</th>
                <th>Role Name</th>
                <th>Date & Time</th>
                <th>Maximum Users</th>
                <th>Active Users</th>
              </tr>
            </thead>
            <tbody>
              {currentUser && <tr>

                <td>{item.roleid}</td>
                <td>{item.roleType}</td>
                <td>{item.rolename}</td>
                <td>
                  <Moment format="MMM Do YYYY">{item.dateTimeChanged}</Moment></td>
                <td>
                  {item.totalEntryRecords === 0 ? "Unrestricted" : item.totalEntryRecords}
                </td>
                <td>
                  {item.activeUsers}
                </td>

              </tr>}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-md-12 text-right">
        <button
          className="btn btn-sm btn-outline-default btn-min-width"
          data-toggle="collapse"
          data-target="#collapseExample"
        >
          <i className="fa fa-search" aria-hidden="true"></i> Search
        </button>
      </div>
      <div className="col-md-12">
        <div className="collapse p-2 bg-secondary border mt-2" id="collapseExample">
          <div className="row">
            <div className="col-md-2">
              <label
                htmlFor="performerId"
                className="form-control-label"
              >
                ID
              </label>
              <input
                type="text"
                name="performerId"
                id="performerId"
                className="form-control"
                placeholder="ID"
                value={performerId}
                onChange={({ currentTarget }) =>
                  setPerformerId(currentTarget.value)
                }
              />
            </div>
            <div className="col-md-5">
              <label
                htmlFor="performerName"
                className="form-control-label"
              >
                Name
              </label>
              <input
                type="text"
                name="performerName"
                id="performerName"
                className="form-control"
                placeholder="Name"
                value={performerName}
                onChange={({ currentTarget }) =>
                  setPerformerName(currentTarget.value)
                }
              />
            </div>
            <div className="col-md-5 form-group">
              <label htmlFor="mobile" className="form-control-label">
                Mobile No.
              </label>
              <input
                type="text"
                name="mobile"
                id="mobile"
                className="form-control"
                placeholder="Mobile No."
                value={formatPhoneNumber(mobile)}
                onChange={({ currentTarget }) =>
                  setMobile(currentTarget.value.replace(/[^\d]/g, ""))
                }
              />
            </div>
            <div className="col-md-12 text-center ">
              <button
                className="btn-sm btn-outline-default mr-2"
                onClick={() => handleFilter()}
              >
                <i className="fa fa-search mr-2" aria-hidden="true"></i>Filter
              </button>
              <button
                className="btn-sm btn-outline-default mr-2"
                onClick={handleFilterCancel}
              >
                <i className="fa fa-refresh mr-2" aria-hidden="true"></i>Refresh
              </button>
              <button
                className="btn-sm btn-outline-danger mr-2"
                data-toggle="collapse"
                data-target="#collapseExample"
                onClick={handleFilterCancel}
              >
                <i className="fa fa-close mr-2" aria-hidden="true"></i>Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <Tabs
          activeKey={selectedKey}
          onSelect={handleTabSelect}
          id="uncontrolled-tab-example"
          className="h3 border border-right-0 border-left-0 border-top-0 font-weight-normal"
        >
          <Tab eventKey="home" title="Users" tabClassName="page-tab border border-left-0 border-top-0">
            <Tab.Content className="border p-0 border-top-0">

              <div className="table-responsive">
                <table className="table align-items-center table-flush table-striped mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th>Select
                        <span style={{ cursor: "pointer" }} onClick={handleResetSelection}>
                          <i className="fa fa-refresh ml-2 text-primary" aria-hidden="true"></i>
                        </span>
                      </th>
                      <th>ID</th>
                      <th>Date & Time</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Contact No.</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {performers.map(item =>
                      <tr>
                        <td>
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input"
                              type="radio"
                              id={item.id}
                              name="role"
                              onChange={() => handleSelectUser(item)}
                              value={selectedUserId}
                              checked={selectedUserId === item.id}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={item.id}
                            >
                            </label>
                          </div>
                        </td>
                        <td>{item.id}</td>
                        <td>
                          <Moment format="MMM Do YYYY h:mm">
                            {item.addedDateTime}
                          </Moment>

                        </td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.contact && getFormatedNumber(item.contact)}</td>
                        <td>{item.status ? <><i
                          className="fa fa-check text-success"
                          style={{ fontSize: "15px" }}
                          aria-hidden="true"
                        ></i>{" "} Activated</> : <><i
                          className="fa fa-times text-danger"
                          style={{ fontSize: "15px" }}
                          aria-hidden="true"
                        ></i>{" "} Deactivated</>}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* <WfPerformerTable
                      data={performers}
                      handleModalShowHide={handleModalShowHide}
                      handleLogShowHide={handleLogShowHide}
                      roleType={loggedUser && loggedUser.roleType}
                    ></WfPerformerTable> */}
              </div>
              {/* <Pagination
                    currentPage={currentPage}
                    pageSize={pageSize}
                    itemsCount={rowsCount}
                    onPageChange={handlePageChange}
                    startIndex={startIndex}
                    endIndex={endIndex}
                  ></Pagination> */}
            </Tab.Content>
          </Tab>
          <Tab eventKey="addedituser" title={`${selectedUserId > 0 ? "Edit" : "Add"} User`}
            tabClassName="page-tab border border-left-0 border-top-0">
            <Tab.Content className="border p-3 border-top-0">
              <AddWorkflowPerformer selectedObj={selectedObj} currentUser={currentUser} handleFormSubmit={handleFormSubmit} handlePermissionShowHide={handlePermissionShowHide} />
            </Tab.Content>
          </Tab>
          <Tab eventKey="status" title="Activate/Deactivate" tabClassName="page-tab border border-left-0 border-right-0 border-top-0">
            <Tab.Content className="border p-3 border-top-0">
              {selectedUserId > 0 ?
                <ActiveDeactiveHistory selectedObj={selectedObj} type="performer" typeName="User" actionType={actionType} handlePermissionShowHide2={handlePermissionShowHide2} /> :
                <div className="col-md-12">
                  <h3 className="text-danger mt-1"> Alert : Please select the User first</h3>
                </div>
              }
            </Tab.Content>
          </Tab>

        </Tabs>
      </div>


    </React.Fragment>
  );

}
