import { createSlice,createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "partners",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        partnerTypes:[],
        performers:[],
        filterList:[],
        agencies:[],
        subcontractorPartners:[],
        recruiterPartners:[],
        subcontractorCandidates:[],
        recruiterCandidates:[],
        agencyCandidates:[],
        partnerListByType:[],
        registeredPartners:[],
        partnerPerformers:[],
    },
    reducers: {
        partnerAdded: (partners, action) => {
            partners.list.push(action.payload);
        },
        partnerReceived: (partners, action) => {
            partners.list = action.payload;
        },
        agenciesReceived: (partners, action) => {
            partners.agencies = action.payload;
        },
        filterPartnerReceived: (partners, action) => {
            partners.filterList = action.payload;
        },
        partnerTypesReceived: (partners, action) => {
            partners.roleTypes = action.payload;
        },
        partnerPerformersReceived: (partners, action) => {
            partners.performers = action.payload;
        },
        subcontractorPartnersRecevied: (partners, action) => {
            partners.subcontractorPartners = action.payload;
        },
        recruiterPartnersRecevied: (partners, action) => {
            partners.recruiterPartners = action.payload;
        },
        subcontractorCandidatesRecevied: (partners, action) => {
            partners.subcontractorCandidates = action.payload;
        },
        recruiterCandidatesRecevied: (partners, action) => {
            partners.recruiterCandidates = action.payload;
        },
        agencyCandidatesRecevied: (partners, action) => {
            partners.agencyCandidates = action.payload;
        },
        partnerListByTypeRecevied: (partners, action) => {
            partners.partnerListByType = action.payload;
        },
        registeredPartnersRecevied: (partners, action) => {
            partners.registeredPartners = action.payload;
        },
        partnerUsersRecevied: (partners, action) => {
            partners.partnerPerformers = action.payload;
        },
    }
});

export const { partnerAdded, partnerReceived,agenciesReceived,partnerTypesReceived,
    partnerPerformersReceived,filterPartnerReceived,
    subcontractorPartnersRecevied,recruiterPartnersRecevied,
    subcontractorCandidatesRecevied,recruiterCandidatesRecevied,
    agencyCandidatesRecevied,partnerListByTypeRecevied,registeredPartnersRecevied,
    partnerUsersRecevied } = slice.actions;
export default slice.reducer;

export const loadPartners = (pageSize, currentPage) => apiCallBegan({
    url: config.SetupUsers.GetWorkflowRoles + pageSize + "&currentPage=" + currentPage,
    onSuccess: partnerReceived.type
});

export const loadRegisteredPartners = () => apiCallBegan({
    url: config.GetRegisteredPartners,
    onSuccess: registeredPartnersRecevied.type
});

export const loadPartnerPerformersById = (id) => apiCallBegan({
    url: config.SetupUsers.GetPartnerPerformers+id,
    onSuccess: partnerUsersRecevied.type
});

export const loadPartnersByFilter = (data) => apiCallBegan({
    url: config.SetupUsers.GetWorkflowRolesByFilter,
    method: "post",
    data: data,
    onSuccess: filterPartnerReceived.type
});

export const loadPartnerTypes = (name) => apiCallBegan({
    url: `${config.GetCodemanager}${name}`,
    onSuccess: partnerTypesReceived.type
});

export const loadSubcontractorPartners = (type) => apiCallBegan({
    url: config.GetPartnerByType + type,
    onSuccess: subcontractorPartnersRecevied.type
});

export const loadAgencies = () => apiCallBegan({
    url: config.GetAgencyList,
    onSuccess: agenciesReceived.type
});

export const loadRecruiterPartners = (type) => apiCallBegan({
    url: config.GetPartnerByType+type,
    onSuccess: recruiterPartnersRecevied.type
});

export const loadPartnerPerformers = (id) => apiCallBegan({
    url: `${config.GetPartnerUsers}${id}`,
    onSuccess: partnerPerformersReceived.type
});

export const loadSubcontractorCandidates = (id) => apiCallBegan({
    url: config.GetPartnerCandidates + id + "&type=subcontractor",
    onSuccess: subcontractorCandidatesRecevied.type
});

export const loadRecruiterCandidates = (id) => apiCallBegan({
    url: config.GetPartnerCandidates + id + "&type=recruiter",
    onSuccess: recruiterCandidatesRecevied.type
});

export const loadAgencyCandidates = (id) => apiCallBegan({
    url: config.GetPartnerCandidates + id + "&type=recruiter",
    onSuccess: agencyCandidatesRecevied.type
});

export const loadPartnerListByType = (type) => apiCallBegan({
    url: config.GetPartnerList + type,
    onSuccess: partnerListByTypeRecevied.type
});

export const getAllPartners= createSelector(
    state => state.entities.partners,
    partners => partners.list
);

export const getFilterPartners= createSelector(
    state => state.entities.partners,
    partners => partners.filterList
);

export const getPartnerTypes = createSelector(
    state => state.entities.partners,
    partners => partners.partnerTypes
);

export const getPartnerPerformers = createSelector(
    state => state.entities.partners,
    partners => partners.performers
);

export const getPartnerUsers = createSelector(
    state => state.entities.partners,
    partners => partners.partnerPerformers
);

export const getSubcontractorPartners = createSelector(
    state => state.entities.partners,
    partners => partners.subcontractorPartners
);
export const getRecruiterPartners = createSelector(
    state => state.entities.partners,
    partners => partners.recruiterPartners
);

export const getAgenciesPartners = createSelector(
    state => state.entities.partners,
    partners => partners.agencies
);

export const getSubcontractorCandidates = createSelector(
    state => state.entities.partners,
    partners => partners.subcontractorCandidates
);

export const getRecruiterCandidates = createSelector(
    state => state.entities.partners,
    partners => partners.recruiterCandidates
);

export const getAgencyCandidates = createSelector(
    state => state.entities.partners,
    partners => partners.agencyCandidates
);

export const getPartnerListByType = createSelector(
    state => state.entities.partners,
    partners => partners.partnerListByType
);

export const getRegisteredPartners = createSelector(
    state => state.entities.partners,
    partners => partners.registeredPartners
);