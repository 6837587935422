import React, { Component } from "react";
import { Modal } from "react-bootstrap";

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  render() {
    const { helpShowHide, handleHelpShowHide, module } = this.props;
    return (
      <Modal show={helpShowHide} size="xl">
        <Modal.Header closeButton onHide={handleHelpShowHide}>
          <Modal.Title>
            {module === "Support" ? "Add Issue" :
              Help - { module }}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          {module === "Support" && <div className="row border bg-white pt-3 pb-3 rounded">
            <div className="col-md-12 form-group">
              <h3>Welcome to our Customer Support Portal!</h3>
            </div>
            <div className="col-md-12 form-group">
              <p>In this portal we offer you an easy way to register any issue you may encounter while working and using
                various functions and features.
              </p>
            </div>
            <div className="col-md-12 form-group">
              <p>Once you register an issue and submit, it will be received by our dedicated and committed customer and
                technical support staff who will review and provide instant fix(s), if required.</p>
            </div>
            <div className="col-md-12 form-group">
              <p>Our customer and technical support staff will also update and notify you of the action taken.</p>
            </div>
            <div className="col-md-12 form-group">
              <h3>Thank you.</h3>
              <h3>WorkFINITY®</h3>
              <h3>
                Customer and Technical Support</h3>
            </div>
          </div>}
          {/* <div className="row">
            <div className="col-md-12 border bg-white pt-3 pb-3 rounded">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
              laborum et enim error molestiae eos sed, asperiores, deleniti
              mollitia, autem tempora molestias soluta itaque? A accusantium
              repellat debitis ab fugiat?
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={handleHelpShowHide}
          >
            <i class="fa fa-times mr-1" aria-hidden="true"></i> Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Help;
