import { object } from "joi";
import React, { Component } from "react";
import Moment from "react-moment";
import Table from "../comman/table";

class WfPerformerTable extends Component {
  columns = [
    { path: "id", label: "ID" },
    {
      path: "addedDateTime",
      label: "Date & Time",
      content: (obj) =>
        obj.addedDateTime && (
          <React.Fragment>
            <Moment format="MMM Do YYYY">{obj.addedDateTime}</Moment>
            <br />
            <Moment format="h:mm a">{obj.addedDateTime}</Moment>
          </React.Fragment>
        ),
    },
    {
      path: "performername",
      label: "User Details",
      content: (obj) => (
        <React.Fragment>
          {obj.name}
          <br />
          {obj.email}
          <br />
          {obj.contact && obj.contact.slice(0, 3)}-
          {obj.contact && obj.contact.slice(3, 6)}-
          {obj.contact && obj.contact.slice(6)}
        </React.Fragment>
      ),
    },
    // { path: "title", label: "Role" },
    // { path: "email", label: "Email" },
    {
      path: "",
      label: "State",
      content: (obj) => <div>{obj.state}</div>,
    },
    // {
    //   path: "contact",
    //   label: "Mobile No.",
    //   content: (obj) => (
    //     <div>
    //       {obj.contact && obj.contact.slice(0, 3)}-
    //       {obj.contact && obj.contact.slice(3, 6)}-
    //       {obj.contact && obj.contact.slice(6)}
    //     </div>
    //   ),
    // },
    { path: "username", label: "Username" },

    {
      path: "status",
      label: "Status",
      content: (obj) =>
        (this.props.roleType === "Complete Application Ownership" ||
          this.props.roleType === "admin") && (
          <React.Fragment>
            {obj.status ? (
              <button
                className="btn btn-link btn-sm p-0 text-underline"
                onClick={() => this.props.handleLogShowHide(obj, "action")}
              >
                <i className="fa fa-check-square" aria-hidden="true"></i>{" "}
                Activated
              </button>
            ) : (
              <button
                className="btn btn-link btn-sm p-0 text-underline"
                onClick={() => this.props.handleLogShowHide(obj, "action")}
              >
                Deactivated
              </button>
            )}
            <br />
            <button
              className="btn btn-link btn-sm p-0 text-underline"
              onClick={() => this.props.handleLogShowHide(obj, "log")}
            >
              Log History
            </button>
          </React.Fragment>
        ),
    },
    {
      path: "",
      label: "Action",
      content: (obj) => (
        <button
          type="button"
          className="btn btn-link btn-sm p-0 text-underline"
          title="Edit User"
          onClick={() => this.props.handleModalShowHide(obj)}
        >
          Edit
        </button>
      ),
    },
  ];
  render() {
    const { data } = this.props;
    return <Table columns={this.columns} data={data}></Table>;
  }
}

export default WfPerformerTable;
