import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { getFormatedNumber, getFieldData } from "../../services/appService";
import AddEditDuration from "../../components/OMCRegistry/AddEditDuration";
import ViewRegistration from "../../components/OMCRegistry/ViewRegistration";
import ViewCandidate from "../../components/OMCRegistry/ViewCandidate";
import { getAddedDurationObj } from "../store/duration";
import { useSelector } from "react-redux";
import AddEditRates from "../../components/OMCRegistry/AddEditRates";
import { getAddedRateObj } from "../store/rates";


export default function CandidateInfo(props) {
    const { candidate } = props;
    const [durationShowHide, setDurationShowHide] = useState(false);
    const [regShowHide, setRegShowHide] = useState(false);
    const [viewShowHide, setViewShowHide] = useState(false);
    const [editDurType, setEditDurType] = useState("");
    const [viewType, setViewType] = useState("");
    const [item, setItem] = useState("");

    const addedDurationObjSelector = useSelector(getAddedDurationObj);
    const addedRateObjSelector = useSelector(getAddedRateObj);

    useEffect(() => {
        if (candidate) setItem(candidate);
    }, [candidate])

    const handleDurationShowHide = (obj, type, key) => {
        if(type === "save") setEditDurType("");
        else setEditDurType(type);
        setViewType("");
    };

    useEffect(() => {
        if (addedDurationObjSelector) {
            const objItem = { ...item };
            objItem.duration = addedDurationObjSelector;
            setItem(objItem);
        }
    }, [addedDurationObjSelector]);

    useEffect(() => {
        if (addedRateObjSelector) {
            const objItem = { ...item };
            objItem.rates = addedRateObjSelector;
            setItem(objItem);
        }
    }, [addedRateObjSelector]);

    const handleDurationChanges = (newObj) => {
        setEditDurType("");
        setDurationShowHide(false);
        setViewType("");
    };

    const handlViewRegistration = () => {
        setRegShowHide(!regShowHide);
        //setSelectedObj(item);
    };

    const handleViewShowHide = (view) => {
        setEditDurType("");
        if(viewType === view){
            setViewShowHide(false);
            setViewType("");
        }else{
            setViewType(view);
            setViewShowHide(true);
        }
    };


    return (
        <React.Fragment>
            <div className="table-responsive border">
                <table className="table align-items-center table-flush table-hover table-striped mb-2">
                    <thead className="thead-light">
                        <tr>
                            <th>Candidate # <br />IPCS ID</th>
                            <th>Candidate Details</th>
                            <th>Entity Type</th>
                            <th>Subcontractor</th>
                            <th>Recruiter</th>
                            <th>Agency/Client</th>
                            <th>MSP Mode</th>
                            <th>Duration<br />(Start-End)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {item &&
                        <tr>
                            <td>
                                {item.performerid}
                                <br />
                                {item.maxloans}
                            </td>
                            <td>
                                <button
                                    onClick={() => handleViewShowHide("candidate")}
                                    //data-target="#collapseExample"
                                    //data-toggle="collapse"
                                    className="btn p-0 btn-link btn-sm text-uppercase">
                                    {item.performername} {item.lastName}
                                </button>
                                <br />
                                {item.performeremail}
                                <br />
                                {getFormatedNumber(item.contactNo)}
                                <br />
                                <Moment format="MMM Do YYYY, h:mm a">
                                    {item.addedDateTime}
                                </Moment>
                            </td>
                            <td>
                                {getFieldData(
                                    item.performerData,
                                    "candidateonboardingform",
                                    "CandidateEntity"
                                ) === "1099 Basis" && "1099"}
                                {getFieldData(
                                    item.performerData,
                                    "candidateonboardingform",
                                    "CandidateEntity"
                                ) === "C2C Basis" && "C2C"}
                                {getFieldData(
                                    item.performerData,
                                    "candidateonboardingform",
                                    "CandidateEntity"
                                ) === "W2 Basis" && "W2"}
                                <br />
                                {getFieldData(
                                    item.performerData,
                                    "candidateonboardingform",
                                    "CandidateEntity"
                                ) === "1099 Basis" && (
                                        <React.Fragment>
                                            Rate :{" "}
                                            <b>
                                                $
                                                {item.rates?.candidateRate}
                                                /Hr
                                            </b>
                                        </React.Fragment>
                                    )}
                                {getFieldData(
                                    item.performerData,
                                    "candidateonboardingform",
                                    "CandidateEntity"
                                ) === "W2 Basis" && (
                                        <React.Fragment>
                                            {getFieldData(
                                                item.performerData,
                                                "candidateonboardingform",
                                                "w9_Type"
                                            )}
                                            <br /> Rate :{" "}
                                            <b>
                                                $
                                                {item.rates?.candidateRate}
                                                /Hr
                                            </b>
                                            <br />
                                            Per Diem Rate :{" "}
                                            <b>
                                                {getFieldData(
                                                    item.performerData,
                                                    "candidateonboardingform",
                                                    "Per_Diem_Rate"
                                                )}
                                            </b>
                                        </React.Fragment>
                                    )}
                            </td>
                            <td>
                                {getFieldData(
                                    item.performerData,
                                    "candidateonboardingform",
                                    "CandidateEntity"
                                ) === "C2C Basis" ? (
                                    <React.Fragment>
                                        <button
                                            className="btn p-0 btn-link btn-sm text-uppercase"
                                            //data-target="#collapseExample"
                                            //data-toggle="collapse"
                                            onClick={() =>
                                                handleViewShowHide("subcontractor")
                                            }
                                        >
                                            {item.subcontractor?.label}
                                        </button>
                                        <br />
                                        ID : <b>{item.validId}</b>,Rate :{" "}
                                        <b>
                                            ${item.rates?.subcontractorRate}/Hr
                                        </b>
                                        <br />
                                        {item.subcontractor?.email}
                                        <br />
                                        {item.subcontractor && (
                                            <Moment format="MMM Do YYYY, h:mm a">
                                                {item.subcontractor.createdDate}
                                            </Moment>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    "NA"
                                )}
                            </td>
                            <td>
                                {item?.recruiter ? (
                                    <React.Fragment>
                                        <button
                                            className="btn p-0 btn-link btn-sm text-uppercase"
                                            //data-target="#collapseExample"
                                            //data-toggle="collapse"
                                            onClick={() =>
                                                handleViewShowHide("recruiter")
                                            }
                                        >
                                            {item.recruiter?.label}
                                        </button>
                                        <br />
                                        ID : <b>{item.recruiter.fuid}</b>,Rate :{" "}
                                        <b>
                                            ${item.rates?.recruiterRate}/Hr
                                        </b>
                                        <br />
                                        {item.recruiter.email}
                                        <br />
                                        <Moment format="MMM Do YYYY, h:mm">
                                            {item.recruiter.createdDate}
                                        </Moment>
                                    </React.Fragment>
                                ) : (
                                    "NA"
                                )}
                            </td>

                            <td>
                                <button className="btn p-0 btn-link btn-sm text-uppercase"
                                 //data-target="#collapseExample"
                                 //data-toggle="collapse"
                                    onClick={() =>
                                        handleViewShowHide("agency")
                                    }>
                                    {getFieldData(
                                        item.performerData,
                                        "projectengagement",
                                        "AgencyName"
                                    )}
                                </button>
                                <br />
                                Rate :{" "}
                                <b>
                                    ${item.rates?.agencyRate}/Hr
                                </b>
                            </td>
                            <td>
                                <button className="btn p-0 btn-link btn-sm text-uppercase"
                                 //data-target="#collapseExample"
                                 //data-toggle="collapse"
                                    onClick={() =>
                                        handleViewShowHide("msp")
                                    }
                                >
                                    {getFieldData(
                                        item.performerData,
                                        "candidateonboardingform",
                                        "Msp_Mode"
                                    )}
                                </button>
                                <br />
                                State/MSP <br />
                                Admin Fee :{" "}
                                <b>
                                    {getFieldData(
                                        item.performerData,
                                        "projectengagement",
                                        "State_Admin_Fee"
                                    )}
                                    %
                                </b>
                                <br /> Tech Fee :{" "}
                                <b>
                                    $
                                    {getFieldData(
                                        item.performerData,
                                        "projectengagement",
                                        "Tech_Fee"
                                    )}
                                    /Hr
                                </b>
                            </td>
                            <td>
                                {item.duration && (
                                    <React.Fragment>
                                        {item.duration.duration} Months
                                        <br />
                                        Start :{" "}
                                        <Moment format="MMM Do YYYY">
                                            {item.duration.startDate}
                                        </Moment>
                                        <br />
                                        End :{" "}
                                        <Moment format="MMM Do YYYY">
                                            {item.duration.endDate}
                                        </Moment>
                                    </React.Fragment>
                                )}
                                <br />
                                <button
                                    className="btn btn-sm btn-link p-0"
                                    onClick={() => handleDurationShowHide("","duration","")}
                                >
                                    Edit Duration
                                </button>
                                <br />
                                <button
                                    className="btn btn-sm btn-link p-0"
                                    onClick={() => handleDurationShowHide("","rate","")}
                                >
                                    Modify Rates
                                </button>
                            </td>
                        </tr> }
                    </tbody>
                </table>
            </div>
                 {viewType !== "" &&
            <div className="p-3 border mt-2">
                 <ViewCandidate
                    viewShowHide={viewShowHide}
                    handleViewShowHide={handleViewShowHide}
                    selectedObj={item}
                    viewType={viewType}
                ></ViewCandidate>
            </div> }
            <AddEditDuration
                durationShowHide={durationShowHide}
                handleDurationShowHide={handleDurationShowHide}
                selectedObj={candidate}
                editType={editDurType}
                handleDurationChanges={handleDurationChanges}
            ></AddEditDuration>
            <AddEditRates durationShowHide={durationShowHide}
                handleDurationShowHide={handleDurationShowHide}
                selectedObj={candidate}
                editType={editDurType}
                handleDurationChanges={handleDurationChanges}></AddEditRates>
        </React.Fragment>
    )
}

