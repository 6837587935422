import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class OmcActionPopup extends Component {
  render() {
    const { omcShowHide, handleOmcModalShowHide, selectedObj } = this.props;
    return (
      <Modal show={omcShowHide} size="lg">
        <Modal.Header closeButton onClick={handleOmcModalShowHide}>
          <Modal.Title>OMC Action Update - View</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          <div className="row">
            <div className="col-md-6 form-group">
              <label htmlFor="" className="form-control-label">
                Approval Status : 
              </label>
              <span class="badge badge-pill badge-success ml-3">Approved</span>
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="" className="form-control-label">
                Patient Approval ID : 
              </label>
              <span class="badge badge-pill badge-success ml-3"></span>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="" className="form-control-label">
                Notes
              </label>
              <textarea col="2" rows="2" className="form-control"></textarea>
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="" className="form-control-label">
                Letter from OMC
              </label>

              <div className="card card-body shadow-none border p-3">
                <h3 className="text-center">Approval Congratulation Letter</h3>
                <p>
                  Congratulations! You are now officially registered with the
                  program.
                </p>
                <br />
                <p>
                  To purchase medical cannabis, you will need to complete and
                  submit a Patient Self-Evaluation Form. Attached is this form.
                </p>
                <br />
                <p>
                  If you are under 18 — or if at your certification appointment,
                  your health care practitioner determined you need a caregiver
                  — the person who is authorized to pick up your medical
                  cannabis on your behalf will also need to fill out this form.
                </p>
                <br />
                <p>To access the form:</p>
                <br />
                <p>
                  Log in to your account on the Medical Cannabis Patient
                  Registry.
                </p>
                <br />
                <p>
                  Submit a new Patient Self-Evaluation Form each time you
                  request medical cannabis.
                </p>
                <br />
                <p>
                  Attached is a list of Cannabis Patient Centers located in
                  Minneapolis, St. Paul, Eagan, Bloomington, Rochester, St.
                  Cloud, Willmar, Moorhead and Hibbing. Find the Cannabis
                  Patient Center closest to you. Before visiting a Cannabis
                  Patient Center, please call to verify the hours of operation.
                </p>
                <br />
                <p>
                  Be sure to bring along your appointment summary and the list
                  of medications currently prescribed to you that you received
                  at your health care practitioner visit.
                </p>
                <p>
                  At the Cannabis Patient Center, you'll meet with the onsite
                  licensed pharmacist who will:
                </p>
                <br />
                <p>
                  Review your self-evaluation report, practitioner appointment
                  summary and the list of your currently prescribed medications.
                </p>
                <br />
                <p>
                  Provide a medical cannabis dosage recommendation customized
                  for you.
                </p>
                <br />
                <p>Thank you</p>
                <h4>MN Dept. of Health/Office of Medical Cannabis</h4>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="danger" onClick={handleOmcModalShowHide}>
            <i className="fa fa-times" aria-hidden="true"></i> Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default OmcActionPopup;
