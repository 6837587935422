import React, { Component } from "react";
import { Link } from "react-router-dom";
import auth from "../services/authService";
import http from "../services/HttpService";
import config from "../config.json";
import ChangePassword from "./ChangePassword";
import Help from "./Anlytics/Help";
import SupportPupup from "./Anlytics/SupportPupup";

class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentTitle: "",
      headerTitle: "",
      user: {},
      showHide: false,
      userApp: {},
      helpShowHide: false,
      loginUser: "",
      issueShowHide: false,
    };
  }

  async componentDidMount() {
    const user = auth.getCurrentUser();
    let userId = user.id;
    if (user.type === "partner") {
      userId = user.linkUser;
    }
    // const { data: userApp } = await http.get(
    //   config.GetUserApplication + userId
    // );
    // const loginUser = await auth.getCurrentUserDetails(userId);
    this.setState({ user });
  }

  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  handleHelpShowHide = () => {
    this.setState({ helpShowHide: !this.state.helpShowHide });
  };

  handleIssueShowHide = () => {
    this.setState({ issueShowHide: !this.state.issueShowHide });
  };

  handleWorkflowTask = (data) => {
    this.props.history.push({
      pathname: "/DynamicHtmlForm",
      state: { workflowTask: data },
    });
  };

  handleCurrentTask = async (wiid) => {
    const { data: workflowTask } = await http.get(
      config.GetWorkflowTask + wiid
    );
    if (workflowTask.formId > 0) {
      if (workflowTask.formType === "CF") {
        this.props.history.push({
          pathname: "/" + workflowTask.formName,
          state: { workflowTask },
        });
      } else {
        this.props.history.push({
          pathname: "/DynamicHtmlForm",
          state: { workflowTask },
        });
      }
    }
  };

  render() {
    const { user, showHide, userApp, helpShowHide, loginUser, issueShowHide } =
      this.state;
    const { type, parentTitle, route, headerTitle } = this.props;
    const userType = localStorage.getItem("userType");
    return (
      <React.Fragment>
        <div
          className="header"
          style={{
            //backgroundImage: `URL("/assets/Img/slide3.jpg")`,
            backgroundPosition: "center center",
            borderTop: "1px solid #ccc",
            backgroundSize: "100%",
            color: "white",
            height: "70px",
            backgroundColor: "white",
          }}
        >
          <div className="container-fluid">
            <div className="header-body">
              <div className="row align-items-center mt-1">
                <div
                  className="col-lg-6 col-7"
                  style={{
                    display: parentTitle === "MyProfile" ? "none" : "",
                  }}
                >
                  <h6 className="h2 text-dark d-inline-block">{parentTitle}</h6>
                </div>
                <div className="col-lg-6">
                  <nav className="navbar navbar-top navbar-expand navbar-dark p-0">
                    <div className="container-fluid">
                      <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav align-items-center ml-md-auto">
                          <li className="nav-item d-xl-none">
                            <div
                              className="pr-3 sidenav-toggler sidenav-toggler-dark"
                              data-action="sidenav-pin"
                              data-target="#sidenav-main"
                            >
                              <div className="sidenav-toggler-inner">
                                <i className="sidenav-toggler-line" />
                                <i className="sidenav-toggler-line" />
                                <i className="sidenav-toggler-line" />
                              </div>
                            </div>
                          </li>
                          <li className="nav-item d-sm-none">
                            <a
                              className="nav-link"
                              href="!#"
                              data-action="search-show"
                              data-target="#navbar-search-main"
                            >
                              <i className="ni ni-zoom-split-in" />
                            </a>
                          </li>
                        </ul>
                        <ul className="navbar-nav align-items-center ml-auto ml-md-0">
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link pr-0"
                              href="#"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <div className="media align-items-center">
                                <span className="avatar avatar-sm rounded-circle">
                                  <img
                                    alt="Image placeholder"
                                    src="../../assets/img/theme/team-4.png"
                                  />
                                </span>
                                <div className="media-body ml-2 d-none d-lg-block">
                                  <span className="mb-0 text-sm font-weight-bold text-dark text-uppercase mt--1">
                                    <b>
                                      {user &&
                                      user.type === "admin" &&
                                      user.name === ""
                                        ? user.username
                                        : user.name}
                                    </b>
                                  </span>
                                  <h5 className="mb-0 text-dark mt--1">
                                    Reg ID :{" "}
                                    {user.type === "partner"
                                      ? user.linkUser
                                      : user.id}
                                    {user.type === "partner" &&
                                      `"Partner ID :" ${user.id} `}
                                    {",   "}
                                    <br />
                                    Role :{" "}
                                    {user.type === "admin"
                                      ? "Super Admin"
                                      : userType}
                                  </h5>
                                </div>
                              </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a href="/Profile" className="dropdown-item">
                                <i className="ni ni-single-02" />
                                <span>My Profile</span>
                              </a>
                              <button
                                className="dropdown-item"
                                onClick={this.handleModalShowHide}
                              >
                                <i className="ni ni-settings-gear-65" />
                                <span>Change Password</span>
                              </button>
                              {/* <button
                                className="dropdown-item"
                                onClick={this.handleHelpShowHide}
                              >
                                <i className="ni ni-support-16" />
                                <span>Help</span>
                              </button> */}

                              <div className="dropdown-divider" />
                              <a href="/logout" className="dropdown-item">
                                <i className="ni ni-user-run" />
                                <span>Logout</span>
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {type !== "portalForm" && (
          <div className="pl-0 pt-2 pb-2 mb-3 border col-md-12 bg-white">
            <div className="row">
              <div className="col-lg-8">
                <nav
                  aria-label="breadcrumb"
                  className="d-none d-md-inline-block ml-md-4 mt-1"
                >
                  <ol className="breadcrumb breadcrumb-links">
                    <li className="breadcrumb-item">
                      <a href="/">
                        <i className="fa fa-home" />
                      </a>
                    </li>
                    {headerTitle !== "Dashboard" && (
                      <React.Fragment>
                        <li className="breadcrumb-item">
                          <a href="/">Dashboard</a>
                        </li>
                      </React.Fragment>
                    )}
                    {headerTitle !== "Quick Links" &&
                      headerTitle !== "Dashboard" && (
                        <li className="breadcrumb-item">
                          <a href="/QuickLinks">Quick Links</a>
                        </li>
                      )}

                    {route?.map((obj) => (
                          <li key={obj.name} className="breadcrumb-item">
                            {obj.url === "" ? (
                              <span className="text-blue">{obj.name}</span>
                            ) : (
                              <Link
                                to={{ pathname: obj.url, state: obj.state }}
                              >
                                {obj.name}
                              </Link>
                            )}
                          </li>
                        ))}
                    {headerTitle && (
                      <li className="breadcrumb-item" aria-current="page">
                        {headerTitle}
                      </li>
                    )}
                  </ol>
                </nav>
              </div>
              <div className="col-lg-4 text-right">
                <button
                  //to="/SupportTicket"
                  className="btn btn-sm btn-danger m-0 mr-2"
                  title="Any defects/issues found in the Portal can be registered in the Customer Support Portal."
                  onClick={this.handleIssueShowHide}
                >
                  <i className="fa fa-support"></i> Access to Customer Support
                  Portal
                </button>
                <button
                  className="btn btn-sm btn-danger m-0"
                  onClick={this.handleHelpShowHide}
                >
                  <i className="fa fa-question-circle"></i> HELP
                </button>
                {window.location.pathname === "/" && (
                  <a
                    href="/QuickLinks"
                    className="btn btn-sm btn-primary m-0 ml-2"
                  >
                    <i
                      className="fa fa-angle-double-right mr-1"
                      aria-hidden="true"
                    ></i>
                    Continue
                  </a>
                )}
              </div>
            </div>
          </div>
        )}

        {showHide &&
          <ChangePassword
            handleModalShowHide={this.handleModalShowHide}
            showHide={showHide}
          ></ChangePassword>}
        {helpShowHide &&
          <Help
            helpShowHide={helpShowHide}
            handleHelpShowHide={this.handleHelpShowHide}
            module={parentTitle}
          ></Help>}
        {issueShowHide &&
          <SupportPupup
            handleIssueShowHide={this.handleIssueShowHide}
            issueShowHide={issueShowHide}
            handleCurrentTask={this.handleCurrentTask}
            handleWorkflowTask={this.handleWorkflowTask}
          ></SupportPupup>}
      </React.Fragment>
    );
  }
}

export default PageHeader;
