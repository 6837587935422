import React, { Component, useEffect, useState } from "react";
import { Popover, Button, OverlayTrigger, Tabs, Tab } from "react-bootstrap";
import AddFormLibrary from "./AddFormLibrary";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import config from "../../config.json";
import Moment from "react-moment";
import ViewForm from "./ViewForm";
import Swal from "sweetalert2";
import ViewFormModal from "../../ReviewTools/ViewFormModal";
import { useDispatch, useSelector } from "react-redux";
import {
  allowFormLibraryEdit, deleteFormLibrary, formLibraryEditAllowed,
  getAppFormLibrary, getAppFormLibraryDeleteStatus, getAppFormLibrarySendStatus,
  getFormLibraryEditStatus, loadAppLibraryForms, sendFormLibraryToApplicant
} from "../../app/store/forms";
import { getAlert, getConfirmAlert } from "../../services/appAlerts";

const FormsLibrary = (props) => {
  const { workflowTask, setViewBprmFormShowHide, handleUpdateComponent } = props;

  const [appFormLibrary, setAppFormLibrary] = useState([]);
  const [selectedKey, setSelectedKey] = useState("home");
  const [selectedFormId, setSelectedFormId] = useState(0);
  const [selectedForm, setSelectedForm] = useState(null);
  const [viewFormShowHide, setViewFormShowHide] = useState(false);
  const [updateCompStatus, setUpdateCompStatus] = useState(false);

  const appFormLibrarySelector = useSelector(getAppFormLibrary);
  const appFormLibrarySendStatusSelector = useSelector(getAppFormLibrarySendStatus);
  const appFormLibraryDeleteStatusSelector = useSelector(getAppFormLibraryDeleteStatus);
  const formLibraryEditStatusSelector = useSelector(getFormLibraryEditStatus);

  const dispatch = useDispatch();

  useEffect(() => {
    if (workflowTask?.ciid > 0) {
      dispatch(loadAppLibraryForms(workflowTask.ciid));
      setUpdateCompStatus(true);
    }
  }, []);

  useEffect(() => {
    if(appFormLibrarySelector.length > 0){
      const frmLibrary = appFormLibrarySelector
      .filter(x => x.formType !== "ACH Information" && x.formType !== "Insurance Information");
      setAppFormLibrary(frmLibrary);
      let frmItems = "";
      frmLibrary.map((x, key) => {
        if (key > 0) frmItems += ",";
        frmItems += x.id;
      })
      if (updateCompStatus) {
        handleUpdateComponent(frmItems);
        setUpdateCompStatus(false);
      }
    }
  }, [appFormLibrarySelector]);


  const handleSelectTab = (tab) => {
    setSelectedKey(tab);
  }

  const handleResetSelection = () => {
    setSelectedFormId(0);
    setSelectedForm(null);
  }

  const handleSelectForm = (frm) => {
    setSelectedForm(frm);
    setSelectedFormId(frm.id);
  }

  const getPopover = (notes) => {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h4">Notes</Popover.Title>
        <Popover.Content>{notes}</Popover.Content>
      </Popover>
    );
    return (
      <React.Fragment>
        <OverlayTrigger trigger="click" placement="top" overlay={popover}>
          <button className="btn btn-link btn-sm p-0 ">
            View
          </button>
        </OverlayTrigger>
      </React.Fragment>
    );
  };

  const handleDeleteForm = async (id) => {
    const result = await getConfirmAlert("", "Are you sure you want to Delete this Form?", "warning");
    if (result.isConfirmed)
      dispatch(deleteFormLibrary(id));
  };

  useEffect(() => {
    if (appFormLibraryDeleteStatusSelector) {
      getAlert("", "Deleted Successfully", "success");
      if (workflowTask?.ciid) {
        dispatch(loadAppLibraryForms(workflowTask.ciid));
        setUpdateCompStatus(true);
      }
    }
  }, [appFormLibraryDeleteStatusSelector])

  const handleAllowForm = async (f, allow) => {
    const result = await getConfirmAlert("", `Are you sure you want to give permission ${allow === 1 ? "to Edit" : "to Edit not allow"
      } this Form?`, "warning");

    if (result.isConfirmed)
      dispatch(allowFormLibraryEdit(f.id, allow,""));
  };

  useEffect(() => {
    if (formLibraryEditStatusSelector) {
      getAlert("", "Applied Successfully", "success");
      if (workflowTask?.ciid) dispatch(loadAppLibraryForms(workflowTask.ciid));
    }
  }, [formLibraryEditStatusSelector])

  const handleSendForm = (id) => {
    dispatch(sendFormLibraryToApplicant(id));
  };

  useEffect(() => {
    if (appFormLibrarySendStatusSelector) {
      getAlert("", "Sent Successfully", "success");
      if (workflowTask?.ciid) dispatch(loadAppLibraryForms(workflowTask.ciid));
    }
  }, [appFormLibrarySendStatusSelector])

  const handleAddFormLibrary = () => {
    setSelectedKey("home");
    setSelectedFormId(0);
    setSelectedForm(null);
    if (workflowTask?.ciid > 0) {
      dispatch(loadAppLibraryForms(workflowTask.ciid));
      setUpdateCompStatus(true);
    } 
  }

  const handleViewFormShowHide = (f) => {
    setViewFormShowHide(!viewFormShowHide);
    setViewBprmFormShowHide(!viewFormShowHide);
    setSelectedForm(f);
  };

  return (
    <React.Fragment>

      <Tabs
        activeKey={selectedKey}
        onSelect={handleSelectTab}
        id="uncontrolled-tab-example"
        className="h3 border border-right-0 border-left-0 border-top-0 font-weight-normal"
      >

        <Tab eventKey="home" title="Forms" tabClassName="page-tab border border-left-0 border-top-0">
          <Tab.Content className="border border-top-0">

            <div className="table-responsive">
              <table className="table align-items-center table-flush table-hover table-striped">
                <thead className="thead-light-green">
                  <tr>
                    <th>Select<span style={{ cursor: "pointer" }} onClick={handleResetSelection}>
                      <i className="fa fa-refresh ml-2 text-primary" aria-hidden="true"></i>
                    </span></th>
                    <th>S.No.</th>
                    <th>Date & Time</th>
                    <th>Name</th>
                    <th>Remarks</th>
                    <th>View</th>
                    <th>Added By</th>
                    <th>Status</th>
                    <th colSpan={3}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {appFormLibrary.map((f, key) => (
                    <tr>
                      <td>
                        <div className="custom-control custom-radio">
                          <input
                            className="custom-control-input"
                            type="radio"
                            id={f.id}
                            name="subcontractor"
                            onChange={() => handleSelectForm(f)}
                            value={selectedFormId}
                            checked={selectedFormId === f.id}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={f.id}
                          >
                          </label>
                        </div>
                      </td>
                      <td>{key + 1}</td>
                      <td>
                        <Moment format="MMM Do YYYY">
                          {f.createdDateTime}
                        </Moment>
                        <br />
                        <Moment format="h:mm a">
                          {f.createdDateTime}
                        </Moment>
                      </td>
                      <td>{f.formName}</td>
                      <td>{getPopover(f.remarks)}</td>
                      <td>
                        <button
                          className="btn btn-link btn-sm p-0"
                          onClick={() => handleViewFormShowHide(f)}
                        >
                          View
                          <span className="badge badge-pill badge-danger position-absolute mt--1">
                            {f.updatedCount}
                          </span>
                        </button>
                      </td>
                      <td>
                        {f.performerName}
                        <br />
                        {f.email}
                        <br />
                        {f.performerRole}
                      </td>
                      <td>
                        {f.updatedCount === 0 ? (
                          <span>
                            Pending
                          </span>
                        ) : (
                          <span>
                            Updated
                          </span>
                        )}
                      </td>
                      <td>
                        {f.sendStatus ? (
                          <span className="p-2">Sent</span>
                        ) : (
                          <button
                            className="btn btn-link btn-sm p-0"
                            onClick={() => handleSendForm(f.id)}
                          >
                            Send
                          </button>
                        )}
                      </td>
                      <td>
                        <button
                          className="btn btn-link btn-sm p-0"
                          onClick={() => handleDeleteForm(f.id)}
                        >
                          Delete
                        </button>
                      </td>
                      <td>
                        {(f.allowEdit === 0 || f.allowEdit === 2) && (
                          <button
                            className="btn btn-link btn-sm p-0"
                            onClick={() => handleAllowForm(f, 1)}
                          >
                            Edit Allow
                          </button>
                        )}

                        {f.allowEdit === 1 && (
                          <span>Edit Allowed</span>
                        )}
                        {f.allowEdit === 2 && (
                          <>
                            <br />
                            <span>Edit Request Received</span>
                            <br />
                            {f.editRemarks}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </Tab.Content>
        </Tab>
        {/* <Tab eventKey="contact" title="View Form" tabClassName="page-tab border border-left-0 border-top-0">
          <Tab.Content className="border border-top-0 p-3">
            {selectedFormId === 0 && <div className="">
              <h3 className="text-danger mt-1"> Alert : Please select the Form first</h3>
            </div>}
            {selectedFormId > 0 &&
              <ViewForm
                workflowTask={workflowTask}
                selectedForm={selectedForm}
              ></ViewForm>}
          </Tab.Content>
        </Tab> */}
        <Tab eventKey="profile" title={`${selectedFormId > 0 ? "Edit" : "Add"} Form`} tabClassName="page-tab border border-left-0 border-right-0 border-top-0">
          <Tab.Content className="border border-top-0 p-3">
            <AddFormLibrary
              //showHide={showHide}
              handleAddFormLibrary={handleAddFormLibrary}
              workflowTask={workflowTask}
              selectedForm={selectedForm}
            ></AddFormLibrary>
          </Tab.Content>
        </Tab>

      </Tabs>


      <ViewFormModal
        viewFormShowHide={viewFormShowHide}
        handleViewForm={handleViewFormShowHide}
        workflowTask={workflowTask}
        selectedForm={selectedForm}
      ></ViewFormModal>
    </React.Fragment>
  );
}

export default FormsLibrary;
