import React, { Component, useEffect, useState } from "react";
import PageHeader from "./PageHeader";
import CrazyLine from "../Charts/crazyLine";
import InfoHeader from "../comman/infoHeader";
import { Col, Nav, Row, Tab, Tabs, NavDropdown } from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import http from "../services/HttpService";
import auth from "../services/authService";
import geo from "../services/geoService";
import config from "../config.json";
import Swal from "sweetalert2";
import Moment from "react-moment";
import _ from "lodash";
import jsPDF from "jspdf";
import "jspdf-autotable";
import GeneratePdf from "./GeneratePdf";
import AddComplaince from "./AddComplaince";
import Progress from "../comman/Progress";
import WorkflowMapPopup from "./WorkflowMapPopup";
import PageSizeOptions from "./PageSizeOptions";
import Pagination from "../comman/pagination";
import { calculateIndex, paginate } from "../utlis/paginate";
import AddEditYearlyRpt from "./AddEditYearlyRpt";
import Subcontractor from "../app/components/Reports/Monthly/Ach/Subcontractor";
import Recruiter from "../app/components/Reports/Monthly/Ach/Recruiter";
import AgencyInvoiceBased from "../app/components/Reports/Monthly/Financial/AgencyInvoiceBased";
import AgencyCandidateBased from "../app/components/Reports/Monthly/Financial/AgencyCandidateBased";
import SubcontractorCandidates from "../app/components/Reports/Monthly/Payment/SubcontractorCandidates";
import RecruiterCandidates from "../app/components/Reports/Monthly/Payment/RecruiterCandidates";
import AgencyInvoiceCandidate from "../app/components/Reports/Monthly/AgencyInvoiceCandidate";
import GrossProfitCandidates from "../app/components/Reports/Monthly/GrossProfitCandidates";
import BurnHourCandidate from "../app/components/Reports/Monthly/BurnHourCandidate";
import AgencyInvBased from "../app/components/Reports/Quarterly/Financial/AgencyInvBased";
import AgencyCanBased from "../app/components/Reports/Quarterly/Financial/AgencyCanBased";
import AgencyInvoicePayment from "../app/components/Reports/Other/AgencyInvoicePayment";
import { useDispatch } from "react-redux";
import { setCurrentPageTitle, setCurrentRoute } from "../app/store/layoutUI";
import { useParams } from "react-router-dom";
import SubcontractorComp from "../app/components/Reports/Compliance/SubcontractorComp";
import RecruiterComp from "../app/components/Reports/Compliance/RecruiterComp";
import W2CandidateComp from "../app/components/Reports/Compliance/W2CandidateComp";
import Ten99CandidateComp from "../app/components/Reports/Compliance/Ten99CandidateComp";
import BurnHrCandidate from "../app/components/Reports/Quarterly/BurnHrCandidate";

const CustomReport = () => {

  const param = useParams();
  let type = param?.type;

  const AllReportNames = [
    {
      id: "monthly_subcontractor",
      name: "Monthly ACH – Payments to Subcontractors",
    },
    {
      id: "monthly_recruiter",
      name: "Monthly ACH – Payments to Recruiters",
    },
    {
      id: "monthly_client",
      name: "Financial Report - Monthly (Accrual Basis - Invoice Based)",
    },

    {
      id: "quarterly_client",
      name: "Financial Report - Quarterly (Accrual Basis - Invoice Based)",
    },
    {
      id: "monthly_client_1",
      name: "Financial Report - Monthly (Accrual Basis - Candidate Based)",
    },
    {
      id: "quarterly_client_1",
      name: "Financial Report - Quarterly (Accrual Basis - Candidate Based)",
    },
    {
      id: "payment_subcontractor",
      name: "Monthly Payments to Subcontractor (Per Candidate Basis)",
    },
    {
      id: "payment_recruiter",
      name: "Monthly Payments to Recruiter (Per Candidate Basis)",
    },
    {
      id: "payment_client",
      name: "Monthly Invoices to Client (Per Candidate-Basis)",
    },
    {
      id: "monthly_profit",
      name: "Monthly Gross Profit Reports – Per Candidate Basis",
    },
    {
      id: "monthly_burn",
      name: "Monthly Burn Reports – Per Candidate Basis",
    },
    {
      id: "quartly_burn",
      name: "Quarterly Burn Reports – Per Candidate Basis",
    },
  ];
  const AllComplianceRpt = [
    {
      id: "subcontractor_compliance",
      name: "Subcontractors",
    },
    {
      id: "recruiter_compliance",
      name: "Recruiters",
    },
    {
      id: "w2_candidate_compliance",
      name: "W2 Candidates",
    },
    {
      id: "1099_candidate_compliance",
      name: "1099 Candidates",
    },
  ];

  const route = [{ name: "Analytics", url: "" }];

  const [key, setKey] = useState(0);
  const [selectedReport, setSelectedReport] = useState("Select Report");
  const [selectedComplianceRpt, setSelectedComplianceRpt] = useState("Select the Entity to send the reports to");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPageTitle("Business Reports"));
    dispatch(setCurrentRoute(route));
  }, []);

  const handleSelectComplaince = (event) => {
    setSelectedComplianceRpt(event);
  };
  const handleSelectReport = (event) => {
    setSelectedReport(event);
  };

  return (
    <div className="col-md-12">
        <Tabs
          id="controlled-tab-example"
          onSelect={(k) => setKey(k)}
          className="bg-light border"
          variant="pills"
        >
          {type !== "compliance" &&
            <Tab eventKey="0" title="Internal to IPCS" tabClassName="m-3">
              <Tab.Container id="left-tabs-example" className="">
                <Row>
                  <Col sm={12}>
                    <Nav
                      variant="pills"
                      className="flex-row p-3 border"
                      style={{ backgroundColor: "#f0f0f0" }}
                    >
                      <NavDropdown
                        title={selectedReport}
                        id="nav-dropdown"
                      >
                        {AllReportNames.map((item) => (
                          <NavDropdown.Item 
                          eventKey={item.id}
                          onSelect={() =>
                            handleSelectReport(item.name)
                          }
                          >
                            {item.name}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    </Nav>
                  </Col>
                  <Col sm={12} className="mt-3">
                    <Tab.Content key="monthly_subcontractor">
                      <Tab.Pane eventKey="monthly_subcontractor">
                        <Subcontractor />
                      </Tab.Pane>
                      <Tab.Pane eventKey="monthly_recruiter">
                        <Recruiter />
                      </Tab.Pane>
                      <Tab.Pane eventKey="monthly_client">  
                        <AgencyInvoiceBased />
                      </Tab.Pane>
                      <Tab.Pane eventKey="quarterly_client">
                        <AgencyInvBased />
                      </Tab.Pane>
                      <Tab.Pane eventKey="monthly_client_1">
                        <AgencyCandidateBased />
                      </Tab.Pane>
                      <Tab.Pane eventKey="quarterly_client_1">
                        <AgencyCanBased />
                      </Tab.Pane>
                      <Tab.Pane eventKey="payment_subcontractor">
                        <SubcontractorCandidates />
                      </Tab.Pane>
                      <Tab.Pane eventKey="payment_recruiter">
                        <RecruiterCandidates />
                      </Tab.Pane>
                      <Tab.Pane eventKey="payment_client">
                        <AgencyInvoiceCandidate />
                      </Tab.Pane>
                      <Tab.Pane eventKey="monthly_profit">
                        <GrossProfitCandidates />
                      </Tab.Pane>
                      <Tab.Pane eventKey="monthly_burn">
                        <BurnHourCandidate />
                      </Tab.Pane>
                      <Tab.Pane eventKey="quartly_burn">
                        <BurnHrCandidate />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Tab>}
          {type !== "compliance" &&
            <Tab
              eventKey="1"
              title="Agencies - Monthly/Quarterly/Yearly"
              tabClassName="m-3"
            >
              <Tab.Container id="left-tabs-example" className="">
                <Row>
                  <Col sm={12}>
                    <Nav
                      variant="pills"
                      className="flex-row p-3 border"
                      style={{ backgroundColor: "#f0f0f0" }}
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="client_invoice"
                          className="mb-3 mr-3 p-2 border"
                        >
                          State - Invoice, Payments and Fees
                          (Monthly/Quarterly/Yearly)
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12} className="mt-3">
                    <Tab.Content>
                      <Tab.Pane eventKey="client_invoice">
                        <AgencyInvoicePayment />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Tab>}
          {type === "compliance" &&
            <Tab
              eventKey="2"
              title="Compliance Statements"
              tabClassName="m-3"
            >
              <Tab.Container id="left-tabs-example" className="">
                <Row>
                  <Col sm={12}>
                    <Nav
                      variant="pills"
                      className="flex-row p-3 border"
                      style={{ backgroundColor: "#f0f0f0" }}
                    >
                      <NavDropdown
                        title={selectedComplianceRpt}
                        id="nav-dropdown"
                        value={selectedComplianceRpt}
                      >
                        {AllComplianceRpt.map((item) => (
                          <NavDropdown.Item
                            eventKey={item.id}
                            onSelect={() =>
                              handleSelectComplaince(item.name)
                            }
                          >
                            {item.name}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    </Nav>
                  </Col>
                  <Col sm={12} className="mt-3">
                    <Tab.Content>
                      <Tab.Pane eventKey="subcontractor_compliance">
                          <SubcontractorComp />
                      </Tab.Pane>
                      <Tab.Pane eventKey="recruiter_compliance">
                            <RecruiterComp />
                      </Tab.Pane>
                      <Tab.Pane eventKey="w2_candidate_compliance">
                            <W2CandidateComp />
                      </Tab.Pane>
                      <Tab.Pane eventKey="1099_candidate_compliance">
                              <Ten99CandidateComp />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Tab>}
        </Tabs>
      </div>
  );
}

export default CustomReport;
