import React, { Component, useEffect, useState } from "react";
import Form from "../comman/form";
import { Modal, Button } from "react-bootstrap";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import UploadDocument from "../comman/UploadDocument";
import http from "../services/HttpService";
import auth, { getCurrentUser } from "../services/authService";
import config from "../config.json";
import BprmForm from "../comman/BprmForm";
import Progress from "../comman/Progress";
import WorkflowElement from "../app/components/WorkflowElement";
import { getStartedAppData, startNewApplication } from "../app/store/application";
import { getCurrentWorkflowTask, loadCurrentWorkflowTask } from "../app/store/WorkflowEngine";
import { useDispatch, useSelector } from "react-redux";

const AddComplaince = (props) => {
  const { showHideCompliance, handleShowHideCompliance, type, selectedObj } = props;
  
  const [workflowTask, setWorkflowTask] = useState(null);
  const [btnProcess,setBtnProcess]=useState(false);
  const [reqCount, setReqCount] = useState(0);
  const [apiCallCount, setApiCallCount] = useState(0);
  const [workflowFormData, setWorkflowFormData] = useState(null);
  const [documentInfo, setDocumentInfo] = useState({ docType: "compliance", updType: "compliance", message: "Compliance Document" })

  const appStartedDataSelector = useSelector(getStartedAppData);
  const currentWorkflowTaskSelector = useSelector(getCurrentWorkflowTask);

  const currentUser = getCurrentUser();

  const dispatch = useDispatch();

  useEffect(()=>{
    if (selectedObj) {
      const data = {};
      data["compliance.Id"] = selectedObj.id;
      data["compliance.Name"] = selectedObj.name;
      data["compliance.Year"] = selectedObj.year;
      data["compliance.Total_Invoice_Amt"] = selectedObj.totalInvoiceAmount;
      data["compliance.Total_Paid_Amt"] = selectedObj.totalPaidAmount;
      data["compliance.Total_Balance_Amt"] = selectedObj.totalDueAmount;
      if (type === "w2")
        data["compliance.Notes"] =
          "Attached is your previous year's W2 Wage and Tax statement.";
      else
        data["compliance.Notes"] =
          "Attached is your previous year's 1099-NEC statement.";
      setWorkflowFormData(data);
    }
  },[selectedObj])

  const handleRunApplication = () => {
    setBtnProcess(true);
    const newApplication = {
      workflowId: config.workflowsIds.compliance.subcontractor,
      performerId: parseInt(currentUser.id),
      applicationId: 1,
    };
    dispatch(startNewApplication(newApplication));
  };

  useEffect(() => {
    if (appStartedDataSelector?.ciid > 0) {
      setTimeout(() => {
        dispatch(loadCurrentWorkflowTask(appStartedDataSelector));
        setReqCount(1);
      }, 3000);
    }
  }, [appStartedDataSelector]);

  useEffect(() => {
    if (currentWorkflowTaskSelector?.formId === null && reqCount <= 2) {
      setTimeout(async () => {
        dispatch(loadCurrentWorkflowTask(appStartedDataSelector));
        const rqCount = reqCount + 1;
        setReqCount(rqCount)
      }, 3000);
    }
    else if (currentWorkflowTaskSelector?.wiid > 0) {
      setWorkflowTask(currentWorkflowTaskSelector);
      setBtnProcess(false);
    }
    else{
      handleShowHideCompliance("","save");
    }
  }, [currentWorkflowTaskSelector]);

  const handleNextTask = () => {
    setTimeout(() => {
      dispatch(loadCurrentWorkflowTask(workflowTask));
      setReqCount(1);
    }, 3000);
  };

  let notes = ""
  if (type === "w2")
    notes =
      "Attached is your previous year's W2 Wage and Tax statement.";
  else
    notes =
      "Attached is your previous year's 1099-NEC statement.";
  
  return (
    <React.Fragment>
      <Modal show={showHideCompliance} size="lg">
        <Modal.Header>
          <Modal.Title>
            {type === "w2" ? "Upload W2 Statement" : "Upload 1099-NEC"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         

          {!workflowTask && <div className="row">

            <div className="col-md-12 form-group">
              {notes} 
              <button className="btn btn-sm btn-primary ml-2" disabled={btnProcess} onClick={handleRunApplication}>
              {btnProcess && <i className="fa fa-spinner fa-spin mr-2"></i>}  
                Upload Document
              </button>
            </div>

          </div>}

          {workflowTask &&
            <div className="col-md-12">
              <WorkflowElement wfTask={workflowTask}
                handleNextTask={handleNextTask}
                workflowFormData={workflowFormData}
                documentInfo={documentInfo} />
            </div>
          }

         
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => handleShowHideCompliance("", "")}
          >
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default AddComplaince;
