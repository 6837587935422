import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import http from "../services/HttpService";
import config from "../config.json";

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formProcess: [],
    };
  }

  componentDidMount() {
    const { processId } = this.props;
    //console.log(processId);
  }

  async componentWillReceiveProps(nextProps) {
    const { processId, formProcess:frmProcess } = nextProps;
    if (processId > 0) {
      const { data: formProcess } = await http.get(
        config.GetFormProcessItems + processId
      );
      this.setState({ formProcess });
    }
    else{
      this.setState({formProcess:frmProcess});
    }

  }

  renderTime = ({ remainingTime }) => {
    const { formProcess } = this.state;
    const { processId } = this.props;
    let processItems = [];
    if (processId !== undefined) {
      processItems = formProcess.filter(
        (x) => x.formProcessId === parseInt(processId)
      );
    } else {
      processItems = formProcess;
    }

    //console.log(processItems);
    if (processItems.length > 0) {
      const index = Math.round(remainingTime / 2) - 1;
      //console.log(processItems[index]);
      //console.log(index);
      return (
        <div className="timer">
          {processItems[index] === undefined
            ? processItems[0].processItem
            : processItems[index].processItem}
        </div>
      );
    } else {
      if (remainingTime === 5 || remainingTime == 6) {
        return <div className="timer">Data Saving...</div>;
      }
      if (remainingTime === 3 || remainingTime == 4) {
        return <div className="timer">Submitting...</div>;
      }
      if (remainingTime === 1 || remainingTime == 2 || remainingTime === 0) {
        return <div className="timer">Processing...</div>;
      }
    }
  };

  render() {
    const { formProcess } = this.state;
    const { showHide, handleModalShowHide } = this.props;
    return (
      <Modal show={showHide} size={"xs"} centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="timer-wrapper">
                <CountdownCircleTimer
                  isPlaying
                  duration={formProcess.length > 3 ? formProcess.length * 2 : 6}
                  colors={[
                    ["#A30000", 0.33],
                    ["#F7B801", 0.33],
                    ["#218380", 0.33],
                  ]}
                  onComplete={() => [false, 1000]}
                >
                  {this.renderTime}
                </CountdownCircleTimer>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default Progress;
