import React, { Component } from "react";
import Joi from "joi-browser";
import { Modal, Button } from "react-bootstrap";
import Form from "../comman/form";
import http from "../services/HttpService";
import config from "../config.json";
import { Link } from "react-router-dom";

class TemplatePopup extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      selectedObj: {},
      emailTemplates: [],
      letterTemplates: [],
      tempType: "",
      TemplateTypes: [{ id: "Email", name: "Email Template" }, { id: "Letter", name: "Letter Template" }]
    };
  }

  schema = {
    templateId: Joi.string().required().label("Template"),
    notes: Joi.string().required().label("Notes"),
  };

  async componentDidMount() {
    const { data: emailTemplates } = await http.get(
      config.GetFormsByType + "EmailTemplate"
    );
    const { data: letterTemplates } = await http.get(
      config.GetFormsByType + "LetterTemplate"
    );
    this.setState({ emailTemplates, letterTemplates });
  }

  doSubmit = () => {
    const { data, emailTemplates, letterTemplates } = this.state;
    if (data["templateType"] === "Letter") {
      const selectedItem = letterTemplates.filter(
        (x) => x.id === parseInt(data["templateId"])
      );
      data["templateName"] = selectedItem[0].formTitle;
    }
    else {
      const selectedItem = emailTemplates.filter(
        (x) => x.id === parseInt(data["templateId"])
      );
      data["templateName"] = selectedItem[0].formTitle;
    }
    //data["templateType"] = tempType;
    this.props.handleSubmit(data);
  };

  componentWillReceiveProps=(props)=>{
    const {selectedTemplate}=props;
    const {data}=this.state;
    if(selectedTemplate){
      
      this.setState({data:selectedTemplate});
    }
    else{
      data["templateType"]="";
      data["notes"]="";
      data["templateId"]="";
      this.setState({data})
    }
  }

  render() {
    const { emailTemplates, letterTemplates, TemplateTypes, data } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            {this.renderSelect("templateType", "Template Type", TemplateTypes, "name", "id")}
          </div>
          {data["templateType"] === "Letter" &&
            <div className="col-md-12">
              {this.renderSelect(
                "templateId",
                "Select Letter Template",
                letterTemplates,
                "formTitle",
                "id"
              )}
            </div>}
          {data["templateType"] === "Email" &&
            <div className="col-md-12">
              {this.renderSelect(
                "templateId",
                "Select Email Template",
                emailTemplates,
                "formTitle",
                "id"
              )}
            </div>}
          <div className="col-lg-12">
            {this.renderTextArea("notes", "Notes")}
          </div>
          <div className="col-md-12 text-center">
            <button
              className="btn btn-sm btn-outline-default"
              onClick={this.handleSubmit}
            >
              <i className="fa fa-check" aria-hidden="true"></i> Save
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default TemplatePopup;
