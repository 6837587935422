import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "rates",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        addedStatus:false,
        deletedStatus:false,
        updatedStatus:false,
        addedRateObj:null
    },
    reducers: {
        ratesReceived:(rates,action)=>{
            rates.list=action.payload;
            rates.addedStatus=false;
            rates.deletedStatus=false;
            rates.updatedStatus=false;
            rates.addedRateObj=null;
        },
        rateAdded:(rates,action)=>{
            rates.addedRateObj=action.payload;
            rates.addedStatus=true;
        },
        rateDeleted:(rates,action)=>{
            rates.deletedStatus=true;
        },
        rateUpdated:(rates,action)=>{
            rates.updatedStatus=true;
        },
        
    }
});

export const { ratesReceived, rateAdded,
    rateDeleted,rateUpdated } = slice.actions;

export default slice.reducer;

export const loadAllRates = (id) => apiCallBegan({
    url: config.GetRateHistory + id,
    onSuccess: ratesReceived.type
});

export const addNewRates = (data) => apiCallBegan({
    url: config.AddNewRates,
    method:"post",
    data:data,
    onSuccess: rateAdded.type
});

export const updateRate = (data) => apiCallBegan({
    url: config.UpdateRates,
    method:"post",
    data:data,
    onSuccess: rateUpdated.type
});

export const deleteRate = (data) => apiCallBegan({
    url: config.DeletePhoneNotes,
    method:"post",
    data:data,
    onSuccess: rateDeleted.type
});

export const getAllRates = createSelector(
    state => state.entities.rates,
    rates => rates.list
);

export const getRateAddedStatus = createSelector(
    state => state.entities.rates,
    rates => rates.addedStatus
);

export const getAddedRateObj = createSelector(
    state => state.entities.rates,
    rates => rates.addedRateObj
);

export const getRateDeletedStatus = createSelector(
    state => state.entities.rates,
    rates => rates.deletedStatus
);

export const getRateUpdatedStatus = createSelector(
    state => state.entities.rates,
    rates => rates.updatedStatus
);

