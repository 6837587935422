import React, { Component } from "react";
import ReactDOM from "react-dom";
import * as createjs from "createjs-module";
import http from "../services/HttpService";
import config from "../config.json";

class WorkflowDesign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newTaskObject1: [
        {
          id: "0",
          typeId: "0",
          label: "Start",
          type: "start",
          description: "Start Description",
          xleft: "400",
          ytop: "50",
          icons: [],
          tec: { id: "", name: "", mouseover: "" },
          timer: {
            occurrence: "",
            dur: "",
            dur_units: "",
            Message: "",
            StopRuleId: "",
            mouseover: "",
          },
          alert: [],
          rules: [],
          Offers: [],
          interfaces: [],
          roles: [],
          eeobjstate: "",
          form: [],
          terminate: "",
          instruction: "",
          wfphase: { Id: "", phase: "" },
          performer: [],
          customemail: [],
          mouseover: "",
        },
        {
          id: "1",
          typeId: "99",
          label: "new workflow",
          type: "businessProcess",
          description: "Automated Description",
          xleft: "400",
          ytop: "150",
          icons: [],
          tec: { id: "", name: "", mouseover: "" },
          timer: {
            occurrence: "",
            dur: "",
            dur_units: "",
            Message: "",
            StopRuleId: "",
            mouseover: "",
          },
          alert: [],
          rules: [],
          Offers: [],
          interfaces: [],
          roles: [],
          eeobjstate: "",
          form: [],
          terminate: "",
          instruction: "",
          wfphase: { Id: "", phase: "" },
          performer: [],
          customemail: [],
          mouseover: "",
        },
        {
          id: "2",
          typeId: "4",
          label: "Automated",
          type: "automated",
          description: "Automated Description",
          xleft: "400",
          ytop: "250",
          icons: [],
          tec: { id: "", name: "", mouseover: "" },
          timer: {
            occurrence: "",
            dur: "",
            dur_units: "",
            Message: "",
            StopRuleId: "",
            mouseover: "",
          },
          alert: [],
          rules: [],
          Offers: [],
          interfaces: [],
          roles: [],
          eeobjstate: "",
          form: [],
          terminate: "",
          instruction: "",
          wfphase: { Id: "", phase: "" },
          performer: [],
          customemail: [],
          mouseover: "",
        },
      ],
      TaskRelation1: [
        {
          task: [
            {
              id: "0",
              typeId: "0",
              label: "Start",
              type: "start",
              description: "Start Description",
              xleft: "400",
              ytop: "50",
              icons: [],
              tec: { id: "", name: "", mouseover: "" },
              timer: {
                occurrence: "",
                dur: "",
                dur_units: "",
                Message: "",
                StopRuleId: "",
                mouseover: "",
              },
              alert: [],
              rules: [],
              Offers: [],
              interfaces: [],
              roles: [],
              eeobjstate: "",
              form: [],
              terminate: "",
              instruction: "",
              wfphase: { Id: "", phase: "" },
              performer: [],
              customemail: [],
              mouseover: "",
            },
          ],
          linkTo: [
            {
              task: [
                {
                  id: "1",
                  typeId: "99",
                  label: "new workflow",
                  type: "businessProcess",
                  description: "Automated Description",
                  xleft: "400",
                  ytop: "150",
                  icons: [],
                  tec: { id: "", name: "", mouseover: "" },
                  timer: {
                    occurrence: "",
                    dur: "",
                    dur_units: "",
                    Message: "",
                    StopRuleId: "",
                    mouseover: "",
                  },
                  alert: [],
                  rules: [],
                  Offers: [],
                  interfaces: [],
                  roles: [],
                  eeobjstate: "",
                  form: [],
                  terminate: "",
                  instruction: "",
                  wfphase: { Id: "", phase: "" },
                  performer: [],
                  customemail: [],
                  mouseover: "",
                },
              ],
              linkTo: [],
              rid: "1",
              connectTo: [
                {
                  condition: "Normal",
                  task: {
                    id: "0",
                    typeId: "0",
                    label: "Start",
                    type: "start",
                    description: "Start Description",
                    xleft: "400",
                    ytop: "50",
                    icons: [],
                    tec: { id: "", name: "", mouseover: "" },
                    timer: {
                      occurrence: "",
                      dur: "",
                      dur_units: "",
                      Message: "",
                      StopRuleId: "",
                      mouseover: "",
                    },
                    alert: [],
                    rules: [],
                    Offers: [],
                    interfaces: [],
                    roles: [],
                    eeobjstate: "",
                    form: [],
                    terminate: "",
                    instruction: "",
                    wfphase: { Id: "", phase: "" },
                    performer: [],
                    customemail: [],
                    mouseover: "",
                  },
                },
              ],
            },
            {
              task: [
                {
                  id: "2",
                  typeId: "4",
                  label: "Automated",
                  type: "automated",
                  description: "Automated Description",
                  xleft: "400",
                  ytop: "250",
                  icons: [],
                  tec: { id: "", name: "", mouseover: "" },
                  timer: {
                    occurrence: "",
                    dur: "",
                    dur_units: "",
                    Message: "",
                    StopRuleId: "",
                    mouseover: "",
                  },
                  alert: [],
                  rules: [],
                  Offers: [],
                  interfaces: [],
                  roles: [],
                  eeobjstate: "",
                  form: [],
                  terminate: "",
                  instruction: "",
                  wfphase: { Id: "", phase: "" },
                  performer: [],
                  customemail: [],
                  mouseover: "",
                },
              ],
              linkTo: [],
              rid: "2",
              connectTo: [
                {
                  condition: "Normal",
                  task: {
                    id: "1",
                    typeId: "99",
                    label: "new workflow",
                    type: "businessProcess",
                    description: "Automated Description",
                    xleft: "400",
                    ytop: "150",
                    icons: [],
                    tec: { id: "", name: "", mouseover: "" },
                    timer: {
                      occurrence: "",
                      dur: "",
                      dur_units: "",
                      Message: "",
                      StopRuleId: "",
                      mouseover: "",
                    },
                    alert: [],
                    rules: [],
                    Offers: [],
                    interfaces: [],
                    roles: [],
                    eeobjstate: "",
                    form: [],
                    terminate: "",
                    instruction: "",
                    wfphase: { Id: "", phase: "" },
                    performer: [],
                    customemail: [],
                    mouseover: "",
                  },
                },
              ],
            },
          ],
          rid: "0",
          connectTo: [],
        },
      ],
      stage: {},
      queue: {},
      doneTask: [],
      tolltip: {},
      container: {},
      out: {},
    };
  }

  async componentDidMount() {
    
    const { selectedObj } = this.props;
    const { data } = await http.get(config.GetWorkflow + selectedObj.ciid);

    if (data !== "") {
      const tasks = data.objectJson !== "" ? JSON.parse(data.objectJson) : "";
      const tasksRelation =
        data.relationJson !== "" ? JSON.parse(data.relationJson) : "";
      if (tasks !== "") this.setState({ newTaskObject1: tasks });
      if (tasksRelation !== "") this.setState({ TaskRelation1: tasksRelation });
    }

    const { data: doneData } = await http.get(
      config.GetWorkflowDoneTask + selectedObj.ciid
    );

    if (doneData !== "") this.setState({ doneTask: doneData });

    var canvas = ReactDOM.findDOMNode(this.refs.canvas);
    this.stage = new createjs.Stage(canvas);
    var container = new createjs.Container();
    var tolltip = new createjs.Shape();
    var out = new createjs.Text("", "13px Arial");
    this.setState({ container, tolltip, out });
    this.stage.enableMouseOver();

    var queue = new createjs.LoadQueue(false);
    queue.on("complete", this.loaded);
    queue.loadManifest([
      {
        id: "start",
        src: "/assets/Images/WF/icons/start1.png",
      },
      {
        id: "businessProcess",
        src: "/assets/Images/WF/icons/businessProcess.png",
      },
      {
        id: "automated",
        src: "/assets/Images/WF/icons/automated1.png",
      },
      {
        id: "automated_timed",
        src: "/assets/Images/WF/icons/automatedtimed1.png",
      },
      {
        id: "interface",
        src: "/assets/Images/WF/icons/interface.png",
      },
      {
        id: "person_based",
        src: "/assets/Images/WF/icons/personbased1.png",
      },
      {
        id: "role",
        src: "/assets/Images/WF/icons/performer.png",
      },
      {
        id: "conditional",
        src: "/assets/Images/WF/icons/conditional2.png",
      },
      {
        id: "alertEmail",
        src: "/assets/Images/WF/icons/alertEmail.png",
      },
      {
        id: "alertPush",
        src: "/assets/Images/WF/icons/alertPush.png",
      },
      {
        id: "alertText",
        src: "/assets/Images/WF/icons/alertText.png",
      },
      {
        id: "finish",
        src: "/assets/Images/WF/icons/finish1.png",
      },
      {
        id: "subProcess",
        src: "/assets/Images/WF/icons/subProcess1.png",
      },
      {
        id: "Timer",
        src: "/assets/Images/WF/icons/timer1.png",
      },
      {
        id: "Conditional_Person",
        src: "/assets/Images/WF/icons/conditionalperson1.png",
      },
      {
        id: "Conditional_person_Timer",
        src: "/assets/Images/WF/icons/conditionalpersontimer1.png",
      },
      {
        id: "Conditional_timed",
        src: "/assets/Images/WF/icons/conditionaltimed1.png",
      },
      {
        id: "custform",
        src: "/assets/Images/WF/icons/BI.png",
      },
      {
        id: "tmr",
        src: "/assets/Images/WF/icons/timer.png",
      },
      {
        id: "rule",
        src: "/assets/Images/WF/icons/rule.png",
      },
      {
        id: "offer",
        src: "/assets/Images/WF/icons/offer.png",
      },
      {
        id: "role",
        src: "/assets/Images/WF/icons/performer.png",
      },
      {
        id: "interface",
        src: "/assets/Images/WF/icons/interface.png",
      },
      {
        id: "businessProcess",
        src: "/assets/Images/WF/icons/businessProcess.png",
      },
    ]);
    this.setState({queue});
  }

  loaded = (event) => {
    //this.setState({ queue: event.currentTarget });
    this.DrawShapes2();
  };

  DrawShapes2() {
    var { TaskRelation1: nodejson1 } = this.state;
    var startNode = nodejson1[0].task[0];
    var firstNode = nodejson1[0].linkTo[0].task[0];
    this.addCircle1(
      startNode.xleft,
      startNode.ytop,
      "20",
      "blue",
      startNode,
      startNode.rid
    );

    for (var item in nodejson1[0].linkTo) {
      var node = nodejson1[0].linkTo[item];
      this.addCircle1(
        node.task[0].xleft,
        node.task[0].ytop,
        "20",
        "blue",
        node.task[0],
        node.rid
      );

      var color = "red";

      if (
        node.connectTo[0].task.id === "0" ||
        node.connectTo[0].task.id === "1" ||
        node.connectTo[0].task.id === "2"
      ) {
        color = "green";
      } else {
        var filterTask = this.state.doneTask.filter(
          (x) =>
            x.jsonItemId === parseInt(node.connectTo[0].task.id) ||
            x.jsonItemId === parseInt(node.task[0].id)
        );
        if (filterTask.length > 0) {
          color = "green";
        }
      }

      this.createLine2(
        node.connectTo[0].task.xleft,
        node.connectTo[0].task.ytop,
        node.task[0].xleft,
        node.task[0].ytop,
        color,
        node.connectTo[0].task.id,
        node.task[0].id,
        node.connectTo[0].condition,
        this.state.newTaskObject1
      );

      for (var a in node.linkTo) {
        var n = node.linkTo[a];
        this.shapes2(node, n, this.state.newTaskObject1);
      }
    }
  }

  shapes2(pn, cn, taskObj) {
    this.createLine3(
      pn.task[0].xleft,
      pn.task[0].ytop,
      cn.task[0].xleft,
      cn.task[0].ytop,
      "blue",
      cn.task[0].id,
      pn.task[0].id,
      cn.condition,
      taskObj
    );
    if (typeof cn.task[0].task != "undefined") {
      if (cn.task[0].task[0].length > 0) {
        for (var ccn in cn.linkTo) {
          var cnn = cn.linkTo[ccn];
          this.shapes2(cn, cnn);
        }
      }
    }
  }

  addCircle1(x, y, r, fill, node, rid) {
    this.addRectangle1(x, y, node, rid);
  }

  addRectangle1(x, y, node, rid) {
    var queue = this.state.queue;
    var cont = new createjs.Container();
    var c = new createjs.Shape();
    console.log(node.type);
    c.graphics
      .beginStroke(0)
      .beginBitmapFill(queue.getResult(node.type), "no-repeat")
      .drawRect(0, 0, 150, 40);
    cont.addChild(c);

    if (node.tec.id != "") {
      var tc = new createjs.Text();
      tc.text = ">> " + node.tec.id;
      tc.color = "Green";
      tc.textAlign = "left";
      tc.textBaseline = "top";
      tc.font = 'bold 11px "FontAwesome"';
      tc.x += 30;
      tc.y -= 12;
      tc.id = node.id + "_" + rid;
      cont.addChild(tc);
    }

    if (node.id != "0") {
      var tc = new createjs.Text();
      tc.text = "TP" + node.id;
      tc.color = "Black";
      tc.textAlign = "left";
      tc.textBaseline = "top";
      tc.font = 'bold 11px "FontAwesome"';
      tc.x -= 25;
      tc.y += 6;
      cont.addChild(tc);
    }

    if (node.type != "finish") {
      var txt = new createjs.Text();
      txt.text = node.label;
      txt.color = "black";
      txt.textAlign = "left";
      txt.textBaseline = "top";
      txt.font = '12px "Segoe UI Semilight"';
      txt.x += 45;
      txt.y += 6;
      cont.addChild(txt);
    }

    var ix = 15;
    var iy = 0;
    if (node.timer.type != "" && node.timer.type != undefined) {
      var tm = new createjs.Shape();
      ix += 30;
      tm.graphics
        .beginStroke(0)
        .beginBitmapFill(queue.getResult("tmr"), "no-repeat")
        .drawRect(0, 0, 25, 25);
      tm.x += ix;
      tm.y += 22;
      cont.addChild(tm);
    }

    if (node.icons.length > 0) {
      for (var i in node.icons) {
        var item = node.icons[i];
        var ic = new createjs.Shape();
        ix += 30;
        ic.graphics
          .beginStroke(0)
          .beginBitmapFill(queue.getResult(item), "no-repeat")
          .drawRect(0, 0, 25, 25);
        ic.x += ix;
        ic.y += 22;
        cont.addChild(ic);
      }
    }

    if (node.alert.length > 0) {
      for (var i in node.alert[0].alertObj.type) {
        var al = node.alert[0].alertObj.type[i];
        var alertImageName = "";
        if (al.type == "Text") {
          alertImageName = "alertText";
        } else if (al.type == "Push") {
          alertImageName = "alertPush";
        } else if (al.type == "Email") {
          alertImageName = "alertEmail";
        }
        var ic = new createjs.Shape();
        ix += 30;
        ic.graphics
          .beginStroke(0)
          .beginBitmapFill(queue.getResult(alertImageName), "no-repeat")
          .drawRect(0, 0, 25, 25);
        ic.x += ix;
        ic.y += 22;
        cont.addChild(ic);
      }
    }

    if (node.rules.length > 0) {
      var ic = new createjs.Shape();
      ix += 30;
      ic.graphics
        .beginStroke(0)
        .beginBitmapFill(queue.getResult("rule"), "no-repeat")
        .drawRect(0, 0, 25, 25);
      ic.x += ix;
      ic.y += 22;
      cont.addChild(ic);
    }

    if (node.Offers.length > 0) {
      var ic = new createjs.Shape();
      ix += 30;
      ic.graphics
        .beginStroke(0)
        .beginBitmapFill(queue.getResult("offer"), "no-repeat")
        .drawRect(0, 0, 25, 25);
      ic.x += ix;
      ic.y += 22;
      cont.addChild(ic);
    }

    if (node.roles.length > 0) {
      var ic = new createjs.Shape();
      ix += 30;
      ic.graphics
        .beginStroke(0)
        .beginBitmapFill(queue.getResult("role"), "no-repeat")
        .drawRect(0, 0, 25, 25);
      ic.x += ix;
      ic.y += 22;
      cont.addChild(ic);
    }

    if (node.interfaces.length > 0) {
      var ic = new createjs.Shape();
      ix += 30;
      ic.graphics
        .beginStroke(0)
        .beginBitmapFill(queue.getResult("interface"), "no-repeat")
        .drawRect(0, 0, 25, 25);
      ic.x += ix;
      ic.y += 22;
      cont.addChild(ic);
    }

    if (node.form.length > 0) {
      if (node.form[0].type == "CF") {
        var ic = new createjs.Shape();
        ix += 30;
        ic.graphics
          .beginStroke(0)
          .beginBitmapFill(queue.getResult("custform"), "no-repeat")
          .drawRect(0, 0, 25, 25);
        ic.x += ix;
        ic.y += 22;
        cont.addChild(ic);
      }
    }

    cont.x = x - 15;
    cont.y = y - 35;
    cont.id = node.id + "_" + rid;

    if (node.id != "0" && node.type != "finish" && node.typeId != "99") {
      if (node.instruction !== "") {
        cont.name = node.instruction;
      } else {
        cont.name = node.label;
      }
      //console.log(node);
      cont.on("mouseover", this.handleMouseEvent);
      cont.on("mouseout", this.handleMouseEvent);
    }

    this.stage.addChild(cont);
    this.stage.update();
  }

  handleMouseEvent = (evt) => {
    //console.log(evt.currentTarget.name);
    const { tolltip, container, out } = this.state;
    //console.log(tolltip);
    //console.log(container);
    //console.log(out);

    var lines = evt.currentTarget.name.split(/\n/);
    //console.log(lines);
    var width = lines[0].length * 10;
    var height = lines.length > 1 ? 35 + 15 * lines.length : 35;

    if (evt.type == "mouseover") {
      tolltip.graphics
        .beginStroke(0)
        .beginFill("#000")
        .drawRect(0, 0, width, height);
      container.x = evt.stageX;
      container.y = evt.stageY;
      //console.log(evt);

      out.text = evt.currentTarget.name;
      out.color = "white";

      out.textAlign = "center";
      out.textBaseline = "middle";
      out.lineHeight = 18;
      out.x = tolltip.x + lines[0].length * 5;
      out.y = tolltip.y + 20;

      container.addChild(tolltip);
      container.addChild(out);
      this.stage.addChild(container);
    } else {
      //tolltip = new createjs.Shape();
      container.removeAllChildren();
      this.stage.removeChild(container);
    }

    this.stage.update();
  };

  createLine2(sx, sy, ex, ey, fill, cid, pid, cond, tobj) {
    var pNode;
    var cNode;

    for (var item in tobj) {
      var node = tobj[item];
      if (node.id == pid) {
        cNode = node;
      }
      if (node.id == cid) {
        pNode = node;
      }
    }

    if (pNode.id != "0") {
      var py = parseFloat(pNode.ytop);
      var cy = parseFloat(cNode.ytop);

      var px = parseFloat(pNode.xleft);
      var cx = parseFloat(cNode.xleft);

      if (py > cy + 30) {
        sx = parseInt(sx) + 20;
        sy = parseInt(sy) - 25;
        ex = parseInt(ex);
        ey = parseInt(ey) + 10;

        if (!(px > cx + 30 || px < cx - 30)) {
          sx = sx - 20;
          sy = sy - 13;
        } else if (px < cx - 30) {
          ey = ey - 10;
          sy = sy + 2;
        }
      } else if (py < cy - 30) {
        sx = parseInt(sx);
        sy = parseInt(sy);
        ex = parseInt(ex);
        ey = parseInt(ey) - 40;
      } else {
        sx = parseInt(sx);
        sy = parseInt(sy);
        ex = parseInt(ex) + 20;
        ey = parseInt(ey) - 15;
      }

      if (px > cx + 30) {
        sx = sx - 15;
        sy = sy - 13;
        ex = ex + 10;
      } else if (px < cx - 30) {
        sx = sx + 25;
        sy = sy - 13;
        ey = ey + 8;

        if (!(py < cy - 30 || py > cy + 30)) {
          ex = ex - 40;
          ey = ey - 10;
        } else if (py > cy + 30) {
          sx = sx - 35;
          sy = sy;
        } else {
          ey = ey - 7;
        }
      } else {
        sx = sx + 5;
        sy = sy + 5;
        ex = ex + 5;
      }
    } else {
      sx = parseInt(sx) + 5;
      sy = parseInt(sy) + 5;
      ex = parseInt(ex) + 5;
      ey = parseInt(ey) - 40;
    }

    var x = new createjs.Shape();
    x.graphics
      .setStrokeStyle(2)
      .beginStroke(fill)
      .moveTo(sx, sy)
      .lineTo(ex, ey);
    x.id = pid + "_" + cid;
    x.name = "line";
    this.stage.addChild(x);
    this.stage.update();

    var ang = this.angle360(sx, sy, ex, ey);

    var size = 6;
    var sides = 3;
    var angle = ang;

    var polygon = new createjs.Shape();
    polygon.graphics
      .beginFill(fill)
      .drawPolyStar(parseInt(ex), parseInt(ey), size, sides, 0, angle);
    this.stage.addChild(polygon);
    this.stage.update();

    var mx = (sx + ex) / 2;
    var my = (sy + ey) / 2;

    mx = (sx + mx) / 2;
    my = (sy + my) / 2;

    var tx;
    var ty;
    if (cond == "True") {
      tx = mx + 5;
      ty = my + 5;
    } else {
      tx = mx + 10;
      ty = my + 5;
    }

    if (cond == "True" || cond == "False") {
      var txt = new createjs.Text();
      txt.text = cond;
      txt.color = "black";
      txt.textAlign = "left";
      txt.textBaseline = "top";
      txt.font = '12px "Segoe UI Semilight"';
      txt.x = tx; //(mx + 5);
      txt.y = ty; //(my + 5);
      this.stage.addChild(txt);
      this.stage.update();
    }
  }

  createLine3(sx, sy, ex, ey, fill, cid, pid, cond, tobj) {
    var pNode;
    var cNode;

    for (var item in tobj) {
      var node = tobj[item];
      if (node.id == cid) {
        cNode = node;
      }
      if (node.id == pid) {
        pNode = node;
      }
    }

    var x = new createjs.Shape();

    if (pNode.id != "0") {
      var py = parseFloat(pNode.ytop);
      var cy = parseFloat(cNode.ytop);

      var px = parseFloat(pNode.xleft);
      var cx = parseFloat(cNode.xleft);

      if (py > cy + 30) {
        sx = parseInt(sx) + 20;
        sy = parseInt(sy) - 25;
        ex = parseInt(ex);
        ey = parseInt(ey) + 10;

        if (!(px > cx + 30 || px < cx - 30)) {
          sx = sx - 20;
          sy = sy - 13;
        } else if (px < cx - 30) {
          ey = ey - 10;
          sy = sy + 2;
        }
      } else if (py < cy - 30) {
        sx = parseInt(sx);
        sy = parseInt(sy);
        ex = parseInt(ex);
        ey = parseInt(ey) - 40;
      } else {
        sx = parseInt(sx);
        sy = parseInt(sy);
        ex = parseInt(ex) + 20;
        ey = parseInt(ey) - 15;
      }

      if (px > cx + 30) {
        sx = sx - 15;
        sy = sy - 13;
        ex = ex + 10;
      } else if (px < cx - 30) {
        sx = sx + 25;
        sy = sy - 13;
        ey = ey + 8;

        if (!(py < cy - 30 || py > cy + 30)) {
          ex = ex - 40;
          ey = ey - 10;
        } else if (py > cy + 30) {
          sx = sx - 35;
          sy = sy;
        } else {
          ey = ey - 7;
        }
      } else {
        sx = sx + 5;
        sy = sy + 5;
        ex = ex + 5;
      }
    } else {
      sx = parseInt(sx) + 5;
      sy = parseInt(sy) + 5;
      ex = parseInt(ex) + 5;
      ey = parseInt(ey) - 40;
    }

    x.graphics
      .setStrokeStyle(2)
      .beginStroke(fill)
      .moveTo(sx, sy)
      .lineTo(ex, ey);
    x.id = pid + "_" + cid;
    x.name = "line";
    this.stage.addChild(x);
    this.stage.update();

    var ang = this.angle360(sx, sy, ex, ey);

    var size = 6;
    var sides = 3;
    var angle = ang;

    var polygon = new createjs.Shape();
    polygon.graphics
      .beginFill(fill)
      .drawPolyStar(parseInt(ex), parseInt(ey), size, sides, 0, angle);
    this.stage.addChild(polygon);
    this.stage.update();

    var mx = (sx + ex) / 2;
    var my = (sy + ey) / 2;

    mx = (sx + mx) / 2;
    my = (sy + my) / 2;

    var tx;
    var ty;
    if (cond == "True") {
      tx = mx + 5;
      ty = my + 5;
    } else {
      tx = mx + 10;
      ty = my + 5;
    }

    if (cond == "True" || cond == "False") {
      var txt = new createjs.Text();
      txt.text = cond;
      txt.color = "black";
      txt.textAlign = "left";
      txt.textBaseline = "top";
      txt.font = '12px "Segoe UI Semilight"';
      txt.x = tx; //(mx + 5);
      txt.y = ty; //(my + 5);
      this.stage.addChild(txt);
      this.stage.update();
    }
  }

  angle360(cx, cy, ex, ey) {
    var dy = ey - cy;
    var dx = ex - cx;
    var theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    return theta;
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ position: "absolute" }}>
          <img src="/assets/Images/WF/traversed.png"></img>{" "}
          <span className="font-weight-bold text-green">Path Traversed </span>
          <br></br>
          <br></br>
          <img src="/assets/Images/WF/nottraversed.png"></img>{" "}
          <span className="font-weight-bold text-red">Path Not Traversed </span>
        </div>
        <canvas
          ref="canvas"
          id="demoCanvas"
          height="2000"
          width="1000"
        ></canvas>
      </React.Fragment>
    );
  }
}

export default WorkflowDesign;
