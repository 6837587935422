import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const AI_SEARCH_BASE_URL="https://wf-search-index-api.azurewebsites.net";

const slice = createSlice({
    name: "wordProccessing",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        htmlToPdfConvert: null,
        docToDocxConvert: null,
        htmlToDocxConvert: null,
        addedStatus: false,
        lockedStatus: false,
        versionList:[],
        searchDocList:[]
    },
    reducers: {
        wordTemplateReceived: (wordProccessing, action) => {
            wordProccessing.list = action.payload;
            wordProccessing.addedStatus=false;
            wordProccessing.lockedStatus=false;
        },
        htmlToPdfConvertedReceived: (wordProccessing, action) => {
            wordProccessing.htmlToPdfConvert = action.payload;
        },
        docToDocxConvertedReceived: (wordProccessing, action) => {
            wordProccessing.docToDocxConvert = action.payload;
        },
        htmlToDocxConvertedReceived: (wordProccessing, action) => {
            wordProccessing.htmlToDocxConvert = action.payload;
        },
        wordTemplateAdded: (wordProccessing, action) => {
            wordProccessing.addedStatus = action.payload;
        },
        wordTemplateLocked: (wordProccessing, action) => {
            wordProccessing.lockedStatus = action.payload;
        },
        versionTemplateReceived: (wordProccessing, action) => {
            wordProccessing.versionList = action.payload;
        },
        searchDocListReceived: (wordProccessing, action) => {
            wordProccessing.searchDocList = action.payload;
        },
    }
});

export const { wordTemplateReceived, htmlToPdfConvertedReceived, docToDocxConvertedReceived,
    htmlToDocxConvertedReceived, wordTemplateAdded,wordTemplateLocked,
    versionTemplateReceived,searchDocListReceived } = slice.actions;
export default slice.reducer;

export const loadWordTemplates = (id) => apiCallBegan({
    url: config.GetWordTemplates + id,
    onSuccess: wordTemplateReceived.type
});

export const loadVersionWordTemplates = (id) => apiCallBegan({
    url: config.GetWordVersionTemplates + id,
    onSuccess: versionTemplateReceived.type
});

export const SetWordTemplateLocked = (id) => apiCallBegan({
    url: config.SetWordDocumentLocked + id,
    onSuccess: wordTemplateLocked.type
});

export const loadDocumentSearch = (data) => apiCallBegan({
    baseURL: AI_SEARCH_BASE_URL,
    url: config.DocumentAiSearch,
    method:"post",
    data: data,
    onSuccess: searchDocListReceived.type
});

export const addWordTemplate = (data) => apiCallBegan({
    url: config.AddWordTemplate,
    method: "post",
    data: data,
    onSuccess: wordTemplateAdded.type
});

export const convertHtmlToPdf = (data) => apiCallBegan({
    url: config.PrintTemplate,
    method: "post",
    data: data,
    responseType: "blob",
    onSuccess: htmlToPdfConvertedReceived.type
});

export const convertDocToDocx = (data) => apiCallBegan({
    url: config.ConvertDocToDocx,
    method: "post",
    data: data,
    responseType: "arraybuffer",
    onSuccess: docToDocxConvertedReceived.type
});

export const convertHtmlToDocx = (data) => apiCallBegan({
    url: config.ConvertHtmlToDocx,
    method: "post",
    data: data,
    responseType: "blob",
    onSuccess: htmlToDocxConvertedReceived.type
});

export const getHtmlToPdf = createSelector(
    state => state.entities.wordProccessing,
    wordProccessing => wordProccessing.htmlToPdfConvert
);

export const getDocToDocx = createSelector(
    state => state.entities.wordProccessing,
    wordProccessing => wordProccessing.docToDocxConvert
);

export const getHtmlToDocx = createSelector(
    state => state.entities.wordProccessing,
    wordProccessing => wordProccessing.htmlToDocxConvert
);

export const getWordTemplates = createSelector(
    state => state.entities.wordProccessing,
    wordProccessing => wordProccessing.list
);

export const getWordTempAddedStatus = createSelector(
    state => state.entities.wordProccessing,
    wordProccessing => wordProccessing.addedStatus
);

export const getWordTempLockedStatus = createSelector(
    state => state.entities.wordProccessing,
    wordProccessing => wordProccessing.lockedStatus
);

export const getWordVersionTemplates = createSelector(
    state => state.entities.wordProccessing,
    wordProccessing => wordProccessing.versionList
);

export const getSearchDocuments = createSelector(
    state => state.entities.wordProccessing,
    wordProccessing => wordProccessing.searchDocList
);

