import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "complianceReports",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        subcontractors:[],
        recruiters:[],
        w2Candidates:[],
        tenNintyCandidates:[]
    },
    reducers: {
        subcontractorsComplicanceReceived: (complianceReports, action) => {
            complianceReports.subcontractors = action.payload;
        },
        recruitersComplicanceReceived: (complianceReports, action) => {
            complianceReports.recruiters = action.payload;
        },
        w2CandidatesComplicanceReceived: (complianceReports, action) => {
            complianceReports.w2Candidates = action.payload;
        },
        tenNintyCandidatesComplicanceReceived: (complianceReports, action) => {
            complianceReports.tenNintyCandidates = action.payload;
        },
    }
});

export const { subcontractorsComplicanceReceived,recruitersComplicanceReceived,
    w2CandidatesComplicanceReceived,tenNintyCandidatesComplicanceReceived } = slice.actions;
export default slice.reducer;

export const loadSubcontractorsComplianceReports = (year) => apiCallBegan({
    url: config.GetSubcontractorCompliance + year,
    onSuccess: subcontractorsComplicanceReceived.type
});

export const loadRecruitersComplianceReports = (year) => apiCallBegan({
    url: config.GetRecruiterCompliance + year,
    onSuccess: recruitersComplicanceReceived.type
});

export const loadW2CandidatesComplianceReports = (year) => apiCallBegan({
    url: config.GetCandidateCompliance + year+'&entityType=W2 Basis',
    onSuccess: w2CandidatesComplicanceReceived.type
});

export const loadTenNintyComplianceReports = (year) => apiCallBegan({
    url: config.GetCandidateCompliance + year+'&entityType=1099 Basis',
    onSuccess: tenNintyCandidatesComplicanceReceived.type
});

export const getSubcontractorsComplianceReports = createSelector(
    state => state.entities.complianceReports,
    complianceReports => complianceReports.subcontractors
);

export const getRecruitersComplianceReports = createSelector(
    state => state.entities.complianceReports,
    complianceReports => complianceReports.recruiters
);

export const getW2CandidateComplianceReports = createSelector(
    state => state.entities.complianceReports,
    complianceReports => complianceReports.w2Candidates
);

export const getTenNintyComplianceReports = createSelector(
    state => state.entities.complianceReports,
    complianceReports => complianceReports.tenNintyCandidates
);



