import React, { Component, useEffect, useState } from "react";
import Form from "../../../comman/form";
import http from "../../../services/HttpService";
import config from "../../../config.json";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import EditPartner from "../../SystemAdministration/ManagePartner/EditPartner";
import { getRecruiterPartners, loadRecruiterPartners } from "../../../app/store/partner";
import { getRateUpdatedStatus, ratesReceived, updateRate } from "../../../app/store/rates";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationData } from "../../../app/store/application";
import { updateNewCandidate } from "../../../app/store/candidate";
import { getAlert } from "../../../services/appAlerts";

const Recruiter = (props) => {

  const { candidateObj, actType, handleNextTab, updateSelectedObject } = props;

  const [recruiters, setRecruiters] = useState([]);
  const [recruiterId, setRecruiterId] = useState(0);
  const [recruiterRate, setRecruiterRate] = useState(0);
  const [partnerType, setPartnerType] = useState("Recruiter");
  const [aworkfinityApp, setAworkfinityApp] = useState(null);

  const recruiterPartnersSelector = useSelector(getRecruiterPartners);
  const rateUpdatedStatusSelector = useSelector(getRateUpdatedStatus);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadRecruiterPartners(partnerType))
  }, []);

  useEffect(() => {
    if (recruiterPartnersSelector.length > 0) {
      const recList = recruiterPartnersSelector.filter((x) => x.createdBy !== "");
      setRecruiters(recList);
    }
  }, [recruiterPartnersSelector]);

  useEffect(() => {

    if(!candidateObj){
      setRecruiterId(0);
      setRecruiterRate(0)
    }

    if (candidateObj?.recruiter)
      setRecruiterId(candidateObj.recruiter.fuid);

    if (candidateObj?.rates)
      setRecruiterRate(candidateObj.rates.recruiterRate ?? 0);

    if (candidateObj?.performerData) {
      const obj = getApplicationData(candidateObj.performerData);
      if (obj?.aWorkfinityApp)
        setAworkfinityApp(obj.aWorkfinityApp);
    }

  }, [candidateObj]);



  const handleSubmit = (btnType) => {
    const NewAWorkfinityApp = { ...aworkfinityApp };
    const appName = Object.keys(NewAWorkfinityApp)[1];
    const aWorkfinityAppEntity = NewAWorkfinityApp[appName];

    if (recruiterId > 0) {
      const subs = recruiters.filter(x => x.fuid === recruiterId);
      if (subs.length > 0) {
        const subDetails = JSON.parse(subs[0].createdBy);
        const appEntity = subDetails[appName];
        aWorkfinityAppEntity["recruiterenrollmentform"] = appEntity["recruiterenrollmentform"];
        NewAWorkfinityApp[appName] = aWorkfinityAppEntity;

        const formData = new FormData();
        formData.append("appEntity", JSON.stringify(NewAWorkfinityApp));
        formData.append("subcontractor", "");
        formData.append("recruiter", recruiterId);
        formData.append("perfId", candidateObj.performerid);
        dispatch(updateNewCandidate(formData));
        handleSaveRates();

        // if (actType === "edit"){
        //   getAlert("", "Updated Successfully", "success");
        // }
        // else{
        //   getAlert("", "Saved Successfully", "success");
        // } 
        //updateSelectedObject(candidateObj.performerid);
        dispatch(ratesReceived([]));
        handleNextTab(btnType);

      }
    }

  };

  const handleSaveRates = () => {
    const frmData = new FormData();
    frmData.append("id", candidateObj.performerid);
    frmData.append("type", "recruiter");
    frmData.append("recRate", recruiterRate);
    dispatch(updateRate(frmData));
  };

  // useEffect(() => {
  //   if (rateUpdatedStatusSelector) {
  //     if (actType === "edit"){
  //       getAlert("", "Updated Successfully", "success");
  //     }
  //     else{
  //       getAlert("", "Saved Successfully", "success");
  //     } 
  //     updateSelectedObject(candidateObj.performerid);
  //     dispatch(ratesReceived([]));
  //     handleNextTab();
  //   }
  // }, [rateUpdatedStatusSelector]);

  const btnDisabled=recruiterId > 0 && recruiterRate > 0;

  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-12 form-group">
          <p className="text-red">
            <i className="fa fa-hand-o-right mr-2" aria-hidden="true"></i>
            If existing recruiter, Select recruiter and enter the rate to be
            paid
          </p>
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="recruiter" className="form-control-label">
            Select Recruiter
          </label>
          <select
            name="recruiter"
            id="recruiter"
            className="form-control"
            onChange={({ currentTarget }) => setRecruiterId(parseInt(currentTarget.value))}
            value={recruiterId}
          >
            <option value="">Select</option>
            {recruiters.map((item) => (
              <option value={item.fuid}>{item.label}</option>
            ))}
          </select>
        </div>

        <div className="col-md-6 form-group">
          <label htmlFor="subcontractor" className="form-control-label">
            Rate/Hour-Paid to Recruiter $
          </label>
          <input
            className="form-control"
            type="number"
            name="rate"
            id="rate"
            value={recruiterRate}
            onChange={({ currentTarget }) => setRecruiterRate(currentTarget.value)}
          />
        </div>
        <div className="col-md-12 text-center form-group">
          <button
            className="btn btn-sm btn-outline-default"
            disabled={!btnDisabled}
            onClick={()=>handleSubmit("save")}
          >
            <i className="fa fa-check" aria-hidden="true"></i> Save
          </button>
          <button
            className="btn btn-sm btn-outline-default"
            disabled={!btnDisabled}
            onClick={()=>handleSubmit("saveAndContinue")}
          >
            <i className="fa fa-check" aria-hidden="true"></i> Save and Continue
          </button>
        </div>
      </div>

    </div>
  );
}

export default Recruiter;
