import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

class AppointmentPopup extends Component {
    render() {
        const { appointmentgShowHide, handleAppointmentModalShowHide, selectedObj } = this.props;
        return (
          <Modal show={appointmentgShowHide} size="lg">
            <Modal.Header closeButton onClick={handleAppointmentModalShowHide}>
              <Modal.Title>Patient Appointment Scheduling - View</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-secondary">
              <div className="row">
                <div className="table-responsive">
                  <table className="table align-items-center table-flush table-hover table-striped">
                    <thead className="thead-light">
                      <tr>
                        <th>Appointment <br/> Date & Time</th>
                        <th>Dispencing Center<br/>
                        /Pharmacist
                        </th>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button variant="danger" onClick={handleAppointmentModalShowHide}>
                <i className="fa fa-times" aria-hidden="true"></i> Close
              </Button>
            </Modal.Footer>
          </Modal>
        );
    }
}

export default AppointmentPopup;