import React, { Component } from "react";
import { Link } from "react-router-dom";

class AgencyManagement extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-xl-3">
          <div className="card bg-gradient-danger">
            <div className="card-body">
              <div className="mb-2">
                <span className="h2 text-white">Agencies</span>
                <div className="text-white mt-2 text-sm">
                  Total
                  <span className="text-white font-weight-bold">(10)</span>
                </div>
              </div>
            </div>
            <div className="card-body pt-0 pb-0">
              <Link
                to="/WorkflowPerformer"
                className="btn btn-sm btn-block btn-neutral"
              >
                View
              </Link>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <small className="text-white">Active:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-success"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <small className="text-white">Inactive:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-warning"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="card bg-gradient-success">
            <div className="card-body">
              <div className="mb-2">
                <span className="h2 text-white">Agency Performer</span>
                <div className="text-white mt-2 text-sm">
                  Total
                  <span className="text-white font-weight-bold">(20)</span>
                </div>
              </div>
            </div>
            <div className="card-body pt-0 pb-0">
              <Link
                to="/PartnerPerformer"
                className="btn btn-sm btn-block btn-neutral"
              >
                View
              </Link>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <small className="text-white">Active:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-default"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <small className="text-white">Inactive:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-warning"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="card">
            <div className="card-header p-3">
              <span className="h2"></span>
            </div>
            <div className="card-body p-3"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgencyManagement;
