import React, { Component, useEffect, useRef, useState, useSyncExternalStore } from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "../../../comman/form";
import http from "../../../services/HttpService";
import auth from "../../../services/authService";
import config from "../../../config.json";
import Swal from "sweetalert2";
import { getAlert } from "../../../services/appAlerts";
import useControls from "../../../app/hooks/useControls";
import useForm from "../../../app/hooks/useForm";
import { getCurrentUser } from "../../../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { SendEmailMessage, addNewEmail, addNewEmailMessage, emailMessageAdded, emailMessageSent, getEmailAddedStatus, getEmailUpdatedStatus, getSentEmailStatus, updateEmailMessage } from "../../../app/store/users";

const SendEmail = (props) => {

  const { showHide, handleModalShowHide, selectedEmail,
    selectedObj, viewType, selectedMailObj, handleUpdateModalShowHide,msgType } = props;

  const [saveBtnType, setSaveBtnType] = useState("");
  const [saveBtnProcess, setSaveBtnProcess] = useState(false);
  const [draftBtnProcess, setDraftBtnProcess] = useState(false);
  const [documents, setDocuments] = useState([]);

  const emailAddedStatusSelector = useSelector(getEmailAddedStatus);
  const sentEmailStatusSelector = useSelector(getSentEmailStatus);
  const emailUpdatedStatusSelector = useSelector(getEmailUpdatedStatus);

  const currentUser = getCurrentUser();

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  useEffect(() => {
    const dt = {};
    if (selectedObj) {
      //dt["emailFrom"] = "anand@ipcs.net";
      dt["emailTo"] = selectedObj.performeremail;
      dt["performerId"] = selectedObj.performerid;
      dt["ciid"] = selectedObj.ciid;
      dt["wiid"] = selectedObj.wiid;
      dt["iiid"] = selectedObj.iiid;
      dt["emails"] = [{ key: selectedObj.performerid, value: selectedObj.performeremail }];
    }

    if (selectedEmail?.length > 0) {
      dt["emailTo"] = selectedEmail.map((x) => x.value).join(", ");
      dt["emails"] = selectedEmail;
    }

    dt["subject"] = "";
    dt["mailBody"] = "";
    dt["addedBy"] = currentUser.id;

    setStateData(dt);

  }, [selectedObj]);

  useEffect(() => {
    const dt = {};
    if (selectedMailObj) {
      dt["emailTo"] = selectedMailObj.emailTo;
      dt["id"] = selectedMailObj.id;
      dt["subject"] = selectedMailObj.subject;
      dt["mailBody"] = selectedMailObj.mailBody;
    }
    setStateData(dt);
  }, [selectedMailObj]);


  const schema = {};

  useEffect(() => {
    if (emailAddedStatusSelector) {
      setSaveBtnProcess(false);
      setDraftBtnProcess(false);
      if (saveBtnType === "send") {
        getAlert("Sent Successfully", "", "success");
      }
      if (saveBtnType === "draft") {
        getAlert("Saved Successfully", "", "success");
      }
      handleModalShowHide("save");
      dispatch(emailMessageAdded(false));
    }
  }, [emailAddedStatusSelector]);

  useEffect(() => {
    if (emailUpdatedStatusSelector) {
      getAlert("Updated Successfully", "", "success");
      setSaveBtnProcess(false);
      setDraftBtnProcess(false);
      if (handleUpdateModalShowHide)
        handleUpdateModalShowHide();
    }
  }, [emailUpdatedStatusSelector])

  // const doSubmit = (btnType) => {
  //   setSaveBtnType(btnType);

  //   if (btnType === "send")
  //     setSaveBtnProcess(true);

  //   if (btnType === "draft")
  //     setDraftBtnProcess(true);

  //   if (btnType === "Update") {
  //     dispatch(updateEmailMessage(stateData));
  //   }
  

  // }

  const doSubmit = (btnType) => {
    setSaveBtnType(btnType);
  
    const data = new FormData();
  
    // Append email content
    data.append("emailTo", stateData.emailTo);
    data.append("subject", stateData.subject);
    data.append("mailBody", stateData.mailBody);
    data.append("addedBy", stateData.addedBy);
  
    // Append files (separately indexed)
    documents.forEach((file, key) => {
      data.append(`files[${key}]`, file.file);
    });
  
    if (btnType === "send") {
      setSaveBtnProcess(true);
      data.append("sendStatus", true); // Indicating that it's a send operation
    }
  
    if (btnType === "draft") {
      setDraftBtnProcess(true);
    }
  
    if (btnType === "Update") {
      dispatch(updateEmailMessage(data));
    } else {
      // Regular email send
      dispatch(addNewEmailMessage(data));
    }
  };
  

  const onFileChange = async (event) => {
    const docs = [...documents];
    for (const file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        size: (file.size / 1024).toFixed(2),
        file: file,
      };
      docs.push(doc);
    }
  
    setDocuments(docs);
    setStateData((prevState) => ({
      ...prevState,
      documents: docs
    }));
  };

  const handleFileRemove = (item) => {
    const updatedDocuments = documents.filter((doc) => doc !== item);
    setDocuments(updatedDocuments);
  };

  const { validate, handleChange, stateData, setStateData,
    stateErrors, setStateErrors, handleSubmit } = useForm(schema, doSubmit);

  const { renderInput, renderTextArea } = useControls(stateData, stateErrors, handleChange, schema);

   console.log(stateData, "stated")
  return (
    <Modal show={showHide} size="xl">
      <Modal.Header closeButton onHide={() => handleModalShowHide("")}>
        <Modal.Title>
          {selectedEmail?.length > 0
            ? "Send Bulk Email"
            : "Send Email"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        <div className="row">
          {/* <div className="col-md-12">
            {renderInput("emailFrom", "From Email")}
          </div> */}
          <div className="col-md-12">
            {selectedEmail?.length > 0
              ? renderTextArea(
                "emailTo",
                `To Email (Total Count: "${selectedEmail.length})`
              )
              : renderInput("emailTo", "To Email")}
          </div>

          <div className="col-md-12">
            {renderInput("subject", "Subject")}
          </div>
          <div className="col-md-12">
            {renderTextArea("mailBody", "Message Body")}
          </div>
          <div className="col-md-12">
            {/* Add Button */}
            <Button
              variant="outline-dark"
              size="sm"
              onClick={() => {
                fileInputRef.current.click(); 
              }}
            >
              <i className="fa fa-paperclip mr-2" aria-hidden="true"></i>
              Add
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={onFileChange}
              multiple
              style={{ position: "absolute", top: "-9999px" }} 
            />

            {documents.length > 0 && (
              <div className="file-preview" style={{marginLeft:" 0px !important"}}>
                {documents.map((file, index) => (
                  <div key={index} className="file-preview-item">
                    <span>{file.name}</span>
                    <span
                      className="file-size"
                      style={{
                        backgroundColor: "#f0f0f0",
                        marginLeft: "10px",
                        padding: "2px 5px",
                        borderRadius: "5px",
                      }}
                    >
                      {file.size} KB
                    </span>
                    <button
                      className="btn btn-sm btn-link"
                      onClick={() => handleFileRemove(file)}
                    >
                      <i className="fa fa-times text-red" aria-hidden="true"></i>
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-danger" onClick={() => handleModalShowHide("")}>
          <i class="fa fa-times" aria-hidden="true"></i> Close
        </button>

        {viewType === "edit" &&
          <button className="btn btn-sm btn-outline-default" disabled={saveBtnProcess} onClick={() => handleSubmit("Update")}>
            {saveBtnProcess ? <i class="fa fa-circle-o-notch fa-spin"></i> : <i class="fa fa-pencil" aria-hidden="true"></i>} Update
          </button>
        }
        {viewType === "add" && <>
          <button className="btn btn-sm btn-outline-default" disabled={draftBtnProcess} onClick={() => handleSubmit("draft")}>
            {draftBtnProcess ? <i class="fa fa-circle-o-notch fa-spin"></i> : <i class="fa fa-check" aria-hidden="true"></i>} Save as Draft
          </button>
          <button className="btn btn-sm btn-outline-default" disabled={saveBtnProcess} onClick={() => handleSubmit("send")}>
            {saveBtnProcess ? <i class="fa fa-circle-o-notch fa-spin"></i> : <i class="fa fa-paper-plane" aria-hidden="true"></i>} Send
          </button> </>
        }
      </Modal.Footer>
    </Modal>
  );
}

export default SendEmail;
