import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import Swal from "sweetalert2";
import "react-big-calendar/lib/css/react-big-calendar.css";

class AddSechduleTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      selectedDate: new Date(),
      selectedView: "month",
      selectedDay: new Date(),
      descShowHide: false,
      descText: "",
    };
  }

  handleSelectDate = (obj) => {
    this.setState({ selectedDate: obj.start, selectedDay: obj.start });
  };

  handleTimeSlot = (obj) => {
    const { events, descText } = this.state;
    //console.log(events);
    //console.log(obj.start);
    const newEvents = [];
    events.map((x, key) => {
      const str = new Date(x.start);
      const str1 = new Date(obj.start);
      const end = new Date(x.end);
      const end1 = new Date(obj.end);
      x.optionId = key + 1;
      if (str.getHours() !== str1.getHours()) {
        newEvents.push(x);
        return;
      } else if (str.getMinutes() !== str1.getMinutes()) {
        newEvents.push(x);
        return;
      } else if (end.getHours() !== end1.getHours()) {
        newEvents.push(x);
        return;
      } else if (end.getMinutes() !== end1.getMinutes()) {
        newEvents.push(x);
        return;
      }
    });
    //console.log(newEvents);
    let lastId = 1;
    if (newEvents.length > 0)
      lastId = newEvents[newEvents.length - 1].optionId + 1;
    newEvents.push({
      optionId: lastId,
      start: obj.start,
      end: obj.end,
      title: "Meeting",
      slots: obj.slots,
    });

    this.setState({ events: newEvents });
  };

  handleDayChanged = (obj) => {
    this.setState({ selectedDay: obj.start });
  };

  handleDateChanged = (obj) => {
    this.setState({ selectedDate: obj.start });
  };

  eventStyleGetter = (obj) => {
    var style = {
      backgroundColor: "red",
      borderRadius: "0px",
      opacity: 0.8,
      color: "black",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  handleSelectEvent = (obj) => {
    var style = {
      backgroundColor: "red",
      borderRadius: "0px",
      opacity: 0.8,
      color: "black",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  doSubmit = () => {
    const { events } = this.state;
    this.props.handleSubmitSchedule(events);
  };

  componentWillReceiveProps() {
    this.setState({ events: [] });
  }

  render() {
    const {
      events,
      selectedDate,
      selectedView,
      selectedDay,
      descShowHide,
      descText,
    } = this.state;
    const { secShowHide, handleSecShowHide } = this.props;
    const localizer = momentLocalizer(moment);
    return (
      <>
        <Modal show={secShowHide} size="lg">
          <Modal.Header closeButton onHide={() => handleSecShowHide()}>
            <Modal.Title>Add Date & Time</Modal.Title>
          </Modal.Header>
          <Modal.Body className="border">
            <div className="row">
              <div className="col-md-6" style={{ height: "350px" }}>
                <Calendar
                  selectable
                  localizer={localizer}
                  events={events}
                  defaultView={Views.MONTH}
                  date={selectedDate}
                  views={["month"]}
                  onSelectSlot={this.handleSelectDate}
                  onNavigate={this.handleDateChanged}
                  dayPropGetter={(date) =>
                    moment(date).format("DD") ===
                      moment(selectedDate).format("DD") && {
                      className: "bg-yellow",
                    }
                  }
                />
              </div>
              <div className="col-md-6" style={{ height: "350px" }}>
                <Calendar
                  selectable
                  localizer={localizer}
                  events={events}
                  defaultView={Views.DAY}
                  date={selectedDay}
                  views={["day"]}
                  onSelectSlot={this.handleTimeSlot}
                  onNavigate={this.handleDayChanged}
                  //onView={(view) => this.setState({ selectedView: view })}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline-danger" onClick={() => handleSecShowHide()}>
            <i className="fa fa-times" aria-hidden="true"></i> Close
            </button>
            <button className="btn btn-sm btn-outline-default" onClick={this.doSubmit}>
            <i className="fa fa-check" aria-hidden="true"></i> Save
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddSechduleTime;
