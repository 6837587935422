import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class MenuItem extends Component {
  render() {
    const {
      menuId,
      menuTitle,
      imageName,
      subMenus,
      handleClick,
      isSelected,
      headingVisible,
    } = this.props;
    return (
      <li className="nav-item">
        {headingVisible && (
          <a
            className={`nav-link${isSelected ? " active" : " "}`}
            href={`navbar-${menuId}`}
            data-toggle="collapse"
            role="button"
            id={menuId}
            aria-expanded="false"
            aria-controls={`navbar-${menuId}`}
            onClick={handleClick}
          >
            {/* <i className={`${imageName}`} aria-hidden="true"></i> */}
            {/* <i className="fa fa-cubes" aria-hidden="true"></i> */}
            <span className="nav-link-text">{menuTitle}</span>
          </a>
        )}
        <div className="collapse show" id={`navbar-${menuId}`}>
          <ul className="nav nav-sm flex-column">
            {subMenus
              .filter((x) => x.isChecked === true)
              .map((item) => (
                <li className="nav-item hover-effect">
                  <NavLink
                    to={item.url}
                    className="nav-link"
                    style={{ paddingLeft: "1.5rem" }}
                  >
                    <i
                      className={`fa fa-angle-double-right`}
                      aria-hidden="true"
                      style={{ minWidth: "1rem" }}
                    ></i>
                    {item.name}
                  </NavLink>
                </li>
              ))}
          </ul>
        </div>
      </li>
    );
  }
}

export default MenuItem;
