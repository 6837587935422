import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "reports",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        loginTrails:null,
        filterLoginTrails:null,
        monthlyAchSubcontractor:[],
        monthlyAchRecruiter:[],
        monthlyClientInvoices:[],
        monthlyClientCandidateInvoices:[],
        monthlyPaySubcontractorCandidates:[],
        monthlyPayRecruiterCandidates:[],
        monthlyInvoiceAgencyCandidates:[],
        monthlyGrossProfitCandidates:[],
        monthlyBurnHourCandidates:[],
        quartlyBurnHourCandidates:[],
        quartlyClientInvoices:[],
        dataTypeApplicationReports:[]
    },
    reducers: {
        monthlyAchSubcontractorReceived: (reports, action) => {
            reports.monthlyAchSubcontractor=action.payload;
        },
        loginTrailsReceived: (reports, action) => {
            reports.loginTrails=action.payload;
        },
        filterLoginTrailsReceived: (reports, action) => {
            reports.filterLoginTrails=action.payload;
        },
        monthlyAchRecruiterReceived: (reports, action) => {
            reports.monthlyAchRecruiter=action.payload;
        },
        monthlyClientInvoicesReceived: (reports, action) => {
            reports.monthlyClientInvoices=action.payload;
        },
        quartlyClientInvoicesReceived: (reports, action) => {
            reports.quartlyClientInvoices=action.payload;
        },
        monthlyClientCandidateInvoicesReceived: (reports, action) => {
            reports.monthlyClientCandidateInvoices=action.payload;
        },
        monthlyPaySubcontractorCandidatesReceived: (reports, action) => {
            reports.monthlyPaySubcontractorCandidates=action.payload;
        },
        monthlyPayRecruiterCandidatesReceived: (reports, action) => {
            reports.monthlyPayRecruiterCandidates=action.payload;
        },
        monthlyInvoiceAgencyCandidatesReceived: (reports, action) => {
            reports.monthlyInvoiceAgencyCandidates=action.payload;
        },
        monthlyGrossProfitCandidatesReceived: (reports, action) => {
            reports.monthlyGrossProfitCandidates=action.payload;
        },
        monthlyBurnHourCandidatesReceived: (reports, action) => {
            reports.monthlyBurnHourCandidates=action.payload;
        },
        quartlyBurnHourCandidatesReceived: (reports, action) => {
            reports.quartlyBurnHourCandidates=action.payload;
        },
        dataTypeApplicationReportsRececived: (reports, action) => {
            reports.dataTypeApplicationReports=action.payload;
        },
    }
});

export const { loginTrailsReceived,monthlyAchSubcontractorReceived, monthlyAchRecruiterReceived,
    monthlyClientInvoicesReceived,monthlyClientCandidateInvoicesReceived,
    monthlyPaySubcontractorCandidatesReceived,monthlyPayRecruiterCandidatesReceived,
    monthlyInvoiceAgencyCandidatesReceived,monthlyGrossProfitCandidatesReceived,
    monthlyBurnHourCandidatesReceived,quartlyClientInvoicesReceived,
    dataTypeApplicationReportsRececived ,quartlyBurnHourCandidatesReceived,filterLoginTrailsReceived} = slice.actions;
export default slice.reducer;

export const loadLoginTrails = (pageSize,pageIndex) => apiCallBegan({
    url: config.GetLoginTrails+pageSize+"&pageIndex="+pageIndex,
    onSuccess: loginTrailsReceived.type
});

export const loadLoginTrailsByFilter = (data) => apiCallBegan({
    url: config.GetLoginTrailsByFilter,
    method: "post",
    data: data,
    onSuccess: loginTrailsReceived.type
});

export const loadMonthlyAchSubcontractor = (y, m) => apiCallBegan({
    url: config.GetMonthlySubcontractorRpt + y + "&month=" + m + "&type=subcontractor",
    onSuccess: monthlyAchSubcontractorReceived.type
});

export const loadMonthlyAchRecruiter = (y,m) => apiCallBegan({
    url: config.GetMonthlySubcontractorRpt + y + "&month=" + m + "&type=recruiter",
    onSuccess: monthlyAchRecruiterReceived.type
});

export const loadMonthlyClientInvoices = (y) => apiCallBegan({
    url: config.GetClientInvoices + y + "&type=monthly",
    onSuccess: monthlyClientInvoicesReceived.type
});

export const loadQuartlyClientInvoices = (y) => apiCallBegan({
    url: config.GetClientInvoices + y + "&type=quartly",
    onSuccess: quartlyClientInvoicesReceived.type
});

export const loadMonthlyClientCandidateInvoices = (y) => apiCallBegan({
    url: config.GetClientInvoices + y + "&type=candidate",
    onSuccess: monthlyClientCandidateInvoicesReceived.type
});

export const loadMonthlyPaySubcontractorCandidates = (id) => apiCallBegan({
    url: config.GetCandidatePayReport + id + "&type=subcontractor",
    onSuccess: monthlyPaySubcontractorCandidatesReceived.type
});

export const loadMonthlyPayRecruiterCandidates = (id) => apiCallBegan({
    url: config.GetCandidatePayReport + id + "&type=recruiter",
    onSuccess: monthlyPayRecruiterCandidatesReceived.type
});

export const loadMonthlyInvoiceAgencyCandidates = (id) => apiCallBegan({
    url: config.GetCandidatePayReport + id + "&type=agency",
    onSuccess: monthlyInvoiceAgencyCandidatesReceived.type
});

export const loadMonthlyGrossProfitCandidates = (y, m) => apiCallBegan({
    url: config.GetCandidateGrossRpt + y + "&month=" + m,
    onSuccess: monthlyGrossProfitCandidatesReceived.type
});

export const loadMonthlyBurnHourCandidates = () => apiCallBegan({
    url: config.GetAllCandidateBurnReport,
    onSuccess: monthlyBurnHourCandidatesReceived.type
});
export const loadQuartlyBurnHourCandidates = () => apiCallBegan({
    url: config.GetAllCandidateQtrlyBurnReport,
    onSuccess: quartlyBurnHourCandidatesReceived.type
});

export const loadDataTypeApplicationReports = () => apiCallBegan({
    url: config.GetApplicationReport,
    onSuccess: dataTypeApplicationReportsRececived.type
});

export const getMonthlyAchSubcontractor = createSelector(
    state => state.entities.reports,
    reports => reports.monthlyAchSubcontractor
);

export const getLoginTrails = createSelector(
    state => state.entities.reports,
    reports => reports.loginTrails
);

export const getMonthlyAchRecruiter = createSelector(
    state => state.entities.reports,
    reports => reports.monthlyAchRecruiter
);

export const getMonthlyClientInvoices = createSelector(
    state => state.entities.reports,
    reports => reports.monthlyClientInvoices
);

export const getQuartlyClientInvoices = createSelector(
    state => state.entities.reports,
    reports => reports.quartlyClientInvoices
);

export const getMonthlyClientCandidateInvoices = createSelector(
    state => state.entities.reports,
    reports => reports.monthlyClientCandidateInvoices
);

export const getMonthlyPaySubcontractorCandidates = createSelector(
    state => state.entities.reports,
    reports => reports.monthlyPaySubcontractorCandidates
);

export const getMonthlyPayRecruiterCandidates = createSelector(
    state => state.entities.reports,
    reports => reports.monthlyPayRecruiterCandidates
);
export const getMonthlyInvoiceAgencyCandidates = createSelector(
    state => state.entities.reports,
    reports => reports.monthlyInvoiceAgencyCandidates
);
export const getMonthlyGrossProfitCandidates = createSelector(
    state => state.entities.reports,
    reports => reports.monthlyGrossProfitCandidates
);

export const getMonthlyBurnHourCandidates = createSelector(
    state => state.entities.reports,
    reports => reports.monthlyBurnHourCandidates
);
export const getQuartlyBurnHourCandidates = createSelector(
    state => state.entities.reports,
    reports => reports.quartlyBurnHourCandidates
);

export const getDataTypeApplicationReports = createSelector(
    state => state.entities.reports,
    reports => reports.dataTypeApplicationReports
);

