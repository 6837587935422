import React, { Component } from "react";
import { Modal, Button, Tab, Tabs } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";
import geo from "../../services/geoService";
import Form from "../../comman/form";

class ViewCandidate extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      candidateEntity: [
        { id: 1, name: "C2C Basis" },
        { id: 2, name: "W2 Basis" },
        { id: 3, name: "1099 Basis" },
      ],
      w2Basis: [
        { id: 1, name: "W2 Hourly with Benefits" },
        { id: 2, name: "W2 Hourly with No-Benefits" },
      ],
      stateList: [],
      months: [
        { id: 1, name: "Jan" },
        { id: 2, name: "Feb" },
        { id: 3, name: "Mar" },
        { id: 4, name: "Apr" },
        { id: 5, name: "May" },
        { id: 6, name: "Jun" },
        { id: 7, name: "Jul" },
        { id: 8, name: "Aug" },
        { id: 9, name: "Sept" },
        { id: 10, name: "Oct" },
        { id: 11, name: "Nov" },
        { id: 12, name: "Dec" },
      ],
      mspMode: [
        { id: 1, name: "Direct" },
        { id: 2, name: "MSP-DotStaff" },
        { id: 3, name: "MSP-ACRO" },
        { id: 4, name: "MSP-CAI" },
        { id: 5, name: "MSP-TAFFIN" },
        { id: 6, name: "Others" },
      ],
      selectedSubcontractor: "",
      selectedRecruiter: "",
    };
  }

  schema = {};

  async componentDidMount() {
    const { data: recruiterList } = await http.get(
      config.GetPartnerByType + "Recruiter"
    );

    const { data: subContractorList } = await http.get(
      config.GetPartnerByType + "Sub-Contractors"
    );
    const stateList = await geo.getStateList();
    this.setState({ stateList });
    this.setState({
      recruiterList,
      subContractorList,
    });

    const { selectedObj, viewType } = this.props;
    
    this.setState({ data: {} });
    if (selectedObj !== undefined && selectedObj !== "") {
      const { data } = this.state;
      data["id"] = selectedObj.performerid;
      data["ipcsId"] = selectedObj.maxloans;
      data["recruiter"] =
        selectedObj.recruiter !== null ? selectedObj.recruiter.fuid : 0;
      data["subcontractor"] = selectedObj.validId;

      const selectedSubcontractor = subContractorList.filter(
        (x) => x.fuid === parseInt(selectedObj.validId)
      );

      data["Rate"] = selectedObj.rates?.subcontractorRate;
      data["Recruiter_Rate"] = selectedObj.rates?.recruiterRate;

      const selectedRecruiter = recruiterList.filter(
        (x) =>
          x.fuid ===
          parseInt(
            selectedObj.recruiter !== null ? selectedObj.recruiter.fuid : 0
          )
      );
      const aWorkfinityApp = JSON.parse(selectedObj.performerData);
      const appShortName = Object.keys(aWorkfinityApp)[1];
      const aWorkfinityAppEntity = aWorkfinityApp[appShortName];
      Object.keys(aWorkfinityAppEntity).forEach(function (keyValue) {
        if (
          keyValue !== "@xmlns:xsd" &&
          keyValue !== "@xmlns:xsi" &&
          keyValue !== "EntityId"
        ) {
          Object.keys(aWorkfinityAppEntity[keyValue]).forEach(function (
            keyValue1
          ) {
            if (keyValue1 !== "EntityId") {
              var item = aWorkfinityAppEntity[keyValue][keyValue1];
              if (!Array.isArray(item)) data[keyValue1.replace("@", "")] = item;
            }
          });
        }
      });

      this.setState({
        data,
        aWorkfinityApp,
        aWorkfinityAppEntity,
        entityName: appShortName,
        selectedSubcontractor,
        selectedRecruiter,
      });
    }
  }

  componentWillReceiveProps = (props) => {

  };

  render() {
    const {
      recruiterList,
      subContractorList,
      candidateEntity,
      w2Basis,
      stateList,
      data,
      months,
      mspMode,
      selectedSubcontractor,
      selectedRecruiter,
    } = this.state;

    const { viewShowHide, handleViewShowHide, viewType } = this.props;

    if (data["CandidateEntity"] === "W2 Basis") {
      const rate = data["Rate_hour_Paid_to_Candidate"];
      const tax = data["Tax"];
      if (
        rate !== "" &&
        rate !== undefined &&
        tax !== "" &&
        tax !== undefined
      ) {
        const taxPerc = tax / 100;
        data["finalRate"] = parseFloat(rate) + parseFloat(rate * taxPerc);
      } else {
        data["finalRate"] = rate;
      }
    }

    if (
      data["StartDate"] !== "" &&
      data["StartDate"] !== undefined &&
      data["EngagementDuration"] !== "" &&
      data["EngagementDuration"] !== undefined
    ) {
      const dt = new Date(data["StartDate"]);
      const num = parseInt(data["EngagementDuration"]);
      dt.setMonth(dt.getMonth() + num);
      //console.log(dt);
      const monthName = months.filter((x) => x.id === dt.getMonth() + 1)[0]
        .name;
      data["EndDate"] = dt.getDate() + "-" + monthName + "-" + dt.getFullYear();
    }

    let objPartner = [];
    if (viewType === "subcontractor") objPartner = selectedSubcontractor;
    if (viewType === "recruiter") objPartner = selectedRecruiter;
    if (objPartner.length > 0) {
      data["AddressLine1"] = objPartner[0].accessKey;
      data["AddressLine2"] = objPartner[0].portalUrl;
      data["City1"] = objPartner[0].city;
      data["State1"] = objPartner[0].state;
      data["Zip1"] = objPartner[0].zip;
    }
    return (
      <div className="col-md-12">
        <div className="col-md-12 text-right">
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => this.props.handleDurationShowHide("", "", -1)}
            >
              <i className="fa fa-times mr-2"></i>Close
            </button>
          </div>
        {/* <div className="row">
            <div className="col-lg-12">
              {this.renderSelect(
                "CandidateEntity",
                "Candidate Entity",
                candidateEntity,
                "name",
                "name"
              )}
            </div>
            {data["CandidateEntity"] === "W2 Basis" && (
              <div className="col-md-12">
                {this.renderSelect(
                  "w9_Type",
                  "Select Benefits",
                  w2Basis,
                  "name",
                  "name"
                )}
              </div>
            )}
          </div> */}
          <div className="col-md-12">
          
        {viewType === "subcontractor" && "Subcontractor"}
        {viewType === "candidate" && "Candidate"}
        {viewType === "agency" && "Agency"}
        {viewType === "msp" && "MSP"}
        {viewType === "recruiter" && "Recruiter"} Details
        <fieldset disabled={true}>
          {viewType === "agency" && (
            <div className="row">
              <div className="col-md-6">
                {this.renderSelect("State", "State", stateList, "name", "name")}
              </div>
              <div className="col-md-6">
                {this.renderInput("AgencyName", "Agency Name")}
              </div>
              <div className="col-md-6">
                {this.renderInput("PositionTitle", "Position Title")}
              </div>
              <div className="col-md-6">
                {this.renderInput("PositionID", "Position ID")}
              </div>
              <div className="col-md-12">
                {this.renderInput("PositionLocation", "Position Location")}
              </div>

              <div className="col-md-4">
                {this.renderInput("StartDate", "Start Date", "date")}
              </div>
              <div className="col-md-4">
                {this.renderInput("EngagementDuration", "Engagement Duration")}
              </div>
              <div className="col-md-4">
                {this.renderInput("EndDate", "End Date")}
              </div>

              <div className="col-md-12">
                {this.renderInput(
                  "RatePayableIpcs",
                  "Rate/Hour-Received By IPCS $"
                )}
              </div>
              <div className="col-md-12">
                {this.renderInput("ProjectDescription", "Project Description")}
              </div>

              <div className="col-md-12">
                <label
                  htmlFor=""
                  className="form-control-label bg-admin-light border-admin-left p-2 w-100"
                >
                  Agency Contact
                </label>
              </div>
              <div className="col-md-4">
                {this.renderInput("AgencyContact", "Name")}
              </div>
              <div className="col-md-4">
                {this.renderInput("AgencyEmail", "Email")}
              </div>
              <div className="col-md-4">
                {this.renderInput("AgencyPhone", "Phone", "phone")}
              </div>
            </div>
          )}

          {viewType === "msp" && (
            <div className="row">
              <div className="col-md-12">
                {this.renderSelect(
                  "Msp_Mode",
                  "MSP Mode",
                  mspMode,
                  "name",
                  "name"
                )}
              </div>
              <div className="col-md-12">
                <label
                  htmlFor=""
                  className="form-control-label bg-admin-light border-admin-left p-2 w-100"
                >
                  MSP Contact 1
                </label>
              </div>
              <div className="col-md-4">
                {this.renderInput("Msp_Contact1_Name", "Name")}
              </div>
              <div className="col-md-4">
                {this.renderInput("Msp_Contact1_Email", "Email")}
              </div>
              <div className="col-md-4">
                {this.renderInput("Msp_Contact1_Phone", "Phone", "phone")}
              </div>
              <div className="col-md-12">
                <label
                  htmlFor=""
                  className="form-control-label bg-admin-light border-admin-left p-2 w-100"
                >
                  MSP Contact 2
                </label>
              </div>
              <div className="col-md-4">
                {this.renderInput("Msp_Contact2_Name", "Name")}
              </div>
              <div className="col-md-4">
                {this.renderInput("Msp_Contact2_Email", "Email")}
              </div>
              <div className="col-md-4">
                {this.renderInput("Msp_Contact2_Phone", "Phone", "phone")}
              </div>
              <div className="col-md-12">
                {this.renderTextArea("mspNotes", "Notes")}
              </div>
            </div>
          )}

          {viewType === "candidate" && (
            <div className="row">
              <div className="col-md-12">
                {this.renderInput("ipcsId", "IPCS Existing ID")}
              </div>
              <div className="col-lg-4">
                {this.renderInput("First_Name", "First Name")}
              </div>
              <div className="col-lg-4">
                {this.renderInput("Middle_Name", "Middle Name")}
              </div>
              <div className="col-lg-4">
                {this.renderInput("Last_Name", "Last Name")}
              </div>
              <div className="col-lg-6">
                {this.renderInput("Email", "Email")}
              </div>
              <div className="col-lg-6">
                {this.renderInput("Mobile_No", "Mobile Number", "phone")}
              </div>

              {data["CandidateEntity"] === "1099 Basis" && (
                <div className="col-md-12">
                  {this.renderInput(
                    "Rate_hour_Paid_to_Candidate",
                    "Rate/hour-Paid to Candidate $"
                  )}
                </div>
              )}

              {data["CandidateEntity"] === "W2 Basis" && (
                <React.Fragment>
                  <div className="col-md-6">
                    {this.renderInput(
                      "Rate_hour_Paid_to_Candidate",
                      "Rate/hour-Paid to Candidate $"
                    )}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput("Tax", "Tax Rate %")}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput(
                      "finalRate",
                      "Final Rate/hour-Paid to Candidate $"
                    )}
                  </div>
                  {data["w9_Type"] === "W2 Hourly with Benefits" && (
                    <div className="col-md-6">
                      {this.renderInput("costOfBenefit", "Cost Of Benefit $")}
                    </div>
                  )}
                </React.Fragment>
              )}
              <div className="col-md-12">
                {this.renderTextArea("notes", "Notes")}
              </div>
            </div>
          )}

          {viewType === "subcontractor" && (
            <div className="row">
              {data["CandidateEntity"] === "C2C Basis" && (
                <React.Fragment>
                  <div className="col-md-6">
                    {this.renderSelect(
                      "subcontractor",
                      "Select Subcontractor",
                      subContractorList,
                      "label",
                      "fuid"
                    )}
                  </div>

                  <div className="col-md-6">
                    {this.renderInput(
                      "Rate",
                      "Rate/Hour-Paid to Subcontractor $"
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
          {viewType === "recruiter" && (
            <div className="row">
              <div className="col-md-8">
                {this.renderSelect(
                  "recruiter",
                  "Recruiter",
                  recruiterList,
                  "label",
                  "fuid"
                )}
              </div>
              <div className="col-md-4">
                {this.renderInput(
                  "Recruiter_Rate",
                  "Rate/Hour-Paid to Recruiter $"
                )}
              </div>
            </div>
          )}
          {(viewType === "subcontractor" || viewType === "recruiter") && (
            <div className="row">
              <div className="col-md-6">
                {this.renderInput("AddressLine1", "Address Line 1")}
              </div>
              <div className="col-md-6">
                {this.renderInput("AddressLine2", "Address Line 2")}
              </div>
              <div className="col-md-4">
                {this.renderInput("City1", "City")}
              </div>
              <div className="col-md-4">
                {this.renderSelect(
                  "State1",
                  "State",
                  stateList,
                  "name",
                  "name"
                )}
              </div>
              <div className="col-md-4">{this.renderInput("Zip1", "Zip")}</div>
            </div>
          )}
        </fieldset>
        </div>
      </div>
    );
  }
}

export default ViewCandidate;
