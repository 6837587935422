import React, { Component } from "react";
import { Popover, Button, OverlayTrigger } from "react-bootstrap";
import DocusignPopup from "./DocusignPopup";
import http from "../../services/HttpService";
import config from "../../config.json";
import Moment from "react-moment";
import Swal from "sweetalert2";
class Docusign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHide: false,
      docusignedDocuments: [],
      contractDocuments: [],
      docType: "",
    };
  }

  handleModalShowHide = (type) => {
    if (type === "success") {
      const { workflowTask } = this.props;
      this.loadDocument(workflowTask);
      this.setState({ showHide: false, docType: "" });
    } else {
      this.setState({ showHide: !this.state.showHide, docType: type });
    }
  };

  getPopover = (notes) => {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h4">Notes</Popover.Title>
        <Popover.Content>{notes}</Popover.Content>
      </Popover>
    );
    return (
      <React.Fragment>
        <OverlayTrigger trigger="click" placement="top" overlay={popover}>
          <button className="btn btn-sm btn-link p-0 text-underline">
            <i className="fa fa-eye mr-2"></i>View
          </button>
        </OverlayTrigger>
      </React.Fragment>
    );
  };

  componentDidMount() {
    const { workflowTask } = this.props;
    //console.log(workflowTask);
    if (workflowTask !== undefined) {
      this.loadDocument(workflowTask);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    // const { workflowTask } = nextProps;
    // console.log(workflowTask);
    // if (workflowTask !== undefined) {
    //   this.loadDocument(workflowTask);
    // }
  };

  loadDocument = async (workflowTask) => {
    const { data: docusignedDocuments } = await http.get(
      config.GetDocusingDocuments + workflowTask.applicantId
    );
    this.setState({ docusignedDocuments });
  };
  handleViewSignedDoc = async (item) => {
    let urlLink = http.baseURL.replace("api/", "") + "SignedDocuments/";
    if (item.signedDocUrl !== null && item.signedDocUrl !== "") {
      urlLink += item.signedDocUrl;
    } else {
      const { data } = await http.get(
        config.ViewDocusignDoc + item.id + "&ciid=" + item.ciid
      );
      urlLink += data;
    }
    window.open(urlLink, "_blank");
  };

  handleSendDocusign = async (id) => {
    const { data } = await http.get(config.SendDocusign + id);
    this.getAlert("", "Sent Successfully", "success");
    if (data === "success") {
      const { workflowTask } = this.props;
      this.loadDocument(workflowTask);
    }
  };

  handleDeleteDocusign = async (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete this document?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Yes",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data } = await http.get(config.DeleteDocusign + id);
        this.getAlert("", "Deleted Successfully", "success");
        if (data === "success") {
          const { workflowTask } = this.props;
          this.loadDocument(workflowTask);
        }
      }
    });
  };

  getAlert(title, message, type) {
    return Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: "Close",
      confirmButtonColor: "#d33",
    });
  }

  render() {
    const { workflowTask, type } = this.props;
    const { docusignedDocuments, contractDocuments, docType } = this.state;
    //console.log(docusignedDocuments);

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6">
            <h3 className="mb-0">e-Sign Documents</h3>
          </div>
          <div className="col-md-6 text-right">
            <button
              type="button"
              className="btn btn-sm btn-default mb-2"
              onClick={() => this.handleModalShowHide("")}
            >
              <i className="fa fa-plus mr-2" aria-hidden="true" />
              Add Document
            </button>
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-striped align-items-center table-flush">
                <thead className="thead-light-green">
                  <tr>
                    <th>ID</th>
                    <th>Date & Time</th>
                    <th>Document Name</th>
                    <th>Size</th>
                    <th>Signed Status</th>
                    <th>Signed Document</th>
                    <th>Added By</th>
                    <th>Remarks</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {docusignedDocuments.map((item, key) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.createdDateTime}
                        </Moment>
                      </td>
                      <td>{item.documentName}</td>
                      <td>{item.docSize}</td>
                      <td>
                        {item.status ? (
                          <button className="btn btn-sm btn-link p-0">
                            Done
                          </button>
                        ) : (
                          <button className="btn btn-sm btn-link p-0">
                            Pending
                          </button>
                        )}
                      </td>
                      <td>
                        {item.status && (
                          <button
                            className="btn btn-sm btn-link p-0 text-underline"
                            onClick={() => this.handleViewSignedDoc(item)}
                          >
                            View
                          </button>
                        )}
                      </td>
                      <td>
                        {item.name}
                        <br />
                        {item.email}
                        <br />
                        {item.role}
                      </td>
                      <td>{item.remarks}</td>
                      <td>
                        {item.sendStatus ? (
                          <button className="btn btn-sm btn-link p-0">
                            Sent
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-link p-0 text-underline"
                            onClick={() => this.handleSendDocusign(item.id)}
                          >
                            Send
                          </button>
                        )}
                        <button
                          className="btn btn-sm btn-link p-0 text-underline"
                          onClick={() => this.handleDeleteDocusign(item.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h3 className="mb-0">Contract Documents</h3>
          </div>
          <div className="col-md-6 text-right">
            <button
              type="button"
              className="btn btn-sm btn-default mb-2"
              onClick={() => this.handleModalShowHide("contract")}
            >
              <i className="fa fa-plus mr-2" aria-hidden="true" />
              Add Document
            </button>
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-striped align-items-center table-flush">
                <thead className="thead-light-green">
                  <tr>
                    <th>ID</th>
                    <th>Date & Time</th>
                    <th>Contract Name</th>
                    <th>Size</th>
                    <th>Signed Status</th>
                    <th>Signed Contract</th>
                    <th>Added By</th>
                    <th>Remarks</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {contractDocuments.map((item, key) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.createdDateTime}
                        </Moment>
                      </td>
                      <td>{item.documentName}</td>
                      <td>{item.docSize}</td>
                      <td>
                        {item.status ? (
                          <button className="btn btn-sm btn-link p-0">
                            Done
                          </button>
                        ) : (
                          <button className="btn btn-sm btn-link p-0">
                            Pending
                          </button>
                        )}
                      </td>
                      <td>
                        {item.status && (
                          <button
                            className="btn btn-sm btn-link p-0 text-underline"
                            onClick={() => this.handleViewSignedDoc(item)}
                          >
                            View
                          </button>
                        )}
                      </td>
                      <td>
                        {item.name}
                        <br />
                        {item.email}
                        <br />
                        {item.role}
                      </td>
                      <td>{item.remarks}</td>
                      <td>
                        {item.sendStatus ? (
                          <button className="btn btn-sm btn-link p-0">
                            Sent
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-link p-0 text-underline"
                            onClick={() => this.handleSendDocusign(item.id)}
                          >
                            Send
                          </button>
                        )}
                        <button
                          className="btn btn-sm btn-link p-0 text-underline"
                          onClick={() => this.handleDeleteDocusign(item.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <DocusignPopup
          showHide={this.state.showHide}
          handleModalShowHide={this.handleModalShowHide}
          workflowTask={workflowTask}
          signType={type}
          docType={docType}
        ></DocusignPopup>
      </React.Fragment>
    );
  }
}

export default Docusign;
