import React, { Component, useEffect, useState } from "react";
import BprmForm from "../comman/BprmForm";
import InfoHeader from "../comman/infoHeader";
import PageHeader from "./PageHeader";
import config from "../config.json";
import http from "../services/HttpService";
import geo from "../services/geoService";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FileSaver from "file-saver";
import { Modal, Button } from "react-bootstrap";
import Moment from "react-moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import WorkflowElement from "../app/components/WorkflowElement";
import { useDispatch, useSelector } from "react-redux";
import { getTemplateForm, loadTemplateForm } from "../app/store/template";

const PrintTemplate = () => {

  const state = {
    route: [{ name: "Home", url: "/" }],
    wfForm: {},
    aWorkfinityAppEntity: {},
    aWorkfinityApp: {},
    stateList: [],
    data: {},
    errors: {},
  };

  const [wfTask, setWfTask] = useState(null);
  const [updatedStateData, setUpdatedStateData] = useState(null);
  const [saveBtnProcessStatus, setSaveBtnProcessStatus] = useState(false);
  const [wfForm, setWfForm] = useState(null);

  //const templateFormSelector=useSelector(getTemplateForm)

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const state = location.state;
    if (state?.item) {
      setWfTask({ formId: state.item.templateId, ciid: state.item.ciid });
      //dispatch(loadTemplateForm(state.item.templateId));
    }
  }, []);

  const handleNextTask = (data) => {
    //handleSubmit(data);
  }

  // async componentDidMount() {
  //   let item = undefined;
  //   if (this.props.location !== undefined) item = this.props.location.state;
  //   console.log(this.props)
  //   if (item !== undefined) {
  //     const { data } = this.state;
  //     const { data: wfForm } = await http.get(config.GetForm + item.templateId);
  //     this.setState({ wfForm });
  //     if (item.ciid !== undefined) {
  //       const { data: application } = await http.get(
  //         config.GetApplication + item.ciid
  //       );
  //       const aWorkfinityApp = JSON.parse(application.applicationData);
  //       const appShortName = Object.keys(aWorkfinityApp)[1];
  //       const aWorkfinityAppEntity = aWorkfinityApp[appShortName];
  //       Object.keys(aWorkfinityAppEntity).forEach(function (keyValue) {
  //         if (
  //           keyValue !== "@xmlns:xsd" &&
  //           keyValue !== "@xmlns:xsi" &&
  //           keyValue !== "EntityId"
  //         ) {
  //           Object.keys(aWorkfinityAppEntity[keyValue]).forEach(function (
  //             keyValue1
  //           ) {
  //             if (keyValue1 !== "EntityId") {
  //               data[keyValue + "." + keyValue1.replace("@", "")] =
  //                 aWorkfinityAppEntity[keyValue][keyValue1];
  //             }
  //           });
  //         }
  //       });
  //       this.setState({ aWorkfinityApp, aWorkfinityAppEntity, data });
  //     }
  //   }
  // }

  const handlePrintPdf = async (event) => {
    // const frmData = new FormData();
    // //console.log(this.refs.printBody.outerHTML);
    // frmData.append("html", this.refs.printBody.outerHTML);
    // const { data } = await http.post(config.PrintTemplate, frmData);
    // let pdfWindow = window.open("");
    // pdfWindow.document.write(
    //   "<iframe width='100%' height='100%' src='" +
    //     data +
    //     "' style='border: none;'></iframe>"
    // );
  };

  // const getHighestColNum = (elements) => {
  //   const col = elements.reduce(function (prev, current) {
  //     return prev.components.length > current.components.length
  //       ? prev
  //       : current;
  //   });
  //   return col.components.length;
  // }

  // const elements = wfForm?.elementJson && JSON.parse(wfForm.elementJson);
  // const compNum = elements && getHighestColNum(elements);

  return (
    <React.Fragment>
      {/* <PageHeader
          route={route}
          parentTitle="Print PDF"
          headerTitle="Print PDF"
        ></PageHeader> */}
      <div className="col-md-12 text-right mb-2">
        <button
          type="button"
          className="btn btn-outline-default btn-sm btn-min-width"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>Back
        </button>
      </div>
      {/* <div id="printBody">
          <table className="table table-bordered bg-white">
            {elements &&
              elements.map((col) => (
                <tr>
                  {col.components.map((comp) => {
                    return (
                      <td
                        style={{ whiteSpace: "pre-wrap" }}
                        colSpan={
                          col.components.length === 1
                            ? compNum
                            : compNum - col.components.length
                        }
                      >
                        {this.rendorHtmlComponent(comp)}
                      </td>
                    );
                  })}
                </tr>
              ))}
          </table>

        </div> */}

      <div className="col-md-12">

        {wfTask?.formId > 0 && <WorkflowElement
          wfTask={wfTask}
          handleNextTask={handleNextTask}
          viewType={false}
          appSummary={false}
          codeVerified={true}
          wfTaskType={"template"}
          saveBtnProcessStatus={saveBtnProcessStatus}
          setSaveBtnProcessStatus={setSaveBtnProcessStatus}
          updatedStateData={updatedStateData}
        />
        }

      </div>

    </React.Fragment>
  );
}

export default PrintTemplate;
