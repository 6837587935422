import React, { Component } from "react";

class UploadDocument extends Component {
  render() {
    const { documents, onFileChange, handleFileRemove } = this.props;
    return (
      <div>
        <div className="dropdown">
          <button
            className="btn btn-success dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-paperclip mr-2" aria-hidden="true"></i>
            Attachment
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href="!#">
              <label
                htmlFor="AdhocMessagedocName"
                className="custom-file-upload mb-0"
              >
                <i className="fa fa-desktop mr-2" aria-hidden="true" />
                Upload From Local
              </label>
              <input
                id="AdhocMessagedocName"
                type="file"
                style={{ display: "none" }}
                onChange={onFileChange}
                multiple
                name="files"
              />
            </a>
            <a className="dropdown-item" href="!#">
              <i class="fa fa-database mr-2" aria-hidden="true"></i>
              Upload From DDRMS
            </a>
            <a className="dropdown-item" href="!#">
              <i class="fa fa-dropbox mr-2" aria-hidden="true"></i>
              Upload From Dropbox
            </a>
            <a className="dropdown-item" href="!#">
              <i class="fa fa-google mr-2" aria-hidden="true"></i>
              Upload From Google Drive
            </a>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped align-items-center table-flush mt-2">
            <thead className="thead-light">
              <tr>
                <th>S.No</th>
                <th>File</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {documents.map((item, key) => (
                <tr>
                  <td>{key + 1}</td>
                  <td>{item.name}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-link"
                      title="Remove"
                      onClick={() => handleFileRemove(item)}
                    >
                      <i class="fa fa-times text-red" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default UploadDocument;
