import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

const Pagination = (props) => {
  const {
    itemsCount,
    pageSize,
    currentPage,
    onPageChange,
    startIndex,
    endIndex,
  } = props;
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1) return null;
  const pages = _.range(1, pagesCount + 1);

  return (
    <div className="card-footer p-3">
      <div className="row">
      <div className="col-md-5 d-flex align-items-center">
        Showing {currentPage} to {pagesCount} of {itemsCount} entries
      </div>
      <div className="col-md-7 d-flex justify-content-end">
        <nav aria-label="...">
          <ul className="pagination mb-0">
            <li
              className={currentPage === 1 ? "page-item disabled" : "page-item"}
            >
              <a
                className="page-link"
                style={{fontSize:"1.5rem"}}
                tabIndex={-1}
                onClick={() => onPageChange(currentPage - 1)}
              >
                <i className="fa fa-angle-left" />
                <span className="sr-only">Previous</span>
              </a>
            </li>
            {endIndex > 5 && (
              <li className="page-item">
                <a className="page-link"> ...</a>
              </li>
            )}
            {pages.slice(startIndex, endIndex).map((page) => (
              <li
                className={
                  page === currentPage ? "page-item active" : "page-item"
                }
              >
                <a
                  className="page-link"
                  onClick={() => onPageChange(page)}
                  style={{ cursor: "pointer" }}
                >
                  {page}
                </a>
              </li>
            ))}
            {pagesCount > 5 && (
              <li className="page-item">
                <a className="page-link"> ...</a>
              </li>
            )}
            <li
              className={
                pagesCount === currentPage ? "page-item disabled" : "page-item"
              }
            >
              <a
                className="page-link"
                style={{fontSize:"1.5rem"}}
                onClick={() => onPageChange(currentPage + 1)}
              >
                <i className="fa fa-angle-right" />
                <span className="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
