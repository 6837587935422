import React, { Component } from "react";
import Moment from "react-moment";
import http from "../../services/HttpService";
import config from "../../config.json";
class MessagingList extends Component {
  constructor(props) {
    super(props);
  }

  handleDownloadFile = async (item) => {
    try {
      const { data } = await http.get(config.DownloadDocument + item.id, {
        responseType: "blob",
      });
      if (data.size > 0) {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", item.documentName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }

    // const { data } = await http.get(config.DownloadDocument + item.docId);
    // if (data !== "Fail") {
    //   this.download(item.docName.trim(), data);
    // }
  };

  download(filename, text) {
    var element = document.createElement("a");
    element.setAttribute("href", text);
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  getExtension(filename) {
    return filename.split(".").pop().toLowerCase();
  }

  render() {
    const { message, profilePicData, profilePicTwoData } = this.props;
    //console.log(message);
    return (
      <div className="bg-white mb-4">
        {message !== undefined && message.author === "send" && (
          <div className="media media-comment d-flex justify-content-end mr-4 media-comment-send">
            <div className="media-body">
              <div className="text-right">
                <h5 className="mt-0 mr-4 pr-3 mb-1">{message.performer}</h5>
              </div>
              <div className="media-comment-text media-comment-text-send">
                <div
                  className="text-sm lh-160 font-weight-normal"
                  dangerouslySetInnerHTML={{ __html: message.data }}
                ></div>

                {message.documents !== null && message.documents.length > 0 && (
                  <ul className="list-group mb-2 mt-2">
                    {message.documents.map((item, key) => (
                      <li className="list-group-item p-0">
                        <button
                          className="btn btn-link btn-sm text-left"
                          title="Download File"
                          onClick={() => this.handleDownloadFile(item)}
                        >
                          {item.documentName}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="icon-actions mt-1 text-right">
                <i className="fa fa-clock-o mr-1" aria-hidden="true"></i>
                <Moment format="MMM Do YYYY, h:mm A">{message.date}</Moment>
              </div>
            </div>
            <img
              alt="Image placeholder"
              className="avatar avatar-lg media-comment-avatar media-comment-avatar-send rounded-circle"
              src={profilePicData ? process.env.REACT_APP_API_BASEURL + "/ProfilePics/" + profilePicData : "../../assets/img/theme/team-4.png"}
            />
          </div>
        )}
        {message.author === "receive" && (
          <div className="media media-comment d-flex justify-content-start media-comment-receive">
            <img
              alt="Image placeholder"
              className="avatar avatar-lg media-comment-avatar rounded-circle"
              src={profilePicTwoData ? process.env.REACT_APP_API_BASEURL + "/ProfilePics/" + profilePicTwoData : "../../assets/img/theme/team-4.png"}
            />
            <div className="media-body">
              <h5 className="mt-0 ml-4 pl-3 mb-1">{message.performer}</h5>
              <div className="media-comment-text">
                <div
                  className="text-sm lh-160 font-weight-normal"
                  dangerouslySetInnerHTML={{ __html: message.data }}
                ></div>
                {message.documents !== null && message.documents.length > 0 && (
                  <ul className="list-group mt-2">
                    {message.documents.map((item, key) => (
                      <li className="list-group-item p-0">
                        <button
                          className="btn btn-link btn-sm text-left"
                          title="Download File"
                          onClick={() => this.handleDownloadFile(item)}
                        >
                          {item.documentName}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="icon-actions mt-1 text-left">
                <i className="fa fa-clock-o mr-1" aria-hidden="true"></i>
                <Moment format="MMM Do YYYY, h:mm A">{message.date}</Moment>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MessagingList;
