import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class BarChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      colorMap :{},
      selectedColors : {}
    }
  }
  componentDidMount() {}

  generateColor=()=> {
    return "#" + Math.random().toString(16).substr(-6);
  }
   generateColor1 = () => {
    let randomColorString = "#";
    const arrayOfColorFunctions = "0123456789abcdef";
    for (let x = 0; x < 6; x++) {
      let index = Math.floor(Math.random() * 16);
      let value = 
      [index];
  
      randomColorString += value;
    }
    return randomColorString;
  };

   newColorFind = id => {
    const {colorMap,selectedColors}=this.state;
    // If already generated and assigned, return
    if (colorMap[id]) return colorMap[id];
  
    // Generate new random color
    let newColor;
  
    do {
      newColor = this.generateColor();
    } while(selectedColors[newColor]);
  
    // Found a new random, unassigned color
    colorMap[id] = newColor;
    selectedColors[newColor] = true;
  
    // Return next new color
    return newColor;
  }
  
  

  render() {
    
    const { graphData } = this.props;
    let dtObj = [];
    let dtlbl = [];
    if(graphData.length > 0 ){
      graphData.map((item,key) => {
        dtlbl = item.label;
        let clr = this.newColorFind(key);
        const obj = {
          label: item.type,
          backgroundColor: clr,
          borderColor: clr,
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: item.data,
        };
        dtObj.push(obj);
      });
    }
    const data = {
      labels: dtlbl,
      datasets: dtObj,
    };

    return (
      <div className="chart">
        <Bar
          data={data}
          width={100}
          height={50}
          options={{ maintainAspectRatio: false }}
        />
      </div>
    );
  }
}

export default BarChart;
