import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "ddrmsDocuments",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        generatedContract: null,
        repoUploadStatus: false,
    },
    reducers: {
        repositoryDocumentsReceived: (ddrmsDocuments, action) => {
            ddrmsDocuments.list = action.payload;
            ddrmsDocuments.repoUploadStatus = false;
        },
        generatedContractReceived: (ddrmsDocuments, action) => {
            ddrmsDocuments.generatedContract = action.payload;
        },
        ddrmsRepoDocumentUploaded: (ddrmsDocuments, action) => {
            ddrmsDocuments.repoUploadStatus = action.payload;
        },
    }
});

export const { repositoryDocumentsReceived, generatedContractReceived,
    ddrmsRepoDocumentUploaded
} = slice.actions;
export default slice.reducer;

export const loadDdrmsRepositoryDocs = (id) => apiCallBegan({
    url: config.GetDdrmsRepository + id,
    onSuccess: repositoryDocumentsReceived.type
});

export const loadGenerateContractDocument1 = (ciid, id) => apiCallBegan({
    url: config.GenerateContractDoc + ciid + "&docId=" + id,
    responseType: "blob",
    onSuccess: generatedContractReceived.type
});

export const UploadDdrmsRepositoryDocs = (data) => apiCallBegan({
    url: config.UploadRepositoryDocument,
    method: "post",
    data: data,
    onSuccess: ddrmsRepoDocumentUploaded.type
});

export const loadGenerateContractDocument = (data) => apiCallBegan({
    url: config.GenerateContractDoc,
    method: "post",
    data: data,
    responseType: "blob",
    onSuccess: generatedContractReceived.type
});

export const getDdrmsRepositoryDocuments = createSelector(
    state => state.entities.ddrmsDocuments,
    ddrmsDocuments => ddrmsDocuments.list
);

export const getGeneratedDocument = createSelector(
    state => state.entities.ddrmsDocuments,
    ddrmsDocuments => ddrmsDocuments.generatedContract
);

export const getDdrmsRepoUploadStatus = createSelector(
    state => state.entities.ddrmsDocuments, 
    ddrmsDocuments => ddrmsDocuments.repoUploadStatus
);