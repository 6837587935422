import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import _ from "lodash";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllMonths, getAllYears } from "../../../../services/appService";
import { getAgenciesPartners, getAgencyCandidates, loadAgencies } from "../../../store/partner";
import { getMonthlyInvoiceAgencyCandidates, loadMonthlyInvoiceAgencyCandidates } from "../../../store/reports";
import GeneratePdf from "../../../../components/GeneratePdf";

const AgencyInvoiceCandidate = () => {

    const years = getAllYears();
    const months = getAllMonths();

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");

    const [pdfShowHide, setPdfShowHide] = useState(false);
    const [rptShowHide, setRptShowHide] = useState(false);
    const [selectedCol, setSelectedCol] = useState([]);
    const [selectedColData, setSelectedColData] = useState([]);

    const [agencies,setAgencies]=useState([]);
    const [candidates,setCandidates]=useState([]);
    const [allAgenciesCandidates,setAllAgenciesCandidates]=useState([]);
    const [candidatesInvoiceRpt,setCandidatesInvoiceRpt]=useState([]);

    const agenciesPartnersSelector = useSelector(getAgenciesPartners);
    const monthlyInvoiceAgencyCandidatesSelector = useSelector(getMonthlyInvoiceAgencyCandidates);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAgencies())
    }, []);

    useEffect(()=>{
        if(agenciesPartnersSelector.length > 0){
            setAllAgenciesCandidates(agenciesPartnersSelector);
            const list = _.unionBy(agenciesPartnersSelector, "title");
            setAgencies(list);
        }
    },[agenciesPartnersSelector])

    const handleAgencyChange = ({ currentTarget }) => {
        const agencyCandidates = allAgenciesCandidates.filter(
          (x) => x.title === currentTarget.value
        );
        setCandidates(agencyCandidates);
      };

    const handleAgencyCandidate = async ({ currentTarget }) => {
        dispatch(loadMonthlyInvoiceAgencyCandidates(currentTarget.value));
    };

    useEffect(()=>{
        setCandidatesInvoiceRpt(monthlyInvoiceAgencyCandidatesSelector);
    },[monthlyInvoiceAgencyCandidatesSelector])

    const  getAgencyMonPayTotal = () => {
        let amountT = 0;
        let paidT = 0;
        let dueT = 0;
        candidatesInvoiceRpt.map((item) => {
          amountT += item.totalAmount;
          paidT += item.paidAmount;
          dueT += item.totalAmount - item.paidAmount;
        });
        return (
          <tr className="font-weight-bold bg-lighter">
            <td colSpan={3} className="text-right">Total</td>
            <td className="text-right">{parseFloat(amountT).toFixed(2)}</td>
            <td className="text-right">{parseFloat(paidT).toFixed(2)}</td>
            <td></td>
            <td className="text-right">{parseFloat(amountT - paidT).toFixed(2)}</td>
          </tr>
        );
      };

      const generateAgencyReport=()=>{
        const headers = [
            [
                "Year",
                "Period",
                "IPCS Invoice #",
                "Amount Due $",
                "Amount Paid $",
                "Date Paid",
                "Amount Not Paid $",
            ],
        ];

        const colData = [];
        candidatesInvoiceRpt.map((item) => {
            const dt = [];
            dt.push(item.year);
            dt.push(months.filter((x) => x.id === item.month)[0].name);
            dt.push(
                item.invoiceNo,
                item.totalAmount,
                item.paidAmount,
                item.paymentDate,
                item.totalAmount - item.paidAmount,
            );
            colData.push(dt);
        });

        setSelectedCol(headers);
        setSelectedColData(colData);
        setPdfShowHide(true);
      }

      const handlepdfShowHide = () => {
        setPdfShowHide(false);
        setSelectedCol([]);
        setSelectedColData([]);
    };  

    const handleYearChange = ({ currentTarget }) => {
        const year = currentTarget.value;
        if (year !== "") {
            const filterData = monthlyInvoiceAgencyCandidatesSelector.filter(x => x.year === parseInt(year));
            setCandidatesInvoiceRpt(filterData);
        }
        else {
            setCandidatesInvoiceRpt(monthlyInvoiceAgencyCandidatesSelector);
        }
        setYear(year);
    }

    const handleMonthChange = ({ currentTarget }) => {
        const month = currentTarget.value;
        if (month !== "") {
            const filterData = monthlyInvoiceAgencyCandidatesSelector.
                                filter(x =>x.year === parseInt(year) && x.month === parseInt(month));

            setCandidatesInvoiceRpt(filterData);
        }
        else {
            setCandidatesInvoiceRpt(monthlyInvoiceAgencyCandidatesSelector);
        }
        setMonth(month);
    }

    return <>
        <div className="card shadow-none border">
            <div className="card-header text-center p-3 bg-lighter">
                <h4 className="mb-0">
                    Monthly Invoices to Client (Per
                    Candidate-Basis)
                </h4>
            </div>
            <div className="card-body p-3">
                <div className="row">
                    <div className="col-md-4">
                        <select
                            name="agencyName"
                            id="agencyName"
                            className="form-control form-control-sm"
                            onChange={handleAgencyChange}
                        >
                            <option value="">Select Agency</option>
                            {agencies.map((item) => (
                                <option value={item.title}>
                                    {item.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <select
                            name="candidateId"
                            id="candidateId"
                            className="form-control form-control-sm"
                            onChange={handleAgencyCandidate}
                        >
                            <option value="">Select</option>
                            {candidates.map((item) => (
                                <option value={item.performerid}>
                                    {item.performername}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-1">
                        <select
                            name="year"
                            id="year"
                            className="form-control form-control-sm"
                            onChange={handleYearChange}
                            value={year}
                        >
                            <option value="">Year</option>
                            {years.map((y) => (
                                <option value={y.name}>
                                    {y.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* <div className="col-md-2">
                        <select
                            name="month"
                            id="month"
                            className="form-control form-control-sm"
                            onChange={({ currentTarget }) => setMonth(currentTarget.value)}
                            value={month}
                        >
                            <option value="">Month</option>
                            {months.map((m) => (
                                <option value={m.id}>{m.name}</option>
                            ))}
                        </select>
                    </div> */}
                    <div className="col-md-3 text-right">
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-sm btn-outline-default"
                            table="monthly_pay_agencies"
                            filename="Client_Monthly_Pay"
                            sheet="Client_Monthly_Pay"
                            buttonText="Export to Excel"
                        />
                        <button
                            type="button"
                            className="btn btn-sm btn-outline-default"
                            onClick={generateAgencyReport}
                        >
                            Generate PDF
                        </button>
                    </div>

                    <div className="col-md-12 mt-2">
                        <div className="table-responsive">
                            <table
                                className="table align-items-center table-flush table-hover table-striped mb-2 border"
                                id="monthly_pay_agencies"
                            >
                                <thead className="thead-light">
                                    <th>Year</th>
                                    <th>Period</th>
                                    <th>IPCS Invoice #</th>
                                    <th>Amount Due $ </th>
                                    <th>Amount Paid $</th>
                                    <th>Date Paid</th>
                                    <th>Amount Not Paid $</th>
                                    {/* <th>Year to Date $</th> */}
                                </thead>
                                <tbody>
                                    {candidatesInvoiceRpt.map((item) => (
                                        <tr>
                                            <td>{item.year}</td>
                                            <td>
                                                {
                                                    months.filter(
                                                        (x) => x.id === item.month
                                                    )[0].name
                                                }
                                            </td>
                                            <td>{item.invoiceNo}</td>
                                            <td className="text-right">{item.totalAmount && parseFloat(item.totalAmount).toFixed(2)}</td>
                                            <td className="text-right">{item.paidAmount &&  parseFloat(item.paidAmount).toFixed(2)}</td>
                                            <td>
                                                {item.paymentDate && (
                                                    <Moment format="MMM Do YYYY, h:mm a">
                                                        {item.paymentDate}
                                                    </Moment>
                                                )}
                                            </td>
                                            <td className="text-right">
                                                {parseFloat(item.totalAmount - item.paidAmount).toFixed(2)}
                                            </td>
                                            {/* <td>{item.year}</td> */}
                                        </tr>
                                    ))}
                                    {getAgencyMonPayTotal()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <GeneratePdf
            handlepdfShowHide={handlepdfShowHide}
            pdfShowHide={pdfShowHide}
            selectedCol={selectedCol}
            selectedColData={selectedColData}
        ></GeneratePdf>
    </>
}

export default AgencyInvoiceCandidate;