import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";

class FollowUpPopup extends Component {
    render() {
        const { flowUpShowHide, handleFlowUpModalShowHide, selectedObj } = this.props;
        return (
          <Modal show={flowUpShowHide} size="lg">
            <Modal.Header closeButton onClick={handleFlowUpModalShowHide}>
              <Modal.Title>Patient Experiance and Adverse Event Data - View</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-secondary">
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive bg-white">
                    <table className="table align-items-center table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th>
                            Appointment <br />
                            Scheduled
                          </th>
                          <th>
                            Medication <br/>
                            Prescribed
                          </th>
                          <th>
                            Drugs Side
                            <br />
                            Effect, If Any
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td>
                                  Mar 24th 2021<br/>
                                  3:36 PM
                              </td>
                              <td>
                                  some issue
                              </td>
                              <td>
                                  check my prescription
                              </td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button variant="danger" onClick={handleFlowUpModalShowHide}>
                <i className="fa fa-times" aria-hidden="true"></i> Close
              </Button>
            </Modal.Footer>
          </Modal>
        );
    }
}

export default FollowUpPopup;