import React from "react";
import PropTypes from "prop-types";
import TableHeader from "./tableHeader"
import TableBody from "./tableBody";

const Table = ({ columns, data }) => {
 
  return (
    <table className="table align-items-center table-flush table-striped mb-4">
      <TableHeader columns={columns}></TableHeader>
      <TableBody data={data} columns={columns}></TableBody>
      {data.length == 0 && (
        <tfoot>
          <tr>
            <td colSpan={columns.length} align="center" className="bg-lighter">
              No Records found
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

Table.propTypes = {};

export default Table;
