import React from "react";

const TextArea = ({ name, label, error, req, ...rest }) => {
  return (
    <div className={`form-group ${req && "required"}`}>
      <label className="form-control-label" htmlFor={name}>
        {label}
      </label>
      <textarea
        {...rest}
        id={name}
        name={name}
        className="form-control"
        placeholder={label}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default TextArea;
