import React, { Component } from "react";
import { Link } from "react-router-dom";
import CrazyLine from "../Charts/crazyLine";
import InfoHeader from "../comman/infoHeader";
import PageHeader from "./PageHeader";

class StandardReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: [{ name: "Analytics", url: "" }],
    };
  }

  render() {
    return (
      <React.Fragment>
        {/* <PageHeader
          route={this.state.route}
          parentTitle="Query Based Reports"
          headerTitle="Query Based Reports"
        ></PageHeader> */}
        <div className="col-md-12">
          <div className="card mb-4 border shadow-none">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1>Business Intelligence / Analytics Report Dashboard</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <p>
                    There are two reports: Standard and Process Performance
                    Reports.
                  </p>
                </div>
              </div>
              <div className="row equal mt-3">
                <div className="col-md-2"></div>
                <div className="col-sm-4 d-flex">
                  <div
                    className="card"
                    style={{
                      border: "3px solid #fff29a",
                      backgroundColor: "#fff29a",
                    }}
                  >
                    <CrazyLine></CrazyLine>
                    <div className="card-body">
                      <h4 className="card-title text-black">
                        Standard Reports
                      </h4>
                      <p className="card-text text-black">
                        View system generated standard reports which contain the
                        most important data related to the system processing
                        parameters.
                      </p>
                      <Link to="StandardRpt" className="btn btn-secondary mt-3">
                        View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 d-flex">
                  <div
                    className="card"
                    style={{
                      border: "3px solid #c9ffb0",
                      backgroundColor: "#c9ffb0",
                    }}
                  >
                    <CrazyLine></CrazyLine>
                    <div className="card-body">
                      <h4 className="card-title text-black">
                        Process Performance Report
                      </h4>
                      <p className="card-text text-black">
                        View custom process performance reports that provides
                        visibility on how processes performed example
                        transactions etc.
                      </p>
                      <Link
                        to="/ProcessPerformanceRpt"
                        className="btn btn-secondary mt-3"
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StandardReport;
