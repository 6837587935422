import React, { Component, useEffect, useState } from "react";
import PageSizeOptions from "../../PageSizeOptions";
import Pagination from "../../../comman/pagination";
import { paginate, calculateIndex } from "../../../utlis/paginate";
import SendEmail from "./SendEmail";
import { getFormatedNumber } from "../../../services/appService";
import { useDispatch, useSelector } from "react-redux";
import { getFilterAllUsers, getRegisteredUsers, loadRegisteredUsers, loadRegisteredUsersByFilter } from "../../../app/store/users";
import { setCurrentPageTitle, setCurrentRoute } from "../../../app/store/layoutUI";
import MailHistory from "./MailHistory";
import { json, useParams } from "react-router-dom";
import AllCountries from "../../../services/allCountries";
import allCountryStates from "../../../services/allCountryStates";
import geoService from "../../../services/geoService";
import file from "file-saver";

const UsersEmail = (props) => {

  const { type, typeName } = props;

  const param = useParams();
  let msgType = param?.type;

  const route = [{ url: "", name: "Communications" }];

  const [candidates, setCandidates] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [dataType, setDataType] = useState("");

  const [selectedObj, setSelectedObj] = useState(null);
  const [showHide, setShowHide] = useState(false);
  const [showHideMailHistory, setShowHideMailHistory] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);
  const [userType, setUserType] = useState(type);
  const [totalCount, setTotalCount] = useState(10);
  const [rowsCount, setRowsCount] = useState(0);
  const [viewType, setViewType] = useState(0);

  const registeredUsersSelector = useSelector(getRegisteredUsers);
  const filterAllUsersSelector = useSelector(getFilterAllUsers);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPageTitle(typeName));
    dispatch(setCurrentRoute(route));
  }, [])

  useEffect(() => {
    dispatch(loadRegisteredUsers(pageSize, currentPage, userType))
  }, []);

  useEffect(() => {
    if (registeredUsersSelector.length > 0) {
      setRowsCount(registeredUsersSelector[0].totalApplicants);
      setCandidates(registeredUsersSelector);
    }
  }, [registeredUsersSelector]);

  useEffect(() => {
    setPageSize(5);
    setCurrentPage(1);
    getPagedData(filterAllUsersSelector, 1, 5);
  }, [filterAllUsersSelector]);

  const getPagedData = (list, currentPage, pageSize) => {
    const items = paginate(list, currentPage, pageSize);
    if (items.length > 0)
      setRowsCount(items[0].totalApplicants);

    setCandidates(items);
  };

  const handlePageChange = (page) => {
    const { startIndex: start, endIndex: end } = calculateIndex(page, startIndex, endIndex);
    setCurrentPage(page);
    setStartIndex(start);
    setEndIndex(end);
    if (dataType === "filter")
      getPagedData(filterAllUsersSelector, page, pageSize)
    else
      dispatch(loadRegisteredUsers(pageSize, page, userType))
  };

  const handlePageSizeChanged = ({ currentTarget }) => {
    setPageSize(currentTarget.value);
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(5);
    if (dataType === "filter")
      getPagedData(filterAllUsersSelector, currentPage, currentTarget.value);
    else
      dispatch(loadRegisteredUsers(currentTarget.value, currentPage, userType));
  };

  const handleModalShowHide = (obj) => {
    if (obj === "" || obj === "save") {
      setShowHide(false);
      setSelectedEmail([]);
      setSelectedObj(null);
      if (obj === "save") {
        if (dataType === "filter")
          getPagedData(filterAllUsersSelector, currentPage, pageSize);
        else
          dispatch(loadRegisteredUsers(pageSize, currentPage, userType));
      }
    }
    else {
      setShowHide(true);
      setSelectedObj(obj);
    }
  };

  const handleShowHideMailHistory = (obj, type) => {
    setShowHideMailHistory(!showHideMailHistory);
    setSelectedObj(obj);
    setViewType(type)
  }

  const handleCheck = (target, item) => {
    const emails = [...selectedEmail];
    if (target.checked) emails.push({ key: item.performerid, value: item.performeremail });
    else {
      const index = emails.findIndex(x => x.key === item.performerid);
      if (index > -1) {
        emails.splice(index, 1);
      }
    }
    setSelectedEmail(emails);
  };

  // const handleAllCheck = ({ target }) => {
  //   if (target.checked) {
  //     const emails = candidates.map((x) => x.performeremail);
  //     setSelectedEmail(emails);
  //   } else {
  //     setSelectedEmail([]);
  //   }
  // };

  const handleAllCheck = ({ target }) => {
    if (target.checked) {
      const selectEmail = candidates.map((x) => { return { key: x.performerid, value: x.performeremail } });
      setSelectedEmail(selectEmail)
    } else {
      setSelectedEmail([])
    }
  };

  const handleFilter = async () => {
    setDataType("filter");
    const frmBody = new FormData();
    frmBody.append("id", userId);
    frmBody.append("name", userName);
    frmBody.append("email", userEmail);
    frmBody.append("type", userType);
    dispatch(loadRegisteredUsersByFilter(frmBody));
  };

  const handleFilterCancel = async () => {
    setDataType("");
    setUserId("");
    setUserName("");
    setUserEmail("");
    setCurrentPage(1);
    setPageSize(5);
    dispatch(loadRegisteredUsers(5, 1, userType))
  };

  const handleRefreshData = () => {
    if (dataType === "filter")
      getPagedData(filterAllUsersSelector, currentPage, pageSize);
    else
      dispatch(loadRegisteredUsers(pageSize, currentPage, userType));
  }

  const loadAllCountryJs = async () => {
    console.log(AllCountries);
    const firstFiftyCountries = AllCountries.slice(200, 250);
    console.log(firstFiftyCountries);
    const countryStates = [];
    var result = await Promise.all(
      firstFiftyCountries.map(async (x) => {
        const st = await geoService.getGeoData(x.geonameId);
        //console.log(st);
        st.map(s => {
          const stObj = { ...s, countryGeonameId: x.geonameId };
          //console.log(stObj);
          countryStates.push(stObj);
        })
        //handleSaveFile(JSON.stringify(countryStates), x.countryName?.replace(/\s+/g, ''));
        //return countryStates;
        return x;
      })
    )

    console.log(result);
    console.log(countryStates);
    handleSaveFile(JSON.stringify(countryStates), "ctstates200_250");

  }

  const handleSaveFile = (data, name) => {
    const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
    file.saveAs(blob, name + ".json");
  };


  return (
    <React.Fragment>
      <div className="col-md-12">
        <div className="card mb-4 shadow-none">
          <div className="card-header border-0 p-2">
            <div className="row">
              <div className="col-md-6">
                <PageSizeOptions
                  pageSize={pageSize}
                  onChange={handlePageSizeChanged}
                ></PageSizeOptions>
              </div>
              <div className="col-md-6 text-right">
                <button
                  className="btn btn-sm btn-outline-default btn-min-width"
                  onClick={loadAllCountryJs}
                >
                  <i className="fa fa-search" aria-hidden="true"></i> Get Country
                </button>
                <button
                  className="btn btn-sm btn-outline-default btn-min-width"
                  data-toggle="collapse"
                  data-target="#collapseExample"
                >
                  <i className="fa fa-search" aria-hidden="true"></i> Search
                </button>
                <button
                  className="btn btn-sm btn-outline-default btn-min-width"
                  onClick={handleModalShowHide}
                >
                  {msgType === "text" ? <><i className="fa fa-paper-plane"></i> Send Bulk Text</> : <><i className="fa fa-paper-plane"></i> Send Bulk Email</>}
                </button>
              </div>
              <div className="col-md-12">
                <div className="collapse p-2 bg-secondary border mt-2" id="collapseExample">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label htmlFor="userId" className="form-control-label">
                          ID
                        </label>
                        <input
                          type="text"
                          name="userId"
                          id="userId"
                          className="form-control"
                          placeholder="ID"
                          value={userId}
                          onChange={({ currentTarget }) => setUserId(currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label
                          htmlFor="userName"
                          className="form-control-label"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          name="userName"
                          id="userName"
                          className="form-control"
                          placeholder="Name"
                          value={userName}
                          onChange={({ currentTarget }) => setUserName(currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label
                          htmlFor="userEmail"
                          className="form-control-label"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          name="userEmail"
                          id="userEmail"
                          className="form-control"
                          placeholder="Email"
                          value={userEmail}
                          onChange={({ currentTarget }) => setUserEmail(currentTarget.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-12 text-center ">
                      <button
                        className="btn-sm btn-outline-default mr-2"
                        onClick={() => handleFilter()}
                      >
                        <i className="fa fa-search mr-2" aria-hidden="true"></i>Filter
                      </button>
                      <button
                        className="btn-sm btn-outline-default mr-2"
                        onClick={handleFilterCancel}
                      >
                        <i className="fa fa-refresh mr-2" aria-hidden="true"></i>Refresh
                      </button>
                      <button
                        className="btn-sm btn-outline-danger mr-2"
                        data-toggle="collapse"
                        data-target="#collapseExample"
                        onClick={handleFilterCancel}
                      >
                        <i className="fa fa-close mr-2" aria-hidden="true"></i>Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-2">
            <div className="table-responsive border">
              <table className="table align-items-center table-flush table-striped">
                <thead className="thead-light">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        name="chkAllSelect"
                        id="chkAllSelect"
                        value={"all"}
                        onChange={handleAllCheck}
                        className="mr-2"
                      />
                      Select All
                    </th>
                    <th>ID</th>
                    <th>Name</th>
                    {msgType === "text" ? <th>Contact</th> : <th>Email</th>}


                    <th>Sent</th>
                    <th>Draft</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {candidates.map((item) => (
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          name="chkSelect"
                          id="chkSelect"
                          onChange={({ target }) => handleCheck(target, item)}
                          checked={selectedEmail.findIndex(x => x.key === item.performerid) > -1}
                        />
                      </td>
                      <td>{item.performerid}</td>
                      <td>
                        <span className="text-uppercase">
                          {item.performername}
                        </span>
                      </td>
                      {msgType === "text" ? <td>
                        {item.contactNo && getFormatedNumber(item.contactNo)}
                      </td> : <td>{item.performeremail}</td>}


                      <td>
                        <button
                          className="btn btn-sm btn-link pr-0"
                          onClick={() => handleShowHideMailHistory(item, "all")}
                        >
                          View
                          <span className="badge badge-pill badge-danger position-absolute ml-1">
                            {item.allMail}
                          </span>
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-link pr-0"
                          onClick={() => handleShowHideMailHistory(item, "draft")}
                        >
                          View
                          <span className="badge badge-pill badge-danger position-absolute ml-1">
                            {item.draftMail}
                          </span>
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-link p-0"
                          onClick={() => handleModalShowHide(item)}
                        >
                          <i className="fa fa-paper-plane"></i> Send
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                pageSize={pageSize}
                itemsCount={rowsCount}
                onPageChange={handlePageChange}
                startIndex={startIndex}
                endIndex={endIndex}
              ></Pagination>
            </div>

          </div>

          <SendEmail
            showHide={showHide}
            handleModalShowHide={handleModalShowHide}
            selectedObj={selectedObj}
            selectedEmail={selectedEmail}
            viewType="add"
            msgType={msgType}
          ></SendEmail>
          <MailHistory
            showHideMailHistory={showHideMailHistory}
            handleShowHideMailHistory={handleShowHideMailHistory}
            selectedObj={selectedObj}
            handleRefreshData={handleRefreshData}
            viewType={viewType}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default UsersEmail;
