import React, { Component } from "react";
import Form from "../../comman/form";
import { Modal, Button } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import Joi from "joi-browser";
import { toast, cssTransition } from "react-toastify";
import ToastTemplate from "../../comman/toastTemplate";
import Swal from "sweetalert2";
import UploadDocument from "../../comman/UploadDocument";
import BprmForm from "../../comman/BprmForm";
import Progress from "../../comman/Progress";

class AddBcChecks extends BprmForm {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      documents: [],
      bcForms: [],
      saveBtnStatus: false,
      workflowTask: {},
      currentUser: {},
      wfForm: "",
      application: {},
      aWorkfinityAppEntity: {},
      encryptionDecryptions: {},
      aWorkfinityApp: {},
      showHide: false,
      formProcess: [],
    };
  }

  schema = {};

  doSubmit1 = async () => {
    const { bcType } = this.props;
    if (bcType === "notify") {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to notify candidate!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saveEditBackgroundCheck();
        } else {
          this.setState({ saveBtnStatus: false });
        }
      });
    } else {
      this.saveEditBackgroundCheck();
    }
  };

  saveEditBackgroundCheck = async () => {
    this.setState({ saveBtnStatus: true });
    const { data:stateData, documents } = this.state;
    const { candidateObj, workItem, bcType } = this.props;
    let data = { ...stateData };
    data.performerId = candidateObj.performerid;
    const user = auth.getCurrentUser();
    data.addedBy = user.id;
    const formData = new FormData();
    formData.append("BackgroundChecks", JSON.stringify(data));
    formData.append("workflowTask", JSON.stringify(workItem));
    formData.append("editType", bcType);
    documents.map((file, key) =>
      formData.append("files[" + key + "]", file.file)
    );
    const { data: result } = await http.post(
      config.AddBackgroundChecks,
      formData
    );
    this.props.handleBcCheckShowHide("save", "");
    if (data["id"] > 0) {
      this.getAlert("", "Saved Successfully", "success");
    } else {
      this.getAlert("", "Saved Successfully", "success");
    }
    this.setState({
      saveBtnStatus: false,
      data: {},
      errors: {},
      documents: [],
    });
  };

  getAlert(title, message, type) {
    return Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: "Close",
      confirmButtonColor: "#d33",
    });
  }

  onFileChange = async (event) => {
    const docs = [...this.state.documents];
    for (const file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        file: file,
        size: file.size,
      };
      docs.push(doc);
    }
    this.setState({ documents: docs });
  };

  convertToBytes = (x) => {
    const units = ["bytes", "KB", "MB"];
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  };

  handleFileRemove = (item) => {
    const index = this.state.documents.indexOf(item);
    let documents = [...this.state.documents];
    if (index !== -1) {
      documents.splice(index, 1);
      this.setState({ documents });
    }
  };

  componentWillReceiveProps = (props) => {
    const { bcType, bcObject, workflowTask, selectedObj, backgroundCheckApp } =
      props;
    if (bcType && (bcType === "edit" || bcType == "notify")) {
      const { data } = this.state;
      data["status"] = bcObject.status;
      //data["notes"] = bcObject.notes;
      data["id"] = bcObject.id;
      this.setState({ data });
      //console.log(data);
    } else {
      this.setState({ data: {}, documents: [] });
    }
    if (backgroundCheckApp?.length > 0) {
      let wfTask = backgroundCheckApp[0].workflowTasks[0];
      if (wfTask) this.loadForms(wfTask, selectedObj);
    }
  };

  loadForms = async (workItem, selectedObj) => {
    //const { selectedObj } = this.props;
    const { data: wfForm } = await http.get(config.GetForm + workItem.formId);
    const { data: application } = await http.get(
      config.GetApplication + workItem.ciid
    );
    const aWorkfinityApp = JSON.parse(application.applicationData);
    const appShortName = Object.keys(aWorkfinityApp)[1];
    const aWorkfinityAppEntity = aWorkfinityApp[appShortName];
    const { data } = this.state;
    Object.keys(aWorkfinityAppEntity).forEach(function (keyValue) {
      if (
        keyValue !== "@xmlns:xsd" &&
        keyValue !== "@xmlns:xsi" &&
        keyValue !== "EntityId"
      ) {
        Object.keys(aWorkfinityAppEntity[keyValue]).forEach(function (
          keyValue1
        ) {
          if (keyValue1 !== "EntityId") {
            var item = aWorkfinityAppEntity[keyValue][keyValue1];
            if (typeof item === "object") {
              if (Array.isArray(item))
                data[keyValue + "." + keyValue1.replace("@", "")] = item;
              else data[keyValue + "." + keyValue1.replace("@", "")] = [item];
            } else {
              data[keyValue + "." + keyValue1.replace("@", "")] = item;
            }
          }
        });
      }
    });
    //console.log(data);
    const user = auth.getCurrentUser();
    data["backgroundcheck.User_Id"] = user.id;
    // data["compliance.Name"] = selectedObj.name;
    // data["compliance.Year"] = selectedObj.year;
    // data["compliance.Total_Invoice_Amt"] = selectedObj.totalInvoiceAmount;
    // data["compliance.Total_Paid_Amt"] = selectedObj.totalPaidAmount;
    // data["compliance.Total_Balance_Amt"] = selectedObj.totalDueAmount;
    this.setState({
      aWorkfinityAppEntity,
      aWorkfinityApp,
      application,
      wfForm,
      workItem,
      showHide: false,
      data,
    });
  };

  async doSubmit(btn) {
    //console.log(btn);
    const btnType = btn.name;
    //console.log(btnType);
    const processId = btn.dataset.process;
    if (processId !== undefined && processId > 0) {
      const { data: formProcess } = await http.get(
        config.GetFormProcessItems + processId
      );
      const confMsg = formProcess.filter((x) => x.isConfirmMessage === true);
      if (confMsg.length > 0) {
        const filterProcess = formProcess.filter(
          (x) => x.isConfirmMessage === false || x.isConfirmMessage === null
        );
        this.confirmMessage(
          processId,
          filterProcess,
          btnType,
          confMsg[0].processItem
        );
      } else {
        if (btnType !== "home" && btnType !== "checkStatus")
          this.setState({ showHide: true, processId, formProcess });
        else this.setState({ processId, formProcess });
        this.handleConfirm(btnType);
      }
    } else {
      if (btnType !== "home" && btnType !== "checkStatus")
        this.setState({ showHide: true, processId });
      else this.setState({ processId });
      this.handleConfirm(btnType);
    }
  }

  confirmMessage = (processId, formProcess, btnType, confMsg) => {
    return Swal.fire({
      title: "Are you sure?",
      text: confMsg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "All data correct. Continue",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ showHide: true, processId, formProcess });
        this.handleConfirm(btnType);
      }
    });
  };

  handleConfirm = async (btnType) => {
    const { aWorkfinityAppEntity, data, aWorkfinityApp, workItem, documents } =
      this.state;
    Object.keys(data).forEach(function (keyValue) {
      const dd = keyValue.split(".");
      if (dd.length > 1) {
        if (dd[1] !== "undefined" && aWorkfinityAppEntity !== undefined)
          if (aWorkfinityAppEntity[dd[0].toLowerCase()] !== undefined) {
            var item = data[keyValue];
            if (Array.isArray(item))
              aWorkfinityAppEntity[dd[0].toLowerCase()][dd[1]] = item;
            else aWorkfinityAppEntity[dd[0].toLowerCase()]["@" + dd[1]] = item;
          }
      }
    });
    const appShortName = Object.keys(aWorkfinityApp)[1];
    aWorkfinityApp[appShortName] = aWorkfinityAppEntity;
    const formData = new FormData();
    formData.append("appEntity", JSON.stringify(aWorkfinityApp));
    formData.append("workItem", JSON.stringify(workItem));
    documents.map((file, key) =>
      formData.append("files[" + key + "]", file.file)
    );
    formData.append("docType", "Background Check");
    formData.append("updType", "Background Check");
    formData.append("message", "Background Check Document");
    const { data: appEntity } = await http.post(config.SaveFormData, formData);
    if (btnType === "save") {
      const { data: nextWorkItem } = await http.post(
        config.SetWorkItemToDone,
        workItem
      );

      if (btnType === "saveAndExit") {
        this.setState({ showHide: false });
        this.setState({ wfForm: "" });
      } else if (btnType === "home" || btnType === "checkStatus") {
        this.setState({ wfForm: "" });
      } else {
        if (nextWorkItem.formId === null) {
          this.handleCurrentTask(workItem, 1);
        } else {
          this.state.showHide && this.handleModalShowHide();
          this.props.handleBcCheckShowHide("save", "");
        }
      }
    }
  };

  handleCurrentTask = (workItem, count) => {
    setTimeout(async () => {
      const { data: nextWorkItem } = await http.post(
        config.GetCurrentWorkflowTask,
        workItem
      );
      if (nextWorkItem.formId === null) {
        if (count === 2) {
          this.state.showHide && this.handleModalShowHide();
          this.setState({ wfForm: "" });
          this.props.handleBcCheckShowHide("save", "");
        } else {
          this.handleCurrentTask(workItem, count + 1);
        }
      } else {
        this.state.showHide && this.handleModalShowHide();
        this.props.handleBcCheckShowHide("save", "");
      }
    }, 3000);
  };

  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  handleNewOnchange = ({ currentTarget: input }) => {
    const { data } = this.state;
    data[input.name] = input.value;
    this.setState({ data });
  };

  render() {
    const { data, documents, saveBtnStatus, wfForm, showHide, formProcess } =
      this.state;
    const { bcCheckShowHide, handleBcCheckShowHide, bcType } = this.props;
    //console.log(selectedObj);
    const elements = //htmlJsonString;
      wfForm !== "" &&
      wfForm.elementJson !== undefined &&
      JSON.parse(wfForm.elementJson);
      
    return (
      <React.Fragment>
        <Modal show={bcCheckShowHide} size="lg">
          <Modal.Header
            closeButton
            onClick={() => handleBcCheckShowHide("", "")}
          >
            <Modal.Title>
              {bcType === "" && "Add Background Check"}
              {bcType === "edit" && "Internal Notes"}
              {bcType === "notify" && "Notify Candidate"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-secondary">
            <div className="row">
              {bcType === "" && (
                <div className="col-md-12">
                  <div className="border row m-1 pt-3 pb-3 bg-white">
                    {elements &&
                      elements.map((col) => (
                        <div
                          className={
                            col.cssclass !== undefined
                              ? `col-${col.width} ` + col.cssclass
                              : `col-${col.width}`
                          }
                        >
                          {col.components.map((comp) => {
                            if (
                              comp.dataType === "button" &&
                              comp.buttonType !== undefined
                            ) {
                              if (
                                comp.buttonType.type !== undefined &&
                                (comp.buttonType.type === "editForm" ||
                                  comp.buttonType.type === "subform")
                              ) {
                                return this.rendorHtmlComponent(comp);
                              }
                            } else {
                              return this.rendorHtmlComponent(comp);
                            }
                          })}
                        </div>
                      ))}
                    <div className="col-md-12 text-center mt-2">
                      {elements &&
                        elements.map((col) =>
                          col.components.map((comp) => {
                            if (
                              comp.dataType === "button" &&
                              comp.buttonType !== undefined
                            ) {
                              if (
                                comp.buttonType.type !== undefined &&
                                comp.buttonType.type !== "editForm" &&
                                comp.buttonType.type !== "subform"
                              ) {
                                return this.rendorHtmlComponent(comp);
                              }
                            }
                          })
                        )}
                    </div>
                  </div>
                </div>
              )}
              {/* {bcType === "" && (
                <React.Fragment>
                  <div className="col-md-12 form-group">
                    {this.renderInput("name", "Background Check Name")}
                    <label htmlFor="" className="form-control-label">
                      Background Check Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      value={data["name"]}
                      placeholder="Background Check Name"
                      onChange={this.handleNewOnchange}
                    />
                  </div>
                  <div className="col-md-12">
                    {this.renderTextArea("notes", "Notes")}
                    <label htmlFor="" className="form-control-label">
                      Nots
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="notes"
                      id="notes"
                      value={data["notes"]}
                      placeholder="Notes"
                      onChange={this.handleNewOnchange}
                    />
                  </div>
                </React.Fragment>
              )} */}
              {bcType === "edit" && (
                <div className="col-md-12 form-group">
                  {/* {this.renderTextArea("notes", "Internal Notes")} */}
                  <label htmlFor="" className="form-control-label">
                    Notes
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="notes"
                    id="notes"
                    value={data["notes"]}
                    placeholder="Notes"
                    onChange={this.handleNewOnchange}
                  />
                </div>
              )}
              {bcType === "notify" && (
                <div className="col-md-12 form-group">
                  {/* {this.renderTextArea("notes", "Notify Notes")} */}
                  <label htmlFor="" className="form-control-label">
                    Notes
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="notes"
                    id="notes"
                    value={data["notes"]}
                    placeholder="Notes"
                    onChange={this.handleNewOnchange}
                  />
                </div>
              )}
              {bcType === "edit" && (
                <React.Fragment>
                  <div className="col-md-12 form-group form-inline">
                    <div className="custom-control custom-radio mb-3">
                      <input
                        name="status"
                        className="custom-control-input"
                        id="activate"
                        type="radio"
                        value="true"
                        defaultChecked={data["status"] === true}
                        onChange={this.handleNewOnchange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="activate"
                      >
                        Pass
                      </label>
                    </div>
                    <div className="custom-control custom-radio mb-3 ml-3">
                      <input
                        name="status"
                        className="custom-control-input"
                        id="deactivate"
                        value="false"
                        type="radio"
                        defaultChecked={data["status"] === false}
                        onChange={this.handleNewOnchange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="deactivate"
                      >
                        Fail
                      </label>
                    </div>
                  </div>
                </React.Fragment>
              )}

              {(bcType === "edit" || bcType === "notify") && (
                <React.Fragment>
                  <div className="col-md-12">
                    <UploadDocument
                      documents={documents}
                      handleFileRemove={this.handleFileRemove}
                      onFileChange={this.onFileChange}
                    ></UploadDocument>
                  </div>
                </React.Fragment>
              )}
            </div>
          </Modal.Body>
          {bcType !== "" && (
            <Modal.Footer>
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => handleBcCheckShowHide("", "")}
              >
                <i className="fa fa-times mr-1"></i>Close
              </Button>
              {bcType === "" ? (
                <Button
                  variant="outline-dark"
                  size="sm"
                  disabled={saveBtnStatus || this.validate()}
                  //disabled={saveBtnStatus}
                  onClick={this.handleSubmit}
                >
                  {saveBtnStatus ? (
                    <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
                  ) : <i className="fa fa-check mr-1"></i>}
                  Save
                </Button>
              ) : (
                <Button
                  variant="outline-dark"
                  size="sm"
                  //disabled={saveBtnStatus || this.validate()}
                  disabled={saveBtnStatus}
                  onClick={this.doSubmit1}
                >
                  {saveBtnStatus ? (
                    <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
                  ) : <i className="fa fa-check mr-1"></i>}
                  Save
                </Button>
              )}
            </Modal.Footer>
          )}
        </Modal>
        <Progress
          showHide={showHide}
          handleModalShowHide={this.handleModalShowHide}
          formProcess={formProcess}
          processId="1"
        ></Progress>
      </React.Fragment>
    );
  }
}

export default AddBcChecks;
