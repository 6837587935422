import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  Row,
  Col,
  ButtonGroup,
  Button,
  Fade,
  Collapse,
  OverlayTrigger,
  Tooltip,
  ListGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import auth from "../../../services/authService";
import http from "../../../services/HttpService";
import config from '../../../config.json'

function FillExample() {
  const user = auth.getCurrentUser();
  const [selectedKey, setSelectedKey] = useState("0");
  const [rolePermission, setRolePermission] = useState([]);
  const [currentUser, setCurrentUser] = useState("");

  const handleSelect = (id) => {
    if (id === selectedKey) setSelectedKey("");
    else setSelectedKey(id);
  };

  const fetchData = async () => {
    if (user === null || user === undefined) window.location = "/AdminLogin";
    let currentUser = "";

    if (user.type === "admin")
      currentUser = await auth.getAdminDetails(user.id);
    else currentUser = await auth.getCurrentUserDetails(user.id);

    if (user.type !== "admin") {
      let roleType = "main";
      let roleId = currentUser.roleId;
      if (currentUser?.registrationType === "PP") {
        roleType = "partner";
        roleId = currentUser.partnerid;
      }
      if (currentUser?.teamID > 0) {
        roleType = "team";
        roleId = currentUser.teamID;
      }
      const { data: permission } = await http.get(
        config.GetRolePermission + roleId + "&roleSource=" + roleType
      );

      setRolePermission(permission)
      setCurrentUser(currentUser)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const hasCheckedMenus = (menu) => {
    if (user.type === "admin") return true
    if (menu?.isChecked) return true;
    if (menu?.menus) {
      return menu?.menus.some((childMenu) => hasCheckedMenus(childMenu));
    }
    return false;
  };

  const handleDeslect = (id) => {
    console.log(id);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Simple tooltip
    </Tooltip>
  );

  const tbCss = {
    zIndex: 105,
    backgroundColor: "#f9f9f9",
    minHeight: "5rem",
    border: "1px solid #f0f0f0"
  };
  console.log(rolePermission, 'permission')

  return (
    <div className="w-100">
      <Tabs
        id="fill-tab-example"
        className="h4 p-2"
        style={{ backgroundColor: "#92a2bc99", border: "0px solid", borderBottomRightRadius: "50px", }}
        onSelect={handleSelect}
        activeKey={selectedKey}
      >
        <Tab
          eventKey="1"
          tabClassName={`menu-tab ${!hasCheckedMenus(rolePermission[0]) ? "d-none" : ""}`}
          title={
            <>
              SYSTEM ADMINISTRATION{" "}
              <i
                className={`fa fa-chevron-${selectedKey === "1" ? "down" : "up"
                  }`}
                aria-hidden="true"
              ></i>
            </>
          }
        >
          <Tab.Content
            className="p-3 w-100 font-weight-bold"
            style={tbCss}
          >
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#1_1">
              <Row>
                <Col md={3}>
                  <ListGroup className="border-0" as="ul">
                    <ListGroup.Item
                      action
                      href="#1_1"
                      as="li"
                      className={`pl-1 pb-2 pt-2 bg-transparent h4 menuItem ${!hasCheckedMenus(rolePermission[0]?.menus[0]) ? "d-none" : ""}`}
                      style={{
                        borderBottom: "2px solid #009dd929",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    >
                      <i className='fa fa-angle-double-right vs'></i> Authorized Users
                    </ListGroup.Item>

                  </ListGroup>
                </Col>
                <Col className="d-flex align-items-center p-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="#1_1">
                      <Link
                        to="/WorkflowUser"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default btn-min-width ${!hasCheckedMenus(rolePermission[0]?.menus[0]?.menus[0]) ? "d-none" : ""}`}

                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Main Users
                      </Link>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <ListGroup className="border-0" as="ul">
                    <ListGroup.Item
                      action
                      href="#1_2"
                      as="li"
                      className={`pl-1 pb-2 pt-2 bg-transparent h4 menuItem ${!hasCheckedMenus(rolePermission[0]?.menus[1]) ? "d-none" : ""}`}
                      style={{
                        borderBottom: "2px solid #009dd929",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    >
                      <i className='fa fa-angle-double-right vs'></i> Manage Entities
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col className="d-flex align-items-center p-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="#1_2">
                      <Link
                        to="/ListSubcontractor/partner"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[0]?.menus[1]?.menus[0]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Subcontractors
                      </Link>
                      <Link
                        to="/Recruiter/partner"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[0]?.menus[1]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Recruiters
                      </Link>
                      <Link
                        to="/ListCondidate/partner"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[0]?.menus[1]?.menus[2]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Candidates
                      </Link>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Tab.Content>
        </Tab>
        <Tab
          eventKey="2"
          tabClassName={`menu-tab ${!hasCheckedMenus(rolePermission[1]) ? "d-none" : ""}`}
          title={
            <>
              ONBOARDING AND CONTRACTS{" "}
              <i
                className={`fa fa-chevron-${selectedKey === "2" ? "down" : "up"
                  }`}
                aria-hidden="true"
              ></i>
            </>
          }
        >
          <Tab.Content
            className="p-3 w-100 font-weight-bold"
            style={tbCss}
          >
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#2_1">
              <Row>
                <Col md={3}>
                  <ListGroup className="border-0" as="ul">
                    <ListGroup.Item
                      action
                      href="#2_1"
                      as="li"
                      className={`pl-1 pb-2 pt-2 bg-transparent h4 menuItem ${!hasCheckedMenus(rolePermission[1]?.menus[0]) ? "d-none" : ""}`}
                      style={{
                        borderBottom: "2px solid #009dd929",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    >
                      <i className='fa fa-angle-double-right vs'></i> Subcontractors
                    </ListGroup.Item>

                  </ListGroup>
                </Col>
                <Col className="d-flex align-items-center p-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="#2_1">
                      <Link
                        to="ListSubcontractor/application"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default btn-min-width ${!hasCheckedMenus(rolePermission[1]?.menus[0]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Subcontractors
                      </Link>
                    </Tab.Pane>

                  </Tab.Content>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <ListGroup className="border-0" as="ul">
                    <ListGroup.Item
                      action
                      href="#2_2"
                      as="li"
                      className={`pl-1 pb-2 pt-2 bg-transparent h4 menuItem ${!hasCheckedMenus(rolePermission[1]?.menus[1]) ? "d-none" : ""}`}
                      style={{
                        borderBottom: "2px solid #009dd929",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    >
                      <i className='fa fa-angle-double-right vs'></i> Recruiters
                    </ListGroup.Item>

                  </ListGroup>
                </Col>
                <Col className="d-flex align-items-center p-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="#2_2">
                      <Link
                        to="Recruiter/application"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default btn-min-width ${!hasCheckedMenus(rolePermission[1]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Recruiters
                      </Link>
                    </Tab.Pane>

                  </Tab.Content>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <ListGroup className="border-0" as="ul">
                    <ListGroup.Item
                      action
                      href="#2_3"
                      as="li"
                      className={`pl-1 pb-2 pt-2 bg-transparent h4 menuItem ${!hasCheckedMenus(rolePermission[1]?.menus[2]) ? "d-none" : ""}`}
                      style={{
                        borderBottom: "2px solid #009dd929",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    >
                      <i className='fa fa-angle-double-right vs'></i> Candidates
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col className="d-flex align-items-center p-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="#2_3">
                      <Link
                        to="ListCondidate/application"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[1]?.menus[2]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        All
                      </Link>
                      <Link
                        to="ListCondidate/application-c2c"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[1]?.menus[2]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        C2C Basis
                      </Link>
                      <Link
                        to="ListCondidate/application-w2"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[1]?.menus[3]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        W2 Basis
                      </Link>
                      <Link
                        to="ListCondidate/application-1099"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[1]?.menus[2]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        1099 Basis
                      </Link>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Tab.Content>
        </Tab>
        <Tab
          eventKey="3"
          tabClassName={`menu-tab ${!hasCheckedMenus(rolePermission[2]) ? "d-none" : ""}`}
          title={
            <>
              INVOICES{" "}
              <i
                className={`fa fa-chevron-${selectedKey === "3" ? "down" : "up"
                  }`}
                aria-hidden="true"
              ></i>
            </>
          }
        >
          <Tab.Content
            className="p-3 w-100 font-weight-bold"
            style={tbCss}
          >
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#3_1">
              <Row>
                <Col md={4}>
                  <ListGroup className="border-0" as="ul">
                    <ListGroup.Item
                      action
                      href="#3_1"
                      as="li"
                      className={`pl-1 pb-2 pt-2 bg-transparent h4 menuItem ${!hasCheckedMenus(rolePermission[2]?.menus[0]) ? "d-none" : ""}`}
                      style={{
                        borderBottom: "2px solid #009dd929",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    >
                      <i className='fa fa-angle-double-right vs'></i> Time Log Management
                    </ListGroup.Item>

                  </ListGroup>
                </Col>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey="#3_1">
                      <Link
                        to="/ListCondidate/timelog"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[2]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        All
                      </Link>
                      <Link
                        to="/ListCondidate/timelog-c2c"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[2]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        C2C Basis
                      </Link>
                      <Link
                        to="/ListCondidate/timelog-w2"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[2]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        W2 Basis
                      </Link>
                      <Link
                        to="/ListCondidate/timelog-1099"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[2]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        1099 Basis
                      </Link>
                    </Tab.Pane>

                  </Tab.Content>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <ListGroup className="border-0" as="ul">
                    <ListGroup.Item
                      action
                      href="#3_2"
                      as="li"
                      className={`pl-1 pb-2 pt-2 bg-transparent h4 menuItem ${!hasCheckedMenus(rolePermission[2]?.menus[1]) ? "d-none" : ""}`}
                      style={{
                        borderBottom: "2px solid #009dd929",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    >
                      <i className='fa fa-angle-double-right vs'></i> Invoices and Payments
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col className="d-flex align-items-center p-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="#3_2">
                      <Link
                        to="/ListCondidate/invoice"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[2]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        All
                      </Link>
                      <Link
                        to="/ListCondidate/invoice-c2c"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[2]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        C2C Basis
                      </Link>
                      <Link
                        to="/ListCondidate/invoice-w2"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[2]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        W2 Basis
                      </Link>
                      <Link
                        to="/ListCondidate/invoice-1099"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[2]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        1099 Basis
                      </Link>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Tab.Content>
        </Tab>
        <Tab
          eventKey="4"
          tabClassName={`menu-tab ${!hasCheckedMenus(rolePermission[3]) ? "d-none" : ""}`}
          title={
            <>
              ANALYTICS{" "}
              <i
                className={`fa fa-chevron-${selectedKey === "4" ? "down" : "up"
                  }`}
                aria-hidden="true"
              ></i>
            </>
          }
        >
          <Tab.Content
            className="p-3 w-100 font-weight-bold"
            style={tbCss}
          >
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#4_1">
              <Row>
                <Col md={3}>
                  <ListGroup className="border-0" as="ul">
                    <ListGroup.Item
                      action
                      href="#4_1"
                      as="li"
                      className={`pl-1 pb-2 pt-2 bg-transparent h4 menuItem `}
                      style={{
                        borderBottom: "2px solid #009dd929",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    >
                      <i className='fa fa-angle-double-right vs'></i> Dashboard
                    </ListGroup.Item>


                  </ListGroup>
                </Col>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey="#4_1">
                      <Link
                        to="Dashboard"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[3]?.menus[0]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Dashboard
                      </Link>
                      <Link
                        to="ApplicationReport"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[3]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Entity Data
                      </Link>
                    </Tab.Pane>


                  </Tab.Content>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <ListGroup className="border-0" as="ul">
                    <ListGroup.Item
                      action
                      href="#4_2"
                      as="li"
                      className={`pl-1 pb-2 pt-2 bg-transparent h4 menuItem `}
                      style={{
                        borderBottom: "2px solid #009dd929",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    >
                      <i className='fa fa-angle-double-right vs'></i> Reports
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col className="d-flex align-items-center p-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="#4_2">
                      <Link
                        to="CustomReport/report"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[3]?.menus[2]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Business
                      </Link>
                      <Link
                        to="CustomReport/compliance"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[3]?.menus[3]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Compliance
                      </Link>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Tab.Content>
        </Tab>
        <Tab
          eventKey="5"
          tabClassName={`menu-tab ${!hasCheckedMenus(rolePermission[4]) ? "d-none" : ""}`}
          title={
            <>
              SECURITY{" "}
              <i
                className={`fa fa-chevron-${selectedKey === "5" ? "down" : "up"
                  }`}
                aria-hidden="true"
              ></i>
            </>
          }
        >
          <Tab.Content
            className="p-3 w-100 font-weight-bold"
            style={tbCss}
          >
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#5_1">
              <Row>
                <Col md={3}>
                  <ListGroup className="border-0" as="ul">
                    <ListGroup.Item
                      action
                      href="#5_1"
                      as="li"
                      className={`pl-1 pb-2 pt-2 bg-transparent h4 menuItem ${!hasCheckedMenus(rolePermission[4]?.menus[0]) ? "d-none" : ""}`}
                      style={{
                        borderBottom: "2px solid #009dd929",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    >
                      <i className='fa fa-angle-double-right vs'></i> Monitoring Logs
                    </ListGroup.Item>

                  </ListGroup>
                </Col>
                <Col className="d-flex align-items-center p-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="#5_1">
                      <Link
                        to="MonitoringLogs"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default btn-min-width ${!hasCheckedMenus(rolePermission[4]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Logs
                      </Link>

                    </Tab.Pane>

                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Tab.Content>
        </Tab>
        <Tab
          eventKey="6"
          tabClassName={`menu-tab ${!hasCheckedMenus(rolePermission[5]) ? "d-none" : ""}`}
          title={
            <>
              COMMUNICATIONS{" "}
              <i
                className={`fa fa-chevron-${selectedKey === "6" ? "down" : "up"
                  }`}
                aria-hidden="true"
              ></i>
            </>
          }
        >
          <Tab.Content
            className="p-3 w-100 font-weight-bold"
            style={tbCss}
          >
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#6_1">
              <Row>
                <Col md={3}>
                  <ListGroup className="border-0" as="ul">
                    <ListGroup.Item
                      action
                      href="#6_1"
                      as="li"
                      className={`pl-1 pb-2 pt-2 bg-transparent h4 menuItem ${!hasCheckedMenus(rolePermission[5]?.menus[0]) ? "d-none" : ""}`}
                      style={{
                        borderBottom: "2px solid #009dd929",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    >
                      <i className='fa fa-angle-double-right vs'></i> Emails
                    </ListGroup.Item>


                  </ListGroup>
                </Col>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey="#6_1">
                      <Link
                        to="PartnerEmails/mail"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[5]?.menus[0]?.menus[0]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Subcontractors
                      </Link>
                      <Link
                        to="AgencyEmails/mail"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[5]?.menus[0]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Recruiters
                      </Link>
                      <Link
                        to="ApplicantEmails/mail"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[5]?.menus[0]?.menus[2]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Candidates
                      </Link>
                    </Tab.Pane>


                  </Tab.Content>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <ListGroup className="border-0" as="ul">
                    <ListGroup.Item
                      action
                      href="#6_2"
                      as="li"
                      className={`pl-1 pb-2 pt-2 bg-transparent h4 menuItem ${!hasCheckedMenus(rolePermission[5]?.menus[1]) ? "d-none" : ""}`}
                      style={{
                        borderBottom: "2px solid #009dd929",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    >
                      <i className='fa fa-angle-double-right vs'></i> Text Messages
                    </ListGroup.Item>

                  </ListGroup>
                </Col>
                <Col className="d-flex align-items-center p-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="#6_2">
                      <Link
                        to="PartnerEmails/text"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[5]?.menus[1]?.menus[0]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Subcontractors
                      </Link>
                      <Link
                        to="AgencyEmails/text"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[5]?.menus[1]?.menus[1]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Recruiters
                      </Link>
                      <Link
                        to="ApplicantEmails/text"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default mr-2 btn-min-width ${!hasCheckedMenus(rolePermission[5]?.menus[1]?.menus[2]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Candidates
                      </Link>
                    </Tab.Pane>

                  </Tab.Content>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <ListGroup className="border-0" as="ul">
                    <ListGroup.Item
                      action
                      href="#6_3"
                      as="li"
                      className={`pl-1 pb-2 pt-2 bg-transparent h4 menuItem ${!hasCheckedMenus(rolePermission[5]?.menus[2]) ? "d-none" : ""}`}
                      style={{
                        borderBottom: "2px solid #009dd929",
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    >
                      <i className='fa fa-angle-double-right vs'></i> Surveys
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col className="d-flex align-items-center p-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="#6_3">
                      <Link
                        to="SurveyFeedback"
                        variant="primary"
                        size="md"
                        className={`btn btn-sm btn-outline-default btn-min-width ${!hasCheckedMenus(rolePermission[5]?.menus[2]?.menus[0]) ? "d-none" : ""}`}
                      >
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{" "}
                        Surveys
                      </Link>

                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Tab.Content>
        </Tab>
      </Tabs>
    </div>
  );
}

export default FillExample;
