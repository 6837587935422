import React, { Component, createRef } from "react";
import { Modal, Button } from "react-bootstrap";
import http from "../../services/HttpService";
import config from "../../config.json";
import { Link } from "react-router-dom";
import UploadDocument from "../../comman/UploadDocument";

class MsgWithApplicantPopup extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = createRef();
    this.state = {
      message: "",
      documents: [],
      templates: [],
      templateId: "",
      messageTypes: [
        { id: 1, name: "Enrollment/Onboarding" },
        { id: 2, name: "ACH Information" },
        { id: 3, name: "Insurane Information" },
        { id: 4, name: "Contract" },
        { id: 5, name: "Purchase Orders" },
        { id: 6, name: "Documents" },
        { id: 7, name: "Invoices" },
        { id: 8, name: "Engagement/Termination" },
        { id: 9, name: "Contract Termination" },
        { id: 10, name: "Engagement Extention" },
        { id: 11, name: "Payments" },
        { id: 12, name: "Others" },
      ],
      msgType: "",
      cannedMessages: [],
    };
  }

  async componentDidMount() {
    // const { data: cannedMessages } = await http.get(
    //   config.GetCodemanager + "Canned Message"
    // );
    // this.setState({ cannedMessages });
  }

  // messgeOnFileChange = (event) => {
  //   const docs = [...this.state.documents];
  //   for (const file of event.target.files) {
  //     var doc = {
  //       name: file.name,
  //       type: file.type,
  //       file: file,
  //       size: file.size
  //     };
  //     docs.push(doc);
  //   }
  //   this.setState({ documents: docs });
  // };

  messgeOnFileChange = (event) => {
    const docs = [...this.state.documents];
    for (const file of event.target.files) {
      const doc = {
        name: file.name,
        type: file.type,
        file: file, // Store the actual file
        size: (file.size / 1024).toFixed(2), // File size in KB
      };
      docs.push(doc);
    }
    this.setState({ documents: docs });
  };

  // handleFileRemove = (item) => {
  //   const index = this.state.documents.indexOf(item);
  //   let documents = [...this.state.documents];
  //   if (index !== -1) {
  //     documents.splice(index, 1);
  //     this.setState({ documents });
  //   }
  // };

  handleFileRemove = (file) => {
    const documents = this.state.documents.filter(
      (doc) => doc.name !== file.name
    );
    this.setState({ documents });
  };

  handleSubmit = () => {
    this.props.handleAddNewMessage(
      this.state.message,
      this.state.documents,
      this.state.msgType
    );
    this.setState({ message: "", documents: [] })
  };

  handleTemplateChange = ({ currentTarget: select }) => {
    this.setState({ templateId: select.value });
  };

  handleCannedMessage = ({ currentTarget }) => {
    this.setState({ message: currentTarget.value });
  };

  render() {
    const { documents } = this.state;
    return (
      <>
        <div className="row">
          {documents.length > 0 && (
            <div className="file-preview">
              {documents.map((file, index) => (
                <div key={index} className="file-preview-item ml-2">
                  <span>{file.name}</span>
                  <span
                    className="file-size"
                    style={{
                      backgroundColor: "#f0f0f0",
                      marginLeft: "10px",
                      padding: "2px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    {file.size} KB
                  </span>
                  <button
                    className="btn btn-sm btn-link"
                    onClick={() => this.handleFileRemove(file)}
                  >
                    <i className="fa fa-times text-red" aria-hidden="true"></i>
                  </button>
                </div>
              ))}
            </div>
          )}
          <div className="col-md-12 form-group">
            <label htmlFor="message" className="form-control-label">
              Message
            </label>
            <textarea
              name="message"
              id="message"
              cols="5"
              rows="3"
              className="form-control"
              value={this.state.message}
              onChange={({ currentTarget }) =>
                this.setState({ message: currentTarget.value })
              }
            ></textarea>
          </div>
          <div className="col-12 text-center mt-3 d-flex justify-content-center">
            <Button
              variant="outline-dark"
              size="sm"
              onClick={() => this.fileInputRef.current.click()}
            >
              <i className="fa fa-paperclip mr-2" aria-hidden="true"></i>
              Add
            </Button>

            <Button variant="outline-dark" size="sm" onClick={this.handleSubmit}>
              <i className="fa fa-paper-plane-o mr-2" aria-hidden="true"></i>
              Send
            </Button>
          </div>
          <input
            type="file"
            ref={this.fileInputRef}
            onChange={this.messgeOnFileChange}
            multiple
            style={{ display: "none" }}
          />
        </div>

      </>
    );
  }
}

export default MsgWithApplicantPopup;
