import React, { Component } from 'react';

class LocationDetails extends Component {
    render() {
        return (
            <React.Fragment>
              <div
                className="modal fade"
                id="modal-location-details"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="modal-location-details"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content" size="xl">
                    <div className="modal-header">
                      <h6 className="modal-title" id="modal-title-notification">
                      Location Details
                      </h6>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body bg-secondary">
                      <div className="row">
                        <div className="col-lg-12">
                         <table className="table table-bordered align-items-center table-flush">
                             <tr>
                                 <td>City</td>
                                 <td>Delhi</td>
                             </tr>
                             <tr>
                                 <td>Region</td>
                                 <td>Delhi</td>
                             </tr>
                             <tr>
                                 <td>Postal Code</td>
                                 <td>110001</td>
                             </tr>
                             <tr>
                                 <td>Cordinates</td>
                                 <td>28.6519,77.2315</td>
                             </tr>
                             <tr>
                                 <td>Timezone</td>
                                 <td>Asia/Kolkata</td>
                             </tr>
                             <tr>
                                 <td>Local Time</td>
                                 <td></td>
                             </tr>
                             <tr>
                                 <td>Country</td>
                                 <td>India</td>
                             </tr>
                         </table>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-danger"
                        data-dismiss="modal"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i> Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
    }
}

export default LocationDetails;