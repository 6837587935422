import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { getTenNintyComplianceReports, loadTenNintyComplianceReports } from "../../../store/complianceReports";
import PageSizeOptions from "../../../../components/PageSizeOptions";
import Pagination from "../../../../comman/pagination";
import { getAllYears } from "../../../../services/appService";
import { calculateIndex, paginate } from "../../../../utlis/paginate";
import WorkflowMapPopup from "../../../../components/WorkflowMapPopup";
import AddComplaince from "../../../../components/AddComplaince";

const Ten99CandidateComp = () => {

  const years = getAllYears();

  const [year, setYear] = useState("");
  const [complianceReport, setComplianceReport] = useState([]);
  const [showHideCompliance, setShowHideCompliance] = useState(false);
  const [complianceType, setComplianceType] = useState("");
  const [selectedObj, setSelectedObj] = useState(null);

  const [workflowShowHide, setWorkflowShowHide] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);


  const tenNintyCandidateComplianceReportsSelector = useSelector(getTenNintyComplianceReports);

  const dispatch = useDispatch();

  useEffect(() => {
    setComplianceReport(tenNintyCandidateComplianceReportsSelector);
  }, [tenNintyCandidateComplianceReportsSelector])


  const handle1099CandidateCompliance = async () => {
    dispatch(loadTenNintyComplianceReports(year))
  };

  const handleShowHideCompliance = (type, obj) => {
    if (obj === "save") {
      setShowHideCompliance(false);
      setComplianceType("");
      setSelectedObj(null);
      if (year > 0)
        dispatch(loadTenNintyComplianceReports(year));
    } else {
      setShowHideCompliance(!showHideCompliance);
      setComplianceType(type);
      setSelectedObj(obj);
    }
  };

  const handlePageSizeChanged = ({ currentTarget }) => {
    setPageSize(currentTarget.value);
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(5);
  };

  const handlePageChange = (page) => {
    const { startIndex: start, endIndex: end } = calculateIndex(page, startIndex, endIndex);
    setCurrentPage(page);
    setStartIndex(start);
    setEndIndex(end);
  };

  const getPagedData = () => {
    const total = complianceReport.length;
    const filterData = paginate(complianceReport, currentPage, pageSize);
    return { totalCount: total, data: filterData };
  };


  const { data: tenNintyCompliance } = getPagedData();

  const handleWorkflowModalShowHide = () => {
    setWorkflowShowHide(!workflowShowHide);
};

  return <>
    <div className="card shadow-none border mb-2">
      <div className="card-header text-center p-3 bg-lighter">
        <h4 className="mb-0">1099 Candidates</h4>
      </div>
      <div className="card-body p-3">
        <div className="row">
          <div className="col-md-6 form-group">

            <select
              name="year"
              id="year"
              className="form-control form-control-sm"
              onChange={({ currentTarget }) => setYear(currentTarget.value)}
              value={year}
            >
              <option value="">Year</option>
              {years.map((y) => (
                <option value={y.name}>
                  {y.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6">
            <button
              className="btn btn-outline-default btn-sm"
              onClick={handle1099CandidateCompliance}
            >
              Generate
            </button>
          
          </div>
          <div className="col-md-12">
            <PageSizeOptions
              pageSize={pageSize}
              onChange={handlePageSizeChanged}
            ></PageSizeOptions>
            <div className="table-responsive mt-2">
              <table
                className="table align-items-center table-flush table-hover table-striped mb-2 border"
                id="table-to-xls-7"
              >
                <thead className="thead-light">
                  <th>ID</th>
                  <th>Candidate Name</th>
                  <th>Total Invoice Amount $</th>
                  <th>Total Paid Amount $</th>
                  <th>Total Balance Amount $</th>
                  <th>Upload <img
                                            src="/assets/Images/WFAssociationIcons/process.gif"
                                            className="w-auto ml-4"
                                            role="button"
                                            style={{ height: "20px" }}
                                            onClick={handleWorkflowModalShowHide}
                                        /></th>
                </thead>
                <tbody>
                  {tenNintyCompliance.map((item) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                      <td>
                        {parseFloat(
                          item.totalInvoiceAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item.totalPaidAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {parseFloat(
                          item.totalDueAmount
                        ).toFixed(2)}
                      </td>
                      <td>
                        {item.complianceDocs.length >
                          0 ? (
                          <>
                            {item.complianceDocs.map(
                              (doc) => (
                                <button className="btn btn-sm btn-link pl-0">
                                  {doc.name}
                                </button>
                              )
                            )}
                            <br />
                            <Moment format="MMM Do YYYY h:mm a">
                              {item.addedDateTime}
                            </Moment>

                            <br />
                            {item.notes}
                          </>
                        ) : (
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => handleShowHideCompliance("", item)}
                          >
                            Upload 1099-NEC
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              currentPage={currentPage}
              pageSize={pageSize}
              itemsCount={complianceReport.length}
              onPageChange={handlePageChange}
              startIndex={startIndex}
              endIndex={endIndex}
            ></Pagination>
          </div>
        </div>
      </div>
    </div>
    <AddComplaince
      showHideCompliance={showHideCompliance}
      handleShowHideCompliance={handleShowHideCompliance}
      type="1099"
      selectedObj={selectedObj}
    //workflowTask={workflowTask}
    ></AddComplaince>

    <WorkflowMapPopup
      workflowShowHide={workflowShowHide}
      handleWorkflowModalShowHide={handleWorkflowModalShowHide}
      selectedObj={{ ciid: 467, currentTask: "Compliance" }}
    ></WorkflowMapPopup>
  </>
}

export default Ten99CandidateComp;