import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "dashboard",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        wfPhases:[],
        reportData:null,
        reportByMonth:null,
        allAppTasks:[],
        configReport:null
    },
    reducers: {
        wfPhasesReceived: (dashboard, action) => {
            dashboard.wfPhases = action.payload;
        },
        configReportReceived: (dashboard, action) => {
            dashboard.configReport = action.payload;
        },
        allAppTasksReceived: (dashboard, action) => {
            dashboard.allAppTasks = action.payload;
        },
        dashboardReportDataReceived: (dashboard, action) => {
            dashboard.reportData = action.payload;
        },
        dashboardReportByMonthReceived: (dashboard, action) => {
            dashboard.reportByMonth = action.payload;
        },
    }
});

export const { wfPhasesReceived,allAppTasksReceived,
    dashboardReportDataReceived,dashboardReportByMonthReceived,configReportReceived } = slice.actions;
export default slice.reducer;

export const loadWfPhases = (name) => apiCallBegan({
    url: config.GetCodemanager + name,
    onSuccess: wfPhasesReceived.type
});
export const loadDashboardReportData = () => apiCallBegan({
    url: config.GetDashboardData,
    onSuccess: dashboardReportDataReceived.type
});

export const loadDashboardReportByMonth = () => apiCallBegan({
    url: config.GetReceivedApplicationByMonth,
    onSuccess: dashboardReportByMonthReceived.type
});

export const loadAllAppTasks = () => apiCallBegan({
    url: config.GetAllTask,
    onSuccess: allAppTasksReceived.type
});

export const loadConfigReport = () => apiCallBegan({
    url: config.GetConfigurationReport,
    onSuccess: configReportReceived.type
});

export const getWfPhases = createSelector(
    state => state.entities.dashboard,
    dashboard => dashboard.wfPhases
);

export const getDashboardReportData = createSelector(
    state => state.entities.dashboard,
    dashboard => dashboard.reportData
);

export const getDashboardReportByMonth = createSelector(
    state => state.entities.dashboard,
    dashboard => dashboard.reportByMonth
);

export const getAllAppTasks = createSelector(
    state => state.entities.dashboard,
    dashboard => dashboard.allAppTasks
);

export const getConfigurationReport = createSelector(
    state => state.entities.dashboard,
    dashboard => dashboard.configReport
);



