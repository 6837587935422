import React, { Component, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import Form from "../../../comman/form";
import http from "../../../services/HttpService";
import auth from "../../../services/authService";
import config from "../../../config.json";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { getActivateDeactivateUpdateStatus, getUserAccessHistory, loadUserAccessLogHistory, updateUserActiveDeactive } from "../../../app/store/users";
import { getAlert, getConfirmAlert } from "../../../services/appAlerts";
import { getCurrentUser } from "../../../app/store/auth";

const ActiveDeactiveHistory=(props)=> {

  const {selectedObj,typeName,type, handlePermissionShowHide2}=props;

  const [status,setStatus]=useState(null);
  const [notes,setNotes]=useState("");
  const [accessHistory,setAccessHistory]=useState([]);
  const [userId,setUserId]=useState(0);
  const [partnerId,setPartnerId]=useState(0);

  const userAccessHistorySelector=useSelector(getUserAccessHistory);
  const activateDeactivateUpdateStatusSelector=useSelector(getActivateDeactivateUpdateStatus);

  const dispatch=useDispatch();
  const currentUser=getCurrentUser();

  useEffect(()=>{
    if(selectedObj && type === "performer"){
      setStatus(selectedObj.status);
      setUserId(selectedObj.id);
      dispatch(loadUserAccessLogHistory(selectedObj.id,type));
    }
    if(selectedObj?.contact1 && type === "partner"){
      setPartnerId(selectedObj.fuid);
      setStatus(selectedObj.contact1.isActive);
      setUserId(selectedObj.contact1.performerid);
      dispatch(loadUserAccessLogHistory(selectedObj.fuid,type));
    }
    setNotes("");
  },[selectedObj]);

  useEffect(()=>{
    if(userAccessHistorySelector !== "")
    setAccessHistory(userAccessHistorySelector);
  },[userAccessHistorySelector]);

  useEffect(()=>{
    if(activateDeactivateUpdateStatusSelector){
      let title = "Activate";
      if (!status) title = "Deactivate";
      getAlert("",`${title}d Successfully`,"success");
      handlePermissionShowHide2();
      if (type === "performer")
        dispatch(loadUserAccessLogHistory(userId, type));
      else
        dispatch(loadUserAccessLogHistory(partnerId, type));
    }
    
  },[activateDeactivateUpdateStatusSelector]);

  const handleSubmit=async()=>{
    let title="Activate";
    if(!status) title="Deactivate";
    const result=await getConfirmAlert("Warning",`Are you sure you want to ${title} the ${typeName}?`,"warning");
    if(result.isConfirmed){
      const data={
        addedBy:currentUser.id,
        status:status,
        notes:notes,
        performerId:userId,
        partnerId:partnerId
      }
      dispatch(updateUserActiveDeactive(data))
    }
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-12 form-group">
        Selected {typeName} : <label htmlFor="" className="form-control-label">{
        type === "partner" ? selectedObj?.label : selectedObj?.name}</label>
        </div>
        <div className="col-md-12 form-inline">
          <div className="custom-control custom-radio mb-3">
            <input
              name="status"
              className="custom-control-input"
              id="activate"
              type="radio"
              checked={status}
              onChange={()=>setStatus(true)}
            />
            <label className="custom-control-label" htmlFor="activate">
              Activate
            </label>
          </div>
          <div className="custom-control custom-radio mb-3 ml-3">
            <input
              name="status"
              className="custom-control-input"
              id="deactivate"
              type="radio"
              checked={status === false}
              onChange={()=>setStatus(false)}
            />
            <label
              className="custom-control-label"
              htmlFor="deactivate"
            >
              Deactivate
            </label>
          </div>
        </div>
        <div className="col-md-12 form-group">
         <label htmlFor="notes">Notes</label>
         <textarea 
         name="notes" 
         id="notes" 
         cols="2" 
         rows="2"
         value={notes}
         className="form-control"
         onChange={({currentTarget})=>setNotes(currentTarget.value)}
         ></textarea>
        </div>
        <div className="col-md-12 text-center">
          <button className="btn btn-sm btn-outline-default" onClick={handleSubmit}>
          <i class="fa fa-check" aria-hidden="true"></i> Save
          </button>
        </div>
        <div className="col-md-12">
          <label htmlFor="" className="form-control-label">History</label>
          <table className="table table-bordered bg-white">
            <thead className="thead-light">
              <tr>
                <th>S.No.</th>
                <th>Date & Time</th>
                <th>Notes</th>
                <th>Action By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {accessHistory.map((item, key) => (
                <tr>
                  <td>{key + 1}</td>
                  <td>
                    <Moment format="MMM Do YYYY, h:mm a">
                      {item.addedDateTime}
                    </Moment>
                  </td>
                  <td>{item.notes}</td>
                  <td>{item.performerName}</td>
                  <td>
                    {item.status && (
                      <span>
                        <i
                          className="fa fa-check text-success"
                          aria-hidden="true"
                        ></i>{" "}
                        Activated
                      </span>
                    )}
                    {!item.status && (
                      <span>
                        <i
                          className="fa fa-times text-danger"
                          aria-hidden="true"
                        ></i>{" "}
                        Deactivated
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ActiveDeactiveHistory;
