import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { getWordVersionTemplates, loadVersionWordTemplates } from "../../store/wordProccessing";

const WordVersionHistory = (props) => {
    const { selectedTemp, handleVersionViewTemp } = props;

    const [tempHistory, setTempHistory] = useState([]);
    const [tempId, setTempId] = useState(0);

    const wordVersionTemplatesSelector=useSelector(getWordVersionTemplates)
    const dispatch=useDispatch();
    useEffect(()=>{
        if(selectedTemp?.templateId > 0){
            dispatch(loadVersionWordTemplates(selectedTemp.templateId));
        }
        else{
            setTempHistory([]);
        }
    },[selectedTemp]);

    useEffect(()=>{
        if(wordVersionTemplatesSelector.length > 0){
            const oldVersionTemplates=wordVersionTemplatesSelector.filter(x=>x.id !== selectedTemp.id);
            setTempHistory(oldVersionTemplates); 
        }
        
    },[wordVersionTemplatesSelector]);

    const handleResetSelection=()=>{
        setTempId(0);
        handleVersionViewTemp(null)
    }

    const handleSelectVerTemplate=(item)=>{
        setTempId(item.id);
        handleVersionViewTemp(item);
    }

    return <>
        <div className="table-responsive">
            <table className="table table-striped align-items-center table-flush">
                <thead className="thead-light-green">
                    <tr>
                        <th>Select<span style={{ cursor: "pointer" }} onClick={handleResetSelection}>
                            <i className="fa fa-refresh ml-2 text-primary" aria-hidden="true"></i>
                        </span></th>
                        <th>ID</th>
                        <th>Added Date Time</th>
                        <th>Case Name</th>
                        <th>Version</th>
                        <th>Added By</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tempHistory.map((item,key) =>
                        <tr>
                            <td>
                                <div className="custom-control custom-radio">
                                    <input
                                        className="custom-control-input"
                                        type="radio"
                                        id={item.id}
                                        name="vertemp"
                                        onChange={() => handleSelectVerTemplate(item)}
                                        value={tempId}
                                        checked={tempId === item.id}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={item.id}
                                    >
                                    </label>
                                </div>
                            </td>
                            <td>{item.id}</td>
                            <td>
                                <Moment format="MMM Do YYYY, h:mm a">
                                    {item.createdDateTime}
                                </Moment>
                            </td>
                            <td>{item.templateName}</td>
                            <td>{item.version}</td>
                            <td>{item.templateType}</td>
                            <td>
                                {!item.isEdit && <span>Locked</span>}
                                {item.isEdit && <>Lock</>}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </>

}

export default WordVersionHistory;