import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class AddNewBprmAccess extends Component {
  render() {
    const { showHide, handleModalShowHide } = this.props;
    return (
      <Modal show={showHide}>
        <Modal.Header closeButton onClick={handleModalShowHide}>
          <Modal.Title>New Login Request</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="form-control-label" htmlFor="description">
                  Enter Reason
                </label>
                <textarea
                  col="2"
                  row="4"
                  id="description"
                  name="description"
                  className="form-control"
                  placeholder="Reason"
                  onChange={this.handleChange}
                ></textarea>
              </div>
              <div className="form-group">
                <label className="form-control-label" htmlFor="description">
                  Token Key
                </label>
                <input
                  type="text"
                  name="token"
                  id="token"
                  className="form-control"
                  value="82a89d4a-e9b0-41fb-b1ae-eff2fc6e7914"
                ></input>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-control-label" htmlFor="startDate">
                  Start Date
                </label>
                <input
                  type="text"
                  name="startDate"
                  id="startDate"
                  className="form-control"
                  value="6-Jan-2021"
                ></input>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-control-label" htmlFor="endDate">
                  End Date
                </label>
                <input
                  type="text"
                  name="endDate"
                  id="endDate"
                  className="form-control"
                  value="8-Jan-2021"
                ></input>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="form-control-label" htmlFor="validity">
                  Token ID Validity
                </label>
                <input
                  type="text"
                  name="validity"
                  id="validity"
                  className="form-control"
                  value="2 Days"
                ></input>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalShowHide}>
            Close
          </Button>
          <Button variant="primary" onClick={this.doSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddNewBprmAccess;
