import React, { Component, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Document from "../PortalForms/Document";
import Docusign from "../PortalForms/Docusign";
import FormsLibrary from "../PortalForms/FormsLibrary";
import InternalReview from "../PortalForms/InternalReview";
import MessageWithApplicant from "../PortalForms/MessageWithApplicant";
import ScheduleMeeting from "../PortalForms/ScheduleMeeting";
import TemplateLibrary from "../PortalForms/TemplateLibrary";
import PurchaseOrderForms from "../OMCRegistry/PurchaseOrderForms";
import TimerMessage from "../PortalForms/TimerMessage";
import AchInformation from "../../ReviewTools/AchInformation";
import InsuranseInformation from "../../ReviewTools/InsuranseInformation";
import TimeLogManagement from "../../ReviewTools/TimeLogManagement";
import SubRecCandidates from "../../ReviewTools/SubRecCandidates";
import PhoneCallApplicant from "../PortalForms/PhoneCallApplicant";
import WordEditor from "../../app/components/Tools/WordEditor";
import { useDispatch, useSelector } from "react-redux";
import {
  getComponentUpdateStatus, getComponentUserStatus,
  loadComponentUserStatus, loadToolsCount, UpdateComponentsUserStatus
} from "../../app/store/tools";
import { getCurrentUser } from "../../app/store/auth";

const ToolsPopup = (props) => {
  const { setViewBprmFormShowHide,
    toolObj: t, task: currentTask,
    uploadType, candidateObj, app, item, handleToolShowHide } = props;

  const componentUserStatusSelector = useSelector(getComponentUserStatus);
  const componentUpdateStatusSelector = useSelector(getComponentUpdateStatus)

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  const handleUpdateComponent = (compItems) => {
    const comp = componentUserStatusSelector.filter(x => x.compId === t.id);
    let updCompItems = "";
    if (comp.length > 0) {
      updCompItems = comp[0].compItems;
    }
    const compObj = {
      compName: t.name,
      performerId: currentUser.id,
      compItems: compItems,
      ciid: currentTask.ciid,
      wiid: currentTask.wiid,
      iiid: currentTask.iiid,
      compId: t.id
    }
    if (compItems !== "" && compItems !== updCompItems) {
      dispatch(UpdateComponentsUserStatus(compObj));
    }
  }

  useEffect(() => {
    if (componentUpdateStatusSelector && currentTask?.ciid > 0) {
      dispatch(loadToolsCount(currentTask.ciid));
      dispatch(loadComponentUserStatus(currentUser.id, currentTask.ciid))
    }
  }, [componentUpdateStatusSelector])

  return t && (
    <div className="">
      {t.id === 7 && <TimerMessage workflowTask={currentTask} />}
      {t.id === 9 && <MessageWithApplicant workflowTask={currentTask} handleUpdateComponent={handleUpdateComponent} />}
      {t.id === 10 && <PhoneCallApplicant workflowTask={currentTask} handleUpdateComponent={handleUpdateComponent} />}
      {t.id === 11 && <ScheduleMeeting workflowTask={currentTask} partitem={item} handleUpdateComponent={handleUpdateComponent} />}
      {t.id === 49 && <WordEditor workflowTask={currentTask} handleUpdateComponent={handleUpdateComponent} />}

      {(t.id === 41 || t.id === 26) && <Docusign type="Workfinity eSign" workflowTask={currentTask} />}
      {t.id === 18 && (
        <FormsLibrary
          workflowTask={currentTask}
          candidateObj={candidateObj}
          uploadType={uploadType}
          setViewBprmFormShowHide={setViewBprmFormShowHide}
          handleUpdateComponent={handleUpdateComponent}
        />
      )}
      {t.id === 24 && <TemplateLibrary workflowTask={currentTask}
        setViewBprmFormShowHide={setViewBprmFormShowHide}
        handleUpdateComponent={handleUpdateComponent}
      />}

      {t.id === 25 && (
        <PurchaseOrderForms
          workflowTask={currentTask}
          handleToolShowHide={handleToolShowHide}
          candidateObj={candidateObj}
        />
      )}
    </div>
  );
}

export default ToolsPopup;
