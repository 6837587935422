import React, { Component } from "react";
import { Popover, Button, OverlayTrigger } from "react-bootstrap";
import InternalReviewPopup from "./InternalReviewPopup";
import Moment from "react-moment";
import http from "../../services/HttpService";
import config from "../../config.json";
import auth from "../../services/authService";
import { getAlert } from "../../services/appAlerts";
class InternalReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHide: false,
      reviewComments: [],
      selectedItem: {},
      refreshValue: false,
    };
  }

  componentDidMount() {
    this.loadComments();
  }

  handleModalShowHide = (item) => {
    this.setState({ showHide: !this.state.showHide, selectedItem: item });
  };

  handleSubmit = async ({ comments }) => {
    const { workflowTask, pageType } = this.props;
    const wfTask={...workflowTask};
    const user = auth.getCurrentUser();
    const frmData = new FormData();
    frmData.append("comment", comments);
    if (pageType === "callCenter") frmData.append("type", "CallCenter");
    else frmData.append("type", "Internal");
    wfTask.performerId = user.id;
    frmData.append("workflowTask", JSON.stringify(wfTask));
    const result = await http.post(config.AddNewComments, frmData);
    getAlert("","Added Successfully","success");
    this.loadComments();
    this.handleRefreshValue(true);
  };

  loadComments = async () => {
    const { workflowTask, pageType } = this.props;
    let url = "Internal&ciid=" + workflowTask.ciid;
    if (pageType === "callCenter" || pageType === "manageApp")
      url = "CallCenter&ciid=" + workflowTask.ciid;
    const { data: reviewComments } = await http.get(
      config.GetReviewComments + url
    );
    this.setState({ reviewComments, showHide: false });
    //this.props.handleAppCommentCount(reviewComments.length);
  };

  handleEditComment = async (item) => {
    const { data } = await http.post(config.UpdateComment, item);
    this.setState({ showHide: false });
    this.loadComments();
    this.handleRefreshValue(true);
  };

  handleDeleteComment = async (item) => {
    const { data } = await http.post(config.DeleteComment, item);
    this.loadComments();
  };

  getPopover = (notes) => {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h4">Notes</Popover.Title>
        <Popover.Content>{notes}</Popover.Content>
      </Popover>
    );
    return (
      <React.Fragment>
        <OverlayTrigger trigger="click" placement="top" overlay={popover}>
          <button
            className="btn btn-sm btn-link"
            onClick={() => this.handleRefreshValue(false)}
          >
            <i className="fa fa-eye mr-2"></i>View
            <span className="badge badge-pill badge-danger position-absolute mt--1 ml-0">
              {this.state.refreshValue && (
                <i className="fa fa-bell-o" aria-hidden="true"></i>
              )}
            </span>
          </button>
        </OverlayTrigger>
      </React.Fragment>
    );
  };

  handleRefreshValue = (value) => {
    this.setState({ refreshValue: value });
  };

  render() {
    const { reviewComments, showHide, selectedItem } = this.state;
    const { workflowTask, pageType } = this.props;
    return (
      <React.Fragment>
        {pageType !== "manageApp" && (
         <div className="text-right">
           <button
            type="button"
            className="btn btn-sm btn-outline-default mb-2 btn-min-width"
            onClick={() => this.handleModalShowHide()}
          >
            <i className="fa fa-plus mr-2" aria-hidden="true" />
            Add Note
          </button>
         </div>
        )}
        <div className="table-responsive">
          <table className="table table-striped align-items-center table-flush border">
            <thead className="thead-light-green">
              <tr>
                <th>S.No.</th>
                <th>Date & Time</th>
                <th>Internal Notes</th>
                <th>Added By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {reviewComments &&
                reviewComments.map((item, key) => (
                  <tr>
                    <td>{key + 1}</td>
                    <td>
                      <Moment format="MMM Do YYYY, h:mm a">
                        {item.dateTime}
                      </Moment>
                    </td>
                    <td>{this.getPopover(item.comments)}</td>
                    <td>
                      {item.performer}
                      <br />
                      {item.email}
                      <br />
                      {item.role}
                      {item.department}
                      {item.partner}
                    </td>
                    {pageType !== "manageApp" && (
                      <td>
                        <button
                          className="btn btn-sm btn-link p-0 text-underline"
                          disabled={
                            workflowTask.wiid === item.wiid ? false : true
                          }
                          onClick={() => this.handleModalShowHide(item)}
                        >
                          <i className="fa fa-edit mr-1"></i>Edit
                        </button>
                        <button
                          className="btn btn-sm btn-link p-0 text-underline"
                          disabled={
                            workflowTask.wiid === item.wiid ? false : true
                          }
                          onClick={() => this.handleDeleteComment(item)}
                        >
                          <i className="fa fa-trash mr-1"></i>Delete
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <InternalReviewPopup
          showHide={showHide}
          handleModalShowHide={this.handleModalShowHide}
          handleSubmit={this.handleSubmit}
          selectedItem={selectedItem}
          handleEditComment={this.handleEditComment}
        ></InternalReviewPopup>
      </React.Fragment>
    );
  }
}

export default InternalReview;
