import React, { Component } from "react";
import { Link } from "react-router-dom";

class ApplicationManagement extends Component {
  render() {
    const { genenratedOffers } = this.props;
    return (
      <div className="row">
        <div className="col-xl-4">
          <div className="card bg-gradient-danger">
            <div className="card-body">
              <div className="mb-2">
                <span className="h2 text-white">Loan Offers</span>
                <div className="text-white mt-2 text-sm">
                  Total Generated Offers :
                  <span className="text-white font-weight-bold ml-2">
                    {genenratedOffers}
                  </span>
                </div>
              </div>
            </div>
            <div className="card-body pt-0 pb-0">
              <Link
                to="/ManageApplication"
                className="btn btn-sm btn-block btn-neutral"
              >
                View
              </Link>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <small className="text-white">Active:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-success"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <small className="text-white">Inactive:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-warning"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-xl-3">
          <div className="card bg-gradient-primary">
            <div className="card-body">
              <div className="mb-2">
                <span className="h2 text-white">Fund Management</span>
                <div className="text-white mt-2 text-sm">
                  Amount Added :
                  <span className="text-white font-weight-bold">150</span>
                </div>
              </div>
            </div>
            <div className="card-body pt-0 pb-0">
              <Link
                to="/PartnerPerformer"
                className="btn btn-sm btn-block btn-neutral"
              >
                View
              </Link>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <small className="text-white">Fund Added:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-default"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <small className="text-white">Fund Withdraw:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-warning"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-xl-3">
          <div className="card bg-gradient-default">
            <div className="card-body">
              <div className="mb-2">
                <span className="h2 text-white">
                  Background Check
                </span>
                <div className="text-white mt-2 text-sm">
                  Total:
                  <span className="text-white font-weight-bold">100</span>
                </div>
              </div>
            </div>
            <div className="card-body pt-0 pb-0">
              <Link
                to="/WorkflowPerformer"
                className="btn btn-sm btn-block btn-neutral"
              >
                View
              </Link>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <small className="text-white">Active:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-success"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <small className="text-white">Inactive:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-warning"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="card bg-gradient-warning">
            <div className="card-body">
              <div className="mb-2">
                <span className="h2 text-white">Survey</span>
                <div className="text-white mt-2 text-sm">
                  Total:
                  <span className="text-white font-weight-bold">50</span>
                </div>
              </div>
            </div>
            <div className="card-body pt-0 pb-0">
              <Link
                to="/PartnerPerformer"
                className="btn btn-sm btn-block btn-neutral"
              >
                View
              </Link>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <small className="text-white">Active:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-default"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <small className="text-white">Inactive:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-warning"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="card bg-gradient-success">
            <div className="card-body">
              <div className="mb-2">
                <span className="h2 text-white">Payment Management</span>
                <div className="text-white mt-2 text-sm">
                  Total:
                  <span className="text-white font-weight-bold">50</span>
                </div>
              </div>
            </div>
            <div className="card-body pt-0 pb-0">
              <Link
                to="/PartnerPerformer"
                className="btn btn-sm btn-block btn-neutral"
              >
                View
              </Link>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <small className="text-white">Active:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-default"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <small className="text-white">Inactive:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-warning"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="card bg-gradient-info">
            <div className="card-body">
              <div className="mb-2">
                <span className="h2 text-white">Cart Management</span>
                <div className="text-white mt-2 text-sm">
                  Total:
                  <span className="text-white font-weight-bold">50</span>
                </div>
              </div>
            </div>
            <div className="card-body pt-0 pb-0">
              <Link
                to="/PartnerPerformer"
                className="btn btn-sm btn-block btn-neutral"
              >
                View
              </Link>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <small className="text-white">Active:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-default"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <small className="text-white">Inactive:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-warning"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="card bg-gradient-primary">
            <div className="card-body">
              <div className="mb-2">
                <span className="h2 text-white">Multi-Search Tool Integration</span>
                <div className="text-white mt-2 text-sm">
                  Total: 260
                  <span className="text-white font-weight-bold">50</span>
                </div>
              </div>
            </div>
            <div className="card-body pt-0 pb-0">
              <Link
                to="/PartnerPerformer"
                className="btn btn-sm btn-block btn-neutral"
              >
                View
              </Link>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <small className="text-white">Active:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-default"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
                <div className="col">
                  <small className="text-white">Inactive:0 %</small>
                  <div className="progress progress-xs my-2">
                    <div
                      className="progress-bar bg-warning"
                      style={{
                        width: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default ApplicationManagement;
