import React from "react";
import { Modal, Button } from "react-bootstrap";
import http from "../../services/HttpService";
import config from "../../config.json";
import BprmForm from "../../comman/BprmForm";
import DropdownPrompt from "../../comman/DropdownPrompt";

class SubForm extends BprmForm {
  constructor(props) {
    super(props);
    this.state = {
      htmlJsonString: [
        {
          label: "?  Column",
          dataType: "column",
          id: "column",
          width: 6,
          components: [
            {
              label: "Save And Continue",
              id: "button",
              dataType: "button",
              navigationPage: "",
              formProcess: 1,
              buttonType: {
                type: "saveAndContinue",
                name: "Save And Continue",
              },
              style: "btn btn-primary",
            },
          ],
        },
      ],
      aWorkfinityAppEntity: {},
      wfForm: {},
      data: {},
      errors: {},
      encryptionDecryptions: {},
      dropdownShowHide: false,
      dropdwonTitle: "",
      selectedDropdwonData: [],
      rules: [],
      aWorkfinityApp: {},
    };
  }

  schema = {};

  async componentDidMount() {
    const { subFormId } = this.props;
    const { data: wfForm } = await http.get(config.GetForm + subFormId);
    // const { data: rules } = await http.get(config.GetFormRule);
    //console.log(rules);
    this.loadCodemangerDropdown(wfForm);
    this.setState({ wfForm });
  }

  async componentWillReceiveProps(nextProps) {
    const {
      aWorkfinityAppEntity,
      subFormDataType,
      subFormAttribute,
      subformItemIndex,
      formDD,
      stateData,
      aWorkfinityApp,
    } = nextProps;

    //console.log(subFormDataType);
    ///console.log(subFormAttribute);
    //console.log(formDD);
    if (subFormDataType !== undefined) {
      const formData =
        stateData[subFormDataType.toLowerCase() + "." + subFormAttribute];
      //console.log(formData);
      if (formData !== undefined && parseInt(subformItemIndex) > -1) {
        const selectedItem = formData[parseInt(subformItemIndex)];
        //console.log(selectedItem);
        if (selectedItem !== undefined) {
          const subFormObj = {};
          Object.keys(selectedItem).forEach(function (keyValue) {
            if (keyValue !== "EntityId" && formDD)
              subFormObj[
                formDD.subformDd.toLowerCase() + "." + keyValue.replace("@", "")
              ] = selectedItem[keyValue];
          });
          //console.log(subFormObj);
          this.setState({
            data: subFormObj,
            aWorkfinityAppEntity,
            aWorkfinityApp,
          });
        }
      } else {
        this.setState({ aWorkfinityAppEntity, aWorkfinityApp });
      }
    }
  }

  loadCodemangerDropdown = (wfForm) => {
    const elements = //htmlJsonString;
      wfForm !== undefined &&
      wfForm.elementJson !== undefined &&
      JSON.parse(wfForm.elementJson);
    let cdmData = [];
    elements &&
      elements.map((col) =>
        col.components.map(async (comp) => {
          if (comp.dataType === "dropDown") {
            const controlId =
              comp.attributeType.toLowerCase() + "." + comp.attributeObject;
            if (
              comp.dropdownvalue.filter((x) => x.name !== "").length === 0 &&
              (comp.isChildElement === false ||
                comp.isChildElement === undefined)
            ) {
              try {
                const { data: items } = await http.get(
                  config.GetDataTypeCodemanager +
                    comp.attributeType.toLowerCase() +
                    "&att=" +
                    comp.attributeObject
                );
                const ddTypeObj = {
                  controlId: controlId,
                  optionData: items,
                };
                cdmData.push(ddTypeObj);
              } catch (err) {
                console.log(err);
              }
            }
          }
        })
      );

    this.setState({ ddTypeCodMngr: cdmData });
  };

  doSubmit = () => {
    const { data } = this.state;
    const {
      aWorkfinityAppEntity,
      subFormAttribute,
      subFormDataType,
      subFormId,
      subformItemIndex,
      stateData,
    } = this.props;
    const subFormObj = {};
    Object.keys(data).forEach(function (keyValue) {
      const dd = keyValue.split(".");
      if (dd.length > 1) {
        var item = data[keyValue];
        subFormObj["@" + dd[1]] = item;
      }
    });
    const controleId = subFormDataType.toLowerCase() + "." + subFormAttribute;
    //console.log(subformItemIndex);
    let subFormArray = [];
    if (subformItemIndex > -1) {
      // subFormArray = [
      //   ...aWorkfinityAppEntity[subFormDataType.toLowerCase()][
      //     subFormAttribute
      //   ],
      // ];
      //subFormArray[subformItemIndex] = subFormObj;
      stateData[controleId][subformItemIndex] = subFormObj;
    } else {
      // if (
      //   aWorkfinityAppEntity[subFormDataType.toLowerCase()][
      //     subFormAttribute
      //   ] !== undefined &&
      //   aWorkfinityAppEntity[subFormDataType.toLowerCase()][subFormAttribute]
      //     .length !== undefined
      // ) {
      //   subFormArray = [
      //     ...aWorkfinityAppEntity[subFormDataType.toLowerCase()][
      //       subFormAttribute
      //     ],
      //     subFormObj,
      //   ];
      // } else {
      //   subFormArray.push(subFormObj);
      // }
      if (
        stateData[controleId] !== undefined &&
        stateData[controleId].length !== undefined
      ) {
        stateData[controleId] = [...stateData[controleId], subFormObj];
      } else {
        subFormArray.push(subFormObj);
        stateData[controleId] = subFormArray;
      }
    }

    //stateData[controleId] = subFormArray;
    //console.log(stateData);
    //this.setState({ data });
    this.props.handleSubformSave(stateData, subFormId, controleId);
  };

  handleDropdownSubmit = (id, value) => {
    const { data } = this.state;
    data[id] = value;
    this.setState({
      data,
      dropdownShowHide: false,
      dropdwonTitle: "",
      selectedDropdwonData: [],
    });
  };

  render() {
    const {
      wfForm,
      htmlJsonString,
      dropdownShowHide,
      selectedDropdwonData,
      dropdwonTitle,
    } = this.state;
    const elements = //htmlJsonString;
      wfForm.elementJson != undefined && JSON.parse(wfForm.elementJson);
    const { showHide, handleModalShowHide, title, subFormId } = this.props;
    return (
      <React.Fragment>
        <Modal show={showHide} size="lg">
          <Modal.Header
            closeButton
            onClick={() => handleModalShowHide("showHide" + subFormId)}
          >
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {elements &&
                elements.map((col) => (
                  <div className={`col-` + col.width}>
                    {col.components.map((comp) => {
                      return this.rendorHtmlComponent(comp);
                    })}
                  </div>
                ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => handleModalShowHide("showHide" + subFormId)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={this.doSubmit}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <DropdownPrompt
          dropdownShowHide={dropdownShowHide}
          handleDropdownShowHide={this.handleDropdownShowHide}
          dropdwonTitle={dropdwonTitle}
          selectedDropdwonData={selectedDropdwonData}
          handleDropdownSubmit={this.handleDropdownSubmit}
        ></DropdownPrompt>
      </React.Fragment>
    );
  }
}

export default SubForm;
