import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";

class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedObj: {},
    };
  }

  componentWillReceiveProps = async (nextProps) => {
    const { selectedObj: obj } = nextProps;
    if (obj.ciid) {
      const { data: selectedObj } = await http.get(
        config.GetPaymentDetails + obj.ciid
      );
      this.setState({ selectedObj });
    }
  };

  render() {
    const { selectedObj } = this.state;
    const {
      paymentShowHide,
      handlePaymentModalShowHide,
      selectedObj: obj,
    } = this.props;
    //console.log(selectedObj);
    return (
      <Modal show={paymentShowHide}>
        <Modal.Header closeButton onClick={handlePaymentModalShowHide}>
          <Modal.Title>Payment Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          {selectedObj.paymentStatus !== undefined && (
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>Payment Successfull</h2>
                <i
                  className="fa fa-check-circle-o display-1 text-success"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="col-md-12 text-right">
                {selectedObj.paymentStatus !== undefined && (
                  <a
                    href={config.CreatePdf + selectedObj.paymentId}
                    className="btn btn-primary btn-sm"
                    target="_blank"
                  >
                    <i className="fa fa-print"></i> Print
                  </a>
                )}
              </div>
              <div className="col-md-12">
                <table className="table align-items-center table-flush table-hover bg-white table-bordered mt-2">
                  <tbody>
                    <tr>
                      <td className="w-10 font-weight-bold">Applicant ID :</td>
                      <td> {selectedObj.performerId}</td>
                      <td className="w-10 text-right font-weight-bold">
                        Document # :{" "}
                      </td>
                      <td>PBC00{selectedObj.paymentId}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Email : </td>
                      <td colSpan="3">{selectedObj.performerEmail}</td>
                    </tr>
                    <tr>
                      <td className="align-top font-weight-bold">Bill To : </td>
                      <td colSpan="3">
                        {selectedObj.billTo.address}
                        <br />
                        {selectedObj.billTo.state}, {selectedObj.billTo.city}
                        <br />
                        {selectedObj.billTo.country}, {selectedObj.billTo.zip}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-12">
                <h3>Payment Details : </h3>
                <table className="table align-items-center table-flush table-hover bg-white table-striped">
                  <thead className="thead-light">
                    <tr>
                      <td className="font-weight-bold">Order # : </td>
                      <td>APP00{obj.ciid}</td>
                    </tr>
                    <tr>
                      <td className="w-25 font-weight-bold">
                        Receipt # / Transaction ID :{" "}
                      </td>
                      <td>
                        {selectedObj.authResponse.transactionResponse.transId}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Total Paid :</td>
                      <td>${selectedObj.amount}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Transaction Date :</td>
                      <td>{selectedObj.paymentDate}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Payment Method :</td>
                      <td>Credit Card</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Card Number :</td>
                      <td>
                        {
                          selectedObj.authResponse.transactionResponse
                            .accountNumber
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Expiry Date :</td>
                      <td>{selectedObj.creditCard.expirationDate}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Name on the Card :</td>
                      <td>{selectedObj.billTo.firstName}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Authorization Code :</td>
                      <td>
                        {selectedObj.authResponse.transactionResponse.authCode}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">
                        Transaction Message :
                      </td>
                      <td>
                        {selectedObj.authResponse.messages.message[0].text}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectedObj.paymentStatus !== undefined && (
            <a
              href={config.CreatePdf + selectedObj.paymentId}
              className="btn btn-primary"
              target="_blank"
            >
              <i className="fa fa-print"></i> Print
            </a>
          )}
          <Button variant="danger" onClick={handlePaymentModalShowHide}>
            <i className="fa fa-times" aria-hidden="true"></i> Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PaymentDetails;
