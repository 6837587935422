import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Form from "../../comman/form";
import Joi from "joi-browser";
import config from "../../config.json";
import http from "../../services/HttpService";
import ThankYou from "./ThankYou";

class SecurityQuestion extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      thankYou: false,
      secMain: true,
    };
  }

  schema = {
    //answer: Joi.string().required().label("Security Answer"),
    // confirmAnswer: Joi.string().required().label("Confirm Security Answer"),
    captcha: Joi.string().required().label("Captcha"),
  };

  onCaptchaChange = (value) => {
    const { data } = this.state;
    data["captcha"] = value;
    this.setState({ data });
  };

  doSubmit = async () => {
    const { data, errors } = this.state;
    const { secQuestion, userType, forgotType } = this.props;
    const secAnswer = [];
    secQuestion.map((item) => {
      const obj = {
        id: item.id,
        hintAnswer1: data[`answer_${item.id}`],
        type: forgotType,
      };
      secAnswer.push(obj);
    });
    const { data: result } = await http.post(
      config.VerifySecQuestion,
      secAnswer
    );
    if (result === "success") {
      this.setState({ secMain: false, thankYou: true });
    } else {
      const ansId = secQuestion[secQuestion.length - 1].id;
      errors[`answer_${ansId}`] = "Invalid Details";
      this.setState({ errors });
    }
  };

  render() {
    const { secMain, thankYou } = this.state;
    const { handleQuestionBack, secQuestion, forgotType, urlData } = this.props;

    if (secQuestion !== undefined && secQuestion !== "") {
      secQuestion.map(
        (item, key) =>
          (this.schema[`answer_${item.id}`] = Joi.string()
            .required()
            .label(`Answer ${key + 1}`))
      );
    }

    return (
      <React.Fragment>
        {secMain && (
          <div className="row">
            <div className="form-group col-md-12">
              <button
                className="btn btn-link pl-0"
                onClick={handleQuestionBack}
              >
                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                Back
              </button>
            </div>
            {secQuestion !== "" &&
              secQuestion !== undefined &&
              secQuestion.map((item, key) => (
                <div className="col-md-12">
                  <label htmlFor="" className="form-control-label">
                    Security Question {key + 1}
                  </label>
                  <p className="form-control form-group">
                    <b>{item.question1}</b>
                  </p>
                  {this.renderInput(
                    `answer_${item.id}`,
                    "Answer " + (key + 1),
                    "password"
                  )}
                </div>
              ))}
            {/* 
           
            <div className="col-md-12">
              {this.renderInput("confirmAnswer", "Confirm Answer", "password")}
            </div> */}
            <div className="col-md-12 form-group">
              <ReCAPTCHA
                sitekey="6Le08xsUAAAAAKC6Oglhe-haRVGzA8KTLUZ-jmEK"
                onChange={this.onCaptchaChange}
              />
            </div>
            <div className="col-md-12">
              <button
                className="btn btn-success w-100"
                onClick={this.handleSubmit}
                disabled={this.validate()}
              >
                SUBMIT
              </button>
            </div>
          </div>
        )}
        {thankYou && (
          <ThankYou forgotType={forgotType} urlData={urlData}></ThankYou>
        )}
      </React.Fragment>
    );
  }
}

export default SecurityQuestion;
