import Joi from "joi-browser";
import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "../../comman/form";

class PhoneCallApplicantPopup extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
    };
  }

  schema = {
    callDate: Joi.string().required().label("Call Date"),
    callTime: Joi.string().required().label("Call Time"),
    duration: Joi.string().required().label("Call Duration"),
  };

  doSubmit = () => {
    const { data } = this.state;
    if (data["phoneId"] !== null && data["phoneId"] !== undefined) {
      this.props.handleEditComment(data);
    } else {
      this.props.handleSubmit(data);
    }
  };

  componentWillReceiveProps(nextProps) {
    const { selectedItem } = nextProps;
    if (selectedItem) {
      const newItem = { ...selectedItem };
      const callDate = newItem["callDate"];
      const dur = newItem["duration"];
      if (callDate)
        newItem["callDate"] = callDate.split("T")[0];
      if (dur > 0) newItem["duration"] = dur?.toString();
      this.setState({ data: newItem });
    } else {
      this.setState({ data: {}, errors: {} });
    }
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-4 form-group">
            {this.renderInput("callDate", "Date", "date")}
          </div>
          <div className="col-md-4 form-group">
            {this.renderInput("callTime", "Time", "time")}
          </div>
          <div className="col-md-4">
            {this.renderInput("duration", "Duration (Minutes)", "number")}
          </div>
          <div className="col-md-12 form-group">
            {this.renderTextArea("comments", "Notes")}
          </div>
          <div className="col-md-12 text-center">
            <button
              className="btn btn-sm btn-outline-default"
              disabled={this.validate()}
              onClick={this.handleSubmit}
            >
              <i className="fa fa-check" aria-hidden="true"></i> Save
            </button>
          </div>
        </div>

      </>
    );
  }
}

export default PhoneCallApplicantPopup;
