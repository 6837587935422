import React from "react";
import Joi from "joi-browser";
import ReCAPTCHA from "react-google-recaptcha";
import Form from "../../comman/form";

class LoginStep2 extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      documents: {},
    };
  }

  schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
    ReCAPTCHA: Joi.string().required().label("Re-Captcha"),
  };

  doSubmit = () => {
    const { data } = this.state;
    this.props.handleDoSubmit(data);
  };

  componentWillReceiveProps = (nextProps) => {
    const { userError } = nextProps;
    console.log(userError);
    if (userError !== undefined) {
      const { errors } = this.state;
      errors["username"] = userError;
      this.setState({ errors });
    }
  };

  onCaptchaChange = async (value) => {
    const { data } = this.state;
    data["ReCAPTCHA"] = value;
    this.setState({ data });
    //this.handleEnterKeyPress();
  };


  render() {
    const { errors } = this.state;
    const { type } = this.props;
    return (
      <React.Fragment>
        <div>
  
          {this.renderInput("username", "Enter Your Username")}
  
          {this.renderInput("password", "Enter Your Password", "password")}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReCAPTCHA
              sitekey="6Le08xsUAAAAAKC6Oglhe-haRVGzA8KTLUZ-jmEK"
              onChange={this.onCaptchaChange}
              onKeyDown={this.handleEnterKeyPress}
            />
          </div>
          {errors["ReCAPTCHA"] && (
            <div className="alert alert-danger">{errors["ReCAPTCHA"]}</div>
          )}
          <div>
            <button
              className="btn w-100 mt-1"
              style={{
                backgroundColor: `${type === "admin" ? "#298607" : "#2b62a1"}`,
                color: "white",
              }}
              disabled={this.validate()}
              onClick={this.handleSubmit}
            >
              Sign In
            </button>
          </div>
  
          {/* Add a div to control the layout of the buttons */}
          <div className="d-flex justify-content-end">
            <button
              onClick={this.props.handleForgotPwd}
              className="btn btn-link btn-sm"
            >
              Forgot Username/Password?
            </button>
          </div>
  
        </div>
      </React.Fragment>
    );
  }
  
}

export default LoginStep2;
