import React, { Component } from "react";
import _ from "lodash";

class TableLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getRandomNumber() {
    return Math.floor(Math.random() * 12) + 1;
  }

  render() {
    const { columns, rows } = this.props;
    const col = _.range(0, columns);
    const row = _.range(0, rows);
    return (
      <React.Fragment>
        {row?.map((r) => (
          <tr>
            {col?.map((c) => (
              <td
                style={{
                  //cursor: "wait",
                  paddingTop: "25px",
                  paddingBottom: "25px",
                }}
              >
                <div class="placeholder-glow1">
                  <span
                    class={`col-${this.getRandomNumber()} placeholder1`}
                  ></span>
                </div>
              </td>
            ))}
          </tr>
        ))}
      </React.Fragment>
    );
  }
}

export default TableLoader;
