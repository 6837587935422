import React, { Component, useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MessagingList from "./messagingList";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import http from "../../services/HttpService";
import config from "../../config.json";
import auth from "../../services/authService";
import UploadDocument from "../../comman/UploadDocument";
import { useDispatch, useSelector } from "react-redux";
import { addAppAdhocNewMessage, addAppMessage, getAddedNewMessageStatus, getAppAdhocMessages, getAppMsgList, getAppMsgReadStatus, loadAppAdhocMessages, loadAppMsgList, updateMessageRead, updateMsgListRead } from "../../app/store/messages";
import { getCurrentUser, getUserProfilePic, getUserProfilePicTwo, loadUserProfilePic, loadUserProfilePicTwo } from "../../app/store/auth";
import { getAlert } from "../../services/appAlerts";

const MessagingPopup = (props) => {

  const toolbar = [
    [
      "undo",
      "redo",
      "font",
      "fontSize",
      "formatBlock",
      //"paragraphStyle",
      //"blockquote",
      "bold",
      "underline",
      "italic",
      //"strike",
      //"subscript",
      //"superscript",
      "fontColor",
      "hiliteColor",
      //"textStyle",
      "removeFormat",
      //"outdent",
      //"indent",
      //"align",
      //"horizontalRule",
      //"list",
      //"lineHeight",
      //"table",
      "link",
      "image",
      //"showBlocks",
      //"codeView",
      //"preview",
      //"print",
      //"save",
      //"template",
    ],
  ];

  const { workflowTask, messageType, message,
    handleReadStatus } = props;

  const [messageList, setMessageList] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [attachmentShowHide, setAttachmentShowHide] = useState(false);
  const [content, setContent] = useState("");
  const [profilePicData, setProfilePicData] = useState(null);
  const [profilePicTwoData, setProfilePicTwoData] = useState(null);

  const appMsgListSelector = useSelector(getAppMsgList);
  const appMsgReadStatusSelector = useSelector(getAppMsgReadStatus);
  const appAdhocMessagesSelector = useSelector(getAppAdhocMessages);
  const addedNewMessageStatusSelector = useSelector(getAddedNewMessageStatus);
  const userProfilePicSelector = useSelector(getUserProfilePic);
  const userProfilePicSelectorTwo = useSelector(getUserProfilePicTwo);
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(loadUserProfilePic(currentUser.id));
    }
  }, []);

  useEffect(() => {
    if (userProfilePicSelector) {
      //let objectURL = 'data:image/jpeg;base64,' + userProfilePicSelector;
      setProfilePicData(userProfilePicSelector);
      //console.log(userProfilePicSelector, "rtr")
    }

  }, [userProfilePicSelector])

  useEffect(() => {
    if (userProfilePicSelectorTwo) {
      //let objectURL = 'data:image/jpeg;base64,' + userProfilePicSelectorTwo;
      setProfilePicTwoData(userProfilePicSelectorTwo);
      //console.log(userProfilePicSelector, "rtr")
    }

  }, [userProfilePicSelectorTwo])


  useEffect(() => {
    if (messageType === "Adhoc" && workflowTask?.wiid > 0) {
      dispatch(loadAppAdhocMessages(workflowTask.wiid));
    }
    else {
      if (message?.id > 0) {
        dispatch(loadAppMsgList(message.id));
      }
    }
    setDocuments([]);
    setContent("");
    setMessageList([]);
  }, [message, workflowTask]);

  useEffect(() => {
    if (appMsgListSelector.length > 0) {
      let msgList = [];
      appMsgListSelector.map((item) => {
        let author = "receive";
        let performer = item.fromName;
        if (item.fromId === parseInt(currentUser.id)) {
          author = "send";
          performer = item.fromName;
        }

        if (item.fromId !== parseInt(currentUser.id)) {
          dispatch(loadUserProfilePicTwo(item.fromId));
        }

        msgList.push({
          author: author,
          data: item.message,
          performer: performer,
          date: item.messageDate,
          documents: item.documents,
          id: item.id,
          readStatus: item.toReadStatus
        });
      });
      setMessageList(msgList);


      if (msgList.filter(x => x.readStatus !== true).length > 0 && message?.id)
        dispatch(updateMessageRead(message.id, currentUser.id))
    }
  }, [appMsgListSelector]);

  useEffect(() => {
    if (appMsgReadStatusSelector) {
      let msgItems = "";
      messageList.filter(m => m.readStatus !== true).map((x, key) => {
        if (key > 0) msgItems += ",";
        msgItems += x.id;
      })
      if (msgItems !== "") {
        handleReadStatus(msgItems);
        updateMsgListRead(false)
      }
    }
  }, [appMsgReadStatusSelector]);

  useEffect(() => {
    if (appAdhocMessagesSelector.length > 0) {
      let msgList = [];
      appAdhocMessagesSelector.map((item) => {
        let author = "receive";
        let performer = item.fromName;
        if (item.fromId === workflowTask.performerId) {
          author = "send";
          performer = item.fromName;
        }

        msgList.push({
          author: author,
          data: item.message,
          performer: performer,
          date: item.messageDate,
          documents: item.documents,
          id: item.id,
          readStatus: item.readStatus
        });
      });

      setMessageList(msgList);

      // if (msgList.filter(x => x.readStatus !== true).length > 0 && message?.id)
      //   dispatch(updateMessageRead(message.id))
    }
  }, [appAdhocMessagesSelector]);

  const handleChange = (newContent) => {
    setContent(newContent);
  };

  const onFileChange = async (event) => {
    const docs = [...documents];
    for (const file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        size: (file.size / 1024).toFixed(2),
        file: file,
      };
      docs.push(doc);
    }
    setDocuments(docs);
  };

  const handleClick = (event) => {
    console.log(event);
  };

  const onSubmit = () => {
    if (content.trim() !== "") {
      const data = new FormData();

      // Append message content
      data.append("message", content);

      // Append documents
      documents.forEach((file, key) => {
        data.append(`files[${key}]`, file.file);
      });

      // Check if it's an Adhoc message
      if (messageType === "Adhoc") {
        data.append("workflowTask", JSON.stringify(workflowTask));
        dispatch(addAppAdhocNewMessage(data));
      } else {
        // For regular message types
        const msg = { ...message };
        msg["receiverId"] = workflowTask.applicantId;
        data.append("performerId", currentUser.id);
        data.append("communication", JSON.stringify(message));
        dispatch(addAppMessage(data));
      }

      // Clear the form after submission
      setContent("");
      setDocuments([]);
      setAttachmentShowHide(false);
    } else {
      getAlert("", "Enter Message", "error");
    }
  };


  // const onSubmit = () => {
  //   if (content.trim() !== "" && content !== "") {
  //     const data = new FormData();
  //     data.append("message", content);
  //     documents.map((file, key) =>
  //       data.append("files[" + key + "]", file.file)
  //     );
  //     if (messageType === "Adhoc") {
  //       data.append("workflowTask", JSON.stringify(workflowTask));
  //       dispatch(addAppAdhocNewMessage(data));
  //     } else {
  //       const msg = { ...message };
  //       msg["receiverId"] = workflowTask.applicantId;
  //       data.append("performerId", currentUser.id);
  //       data.append("communication", JSON.stringify(message));
  //       dispatch(addAppMessage(data))
  //     }
  //   } else {
  //     getAlert("", "Enter Message", "error");
  //   }
  // };

  useEffect(() => {
    if (addedNewMessageStatusSelector) {
      if (message?.id > 0) {
        dispatch(loadAppMsgList(message.id));
        setContent("");
        setDocuments([]);
        setAttachmentShowHide(false);
      }
    }
  }, [addedNewMessageStatusSelector])

  // const handleFileRemove = (item) => {
  //   const index = documents.indexOf(item);
  //   let docs = [...documents];
  //   if (index !== -1) {
  //     docs.splice(index, 1);
  //     setDocuments(docs)
  //   }
  // };
  const handleFileRemove = (item) => {
    const updatedDocuments = documents.filter((doc) => doc !== item);
    setDocuments(updatedDocuments);
  };

  const handleAttachmentShowHide = () => {
    setAttachmentShowHide(!attachmentShowHide)
  }

  return (
    <>
      <div
        className="row"
        style={{
          maxHeight: 350,
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <div className="col-md-12 bg-white" style={{ minHeight: "300px" }}>
          {messageList.map((item) => (
            <MessagingList
              message={item}
              handleClick={handleClick}
              profilePicData={profilePicData}
              profilePicTwoData={profilePicTwoData}
            ></MessagingList>
          ))}
        </div>
      </div>
      <div className="row pt-3 pb-3 border-top">
        {documents.length > 0 && (
          <div className="file-preview">
            {documents.map((file, index) => (
              <div key={index} className="file-preview-item ml-2">
                <span>{file.name}</span>
                <span
                  className="file-size"
                  style={{
                    backgroundColor: "#f0f0f0",
                    marginLeft: "10px",
                    padding: "2px 5px",
                    borderRadius: "5px",
                  }}
                >
                  {file.size} KB
                </span>
                <button
                  className="btn btn-sm btn-link"
                  onClick={() => handleFileRemove(file)}
                >
                  <i className="fa fa-times text-red" aria-hidden="true"></i>
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="col-md-12">
          <SunEditor
            setOptions={{
              height: 50,
              buttonList: toolbar,
            }}
            onChange={handleChange}
            setContents={content}

          />
        </div>
        <div className="col-12 text-center mt-3 d-flex justify-content-center">
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => {
              fileInputRef.current.click(); // Clicking the hidden file input
            }}
          >
            <i className="fa fa-paperclip mr-2" aria-hidden="true"></i>
            Add
          </Button>

          <Button variant="outline-dark" size="sm" onClick={onSubmit}>
            <i className="fa fa-paper-plane-o mr-2" aria-hidden="true"></i>
            Send
          </Button>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={onFileChange}
          multiple
          style={{ position: "absolute", top: "-9999px" }}
        />
      </div>
    </>
  );
}

export default MessagingPopup;
