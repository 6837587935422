import React, { Component } from "react";
import { Card } from "react-bootstrap";
import Moment from "react-moment";
import http from "../services/HttpService";
import config from "../config.json";
import MessagingPopup from "./PortalForms/MessagingPopup";
import MsgWithApplicantPopup from "./PortalForms/MsgWithApplicantPopup";
import AddNewGroupMessage from "./AddNewGroupMessage";

class GroupMessaging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showHide: false,
      msgShowHide: false,
      msgId: 0,
      message: [],
      selectedMessage: {},
    };
  }

  async componentDidMount() {
    this.loadMessage();
  }

  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  handleMessagePopup = (message) => {
    this.setState({
      msgShowHide: !this.state.msgShowHide,
      selectedMessage: message,
    });
  };

  handleAddNewMessage = async (Msg, docs) => {
    const { WorkflowTask } = this.props;
    //console.log(Msg);
    //console.log(docs);
    const frmdata = new FormData();
    frmdata.append("task", JSON.stringify(WorkflowTask));
    frmdata.append("Msg", Msg);
    docs.map((file, key) => frmdata.append("files[" + key + "]", file.file));
    const result = await http.post(config.AddNewMessage, frmdata);
    this.handleModalShowHide();
  };

  loadMessage = async () => {
    const { WorkflowTask } = this.props;
    const { data: messaging } = await http.get(
      config.GetCommunication + WorkflowTask.ciid
    );
    //console.log(data);
    let msgUnreadCount = 0;
    messaging.map((item) => (msgUnreadCount += item.unreadCount));
    this.setState({ message: messaging, msgUnreadCount });
  };

  handleMessaging = (Msg) => {
    //console.log(Msg);
  };

  render() {
    const {
      showHide,
      msgShowHide,
      message: messaging,
      selectedMessage,
    } = this.state;

    const { WorkflowTask } = this.props;
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-sm btn-default mb-2"
          onClick={this.handleModalShowHide}
        >
          <i className="fa fa-plus mr-2" aria-hidden="true" />
          Create Group and Send Message(s)
        </button>
        <div className="bg-white table-responsive">
          <table className="table table-striped align-items-center table-flush mt-2">
            <thead className="thead-light-green">
              <tr>
                <th>S.No</th>
                <th>Date and Time</th>
                <th>Group</th>
                <th>Messages</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {messaging.length > 0 &&
                messaging.map((item) => (
                  <tr>
                    <td>{item.id}</td>
                    <td>
                      <Moment format="MMMM Do YYYY, h:mm A">
                        {item.addedDateTime}
                      </Moment>
                    </td>
                    <td>{item.receiver}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => this.handleMessagePopup(item)}
                      >
                        View/Reply
                      </button>
                    </td>
                    <td>{item.createdBy}
                    <br/>
                    {item.email}<br/>
                    {item.role}
                    {item.department}
                    {item.partner}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <MsgWithApplicantPopup
          showHide={showHide}
          handleModalShowHide={this.handleModalShowHide}
          handleAddNewMessage={this.handleAddNewMessage}
          WorkflowTask={WorkflowTask}
        ></MsgWithApplicantPopup>
        <AddNewGroupMessage
          showHide={showHide}
          handleModalShowHide={this.handleModalShowHide}
          handleAddNewMessage={this.handleAddNewMessage}
          WorkflowTask={WorkflowTask}
        ></AddNewGroupMessage>
        <MessagingPopup
          msgShowHide={msgShowHide}
          message={selectedMessage}
          handleMsgModalShowHide={this.handleMessagePopup}
          handleMessaging={this.handleMessaging}
        ></MessagingPopup>
      </React.Fragment>
    );
  }
}

export default GroupMessaging;
