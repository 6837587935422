import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDdrmsRepositoryDocuments, loadDdrmsRepositoryDocs } from "../../store/ddrmsDocument";

const UploadRepository = (props) => {
    const { workItem, setSelectedRepoDocs } = props;

    const [repoDocs, setRepoDocs] = useState([]);
    const [selectedDocs, setSelectedDocs] = useState([]);

    const ddrmsRepositoryDocumentsSelector = useSelector(getDdrmsRepositoryDocuments);

    const dispatch = useDispatch();

    useEffect(() => {
        if (workItem?.ciid > 0)
            dispatch(loadDdrmsRepositoryDocs(workItem.ciid));
    }, []);

    useEffect(() => {
        setRepoDocs(ddrmsRepositoryDocumentsSelector);
    }, [ddrmsRepositoryDocumentsSelector]);

    const handleSelectDoc = ({ currentTarget }) => {
        const id = currentTarget.name.split("_")[1];
        const existingDocs = [...selectedDocs];
        const index = existingDocs.findIndex((x) => x.id === parseInt(id));
        if (currentTarget.checked) {
            const obj = repoDocs.filter((x) => x.id === parseInt(id));
            if (obj.length > 0) existingDocs.push(obj[0]);
        } else {
            existingDocs.splice(index, 1);
        }
        setSelectedDocs(existingDocs);
        setSelectedRepoDocs(existingDocs)
    };

    const handleRepoSubmit = async () => {
        const { repoDocs } = this.state;
        this.setState({ submitProcess: true });
        const { workItem } = this.props;
        const frmData = new FormData();
        frmData.append("ciid", workItem.ciid);
        frmData.append("perfid", workItem.applicantId);
        repoDocs.map((file, key) =>
          frmData.append("files[" + key + "]", file.file)
        );
        // const { data: result } = await http.post(
        //   config.UploadDdrmsRepository,
        //   frmData
        // );
        // this.setState({ submitProcess: false });
        // this.getAlert("", "Added Successfully", "success");
        // this.loadRepoDocuments(workItem.ciid);
        // this.setState({ addingRepo: false });
      };

    return <>

        <div className="col-md-12 text-right mb-2">
            <a className="btn btn-outline-default btn-sm btn-min-width"
                href="https://ipcs-ddrms.azurewebsites.us/login" target="_blank">
                <i class="fa fa-plus" aria-hidden="true"></i> Add Document
            </a>
        </div>
        <div className="col-md-12 form-group">
            <div className="table-responsive bg-white border" style={{maxHeight:"270px",overflow:"auto"}}>
                <table className="table align-items-center table-flush table-hover table-striped">
                    <thead className="thead-light">
                        <tr>
                            <th>Select</th>
                            <th>ID</th>
                            <th>Document Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {repoDocs.map((item) => (
                            <tr>
                                <td>
                                    <input
                                        type="checkbox"
                                        name={`chkSelect_${item.id}`}
                                        id={`chkSelect_${item.id}`}
                                        onChange={handleSelectDoc}
                                    />
                                </td>
                                <td>{item.id}</td>
                                <td>
                                    <button className="btn btn-link pl-0 btn-sm">
                                        {item.name}
                                    </button>
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </>
}

export default UploadRepository;