import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Moment from "react-moment";
import config from "../../config.json";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import UploadDocPopup from "./UploadDocPopup";

class AppDocumnets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receivedDocuments: [],
      uploadDocShowHide: false,
      runningApplication: [],
    };
  }

  async componentWillReceiveProps(nextProps) {
    const { workflowId, currentApp } = nextProps;
    if (workflowId > 0) {
      const user = auth.getCurrentUser();
      if (user.type === "partner") {
        user.id = user.linkUser;
      }
      const { data: receivedDocuments } = await http.get(
        config.GetReceivedDocuments + user.id + "&chkId=" + workflowId
      );
      //console.log(receivedDocuments);
      const filterDocs = receivedDocuments.filter(
        (x) => x.ciid === currentApp.ciid
      );
      this.setState({ receivedDocuments: filterDocs });
    }
  }

  handleDownloadFile = async (item) => {
    try {
      const { data } = await http.get(config.DownloadDocument + item.id, {
        responseType: "blob",
      });
      if (data.size > 0) {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", item.documentName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }

    // const { data } = await http.get(config.DownloadDocument + item.docId);
    // if (data !== "Fail") {
    //   this.download(item.docName.trim(), data);
    // }
  };

  download(filename, text) {
    var element = document.createElement("a");
    element.setAttribute("href", text);
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  getExtension(filename) {
    return filename.split(".").pop().toLowerCase();
  }

  handleUploadDocModalShowHide = (docs) => {
    if (docs !== undefined) {
      this.setState({
        uploadDocShowHide: !this.state.uploadDocShowHide,
        runningApplication: [docs],
      });
    } else {
      this.setState({
        uploadDocShowHide: !this.state.uploadDocShowHide,
        runningApplication: [],
      });
    }
  };

  render() {
    const { receivedDocuments, uploadDocShowHide, runningApplication } =
      this.state;
    const { docShowHide, handleDocShowHide, currentApp } = this.props;
    const sendDoc =
      receivedDocuments && receivedDocuments.filter((x) => x.type === "send");
    const receiveDoc =
      receivedDocuments &&
      receivedDocuments.filter((x) => x.type === "received");
    return (
      <React.Fragment>
        <Modal show={docShowHide} size="lg">
          <Modal.Header closeButton onClick={() => handleDocShowHide("", 0)}>
            <Modal.Title>Received and Sent Documents</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn btn-sm btn-warning btn-round btn-icon mb-2"
                  onClick={() => this.handleUploadDocModalShowHide(currentApp)}
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </span>
                  <span className="btn-inner--text">Upload Document</span>
                </button>
              </div>
              <div className="col-lg-12">
                <h4>Received Documents</h4>
                <table className="table align-items-center table-flush table-hover table-striped">
                  <thead className="thead-light-pink">
                    <tr>
                      <th>App Id</th>
                      <th>Date</th>
                      <th>Document</th>
                      <th>Size</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {receiveDoc &&
                      receiveDoc.map((item) => (
                        <tr>
                          <td>{item.ciid}</td>
                          <td>
                            <Moment format="MMMM Do YYYY, h:mm A">
                              {item.createdDateTime}
                            </Moment>
                          </td>
                          <td>{item.documentName}</td>
                          <td>{item.docSize}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-primary"
                              title="Download Document"
                              onClick={() => this.handleDownloadFile(item)}
                            >
                              <i
                                className="fa fa-download"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="col-md-12">
                <h4>Sent Documents</h4>
                <table className="table align-items-center table-flush table-hover table-striped">
                  <thead className="thead-light-pink">
                    <tr>
                      <th>App Id</th>
                      <th>Date</th>
                      <th>Document</th>
                      <th>Size</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sendDoc &&
                      sendDoc.map((item) => (
                        <tr>
                          <td>{item.ciid}</td>
                          <td>
                            <Moment format="MMMM Do YYYY, h:mm A">
                              {item.createdDateTime}
                            </Moment>
                          </td>
                          <td>{item.documentName}</td>
                          <td>{item.docSize}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-primary"
                              title="Download Document"
                              onClick={() => this.handleDownloadFile(item)}
                            >
                              <i
                                className="fa fa-download"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => handleDocShowHide("", 0)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <UploadDocPopup
          uploadDocShowHide={uploadDocShowHide}
          handleUploadDocModalShowHide={this.handleUploadDocModalShowHide}
          runningApplication={runningApplication}
        ></UploadDocPopup>
      </React.Fragment>
    );
  }
}

export default AppDocumnets;
