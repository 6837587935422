import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Pagination from "../../comman/pagination";
import http from "../../services/HttpService";
import config from "../../config.json";
import auth from "../../services/authService";
import PaymentPopup from "../PaymentPopup";
import EmailMsgPopup from "./EmailMsgPopup";
import MessagingPopup from "../PortalForms/MessagingPopup";
import OmcActionPopup from "./OmcActionPopup";
import DispencingPopup from "./DispencingPopup";
import AppointmentPopup from "./AppointmentPopup";
import MdTrnsPopup from "./MdTrnsPopup";
import FollowUpPopup from "./FollowUpPopup";
import AdditionalCtrlPopup from "./AdditionalCtrlPopup";

class MyPatientBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideShowAll: false,
      notification: 0,
      accordianData: {},
      allApps: [],
      paymentShowHide: false,
      selectedWorkflowTask: {},
      mdCond: "",
      emailMsgShowHide: false,
      runningApplication: [],
      msgShowHide: false,
      selectedMessage: {},
      omcShowHide: false,
      dispencingShowHide: false,
      appointmentgShowHide: false,
      mdTrnsShowHide: false,
      flowUpShowHide: false,
      ctrlShowHide: false,
    };
  }

  async componentDidMount() {
    const user = auth.getCurrentUser();
    const { data: allApps } = await http.get(
      config.GetRegisteredApplicantApp + "Patient"
    );
    console.log(allApps);
    const { patientType } = this.props;
    let filterApps = [];
    if (patientType === "existing") {
      filterApps = allApps.filter(
        (x) => x.appStatus === 1 && x.orderId === user.id.toString()
      );
    } else {
      filterApps = allApps.filter(
        (x) => x.appStatus !== 1 && x.orderId === user.id.toString()
      );
    }
    this.setState({ allApps: filterApps });
    const { data: appliedApplication } = await http.get(
      config.GetPerformerApplication + user.id
    );
    const filterRuningApp = appliedApplication.filter(
      (x) => x.workflowType === 2 && x.pmId === 15
    );
    const currentWf = filterRuningApp[filterRuningApp.length - 1];
    if (currentWf !== "" && currentWf !== undefined) {
      const { data: runningApplication } = await http.get(
        config.GetRunningTask + currentWf.workflowId + "&performerId=" + user.id
      );
      this.setState({ runningApplication });
    }
  }

  handleSelect = (index, name) => {
    const accordianData = { ...this.state.accordianData };
    const checkIndex = accordianData[name];
    if (checkIndex === index) {
      accordianData[name] = null;
      this.setState({ accordianData });
    } else {
      accordianData[name] = index;
      this.setState({ accordianData });
    }
  };

  getWfPhasesColor(phase) {
    switch (phase) {
      case "Closed":
        return "btn-default";
      case "Review":
        return "btn-secondary bg-yellow border-0";
      case "Application":
        return "btn-primary";
      case "Pending":
        return "btn-info";
      case "Approved":
        return "btn-success";
      case "Denied":
        return "btn-danger";
      case "Withdrawn":
        return "btn-warning";
    }
  }

  handlePaymentModalShowHide = (obj) => {
    this.setState({
      paymentShowHide: !this.state.paymentShowHide,
      selectedWorkflowTask: obj,
    });
  };

  getApprovalId = (item) => {
    const dt = new Date(item.appStartDateTime);
    return (
      "PT" +
      dt.getDate() +
      "" +
      (dt.getMonth() + 1) +
      "" +
      dt.getHours() +
      "" +
      dt.getMinutes()
    );
  };

  getCaregiverDetail = (appData) => {
    if (appData === null) return "";
    const aWorkfinityApp = JSON.parse(appData);
    const appShortName = Object.keys(aWorkfinityApp)[1];
    const aWorkfinityAppEntity = aWorkfinityApp[appShortName];
    const cvData = aWorkfinityAppEntity["patientinformationself"];
    if (cvData !== null || cvData !== "") {
      return cvData["@Have_Caregiver"];
    }
    return "";
  };

  getMedicalConditions = async () => {
    const { data: application } = await http.get(config.GetApplication + 100);
    const aWorkfinityApp = JSON.parse(application.applicationData);
    const appShortName = Object.keys(aWorkfinityApp)[1];
    const aWorkfinityAppEntity = aWorkfinityApp[appShortName];
    console.log(aWorkfinityAppEntity);
    let mdCond = "";
    Object.keys(aWorkfinityAppEntity["patientmedicaldiagnosis"]).forEach(
      function (keyValue1, index1, map1) {
        //console.log(keyValue1);
        if (keyValue1 !== "EntityId") {
          //console.log(aWorkfinityAppEntity["patientmedicaldiagnosis"][keyValue1]);
          mdCond += aWorkfinityAppEntity["patientmedicaldiagnosis"][
            keyValue1
          ].replace("patientmedicaldiagnosis.", "");
          mdCond += " <br/> ";
        }
      }
    );

    //mdCond.replace('patientmedicaldiagnosis.','');

    this.setState({ mdCond });
  };

  handleEmailMsgShowHide = () => {
    this.setState({ emailMsgShowHide: !this.state.emailMsgShowHide });
  };

  handleCurrentTask = async () => {
    const { runningApplication } = this.state;
    if (runningApplication.length > 0) {
      const wiid = runningApplication[0].wiid;
      const { data: workflowTask } = await http.get(
        config.GetWorkflowTask + wiid
      );
      //console.log(workflowTask);
      this.handleWorkflowTask(workflowTask);
    }
  };

  handleLapPatient = async () => {
    const user = auth.getCurrentUser();
    const newApplication = {
      workflowId: 73,
      performerId: parseInt(user.id),
      applicationId: 1,
    };

    this.setState({ showHide: true });

    const { data } = await http.post(
      config.StartNewApplication,
      newApplication
    );
    console.log(data);

    const { data: workItemTask } = await http.post(
      config.GetCurrentWorkflowTask,
      data
    );

    console.log(workItemTask);

    if (workItemTask.formId === null) {
      setTimeout(async () => {
        const { data: workItemTask } = await http.post(
          config.GetCurrentWorkflowTask,
          data
        );
        this.handleWorkflowTask(workItemTask);
      }, 3000);
    } else {
      this.handleWorkflowTask(workItemTask);
    }
  };

  handleWorkflowTask = (workflowTask) => {
    if (workflowTask.formId > 0) {
      if (workflowTask.formType === "CF") {
        this.props.history.push({
          pathname: "/" + workflowTask.formName,
          state: workflowTask,
        });
      } else {
        this.props.history.push({
          pathname: "/PortalForm",
          state: workflowTask,
        });
      }
    }
  };

  handleMessagePopup = (message) => {
    this.setState({
      msgShowHide: !this.state.msgShowHide,
      selectedMessage: message,
    });
  };

  handleOmcModalShowHide = (obj) => {
    this.setState({
      omcShowHide: !this.state.omcShowHide,
      selectedWorkflowTask: obj,
    });
  };

  handleDispencingModalShowHide = (obj) => {
    this.setState({
      dispencingShowHide: !this.state.dispencingShowHide,
      selectedWorkflowTask: obj,
    });
  };

  handleAppointmentModalShowHide = (obj) => {
    this.setState({
      appointmentgShowHide: !this.state.appointmentgShowHide,
      selectedWorkflowTask: obj,
    });
  };

  handleMdTrnsModalShowHide = (obj) => {
    this.setState({
      mdTrnsShowHide: !this.state.mdTrnsShowHide,
      selectedWorkflowTask: obj,
    });
  };

  handleFlowUpModalShowHide = (obj) => {
    this.setState({
      flowUpShowHide: !this.state.flowUpShowHide,
      selectedWorkflowTask: obj,
    });
  };

  handleCtrlModalShowHide = (obj) => {
    this.setState({
      ctrlShowHide: !this.state.ctrlShowHide,
      selectedWorkflowTask: obj,
    });
  };

  handleDownloadFile = async (item) => {
    try {
      const { data } = await http.get(config.DownloadDocument + item.docId, {
        responseType: "blob",
      });
      if (data.size > 0) {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", item.docName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }

    // const { data } = await http.get(config.DownloadDocument + item.docId);
    // if (data !== "Fail") {
    //   this.download(item.docName.trim(), data);
    // }
  };

  render() {
    const {
      allApps,
      paymentShowHide,
      selectedWorkflowTask,
      emailMsgShowHide,
      selectedMessage,
      msgShowHide,
      omcShowHide,
      dispencingShowHide,
      appointmentgShowHide,
      mdTrnsShowHide,
      flowUpShowHide,
      ctrlShowHide,
    } = this.state;
    const usrType = localStorage.getItem("userType");
    const { patientType } = this.props;
    return (
      <div className="row">
        <div className="col-md-12 text-right form-group backgroundColor">
          <div className="row">
            <div className="col-6 d-flex align-items-center">
              <h1 className="mb-0">Patient Enrollment</h1>
            </div>
            <div className="col-6 text-right">
              {usrType === "Health Care Practitioners (HCP)" &&
                patientType === "new" && (
                  <React.Fragment>
                    <button
                      className="btn btn-default"
                      onClick={this.handleLapPatient}
                    >
                      Enroll LAP Patient
                    </button>
                    <button
                      className="btn btn-default"
                      onClick={this.handleCurrentTask}
                    >
                      Enroll New Patient
                    </button>
                  </React.Fragment>
                )}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card card-body shadow-none border">
            <div className="row">
              <div className="col-md-6 form-group">
                <label htmlFor="" className="form-control-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                />
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="" className="form-control-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                />
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="" className="form-control-label">
                  Date of Birth
                </label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Date of Birth"
                />
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="" className="form-control-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                />
              </div>
              <div className="col-md-12 text-center">
                <button className="btn btn-primary">Search</button>
                <button className="btn btn-danger">Cancel</button>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table align-items-center table-hover">
              <thead className="thead-light">
                <tr>
                  <th>
                    Date
                    <br />
                    Enrolled
                  </th>
                  <th>
                    Reg. ID <br></br>
                    Patient ID<br></br>
                    Approval Status
                  </th>
                  <th>Patient</th>
                  <th>
                    Caregiver
                    <br /> Allowed
                  </th>
                  <th>
                    Program Status
                    <br /> Documents
                  </th>
                  <th>
                    Medical <br />
                    Condition
                  </th>
                  <th>
                    Date
                    <br />
                    Approved
                  </th>
                  <th>
                    Survey
                    <br />
                    Due
                  </th>
                  <th>
                    View
                    <br />
                    Details
                  </th>
                  <th>
                    OMC <br />
                    Action
                  </th>
                  <th>
                    Dispencing <br />
                    Center
                  </th>
                  <th>
                    Patient <br />
                    Appointment
                    <br />
                    Scheduling
                  </th>
                  <th>
                    Medical <br />
                    Transactions
                  </th>
                  <th>
                    Patient Follow Up <br />
                    and Adverse
                    <br />
                    effect Reporting
                  </th>
                  <th>
                    Group <br />
                    Messaging
                  </th>
                  <th>
                    Patient <br />
                    Messaging
                  </th>
                  <th>
                    Additional <br /> Controls
                  </th>
                </tr>
              </thead>
              <tbody>
                {allApps.map((item) => (
                  <tr>
                    <td className="align-top">
                      <Moment format="MMM Do YYYY">{item.regDateTime}</Moment>
                      <br></br>
                      <button
                        className="btn btn-link btn-sm p-0 m-0"
                        onClick={this.handleEmailMsgShowHide}
                      >
                        View Message
                      </button>
                    </td>
                    <td className="align-top">
                      {item.regId} <br />
                      {item.appStatus === 1 && (
                        <React.Fragment>
                          {this.getApprovalId(item)}
                          <br />
                          <span
                            className="badge rounded-0 pt-2 pb-2 text-white"
                            style={{
                              fontSize: "11px",
                              backgroundColor: "#4caf50",
                            }}
                          >
                            OMC Approved
                          </span>
                        </React.Fragment>
                      )}
                    </td>
                    <td className="align-top">
                      {item.applicantName} <br></br>
                      Phone : {item.phone} <br></br>
                      Email : {item.email} <br></br>
                      Address : {item.state} {item.county}
                    </td>
                    <td className="align-top">
                      {this.getCaregiverDetail(item.appData)}
                    </td>
                    <td className="align-top">
                      {item.appStartDateTime !== null && (
                        <React.Fragment>
                          <Moment format="MMM Do YYYY">
                            {item.appStartDateTime}
                          </Moment>
                          <br></br>
                          <Moment format="h:mm A">
                            {item.appStartDateTime}
                          </Moment>
                          <br></br>
                          <Link
                            to={{
                              pathname: "/ApplicationSummary",
                              state: { task: item },
                            }}
                            className="btn btn-link btn-sm p-0 m-0"
                          >
                            Application Forms
                          </Link>
                          <br />
                          {item.paymentDetails !== null && (
                            <React.Fragment>
                              <button
                                className="btn btn-link btn-sm p-0 m-0"
                                onClick={() =>
                                  this.handlePaymentModalShowHide(item)
                                }
                              >
                                Payment Receipt
                              </button>
                              <br />
                            </React.Fragment>
                          )}

                          {item.appDocuments !== null &&
                            item.appDocuments.map((doc) => (
                              <React.Fragment>
                                <button
                                  className="btn btn-link p-0 m-0 btn-sm"
                                  onClick={() => this.handleDownloadFile(doc)}
                                >
                                  {doc.docName}
                                </button>
                                <br></br>
                              </React.Fragment>
                            ))}
                        </React.Fragment>
                      )}
                    </td>
                    <td className="align-top">
                      {this.state.mdCond.split("<br/>").map((x) => (
                        <React.Fragment>
                          {x} <br></br>
                        </React.Fragment>
                      ))}
                    </td>
                    <td className="align-top">
                      {item.appStatus === 1 && (
                        <React.Fragment>
                          <Moment format="MMM Do YYYY">
                            {item.appStartDateTime}
                          </Moment>
                          <br></br>
                          <Moment format="h:mm A">
                            {item.appStartDateTime}
                          </Moment>
                        </React.Fragment>
                      )}
                    </td>
                    <td className="align-top">
                      {item.isSurveyAvailable ? (
                        item.isSurveyStarted ? (
                          <React.Fragment>
                            <span
                              className="badge rounded-0 pt-2 pb-2 text-white"
                              style={{
                                fontSize: "11px",
                                backgroundColor: "#4caf50",
                              }}
                            >
                              Started
                            </span>
                            <br />
                            <button
                              className="btn btn-link btn-sm p-0 m-0 text-underline"
                              onClick={() => this.handleRemainder(item.regId)}
                            >
                              Send Remainder
                            </button>
                            <br />
                            <button
                              className="btn btn-link btn-sm p-0 m-0"
                              title="View message"
                              onClick={this.handleRemainderMessage}
                            >
                              <span className="badge badge-warning">
                                <i
                                  class="fa fa-envelope mr-1"
                                  aria-hidden="true"
                                ></i>
                                {item.remainderCount === null
                                  ? 0
                                  : item.remainderCount}
                              </span>
                            </button>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <span
                              className="badge rounded-0 pt-2 pb-2 text-white"
                              style={{
                                fontSize: "11px",
                                backgroundColor: "#ec0c38",
                              }}
                            >
                              Not Started
                            </span>
                            <br />
                            <button
                              className="btn btn-success btn-sm mt-2"
                              onClick={() =>
                                this.handleSurveyApplication(item.appId)
                              }
                            >
                              Send
                            </button>
                          </React.Fragment>
                        )
                      ) : (
                        <React.Fragment>
                          <span
                            className="badge rounded-0 pt-2 pb-2 text-white"
                            style={{
                              fontSize: "11px",
                              backgroundColor: "#ec0c38",
                            }}
                          >
                            Not Started
                          </span>
                          <br />
                          <button
                            className="btn border btn-sm mt-2"
                            title="Send Survey after application closed"
                          >
                            Send
                          </button>
                        </React.Fragment>
                      )}
                    </td>
                    <td className="align-top">
                      <Link
                        className="btn btn-sm btn-primary"
                        to={{
                          pathname: "PatientDetails",
                          state: { obj: item },
                        }}
                      >
                        View
                      </Link>
                    </td>
                    <td className="align-top">
                      <button
                        className="btn btn-outline-default btn-sm"
                        onClick={() => this.handleOmcModalShowHide(item)}
                      >
                        View
                      </button>
                    </td>
                    <td className="align-top">
                      <button
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => this.handleDispencingModalShowHide(item)}
                      >
                        View
                      </button>
                    </td>
                    <td className="align-top">
                      <button
                        className="btn btn-outline-success btn-sm"
                        onClick={() =>
                          this.handleAppointmentModalShowHide(item)
                        }
                      >
                        View
                      </button>
                    </td>
                    <td className="align-top">
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.handleMdTrnsModalShowHide(item)}
                      >
                        View
                      </button>
                    </td>
                    <td className="align-top">
                      <button
                        className="btn btn-outline-warning btn-sm"
                        onClick={() => this.handleFlowUpModalShowHide(item)}
                      >
                        View
                      </button>
                    </td>
                    <td className="align-top">
                      <button
                        className="btn btn-outline-success btn-sm"
                        onClick={() => this.handleMessagePopup(item)}
                      >
                        View/Reply
                      </button>
                    </td>
                    <td className="align-top">
                      <button
                        className="btn btn-outline-success btn-sm"
                        onClick={() => this.handleMessagePopup(item)}
                      >
                        View/Reply
                      </button>
                    </td>
                    <td className="align-top">
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.handleCtrlModalShowHide(item)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination></Pagination>
        </div>
        <PaymentPopup
          paymentShowHide={paymentShowHide}
          handlePaymentModalShowHide={this.handlePaymentModalShowHide}
          selectedObj={selectedWorkflowTask}
        ></PaymentPopup>
        <EmailMsgPopup
          emailMsgShowHide={emailMsgShowHide}
          handleEmailMsgShowHide={this.handleEmailMsgShowHide}
        ></EmailMsgPopup>

        <MessagingPopup
          msgShowHide={msgShowHide}
          message={selectedMessage}
          handleMsgModalShowHide={this.handleMessagePopup}
          handleMessaging={this.handleMessaging}
        ></MessagingPopup>

        <OmcActionPopup
          omcShowHide={omcShowHide}
          handleOmcModalShowHide={this.handleOmcModalShowHide}
          selectedObj={selectedWorkflowTask}
        ></OmcActionPopup>

        <DispencingPopup
          dispencingShowHide={dispencingShowHide}
          handleDispencingModalShowHide={this.handleDispencingModalShowHide}
          selectedObj={selectedWorkflowTask}
        ></DispencingPopup>

        <AppointmentPopup
          appointmentgShowHide={appointmentgShowHide}
          handleAppointmentModalShowHide={this.handleAppointmentModalShowHide}
          selectedObj={selectedWorkflowTask}
        ></AppointmentPopup>

        <MdTrnsPopup
          mdTrnsShowHide={mdTrnsShowHide}
          handleMdTrnsModalShowHide={this.handleMdTrnsModalShowHide}
          selectedObj={selectedWorkflowTask}
        ></MdTrnsPopup>

        <FollowUpPopup
          flowUpShowHide={flowUpShowHide}
          handleFlowUpModalShowHide={this.handleFlowUpModalShowHide}
          selectedObj={selectedWorkflowTask}
        ></FollowUpPopup>

        <AdditionalCtrlPopup
          ctrlShowHide={ctrlShowHide}
          handleCtrlModalShowHide={this.handleCtrlModalShowHide}
          selectedObj={selectedWorkflowTask}
        ></AdditionalCtrlPopup>
      </div>
    );
  }
}

export default MyPatientBody;
