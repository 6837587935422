import React, { Component, useEffect, useState } from "react";
import config from "../config.json";
import http from "../services/HttpService";
import { useDispatch, useSelector } from "react-redux";
import { getConfigurationReport, loadConfigReport } from "../app/store/dashboard";

const ApplicationStatics = () => {
  const [configReport, setConfigReport] = useState(null);

  const configurationReportSelector = useSelector(getConfigurationReport)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadConfigReport());
  }, []);

  useEffect(() => {
    setConfigReport(configurationReportSelector);
  }, [configurationReportSelector]);

  const getBoxDesign = (name, count) => {
    return (
      <div className="card card-stats shadow-none border mb-0 mt-4">
        <div className="card-body p-3">
          <div className="row">
            <div className="col">
              <h5 className="card-title text-uppercase text-muted mb-0">
                {name}
              </h5>
              <span className="h2 font-weight-bold mb-0">Total : {count}</span>
            </div>
            <div className="col-auto">
              <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                <i className="fa fa-cog" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    configReport && <div className="row">
      <div className="col-xl-3 col-md-3">
        {getBoxDesign("Workflows (All Versions)", configReport.workflow)}
      </div>
      <div className="col-xl-3 col-md-3">
        {getBoxDesign(
          "Subprocesses (All Versions)",
          configReport.subProcess
        )}
      </div>
      <div className="col-xl-3 col-md-3">
        {getBoxDesign("Rules", configReport.rules)}
      </div>
      {/* <div className="col-xl-3 col-md-3">
        {getBoxDesign("APIs/Interfaces", 1)}
      </div> */}
      <div className="col-xl-3 col-md-3">
        {getBoxDesign("Forms", configReport.forms)}
      </div>
      <div className="col-xl-3 col-md-3">
        {getBoxDesign("Forms Libraries", configReport.formLibrary)}
      </div>
      <div className="col-xl-3 col-md-3">
        {getBoxDesign("Documents", configReport.documents)}
      </div>
      <div className="col-xl-3 col-md-3">
        {getBoxDesign("Document Storage", 0)}
      </div>
    </div>
  );
}

export default ApplicationStatics;
