import React, { useEffect, useState } from "react";
import Input from "../../../comman/input";
import { Modal, Button } from "react-bootstrap";
import TextArea from "../../../comman/textarea";
import { useDispatch, useSelector } from "react-redux";
import { addReqDocuments, documentUploaded, getReqDocAddedStatus, getUploadDocumentId, uploadDocument } from "../../store/documents";
import { getAlert } from "../../../services/appAlerts";
import { getCurrentUser } from "../../store/auth";

const UploadOther = (props) => {

    const { handleOtherDocShowHide, otherDocShowHide, workflowTask } = props;

    const [docName, setDocName] = useState("");
    const [docNotes, setDocNotes] = useState("");
    const [documents, setDocuments] = useState([]);
    const [btnProcess, setBtnProcess] = useState(null);

    const reqDocAddedStatusSelector = useSelector(getReqDocAddedStatus);
    const uploadDocumentIdSelector = useSelector(getUploadDocumentId);

    const dispatch = useDispatch();
    const currentUser = getCurrentUser();

    useEffect(() => {
        if (!otherDocShowHide) {
            setDocuments([]);
            setDocName("");
            setDocNotes("");
        }
    }, [otherDocShowHide])

    const handleSubmit = () => {
        setBtnProcess(true);
        if (documents.length > 0) {
            let wfTask = { ...workflowTask };
            wfTask.performerId = wfTask.applicantId;
            let updType = "sharing";
            let isPwd = "";

            var frmData = new FormData();
            frmData.append("workflowTask", JSON.stringify(wfTask));
            frmData.append("userId", currentUser.id);
            documents.map((file, key) =>
                frmData.append("files[" + key + "]", file.file)
            );
            frmData.append("docType", "req");
            frmData.append("updType", updType);
            frmData.append("isPwd", isPwd);
            dispatch(uploadDocument(frmData));
        }
        else {
            const frmObj = {
                "formId": 0,
                "documnetName": docName,
                "notes": docNotes,
                "ciid": workflowTask.ciid,
                "iiid": workflowTask.iiid,
                "wiid": workflowTask.wiid,
                "performerId": workflowTask.applicantId,
                "addedBy": currentUser.id
            }
            dispatch(addReqDocuments(frmObj));
        }
    }

    useEffect(() => {
        if (uploadDocumentIdSelector) {
            const frmObj = {
                "formId": 0,
                "documnetName": docName,
                "notes": docNotes,
                "ciid": workflowTask.ciid,
                "iiid": workflowTask.iiid,
                "wiid": workflowTask.wiid,
                "performerId": workflowTask.applicantId,
                "addedBy": currentUser.id,
                "refDocId": uploadDocumentIdSelector
            }
            dispatch(addReqDocuments(frmObj));
            dispatch(documentUploaded(null));

        }
    }, [uploadDocumentIdSelector]);

    useEffect(() => {
        if (reqDocAddedStatusSelector) {
            setBtnProcess(false);
            getAlert("", "Saved Successfully", "success");
            setDocName("");
            setDocNotes("");
            setDocuments([]);
            dispatch(documentUploaded(null));
            handleOtherDocShowHide("save");
        }
    }, [reqDocAddedStatusSelector])

    const handleOnChange = (obj) => {
        console.log(obj);
    }

    const handleFileChange = (event) => {
        const docs = [];
        for (const file of event.target.files) {
            var doc = {
                name: file.name,
                type: file.type,
                file: file,
                size: file.size,
            };
            docs.push(doc);
        }
        setDocuments(docs);
    }

    return <>
        <Modal show={otherDocShowHide} size="xl">
            <Modal.Header closeButton onHide={handleOtherDocShowHide}>
                <Modal.Title>Upload Required Document</Modal.Title>
            </Modal.Header>
            <Modal.Body className="border">
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            name="docName"
                            value={docName}
                            label="Document Name"
                            onChange={({ target }) => setDocName(target.value)}
                        />
                    </div>
                    <div className="col-md-12">
                        <TextArea
                            name="docNotes"
                            value={docNotes}
                            label="Document Notes"
                            onChange={({ target }) => setDocNotes(target.value)}
                        />
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="refDoc" className="form-control-label">
                            Upload Reference Document</label>
                        <input
                            type="file"
                            name="refDoc"
                            id="refDoc"
                            className="form-control"
                            onChange={handleFileChange}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-outline-danger" onClick={handleOtherDocShowHide}>
                    <i class="fa fa-times"></i> Close
                </button>
                <button className="btn btn-sm btn-outline-default" disabled={btnProcess} onClick={handleSubmit}>
                    {btnProcess ? <i class="fa fa-circle-o-notch fa-spin"></i> : <i class="fa fa-check"></i>} Save
                </button>
            </Modal.Footer>
        </Modal>
    </>

}

export default UploadOther;