import React, { Component, useEffect, useState } from "react";
import _ from "lodash";
import Moment from "react-moment";
import http from "../services/HttpService";
import auth from "../services/authService";
import geo from "../services/geoService";
import config from "../config.json";
import BprmForm from "../comman/BprmForm";
import ToastTemplate from "../comman/toastTemplate";
import SubForm from "./PortalForms/SubForm";
import PageHeader from "./PageHeader";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import readXlsxFile from "read-excel-file";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useLocation, useNavigate } from "react-router-dom";
import countryISDCodes from "../services/countryCodes.json";
import SSN_textbox from "../BprmControls/SSN_textbox";
import Phone_textbox from "../BprmControls/Phone_textbox";
import Other_textbox from "../BprmControls/Other_textbox";
import DecryptAppData from "./DecryptAppData";
import { useDispatch, useSelector } from "react-redux";
import { getAppSummary, getAppUpdates, loadAppSummary, loadAppUpdates } from "../app/store/application";
import WorkflowElement from "../app/components/WorkflowElement";
import { getAlert } from "../services/appAlerts";
import PartnerInfo from "../app/components/PartnerInfo";
import CandidateInfo from "../app/components/CandidateInfo";
import { Tab, Tabs } from "react-bootstrap";

export default function ApplicationSummary() {

  const state = {
    route: [],
    appSummary: {},
    currentSequence: 1,
    data: {},
    errors: {},
    wfForm: {},
    encryptionDecryptions: {},
    rules: [],
    workItem: {},
    showHide: false,
    processId: 0,
    formProcess: [],
    isDisabled: false,
    stateList: [],
    stateCountyCity: [],
    aWorkfinityAppEntity: {},
    aWorkfinityApp: {},
    appUpdateHistory: [],
    fieldShowHide: false,
    selectedUpdateHistory: {},
    subformDataType: [],
    formJosn: "",
    countryCodes: [],
    countryList: [],
    decryptShowHide: false,
    codeIsVerified: false,
  };

  const [route, setRoute] = useState([]);
  const [appSummary, setAppSummary] = useState("");
  const [wfTask, setWfTask] = useState("");
  const [updatedStateData, setUpdatedStateData] = useState("");
  const [currentSequence, setCurrentSequence] = useState(1);
  const [decryptShowHide, setDecryptShowHide] = useState(false);
  const [codeIsVerified, setCodeIsVerified] = useState(false);
  const [viewType, setViewType] = useState("View");
  const [appType, setAppType] = useState("");
  const [applicantObj, setApplicantObj] = useState(null);
  const [processType, setProcessType] = useState(null);
  const [appUpdates, setAppUpdates] = useState([]);
  const [countryCodes, setCountryCodes] = useState(countryISDCodes);
  const [selectedKey, setSelectedKey] = useState("home");

  const appSummarySelector = useSelector(getAppSummary);
  const appUpdateSelector = useSelector(getAppUpdates);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const state = location.state;
    if (state?.task) {
      dispatch(loadAppSummary(state.task.ciid));
      dispatch(loadAppUpdates(state.task.ciid));
      setWfTask({ formId: 0, ciid: state.task.ciid });
    }

    if (state?.viewType === "edit")
      setViewType("Edit");

    if (state?.appType) setAppType(state.appType);
    if (state?.applicant) setApplicantObj(state.applicant);
    if (state?.processType) setProcessType(state.processType)
  }, []);

  useEffect(() => {
    if (appSummarySelector !== "") {
      setAppSummary(appSummarySelector);
      const task = { ...wfTask };
      const frm = appSummarySelector.applicationForms.filter(x => x.formSequence === currentSequence);
      if (frm.length > 0) {
        task.formId = frm[0].formId;
        setWfTask(task);
      }
      if (appUpdateSelector.length === 0) {
        setUpdatedStateData(appSummarySelector.applicationData)
      }
    }

    if (appUpdateSelector.length > 0) {
      setAppUpdates(appUpdateSelector);
    }

  }, [appSummarySelector, appUpdateSelector]);

  const handleNextTask = () => {
    console.log("test");
  };

  const handleChangeForms = () => {

  }

  const handleDecryptShowHide = () => {
    setDecryptShowHide(!decryptShowHide);
  };

  const verifySecurityCode = (code) => {
    if (code !== "12345") {
      getAlert("Invalid Code", "", "error");
    } else {
      getAlert("Authentication Successful", "", "success");
      setCodeIsVerified(true);
      setDecryptShowHide(false);
    }
  };

  const handleNextForm = () => {
    const { currentSequence, appSummary } = this.state;
    if (
      currentSequence >= 1 &&
      currentSequence < appSummary.applicationForms.length
    ) {
      const nextForm = currentSequence + 1;
      const currentForm = appSummary.applicationForms[nextForm];
      //this.loadCMDropdownSubform(currentForm);
      //this.setState({ currentSequence: nextForm });
    }
  };

  const handlePreviousForm = () => {
    const { currentSequence, appSummary } = this.state;
    if (
      currentSequence > 1 &&
      currentSequence <= appSummary.applicationForms.length
    ) {
      const previousForm = currentSequence - 1;
      const currentForm = appSummary.applicationForms[previousForm];
      //this.loadCMDropdownSubform(currentForm);
      //this.setState({ currentSequence: previousForm });
    }
  };

  const getUpdateFields = (item) => {
    const fields = JSON.parse(item.updatedField);
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Field Name</th>
            <th>Original Value</th>
            <th>Updated Value</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((upd) => (
            <tr>
              <td>{upd.Label}</td>
              <td>{upd.PreviousValue}</td>
              <td>{upd.NewValue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const handleUpdateView = (item) => {
    setUpdatedStateData(item.stateData);
  };

  const handleViewSubmitData = () => {
    if (appSummary?.applicationData) setUpdatedStateData(appSummary.applicationData);
  };

  const handleTabSelect = (tab) => {
    setSelectedKey(tab);
  }

  return (
    <React.Fragment>
      <div className="col-md-12 text-right mb-2">

      <button
            className="btn btn-sm btn-outline-default btn-min-width"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-arrow-left mr-2"></i>Back
          </button>
</div>
      {applicantObj && appType === "partner" && (
        <div className="col-md-12">
          <PartnerInfo partner={applicantObj} type={processType} appType={appType} />
        </div>
      )}
      {applicantObj && appType !== "partner" && (
        <div className="col-md-12">
          <CandidateInfo candidate={applicantObj} />
        </div>
      )}
      <div className="col-md-12 mt-3">
        <Tabs
          activeKey={selectedKey}
          onSelect={handleTabSelect}
          id="uncontrolled-tab-example"
          className="h3 border border-right-0 border-left-0 border-top-0 font-weight-normal"
        >
          <Tab eventKey="home" title={`${appType === "partner" ? "Enrollment" : "Onboarding"} Forms`}
            tabClassName="page-tab border border-left-0 border-right-0 border-top-0">
            <Tab.Content className="border p-3 border-top-0">
              <div className="row">
                <div className="col-md-12 mb-2">
                  <button
                    className="btn btn-outline-default btn-sm btn-min-width"
                    onClick={handleViewSubmitData}
                  >
                    View Submitted Data
                  </button>
                  <button
                    className="btn btn-outline-default btn-sm btn-min-width"
                    onClick={handleDecryptShowHide}
                  >
                    Decrypt
                  </button>
                </div>
              </div>

              {wfTask?.formId > 0 && <WorkflowElement
                wfTask={wfTask}
                handleNextTask={handleNextTask}
                viewType={viewType === "View" ? true : false}
                appSummary={true}
                codeVerified={codeIsVerified}
                updatedStateData={updatedStateData}
              />
              }
            </Tab.Content>
          </Tab>
          <Tab eventKey="profile" title="Enrollment Data Edit Log History" tabClassName="page-tab border border-top-0 radius-0">
            <Tab.Content className="border p-3 border-top-0">
              <div className="table-responsive">
                <table className="table align-items-center table-flush border">
                  <thead className="thead-light">
                    <tr>
                      <th>Version #</th>
                      <th>Date & Time</th>
                      <th>Performer</th>
                      <th>View</th>
                      <th>Updated Field</th>
                    </tr>
                  </thead>
                  <tbody>
                    {appUpdates.map((item) => (
                      <tr>
                        <td>{item.id}</td>
                        <td>
                          <Moment format="MMM Do YYYY, h:mm a">
                            {item.createdDateTime}
                          </Moment>
                        </td>
                        <td>{item.createdBy}</td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleUpdateView(item)}
                          >
                            View
                          </button>
                        </td>
                        <td>{getUpdateFields(item)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Tab.Content>
          </Tab>

        </Tabs>


      </div>
      <DecryptAppData
        decryptShowHide={decryptShowHide}
        handleDecryptShowHide={handleDecryptShowHide}
        verifySecurityCode={verifySecurityCode}
      ></DecryptAppData>
    </React.Fragment>
  );
}
