import React, { Component, useEffect, useState } from "react";
import Moment from "react-moment";
import http from "../services/HttpService";
import config from "../config.json";
import ViewForm from "../components/PortalForms/ViewForm";
import AddEditNotes from "../components/OMCRegistry/AddEditNotes";
import ViewFormModal from "./ViewFormModal";
import { getFieldData } from "../services/appService";
import useDocDownload from "../app/hooks/useDocDownload";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments, loadDocuments } from "../app/store/documents";
import { getAppFormLibrary, loadAppLibraryForms } from "../app/store/forms";

const InsuranseInformation = (props) => {
  const { workflowTask, item, app, setViewBprmFormShowHide } = props;

  const [formsLib, setFormsLib] = useState([]);
  const [appDocuments, setAppDocuments] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [achFormShowHide, setAchFormShowHide] = useState(false);
  const [noteShowHide, setNoteShowHide] = useState(false);

  const appFormLibrarySelector = useSelector(getAppFormLibrary);
  const documentsSelector = useSelector(getDocuments);

  const [handleDownloadFile] = useDocDownload()

  const dispatch = useDispatch();

  useEffect(() => {
    if (workflowTask?.ciid) {
      dispatch(loadAppLibraryForms(workflowTask.ciid));
      dispatch(loadDocuments(workflowTask.ciid));
    }
  }, []);

  useEffect(() => {
    if (appFormLibrarySelector.length > 0) {
      const achForms = appFormLibrarySelector.filter(x => x.formType === "Insurance Information");
      setFormsLib(achForms);
    }
  }, [appFormLibrarySelector]);

  useEffect(() => {
    setAppDocuments(documentsSelector);
  }, [documentsSelector]);

  const handleFormShowHide = (f) => {
    setSelectedForm(f);
    setAchFormShowHide(!achFormShowHide);
    setViewBprmFormShowHide(!achFormShowHide);
  };

  const handleNoteShowHide = (type, obj) => {
    if(type === "save"){
      setSelectedForm(null)
      setNoteShowHide(false);
    }else{
      setSelectedForm(obj);
      setNoteShowHide(!noteShowHide);
    }
  };

  const handleCOIDoc = (item) => {
    const docs = appDocuments.filter((x) => x.uploadType === `insurance_${item.id}`);
    if (docs.length === 0)
      return <span> Pending </span>;

    return <>
      <button
        className="btn btn-link btn-sm p-0 text-underline"
        title="Download Document"
        onClick={() => handleDownloadFile(docs[0])}
      >
        {docs[0].docName}
      </button>
      <br />
      {docs[0].remarks}
    </>

  }

  const getModifiedFiledData = (ddType, name) => {
    const dt = getFieldData(app?.applicationData, ddType, name);
    if (dt && dt !== "") return <Moment format="MMM Do YYYY">{dt}</Moment>
    return "";
  }

  let candidateObj = "";
  if (item) {
    candidateObj = {
      performerid: item?.id,
    };
  }
  return (
    <>
      <div className="table-responsive border">
        <table className="table align-items-center table-flush table-hover table-striped">
          <thead className="thead-light">
            <tr>
              <th>S.No.</th>
              <th>Insurance Information</th>
              <th>Last Updated</th>
              <th>View Form</th>
              <th>COI</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {formsLib.map((item, key) => (
              <tr>
                <td>{key + 1}</td>
                <td>
                  <React.Fragment>
                    <table>
                      <tr>
                        <th>Type of Insurance</th>
                        <th>Expiration Date</th>
                      </tr>
                      <tr>
                        <td>Commercial General Liability</td>
                        <td>
                          {getModifiedFiledData("insurancedetails",
                            "Commercial_General_Liability_Expiration"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Automobile Liability</td>
                        <td>
                          {getModifiedFiledData("insurancedetails",
                            "Automobile_Liability_Expiration"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Umbrella Liability</td>
                        <td>
                          {getModifiedFiledData("insurancedetails",
                            "Umbrella_Liab_Expiration"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Workers Compensation and Employer's Liability
                        </td>
                        <td>
                          {getModifiedFiledData("insurancedetails",
                            "Workers_Compensation_Expiration"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Cyber and Privacy Security Liability</td>
                        <td>
                          {getModifiedFiledData("insurancedetails",
                            "Cyber_Privacy_Security_Liab_Expiration"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>E&O Liability</td>
                        <td>
                          {getModifiedFiledData("insurancedetails",
                            "E_and_O_Liab_Expiration"
                          )}
                        </td>
                      </tr>
                    </table>
                  </React.Fragment>
                </td>
                <td>
                  {item.lastUpdate && (
                    <>
                      <Moment format="MMM Do YYYY">
                        {item.lastUpdate}
                      </Moment>
                      <br />
                      <Moment format="h:mm a">{item.lastUpdate}</Moment>
                    </>
                  )}
                </td>
                <td>
                  <button
                    className="btn btn-link btn-sm p-0 text-underline"
                    onClick={() => handleFormShowHide(item)}
                  >
                    View
                  </button>
                  <br />
                  {item.allowEdit === 0 && (
                    <span>
                    Edit Locked
                  </span>
                  )}
                  {item.allowEdit === 2 && (
                    <>
                      <span>Edit Request Received</span>
                    <br />
                   {item.editReqDateTime &&  <Moment format="MMM Do YYYY, h:mm a">
                      {item.editReqDateTime}
                    </Moment>}

                      <br />
                      {item.editRemarks}
                    </>
                  )}
                </td>
                <td>
                  {handleCOIDoc(item)}
                </td>
                <td>
                  {item.allowEdit === 2 && (
                    <button
                      className="btn btn-link btn-sm p-0 text-underline"
                      onClick={() => handleNoteShowHide("", item)}
                    >
                      Edit Allow
                    </button>
                  )}
                  {item.allowEdit === 1 && (
                    <>
                      <span>
                      Edit Allowed
                    </span>
                      <br />
                      {item.allowEditDateTime && (
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.allowEditDateTime}
                        </Moment>
                      )}
                      <br />
                      {item.editRemarks}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ViewFormModal
        viewFormShowHide={achFormShowHide}
        handleViewForm={handleFormShowHide}
        workflowTask={workflowTask}
        selectedForm={selectedForm}
      ></ViewFormModal>

      <AddEditNotes
        noteShowHide={noteShowHide}
        handleNoteShowHide={handleNoteShowHide}
        notesType="formEditReq"
        candidateObj={candidateObj}
        selectedObj={selectedForm}
      ></AddEditNotes>
    </>
  );
}

export default InsuranseInformation;
