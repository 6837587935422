import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import http from "../../services/HttpService";
import config from "../../config.json";
import Moment from "react-moment";

class MdTrnsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      medicineTransaction: [],
    };
  }

  async componentDidMount() {
    const { data: medicineTransaction } = await http.get(
      config.GetMedicineTransactions + 69
    );
    console.log(medicineTransaction);
    this.setState({ medicineTransaction });
  }

  getMedicinList = (item) => {
    const mdList = JSON.parse(item);
    const tbl = (
      <table className="table table-bordered">
        <thead>
          <th>Medicine</th>
          <th>Dosage</th>
          <th>Notes</th>
        </thead>
        <tbody>
          {mdList.map((x) => (
            <tr>
              <td>{x.medicine}</td>
              <td>{x.dosage}</td>
              <td>{x.notes}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );

    return tbl;
  };

  render() {
    const {medicineTransaction} =this.state;
    const {
      mdTrnsShowHide,
      handleMdTrnsModalShowHide,
      selectedObj,
    } = this.props;
    return (
      <Modal show={mdTrnsShowHide} size="lg">
        <Modal.Header closeButton onClick={handleMdTrnsModalShowHide}>
          <Modal.Title>Medical Transactions Report - View</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive bg-white">
                <table className="table align-items-center table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th>
                        Updated
                        <br></br> Date and Time
                      </th>
                      <th>
                        Updated By Pharmicst<br></br>
                        ID and Location
                      </th>
                      <th>
                        Distributor/Dispencing Center
                        <br></br> Name , Email, Phone
                      </th>
                      <th>Medication Prescribed</th>
                      <th>Manufacturer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {medicineTransaction.map((x) => (
                      <tr>
                        <td>
                          <Moment format="MMM Do YYYY">
                            {x.addedDateTime}
                          </Moment>
                          <br></br>
                          <Moment format="h:mm A">{x.addedDateTime}</Moment>
                        </td>
                        <td>
                          {x.pharmacistId} &nbsp;&nbsp;
                          {x.pharmacistDetails !== null &&
                            x.pharmacistDetails.performername}
                          <br></br>
                          {x.pharmacistDetails !== null &&
                            x.pharmacistDetails.location}
                        </td>
                        <td>
                          {x.dispensingCenter}
                          <br></br>
                          {x.dispencingDetails !== null &&
                            x.dispencingDetails.email}
                          <br></br>
                          {x.dispencingDetails !== null &&
                            x.dispencingDetails.portalUrl}
                        </td>
                        <td>{this.getMedicinList(x.medicationList)}</td>
                        <td>{x.manufacturer}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="danger" onClick={handleMdTrnsModalShowHide}>
            <i className="fa fa-times" aria-hidden="true"></i> Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MdTrnsPopup;
