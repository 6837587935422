import React, { Component, useEffect, useRef, useState } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import MessagingList from "./messagingList";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import http from "../../services/HttpService";
import config from "../../config.json";
import auth from "../../services/authService";
import UploadDocument from "../../comman/UploadDocument";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getUserProfilePic, getUserProfilePicTwo, loadUserProfilePicTwo } from "../../app/store/auth";
import { addAppMessage, getAddedNewMessageStatus, getAppMsgList, getAppMsgReadStatus, loadAppMsgList, updateMessageRead, updateMsgListRead } from "../../app/store/messages";
import { getComponentUserStatus, UpdateComponentsUserStatus } from "../../app/store/tools";

const MessagingPopup = (props) => {

  const { msgShowHide, handleMsgModalShowHide, message,
    handleSendMessage, handleSupportMessage } = props;

  const toolbar = [
    [
      "undo",
      "redo",
      "font",
      "fontSize",
      "formatBlock",
      "bold",
      "underline",
      "italic",
      "fontColor",
      "hiliteColor",
      "removeFormat",
      "link",
      "image",
    ],
  ];

  const [messageList, setMessageList] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [attachmentShowHide, setAttachmentShowHide] = useState(false);
  const [content, setContent] = useState("");
  const [profilePicData, setProfilePicData] = useState(null);
  const [profilePicTwoData, setProfilePicTwoData] = useState(null);

  const appMsgListSelector = useSelector(getAppMsgList);
  const addedNewMessageStatusSelector = useSelector(getAddedNewMessageStatus);
  const componentUserStatusSelector = useSelector(getComponentUserStatus);
  const appMsgReadStatusSelector = useSelector(getAppMsgReadStatus);
  const userProfilePicSelector = useSelector(getUserProfilePic);
  const userProfilePicSelectorTwo = useSelector(getUserProfilePicTwo);

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (message?.id > 0) {
      dispatch(loadAppMsgList(message.id));
    }
    setDocuments([]);
    setContent("");
    setMessageList([]);
  }, [message]);

  useEffect(() => {
    if (appMsgListSelector.length > 0) {
      let msgList = [];
      appMsgListSelector.map((item) => {
        let author = "receive";
        let performer = item.fromName;
        if (item.fromId === parseInt(currentUser.id)) {
          author = "send";
          performer = item.fromName;
        }

        if (item.fromId !== parseInt(currentUser.id)) {
          dispatch(loadUserProfilePicTwo(item.fromId));
        }

        msgList.push({
          author: author,
          data: item.message,
          performer: performer,
          date: item.messageDate,
          documents: item.documents,
          id: item.id,
          readStatus: item.readStatus
        });
      });

      setMessageList(msgList);

      if (msgList.filter(x => x.readStatus !== true).length > 0 && message?.id)
        dispatch(updateMessageRead(message.id, currentUser.id))

    }
  }, [appMsgListSelector]);

  useEffect(() => {
    if (userProfilePicSelector) {
      let objectURL = 'data:image/jpeg;base64,' + userProfilePicSelector;
      setProfilePicData(objectURL);
    
    }

  }, [userProfilePicSelector])

  useEffect(() => {
    if (userProfilePicSelectorTwo) {
      let objectURL = 'data:image/jpeg;base64,' + userProfilePicSelectorTwo;
      setProfilePicTwoData(objectURL);
    }

  }, [userProfilePicSelectorTwo])

  useEffect(() => {
    if (appMsgReadStatusSelector) {
      let msgItems = "";
      messageList.map((x, key) => {
        if (key > 0) msgItems += ",";
        msgItems += x.id;
      })
      if (msgItems !== "") {
        handleUpdateComponent(msgItems)
        updateMsgListRead(false);
        if (handleSendMessage)
          handleSendMessage();
      }
    }
  }, [appMsgReadStatusSelector]);

  const handleUpdateComponent = (compItems) => {
    const comp = componentUserStatusSelector.filter(x => x.compId === 9);
    let updCompItems = "";
    if (comp.length > 0) {
      updCompItems = comp[0].compItems;
    }
    const compObj = {
      compName: "Messages",
      performerId: currentUser.id,
      compItems: compItems,
      ciid: message.ciid,
      wiid: message.wiid,
      iiid: message.iiid,
      compId: 9
    }
    if (compItems !== "" && compItems !== updCompItems) {
      dispatch(UpdateComponentsUserStatus(compObj));
    }
  }

  const handleChange = (newContent) => {
    setContent(newContent);
  };

  const onFileChange = async (event) => {
    const docs = [...documents];
    for (const file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        size: (file.size / 1024).toFixed(2),
        file: file,
      };
      docs.push(doc);
    }
    setDocuments(docs);
  };

  const handleClick = (event) => {
    console.log(event);
  };

  const onSubmit = () => {
    const data = new FormData();
    data.append("message", content);
    data.append("performerId", currentUser.id);
    data.append("communication", JSON.stringify(message));
    documents.map((file, key) => data.append("files[" + key + "]", file.file));
    dispatch(addAppMessage(data))
  };

  useEffect(() => {
    if (addedNewMessageStatusSelector) {
      if (message?.id > 0) {
        dispatch(loadAppMsgList(message.id));

        if (handleSupportMessage)
          handleSupportMessage(content);

        setDocuments([]);
        setContent("");
        setMessageList([]);
        setAttachmentShowHide(false);
      }
    }
  }, [addedNewMessageStatusSelector])

  const handleFileRemove = (item) => {
    const updatedDocuments = documents.filter((doc) => doc !== item);
    setDocuments(updatedDocuments);
  };

  const handleAttachmentShowHide = () => {
    setAttachmentShowHide(!attachmentShowHide)
  }

  return (
    <Modal show={msgShowHide} size="xl">
      <Modal.Header closeButton onHide={() => handleMsgModalShowHide("")}>
        <Modal.Title>Messages</Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        <div
          className="row mt--3"
          style={{
            maxHeight: 350,
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <div className="col-md-12 bg-white">
            {messageList.map((item) => (
              <MessagingList
                message={item}
                handleClick={handleClick}
                profilePicData={profilePicData}
                profilePicTwoData={profilePicTwoData}
              ></MessagingList>
            ))}
          </div>
        </div>
        <div className="row pt-3 pb-3 border-top">
          {documents.length > 0 && (
            <div className="file-preview">
              {documents.map((file, index) => (
                <div key={index} className="file-preview-item ml-2">
                  <span>{file.name}</span>
                  <span
                    className="file-size"
                    style={{
                      backgroundColor: "#f0f0f0",
                      marginLeft: "10px",
                      padding: "2px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    {file.size} KB
                  </span>
                  <button
                    className="btn btn-sm btn-link"
                    onClick={() => handleFileRemove(file)}
                  >
                    <i className="fa fa-times text-red" aria-hidden="true"></i>
                  </button>
                </div>
              ))}
            </div>
          )}
          <div className="col-md-12">
            <SunEditor
              setOptions={{
                height: 50,
                buttonList: toolbar,
              }}
              onChange={handleChange}
              setContents={content}
            />
          </div>
          <div className="col-12 text-center mt-3 d-flex justify-content-center">
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            <i className="fa fa-paperclip mr-2" aria-hidden="true"></i>
            Add
          </Button>

          <Button variant="outline-dark" size="sm" onClick={onSubmit}>
            <i className="fa fa-paper-plane-o mr-2" aria-hidden="true"></i>
            Send
          </Button>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={onFileChange}
          multiple
          style={{ position: "absolute", top: "-9999px" }}
        />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-danger" onClick={() => handleMsgModalShowHide("")}>
          <i className="fa fa-times" aria-hidden="true"></i> Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default MessagingPopup;
