import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import { getAllMonths, getAllYears } from "../../../../../services/appService";
import { getMonthlyClientCandidateInvoices, loadMonthlyClientCandidateInvoices } from "../../../../store/reports";
import AddEditYearlyRpt from "../../../../../components/AddEditYearlyRpt";
import GeneratePdf from "../../../../../components/GeneratePdf";


const AgencyCanBased = () => {

    const years = getAllYears();
    const months = getAllMonths();

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [pdfShowHide, setPdfShowHide] = useState(false);
    const [rptShowHide, setRptShowHide] = useState(false);
    const [selectedCol, setSelectedCol] = useState([]);
    const [selectedColData, setSelectedColData] = useState([]);
    const [clientRpt, setClientRpt] = useState([]);
    const [rptSelectedObj, setRptSelectedObj] = useState(null);

    const monthlyClientCandidateInvoicesSelector = useSelector(getMonthlyClientCandidateInvoices);

    const dispatch = useDispatch();

    const agencyGenerateRpt = () => {
        if (year) dispatch(loadMonthlyClientCandidateInvoices(year));
    };

    useEffect(() => {
        setClientRpt(monthlyClientCandidateInvoicesSelector)
    }, [monthlyClientCandidateInvoicesSelector])

    const handlePreviousDue = ({ currentTarget }) => {
        const newClientReport = [...clientRpt];
        const id = currentTarget.name.split("_")[1];
        const index = newClientReport.findIndex((x) => x.candidateId == id);
        const currentObj = { ...newClientReport[index] };
        currentObj.previousDue = parseFloat(currentTarget.value);
        if (currentTarget.value !== "") {
            currentObj.currentYearTotal = currentObj.yearTotal + currentObj.previousDue;
            currentObj.nextDue = currentObj.currentYearTotal - currentObj.currentPaid;
        }
        newClientReport[index] = currentObj;
        setClientRpt(newClientReport);
    };

    const handleCurrenYearPaid = ({ currentTarget }) => {
        const newClientReport = [...clientRpt];
        const id = currentTarget.name.split("_")[1];
        const index = newClientReport.findIndex((x) => x.candidateId == id);
        const currentObj = { ...newClientReport[index] };
        currentObj.currentPaid = parseFloat(currentTarget.value);
        if (currentTarget.value !== "") {
            currentObj.currentDue = currentObj.currentYearTotal - currentObj.currentPaid;
            currentObj.nextDue = currentObj.currentDue;
        }
        newClientReport[index] = currentObj;
        setClientRpt(newClientReport);
    };

    const agencyRptExportPdf = () => {
        const headers = [
            [
                "Candidate #",
                "Candidate Name",
                `${year - 1} Invoices Due in ${year}`,
                `03/31/${year} 1st Quarter $ Invoiced - Received`,
                `06/30/${year} 2nd Quarter $ Invoiced - Received`,
                `09/30/${year} 3rd Quarter $ Invoiced - Received`,
                `12/31/${year} 4th Quarter $ Invoiced - Received`,
                `${year} Year Total`,
                `${year} Invoices Paid in ${year}`,
                `${year} Invoices Paid in ${year + 1}`,
            ],
        ];

        const colData = [];
        clientRpt.map((item) => {
            const dt = [];
            dt.push(
                item.candidateId,
                item.candidate,
                item.clientName,
                item.previousDue,
                `${item.quarter1.invoiced} - ${item.quarter1.received}`,
                `${item.quarter2.invoiced} - ${item.quarter2.received}`,
                `${item.quarter3.invoiced} - ${item.quarter3.received}`,
                `${item.quarter4.invoiced} - ${item.quarter4.received}`,
                item.currentYearTotal,
                item.currentPaid,
                item.currentYearTotal - item.currentPaid
            );
            colData.push(dt);
        });
        setSelectedCol(headers);
        setSelectedColData(colData);
        setPdfShowHide(true);
    };

    const   getClientRptTotal = () => {
        let prevDueT = 0;
        let yearT = 0;
        let paidT = 0;
        let Q1T = { invT: 0, recT: 0 };
        let Q2T = { invT: 0, recT: 0 };
        let Q3T = { invT: 0, recT: 0 };
        let Q4T = { invT: 0, recT: 0 };
        clientRpt.map((item) => {
            prevDueT += item.previousDue;
            yearT += item.currentYearTotal;
            paidT += item.currentPaid;
            Q1T.invT += item.quarter1.invoiced;
            Q1T.recT += item.quarter1.received;
            Q2T.invT += item.quarter2.invoiced;
            Q2T.recT += item.quarter2.received;
            Q3T.invT += item.quarter3.invoiced;
            Q3T.recT += item.quarter3.received;
            Q4T.invT += item.quarter4.invoiced;
            Q4T.recT += item.quarter4.received;
        });
        return (
            <tr className="bg-lighter">
                <td className="font-weight-bold text-right" colSpan={3}>
                    Total
                </td>
                <td className="font-weight-bold text-right">{parseFloat(prevDueT).toFixed(2)}</td>
                <td className="font-weight-bold text-right">
                    {parseFloat(Q1T.invT).toFixed(2)} - {parseFloat(Q1T.recT).toFixed(2)}
                </td>
                <td className="font-weight-bold text-right">
                    {parseFloat(Q2T.invT).toFixed(2)} - {parseFloat(Q2T.recT).toFixed(2)}
                </td>
                <td className="font-weight-bold text-right">
                    {parseFloat(Q3T.invT).toFixed(2)} - {parseFloat(Q3T.recT).toFixed(2)}
                </td>
                <td className="font-weight-bold text-right">
                    {parseFloat(Q4T.invT).toFixed(2)} - {parseFloat(Q4T.recT).toFixed(2)}
                </td>
                <td className="font-weight-bold text-right">{parseFloat(yearT).toFixed(2)}</td>
                <td className="font-weight-bold text-right">{parseFloat(paidT).toFixed(2)}</td>
                <td className="font-weight-bold text-right">{parseFloat(yearT - paidT).toFixed(2)}</td>
                
                <td></td>
            </tr>
        );
    };


    const handleSaveCandidateRpt = async (item) => {
        setRptSelectedObj(item);
        setRptShowHide(true);
    };

    const handleRptShowHide = (type) => {
        setRptShowHide(false);
        setRptSelectedObj(null);
        if (type === "save")
            agencyGenerateRpt();
    };

    const handlepdfShowHide = () => {
        setPdfShowHide(false);
        setSelectedCol([]);
        setSelectedColData([]);
    };

    return <>
        <div className="card shadow-none border">
            <div className="card-header text-center p-3 bg-lighter">
                <h4 className="mb-0">
                    Invoices Raised to Clients - Candidate Basis
                    (Quarterly)
                </h4>
            </div>
            <div className="card-body p-3">
                <div className="row">
                    <div className="col-md-3">
                        <select
                            name="year"
                            id="year"
                            className="form-control form-control-sm"
                            onChange={({ currentTarget }) => setYear(parseInt(currentTarget.value))}
                            value={year}
                        >
                            <option value="">Year</option>
                            {years.map((y) => (
                                <option value={y.name}>
                                    {y.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button
                            className="btn btn-sm btn-outline-default"
                            disabled={!year}
                            onClick={agencyGenerateRpt}
                        >
                            Generate
                        </button>
                    </div>
                    <div className="col-md-6 text-right">
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-sm btn-outline-default"
                            table="agency_qrtly_candidate"
                            filename="Candidate_Invoice_Quartly"
                            sheet="Candidate_Invoice_Quartly"
                            buttonText="Export to Excel"
                        />
                        <button
                            type="button"
                            className="btn btn-sm btn-outline-default"
                            disabled={clientRpt.length === 0}
                            onClick={agencyRptExportPdf}
                        >
                            Generate PDF
                        </button>
                    </div>
                    <div className="col-md-12 mt-2">
                        <div className="table-responsive">
                            <table
                                className="table align-items-center table-flush table-hover table-striped mb-2 border"
                                id="agency_qrtly_candidate"
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th>Candidate #</th>
                                        <th>Candidate Name</th>
                                        <th>Client Name</th>
                                        <th>
                                            {year - 1} Invoices
                                            <br />
                                            Due in {year}
                                        </th>
                                        <th>
                                            03/31/{year} 1st Quarter $
                                            <br />
                                            Invoiced - Received
                                        </th>
                                        <th>
                                            06/30/{year} 2nd Quarter $
                                            <br />
                                            Invoiced - Received
                                        </th>
                                        <th>
                                            09/30/{year} 3rd Quarter $
                                            <br />
                                            Invoiced - Received
                                        </th>
                                        <th>
                                            12/31/{year} 4th Quarter $
                                            <br />
                                            Invoiced - Received
                                        </th>
                                        <th>
                                            {year} Year Total
                                        </th>
                                        <th>
                                            {year} Invoices
                                            <br />
                                            Paid in {year}
                                        </th>
                                        <th>
                                            {year} Invoices
                                            <br />
                                            Paid in {year + 1}
                                        </th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientRpt.map((item) => (
                                        <tr>
                                            <td>{item.candidateId}</td>
                                            <td>{item.candidate}</td>
                                            <td>{item.clientName}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm text-right"
                                                    onChange={handlePreviousDue}
                                                    id={`prev_${item.candidateId}`}
                                                    name={`prev_${item.candidateId}`}
                                                    value={parseFloat(item.previousDue).toFixed(2)}
                                                />
                                            </td>
                                            <td className="text-right">
                                                {`${parseFloat(item.quarter1.invoiced).toFixed(2)} - ${parseFloat(item.quarter1.received).toFixed(2)}`}
                                            </td>
                                            <td className="text-right">
                                                {`${parseFloat(item.quarter2.invoiced).toFixed(2)} - ${parseFloat(item.quarter2.received).toFixed(2)}`}
                                            </td>
                                            <td className="text-right">
                                                {`${parseFloat(item.quarter3.invoiced).toFixed(2)} - ${parseFloat(item.quarter3.received).toFixed(2)}`}
                                            </td>
                                            <td className="text-right">
                                                {`${parseFloat(item.quarter4.invoiced).toFixed(2)} - ${parseFloat(item.quarter4.received).toFixed(2)}`}
                                            </td>
                                            <td className="text-right"> {parseFloat(
                                                item.currentYearTotal
                                            ).toFixed(2)}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm text-right"
                                                    onChange={handleCurrenYearPaid}
                                                    id={`prev_${item.candidateId}`}
                                                    name={`prev_${item.candidateId}`}
                                                    value={parseFloat(
                                                        item.currentPaid
                                                    ).toFixed(2)}
                                                />
                                            </td>
                                            <td className="text-right">
                                                {parseFloat(
                                                    item.currentYearTotal -
                                                    item.currentPaid
                                                ).toFixed(2)}
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => handleSaveCandidateRpt(item)}
                                                >
                                                    Save
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    {getClientRptTotal()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <AddEditYearlyRpt
            rptShowHide={rptShowHide}
            handleRptShowHide={handleRptShowHide}
            selectedObj={rptSelectedObj}
            year={year}
        ></AddEditYearlyRpt>
         <GeneratePdf
            handlepdfShowHide={handlepdfShowHide}
            pdfShowHide={pdfShowHide}
            selectedCol={selectedCol}
            selectedColData={selectedColData}
        ></GeneratePdf>
    </>
}

export default AgencyCanBased;