import React, { Component } from "react";

class SSN_textbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      encryptDecrypt: true,
      encryptValue: "",
      decryptValue: "",
      valueArray: [],
      selectedTextStartIndex: 0,
      selectedTextEndIndex: 0,
      //myRef: React.createRef(),
    };
  }

  handleEncryptOnChange = (input) => {
    const { currentTarget } = input;
    const { name } = this.props;
    let filterValue = currentTarget.value; //.replace(/[^\d]/g, "");
    const {
      encryptDecrypt,
      valueArray,
      encryptValue,
      decryptValue,
      selectedTextStartIndex,
      selectedTextEndIndex,
    } = this.state;
    //console.log(filterValue);
    if (encryptDecrypt) {
      let newValue = filterValue.replace(/\-/g, "");
      let decrypt = "";
      for (let index = 0; index < newValue.length; index++) {
        decrypt += "*";
      }
      filterValue = filterValue.replace(/[^\d]/g, "");
      if (filterValue === "") {
        let calcIndex1 = input.target.selectionStart;
        if (calcIndex1 > 6) {
          calcIndex1 = calcIndex1 - 2;
        } else {
          if (calcIndex1 > 3 && calcIndex1 < 7) calcIndex1 = calcIndex1 - 1;
        }
        if (selectedTextStartIndex !== selectedTextEndIndex) {
          for (
            let index = selectedTextEndIndex;
            index > selectedTextStartIndex;
            index--
          ) {
            let nindex = index;
            if (nindex > 6) {
              nindex = nindex - 2;
            } else {
              if (nindex > 3 && nindex < 7) nindex = nindex - 1;
            }
            valueArray.splice(nindex, 1);
          }
        } else {
          valueArray.splice(calcIndex1, 1);
        }
      } else {
        let calcIndex = input.target.selectionStart;
        if (calcIndex > 4 && calcIndex < 7) {
          calcIndex = calcIndex - 1;
        } else {
          if (calcIndex > 7) calcIndex = calcIndex - 2;
        }
        for (let index = 0; index < filterValue.length; index++) {
          const element = filterValue[index];
          calcIndex += index;
          valueArray.splice(calcIndex - 1, 0, element);
        }
      }

      this.setState({ valueArray });
      let checkIsNumber = filterValue.replace(/\*/g, "");
      if (valueArray.length > 0) {
        this.setState({ decryptValue: decrypt });
      } else {
        this.setState({ decryptValue: "" });
      }
      this.props.handleOnChange(name, valueArray);
    } else {
      filterValue = filterValue.replace(/[^\d]/g, "");
      let newArray = [];
      for (let index = 0; index < filterValue.length; index++) {
        newArray.push(filterValue[index]);
      }
      this.setState({ valueArray: newArray });
      this.setState({ decryptValue: filterValue });
      this.props.handleOnChange(name, newArray);
    }
  };

  handleDecryptOnChange = (input) => {
    const { encryptValue } = this.state;
    const { currentTarget } = input;
    let decrypt = "";
    for (let index = 0; index < currentTarget.value.length; index++) {
      decrypt += "*";
    }
    this.setState({ decryptValue: decrypt });
    input.currentTarget.value = currentTarget.value.replace(/[^\d]/g, "");
    this.handleEncryptOnChange(input);
  };

  handleEncryptValue = () => {
    const { valueArray } = this.state;
    let decValue = "";
    for (let index = 0; index < valueArray.length; index++) {
      decValue += "*";
    }
    this.setState({ decryptValue: decValue, encryptDecrypt: true });
  };

  handleDcryptValue = () => {
    const { valueArray } = this.state;
    let decValue = "";
    for (let index = 0; index < valueArray.length; index++) {
      const element = valueArray[index];
      decValue += element;
    }
    this.setState({ decryptValue: decValue, encryptDecrypt: false });
  };

  formatSSNNumber(value) {
    //console.log(value);
    if (!value) return value;
    let phoneNumber = value;
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      5
    )}-${phoneNumber.slice(5)}`;
  }

  handleMouseUp = ({ currentTarget }) => {
    //console.log(currentTarget.selectionStart);
    //console.log(currentTarget.selectionEnd);
    this.setState({
      selectedTextStartIndex: currentTarget.selectionStart,
      selectedTextEndIndex: currentTarget.selectionEnd,
    });
  };

  componentDidMount() {
    const { value } = this.props;
    if (value !== "" && value !== undefined) {
      const { encryptDecrypt } = this.state;
      let valueArray = [];
      let decValue = "";
      for (let index = 0; index < value.length; index++) {
        const element = value[index];
        valueArray.push(element);
        decValue += "*";
      }

      if (encryptDecrypt) this.setState({ decryptValue: decValue, valueArray });
      else this.setState({ decryptValue: value, valueArray });
    } else {
      this.setState({ valueArray: [], decryptValue: "" });
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const { value } = nextProps;
    if (value !== "" && value !== undefined) {
      const { encryptDecrypt } = this.state;
      let valueArray = [];
      let decValue = "";
      for (let index = 0; index < value.length; index++) {
        const element = value[index];
        valueArray.push(element);
        decValue += "*";
      }

      if (encryptDecrypt) this.setState({ decryptValue: decValue, valueArray });
      else this.setState({ decryptValue: value, valueArray });
    } else {
      this.setState({ valueArray: [], decryptValue: "" });
    }
  };

  render() {
    const { encryptDecrypt, decryptValue } = this.state;
    const { label, name, isDisabled, codeIsVerified } = this.props;
    return (
      <>
        <div className="d-flex">
          <input
            type="text"
            className="form-control"
            name={name}
            value={this.formatSSNNumber(decryptValue)}
            onChange={this.handleEncryptOnChange}
            onMouseUp={this.handleMouseUp}
            title="SSN 9 Digit"
            placeholder={label}
            disabled={isDisabled}
          ></input>
          {!encryptDecrypt && (
            <button
              className="btn btn-danger btn-sm ml-2"
              onClick={this.handleEncryptValue}
            >
              <i className="fa fa-eye-slash" aria-hidden="true"></i>
            </button>
          )}
          {encryptDecrypt && (
            <button
              className="btn btn-success btn-sm ml-2"
              disabled={!codeIsVerified}
              onClick={this.handleDcryptValue}
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </button>
          )}
        </div>
      </>
    );
  }
}

export default SSN_textbox;
