import React, { useEffect } from "react";
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getAllMonths, getAllYears } from "../../../../services/appService";
import { getAgenciesPartners, loadAgencies } from "../../../store/partner";
import { getQuartlyBurnHourCandidates, loadQuartlyBurnHourCandidates } from "../../../store/reports";
import GeneratePdf from "../../../../components/GeneratePdf";

const BurnHrCandidate = () => {

    const years = getAllYears();
    const months = getAllMonths();

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");

    const [pdfShowHide, setPdfShowHide] = useState(false);
    const [rptShowHide, setRptShowHide] = useState(false);
    const [selectedCol, setSelectedCol] = useState([]);
    const [selectedColData, setSelectedColData] = useState([]);

    const [selectedAgency, setSelectedAgency] = useState("");
    const [selectedCandidate, setSelectedCandidate] = useState("");
    const [agencies, setAgencies] = useState([]);
    const [candidates, setCandidates] = useState([]);
    const [allAgenciesCandidates, setAllAgenciesCandidates] = useState([]);
    const [allCandidateBurnRpt, setAllCandidateBurnRpt] = useState([]);
    const [candidatesBurnRpt, setCandidatesBurnRpt] = useState([]);

    const agenciesPartnersSelector = useSelector(getAgenciesPartners);
    const quartlyBurnHourCandidatesSelector = useSelector(getQuartlyBurnHourCandidates)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAgencies())
        dispatch(loadQuartlyBurnHourCandidates())
    }, []);

    useEffect(() => {
        if (agenciesPartnersSelector.length > 0) {
            setAllAgenciesCandidates(agenciesPartnersSelector);
            const list = _.unionBy(agenciesPartnersSelector, "title");
            setAgencies(list);
        }
    }, [agenciesPartnersSelector]);


    useEffect(() => {
        if (quartlyBurnHourCandidatesSelector.length > 0) {
            setAllCandidateBurnRpt(quartlyBurnHourCandidatesSelector);
        }
    }, [quartlyBurnHourCandidatesSelector])

    const handleAgencyChange = ({ currentTarget }) => {
        const agencyCandidates = allAgenciesCandidates.filter(
            (x) => x.title === currentTarget.value
        );
        setCandidates(agencyCandidates);
        setSelectedAgency(currentTarget.value);
    };

    const handleMonthlyBurnRpt = () => {
        let filterData = allCandidateBurnRpt;
        if (selectedAgency !== "")
            filterData = filterData.filter((x) => x.agencyName === selectedAgency);
        if (selectedCandidate !== "")
            filterData = filterData.filter(
                (x) => x.id === parseInt(selectedCandidate)
            );
        if (year !== "")
            filterData = filterData.filter((x) => x.year === parseInt(year));
        if (month !== "")
            filterData = filterData.filter((x) => x.month === parseInt(month));

        setCandidatesBurnRpt(filterData);
    };

    const handlepdfShowHide = () => {
        setPdfShowHide(false);
        setSelectedCol([]);
        setSelectedColData([]);
    };

    const generateBurnHrCandidatePdf=()=>{
        setSelectedCol([]);
        setSelectedColData([]);
        setPdfShowHide(true);
    }


    return <>
        <div className="card shadow-none border">
            <div className="card-header text-center p-3 bg-lighter">
                <h4 className="mb-0">
                    Monthly Burn Reports - Per Candidate Basis
                </h4>
            </div>
            <div className="card-body p-3">
                <div className="row">
                    <div className="col-md-3">
                        <select
                            name="agencyName"
                            id="agencyName"
                            className="form-control form-control-sm"
                            onChange={handleAgencyChange}
                            value={selectedAgency}
                        >
                            <option value="">Select Agency</option>
                            {agencies.map((item) => (
                                <option value={item.title}>
                                    {item.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <select
                            name="candidateId"
                            id="candidateId"
                            className="form-control form-control-sm"
                            onChange={({ currentTarget }) => setSelectedCandidate(currentTarget.value)}
                            value={selectedCandidate}
                        >
                            <option value="">Select</option>
                            {candidates.map((item) => (
                                <option value={item.performerid}>
                                    {item.performername}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-1">
                        <select
                            name="year"
                            id="year"
                            className="form-control form-control-sm"
                            onChange={({ currentTarget }) => setYear(currentTarget.value)}
                            value={year}
                        >
                            <option value="">Year</option>
                            {years.map((y) => (
                                <option value={y.name}>
                                    {y.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* <div className="col-md-2">
                        <select
                            name="month"
                            id="month"
                            className="form-control form-control-sm"
                            onChange={({ currentTarget }) => setMonth(currentTarget.value)}
                            value={month}
                        >
                            <option value="">Month</option>
                            {months.map((m) => (
                                <option value={m.id}>{m.name}</option>
                            ))}
                        </select>
                    </div> */}
                    <div className="col-md-1">
                        <button
                            className="btn btn-sm btn-outline-default"
                            onClick={handleMonthlyBurnRpt}
                        >
                            Generate
                        </button>
                    </div>
                    <div className="col-md-4 text-right">
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-sm btn-outline-default"
                            table="table-to-xls-6"
                            filename="Client_Monthly_Pay"
                            sheet="Client_Monthly_Pay"
                            buttonText="Export to Excel"
                        />
                        <button
                            type="button"
                            className="btn btn-sm btn-outline-default"
                            onClick={generateBurnHrCandidatePdf}
                        >
                            Generate PDF
                        </button>
                    </div>
                    
                    <div className="col-md-12 mt-2">
                        <div className="table-responsive">
                            <table
                                className="table align-items-center table-flush table-hover table-striped mb-2 border"
                                id="table-to-xls-7"
                            >
                                <thead className="thead-light">
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Agency/Client</th>
                                    <th>Fixed Hrs</th>
                                    <th>Year</th>
                                    <th>Quarter</th>
                                    <th>Hrs Worked</th>
                                    <th>Balance Hrs</th>
                                </thead>
                                <tbody>
                                    {candidatesBurnRpt.map(
                                        (item, key) => (
                                            <tr>
                                                <td>
                                                    {key > 0 &&
                                                        item.id ===
                                                        candidatesBurnRpt[key - 1]
                                                            .id
                                                        ? ""
                                                        : item.id}
                                                </td>
                                                <td>
                                                    {key > 0 &&
                                                        item.name ===
                                                        candidatesBurnRpt[key - 1]
                                                            .name
                                                        ? ""
                                                        : item.name}
                                                </td>
                                                <td>
                                                    {key > 0 &&
                                                        item.agencyName ===
                                                        candidatesBurnRpt[key - 1]
                                                            .agencyName
                                                        ? ""
                                                        : item.agencyName}
                                                </td>
                                                <td>{item.fixedHrs}</td>
                                                <td>{item.year}</td>
                                                <td>
                                                Quarter {item.quarterNumber}
                                                </td>

                                                <td>
                                                    {item.workedHrs}
                                                
                                                </td>
                                                <td>
                                                   {item.balanceHrs}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <GeneratePdf
            handlepdfShowHide={handlepdfShowHide}
            pdfShowHide={pdfShowHide}
            selectedCol={selectedCol}
            selectedColData={selectedColData}
        ></GeneratePdf>
    </>
}

export default BurnHrCandidate;