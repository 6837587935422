import React, { Component } from 'react';
import Table from "../comman/table";

class LogsTable extends Component {
  columns = [
    { path: "id", label: "ID" },
    { path: "userid", label: "User ID" },
    { path: "userName", label: "User Name" },
    { path: "loginDateTime", label: "Login Date & Time" },
    { path: "ipAddress", label: "IP Address" },
    { path: "country", label: "Country" },
    { path: "logoutDateTime", label: "Logout DateTime" },
    {
      path: "",
      label: "Location Details",
      content: (obj) => (
        <button
          type="button"
          class="btn btn-link btn-sm"
          data-toggle="modal"
          data-target="#modal-location-details"
          data-original-title="View location"
        >
          <i class="fa fa-eye" aria-hidden="true"></i>
        </button>
      ),
    },
    {
      path: "",
      label: "Browser Details",
      content: (obj) => (
        <button
          type="button"
          class="btn btn-link btn-sm"
          data-toggle="modal"
          data-target="#modal-browser-details"
          data-original-title="View Browser"
        >
          <i class="fa fa-eye" aria-hidden="true"></i>
        </button>
      ),
    },
  ];
  render() {
    const { data } = this.props;
    return <Table columns={this.columns} data={data}></Table>;
  }
}

export default LogsTable;