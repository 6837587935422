import Moment from "react-moment";
import React, { useEffect, useState } from "react";
import { getFormatedNumber } from "../../services/appService";
import ActiveDeactiveHistory from "../../components/SystemAdministration/ManagePartner/ActiveDeactiveHistory";
import { SendReminder, getReminderStatus } from "../store/candidate";
import { getAlert } from "../../services/appAlerts";
import { useDispatch, useSelector } from "react-redux";

export default function PartnerInfo(props) {

    const { module, partner: item, type,appType } = props;
    const [logShowHide, setLogShowHide] = useState(false);
    const [actionType, setActionType] = useState("");

    const [remainderMsgShowHide, setRemainderMsgShowHide] = useState(false);
    
    const remiderStatusSelector = useSelector(getReminderStatus);

    const dispatch = useDispatch();

    useEffect(() => {
        if (remiderStatusSelector === "success") {
            getAlert("", "Reminder Sent Successfully", "success");
        }
    }, [remiderStatusSelector]);

    const handleRemainder = async (id) => {
        dispatch(SendReminder(id));
    };

    const handleRemainderMessage = () => {
        setRemainderMsgShowHide(!remainderMsgShowHide);
    };

    const handleLogShowHide = (item, actionType) => {
        if (item === "save") {
            setLogShowHide(false);
            setActionType(actionType);
        } else {
            setLogShowHide(!logShowHide);
            setActionType(actionType);
        }
    };

    return (
        <React.Fragment>
            <div className="table-responsive border">
                <table className="table align-items-center table-flush table-hover table-striped mb-2">
                    <thead className="thead-light">
                        <tr>
                            <th>ID #</th>
                            <th>{module} Details</th>
                            <th>Key Contact</th>
                            {module !== "Recruiter" && <th>Entity Type</th>}
                            <th>Enrollment Status</th>
                            {type === "partner" && <th>Active Users</th>}
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{item.fuid}</td>
                            <td>
                                <span className="text-uppercase">
                                    {item.label}
                                </span>
                                <br />
                               {item.email}
                                <br />
                                <Moment format="MMM Do YYYY, h:mm a">
                                    {item.createdDate}
                                </Moment>
                            </td>
                            <td>
                                {item?.contact1 && (
                                    <React.Fragment>
                                        <span className="text-uppercase">
                                            {item.contact1.performername}
                                        </span>
                                        <br />
                                        {item.contact1.performeremail}
                                        <br />
                                        {getFormatedNumber(item.contact1.contactNo)}
                                    </React.Fragment>
                                )}
                            </td>
                            {module !== "Recruiter" && <td>C2C</td>}
                            <td>
                            {item.enrollmentStatus &&  "Complete"}
                            {!item.enrollmentStatus && "Pending"}
                                <br />
                                {item.contact1 !== null && type === "partner" && (
                                    <React.Fragment>
                                        <button
                                            className="btn btn-link btn-sm p-0 m-0 text-underline"
                                            onClick={() =>
                                                handleRemainder(
                                                    item.contact1.performerid
                                                )
                                            }
                                        >
                                            Send Reminder
                                        </button>
                                        <br />
                                        <button
                                            className="btn btn-link btn-sm p-0"
                                            title="View message"
                                            onClick={handleRemainderMessage}
                                        >
                                            <span className="badge badge-warning rounded-0 text-red">
                                                <i
                                                    class="fa fa-envelope mr-1"
                                                    aria-hidden="true"
                                                ></i>
                                                {!item.contact1?.msgAlertCount
                                                    ? 0
                                                    : item.contact1.msgAlertCount}
                                            </span>
                                        </button>
                                    </React.Fragment>
                                )}
                            </td>
                            {type === "partner" && (
                                <td>
                                    <span
                                        className="badge badge-success w-50 rounded-0"
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {item.activeUsers}
                                    </span>
                                </td>
                            )}
                            <td>
                                {type === "partner"
                                    ? item.contact1 !== null && (
                                        <React.Fragment>
                                            {item.contact1.isActive === true && (
                                                <button
                                                    className="btn btn-sm btn-link p-0 text-underline"
                                                    onClick={() =>
                                                        handleLogShowHide(item)
                                                    }
                                                >
                                                    <i
                                                        className="fa fa-check-square"
                                                        aria-hidden="true"
                                                    ></i>{" "}
                                                    Activated
                                                </button>
                                            )}
                                            {item.contact1.isActive === false && (
                                                <button
                                                    className="btn btn-sm btn-link p-0 text-underline"
                                                    onClick={() =>
                                                        handleLogShowHide(item)
                                                    }
                                                >
                                                    Deactivated
                                                </button>
                                            )}
                                            {item.contact1.isActive === null && (
                                                <button
                                                    className="btn btn-sm btn-link p-0 text-underline"
                                                    onClick={() =>
                                                        handleLogShowHide(item)
                                                    }
                                                >
                                                    To Activate
                                                </button>
                                            )}
                                            <br />
                                            {item.contact1.isActive !== null && (
                                                <button
                                                    className="btn btn-sm btn-link p-0 text-underline mt-2"
                                                    onClick={() =>
                                                        handleLogShowHide(item)
                                                    }
                                                >
                                                    Log History
                                                </button>
                                            )}
                                        </React.Fragment>
                                    )
                                    : item?.contact1 && (
                                        <React.Fragment>
                                            {item.contact1.isActive === true && (
                                                <>
                                                    <i
                                                        className="fa fa-check text-success"
                                                        aria-hidden="true"
                                                    ></i>{" "}
                                                    Activated
                                                </>
                                            )}
                                            {item.contact1.isActive === false && (
                                                <>
                                                   <i
                                                        className="fa fa-times text-danger"
                                                        aria-hidden="true"
                                                    ></i>{" "} Deactivated
                                                </>
                                            )}
                                            {item.contact1.isActive === null && (
                                                <>
                                                    To Activate
                                                </>
                                            )}
                                        </React.Fragment>
                                    )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {/* <ActiveDeactiveHistory
                logShowHide={logShowHide}
                handleLogShowHide={handleLogShowHide}
                selectedObj={item}
                type={appType}
                pageType={module}
                actionType={actionType}
            ></ActiveDeactiveHistory> */}
        </React.Fragment>
    )
}