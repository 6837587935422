import React from "react";
import http from "../services/HttpService";
import config from "../config.json";
import Joi from "joi-browser";
import Form from "../comman/form";
import geo from "../services/geoService";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import ToastTemplate from "../comman/toastTemplate";
import { getAlert } from "../services/appAlerts";

class AddPartnerPerformer extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      stateList: [],
      countyList: [],
      selectedStateId: "",
      selectedState: "",
      selectedCountyId: "",
      selectedCounty: "",
      roles: [
        { id: "1", name: "Partner Admin" },
        { id: "2", name: "Partner Performer" },
      ],
      modalShow: "",
      partnerUserTypes: [],
    };
  }

  async componentDidMount() {
    const stateList = await geo.getStateList();
    this.setState({ stateList });
    let cmName = "Partner User Role";
    const { data: partnerUserTypes } = await http.get(
      config.GetCodemanager + cmName
    );
    this.setState({ partnerUserTypes });
  }

  schema = {
    name: Joi.string().required().label("Name"),
    email: Joi.string().email().required().label("Email"),
    contact: Joi.string()
      .length(10)
      .options({
        language: {
          string: {
            length: "Mobile No. must be 10 digits.",
          },
        },
      })
      .options({})
      .regex(/^[0-9]+$/)
      .options({
        language: {
          string: {
            regex: {
              base: "This entry can only contain numbers",
            },
          },
        },
      })
      .label("Mobile No."),
  };

  doSubmit = async () => {
    const { data } = this.state;
    this.props.handleFormSubmit(data);
    if (data["id"] > 0) {
      getAlert("", "Updated Successfully", "success");
    } else {
      getAlert("", "Added Successfully", "success");
    }
    this.props.handlePermissionShowHide();
  };

  handleStateChanged = async ({ target: input }) => {
    const selectedOption = input.selectedOptions[0];
    this.setState({
      selectedState: selectedOption.text,
      selectedStateId: selectedOption.value,
    });

    this.updateState(input.name, selectedOption.text);

    await this.bindCountyDropdown(selectedOption.value);
  };

  handleCountyChanged = ({ target: input }) => {
    const selectedOption = input.selectedOptions[0];
    this.setState({
      selectedCounty: selectedOption.text,
      selectedCountyId: selectedOption.value,
    });

    this.updateState(input.name, selectedOption.text);
  };

  updateState(name, value) {
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  }

  async bindCountyDropdown(geonameId) {
    const countyList = geonameId > 0 ? await geo.getGeoData(geonameId) : [];
    this.setState({ countyList });
    return countyList;
  }

  componentWillReceiveProps = async (nextProps) => {
    const { selectedObj } = nextProps;
    if (selectedObj && selectedObj !== "") {
      const { stateList } = this.state;
      //console.log(selectedObj);
      const selectedState = stateList.filter(
        (x) => x.name === selectedObj.state
      );
      this.setState({ data: selectedObj });
      if (selectedState.length > 0) {
        this.setState({ selectedStateId: selectedState[0].geonameId });
        const countyList = await this.bindCountyDropdown(
          selectedState[0].geonameId
        );
        if (selectedObj.county !== null) {
          const selectedCounty = countyList.filter(
            (x) => x.name === selectedObj.county
          );
          if (selectedCounty.length > 0) {
            this.setState({ selectedCountyId: selectedCounty[0].geonameId });
          }
        }
      }
    } else {
      this.setState({ data: {}, selectedCountyId: "", selectedStateId: "" });
    }
  };

  render() {
    const {
      stateList,
      countyList,
      roles,
      selectedCountyId,
      selectedStateId,
      partnerUserTypes,
    } = this.state;

    const { showHide, handleModalShowHide } = this.props;

    return (
      <div className="">

        <div className="row">
          <div className="col-md-12">
          {this.renderSelect(
            "title",
            "Select User Type",
            partnerUserTypes,
            "name",
            "name"
          )}
        </div>
          <div className="col-lg-6">{this.renderInput("name", "Name")}</div>
          <div className="col-lg-6">{this.renderInput("email", "Email")}</div>
          <div className="col-lg-6">
            {this.renderInput("contact", "Mobile No.", "phone")}
          </div>
          <div className="col-lg-6">
            <label className="form-control-label" htmlFor="stateList">
              State
            </label>
            <select
              className="form-control"
              id="stateList"
              name="state"
              value={selectedStateId}
              onChange={this.handleStateChanged}
            >
              <option value="0">All</option>
              {stateList.map((s) => (
                <option key={s.geonameId} value={s.geonameId}>
                  {s.name}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="col-lg-6">
            <label className="form-control-label" htmlFor="countyList">
              County
            </label>
            <select
              className="form-control"
              id="countyList"
              name="county"
              value={selectedCountyId}
              onChange={this.handleCountyChanged}
            >
              <option value="0">All</option>
              {countyList.map((s) => (
                <option key={s.geonameId} value={s.geonameId}>
                  {s.name}
                </option>
              ))}
            </select>
          </div> */}
          <div className="col-lg-6">{this.renderInput("county", "City")}</div>
         
          <div className="col-md-12 mt-3 text-center">
            <button
              className="btn btn-sm btn-outline-default"
              disabled={this.validate()}
              onClick={this.handleSubmit}
            >
              <i class="fa fa-check" aria-hidden="true"></i> Save
            </button>

          </div></div>
      </div>
    );
  }
}

export default AddPartnerPerformer;
