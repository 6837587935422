import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";
import http from "../../services/HttpService";
import geoService from "../../services/geoService";

const slice = createSlice({
    name: "bprmForm",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        updateStatus:"",
        updateHistoryStatus:false,
        allStatesObj:[],
        allStateCountyCity:[],
        formSaveStatus:false
    },
    reducers: {
        bprmFormReceived: (bprmForm, action) => {
            bprmForm.list = action.payload;
        },
        formReceiving: (bprmForm, action) => {
            bprmForm.list = [];
        },
        formReceived: (bprmForm, action) => {
            bprmForm.list.push(action.payload);
            bprmForm.updateHistoryStatus=false;
        },
        formSaving: (bprmForm, action) => {
            bprmForm.loading = true;
            bprmForm.updateStatus="";
        },
        formSaved: (bprmForm, action) => {
            bprmForm.loading = false;
            bprmForm.updateHistoryStatus=false;
            bprmForm.formSaveStatus=true;
        },
        formUpdated: (bprmForm, action) => {
            bprmForm.updateStatus = action.payload;
        },
        formUpdateHistoryAdded: (bprmForm, action) => {
            bprmForm.updateHistoryStatus = true;
        },
        allStatesObjReceived: (bprmForm, action) => {
            bprmForm.allStatesObj = action.payload;
        },
        allStateCountyCityReceived: (bprmForm, action) => {
            bprmForm.allStateCountyCity = action.payload;
        },
        taskFormSaved:(bprmForm,action)=>{
            bprmForm.formSaveStatus=action.payload;
        }
    }
});

export const { formReceiving, formReceived, formSaved, 
    formSaving,formUpdated,formUpdateHistoryAdded,
    allStatesObjReceived,allStateCountyCityReceived,taskFormSaved } = slice.actions;
export default slice.reducer;

export const loadBprmForm = (id) => apiCallBegan({
    url: config.GetForm + id,
    onStart: formReceiving.type,
    onSuccess: formReceived.type
});

export const saveBprmForm = (data) => apiCallBegan({
    url: config.SaveFormData,
    method: "post",
    data: data,
    onStart: formSaving.type,
    onSuccess: formSaved.type
});

export const addUpdateBprmFormHistory = (data) => apiCallBegan({
    url: config.UpdateFormLibraryHistory,
    method: "post",
    data: data,
    onSuccess: formUpdateHistoryAdded.type
});

export const updateBprmForm = (data) => apiCallBegan({
    url: config.UpdateApplicationData,
    method: "post",
    data: data,
    onStart: formSaving.type,
    onSuccess: formUpdated.type
});

export const getBprmForm = createSelector(
    state => state.entities.bprmForm,
    bprmForm => bprmForm.list
);

export const getUpdateStatus = createSelector(
    state => state.entities.bprmForm,
    bprmForm => bprmForm.updateStatus
);

export const getFormUpdateHistoryStatus = createSelector(
    state => state.entities.bprmForm,
    bprmForm => bprmForm.updateHistoryStatus
);
export const getTaskFormSavedStatus = createSelector(
    state => state.entities.bprmForm,
    bprmForm => bprmForm.formSaveStatus
);

export const getFormAllCountryStates = createSelector(
    state => state.entities.bprmForm,
    bprmForm => bprmForm.allStatesObj
);

export const getFormAllStateCountyCity = createSelector(
    state => state.entities.bprmForm,
    bprmForm => bprmForm.allStateCountyCity
);

export const getStateCountyCity = async (wfForm, data) => {
    let stateCountyCity = [];
    let allStateList=[];
    let allStateObjects=[];
    let allCountyObjects=[];
    let allCityObjects=[];
    const allCountyList=[];
    const allCityList=[];
    if(!wfForm.elementJson) return stateCountyCity;
    const countryList = await geoService.getAllCountries();
    const elements = JSON.parse(wfForm.elementJson);
    const newElements=[];
    const allSelectComp=elements.map(col=> {
        if(col.components.filter(x => x.addressObjList === "country" 
            || x.addressObjList === "state" || x.addressObjList === "county").length > 0)
            return newElements.push(col);
    })
    const result = await Promise.all(newElements.map(async (col) => {
        col.components.map(async (comp) => {
                const controleId = comp.attributeType.toLowerCase() + "." + comp.attributeObject;
                const ddName = controleId.split(".")[0];

            let childCompId = "";
            if (comp.childAttributeType && comp.childAttributeObject)
                childCompId = comp.childAttributeType?.toLowerCase() + "." + comp.childAttributeObject;
            const dd = stateCountyCity[ddName];
                if (comp.addressObjList === "country") {
                    const obj = {
                        ddType: ddName,
                        countryList: countryList,
                        stateList: [],
                        stateObjects: [],
                        countyList: [],
                        countyObjects: [],
                        cityList: [],
                        cityObjects: [],
                    };
                    if (!dd)
                    stateCountyCity[ddName] = obj
                }

                
                if (data[controleId] !== "" && data[controleId]) {
                    if (comp.addressObjList === "country") {
                        let dd = stateCountyCity[ddName];
                        if (dd) {
                            const sCode = countryList.filter((x) => x.countryName === data[controleId]);
                            if (sCode.length > 0) {
                                const stObj = {
                                    name: sCode[0].countryName,
                                    list: [],
                                    id: sCode[0].geonameId,
                                    compId:controleId,
                                    childCompId:childCompId
                                }
                                allStateObjects.push(stObj);
                                dd.stateList = []
                                dd.stateObjects=allStateObjects;
                            }
                            
                            dd.countyList = [];
                            dd.cityList = [];
                            stateCountyCity[ddName] = dd;
                        }
                    }
                    if (comp.addressObjList === "state") {
                        const dd = stateCountyCity[ddName];
                        if (dd) {
                            const ctObj = {
                                name: data[controleId],
                                list: [],
                                id: 0,
                                compId:controleId,
                                childCompId:childCompId
                            }
                            allCountyObjects.push(ctObj);
                            dd.countyList = []
                            dd.countyObjects = allCountyObjects;

                            dd.cityList = [];
                            stateCountyCity[ddName] = dd;
                        }
                    }
                    if (comp.addressObjList === "county") {
                        const dd = stateCountyCity[ddName];
                        if (dd) {
                            //const ctgid = dd?.countyObjects?.filter(x => x.childCompId === controleId);
                            //const ctList = await geoService.getGeoData(ctgid[0].id);
                            //const ct = await dd.countyList;
                            // const gid = ctList.filter((x) => x.name === data[controleId]);
                            // if (gid.length > 0) {
                            //     const ctObj = {
                            //         name: data[controleId],
                            //         list: [],
                            //         id: gid[0].geonameId,
                            //         compId:controleId,
                            //         childCompId:childCompId
                            //     }
                            //     allCityObjects.push(ctObj);
                            //     dd.cityList = []
                            //     dd.cityObjects = allCityObjects;
                            // }
                            const ctObj = {
                                name: data[controleId],
                                list: [],
                                id: 0,
                                compId:controleId,
                                childCompId:childCompId
                            }
                            allCityObjects.push(ctObj);
                            dd.cityList = []
                            dd.cityObjects = allCityObjects;
                            stateCountyCity[ddName] = dd;
                        }
                    }
                } 
                else {
                    const obj = {
                        ddType: ddName,
                        countryList: countryList,
                        stateList: [],
                        stateObjects: [],
                        countyList: [],
                        countyObjects: [],
                        cityList: [],
                        cityObjects: [],
                    };
                    if (!dd)
                    stateCountyCity[ddName] = obj;
                }
            });
            return stateCountyCity;
    }))

     return stateCountyCity;
}

export const getDataTypeCodemanager = async (wfForm) => {
    let ddTypeCodMngr = [];
    let subformDataType = [];
    if(!wfForm.elementJson) return { ddTypeCodMngr, subformDataType }
    const elements = JSON.parse(wfForm.elementJson);
    const result = await Promise.all(elements.map(async (col) => {
        col.components
            .filter(x => x.dataType === "dropDown" || x.dataType === "list")
            .map(async (comp) => {
                let controleId = "";
                if (comp.attributeType)
                    controleId = comp.attributeType?.toLowerCase();
                if (comp.attributeObject)
                    controleId += "." + comp.attributeObject;

                if (comp.dataType === "dropDown") {
                    const opData = comp.dropdownvalue.filter((x) => x.name !== "");
                    if (opData.length === 0 && !comp.isChildElement && controleId !== "") {
                        const { data: items } = await http.get(
                            config.GetDataTypeCodemanager +
                            comp.attributeType.toLowerCase() +
                            "&att=" +
                            comp.attributeObject
                        );

                        const ddTypeObj = {
                            controlId: controleId,
                            optionData: items,
                        };
                        ddTypeCodMngr.push(ddTypeObj);
                    }
                }

                if (comp.dataType === "list" && controleId !== "") {
                    const { data } = await http.get(
                        config.GetDataType +
                        comp.attributeType +
                        "&attName=" +
                        comp.attributeObject
                    );
                    subformDataType.push(data);
                }
            });
    }));

    const obj = { ddTypeCodMngr, subformDataType }
    return obj;
}

export const getDataTypeDropdwonItems = async (nextType) => {
    const { data: items } = await http.get(
        config.GetDataTypeCodemanager + nextType[0] + "&att=" + nextType[1]
    );
    return items;
}