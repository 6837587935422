import React, { useEffect } from "react";
import { useState } from "react";
import useForm from "../../app/hooks/useForm";
import useControls from "../../app/hooks/useControls";
import Moment from "react-moment";
import { getFieldData } from "../../services/appService";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addNewRates, getAllRates, getRateAddedStatus, loadAllRates } from "../../app/store/rates";
import { getCurrentUser } from "../../services/authService";
import { getAlert } from "../../services/appAlerts";

const AddEditRates = (props) => {

    const { selectedObj, editType, handleDurationShowHide } = props;

    const [rateHistory, setRateHistory] = useState([]);
    const [entityType, setEntityType] = useState([]);

    const allRatesSelector = useSelector(getAllRates);
    const rateAddedStatusSelector = useSelector(getRateAddedStatus)

    const dispatch = useDispatch();
    const currentUser = getCurrentUser();

    useEffect(() => {
       if(editType !== ""){
        if (selectedObj?.rates) {
            const data = { ...stateData };
            data["existingSubcontractorRate"] = selectedObj.rates.subcontractorRate;
            data["existingRecruiterRate"] = selectedObj.rates.recruiterRate;
            data["existingAgencyRate"] = selectedObj.rates.agencyRate;
            data["existingCandidateRate"] = selectedObj.rates.candidateRate;

            data["dateCandidateRate"] = getDateFormat(selectedObj.rates.candidateEffectiveDate);
            data["dateAgencyRate"] = getDateFormat(selectedObj.rates.agencyEffectiveDate);
            data["dateSubcontractorRate"] = getDateFormat(selectedObj.rates.subcontractorEffectiveDate);
            data["dateRecruiterRate"] = getDateFormat(selectedObj.rates.recruiterEffectiveDate);

            data["notes"] = selectedObj.rates.notes;

            setStateData(data);
        }
        const entityType = getFieldData(
            selectedObj.performerData,
            "candidateonboardingform",
            "CandidateEntity"
        );
        setEntityType(entityType);
        dispatch(loadAllRates(selectedObj.performerid));
       }
    }, [editType]);

    const getDateFormat = (dtObj) => {
        const dt = new Date(dtObj);
        let divider = dt.getMonth() < 9 ? "-0" : "-";
        let divider1 = dt.getDate() < 9 ? "-0" : "-";
        const newDt =
            dt.getFullYear() +
            divider +
            (dt.getMonth() + 1) +
            divider1 +
            dt.getDate();
        return newDt;
    };

    useEffect(() => {
        if (allRatesSelector) setRateHistory(allRatesSelector);
    }, [allRatesSelector]);

    const schema = {};

    const doSubmit = () => {
        const data = { ...stateData };
        const rateObj = {
            performerId: selectedObj.performerid,
            addedBy: currentUser.id,
            notes: data["notes"],
            subcontractorRate:
                !data["newSubcontractorRate"]
                    ? data["existingSubcontractorRate"]
                    : data["newSubcontractorRate"],
            recruiterRate:
                !data["newRecruiterRate"]
                    ? data["existingRecruiterRate"]
                    : data["newRecruiterRate"],
            candidateRate:
                !data["newCandidateRate"]
                    ? data["existingCandidateRate"]
                    : data["newCandidateRate"],
            agencyRate:
                !data["newAgencyRate"]
                    ? data["existingAgencyRate"]
                    : data["newAgencyRate"],
            candidateEffectiveDate: data["dateCandidateRate"],
            agencyEffectiveDate: data["dateAgencyRate"],
            subcontractorEffectiveDate: data["dateSubcontractorRate"],
            recruiterEffectiveDate: data["dateRecruiterRate"],
        };
        dispatch(addNewRates(rateObj))
    }

    useEffect(() => {
        if (rateAddedStatusSelector) {
            getAlert("", "Saved Successfully", "success");
            if (selectedObj?.performerid)
                dispatch(loadAllRates(selectedObj.performerid));

            setStateData(null);
            handleDurationShowHide("", "save", -1)
        }
    }, [rateAddedStatusSelector])

    const { validate, handleChange, stateData, setStateData,
        stateErrors, setStateErrors, handleSubmit } = useForm(schema, doSubmit);

    const { renderSelect, renderInput, renderTextArea } = useControls(stateData, stateErrors, handleChange, schema);

    return <>

        {editType === "rate" && stateData &&
            <div className="p-3 border mt-2">
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => handleDurationShowHide("", "", -1)}
                        >
                            <i className="fa fa-times mr-2"></i>Close
                        </button>
                    </div>
                    <div className="col-md-12 text-center">
                        <h3>Modify Rate</h3>
                    </div>
                    <div className="col-md-12">
                        <table className="table table-bordered bg-white" cellPadding={10}>
                            <thead className="table-header">
                                <tr>
                                    <th></th>
                                    <th>Existing Rate</th>
                                    <th>New Rate</th>
                                    <th>
                                        Effective Date (
                                        <span className="text-danger">
                                            Always Effective from the 1st of the month
                                        </span>
                                        )
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Rate Changed By Client/Agency</th>
                                    <td>
                                        {renderInput("existingAgencyRate", "", "number", "s")}
                                    </td>
                                    <td>
                                        {renderInput("newAgencyRate", "", "number", "s")}
                                    </td>
                                    <td>
                                        {renderInput("dateAgencyRate", "", "date", "s")}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Rate Change to Recruiter</th>
                                    <td>
                                        {renderInput("existingRecruiterRate", "", "number", "s")}
                                    </td>
                                    <td>
                                        {renderInput("newRecruiterRate", "", "number", "s")}
                                    </td>
                                    <td>
                                        {renderInput("dateRecruiterRate", "", "date", "s")}
                                    </td>
                                </tr>
                                {entityType === "C2C Basis" && (
                                    <tr>
                                        <th>Rate Change to Subcontractor</th>
                                        <td>
                                            {renderInput("existingSubcontractorRate", "", "number", "s")}
                                        </td>
                                        <td>
                                            {renderInput("newSubcontractorRate", "", "number", "s")}
                                        </td>
                                        <td>
                                            {renderInput("dateSubcontractorRate", "", "date", "s")}
                                        </td>
                                    </tr>
                                )}
                                {entityType !== "C2C Basis" && (
                                    <tr>
                                        <th>Rate Change to Candidate</th>
                                        <td>
                                            {renderInput("existingCandidateRate", "", "number", "s")}
                                        </td>
                                        <td>
                                            {renderInput("newCandidateRate", "", "number", "s")}
                                        </td>
                                        <td>
                                            {renderInput("dateCandidateRate", "", "date", "s")}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-12">
                        {renderTextArea("notes", "Notes")}
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="" className="form-control-label">
                            History
                        </label>
                        <table className="table table-bordered bg-white" cellPadding={10}>
                            <thead className="thead-light">
                                <tr>
                                    <th>S.No.</th>
                                    <th>Date & Time</th>
                                    {entityType !== "C2C Basis" && (
                                        <th>
                                            Candidate Rate
                                            <br />
                                            Effective Date
                                        </th>
                                    )}
                                    {entityType === "C2C Basis" && (
                                        <th>
                                            Subcontractor Rate
                                            <br />
                                            Effective Date
                                        </th>
                                    )}
                                    <th>
                                        Recruiter Rate
                                        <br />
                                        Effective Date
                                    </th>
                                    <th>
                                        Agency Rate
                                        <br />
                                        Effective Date
                                    </th>
                                    <th>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rateHistory.map((item, key) => (
                                    <tr>
                                        <td>{key + 1}</td>
                                        <td>
                                            <Moment format="MMM Do YYYY">
                                                {item.addedDateTime}
                                            </Moment>
                                        </td>
                                        {entityType !== "C2C Basis" && (
                                            <td>
                                                <b>${item.candidateRate}/Hr</b>
                                                <br />
                                                <Moment format="MMM Do YYYY">
                                                    {item.candidateEffectiveDate}
                                                </Moment>
                                            </td>
                                        )}
                                        {entityType === "C2C Basis" && (
                                            <td>
                                                <b>${item.subcontractorRate}/Hr</b>
                                                <br />
                                                <Moment format="MMM Do YYYY">
                                                    {item.subcontractorEffectiveDate}
                                                </Moment>
                                            </td>
                                        )}
                                        <td>
                                            <b>$ {item.recruiterRate}/Hr</b>
                                            <br />
                                            <Moment format="MMM Do YYYY">
                                                {item.recruiterEffectiveDate}
                                            </Moment>
                                        </td>
                                        <td>
                                            <b>${item.agencyRate}/Hr</b>
                                            <br />
                                            <Moment format="MMM Do YYYY">
                                                {item.agencyEffectiveDate}
                                            </Moment>
                                        </td>
                                        <td>{item.notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-12 text-center mt-2">
                        <button className="btn btn-sm btn-outline-default"
                            onClick={handleSubmit}
                        >
                            <i class="fa fa-check" aria-hidden="true"></i> Save
                        </button>
                    </div>
                </div>
            </div>}
    </>
}

export default AddEditRates;