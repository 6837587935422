import React, { Component } from "react";
import Form from "../comman/form";
import { Modal } from "react-bootstrap";
import WorkflowDesign from "./WorkflowDesign";

class WorkflowMapPopup extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      errors: {},
      selectedObj: {},
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    //loadjs("/assets/js/workflow.js")
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedObj) {
      const { selectedObj } = nextProps;
      this.setState({ selectedObj });
    }
  }

  render() {
    const { selectedObj } = this.state;
    const { workflowShowHide, handleWorkflowModalShowHide } = this.props;
    return (
      <Modal show={workflowShowHide} size="lg">
        <Modal.Header
          closeButton
          onHide={() => handleWorkflowModalShowHide("")}
        >
          <Modal.Title>Workflow Process Map</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          <div className="row">
            <div className="col-lg-12">
              <h4>
                Process ID - <span>{selectedObj.ciid}</span>, Task Name -
                <span>{selectedObj.currentTask}</span>
              </h4>
            </div>
          </div>
          <div className="row" style={{ height: "400px", overflow: "scroll" }}>
            <WorkflowDesign selectedObj={selectedObj}></WorkflowDesign>
            <div ref={this.myRef} />;
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={() => handleWorkflowModalShowHide("")}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default WorkflowMapPopup;
