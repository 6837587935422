import React, { Component } from "react";
import CandidatePay from "./CandidatePay";
import Moment from "react-moment";

class CandidateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidateInvoices: [
        {
          id: 1,
          year: 2021,
          month: 1,
          monthName: "January",
          projectStartDate: "12/1/2021",
          period: [
            {
              startDate: "1/1/2021",
              endDate: "31/1/2021",
              hours: "10",
              timeSheet: 1,
              timeLogged: 1,
              week: 1,
              weekDays: 5,
            },
            {
              startDate: "1/1/2021",
              endDate: "31/1/2021",
              hours: "10",
              timeSheet: 1,
              timeLogged: 1,
              week: 1,
              weekDays: 5,
            },
          ],
          rate: 40,
          totalWorkingHrs: 57,
          totalInvoiceAmt: 2280,
        },
      ],
      candidateDetails: {
        id: 150,
        name: "amir khan",
        date: "11/19/2021",
        entity: "C2C Basis",
        email: "amir@actech.com",
        phone: "963-986-8765",
        role: "Developer",
        subContractor: "Acetech, Minneapolis, MN",
        contractorId: 1,
        client: "State of Minnesota",
        clientId: 1,
        engagement: "Direct-to-IPCS",
        duration: "12 Months",
        startDate: "12/1/2021",
        endDate: "12/31/2022",
        rate: "40",
        status: false,
      },
      payShowHide: false,
    };
  }

  handlePayShowHide = () => {
    this.setState({ payShowHide: !this.state.payShowHide });
  };

  render() {
    const {
      candidateInvoices,
      candidateDetails: item,
      payShowHide,
    } = this.state;
    const { type } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 form-group">
            <div className="table-responsive">
              <table className="table align-items-center table-flush table-hover table-striped mb-2 border">
                <thead className="thead-light">
                  <tr>
                    <th>Candidate #</th>
                    <th>Candidate Details</th>
                    <th>Client</th>
                    <th>Duration</th>
                    <th>Candidate Onboarding Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item.id}</td>
                    <td>
                      <span className="text-uppercase">{item.name}</span>
                      <br />
                      <a href="#">{item.email}</a>
                      <br />
                      {item.phone}
                      <br />
                      {item.role}
                      <br />
                      <span className="badge badge-primary">
                        <Moment format="MM-DD-YYYY">{item.date}</Moment>
                        <br />
                        <Moment format="h:mm A">{item.date}</Moment>
                      </span>
                      <br />
                      Rate/Hr - Payable By IPCS : ${item.rate} Rate/Hr
                    </td>

                    <td>
                      {item.clientId} - {item.client}
                    </td>
                    <td>
                      {item.duration}
                      <br />
                      Start : {item.startDate}
                      <br />
                      End : {item.endDate}
                    </td>
                    <td>
                      {item.status && (
                        <button className="btn btn-sm btn-primary">
                          Complete
                        </button>
                      )}
                      {!item.status && (
                        <button className="btn btn-sm btn-danger">
                          Not Complete
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {type === "subscontractor" && (
            <React.Fragment>
              <div className="col-md-12">
                <div className="card card-body p-3 shadow-none border">
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="year" className="form-control-label">
                        Invoice Year
                      </label>
                      <select name="year" id="year" className="form-control">
                        <option value="0">All</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="month" className="form-control-label">
                        Invoice Month
                      </label>
                      <select name="month" id="month" className="form-control">
                        <option value="0">All</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card shadow-none border">
                  <div className="card-header p-2 bg-admin-light border-admin-left">
                    <h4 className="mb-0 text-uppercase">
                      Candidate Time Log Management and subcontractor invoice
                    </h4>
                  </div>
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-body p-3 shadow-none border">
                          <div className="row">
                            <div className="col-md-6">
                              <label
                                htmlFor="year"
                                className="form-control-label"
                              >
                                Invoice Year
                              </label>
                              <select
                                name="year"
                                id="year"
                                className="form-control"
                              >
                                <option value="0">All</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="month"
                                className="form-control-label"
                              >
                                Invoice Month
                              </label>
                              <select
                                name="month"
                                id="month"
                                className="form-control"
                              >
                                <option value="0">All</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="table-responsive">
                          <table className="table align-items-center table-flush table-striped mb-2 border">
                            <thead className="thead-light">
                              <tr>
                                <th>Year</th>
                                <th>Month</th>
                                <th>
                                  Project
                                  <br /> Start Date
                                </th>
                                <th>Period</th>
                                <th>
                                  Working
                                  <br />
                                  Hrs
                                </th>
                                <th>Rate/Hr ($)</th>
                                <th>
                                  Invoice
                                  <br />
                                  Amount ($)
                                </th>
                                <th>
                                  Subcontractor <br />
                                  Invoice
                                </th>
                                <th>Status</th>
                                <th>Payment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {candidateInvoices.map((item) => (
                                <tr>
                                  <td>{item.year}</td>
                                  <td>
                                    <span className="text-uppercase">
                                      {item.monthName}
                                    </span>
                                  </td>
                                  <td>{item.projectStartDate}</td>
                                  <td>
                                    <table className="table table-bordered">
                                      <thead className="border bg-lighter">
                                        <tr>
                                          <th>Week</th>
                                          <th>Hrs</th>
                                          <th>Time Logged</th>
                                          <th>Time Sheet</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item.period.map((p) => (
                                          <tr>
                                            <td>
                                              {p.startDate} - {p.endDate}
                                            </td>
                                            <td>{p.hours}</td>
                                            <td>
                                              <button className="btn btn-sm btn-link">
                                                View
                                              </button>
                                            </td>
                                            <td>
                                              <a href="#">Time Sheet</a>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </td>
                                  <td>{item.totalWorkingHrs}</td>
                                  <td>{item.rate}</td>
                                  <td>{item.totalInvoiceAmt}</td>
                                  <td>
                                    <button className="btn btn-sm btn-primary">
                                      Download
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={this.handleInvRevShowHide}
                                    >
                                      Review
                                    </button>
                                    <br />
                                    <button className="btn btn-sm btn-success mt-2">
                                      Approve
                                    </button>
                                    <br />
                                    <button className="btn btn-sm btn-danger mt-2">
                                      Reject
                                    </button>
                                    <br />
                                    Final Amt : $2280
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-outline-default btn-sm"
                                      onClick={this.handlePayShowHide}
                                    >
                                      Pay
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {type === "recruiter" && (
            <React.Fragment>
              <div className="col-md-12">
                <div className="card shadow-none border">
                  <div className="card-header p-2 bg-admin-light border-admin-left">
                    <h4 className="mb-0 text-uppercase">
                      Candidate Time Log Management and Recruiter invoice
                    </h4>
                  </div>
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-body p-3 shadow-none border">
                          <div className="row">
                            <div className="col-md-6">
                              <label
                                htmlFor="year"
                                className="form-control-label"
                              >
                                Invoice Year
                              </label>
                              <select
                                name="year"
                                id="year"
                                className="form-control"
                              >
                                <option value="0">All</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="month"
                                className="form-control-label"
                              >
                                Invoice Month
                              </label>
                              <select
                                name="month"
                                id="month"
                                className="form-control"
                              >
                                <option value="0">All</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="table-responsive">
                          <table className="table align-items-center table-flush table-striped mb-2 border">
                            <thead className="thead-light">
                              <tr>
                                <th>Year</th>
                                <th>Month</th>
                                <th>
                                  Project
                                  <br /> Start Date
                                </th>
                                <th>Period</th>
                                <th>
                                  Working
                                  <br />
                                  Hrs
                                </th>
                                <th>Rate/Hr ($)</th>
                                <th>
                                  Invoice
                                  <br />
                                  Amount ($)
                                </th>
                                <th>
                                  Recruiter <br />
                                  Invoice
                                </th>
                                <th>Status</th>
                                <th>Payment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {candidateInvoices.map((item) => (
                                <tr>
                                  <td>{item.year}</td>
                                  <td>
                                    <span className="text-uppercase">
                                      {item.monthName}
                                    </span>
                                  </td>
                                  <td>{item.projectStartDate}</td>
                                  <td>
                                    <table className="table table-bordered">
                                      <thead className="border bg-lighter">
                                        <tr>
                                          <th>Week</th>
                                          <th>Hrs</th>
                                          <th>Time Logged</th>
                                          <th>Time Sheet</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item.period.map((p) => (
                                          <tr>
                                            <td>
                                              {p.startDate} - {p.endDate}
                                            </td>
                                            <td>{p.hours}</td>
                                            <td>
                                              <button className="btn btn-sm btn-link">
                                                View
                                              </button>
                                            </td>
                                            <td>
                                              <a href="#">Time Sheet</a>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </td>
                                  <td>{item.totalWorkingHrs}</td>
                                  <td>{item.rate}</td>
                                  <td>{item.totalInvoiceAmt}</td>
                                  <td>
                                    <button className="btn btn-sm btn-primary">
                                      Download
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={this.handleInvRevShowHide}
                                    >
                                      Review
                                    </button>
                                    <br />
                                    <button className="btn btn-sm btn-success mt-2">
                                      Approve
                                    </button>
                                    <br />
                                    <button className="btn btn-sm btn-danger mt-2">
                                      Reject
                                    </button>
                                    <br />
                                    Final Amt : $2280
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-outline-default btn-sm"
                                      onClick={this.handlePayShowHide}
                                    >
                                      Pay
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {type === "agency" && (
            <React.Fragment>
              <div className="col-md-12">
                <div className="card shadow-none border">
                  <div className="card-header p-2 bg-admin-light border-admin-left">
                    <h4 className="mb-0 text-uppercase">
                      Invoicing to client/Agency
                    </h4>
                  </div>
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card card-body p-3 shadow-none border">
                          <div className="row">
                            <div className="col-md-6">
                              <label
                                htmlFor="year"
                                className="form-control-label"
                              >
                                Invoice Year
                              </label>
                              <select
                                name="year"
                                id="year"
                                className="form-control"
                              >
                                <option value="0">All</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="month"
                                className="form-control-label"
                              >
                                Invoice Month
                              </label>
                              <select
                                name="month"
                                id="month"
                                className="form-control"
                              >
                                <option value="0">All</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="table-responsive">
                          <table className="table align-items-center table-flush table-striped mb-2 border">
                            <thead className="thead-light">
                              <tr>
                                <th>Year</th>
                                <th>Month</th>
                                <th>
                                  Project
                                  <br /> Start Date
                                </th>
                                <th>Period</th>
                                <th>
                                  Working
                                  <br />
                                  Hrs
                                </th>
                                <th>
                                  Rate/Hr ($)
                                  <br />
                                  Payable to IPCS
                                </th>
                                <th>
                                  Invoice
                                  <br />
                                  Amount ($)
                                </th>
                                <th>
                                  Upload <br />
                                  Invoice
                                </th>
                                <th>Status</th>
                                <th>Payment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {candidateInvoices.map((item) => (
                                <tr>
                                  <td>{item.year}</td>
                                  <td>
                                    <span className="text-uppercase">
                                      {item.monthName}
                                    </span>
                                  </td>
                                  <td>{item.projectStartDate}</td>
                                  <td>
                                    <table className="table table-bordered">
                                      <thead className="border bg-lighter">
                                        <tr>
                                          <th>From</th>
                                          <th>To</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item.period.map((p) => (
                                          <tr>
                                            <td>{p.startDate}</td>
                                            <td>{p.endDate}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </td>
                                  <td>{item.totalWorkingHrs}</td>
                                  <td>{item.rate}</td>
                                  <td>{item.totalInvoiceAmt}</td>
                                  <td>
                                    <input
                                      type="file"
                                      className="form-control form-control-sm"
                                      name={`file_${item.id}`}
                                    />
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={this.handleInvRevShowHide}
                                    >
                                      Review
                                    </button>
                                    <br />
                                    <button className="btn btn-sm btn-success mt-2">
                                      Approve
                                    </button>
                                    <br />
                                    <button className="btn btn-sm btn-danger mt-2">
                                      Reject
                                    </button>
                                    <br />
                                    Final Amt : $2280
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-outline-default btn-sm"
                                      onClick={this.handlePayShowHide}
                                    >
                                      Pay
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        <CandidatePay
          payShowHide={payShowHide}
          handlePayShowHide={this.handlePayShowHide}
        ></CandidatePay>
      </React.Fragment>
    );
  }
}

export default CandidateList;
