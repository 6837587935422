import React, { Component, useLayoutEffect } from "react";
import Steps, { Step } from "rc-steps";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import ReviewSubProcessPopup from "./ReviewSubProcessPopup";

class MySteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processShowHide: false,
      selectedTask: "",
      subProcessNum: 0,
    };
  }

  handleProcessShowHide = (tsk) => {
    this.setState({
      processShowHide: !this.state.processShowHide,
      selectedTask: tsk,
    });
  };

  setSubprocessNum = () => {
    const { subProcessNum } = this.state;
    const newNum = subProcessNum + 1;
    //this.setState({ subprocessNum: newNum });
    if (newNum === 1) return true;
    return false;
  };

  render() {
    const { processShowHide, selectedTask } = this.state;
    const {
      custApp,
      handleJorneySteps,
      compList,
      handleToolShowHide,
      candidateObj,
    } = this.props;

    const pendingPhases = [];
    const subprocessIndex = [];
    const subprocessLastIndex = [];
    let approvedDenied = "";
    if (custApp) {
      custApp.allWfPhase
        .filter((x) => x.wfPhase !== "" && x.wfPhase !== "Application")
        .map((f) => {
          const wfTasks = custApp.workflowTasks.filter(
            (x) => x.wfPhase === f.wfPhase && x.itemType === 14
          );

          //console.log(f.wfPhase);
          // console.log(wfTasks);

          const newOrder = wfTasks.sort(
            (a, b) => a.orderNumber - b.orderNumber
          );
          //console.log(newOrder);
          if (newOrder.length > 0) {
            const objItem = { wfPhase: f.wfPhase, itemId: newOrder[0].itemId };
            const objItem1 = {
              wfPhase: f.wfPhase,
              itemId: newOrder[newOrder.length - 1].itemId,
            };
            subprocessIndex.push(objItem);
            subprocessLastIndex.push(objItem1);
          }
          if (f.wfPhase === "Rejected") {
            const deniedTasks = custApp.workflowTasks.filter(
              (x) => x.wfPhase === "Rejected" && x.taskDoneStatus > 1
            );
            if (deniedTasks.length > 0) approvedDenied = "Rejected";
          }

          if (f.wfPhase === "Approved") {
            const approvedTsk = custApp.workflowTasks.filter(
              (x) => x.wfPhase === "Approved" && x.taskDoneStatus > 1
            );
            if (approvedTsk.length > 0) approvedDenied = "Approved";
          }

          // newOrder.map((o) => console.log(o.orderNumber, o.itemName));

          // console.log(wfTasks.filter((t) => t.taskDoneStatus < 1));
          // const totalTask = wfTasks.length;
          // console.log(totalTask);
          // const doneTask = wfTasks.filter((t) => t.taskDoneStatus > 1).length;
          // console.log(doneTask);
          // if (totalTask !== doneTask) pendingPhases.push(f.wfPhase);
        });
    }

    const donePhase = custApp?.allWfPhase ? custApp.allWfPhase.length - pendingPhases.length : 0;
    
    let currentStep = 0;
    if (
      custApp?.workflowTasks.filter(
        (x) =>
          (x.wfPhase === "Approved" || x.wfPhase === "Rejected") &&
          x.taskDoneStatus > 1
      ).length > 1
    )
      currentStep = 1;

    return (
      <React.Fragment>
        <Steps direction="vertical" current={3} status="error">
          {custApp !== undefined &&
            custApp.allWfPhase
              .filter(
                (w) =>
                  w.wfPhase !== "" &&
                  w.wfPhase !== "Application" &&
                  w.wfPhase !== "Onboarding"
              )
              .map((f, key) => {
                if (f.wfPhase === "Rejected") return;
                return (
                  <Step
                    title={f.wfPhase === "" ? "No Phase" : f.wfPhase}
                    description={
                      <ul>
                        {f.wfPhase === "Review" && (
                          <li>
                            <span
                              className="text-blue"
                              style={{ cursor: "pointer" }}
                              onClick={() =>handleToolShowHide(1,"")}
                            >
                              Application Review
                            </span>
                          </li>
                        )}
                        {f.wfPhase !== "Onboarding" &&
                          custApp?.workflowTasks
                            .filter(
                              (x) =>
                                x.wfPhase === f.wfPhase &&
                                x.itemType !== 3 &&
                                x.itemType !== 2 &&
                                x.itemType !== 4
                            ).sort((a, b) => a.orderNumber - b.orderNumber)
                            .map((task) => (
                              <>
                                {task.itemType === 14 &&
                                  subprocessIndex.filter(
                                    (s) =>
                                      s.wfPhase === f.wfPhase &&
                                      s.itemId === task.itemId
                                  ).length > 0 && (
                                    <li
                                      style={{
                                        listStyle: "none",
                                        marginLeft: "-15px",
                                        padding: "10px 0px 0px 0px",
                                      }}
                                    >
                                      <p className="mb-0">
                                        <img
                                          src="/assets/Images/WFAssociationIcons/subprocess.gif"
                                          title="Subprocess"
                                          style={{
                                            height: "18px",
                                            marginRight: "0px",
                                            marginTop: "-3px",
                                          }}
                                        />{" "}
                                        Subprocess
                                      </p>
                                    </li>
                                  )}

                                <li
                                  style={{
                                    marginLeft: `${
                                      task.itemType === 14 && "16px"
                                    }`,
                                    marginBottom: `${
                                      subprocessLastIndex.filter(
                                        (l) =>
                                          l.wfPhase === f.wfPhase &&
                                          l.itemId === task.itemId
                                      ).length > 0 && "10px"
                                    }`,
                                  }}
                                >
                                  {task.taskDoneStatus === 0 &&
                                    task.itemType === 1 &&
                                    task.itemName}
                                    
                                  {task.taskDoneStatus === 4 &&
                                    task.itemType === 1 && (
                                      <span
                                        className="text-blue"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleJorneySteps(task.wiid)
                                        }
                                      >
                                        {task.itemName}{" "}
                                        <i
                                          className="fa fa-check-square text-green"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}

                                  {task.taskDoneStatus === 2 &&
                                    task.itemType === 1 && (
                                      <span
                                        className="text-blue"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleJorneySteps(task.wiid)
                                        }
                                      >
                                        {task.itemName}
                                      </span>
                                    )}
                                  {task.itemType !== 1 &&
                                    task.itemType !== 14 && (
                                      <span
                                        className={`${
                                          task.taskDoneStatus > 0 && "text-blue"
                                        }`}
                                      >
                                        {task.itemName}
                                      </span>
                                    )}

                                  {task.itemType === 14 && (
                                    <span
                                      className={`${
                                        task.taskDoneStatus > 0 && "text-blue"
                                      }`}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        task.taskDoneStatus > 0 &&
                                        this.handleProcessShowHide(task)
                                      }
                                    >
                                      {task.itemName}{" "}
                                      {task.taskDoneStatus === 4 && (
                                        <i
                                          className="fa fa-check-square text-green"
                                          aria-hidden="true"
                                        ></i>
                                      )}
                                    </span>
                                  )}
                                </li>
                              </>
                            ))}
                      </ul>
                    }
                  />
                );
              })}
        </Steps>
        <ReviewSubProcessPopup
          processShowHide={processShowHide}
          handleProcessShowHide={this.handleProcessShowHide}
          compList={compList}
          handleToolShowHide={handleToolShowHide}
          selectedTask={selectedTask}
          custApp={custApp}
          candidateObj={candidateObj}
        ></ReviewSubProcessPopup>
      </React.Fragment>
    );
  }
}

export default MySteps;
