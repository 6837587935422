import React, { Component } from "react";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap";

class AddNewGroupDocs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      message: "",
      documents: [],
      externalInterface: [],
      selectedOption: "",
    };
  }

  async componentDidMount() {}

  onFileChange = async (event) => {
    //console.log(event.target.files);
    const docs = [...this.state.documents];
    for (const file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        file: file,
      };
      docs.push(doc);
    }
    this.setState({ documents: docs });
  };

  handleFileRemove = (item) => {
    const index = this.state.documents.indexOf(item);
    let documents = [...this.state.documents];
    if (index !== -1) {
      documents.splice(index, 1);
      this.setState({ documents });
    }
  };

  handleSubmit = () => {
    this.props.handleAddNewMessage(this.state.message, this.state.documents);
  };

  handleChange1 = (selectedOption) => {
    //console.log(selectedOption);
    if (selectedOption === null) {
      this.setState({ selectedOption: [] });
      return;
    }
    //this.setState({ selectedOption });
    // console.log(`Option selected:`, selectedOption);
    const data = { ...this.state.data };
    let interfaces = [];
    selectedOption.map((item) =>
      interfaces.push({ interfaceName: item.value, interfaceName: item.label })
    );
    data["accessInterfaces"] = interfaces;
    this.setState({ data, selectedOption });
  };

  render() {
    const { showHide, handleModalShowHide, WorkflowTask } = this.props;
    const { documents, selectedOption } = this.state;

    const options = [
      { value: "1", label: "Performer 1" },
      { value: "2", label: "Performer 2" },
      { value: "3", label: "Performer 3" },
    ];

    return (
      <Modal show={showHide} size={"lg"} onHide={handleModalShowHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Group Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 form-group">
              <Select
                value={selectedOption}
                onChange={this.handleChange1}
                options={options}
                isMulti={true}
              />
            </div>
            <div className="col-md-12 form-group">
              <label htmlFor="message" className="form-control-label">
                Message
              </label>
              <textarea
                name="message"
                id="message"
                cols="5"
                rows="3"
                className="form-control"
                value={this.state.message}
                onChange={({ currentTarget }) =>
                  this.setState({ message: currentTarget.value })
                }
              ></textarea>
            </div>
            <div className="col-md-12 form-group">
              <div className="dropdown">
                <button
                  className="btn btn-success dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fa fa-paperclip mr-2" aria-hidden="true"></i>
                  Attachment
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item" href="!#">
                    <label
                      htmlFor="AdhocMessagedocName"
                      className="custom-file-upload mb-0"
                    >
                      <i className="fa fa-desktop mr-2" aria-hidden="true" />
                      Upload Form Computer
                    </label>
                    <input
                      id="AdhocMessagedocName"
                      type="file"
                      style={{ display: "none" }}
                      onChange={this.onFileChange}
                      multiple
                      name="files"
                    />
                  </a>
                  <a className="dropdown-item" href="!#">
                    <i class="fa fa-database mr-2" aria-hidden="true"></i>
                    Upload From DDRMS
                  </a>
                  <a className="dropdown-item" href="!#">
                    <i class="fa fa-dropbox mr-2" aria-hidden="true"></i>
                    Upload From Dropbox
                  </a>
                  <a className="dropdown-item" href="!#">
                    <i class="fa fa-google mr-2" aria-hidden="true"></i>
                    Upload From Google Drive
                  </a>
                </div>
              </div>
              <table className="table table-striped align-items-center table-flush mt-2">
                <thead className="thead-light">
                  <tr>
                    <th>S.No</th>
                    <th>Document</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((item, key) => (
                    <tr>
                      <td>{key + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-link"
                          title="Remove"
                          onClick={() => this.handleFileRemove(item)}
                        >
                          <i
                            class="fa fa-times text-red"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalShowHide}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddNewGroupDocs;
