import { combineReducers } from "@reduxjs/toolkit";
import workflowRoleReducers from "./workflowRole"
import subcontractorsReducers from "./subcontractor";
import recruiterReducers from "./recruiter";
import candidateReducers from "./candidate";
import toolsReducers from "./tools";
import bprmFormReducers from "./bprmForm";
import applicationReducers from "./application";
import workflowEngineReducers from "./WorkflowEngine";
import timeLogReducers from "./timeLog";
import timeLogInvoiceReducers from "./timeLogInvoice";
import partnerReducers from "./partner";
import layoutUIReducers from "./layoutUI";
import commentstReducers from "./comments";
import messagesReducers from "./messages";
import documentsReducers from "./documents";
import formsReducers from "./forms";
import phoneCallReducers from "./phoneCall";
import templateReducers from "./template";
import scheduleReducers from "./schedule";
import ratesReducers from "./rates";
import durationReducers from "./duration";
import usersReducers from "./users";
import reportsReducer from "./reports"
import dashboardReducer from "./dashboard";
import complianceReportsReducer from "./complianceReports";
import wordProccessingReducer from "./wordProccessing";
import ddrmsDocumentsReducer from "./ddrmsDocument";
import dataTypesReducer from "./dataTypes";
import purchaseOrdersReducer from "./purchaseOrder";
import reviewNotesReducer from "./reviewNotes";
import supportReducer from "./support";

export default combineReducers({
    support: supportReducer,
    workflowRoles: workflowRoleReducers,
    subcontractors: subcontractorsReducers,
    recruiters: recruiterReducers,
    candidates: candidateReducers,
    tools: toolsReducers,
    bprmForm: bprmFormReducers,
    application: applicationReducers,
    workflowEngine: workflowEngineReducers,
    timeLog: timeLogReducers,
    timeLogInvoice: timeLogInvoiceReducers,
    partners: partnerReducers,
    layoutUI: layoutUIReducers,
    comments: commentstReducers,
    messages: messagesReducers,
    documents: documentsReducers,
    forms: formsReducers,
    phoneCalls:phoneCallReducers,
    templates:templateReducers,
    schedules:scheduleReducers,
    rates:ratesReducers,
    duration:durationReducers,
    users:usersReducers,
    reports:reportsReducer,
    dashboard:dashboardReducer,
    complianceReports:complianceReportsReducer,
    wordProccessing:wordProccessingReducer,
    ddrmsDocuments:ddrmsDocumentsReducer,
    dataTypes:dataTypesReducer,
    purchaseOrders:purchaseOrdersReducer,
    reviewNotes:reviewNotesReducer
});