import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import InfoHeader from "../../comman/infoHeader";
import PageHeader from "../PageHeader";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import config from "../../config.json";
import ChecklistItem from "../PartnerManage/ChecklistItem";

class SurveyFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: [{ name: "Communication", url: "" }],
      appTasklist: [],
      user: {},
    };
  }

  async componentDidMount() {
    let user = auth.getCurrentUser();
    if (user.type === "partner") {
      user.id = user.linkUser;
    }
    const { data: appliedApplication } = await http.get(
      config.GetPerformerApplication + user.id
    );

    if (appliedApplication.length > 0) {
      const surveyApp = appliedApplication.filter((x) => x.type === "Survey");
      let appTasklist = [];
      surveyApp.map(async (item) => {
        const { data: runningApplication } = await http.get(
          config.GetRunningTask + item.workflowId + "&performerId=" + user.id
        );
        const obj = {
          chkApp: item,
          runningApplication,
        };
        appTasklist.push(obj);
        this.setState({ appTasklist, user });
      });
    }
  }

  render() {
    const { route, appTasklist, user } = this.state;
    return (
      <React.Fragment>
        {/* <PageHeader
          route={route}
          parentTitle="Surveys"
          headerTitle="Surveys"
        ></PageHeader> */}
        <div className="col-md-12 form-group mt-3">
          <ul className="list-group">
            <li className="list-group-item">
              <button className="btn btn-success btn-sm">
                Complete Your Survey
              </button>
            </li>
          </ul>
          <ul className="list-group mt-3">
            {appTasklist.map((app) => (
              <React.Fragment>
                <ChecklistItem
                  Checklist={app.chkApp}
                  color="#fce7a9"
                  type="ApplicationStatus"
                ></ChecklistItem>
                <li className="list-group-item">
                  <div className="table-responsive">
                    <table className="table align-items-center table-flush table-hover table-striped">
                      <thead className="thead-light-pink">
                        <tr>
                          <th>App Id</th>
                          <th>Date</th>
                          <th>Application Workflow Status</th>
                          <th>Review Officer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {app.runningApplication.map((item) => (
                          <tr>
                            <td>{item.ciid}</td>
                            <td>
                              <Moment format="MMM Do YYYY">
                                {item.startDateTime}
                              </Moment>
                              <br></br>
                              <Moment format="h:mm A">
                                {item.startDateTime}
                              </Moment>
                            </td>
                            <td>
                              {parseInt(user.id) === item.performerId ? (
                                <Link
                                  onClick={() =>
                                    this.handleCurrentTask(item.wiid)
                                  }
                                >
                                  {item.taskName}
                                </Link>
                              ) : (
                                item.taskName
                              )}
                            </td>
                            <td>
                              {parseInt(user.id) !== item.performerId ? (
                                <React.Fragment>
                                  {item.performer !== null &&
                                    item.performer.toUpperCase()}
                                  <br></br>
                                  {item.email !== null &&
                                    "Email : " + item.email}
                                  <br></br>
                                  {item.contact !== null &&
                                    "Contact : " + item.contact}
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default SurveyFeedback;
