import React, { Component } from "react";
import ClientHeader from "./ClientHeader";
import { Link } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import TopMenu from "../app/components/Header/TopMenu";
import Menu from "../app/components/Header/Menu";
import LeftMenu from "./Menu";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHide: false,
    };
  }

  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  

  render() {
    const { showHide } = this.state;
    const route = [
      { url: "", name: "System Administration" },
      { url: "", name: "Authorized Users" },
    ];
    const headerTitle="Main Users";
    return (
      <React.Fragment>
        {/* <ClientHeader></ClientHeader> */}
        <div className="col-md-12">
          <TopMenu />
        </div>

        <div className="col-md-12">
          <div
            className="d-flex justify-content-center"
            style={{
              //backgroundColor: "#fdc300",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "50px",
            }}
          >
            <Menu />
          </div>
        </div>
        <LeftMenu />
        <div className="col-md-12 form-group">
          <nav
            aria-label="breadcrumb"
            className="bg-white p-3 border"
          >
            <ol className="breadcrumb breadcrumb-links">
              <li className="breadcrumb-item">
                <a href="/">
                  <i className="fa fa-home" />
                </a>
              </li>
              {headerTitle !== "Dashboard" && (
                <React.Fragment>
                  <li className="breadcrumb-item">
                    <a href="/">Dashboard</a>
                  </li>
                </React.Fragment>
              )}
              {headerTitle !== "Quick Links" &&
                headerTitle !== "Dashboard" && (
                  <li className="breadcrumb-item">
                    <a href="/QuickLinks">Quick Links</a>
                  </li>
                )}

              {route?.map((obj) => (
                <li key={obj.name} className="breadcrumb-item">
                  {obj.url === "" ? (
                    <span className="text-blue">{obj.name}</span>
                  ) : (
                    <Link
                      to={{ pathname: obj.url, state: obj.state }}
                    >
                      {obj.name}
                    </Link>
                  )}
                </li>
              ))}
              {headerTitle && (
                <li className="breadcrumb-item" aria-current="page">
                  {headerTitle}
                </li>
              )}
            </ol>
          </nav>
        </div>

        <ChangePassword
          showHide={showHide}
          handleModalShowHide={this.handleModalShowHide}
        ></ChangePassword>
      </React.Fragment>
    );
  }
}

export default Header;
