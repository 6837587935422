import React, { Component, useEffect } from "react";
import HCPHexagon from "../PartnerManage/HCPHexagon";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";
import { useDispatch } from "react-redux";

const HexagonLanding = () => {


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentPageTitle("Quick Links"))
  }, []);

  const handleShowHide = () => {
    //this.setState({ showHide: !this.state.showHide });
  };

  // const { route, showHide } = this.state;
  // const user = auth.getCurrentUser();
  return (
    <React.Fragment>
      <div className="bg-white mt-3">
        <HCPHexagon></HCPHexagon>
      </div>
    </React.Fragment>
  );
}

export default HexagonLanding;
