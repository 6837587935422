import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PublicLayout from "./app/Layout/Public/Layout";
import PrivateLayout from "./app/Layout/Private/Layout";
import Logout from "./components/Logout";
import ForgotValidID from "./components/Auth/ForgotValidID";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ValidateUser from "./components/Auth/ValidateUser";
import Login from "./components/Login";
import RequireAuth from "./app/components/Auth/RequireAuth";
import WorkflowUser from "./components/SystemAdministration/UserAuthenticationAccess/WorkflowUser";
import ManageWorkflowPerformer from "./components/ManageWorkflowPerformer";
import ListSubcontractor from "./components/OMCRegistry/ListSubcontractor";
import AdminLogin from "./components/AdminLogin";
import ManagePartnerPerformer from "./components/ManagePartnerPerformer";
import ListCondidate from "./components/OMCRegistry/ListCondidate";
import ListRecruiter from "./components/SystemAdministration/ManagePartner/ListRecruiter";
import CustomerApps from "./components/OMCRegistry/CustomerApps";
import ReviewTask from "./components/OMCRegistry/ReviewTask";
import CadidateDetails from "./components/OMCRegistry/CadidateDetails";
import PartnerProfile from "./components/PartnerManage/PartnerProfile";
import PrintTemplate from "./components/PrintTemplate";
import CandidateInvoice from "./components/OMCRegistry/CandidateInvoice";
import ManageBprmAccess from "./components/SystemAdministration/BprmAccess/ManageBprmAccess";
import AccessDdrms from "./components/SystemAdministration/DocumentManage/AccessDdrms";
import Dashboard from "./components/Dashboard";
import ApplicationReport from "./components/ApplicationReport";
import CustomReport from "./components/CustomReport";
import StandardReport from "./components/StandardReport";
import MonitoringLogs from "./components/MonitoringLogs";
import EmailSmtpSetup from "./components/EmailSmtpSetup";
import ApplicantEmails from "./components/SystemAdministration/ManageEmails/ApplicantEmails";
import PartnerEmails from "./components/SystemAdministration/ManageEmails/PartnerEmails";
import AgencyEmail from "./components/SystemAdministration/ManageEmails/AgencyEmail";
import SurveyFeedback from "./components/ApplicantProcess/SurveyFeedback";
import ApplicationSummary from "./components/ApplicationSummary";
import HexagonLanding from "./components/LandingPages/HexagonLanding";
import DownloadDocLink from "./app/components/Tools/DownloadDocLink";
import ViewFormLink from "./app/components/Tools/ViewFormLink";
import ChangePassword from "./components/ChangePassword";
import ResetPassword from "./components/Auth/ResetPassword";

function App() {

    return <Routes>
        <Route element={<PublicLayout />}>
            <Route path="/login" element={<AdminLogin />} />
            <Route path="/ForgotValidID" element={<ForgotValidID />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/ValidateUser" element={<ValidateUser />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />
        </Route>
        <Route element={<RequireAuth />}>

            <Route path="/logout" element={<Logout />} />
            <Route path="/" element={<PrivateLayout />}>
                <Route exact path="/" element={<Dashboard />} />
                <Route path="/QuickLinks" element={<HexagonLanding />} />
                <Route path="/WorkflowUser" element={<WorkflowUser />} />
                <Route
                    path="/ManageWorkflowPerformer"
                    element={<ManageWorkflowPerformer />}
                />
                <Route path="/ListSubcontractor/:type" element={<ListSubcontractor />} />
                <Route path="/Recruiter/:type" element={<ListRecruiter />} />
                <Route
                    path="/ManagePartnerPerformer"
                    element={<ManagePartnerPerformer />}
                />
                <Route path="/ListCondidate/:type" element={<ListCondidate />} />
                <Route path="/CustomerApps" element={<CustomerApps />} />
                <Route path="/ReviewTask" element={<ReviewTask />} />
                <Route path="/CandidateDetails" element={<CadidateDetails />} />
                <Route path="/Profile" element={<PartnerProfile />} />
                <Route path="/PrintTemplate" element={<PrintTemplate />} />
                <Route path="/CandidateInvoice" element={<CandidateInvoice />} />
                <Route path="/MngBprmAccess" element={<ManageBprmAccess />} />
                <Route path="/AccessDdrms" element={<AccessDdrms />} />
                <Route path="/Dashboard" element={<Dashboard />} />
                <Route path="/ApplicationReport" element={<ApplicationReport />} />
                <Route path="/CustomReport/:type" element={<CustomReport />} />
                <Route path="/StandardReport" element={<StandardReport />} />
                <Route path="/MonitoringLogs" element={<MonitoringLogs />} />
                <Route path="/EmailSmtpSetup" element={<EmailSmtpSetup />} />
                <Route path="/ApplicantEmails/:type" element={<ApplicantEmails />} />
                <Route path="/PartnerEmails/:type" element={<PartnerEmails />} />
                <Route path="/AgencyEmails/:type" element={<AgencyEmail />} />
                <Route path="/SurveyFeedback" element={<SurveyFeedback />} />
                <Route path="/ApplicationSummary" element={<ApplicationSummary />} />
                <Route path="/DownloadDocLink/:id" element={<DownloadDocLink />} />
                <Route path="/ViewFormLink/:id" element={<ViewFormLink />} />
            </Route>
        </Route>
    </Routes>
}

export default App;