import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AddWordTemplate from "./AddWordTemplate";
import { useDispatch, useSelector } from "react-redux";
import { SetWordTemplateLocked, getSearchDocuments, getWordTempLockedStatus, 
    getWordTemplates, loadDocumentSearch, loadWordTemplates } from "../../store/wordProccessing";
import Moment from "react-moment";
import { getAlert } from "../../../services/appAlerts";
import WordVersionHistory from "./WordVersionHistory";

const WordEditor = (props) => {
    const { workflowTask ,handleUpdateComponent} = props;
    const [wordTemplates, setWordTemplates] = useState([]);
    const [selectedKey, setSelectedKey] = useState("home");
    const [selectedTempId, setSelectedTempId] = useState(0);
    const [verSelectedTempId, setVerSelectedTempId] = useState(0);
    const [verSelectedTemp, setVerSelectedTemp] = useState(null);
    const [selectedTemp, setSelectedTemp] = useState(null);
    const [searchParam,setSearchParam]=useState("");
    const [updateCompStatus, setUpdateCompStatus] = useState(false);

    const wordTemplatesSelector = useSelector(getWordTemplates);
    const wordTempLockedStatusSelector = useSelector(getWordTempLockedStatus);
    const searchDocumentsSelector = useSelector(getSearchDocuments);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (workflowTask?.ciid > 0){
            dispatch(loadWordTemplates(workflowTask.ciid));
            setUpdateCompStatus(true);
        }
    }, []);

    useEffect(() => {
        if (wordTemplatesSelector.length > 0) {
            setWordTemplates(wordTemplatesSelector);
            let wordItems = "";
            wordTemplatesSelector.map((x, key) => {
                if (key > 0) wordItems += ",";
                wordItems += x.id;
            })
            if (updateCompStatus) {
                handleUpdateComponent(wordItems);
                setUpdateCompStatus(false);
            }
        }
        
    }, [wordTemplatesSelector])

    const handleSubmit = async () => {
        setSelectedKey("home");
        setSelectedTemp(null);
        setSelectedTempId(0);

        if (workflowTask?.ciid > 0){
            dispatch(loadWordTemplates(workflowTask.ciid))
            setUpdateCompStatus(true);
        }
    };

    const handleEditTemp = (item) => {
        //dispatch(updateAppSchedule(item));
    };

    const handleSelectTab = (tab) => {
        setSelectedKey(tab);
    }

    const handleResetSelection = () => {
        setSelectedTempId(0);
        setSelectedTemp(null);
    }

    const handleSelectTemplate = (item) => {
        setSelectedTemp(item);
        setSelectedTempId(item.id);
    }

    const handleWordTemplateLock = (item) => {
        dispatch(SetWordTemplateLocked(item.id));
    }

    useEffect(() => {
        if (wordTempLockedStatusSelector) {
            getAlert("", "Locked Successfully", "success");
            if (workflowTask?.ciid > 0)
                dispatch(loadWordTemplates(workflowTask.ciid));
        }
    }, [wordTempLockedStatusSelector]);

    const handleVersionViewTemp=(item)=>{
        if(item){
            setVerSelectedTempId(item.id);
            setVerSelectedTemp(item);
        }
        else{
            setVerSelectedTemp(null);
            setVerSelectedTempId(0)
        }
    }

    const handleClickSearch = () => {
        dispatch(loadDocumentSearch({
            "query": searchParam
        }))
    }

    useEffect(()=>{
        if(searchDocumentsSelector.length > 0){
            const docList=[];
            searchDocumentsSelector.map((d,key)=>{
                //console.log(d.document.tags)
                const dObj={
                    id:key+1,
                    templateName:d.document.title,
                    notes:d.document.description,
                    templateType:d.document.author,
                    createdDateTime:d.document.createdDate,
                    version:1,
                    isEdit:true,
                    templateData:d.document.tags.join(", ")
                }
                docList.push(dObj);
            })

            setWordTemplates(docList);
        }
    },[searchDocumentsSelector]);

    const handleResetSearch=()=>{
        setSearchParam("");
        setWordTemplates([]);

        if (workflowTask?.ciid > 0)
            dispatch(loadWordTemplates(workflowTask.ciid));
    }

    return <>
        {/* <button className="btn btn-primary" onClick={downloadDoc}>Download Document</button>
        <button className="btn btn-primary" onClick={handleViewForm}>View Form</button> */}
        <Tabs
            activeKey={selectedKey}
            onSelect={handleSelectTab}
            id="uncontrolled-tab-example"
            className="h3 border border-right-0 border-left-0 border-top-0 font-weight-normal"
        >
            <Tab eventKey="home" title="Case Reports" tabClassName="page-tab border border-left-0 border-top-0">
                <Tab.Content className="border border-top-0 p-3">
                
                    <div className="row">
                        <div className="col-md-9 form-group">
                            <input type="text" 
                            placeholder="Search by name, notes, created by and document data..."
                                className="form-control" 
                                id="txtSearch" 
                                name="txtSearch"
                                onChange={({currentTarget})=>setSearchParam(currentTarget.value)}
                                value={searchParam}
                            />
                        </div>
                        <div className="col-md-3 form-group">
                            <button 
                            className="btn btn-sm btn-outline-default"
                             onClick={handleClickSearch}
                             disabled={!searchParam}
                             ><i className="fa fa-check mr-2"/>Search Document</button>
                             <button 
                            className="btn btn-sm btn-outline-default"
                             onClick={handleResetSearch}
                             ><i className="fa fa-refresh mr-2"/>Reset</button>
                        </div>
                        <div className="col-md-12"><div className="table-responsive">

                        <table className="table table-striped align-items-center table-flush">
                            <thead className="thead-light-green">
                                <tr>
                                    <th>Select<span style={{ cursor: "pointer" }} onClick={handleResetSelection}>
                                        <i className="fa fa-refresh ml-2 text-primary" aria-hidden="true"></i>
                                    </span></th>
                                    <th>ID</th>
                                    <th>Added Date Time</th>
                                    <th>Case Name</th>
                                    <th>Version</th>
                                    <th>Tags</th>
                                    <th>Added By</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {wordTemplates.map(item =>
                                    <tr>
                                        <td>
                                            <div className="custom-control custom-radio">
                                                <input
                                                    className="custom-control-input"
                                                    type="radio"
                                                    id={item.id}
                                                    name="schedule"
                                                    onChange={() => handleSelectTemplate(item)}
                                                    value={selectedTempId}
                                                    checked={selectedTempId === item.id}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor={item.id}
                                                >
                                                </label>
                                                {/* {item.templateId} */}
                                            </div>
                                        </td>
                                        <td>{item.id}</td>
                                        <td>
                                            <Moment format="MMM Do YYYY, h:mm a">
                                                {item.createdDateTime}
                                            </Moment>
                                        </td>
                                        <td>{item.templateName}</td>
                                        <td>{item.version}</td>
                                        <td></td>
                                        <td>{item.templateType}</td>
                                        <td>
                                            {!item.isEdit && <span>Locked</span>}
                                            {item.isEdit && <button className="btn btn-sm btn-link"
                                                onClick={() => handleWordTemplateLock(item)}>Lock</button>}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    </div>
                    </div>
                    
                </Tab.Content>
            </Tab>
            <Tab eventKey="history" title="Version History" tabClassName="page-tab border border-left-0 border-top-0">
                <Tab.Content className="border border-top-0 p-3">
                    {selectedTempId === 0 && <div className="">
                        <h3 className="text-danger mt-1"> Alert : Please select the Report first</h3>
                    </div>}
                    {selectedTempId > 0 && <WordVersionHistory
                        selectedTemp={selectedTemp}
                        handleVersionViewTemp={handleVersionViewTemp}
                    />}

                </Tab.Content>
            </Tab>
            <Tab eventKey="add" title={`${selectedTempId > 0 ? "Edit" : "Add"} Case Report`} tabClassName="page-tab border border-left-0 border-right-0 border-top-0">
                <Tab.Content className="border border-top-0 p-3">
                    <AddWordTemplate
                        handleSubmit={handleSubmit}
                        selectedItem={selectedTemp}
                        verSelectedTemp={verSelectedTemp}
                        handleEditAppointment={handleEditTemp}
                        workflowTask={workflowTask}
                    ></AddWordTemplate>
                </Tab.Content>
            </Tab>
        </Tabs>

    </>
}

export default WordEditor;