import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useDispatch, useSelector } from "react-redux";
import { getAllMonths, getAllYears } from "../../../../../services/appService";
import { getMonthlyClientCandidateInvoices, loadMonthlyClientCandidateInvoices } from "../../../../store/reports";
import AddEditYearlyRpt from "../../../../../components/AddEditYearlyRpt";
import GeneratePdf from "../../../../../components/GeneratePdf";

const AgencyCandidateBased = () => {

    const years = getAllYears();
    const months = getAllMonths();

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [pdfShowHide, setPdfShowHide] = useState(false);
    const [rptShowHide, setRptShowHide] = useState(false);
    const [selectedCol, setSelectedCol] = useState([]);
    const [selectedColData, setSelectedColData] = useState([]);
    const [clientRpt, setClientRpt] = useState([]);
    const [rptSelectedObj, setRptSelectedObj] = useState(null);

    const monthlyClientCandidateInvoicesSelector =useSelector(getMonthlyClientCandidateInvoices);

    const dispatch=useDispatch();

    const agencyGenerateRpt = () => {
        if (year) dispatch(loadMonthlyClientCandidateInvoices(year));
    };

    useEffect(()=>{
        setClientRpt(monthlyClientCandidateInvoicesSelector)
    },[monthlyClientCandidateInvoicesSelector])

    const getClientRptTotal = () => {
        let prevDueT = 0;
        let yearT = 0;
        let paidT = 0;
        let monthT = months.map((m) => {
            m.invT = 0;
            m.recT = 0;
            return m;
        });

        clientRpt.map((item) => {
            console.log(item);
            prevDueT += item.previousDue;
            yearT += item.currentYearTotal;
            paidT += item.currentPaid;
            monthT.map((m) => {
                m.invT += parseFloat(item[m.name.toLocaleLowerCase()].invoiced);
                m.recT += parseFloat(item[m.name.toLocaleLowerCase()].received);
                return m;
            });
        });

        return (
            <tr className="bg-lighter">
                <td className="font-weight-bold text-right" colSpan={3}>
                    Total
                </td>
                <td className="font-weight-bold text-right">{parseFloat(prevDueT).toFixed(2)}</td>
                {monthT.map((m) => (
                    <td className="font-weight-bold text-right">
                        {parseFloat(m.invT).toFixed(2)} - {parseFloat(m.recT).toFixed(2)}
                    </td>
                ))}
                <td className="font-weight-bold text-right">{parseFloat(yearT).toFixed(2)}</td>
                <td className="font-weight-bold text-right">{parseFloat(paidT).toFixed(2)}</td>
                <td className="font-weight-bold text-right">{parseFloat(yearT - paidT).toFixed(2)}</td>
                <td></td>
            </tr>
        );
    };

    const agencyRptExportPdf = () => {
        const headers = [
            [
                "Candidate #",
                "Candidate Name",
                `${year - 1} Invoices Due in ${year}`,
            ],
        ];
        months.map((m) => {
            headers[0].push(`${m.name} (Invoiced - Received)`);
        });
        headers[0].push(`${year} Year Total`);
        headers[0].push(
            `${year} Invoices Paid in ${year}`
        );
        headers[0].push(
            `${year} Invoices Paid in ${year + 1
            }`
        );
        const colData = [];
        clientRpt.map((item) => {
            const dt = [];
            dt.push(
                item.candidateId,
                item.candidate,
                item.clientName,
                item.previousDue
            );

            months.map((m) =>
                dt.push(
                    `${item[m.name.toLocaleLowerCase()].invoiced} - ${item[m.name.toLocaleLowerCase()].received
                    }`
                )
            );
            dt.push(item.currentYearTotal);
            dt.push(item.currentPaid);
            dt.push(item.currentYearTotal - item.currentPaid);
            colData.push(dt);
        });

        setSelectedCol(headers);
        setSelectedColData(colData);
        setPdfShowHide(true);
    };

    const handlePreviousDue = ({ currentTarget }) => {
        const newClientReport = [...clientRpt];
        const id = currentTarget.name.split("_")[1];
        const index = newClientReport.findIndex((x) => x.candidateId == id);
        const currentObj = {...newClientReport[index]};
        currentObj.previousDue = parseFloat(currentTarget.value);
        if (currentTarget.value !== "") {
            currentObj.currentYearTotal = currentObj.yearTotal + currentObj.previousDue;
            currentObj.nextDue = currentObj.currentYearTotal - currentObj.currentPaid;
        }
        newClientReport[index] = currentObj;
        setClientRpt(newClientReport);
    };

    const handleCurrenYearPaid = ({ currentTarget }) => {
        const newClientReport = [...clientRpt];
        const id = currentTarget.name.split("_")[1];
        const index = newClientReport.findIndex((x) => x.candidateId == id);
        const currentObj = {...newClientReport[index]};
        currentObj.currentPaid = parseFloat(currentTarget.value);
        if (currentTarget.value !== "") {
            currentObj.currentDue = currentObj.currentYearTotal - currentObj.currentPaid;
            currentObj.nextDue = currentObj.currentDue;
        }
        newClientReport[index] = currentObj;
        setClientRpt(newClientReport);
    };

    const handleSaveCandidateRpt = async (item) => {
        setRptSelectedObj(item);
        setRptShowHide(true);
    };

    const handleRptShowHide = (type) => {
        setRptShowHide(false);
        setRptSelectedObj(null);
        if (type === "save")
            agencyGenerateRpt();
    };

    const handlepdfShowHide = () => {
        setPdfShowHide(false);
        setSelectedCol([]);
        setSelectedColData([]);
    };

    return <>
        <div className="card shadow-none border">
            <div className="card-header text-center p-3 bg-lighter">
                <h4 className="mb-0">
                    Invoices Raised to Clients - Candidate Basis
                    (Monthly)
                </h4>
            </div>
            <div className="card-body p-3">
                <div className="row">
                <div className="col-md-3">
                        <select
                            name="year"
                            id="year"
                            className="form-control form-control-sm"
                            onChange={({ currentTarget }) => setYear(parseInt(currentTarget.value))}
                            value={year}
                        >
                            <option value="">Year</option>
                            {years.map((y) => (
                                <option value={y.name}>
                                    {y.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button
                            className="btn btn-sm btn-outline-default"
                            disabled={!year}
                            onClick={agencyGenerateRpt}
                        >
                            Generate
                        </button>
                    </div>
                    <div className="col-md-6 text-right">
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-sm btn-outline-default"
                            table="mon_agency_candidate"
                            filename="Client_Invoice_Monthly"
                            sheet="Client_Invoice_Monthly"
                            buttonText="Export to Excel"
                        />
                        <button
                            type="button"
                            className="btn btn-sm btn-outline-default"
                            disabled={clientRpt.length === 0}
                            onClick={agencyRptExportPdf}
                        >
                            Generate PDF
                        </button>
                    </div>
                    <div className="col-md-12 mt-2">
                        <div className="table-responsive">
                            <table
                                className="table align-items-center table-flush table-hover table-striped mb-2 border"
                                id="mon_agency_candidate"
                            >
                                <thead className="thead-light">
                                    <th>Candidate #</th>
                                    <th>Candidate Name</th>
                                    <th>Agency / Client Name</th>
                                    <th>
                                        {year - 1} Invoices
                                        <br />
                                        Due in{" "}
                                        {year}
                                    </th>
                                    {months.map((m) => (
                                        <th>
                                            {m.name}
                                            <br />
                                            (Invoiced - Received)
                                        </th>
                                    ))}
                                    <th>
                                        {year} Year Total
                                    </th>
                                    <th>
                                        {year} Invoices
                                        <br />
                                        Paid in {year}
                                    </th>
                                    <th>
                                        {year} Invoices
                                        <br />
                                        Paid in {year + 1}
                                    </th>
                                    <th>Action</th>
                                </thead>
                                <tbody>
                                    {clientRpt.map((item) => (
                                        <tr>
                                            <td>{item.candidateId}</td>
                                            <td>{item.candidate}</td>
                                            <td>{item.clientName}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm text-right"
                                                    onChange={handlePreviousDue}
                                                    id={`prev_${item.candidateId}`}
                                                    name={`prev_${item.candidateId}`}
                                                    value={parseFloat(
                                                        item.previousDue
                                                    ).toFixed(2)}
                                                />
                                            </td>
                                            {months.map((m) => (
                                                <td className="text-right">
                                                    {`${parseFloat(
                                                        item[
                                                            m.name.toLocaleLowerCase()
                                                        ].invoiced
                                                    ).toFixed(
                                                        2
                                                    )} - ${parseFloat(
                                                        item[
                                                            m.name.toLocaleLowerCase()
                                                        ].received
                                                    ).toFixed(2)}`}
                                                </td>
                                            ))}
                                            <td className="text-right">
                                                {parseFloat(
                                                    item.currentYearTotal
                                                ).toFixed(2)}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm text-right"
                                                    onChange={handleCurrenYearPaid}
                                                    id={`prev_${item.candidateId}`}
                                                    name={`prev_${item.candidateId}`}
                                                    value={parseFloat(
                                                        item.currentPaid
                                                    ).toFixed(2)}
                                                />
                                            </td>
                                            <td className="text-right">
                                                {parseFloat(
                                                    item.currentYearTotal -
                                                    item.currentPaid
                                                ).toFixed(2)}
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() =>handleSaveCandidateRpt(item)}
                                                >
                                                    Save
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    {getClientRptTotal()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddEditYearlyRpt
          rptShowHide={rptShowHide}
          handleRptShowHide={handleRptShowHide}
          selectedObj={rptSelectedObj}
          year={year}
        ></AddEditYearlyRpt>
             <GeneratePdf
            handlepdfShowHide={handlepdfShowHide}
            pdfShowHide={pdfShowHide}
            selectedCol={selectedCol}
            selectedColData={selectedColData}
        ></GeneratePdf>
    </>
}

export default AgencyCandidateBased;