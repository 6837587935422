import React, { Component } from "react";
import LoginHeader from "./LoginHeader";
import LoginFooter from "./LoginFooter";
import { Link, Redirect } from "react-router-dom";
import LoginStep2 from "./LoginStep2";
import auth from "../../services/authService";
import http from "../../services/HttpService";
import config from "../../config.json";

class AdminLogin1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      userError: "",
    };
  }

  doSubmit = async (step2Data) => {
    const { data } = this.state;
    const mergedData = { ...data, ...step2Data };
    try {
      await auth.login(mergedData.username, mergedData.password, "admin", 0);
      localStorage.setItem("userType", data["userType"]);
      if (this.props.location.state !== undefined) {
        window.location = this.props.location.state.from.pathname;
      } else window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        this.setState({ userError: ex.response.data.message });
      }
    }
  };

  render() {
    const { data, userError } = this.state;
    return (
      <React.Fragment>
        <LoginHeader></LoginHeader>
        <div className="col-md-12">
          <div className="row d-flex justify-content-center">
            <div className="col-md-6">
              <div
                className="card shadow-none border rounded-0"
                style={{
                  backgroundColor: "#fafafa",
                  marginTop: "30px",
                }}
              >
                <div className="card-body">
                  <div className="text-center text-white mb-2">
                    <h1 className="text-black">SIGN IN</h1>
                  </div>
                  <div>
                    <Link className="btn btn-link btn-sm" to="/AdminLogin">
                      <i
                        className="fa fa-long-arrow-left"
                        aria-hidden="true"
                      ></i>
                      Back
                    </Link>
                  </div>
                  <div>
                    <label className="form-control-label">
                      User Type : {data["userType"]}, Valid ID :{" "}
                      {data["partnerId"]}
                    </label>
                  </div>
                  <LoginStep2
                    handleDoSubmit={this.doSubmit}
                    userError={userError}
                    type="admin"
                  ></LoginStep2>
                  <div className="mt-2">
                    <Link
                      to="/ForgotPassword"
                      className="btn btn-link btn-sm"
                      style={{ float: "right" }}
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card shadow-none border">
                <div className="card-body p-2 text-center">
                  <p>
                    Technical Support :{" "}
                    <a href="mailto:Support1@workfinity.com">
                      Support1@workfinity.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginFooter></LoginFooter>
      </React.Fragment>
    );
  }
}

export default AdminLogin1;
