import React, { Component, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "../comman/form";
import config from "../config.json";
import http from "../services/HttpService";
import Swal from "sweetalert2";
import SignaturePad from "react-signature-canvas";
import Moment from "react-moment";
import auth from "../services/authService";
import UploadDocument from "../comman/UploadDocument";
import Joi from "joi-browser";
import publicIp from "react-public-ip"
import { useState } from "react";
import { getCurrentUser } from "../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getFieldData } from "../services/appService";
import { addNewPurchaseOrder, generatedPoOrderPdfReceived, getPOAddedStatus, getPoOrdersPdf,
   getPOUploadedStatus, printGeneratedPoOrders, uploadNewPurchaseOrder } from "../app/store/purchaseOrder";

const PrintPurchaseOrder = (props) => {
  const {
    genShowHide,
    handleGenShowHide,
    candidateObj,
    workflowTask,
    poAction,
    allPoTypes,
  } = props;

  const [ipAddress, setIpAddress] = useState("");
  const [esignName, setEsignName] = useState("");
  const [poType, setPoType] = useState("");
  const [otherNotes, setOtherNotes] = useState("");
  const [poId, setPoId] = useState(0);
  const [partnerName, setPartnerName] = useState("");
  const [partAddress, setPartAddress] = useState("");
  const [partContact, setPartContact] = useState("");
  const [partEmail, setPartEmail] = useState("");
  const [payRate, setPayRate] = useState(0);
  const [strDate, setStrDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [keyName, setKeyName] = useState("");
  const [partTitle, setPartTitle] = useState("Key Contact");
  const [ssn, setSsn] = useState("");
  const [documents, setDocuments] = useState([]);
  const [pdfBtnProccess, setPdfBtnProccess] = useState(false);
  const [saveBtnProccess, setSaveBtnProccess] = useState(false);
  const [updBtnProccess, setUpdBtnProccess] = useState(false);

  const pOAddedStatusSelector = useSelector(getPOAddedStatus);
  const poOrdersPdfSelector = useSelector(getPoOrdersPdf);
  const pOUploadedStatusSelector = useSelector(getPOUploadedStatus);

  const printBodyRef = useRef();
  const currentUser = getCurrentUser();
  const dispatch = useDispatch();

  useEffect(()=>{
    if(!genShowHide){
      setEsignName("");
      setPoType("");
      setOtherNotes("");
      setDocuments("");
    }
  },[genShowHide])

  useEffect(() => {
    const loadIpAddress = async () => {
      const ipv4 = await publicIp.v4() || "";
      setIpAddress(ipv4);

    }
    loadIpAddress();
  }, []);

  useEffect(() => {
    if (candidateObj && poType) {
      if (candidateObj?.duration) {
        setStrDate(new Date(candidateObj.duration?.startDate)?.toLocaleDateString());
        setEndDate(new Date(candidateObj.duration?.endDate)?.toLocaleDateString());
      }
      let ddName = "";
      if (poType === "subcontractor") {
        ddName = "subcontractorenrollmentform";
        setPoId(candidateObj.subcontractor?.fuid);
        setPayRate(candidateObj.rates?.subcontractorRate)
      }
      if (poType === "recruiter") {
        ddName = "recruiterenrollmentform";
        setPoId(candidateObj.recruiter?.fuid);
        setPayRate(candidateObj.rates?.recruiterRate)
      }

      if (poType === "recruiter" || poType === "subcontractor") {
        setKeyName(getKeyName(candidateObj.performerData, ddName));
        setPartnerName(getFieldData(candidateObj.performerData, ddName, "Company_Name"))
        setPartTitle(getFieldData(candidateObj.performerData, ddName, "Key_Contact1_Title"));
        setPartContact(getFieldData(candidateObj.performerData, ddName, "Company_Phone"));
        setPartEmail(getFieldData(candidateObj.performerData, ddName, "Company_Email"));
        setPartAddress(getPartnerAddress(candidateObj.performerData, ddName));
      }

      if (poType === "candidate") {
        ddName = "candidateonboardingform";
        setPoId(candidateObj.performerid);
        setPayRate(candidateObj.rates?.candidateRate);
        const candName = candidateObj.performername + " " + candidateObj.lastName;
        setPartnerName(candName);
        setKeyName(candName);
        setPartTitle("Candidate");
        setPartContact(getFieldData(candidateObj.performerData, ddName, "Mobile_No"));
        setPartEmail(getFieldData(candidateObj.performerData, ddName, "Email"));
        setPartAddress(getCandidateAddress(candidateObj.performerData,ddName));
        let sn = getFieldData(candidateObj.performerData, ddName, "Social_Security_No");
        if (sn !== "") sn = sn.substring(sn.length - 4);
        setSsn(sn)
      }
    }
  }, [candidateObj,poType]);

  const uploadPoOrder = () => {
    setUpdBtnProccess(true);
    let subconId = 0;
    let recId = 0;
    if (poType === "subcontractor")
      subconId = candidateObj.subcontractor.fuid;
    if (poType === "recruiter") recId = candidateObj.recruiter.fuid;
    const poObject = {
      type: poType,
      candidateId: candidateObj.performerid,
      subcontractorId: subconId,
      recruiterId: recId,
      ipcsSignBy: currentUser.id,
      ciid: workflowTask.ciid,
      notes: otherNotes,
    };
    const frmData = new FormData();
    documents.map((file, key) =>
      frmData.append("files[" + key + "]", file.file)
    );
    frmData.append("purchaseOrder", JSON.stringify(poObject));
    dispatch(uploadNewPurchaseOrder(frmData));
  };

  useEffect(()=>{
    if(pOUploadedStatusSelector){
      setUpdBtnProccess(false);
      handleGenShowHide("save", "");
    }
  },[pOUploadedStatusSelector])

  const doSubmit = () => {
    setSaveBtnProccess(true);
    let subconId = 0;
    let recId = 0;
    if (poType === "subcontractor")
      subconId = candidateObj.subcontractor.fuid;
    if (poType === "recruiter") recId = candidateObj.recruiter.fuid;

    const poObject = {
      type: poType,
      candidateId: candidateObj.performerid,
      subcontractorId: subconId,
      recruiterId: recId,
      ipcsSignBy: currentUser.id,
      ciid: workflowTask.ciid,
      notes: otherNotes,
      ipcsSignerName: esignName,
      ipcsIpAddress: ipAddress,
    };
    const logImg = getEmergencyFoundImg();
    const frmData = new FormData();
    let htmlBody =
      "<div style='text-align:center;padding-bottom:10px'>" +
      logImg.outerHTML +
      "</img></div>" + printBodyRef.current.innerHTML;
    frmData.append("html", htmlBody);
    frmData.append("purchaseOrder", JSON.stringify(poObject));
    dispatch(addNewPurchaseOrder(frmData));
  };

  useEffect(()=>{
    if(pOAddedStatusSelector){
      setSaveBtnProccess(false);
      handleGenShowHide("save", "");
    }
  },[pOAddedStatusSelector])

  const handlePrintPdf = () => {
    setPdfBtnProccess(true);
    const frmData = new FormData();
    const logImg = getEmergencyFoundImg();
    let htmlBody =
      "<div style='text-align:center;padding-bottom:10px'>" +
      logImg.outerHTML +
      "</img></div>" + printBodyRef.current.innerHTML;
    frmData.append("html", htmlBody);
    dispatch(printGeneratedPoOrders(frmData));
  };

  useEffect(() => {
    if (poOrdersPdfSelector?.size > 0) {
      const url = window.URL.createObjectURL(poOrdersPdfSelector);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      dispatch(generatedPoOrderPdfReceived(null));
      setPdfBtnProccess(false);
    }
  }, [poOrdersPdfSelector])

  const getEmergencyFoundImg = () => {
    var c = document.createElement("canvas");
    var img = document.getElementById("Img1");
    c.height = img.naturalHeight;
    c.width = img.naturalWidth;
    var ctx = c.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var base64String = c.toDataURL();
    let img2 = document.createElement("img");
    img2.height = img.naturalHeight;
    img2.width = img.naturalWidth;
    img2.src = base64String;
    return img2;
  };

  const getPartnerAddress = (data, type) => {
    const add1 = getFieldData(data, type, "Company_Address_Line1");
    const add2 = getFieldData(data, type, "Company_Address_Line2");
    const state = getFieldData(data, type, "Company_State");
    const city = getFieldData(data, type, "Company_City");
    const zip = getFieldData(data, type, "Company_Zip_Code");
    let partAddress = "";
    if (add1 !== "") partAddress += add1 + ", ";
    if (add2 !== "") partAddress += add2 + ", ";
    if (state !== "") partAddress += state + ", ";
    if (city !== "") partAddress += city + ", ";
    if (zip !== "") partAddress += zip;

    return partAddress;
  };

  const getCandidateAddress = (data, type) => {
    const add1 = getFieldData(data, type, "Current_Address_Line1");
    const add2 = getFieldData(data, type, "Current_Address_Line2");
    const state = getFieldData(data, type, "Current_State");
    const city = getFieldData(data, type, "Current_City");
    const zip = getFieldData(data, type, "Current_Zip_Code");
    let partAddress = "";
    if (add1 !== "") partAddress += add1 + ", ";
    if (add2 !== "") partAddress += add2 + ", ";
    if (state !== "") partAddress += state + ", ";
    if (city !== "") partAddress += city + ", ";
    if (zip !== "") partAddress += zip;

    return partAddress;
  };

  const getKeyName = (data, type) => {
    const first = getFieldData(data, type, "Key_Contact1_First_Name");
    const middle = getFieldData(data, type, "Key_Contact1_Middle_Name");
    const last = getFieldData(data, type, "Key_Contact1_Last_Name");
    const name = first + " " + middle + " " + last;
    return name;
  };

  const onFileChange = (event) => {
    const docs = [];
    for (const file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        file: file,
        size: file.size,
      };
      docs.push(doc);
    }
    setDocuments(docs);
  };

  const handleFileRemove = (item) => {
    const index = this.state.documents.indexOf(item);
    let documents = [...this.state.documents];
    if (index !== -1) {
      documents.splice(index, 1);
      this.setState({ documents });
    }
  };

  const borderCss = {
    border: "1px solid #ccc",
    borderTop: "0px",
    borderRight: "0px",
    backgroundColor: "#f0f0f0",
    width: "30%",
    //fontSize: "11px",
  };

  const borderCss1 = {
    border: "1px solid #ccc",
    borderTop: "0px",
    borderRight: "0px",
    //fontSize: "11px",
  };

  return (
    <Modal show={genShowHide} size="xl">
      <Modal.Header closeButton onHide={() => handleGenShowHide("")}>
        <Modal.Title>
          {poAction === "generate" && "Add Purchase Order"}
          {poAction === "upload" && "Upload Purchase Order"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 form-group">
            <label htmlFor="poType" className="form-control-label">Purchase Order For</label>
            <select name="poType" id="poType"
              className="form-control"
              value={poType}
              onChange={({ currentTarget }) => setPoType(currentTarget.value)}
            >
              <option value="">Select</option>
              {allPoTypes.map(p =>
                <option value={p.value}>{p.name}</option>
              )}
            </select>
          </div>
        </div>
        {poAction === "upload" && (
          <div className="row">
            <div className="col-md-12">
              <UploadDocument
                documents={documents}
                onFileChange={onFileChange}
                handleFileRemove={handleFileRemove}
              ></UploadDocument>
            </div>
          </div>
        )}
        {poType !== "" && poAction !== "upload" && (
          <React.Fragment>
            <img id="Img1" src={`/assets/Img/logo.png`} />
            <div id="printBody" ref={printBodyRef}>
              <table
                width="100%"
                cellPadding="3"
                cellSpacing="0"
                style={{
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "5px",
                  fontSize: "13px"
                }}
              >
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <p>
                      METROPOINT, Suite 1595, 600 South Highway 169,
                      Minneapolis, MN 55426
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <p>
                      Tel: 952-541-4888 Fax: 952-541-1222 Email:
                      support@ipcs.net
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <p>Web sites: www.ipcs.net www.workfinity.com</p>
                  </td>
                </tr>
              </table>
              <table width="100%" cellPadding="5" style={{ fontSize: "13px" }}>
                <tr>
                  <td>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      PURCHASE ORDER # {poId}
                    </p>
                    This Purchase Order is issued pursuant to the Consulting
                    Services Agreement signed by IPCS and the{" "}
                    <span style={{ textTransform: "uppercase" }}>{poType}</span>.
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    Date: {new Date().toLocaleDateString()}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <p>Issued To : {partnerName}</p>
                    <p>{partAddress}</p>
                    <p>
                      Phone: {partContact} Email: {partEmail}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        textDecoration: "underline",
                      }}
                    >
                      Consultant Engagement Details
                    </p>
                    <p>
                      IPCS is engaging the following Consultant to work on
                      IPCS's Project
                    </p>
                  </td>
                </tr>
              </table>
              <table
                style={{
                  border: "1px solid #ccc",
                  borderLeft: "0",
                  borderBottom: "0",
                  fontSize: "13px"
                }}
                width="100%"
                cellPadding="8"
                cellSpacing="0"
              >
                <tr>
                  <td style={borderCss}>Candidate Name</td>
                  <td style={borderCss1}>
                    {candidateObj.performername + " " + candidateObj.lastName}
                  </td>
                </tr>
                <tr>
                  <td style={borderCss}>Last 4 digits of SSN</td>
                  <td style={borderCss1}>{ssn}</td>
                </tr>
                <tr>
                  <td style={borderCss}>IPCS Client</td>
                  <td style={borderCss1}>
                    State :{" "}
                    {getFieldData(
                      candidateObj.performerData,
                      "projectengagement",
                      "State"
                    )}
                    , Agency :{" "}
                    {getFieldData(
                      candidateObj.performerData,
                      "projectengagement",
                      "AgencyName"
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={borderCss}>Responsibilities/Role</td>
                  <td style={borderCss1}>
                    {getFieldData(
                      candidateObj.performerData,
                      "projectengagement",
                      "PositionTitle"
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={borderCss}>Project Location</td>
                  <td style={borderCss1}>
                    {getFieldData(
                      candidateObj.performerData,
                      "projectengagement",
                      "PositionLocation"
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={borderCss}>
                    Start Date / Duration (From - To)
                  </td>
                  <td style={borderCss1}>
                    {strDate} / ({strDate} - {endDate})
                  </td>
                </tr>
                <tr>
                  <td style={borderCss}>Rate payable by IPCS</td>
                  <td style={borderCss1}>${payRate}/hr. (all inclusive)</td>
                </tr>
                <tr>
                  <td style={borderCss}>Background Check</td>
                  <td style={borderCss1}>
                    Background Check and Drug Screen to be completed I-9,
                    e-verify to be provided
                  </td>
                </tr>
                <tr>
                  <td style={borderCss}>Other Notes</td>
                  <td style={borderCss1}>{otherNotes}</td>
                </tr>
              </table>
              <table width="100%" style={{ marginTop: "20px", fontSize: "13px" }}>
                <tr>
                  <td>
                    All other Terms and Conditions as per the Consulting
                    Services Agreement between IPCS and the CONSULTANT will
                    remain the same.
                  </td>
                </tr>
              </table>
              <table width="100%" style={{ marginTop: "20px", fontSize: "13px" }}>
                <tr>
                  <td width="50%">
                    <p>International Projects Consultancy</p>
                    <p>Services (IPCS) Inc.</p>
                  </td>
                  <td valign="top">
                    <p>{partnerName}</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: "7px" }}>
                    <p style={{
                      fontStyle: "italic", fontWeight: "bold",
                      fontSize: "14px", marginBottom: "7px"
                    }}>{esignName}</p>
                    <p>{new Date().toUTCString()}</p>
                    <p>IP Address : {ipAddress}</p>
                  </td>
                  <td style={{ paddingTop: "7px" }}>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: "7px" }}>
                    <p>Authorized Signature</p>
                    <p>Name : Kuldeep Dhar</p>
                    <p>Title : Sr. Vice President</p>
                  </td>
                  <td style={{ paddingTop: "7px" }}>
                    <p>Authorized Signature</p>
                    <p>Name : {keyName}</p>
                    <p>Title : {partTitle}</p>
                  </td>
                </tr>
              </table>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <label htmlFor="esignName" className="form-control-label">Sign Digitally</label>
                <textarea cols="2" rows={2}
                  id="esignName"
                  name="esignName"
                  className="form-control"
                  value={esignName}
                  placeholder="Sign Digitally"
                  onChange={({ currentTarget }) => setEsignName(currentTarget.value)}
                ></textarea>
              </div>
              <div className="col-md-6">
                <label htmlFor="otherNotes" className="form-control-label">Enter Other Notes</label>
                <textarea cols="2" rows={2}
                  id="otherNotes"
                  name="otherNotes"
                  className="form-control"
                  value={otherNotes}
                  placeholder="Enter Other Notes"
                  onChange={({ currentTarget }) => setOtherNotes(currentTarget.value)}
                ></textarea>
              </div>
            </div>
          </React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-danger btn-sm" onClick={() => handleGenShowHide("")}>
          <i className="fa fa-times"></i> Close
        </button>

        {poAction === "generate" && (
          <button
            className="btn btn-outline-default btn-sm"
            disabled={saveBtnProccess}
            onClick={doSubmit}
          >
            {!saveBtnProccess && <i className="fa fa-cog mr-1"></i>}
            {saveBtnProccess && (
              <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
            )}
            Save/Generate
          </button>
        )}
        {poAction !== "upload" && (
          <button
            className="btn btn-outline-default btn-sm"
            disabled={pdfBtnProccess}
            onClick={handlePrintPdf}
          >
            {!pdfBtnProccess && <i className="fa fa-print mr-1"></i>}
            {pdfBtnProccess && (
              <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
            )}
            Print PDF
          </button>
        )}

        {poAction === "upload" && (
          <button
            className="btn btn-outline-default btn-sm"
            disabled={updBtnProccess}
            onClick={uploadPoOrder}
          >
            {!updBtnProccess && <i className="fa fa-save mr-1"></i>}
            {updBtnProccess && (
              <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
            )}
            Save
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default PrintPurchaseOrder;
