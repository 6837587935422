import React from "react";
import { useState } from "react";
import UploadDocument from "../../../comman/UploadDocument";

const UploadLocal = (props) => {

    const { setLocalDocs } = props;

    const [updDocs, setUpdDocs] = useState([]);

    const onFileChange = async (event) => {
        const docs = [...updDocs];
        const frmData = new FormData();
        let key = 0;
        for (const file of event.target.files) {
            var doc = {
                name: file.name,
                type: file.type,
                file: file,
                size: file.size,
            };
            docs.push(doc);
            key += 1;
            frmData.append("files[" + key + "]", file);
        }
        setUpdDocs(docs);
        setLocalDocs(docs);
        // const { data: result } = await http.post(config.ScanDocumentVirus, frmData);
        // //console.log(result);
        // if (result === "NoVirus") {
        //     this.setState({ documents: docs });
        // } else {
        //     this.getAlert("Malware Detected", "File cannot be uploaded", "error");
        // }
    };

    const handleFileRemove = (item) => {
        const index = updDocs.indexOf(item);
        let documents = [...updDocs];
        if (index !== -1) {
            documents.splice(index, 1);
            setUpdDocs(documents);
            setLocalDocs(documents);
        }
    };

    return <>
        <UploadDocument
            documents={updDocs}
            onFileChange={onFileChange}
            handleFileRemove={handleFileRemove}
        ></UploadDocument>
    </>
}

export default UploadLocal;