import React, { Component } from "react";
import { Link } from "react-router-dom";
import auth from "../services/authService";
import http from "../services/HttpService";
import config from "../config.json";
import ChangePassword from "./ChangePassword";
import PartnerDashbaord from "./PartnerManage/PartnerDashbaord";
import InfoBox from "../comman/InfoBox";

class DashboardPageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      application: 50,
      users: 53,
      documents: 63,
      wPhaseclosed: 36,
      wPhaseApplication: 32,
      wPhaseReview: 56,
      wPhaseApproved: 96,
      wPhasePending: 65,
      wPhaseWithdrawn: 69,
      latestApplication: [],
      user: {},
      showHide: false,
      userApp: {},
    };
  }

  async componentDidMount() {
    const user = auth.getCurrentUser();
    // const { data: userApp } = await http.get(
    //   config.GetUserApplication + user.id
    // );
    // //console.log(userApp);
    this.setState({ user });
  }

  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  getNumberUnit = (labelValue) => {
    const sign = Math.sign(Number(labelValue));
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? sign * (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? sign * (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? sign * (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
      : Math.abs(Number(labelValue));
  };

  render() {
    const { user, showHide, userApp } = this.state;
    const { dashboardData } = this.props;
    return (
      <div
        className="card card-body shadow-none mb-0 pb-0 rounded-0 mt--3"
        style={{ backgroundColor: "#f0f0f0" }}
      >
        {user.type === "admin" || user.type === "workflow" ? (
          <div className="row">
            <InfoBox
              name="Candidates - Enrolled"
              count={dashboardData.allCandidate}
              otherInfo={dashboardData.allCandidateTypes}
            ></InfoBox>
            <InfoBox
              name="Candidates - Activated/Billable"
              count={dashboardData.activeCandidate}
              otherInfo={dashboardData.activeCandidateTypes}
            ></InfoBox>
            <InfoBox
              name="Candidate - Deactivated"
              count={dashboardData.inActiveCandidate}
              otherInfo={dashboardData.inActiveCandidateTypes}
            ></InfoBox>
            <InfoBox
              name="Subcontractors - Enrolled"
              count={dashboardData.allSubcontractors}
            ></InfoBox>
            <InfoBox
              name="Recruiters"
              count={dashboardData.allRecruiters}
              otherInfo={[
                { name: "Engaged", count: dashboardData.recruiterUsers },
                {
                  name: "Not Engaged",
                  count:
                    dashboardData.allRecruiters - dashboardData.recruiterUsers,
                },
              ]}
            ></InfoBox>
            <InfoBox
              name="Users"
              count={dashboardData.users}
              otherInfo={[
                { name: "IPCS", count: dashboardData.ipcsUsers },
                {
                  name: "Subcontractor",
                  count: dashboardData.subcontractorUsers,
                },
                {
                  name: "Recruiter",
                  count: dashboardData.recruiterUsers,
                },
                {
                  name: "Candidate",
                  count: dashboardData.activeCandidate,
                },
              ]}
            ></InfoBox>
            <InfoBox
              name="Concurrent Users"
              count={dashboardData.loggedUser}
              otherInfo={[
                { name: "IPCS", count: dashboardData.ipcsLoggedUsers },
                {
                  name: "Subcontractor",
                  count: dashboardData.subcontractorLoggedUsers,
                },
                {
                  name: "Recruiter",
                  count: dashboardData.recruiterLoggedUsers,
                },
                {
                  name: "Candidate",
                  count: dashboardData.loggedCandidate,
                },
              ]}
            ></InfoBox>
          </div>
        ) : (
          <PartnerDashbaord showHide={true}></PartnerDashbaord>
        )}

        <ChangePassword
          handleModalShowHide={this.handleModalShowHide}
          showHide={showHide}
        ></ChangePassword>
      </div>
    );
  }
}

export default DashboardPageHeader;
