import React, { Component, useEffect } from "react";
import Form from "../comman/form";
import auth from "../services/authService";
import geo from "../services/geoService";

class MyProfileDetails extends Form {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      currentUser: {},
      stateList: [],
      countyList: [],
      selectedStateId: "",
      selectedState: "",
      selectedCountyId: "",
      selectedCounty: "",
      data: {},
      errors: {},
    };
  }

  schema = {};

  async componentDidMount() {
    //const user = auth.getCurrentUser();
    //const stateList = await geo.getStateList();
    //this.setState({ stateList });
  }

  async componentWillReceiveProps(nextProps) {
    const { userDetails } = nextProps;
    //console.log(userDetails);
    if (userDetails && userDetails !== "") {
      const user = auth.getCurrentUser();
      const stateList = await geo.getStateList();
      let userName = userDetails.userName;
      let email = userDetails.performeremail;
      let name = userDetails.performername;
      let id = userDetails.performerid;
      let contactNum = userDetails.contactNo;
      let lastname = userDetails.lastName;
      let zipCode = userDetails.zipCode;
      // if (user.type === "admin") {
      //   userName = userDetails.uid;
      //   email = userDetails.email;
      //   name = userDetails.name;
      //   id = userDetails.id;
      //   contactNum = userDetails.statusComment;
      // }

      const obj = {
        performerid: id,
        performername: name,
        performeremail: email,
        userName: userName,
        contactNo: contactNum,
        location: userDetails.location,
        state: userDetails.state,
        county: userDetails.county,
        lastName: lastname,
        zipCode: zipCode,
        // zip: userDetails?.performerData
        //   ? this.getFieldData(
        //       userDetails.performerData,
        //       "applicantregistration",
        //       "Zip"
        //     )
        //   : "",
      };
      //console.log(userDetails);
      this.setState({ data: obj, user, stateList });
    }
  }

  doSubmit = () => {
    const { data } = this.state;
    this.props.handleSubmit(data);
    this.props.setProfileEdit(false)
  };

  render() {
    const { stateList } = this.state;
    const { profileEdit } = this.props;
    return (
      <React.Fragment>
        <fieldset disabled={!profileEdit}>
          <div className="row">
            <div className="col-lg-6">
              {this.renderInput("performername", "First Name")}
            </div>
            <div className="col-lg-6">
              {this.renderInput("lastName", "Last Name")}
            </div>
            <div className="col-lg-6">
              {this.renderInput("contactNo", "Phone No.", "phone")}
            </div>
            <div className="col-lg-6">
              {this.renderInput("performeremail", "Email")}
            </div>
            <div className="col-lg-12 form-group">
            <label className="form-control-label mr-2">
                Choose a way to verify
              </label>
              (<span className="text-red">You'll need to confirm that it's yours. 
                Select how you'd like to get a verification code.</span>)
                <div className="d-flex">
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id="verifyByEmail"
                  type="checkbox"
                />
                <label className="custom-control-label mr-3" htmlFor="verifyByEmail">
                  Email
                </label>
              </div>
              <div className="custom-control custom-checkbox">
              <input
                  className="custom-control-input"
                  id="verifyByPhone"
                  type="checkbox"
                />
                <label className="custom-control-label" htmlFor="verifyByPhone">
                Phone No.
                </label>
              </div></div>
            </div>
            {/* <div className="col-lg-6">
              <fieldset disabled={true}>
                {this.renderInput("userName", "Username")}
              </fieldset>
            </div> */}
            <div className="col-md-12">
              {this.renderInput("location", "Address Line 1")}
            </div>
            <div className="col-md-4">
              {this.renderSelect(
                "state",
                "State",
                stateList,
                "name",
                "name"
              )}
            </div>
            <div className="col-md-4">
              {this.renderInput("county", "City")}
            </div>
            <div className="col-md-4">
              {this.renderInput("zipCode", "Zip Code")}
            </div>
            <div className="col-md-12 text-center form-group">
              {profileEdit && (
                <button
                  type="button"
                  className="btn btn-sm btn-outline-default"
                  onClick={this.handleSubmit}
                >
                  <i className="fa fa-pencil" aria-hidden="true"></i> Update
                </button>
              )}
            </div>
          </div>
        </fieldset>

      </React.Fragment>
    );
  }
}

export default MyProfileDetails;
