import React, { Component, useDebugValue } from "react";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import http from "../services/HttpService";
import config from "../config.json";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";
import SSN_textbox from "../BprmControls/SSN_textbox";
import Phone_textbox from "../BprmControls/Phone_textbox";
import Other_textbox from "../BprmControls/Other_textbox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Date_textbox from "../BprmControls/Date_textbox";
class BprmForm extends Component {
  state = {
    data: {},
    errors: {},
    wfForm: {},
    encryptionDecryptions: {},
    rules: [],
    workItem: {},
    isDisabled: false,
    stateList: [],
    stateCountyCity: [],
    documents: [],
    ddTypeCodMngr: [],
    subformItemIndex: -1,
    dropdownShowHide: false,
    dropdwonTitle: "",
    selectedDropdwonData: [],
    codeIsVerified: false,
    btnProcessStatus:false
  };

  handeOnChange = ({ currentTarget: input }) => {
    //console.log(input);
    this.handleChange(input);
    if (input.dataset.encryption !== undefined && input.dataset.encryption) {
      this.addEncryptionFieldData(input.name, input.value);
    }
  };

  handeSsnPhoneOnChange = ({ currentTarget: input }) => {
    input.value = input.value.replace(/[^\d]/g, "");
    this.handleChange(input);
  };

  handleChange = (input) => {
    const { data, errors } = this.state;
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    data[input.name] = input.value;
    this.setState({ data, errors });
    //this.getParameterCaseInsensitive(data, input.name)
  };

  onFileChange = (event) => {
    const docs = [...this.state.documents];
    const { data } = this.state;
    const docIndex = docs.findIndex((x) => x.refId === event.target.name);
    if (docIndex > -1) {
      docs.splice(docIndex, 1);
    }
    let indx = 0;
    if (docs.length > 0) {
      indx = docs[docs.length - 1].id;
    }
    for (const file of event.target.files) {
      indx += 1;
      const ext = file.name.split(".");
      var doc = {
        id: indx,
        name: file.name,
        newName: file.name,
        type: file.type,
        file: file,
        refId: event.target.name,
        extension: ext.length > 1 ? ext[1] : "",
      };
      docs.push(doc);
    }
    console.log(docs);
    // event.target.value = event.target.files[0].name;
    data[event.target.id] = event.target.files[0].name;
    this.validateDocuments(docs);
    this.setState({ documents: docs, data });
  };

  validateDocuments = (documents) => {
    const { data, errors } = this.state;
    if (documents.length > 0) {
      data["docs"] = documents.length.toString();
      delete errors["docs"];
    } else {
      const obj = { schedule: "" };
      const schema = { schedule: this.schema["docs"] };
      const { error } = Joi.validate(obj, schema);
      errors["docs"] = error.details[0].message;
      data["docs"] = "";
    }
    this.setState({ data, errors });
  };

  handleCheckBoxChecked = ({ target: input }) => {
    if (!input.checked) {
      input.value = "";
    }
    //console.log(input);
    this.handleChange(input);
  };

  getParameterCaseInsensitive(object, name) {
    return Object.keys(object).find(function (key) {
      if (key.toLowerCase() === name.toLowerCase()) return key;
    });
  }

  validate = () => {
    const { workItem } = this.state;
    //console.log(workItem);
    if (workItem === undefined) return true;
    if (workItem.status) return true;
    const options = { abortEarly: false, allowUnknown: true };
    const result = Joi.validate(this.state.data, this.schema, options);
    //console.log(result);
    if (!result.error) return null;
    const errors = {};
    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    if (this.schema[name] != undefined) {
      const obj = { [name]: value };
      const schema = { [name]: this.schema[name] };
      const { error } = Joi.validate(obj, schema);
      return error ? error.details[0].message : null;
    } else {
      return null;
    }
  };

  handleEncryptionDecryption = (name) => {
    const { data, encryptionDecryptions } = this.state;
    if (encryptionDecryptions[name + "encrypt"]) {
      const actualValue = encryptionDecryptions[name];
      const encryptedValue = data[name];
      //console.log(encryptedValue);
      //console.log(actualValue);
      // data[name] = encryptionDecryptions[name];
      // encryptionDecryptions[name] = "";
      encryptionDecryptions[name] = encryptedValue;
      data[name] = actualValue;
      encryptionDecryptions[name + "encrypt"] = false;
    } else {
      const encryptedValue = encryptionDecryptions[name];
      const actualValue = data[name];
      // console.log(encryptedValue);
      // console.log(actualValue);
      // let encrypValue = "";
      // let dataLength = data[name] !== undefined ? data[name].length : 0;
      // for (let i = 0; i < dataLength; i++) {
      //   encrypValue += "*";
      // }
      // encryptionDecryptions[name] = data[name];
      // data[name] = encrypValue;
      encryptionDecryptions[name] = actualValue;
      data[name] = encryptedValue;
      encryptionDecryptions[name + "encrypt"] = true;
    }
    this.setState({ data, encryptionDecryptions });
  };

  addEncryptionFieldData = (name, value) => {
    const encryp = this.state.encryptionDecryptions;
    const data = this.state.data;
    const newValue = value.replace(/\-/g, "");

    let modifiedValue = "";
    for (let i = 0; i < newValue.length; i++) {
      modifiedValue += "*";
    }
    if (encryp[name + "encrypt"]) {
      data[name] = modifiedValue;
      encryp[name] = encryp[name] + value.replace(/[^\d]/g, "");
    } else {
      data[name] = value.replace(/[^\d]/g, "");
      encryp[name] = modifiedValue;
    }
    this.setState({ encryptionDecryptions: encryp, data });
  };

  handleSubformModalShowHide = (name) => {
    //console.log(name);
    const modelId = name.split("-");
    //console.log(modelId);
    if (modelId.length > 1)
      this.setState({
        [modelId[0]]: !this.state[modelId[0]],
        subformItemIndex: modelId[1],
      });
    else this.setState({ [name]: !this.state[name], subformItemIndex: -1 });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(e.currentTarget.dataset.process);
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit(e.currentTarget);
  };

  getEvaluatedValue = async (comp) => {
    //console.log(comp);
    const ruleId = comp.rule;
    const { aWorkfinityAppEntity, data, aWorkfinityApp } = this.state;
    //console.log(data);
    Object.keys(data).forEach(function (keyValue) {
      const dd = keyValue.split(".");
      if (dd.length > 1) {
        if (dd[1] !== "undefined" && aWorkfinityAppEntity !== undefined)
          if (aWorkfinityAppEntity[dd[0].toLowerCase()] !== undefined) {
            var item = data[keyValue];
            if (Array.isArray(item))
              aWorkfinityAppEntity[dd[0].toLowerCase()][dd[1]] = item;
            else aWorkfinityAppEntity[dd[0].toLowerCase()]["@" + dd[1]] = item;
          }
      }
    });
    const appShortName = Object.keys(aWorkfinityApp)[1];
    aWorkfinityApp[appShortName] = aWorkfinityAppEntity;

    const formData = new FormData();
    formData.append("appEntity", JSON.stringify(aWorkfinityApp));
    formData.append("appId", 1);
    formData.append("ruleId", ruleId);
    const { data: newAWorkfinityApp } = await http.post(
      config.ExcuteFormRule,
      formData
    );
    const newAWorkfinityAppEntity = newAWorkfinityApp[appShortName];
    if (newAWorkfinityAppEntity !== "") {
      Object.keys(newAWorkfinityAppEntity).forEach(function (keyValue) {
        if (
          keyValue !== "@xmlns:xsd" &&
          keyValue !== "@xmlns:xsi" &&
          keyValue !== "EntityId"
        ) {
          Object.keys(newAWorkfinityAppEntity[keyValue]).forEach(function (
            keyValue1
          ) {
            if (keyValue1 !== "EntityId") {
              var item = newAWorkfinityAppEntity[keyValue][keyValue1];
              if (typeof item === "object") {
                if (Array.isArray(item))
                  data[keyValue + "." + keyValue1.replace("@", "")] = item;
                else data[keyValue + "." + keyValue1.replace("@", "")] = [item];
              } else {
                data[keyValue + "." + keyValue1.replace("@", "")] = item;
              }
            }
          });
        }
      });
    }
    this.setState({ data });
  };

  getEvaluatedValue1 = (comp) => {
    //console.log(comp);
    const ruleId = comp.rule;
    const { rules, data } = this.state;
    //console.log(rules);
    if (rules === undefined) return 0;
    const rule = rules.filter((x) => x.ruleId === ruleId);
    //console.log(rule);
    //const ruleEntity =
    // comp.attributeType.toLowerCase() + "." + comp.attributeObject;
    //const ruleScript = comp.ruleExpression.split("=");
    if (rule.length > 0 && rule[0].ruleScript !== null) {
      //if (ruleScript.length > 1 && ruleScript[1] !== null) {
      let ruleExp = rule[0].ruleScript.split("(");
      //const rs = "(" + ruleScript[1] + ")";
      //let ruleExp = rs.split("(");
      let expressionValues = [];
      let calcValue = "";
      ruleExp.map((item) => {
        let rd = item.split(")");
        if (rd != "") {
          let rv = this.evaluateRuleExp(rd[0]);
          //console.log(rd);
          //expressionValues.push(rv);
          if (rd[1] !== "") {
            let pvalue = rd[1].split(",");
            //console.log(pvalue);
            if (pvalue.length > 1) {
              let rootValue = isNaN(parseInt(pvalue[0]))
                ? parseInt(data[pvalue[0]])
                : pvalue[0];
              //console.log(isNaN(parseInt(pvalue[0])))
              let pv = Math.pow(rv, rootValue);
              expressionValues.push(pv);
              if (pvalue[1] !== "") {
                expressionValues.push(pvalue[1]);
              }
            } else {
              expressionValues.push(rv);
              expressionValues.push(rd[1]);
            }
          } else {
            expressionValues.push(rv);
          }
        }
      });

      //console.log(expressionValues);

      for (let j = 0; j < expressionValues.length; j++) {
        let resultValue = expressionValues[j];
        let rValue = resultValue.toString().trim();
        if (
          rValue !== "+" &&
          rValue !== "-" &&
          rValue !== "*" &&
          rValue !== "/"
        ) {
          calcValue = resultValue;
        } else {
          j++;
          if (rValue === "+") {
            calcValue = calcValue + expressionValues[j];
          }
          if (rValue === "-") {
            calcValue = calcValue - expressionValues[j];
          }
          if (rValue === "*") {
            calcValue = calcValue * expressionValues[j];
          }
          if (rValue === "/") {
            calcValue = calcValue / expressionValues[j];
          }
        }
      }

      data[rule[0].ruleEntity] = isNaN(calcValue) ? "" : calcValue.toString();
      //data[ruleEntity] = isNaN(calcValue) ? "" : calcValue.toString();
      return isNaN(calcValue) ? "" : calcValue;
    }

    return "";
  };

  evaluateRuleExp(rule) {
    const { data } = this.state;
    const ruleData = rule.split(" ");
    //console.log(ruleData);
    let calcValue = 0;
    for (let i = 0; i < ruleData.length; i++) {
      let rValue = ruleData[i];
      //console.log(rValue);
      if (
        rValue !== "+" &&
        rValue !== "-" &&
        rValue !== "*" &&
        rValue !== "/"
      ) {
        calcValue += isNaN(parseInt(data[ruleData[i]]))
          ? parseInt(ruleData[i])
          : parseInt(data[ruleData[i]]);
      } else {
        i++;
        const ddvalue =
          data[ruleData[i]] === undefined
            ? 0
            : isNaN(data[ruleData[i]])
            ? parseInt(ruleData[i])
            : data[ruleData[i]];
        if (rValue === "+") {
          calcValue = calcValue + parseInt(ddvalue);
        }

        if (rValue === "-") {
          calcValue = calcValue - parseInt(ddvalue);
        }

        if (rValue === "*") {
          calcValue = calcValue * parseInt(ddvalue);
        }

        if (rValue === "/") {
          calcValue = calcValue / parseInt(ddvalue);
        }
      }
    }

    return calcValue;
  }

  handleEditForm = () => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You want to edit this form",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ isDisabled: false });
      }
    });
  };

  handleSSNChanges = ({ currentTarget: input }) => {
    const { data, encryptionDecryptions } = this.state;
    const ssnDdName = input.name.split("~")[0];
    data[input.name] = input.value;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    //console.log(errorMessage);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    this.setState({ data, errors });

    if (input.dataset.encryption !== undefined && input.dataset.encryption) {
      this.addEncryptionFieldData(input.name, input.value);
      const essn1 =
        encryptionDecryptions[ssnDdName + "~1"] === undefined
          ? ""
          : encryptionDecryptions[ssnDdName + "~1"];
      const essn2 =
        encryptionDecryptions[ssnDdName + "~2"] === undefined
          ? ""
          : encryptionDecryptions[ssnDdName + "~2"];
      const essn3 =
        encryptionDecryptions[ssnDdName + "~3"] === undefined
          ? ""
          : encryptionDecryptions[ssnDdName + "~3"];

      encryptionDecryptions[ssnDdName] = essn1 + essn2 + essn3;

      const ssn1 =
        data[ssnDdName + "~1"] === undefined ? "" : data[ssnDdName + "~1"];
      const ssn2 =
        data[ssnDdName + "~2"] === undefined ? "" : data[ssnDdName + "~2"];
      const ssn3 =
        data[ssnDdName + "~3"] === undefined ? "" : data[ssnDdName + "~3"];
      data[ssnDdName] = ssn1 + ssn2 + ssn3;
      this.setState({ encryptionDecryptions, data });
    } else {
      const ssn1 =
        data[ssnDdName + "~1"] === undefined ? "" : data[ssnDdName + "~1"];
      const ssn2 =
        data[ssnDdName + "~2"] === undefined ? "" : data[ssnDdName + "~2"];
      const ssn3 =
        data[ssnDdName + "~3"] === undefined ? "" : data[ssnDdName + "~3"];
      data[ssnDdName] = ssn1 + ssn2 + ssn3;
      this.setState({ data });
    }
  };

  handleSSNPhoneEncryptionDecryption = (name) => {
    this.handleEncryptionDecryption(name + "~1");
    this.handleEncryptionDecryption(name + "~2");
    this.handleEncryptionDecryption(name + "~3");

    const { data, encryptionDecryptions } = this.state;
    const ssn1 = data[name + "~1"] === undefined ? "" : data[name + "~1"];
    const ssn2 = data[name + "~2"] === undefined ? "" : data[name + "~2"];
    const ssn3 = data[name + "~3"] === undefined ? "" : data[name + "~3"];
    data[name] = ssn1 + ssn2 + ssn3;
    const essn1 =
      encryptionDecryptions[name + "~1"] === undefined
        ? ""
        : encryptionDecryptions[name + "~1"];
    const essn2 =
      encryptionDecryptions[name + "~2"] === undefined
        ? ""
        : encryptionDecryptions[name + "~2"];
    const essn3 =
      encryptionDecryptions[name + "~3"] === undefined
        ? ""
        : encryptionDecryptions[name + "~3"];
    encryptionDecryptions[name] = essn1 + essn2 + essn3;
    this.setState({ data, encryptionDecryptions });
  };

  handleCountryOnChange = ({ currentTarget: select }) => {
    //console.log(select);
    const selectedOption = select.selectedOptions[0];
    //console.log(selectedOption.dataset.geonameid);
    if (selectedOption === undefined) return;
    this.handleChange(select);
    this.bindCountryDropdown(selectedOption.dataset.geonameid, select);
  };

  handleStateOnChange = ({ currentTarget: select }) => {
    //console.log(select);
    const selectedOption = select.selectedOptions[0];
    //console.log(selectedOption);
    this.handleChange(select);
    this.bindCountyDropdown(selectedOption.dataset.geonameid, select);
  };

  handleCountyOnChange = ({ currentTarget: select }) => {
    //console.log(select);
    const selectedOption = select.selectedOptions[0];
    //console.log(selectedOption);
    if (selectedOption === undefined) return;
    this.handleChange(select);
    this.bindCityDropdown(selectedOption.dataset.geonameid, select);
  };

  getCodemangerOption = (id) => {
    const { ddTypeCodMngr } = this.state;
    if (ddTypeCodMngr !== undefined) {
      const ddType = ddTypeCodMngr.filter((x) => x.controlId === id);
      return (
        ddType.length > 0 &&
        ddType[0].optionData.map((d) => (
          <option value={d.name} data-code={d.code}>
            {d.name}
          </option>
        ))
      );
    }
  };

  handleCustomChange = async ({ currentTarget: select }) => {
    const selectedItem = select.selectedOptions[0];
    //console.log(selectedItem.dataset.code);
    const nextAttribute = select.dataset.childattribute;
    //console.log(nextAttribute);
    const nextType = nextAttribute.split(".");
    const { ddTypeCodMngr, data } = this.state;
    try {
      const { data: items } = await http.get(
        config.GetDataTypeCodemanager + nextType[0] + "&att=" + nextType[1]
      );
      //console.log(items);
      const filterData = items.filter(
        (x) => x.parentCode === parseInt(selectedItem.dataset.code)
      );
      //console.log(filterData);
      const ddTypeObj = {
        controlId: nextAttribute,
        optionData: filterData,
      };
      const removeOldData = ddTypeCodMngr.filter(
        (x) => x.controlId !== nextAttribute
      );
      const addedType = [...removeOldData, ddTypeObj];
      data[select.name] = selectedItem.text;
      this.setState({ ddTypeCodMngr: addedType });
      this.handleOnChangeTrigger(nextAttribute);
    } catch (err) {
      console.log(err);
    }
  };

  handleOnChangeTrigger(id) {
    var element = document.getElementById(id);
    var event = new Event("change", { bubbles: true });
    element.dispatchEvent(event);
  }

  handleDropdownShowHide = (id, label) => {
    if (id !== undefined) {
      const { ddTypeCodMngr } = this.state;
      const ddType = ddTypeCodMngr.filter((x) => x.controlId === id);
      this.setState({
        dropdownShowHide: !this.state.dropdownShowHide,
        dropdwonTitle: label,
        selectedDropdwonData: ddType,
      });
    } else {
      this.setState({
        dropdownShowHide: !this.state.dropdownShowHide,
        dropdwonTitle: "",
        selectedDropdwonData: [],
      });
    }
  };

  handleDropdownValueChange = ({ currentTarget }) => {
    const { ddTypeCodMngr, data, errors } = this.state;
    const ddType = ddTypeCodMngr.filter(
      (x) => x.controlId === currentTarget.id
    );
    if (ddType.length > 0) {
      const filter = ddType[0].optionData.filter((x) => {
        return x.name.match(new RegExp(`${currentTarget.value}`, "gi"));
      });
      if (filter.length === 0) {
        data[currentTarget.id] = currentTarget.value;
        errors[currentTarget.id] = "Not Found";
      } else {
        data[currentTarget.id] = currentTarget.value;
        errors[currentTarget.id] = "";
      }

      this.setState({ data, errors });
    }
  };

  getPopover = (notes) => {
    const popover = (
      <Popover id="popover-basic" style={{ minWidth: "500px" }}>
        <Popover.Title as="h4">Rule Expression</Popover.Title>
        <Popover.Content>{notes}</Popover.Content>
      </Popover>
    );
    return (
      <React.Fragment>
        <OverlayTrigger trigger="click" placement="top" overlay={popover}>
          <Button className="btn btn-sm btn-outline-success ml-2">
            <i className="fa fa-eye mr-2"></i>View Rule
          </Button>
        </OverlayTrigger>
      </React.Fragment>
    );
  };

  formatPhoneNumber(value) {
    if (!value) return value;
    let phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6)}`;
  }
  formatSSNNumber(value) {
    if (!value) return value;
    let phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      5
    )}-${phoneNumber.slice(5)}`;
  }

  handleEncryptDecryptedOnChange = (name, valueArray) => {
    const { data, errors } = this.state;
    let decValue = "";
    for (let index = 0; index < valueArray.length; index++) {
      const element = valueArray[index];
      decValue += element;
    }
    const input = { name: name, value: decValue };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[name] = errorMessage;
    else delete errors[name];
    data[name] = decValue;
    this.setState({ data, errors });
  };

  getAlert(title, message, type) {
    return Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: "Close",
      confirmButtonColor: "#d33",
    });
  }

  handleDateEncryptDecryptedOnChange = (name, valueArray) => {
    //console.log(valueArray);
    const { data, errors } = this.state;
    let decValue = "";
    for (let index = 0; index < valueArray.length; index++) {
      const element = valueArray[index];
      decValue += element;
    }
    let dt = "";
    if (valueArray.length > 0) {
      dt = `${decValue.slice(0, 2)}-${decValue.slice(2, 4)}-${decValue.slice(
        4
      )}`;
    }
    const input = { name: name, value: dt };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[name] = errorMessage;
    else delete errors[name];
    data[name] = dt;
    this.setState({ data, errors });
  };

  rendorHtmlComponent(comp) {
    const {
      data,
      encryptionDecryptions,
      errors,
      isDisabled,
      wfForm,
      stateList,
      stateCountyCity,
      ddTypeCodMngr,
      countryCodes,
      codeIsVerified,
      btnProcessStatus
    } = this.state;
    //console.log(data);
    const attributeType =
      comp.attributeType !== undefined ? comp.attributeType.toLowerCase() : "";

    const attributeObject =
      comp.attributeObject !== undefined ? comp.attributeObject : "";

    const controleId =
      attributeType !== "" && attributeObject !== ""
        ? attributeType + "." + attributeObject
        : "";

    if (comp.isRequired && controleId != "" && comp.dataType !== "esign") {
      this.schema[controleId] = Joi.string().required().label(comp.label);
    }

    if (comp.dataType === "label") {
      //console.log(comp)
      const attStyle = comp.styleCss !== undefined && comp.styleCss.textalign;
      const textContent = (
        <React.Fragment>
          {comp.formIcons !== undefined ? (
            <div className={comp.text !== undefined && "d-flex"}>
              <div>
                <span>
                  <i className={`${comp.formIcons} p-1`} aria-hidden="true"></i>
                </span>
              </div>
              <div>
                {comp.text &&
                  comp.text.split("&lt;br&gt;").map((item) => (
                    <React.Fragment>
                      {item} <br></br>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ) : (
            comp.text && (
              <div>
                {comp.text.split("&lt;br&gt;").map((item) => (
                  <React.Fragment>
                    {item} <br></br>
                  </React.Fragment>
                ))}
              </div>
            )
          )}
        </React.Fragment>
      );

      if (comp.attributeDataTypes !== undefined) {
        return (
          <div
            className={`${attStyle !== "" ? "text-" + attStyle + " " : ""} ${
              comp.cssclass !== undefined ? comp.cssclass : ""
            } form-group`}
          >
            {comp.attributeDataTypes.type === "ol" && (
              <ol type={comp.orderedListType}>
                {comp.text.split("&lt;br&gt;").map((p) => (
                  <li>{p}</li>
                ))}
              </ol>
            )}
            {comp.attributeDataTypes.type === "ul" && (
              <ul>
                {comp.text.split("&lt;br&gt;").map((p) => (
                  <li>{p}</li>
                ))}
              </ul>
            )}
            {comp.attributeDataTypes.type === "paragraph" && (
              <p>{controleId !== "" ? data[controleId] : textContent}</p>
            )}
            {comp.attributeDataTypes.type === "label" && (
              <label>
                {controleId !== "" ? data[controleId] : textContent}
              </label>
            )}
            {comp.attributeDataTypes.type === "h1" && (
              <h1 className="mb-0">
                {controleId !== "" ? data[controleId] : textContent}
              </h1>
            )}
            {comp.attributeDataTypes.type === "h2" && (
              <h2 className="mb-0">
                {controleId !== "" ? data[controleId] : textContent}
              </h2>
            )}
            {comp.attributeDataTypes.type === "h3" && (
              <h3 className="mb-0">
                {controleId !== "" ? data[controleId] : textContent}
              </h3>
            )}
            {comp.attributeDataTypes.type === "h4" && (
              <h4 className="mb-0">
                {controleId !== "" ? data[controleId] : textContent}
              </h4>
            )}
            {comp.attributeDataTypes.type === "h5" && (
              <h5 className="mb-0">
                {controleId !== "" ? data[controleId] : textContent}
              </h5>
            )}
          </div>
        );
      } else {
        return (
          <label
            className={`${attStyle !== "" ? "text-" + attStyle + " " : ""} ${
              comp.cssclass !== undefined ? comp.cssclass : ""
            } form-group`}
          >
            {controleId !== "" ? (
              <span className="ml-1">{data[controleId]}</span>
            ) : (
              textContent
            )}
          </label>
        );
      }
    }

    if (comp.dataType === "textBox") {
      //console.log(comp);
      let inputType = "";
      if (comp.attributeDataTypes != undefined) {
        inputType = comp.attributeDataTypes.type;

        if (inputType === "phone" || inputType === "mobile") {
          this.schema[controleId] = !comp.isRequired
            ? Joi.string()
                .length(10)
                .options({
                  language: {
                    string: {
                      length: comp.label + " must be 10 digits.",
                    },
                  },
                })
                .label(comp.label)
            : Joi.string()
                .length(10)
                .options({
                  language: {
                    string: {
                      length: comp.label + " must be 10 digits.",
                    },
                  },
                })
                .required()
                .label(comp.label);
        }

        if (inputType === "ssn") {
          this.schema[controleId] = !comp.isRequired
            ? Joi.string()
                .length(9)
                .options({
                  language: {
                    string: {
                      length: comp.label + " must be 9 digits.",
                    },
                  },
                })
                .label(comp.label)
            : Joi.string()
                .length(9)
                .options({
                  language: {
                    string: {
                      length: comp.label + " must be 9 digits.",
                    },
                  },
                })
                .required()
                .label(comp.label);
        }

        if (inputType === "email"){
          this.schema[controleId] = !comp.isRequired
          ? Joi.string().email().label(comp.label)
          : Joi.string().email().required().label(comp.label);
        }

      }
      return (
        <div className={`form-group ${comp.isRequired && "required"}`}>
          <label className="form-control-label" htmlFor={controleId}>
            {comp.label}{" "}
            {comp.rule !== undefined && this.getPopover(comp.ruleExpression)}
          </label>
          {comp.encryptionValidate ? (
            <>
              {inputType === "ssn" && (
                <SSN_textbox
                  value={data[controleId] || ""}
                  label={comp.label}
                  isDisabled={isDisabled}
                  name={controleId}
                  handleOnChange={this.handleEncryptDecryptedOnChange}
                  codeIsVerified={codeIsVerified}
                />
              )}
              {(inputType === "phone" || inputType === "mobile") && (
                <Phone_textbox
                  value={data[controleId] || ""}
                  label={comp.label}
                  isDisabled={isDisabled}
                  name={controleId}
                  handleOnChange={this.handleEncryptDecryptedOnChange}
                  codeIsVerified={codeIsVerified}
                />
              )}
              {(inputType === "" || inputType === "email") && (
                <Other_textbox
                  value={data[controleId]}
                  label={comp.label}
                  isDisabled={isDisabled}
                  name={controleId}
                  handleOnChange={this.handleEncryptDecryptedOnChange}
                  codeIsVerified={codeIsVerified}
                />
              )}
            </>
          ) : (
            <>
              <div className="d-flex">
                {inputType === "ssn" && (
                  <>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={comp.label}
                      disabled={isDisabled}
                      name={controleId}
                      value={this.formatSSNNumber(data[controleId] || "")}
                      onChange={this.handeSsnPhoneOnChange}
                      title="SSN 9 Digit"
                      aria-label={comp.label}
                      aria-required={comp.isRequired}
                    ></input>
                  </>
                )}

                {(inputType === "phone" || inputType === "mobile") && (
                  <>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={comp.label}
                      disabled={isDisabled}
                      name={controleId}
                      value={this.formatPhoneNumber(data[controleId] || "")}
                      onChange={this.handeSsnPhoneOnChange}
                      //data-encryption={comp.encryptionValidate}
                      title={comp.label}
                      aria-label={comp.label}
                      aria-required={comp.isRequired}
                    ></input>
                  </>
                )}

                {(inputType === "" || inputType === "email") && (
                  <React.Fragment>
                    <input
                      type="text"
                      className={
                        comp.cssclass !== undefined
                          ? comp.cssclass + " form-control"
                          : "form-control"
                      }
                      id={controleId}
                      placeholder={comp.label}
                      name={controleId}
                      value={
                        data[controleId] === undefined ? "" : data[controleId]
                      }
                      //data-encryption={comp.encryptionValidate}
                      onChange={this.handeOnChange}
                      disabled={isDisabled}
                      aria-required={comp.isRequired}
                      readOnly={comp.rule !== undefined}
                    />

                    {comp.rule !== undefined && (
                      <button
                        className="btn btn-sm btn-primary ml-2"
                        onClick={() => this.getEvaluatedValue(comp)}
                      >
                        Apply Rule
                      </button>
                    )}
                  </React.Fragment>
                )}
                {/* {comp.encryptionValidate && (
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={() => this.handleEncryptionDecryption(controleId)}
                    title={`${
                      encryptionDecryptions[controleId + "encrypt"]
                        ? "Decrypt"
                        : "Encrypt"
                    } ${comp.label}`}
                  >
                    {encryptionDecryptions[controleId + "encrypt"] ? (
                      <i className="fa fa-eye-slash" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-eye" aria-hidden="true"></i>
                    )}
                  </button>
                )} */}
              </div>
            </>
          )}
          {errors[controleId] && (
            <div className="alert alert-danger">{errors[controleId]}</div>
          )}
        </div>
      );
    }

    if (comp.dataType === "radio") {
      return (
        <div
          className={`form-group ${comp.isRequired && "required"}`}
          role="radiogroup"
          aria-labelledby={controleId + "_group_label"}
          id={controleId + "_rg"}
        >
          <label
            className="form-control-label mr-2"
            id={controleId + "_group_label"}
          >
            {comp.label}
          </label>
          {comp.radioValues.map((item, key) => (
            <div className="custom-control custom-radio mb-2">
              <input
                tabIndex={key}
                name={controleId}
                className={
                  comp.cssclass !== undefined
                    ? comp.cssclass + " custom-control-input"
                    : "custom-control-input"
                }
                id={controleId + key}
                type="radio"
                onChange={this.handeOnChange}
                value={item.value}
                defaultChecked={
                  comp.attributeObject != undefined &&
                  data[controleId] === item.value
                }
                disabled={isDisabled}
                aria-labelledby={controleId + key}
                aria-checked={
                  comp.attributeObject != undefined &&
                  data[controleId] === item.value
                }
              />
              <label
                className="custom-control-label mr-2 pt-1"
                htmlFor={controleId + key}
              >
                {item.name}
              </label>
            </div>
          ))}
          {errors[controleId] && (
            <div className="alert alert-danger">{errors[controleId]}</div>
          )}
        </div>
      );
    }

    if (comp.dataType === "date") {
      return (
        <div className={`form-group ${comp.isRequired && "required"}`}>
          <label className="form-control-label" htmlFor={controleId}>
            {comp.label} (MM-DD-YYYY)
          </label>
          <Date_textbox
            value={data[controleId]}
            label={comp.label}
            isDisabled={isDisabled}
            name={controleId}
            handleOnChange={this.handleDateEncryptDecryptedOnChange}
            codeIsVerified={codeIsVerified}
          />
          <div className="d-flex">
            {/* <DatePicker
              dateFormat="MM-dd-yyyy"
              placeholderText="MM-DD-YYYY"
              onChange={(date) =>
                this.handleChange({
                  name: controleId,
                  value: new Date(date).toDateString(),
                })
              }
              name={controleId}
              id={controleId}
              className={
                comp.cssclass !== undefined
                  ? comp.cssclass + " form-control"
                  : "form-control"
              }
              selected={
                data[controleId] === undefined || data[controleId] === ""
                  ? ""
                  : new Date(data[controleId])
              }
              disabled={isDisabled}
            /> */}

            {/* <input
              className={
                comp.cssclass !== undefined
                  ? comp.cssclass + " form-control"
                  : "form-control"
              }
              placeholder="Select date"
              type="date"
              id={controleId}
              name={controleId}
              value={data[controleId]}
              //data-encryption={comp.encryptionValidate}
              onChange={this.handeOnChange}
              disabled={isDisabled}
            /> 
            {comp.encryptionValidate && (
              <button
                className="btn btn-secondary btn-sm ml-2"
                onClick={() => this.handleEncryptionDecryption(controleId)}
              >
                {encryptionDecryptions[controleId] === "" ? (
                  <i class="fa fa-eye-slash" aria-hidden="true"></i>
                ) : (
                  <i class="fa fa-eye" aria-hidden="true"></i>
                )}
              </button>
            )} */}
          </div>
          {errors[controleId] && (
            <div className="alert alert-danger">{errors[controleId]}</div>
          )}
        </div>
      );
    }

    if (comp.dataType === "time") {
      return (
        <div className={`form-group ${comp.isRequired && "required"}`}>
          <label className="form-control-label" htmlFor={controleId}>
            {comp.label}
          </label>
          <div className="d-flex">
            <input
              className={
                comp.cssclass !== undefined
                  ? comp.cssclass + " form-control"
                  : "form-control"
              }
              placeholder="Select time"
              type="time"
              id={controleId}
              name={controleId}
              value={data[controleId]}
              onChange={this.handeOnChange}
              data-encryption={comp.encryptionValidate}
              disabled={isDisabled}
            />
            {comp.encryptionValidate && (
              <button
                className="btn btn-secondary btn-sm ml-2"
                onClick={() => this.handleEncryptionDecryption(controleId)}
              >
                {encryptionDecryptions[controleId] === "" ? (
                  <i class="fa fa-eye-slash" aria-hidden="true"></i>
                ) : (
                  <i class="fa fa-eye" aria-hidden="true"></i>
                )}
              </button>
            )}
          </div>
          {errors[controleId] && (
            <div className="alert alert-danger">{errors[controleId]}</div>
          )}
        </div>
      );
    }

    if (comp.dataType === "password") {
      return (
        <div className={`form-group ${comp.isRequired && "required"}`}>
          <label className="form-control-label" htmlFor={controleId}>
            {comp.label}
          </label>
          <div className="d-flex">
            <input
              type="password"
              className={
                comp.cssclass !== undefined
                  ? comp.cssclass + " form-control"
                  : "form-control"
              }
              id={controleId}
              placeholder={comp.label}
              name={controleId}
              value={data[controleId]}
              onChange={this.handeOnChange}
              data-encryption={comp.encryptionValidate}
              disabled={isDisabled}
            />
            {comp.encryptionValidate && (
              <button
                className="btn btn-secondary btn-sm ml-2"
                onClick={() => this.handleEncryptionDecryption(controleId)}
              >
                {encryptionDecryptions[controleId] === "" ? (
                  <i class="fa fa-eye-slash" aria-hidden="true"></i>
                ) : (
                  <i class="fa fa-eye" aria-hidden="true"></i>
                )}
              </button>
            )}
          </div>
          {errors[controleId] && (
            <div className="alert alert-danger">{errors[controleId]}</div>
          )}
        </div>
      );
    }

    if (comp.dataType === "dropDown") {
      //console.log(comp);
      let selectField = "";
      if (comp.dropdownType === "search") {
        selectField = (
          <div className="d-flex">
            <input
              type="text"
              className="form-control mr-2"
              name={controleId}
              id={controleId}
              value={data[controleId]}
              onChange={this.handleDropdownValueChange}
            />
            <button
              className="btn btn-outline-primary"
              onClick={() =>
                this.handleDropdownShowHide(controleId, comp.label)
              }
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        );
      }
      if (comp.dropdownType === "countrycode") {
        selectField = (
          <select
            name={controleId}
            id={controleId}
            className="form-control"
            value={data[controleId] || ""}
            onChange={this.handeOnChange}
            disabled={isDisabled}
          >
            <option value="">Select</option>
            {countryCodes.map((item) => (
              <option value={item.dial_code + "_" + item.code}>
                {item.dial_code} {item.name}
              </option>
            ))}
          </select>
        );
      } else {
        selectField = (
          <select
            name={controleId}
            id={controleId}
            className={
              comp.cssclass !== undefined
                ? `${comp.cssclass} form-control`
                : `form-control`
            }
            value={data[controleId] || ""}
            //onChange={this.handeOnChange}
            onChange={
              comp.childAttributeType !== undefined
                ? this.handleCustomChange
                : this.handeOnChange
            }
            disabled={isDisabled}
            data-childattribute={
              comp.childAttributeType !== undefined &&
              comp.childAttributeType.toLowerCase() +
                "." +
                comp.childAttributeObject
            }
          >
            <option value="">Select</option>
            {comp.dropdownvalue.filter((x) => x.name !== "").length > 0
              ? comp.dropdownvalue.map(
                  (d) =>
                    d.name !== "" && <option value={d.value}>{d.name}</option>
                )
              : this.getCodemangerOption(controleId)}
          </select>
        );
      }

      if (
        comp.addressGroup === "apistatecountycity" ||
        comp.addressGroup === "apistatecounty" ||
        comp.addressGroup === "apicountrystate" ||
        comp.addressGroup === "apiuscountycity" ||
        comp.addressGroup === "apiusstatecounty"
      ) {
        if (comp.addressObjList === "country") {
          const countryList =
            comp.attributeType === undefined ||
            comp.attributeType === "" ||
            stateCountyCity === undefined
              ? []
              : stateCountyCity[comp.attributeType.toLowerCase()] === undefined
              ? []
              : stateCountyCity[comp.attributeType.toLowerCase()].countryList;
          //console.log(stateCountyCity);
          selectField = (
            <select
              name={controleId}
              id={controleId}
              className={
                comp.cssclass !== undefined
                  ? comp.cssclass + " form-control"
                  : "form-control"
              }
              value={data[controleId] || ""}
              onChange={this.handleCountryOnChange}
              disabled={isDisabled}
            >
              <option value="">Select</option>
              {countryList &&
                countryList.map((s) => (
                  <option
                    key={s.geonameId}
                    value={s.countryName}
                    data-geonameid={s.geonameId}
                  >
                    {s.countryName}
                  </option>
                ))}
            </select>
          );
        }
        if (comp.addressObjList === "state") {
          const stateList =
            comp.attributeType === undefined ||
            comp.attributeType === "" ||
            stateCountyCity === undefined
              ? []
              : stateCountyCity[comp.attributeType.toLowerCase()] === undefined
              ? []
              : stateCountyCity[comp.attributeType.toLowerCase()].stateList;
          console.log(data[stateCountyCity]);
          selectField = (
            <select
              name={controleId}
              id={controleId}
              className={
                comp.cssclass !== undefined
                  ? comp.cssclass + " form-control"
                  : "form-control"
              }
              value={data[controleId] || ""}
              onChange={this.handleStateOnChange}
              disabled={isDisabled}
            >
              <option value="">Select</option>
              {stateList.map((s) => (
                <option
                  key={s.geonameId}
                  value={s.name}
                  data-geonameid={s.geonameId}
                  //selected={data[controleId] === s.name}
                >
                  {s.name}
                </option>
              ))}
            </select>
          );
        }
        if (comp.addressObjList === "county") {
          const countyList =
            comp.attributeType === undefined ||
            comp.attributeType === "" ||
            stateCountyCity === undefined
              ? []
              : stateCountyCity[comp.attributeType.toLowerCase()] === undefined
              ? []
              : stateCountyCity[comp.attributeType.toLowerCase()].countyList;
          selectField = (
            <select
              name={controleId}
              id={controleId}
              className={
                comp.cssclass !== undefined
                  ? comp.cssclass + " form-control"
                  : "form-control"
              }
              value={data[controleId] || ""}
              onChange={this.handleCountyOnChange}
              disabled={isDisabled}
            >
              <option value="">Select</option>
              {countyList.map((s) => (
                <option
                  key={s.geonameId}
                  value={s.countryName}
                  data-geonameid={s.geonameId}
                >
                  {s.countryName}
                </option>
              ))}
            </select>
          );
        }
        if (comp.addressObjList === "city") {
          const cityList =
            comp.attributeType === undefined ||
            comp.attributeType === "" ||
            stateCountyCity === undefined
              ? []
              : stateCountyCity[comp.attributeType.toLowerCase()] === undefined
              ? []
              : stateCountyCity[comp.attributeType.toLowerCase()].cityList;
          selectField = (
            <select
              name={controleId}
              id={controleId}
              className={
                comp.cssclass !== undefined
                  ? comp.cssclass + " form-control"
                  : "form-control"
              }
              value={data[controleId] || ""}
              onChange={this.handeOnChange}
              disabled={isDisabled}
            >
              <option value="">Select</option>
              {cityList.map((s) => (
                <option
                  key={s.geonameId}
                  value={s.name}
                  data-geonameid={s.geonameId}
                >
                  {s.name}
                </option>
              ))}
            </select>
          );
        }
      }

      return (
        <div className={`form-group ${comp.isRequired && "required"}`}>
          <label className="form-control-label" htmlFor={controleId}>
            {comp.label}
          </label>
          {selectField}
          {errors[controleId] && (
            <div className="alert alert-danger">{errors[controleId]}</div>
          )}
        </div>
      );
    }

    if (comp.dataType === "checkBox") {
      //console.log(comp);
      return (
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              className={
                comp.cssclass !== undefined
                  ? comp.cssclass + " custom-control-input"
                  : "custom-control-input"
              }
              id={controleId}
              type="checkbox"
              name={controleId}
              value="Yes"
              onChange={this.handleCheckBoxChecked}
              defaultChecked={
                comp.attributeObject != undefined &&
                data[controleId] === comp.attributeObject
              }
              disabled={isDisabled}
              aria-label={comp.label}
            />

            <label
              className="custom-control-label pt-1 form-control-label"
              htmlFor={controleId}
            >
              {comp.isRequired && (
                <i
                  className="fa fa-asterisk"
                  aria-hidden="true"
                  style={{
                    fontSize: "50%",
                    position: "absolute",
                    marginLeft: "-10px",
                    color: "#d00",
                    fontSize: "8px",
                  }}
                ></i>
              )}{" "}
              {comp.label}
            </label>
            {errors[controleId] && (
              <div className="alert alert-danger">{errors[controleId]}</div>
            )}
          </div>
        </div>
      );
    }

    if (comp.dataType === "multiLineBox") {
      //console.log(comp);
      return (
        <div className={`form-group ${comp.isRequired && "required"}`}>
          <label className="form-control-label" htmlFor={controleId}>
            {comp.label}
          </label>
          <div className="d-flex">
            <textarea
              className={
                comp.cssclass !== undefined
                  ? comp.cssclass + "form-control"
                  : "form-control"
              }
              id={controleId}
              placeholder={comp.label}
              rows={2}
              name={controleId}
              value={
                comp.rule === undefined
                  ? data[controleId] === undefined
                    ? ""
                    : data[controleId]
                  : this.getEvaluatedValue(comp.rule)
              }
              onChange={this.handeOnChange}
              data-encryption={comp.encryptionValidate}
              disabled={isDisabled}
              aria-label={comp.label}
            />
            {comp.encryptionValidate && (
              <button
                className="btn btn-secondary btn-sm ml-2"
                onClick={() => this.handleEncryptionDecryption(controleId)}
              >
                {encryptionDecryptions[controleId] === "" ? (
                  <i class="fa fa-eye-slash" aria-hidden="true"></i>
                ) : (
                  <i class="fa fa-eye" aria-hidden="true"></i>
                )}
              </button>
            )}
          </div>
          {errors[controleId] && (
            <div className="alert alert-danger">{errors[controleId]}</div>
          )}
        </div>
      );
    }

    if (comp.dataType === "file") {
      return (
        <div className={`form-group ${comp.isRequired && "required"}`}>
          <label className="form-control-label" htmlFor={controleId}>
            {comp.label}
          </label>
          <br />
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id={controleId}
              name={controleId}
              disabled={isDisabled}
              aria-label={comp.label}
              onChange={this.onFileChange}
            />
            <label className="custom-file-label" htmlFor={controleId}>
              {data[controleId] === undefined
                ? "Select file"
                : data[controleId]}
            </label>
          </div>
        </div>
      );
    }

    if (comp.dataType === "link") {
      return (
        <div className="form-group">
          <a
            href={comp.url}
            target="_blank"
            className={comp.cssclass !== undefined && comp.cssclass}
            disabled={isDisabled}
            aria-label={comp.label}
          >
            {comp.label}
          </a>
        </div>
      );
    }

    if (comp.dataType === "button" && comp.buttonType !== undefined) {
      if (
        comp.buttonType.type !== undefined &&
        comp.buttonType.type === "subform"
      ) {
        return (
          <button
            type="button"
            onClick={() =>
              this.handleSubformModalShowHide("showHide" + comp.subform)
            }
            className={`${
              comp.cssclass !== undefined
                ? comp.style + " " + comp.cssclass
                : comp.style
            } form-group`}
            disabled={isDisabled}
          >
            {comp.formIcons !== undefined && (
              <i className={comp.formIcons} aria-hidden="true"></i>
            )}{" "}
            {comp.label}
          </button>
        );
      } 
      else {
        if (
          comp.buttonType.type === "close" ||
          comp.buttonType.type === "exit" ||
          comp.buttonType.type === "back"
        ) {
          return (
            <button
              type="button"
              name={comp.buttonType.type}
              data-process={
                comp.formProcess === undefined ? 0 : comp.formProcess
              }
              className={`${
                comp.cssclass !== undefined
                  ? comp.style + " " + comp.cssclass
                  : comp.style
              }`}
              onClick={this.handleClose}
              aria-label={comp.label}
            >
              {comp.formIcons !== undefined && (
                <i className={`${comp.formIcons} mr-1`} aria-hidden="true"></i>
              )}
              {comp.label}
            </button>
          );
        } else 
        if (
          comp.buttonType.type === "submit" ||
          comp.buttonType.type === "saveAndContinue" ||
          comp.buttonType.type === "saveAndExit" ||
          comp.buttonType.type === "save" ||
          comp.buttonType.type === "taskCompleted" ||
          comp.buttonType.type === "checkStatus" ||
          comp.buttonType.type === "home"
        ) {
          return (
            (wfForm.isEdit === undefined ||
              wfForm.isEdit === true ||
              wfForm.isEdit === null ||
              isDisabled) && (
              <button
                type="button"
                name={comp.buttonType.type}
                data-process={
                  comp.formProcess === undefined ? 0 : comp.formProcess
                }
                className={`${
                  comp.cssclass !== undefined
                    ? comp.style + " " + comp.cssclass
                    : comp.style
                }`}
                disabled={btnProcessStatus || this.validate()}
                onClick={this.handleSubmit}
                aria-label={comp.label}
              >
                {btnProcessStatus && <i className="fa fa-spinner fa-spin mr-2"></i>}
                {comp.formIcons !== undefined && (
                  <i
                    className={`${comp.formIcons} mr-1`}
                    aria-hidden="true"
                  ></i>
                )}
                {comp.label}
              </button>
            )
          );
        } else if (comp.buttonType.type === "editForm") {
          return (
            <button
              type="button"
              name={comp.buttonType.type}
              data-process={
                comp.formProcess === undefined ? 0 : comp.formProcess
              }
              className={`${
                comp.cssclass !== undefined
                  ? comp.style + " " + comp.cssclass
                  : comp.style
              }`}
              onClick={this.handleEditForm}
              aria-label={comp.label}
            >
              {comp.formIcons !== undefined && (
                <i className={`${comp.formIcons} mr-1`} aria-hidden="true"></i>
              )}
              {comp.label}
            </button>
          );
        } else if (
          comp.buttonType.type === "update" ||
          comp.buttonType.type === "updateAndContinue" ||
          comp.buttonType.type === "updateAndExit"
        ) {
          return (
            !isDisabled &&
            wfForm.isEdit !== undefined &&
            wfForm.isEdit !== null &&
            wfForm.isEdit === false && (
              <button
                type="button"
                name={comp.buttonType.type}
                data-process={
                  comp.formProcess === undefined ? 0 : comp.formProcess
                }
                className={`${
                  comp.cssclass !== undefined
                    ? comp.style + " " + comp.cssclass
                    : comp.style
                }`}
                disabled={btnProcessStatus || this.validate()}
                onClick={this.handleSubmit}
                aria-lable={comp.label}
              >
                {btnProcessStatus && <i className="fa fa-spinner fa-spin mr-2"></i>}
                {comp.formIcons !== undefined && (
                  <i
                    className={`${comp.formIcons} mr-1`}
                    aria-hidden="true"
                  ></i>
                )}
                {comp.label}
              </button>
            )
          );
        } else if (comp.buttonType.type === "help") {
          return (
            <button
              type="button"
              name={comp.buttonType.type}
              data-process={
                comp.formProcess === undefined ? 0 : comp.formProcess
              }
              className={`${
                comp.cssclass !== undefined
                  ? comp.style + " " + comp.cssclass
                  : comp.style
              }`}
              //disabled={this.validate()}
              //onClick={this.handleSubmit}
              aria-lable={comp.label}
            >
              {comp.formIcons !== undefined && (
                <i className={`${comp.formIcons} mr-1`} aria-hidden="true"></i>
              )}
              {comp.label}
            </button>
          );
        }
      }
    }

    if (comp.dataType === "excel") {
      return (
        <React.Fragment>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="btn btn-success"
            table={`table-to-xls-${comp.id}-d`}
            filename={comp.label}
            sheet={comp.label}
            buttonText={comp.label}
          />
          <table id={`table-to-xls-${comp.id}-d`} className="d-none">
            <thead>
              <tr>
                <th></th>
              </tr>
            </thead>
          </table>
        </React.Fragment>
      );
    }

    if (comp.dataType === "esign") {
      //console.log(comp);
      return (
        <React.Fragment>
          <div className={`form-group ${comp.isRequired && "required"}`}>
            <label className="form-control-label" htmlFor={controleId}>
              {comp.label}
            </label>
            <div className="d-flex">
              <div className="form-control" style={{ height: 100 }}>
                <SignaturePad
                  canvasProps={{
                    width: 350,
                    height: 100,
                  }}
                  minWidth="0.5"
                  ref={(ref) => {
                    this.sigPad = ref;
                  }}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default BprmForm;
