import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "./PageHeader";
import AddPartnerPerformer from "./AddPartnerPerformer";
import http from "../services/HttpService";
import auth from "../services/authService";
import config from "../config.json";
import Pagination from "../comman/pagination";
import { paginate, calculateIndex } from "../utlis/paginate";
import PartnerPerformerTable from "./PartnerPerformerTable";
import PageSizeOptions from "./PageSizeOptions";
import ActiveDeactiveHistory from "./SystemAdministration/ManagePartner/ActiveDeactiveHistory";
import { getLoggedUser, getLoggedUserDetails, getCurrentUser } from "../app/store/auth";
import {
  getSubcontractorPerformers, loadSubcontractorPerformers,
  AddSubcontractorPerformer, getAddedSubPerformerStatus
} from "../app/store/subcontractor";
import {
  getRecruiterPerformers, loadRecruiterPerformers,
  AddRecruiterPerformer, getAddedRecPerfromerStatus
} from "../app/store/recruiter";
import { setCurrentPageTitle, setCurrentRoute } from "../app/store/layoutUI";
import { getFormatedNumber } from "../services/appService";
import Moment from "react-moment";
import { SendReminder } from "../app/store/candidate";
import { Tab, Tabs } from "react-bootstrap";

export default function ManagePartnerPerformer(props) {

  const routeArray = [
    { name: "System Administration", url: "" },
    { name: "Manage Entities", url: "" },
  ];
  //const [route,setRoute]=useState(routeArray)
  const [partnerType, setPartnerType] = useState("Recruiter")
  const [performers, setPerformers] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [rowsCount, setRowsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);

  const [showHide, setShowHide] = useState(false);
  const [logShowHide, setLogShowHide] = useState(false);
  const [remainderMsgShowHide, setRemainderMsgShowHide] = useState(false);

  const [selectedObj, setSelectedObj] = useState(null);
  const [currentUser, setCurrentUser] = useState("");
  const [loggedUser, setLoggedUser] = useState("");

  const [performerId, setPerformerId] = useState("");
  const [performerName, setPerformerName] = useState("");
  const [mobile, setMobile] = useState("");

  const [actionType, setActionType] = useState("action");
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [selectedKey, setSelectedKey] = useState("home");

  const subcontractorPerformers = useSelector(getSubcontractorPerformers);
  const recruiterPerformers = useSelector(getRecruiterPerformers);

  const subPerformerAdded = useSelector(getAddedSubPerformerStatus);
  const recPerformerAdded = useSelector(getAddedRecPerfromerStatus);

  const userDetails = useSelector(getLoggedUserDetails);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const state = location?.state;
    if (state?.partner && state?.url) {
      routeArray.push({ name: state.partner, url: state.url });
    }
    dispatch(setCurrentRoute(routeArray));
    dispatch(setCurrentPageTitle("Manage Users"))
  }, [])


  useEffect(() => {
    const state = location?.state;
    if (state?.obj) {
      const currentObj = state.obj;
      setCurrentUser(currentObj);
      if (currentObj?.partnerType === "Sub-Contractors") {
        setPartnerType("Subcontractor");
        dispatch(loadSubcontractorPerformers(currentObj?.fuid))
      }
      else {
        dispatch(loadRecruiterPerformers(currentObj?.fuid))
      }
    }
  }, []);

  useEffect(() => {
    getPagedData(subcontractorPerformers, currentPage, pageSize);
  }, [subcontractorPerformers]);

  useEffect(() => {
    getPagedData(recruiterPerformers, currentPage, pageSize);
  }, [recruiterPerformers]);

  useEffect(() => {
    const user = getCurrentUser();
    if (user?.type === "admin" && userDetails !== null) {
      const usrDetails = { ...userDetails, roleType: "admin" };
      setLoggedUser(usrDetails);
    }
  }, [userDetails]);

  useEffect(() => {
    if (subPerformerAdded) {
      dispatch(loadSubcontractorPerformers(currentUser.fuid));
    }
  }, [subPerformerAdded])

  useEffect(() => {
    if (recPerformerAdded) {
      dispatch(loadRecruiterPerformers(currentUser.fuid));
    }
  }, [recPerformerAdded])


  const handleSelection = (item) => {
    const obj = performers.filter(x => x.id === item.id);
    if (obj.length > 0) {
      setSelectedObj(obj[0]);
      setSelectedUserId(item.id);
    }
  };


  const handleFormSubmit = (performer) => {
    if (currentUser?.fuid > 0) {
      performer["partnerId"] = currentUser.fuid;
      if (partnerType === "Subcontractor")
        dispatch(AddSubcontractorPerformer(performer));
      else
        dispatch(AddRecruiterPerformer(performer));
      setShowHide(!showHide);
    }
  };

  const getPagedData = (list, currentPage, pageSize) => {
    const items = paginate(list, currentPage, pageSize);
    setRowsCount(list.length);
    setPerformers(items);
  };

  const handleLogShowHide = (item, actionType) => {
    if (item === "save") {
      setLogShowHide(false);
      setSelectedObj("");
      setActionType(actionType);
      if (partnerType === "Subcontractor")
        dispatch(loadSubcontractorPerformers(currentUser?.partnerId));
      else
        dispatch(loadRecruiterPerformers(currentUser?.partnerId))
    } else {
      setLogShowHide(!logShowHide);
      setSelectedObj(item);
      setActionType(actionType);
    }
  };

  const handleTabSelect = (tab) => {
    setSelectedKey(tab);
  }

  const handleFilter = async () => {
    let filtered =
      performerId !== ""
        ? performers.filter((p) => p.id === parseInt(performerId))
        : performers;

    filtered =
      performerName !== ""
        ? filtered.filter((x) =>
          x.name.toLowerCase().includes(performerName.toLowerCase())
        )
        : filtered;

    filtered =
      mobile !== ""
        ? filtered.filter((x) => x.contact.includes(mobile))
        : filtered;

    getPagedData(filtered, 1, pageSize)
  };

  const handleFilterCancel = () => {
    if (partnerType === "Subcontractor")
      getPagedData(subcontractorPerformers, 1, pageSize);
    else
      getPagedData(recruiterPerformers, 1, pageSize);

    setPerformerId("");
    setPerformerName("");
    setMobile("");
  };


  const handlePageChange = (page) => {
    const { startIndex: start, endIndex: end } = calculateIndex(page, startIndex, endIndex);
    setCurrentPage(page);
    setStartIndex(start);
    setEndIndex(end);
    if (partnerType === "Subcontractor")
      getPagedData(subcontractorPerformers, page, pageSize);
    else
      getPagedData(recruiterPerformers, page, pageSize);
  };

  const handlePageSizeChanged = ({ currentTarget }) => {
    setPageSize(currentTarget.value);
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(5);
    if (partnerType === "Subcontractor")
      getPagedData(subcontractorPerformers, currentTarget.value, pageSize);
    else
      getPagedData(recruiterPerformers, currentTarget.value, pageSize);
  };

  const handleRemainder = async (id) => {
    dispatch(SendReminder(id));
  };

  const handleRemainderMessage = () => {
    setRemainderMsgShowHide(!remainderMsgShowHide);
  };

  const handleResetSelection = () => {
    setSelectedUserId(0);
    setSelectedObj(null);
  }

  const handlePermissionShowHide = () => {
    handleResetSelection()
    setSelectedKey("home");
  };

  const handlePermissionShowHide2 = () => {
    dispatch(loadSubcontractorPerformers(currentUser.fuid));
    dispatch(loadRecruiterPerformers(currentUser.fuid));
    setSelectedKey("home");
  };

  let item = "";
  if (currentUser) item = currentUser;

  return (
    <React.Fragment>
      <div className="col-md-12 text-right">
        <button
          onClick={() => navigate(-1)}
          className="btn btn-sm btn-outline-default mb-2 btn-min-width"
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
        </button>
      </div>
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table align-items-center table-flush table-hover table-striped border">
            <thead className="thead-light">
              <tr>
                <th>ID</th>
                <th>Subcontractor Details</th>
                <th>Application</th>
                <th>Enrollment Status</th>
                <th>Active Users</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {item !== "" &&
                <tr>

                  <td>
                    {item.fuid}
                  </td>
                  <td>
                    <span className="text-uppercase">
                      {item.label}
                    </span>
                    <br />
                    <span className="text-primary">{item.email}</span>
                    <br />
                    <Moment format="MMM Do YYYY h:mm">
                      {item.createdDate}
                    </Moment>
                  </td>
                  <td>
                    <table className="table table-bordered">
                      {item.applications.map((app) => (
                        <tr>
                          <td className="p-1">
                            <button className="btn btn-sm btn-link p-0">
                              {app.applicationId} -{" "}
                              {app.applicationType}
                            </button>
                          </td>
                          <td width={"10%"}>
                            {app.appSubmitted && (
                              <span
                                className="badge badge-success rounded-0 btn-min-width"
                              >
                                Complete
                              </span>
                            )}

                            {(app.appSubmitted === "" ||
                              app.appSubmitted === null) && (
                                <span
                                  className="badge badge-success rounded-0 bg-yellow btn-min-width"
                                >
                                  Review
                                </span>
                              )}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </td>
                  <td>
                    {item.enrollmentStatus && (
                      <button className="btn btn-sm btn-link p-0">
                        Done
                      </button>
                    )}
                    {!item.enrollmentStatus && (
                      <button className="btn btn-sm btn-link p-0">
                        Pending
                      </button>
                    )}
                    <br />
                    {item.contact1 && (
                      <React.Fragment>
                        <button
                          className="btn btn-sm btn-link p-0 text-underline"
                          onClick={() =>
                            handleRemainder(
                              item.contact1.performerid
                            )
                          }
                        >
                          Send Reminder
                        </button>
                        <button
                          className="btn btn-sm btn-link p-0 text-underline mt-1"
                          title="View message"
                          onClick={handleRemainderMessage}
                        >
                          <span className="badge badge-warning rounded-0 text-red">
                            <i
                              class="fa fa-envelope mr-1"
                              aria-hidden="true"
                            ></i>
                            {!item.contact1.msgAlertCount ? 0 : item.contact1.msgAlertCount}
                          </span>
                        </button>
                      </React.Fragment>
                    )}
                  </td>
                  <td>
                    {item.activeUsers}
                  </td>
                  <td>
                    {item.contact1?.isActive === true && (
                      <button className="btn btn-sm btn-link p-0">
                        <i
                          className="fa fa-check-square"
                          aria-hidden="true"
                        ></i>{" "}
                        Activated
                      </button>
                    )}
                    {item.contact1?.isActive === false && (
                      <button className="btn btn-sm btn-link p-0">
                        Deactivated
                      </button>
                    )}
                    {item.contact1?.isActive === null && (
                      <button className="btn btn-sm btn-link p-0">
                        To Activate
                      </button>
                    )}
                  </td>

                </tr>}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-md-12 text-right">
        <button
          className="btn btn-sm btn-outline-default btn-min-width"
          data-toggle="collapse"
          data-target="#collapseExample"
        >
          <i className="fa fa-search" aria-hidden="true"></i> Search
        </button>
      </div>
      <div className="col-md-12">
        <div className="collapse p-2 bg-secondary border mt-2 mb-2" id="collapseExample">
          <div className="row">
            <div className="col-md-2">
              <label
                htmlFor="performerId"
                className="form-control-label"
              >
                ID
              </label>
              <input
                type="text"
                name="performerId"
                id="performerId"
                className="form-control"
                placeholder="ID"
                value={performerId}
                onChange={({ currentTarget }) =>
                  setPerformerId(currentTarget.value)
                }
              />
            </div>
            <div className="col-md-5">
              <label
                htmlFor="performerName"
                className="form-control-label"
              >
                Name
              </label>
              <input
                type="text"
                name="performerName"
                id="performerName"
                className="form-control"
                placeholder="Name"
                value={performerName}
                onChange={({ currentTarget }) =>
                  setPerformerName(currentTarget.value)
                }
              />
            </div>
            <div className="col-md-5 form-group">
              <label htmlFor="mobile" className="form-control-label">
                Mobile No.
              </label>
              <input
                type="text"
                name="mobile"
                id="mobile"
                className="form-control"
                placeholder="Mobile No."
                value={getFormatedNumber(mobile)}
                onChange={({ currentTarget }) =>
                  setMobile(currentTarget.value.replace(/[^\d]/g, ""))
                }
              />
            </div>
            <div className="col-md-12 text-center ">
              <button
                className="btn-sm btn-outline-default mr-2"
                onClick={() => handleFilter()}
              >
                <i className="fa fa-search mr-2" aria-hidden="true"></i>Filter
              </button>
              <button
                className="btn-sm btn-outline-default mr-2"
                onClick={handleFilterCancel}
              >
                <i className="fa fa-refresh mr-2" aria-hidden="true"></i>Refresh
              </button>
              <button
                className="btn-sm btn-outline-danger mr-2"
                data-toggle="collapse"
                data-target="#collapseExample"
                onClick={handleFilterCancel}
              >
                <i className="fa fa-close mr-2" aria-hidden="true"></i>Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <Tabs
          activeKey={selectedKey}
          onSelect={handleTabSelect}
          id="uncontrolled-tab-example"
          className="h3 border border-right-0 border-left-0 border-top-0 font-weight-normal"
        >
          <Tab eventKey="home" title="Users" tabClassName="page-tab border border-left-0 border-top-0">
            <Tab.Content className="border border-top-0">
              <div className="table-responsive">
                <table className="table align-items-center table-flush table-striped mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th>Select
                        <span style={{ cursor: "pointer" }} onClick={handleResetSelection}>
                          <i className="fa fa-refresh ml-2 text-primary" aria-hidden="true"></i>
                        </span>
                      </th>
                      <th>Select</th>
                      <th>ID</th>
                      <th>Date & Time</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Contact No.</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {performers.map(item =>
                      <tr>
                        <td>
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input"
                              type="radio"
                              id={item.id}
                              name="role"
                              onChange={() => handleSelection(item)}
                              value={selectedUserId}
                              checked={selectedUserId === item.id}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={item.id}
                            >
                            </label>
                          </div>
                        </td>
                        <td>{item.id}</td>
                        <td>
                          <Moment format="MMM Do YYYY h:mm">
                            {item.addedDateTime}
                          </Moment>
                        </td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.contact && getFormatedNumber(item.contact)}</td>
                        <td>{item.status ? <><i
                          className="fa fa-check text-success"
                          style={{ fontSize: "15px" }}
                          aria-hidden="true"
                        ></i>{" "} Activated</> : <><i
                          className="fa fa-times text-danger"
                          style={{ fontSize: "15px" }}
                          aria-hidden="true"
                        ></i>{" "} Deactivated</>}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* <WfPerformerTable
                      data={performers}
                      handleModalShowHide={handleModalShowHide}
                      handleLogShowHide={handleLogShowHide}
                      roleType={loggedUser && loggedUser.roleType}
                    ></WfPerformerTable> */}
              </div>
              {/* <Pagination
                    currentPage={currentPage}
                    pageSize={pageSize}
                    itemsCount={rowsCount}
                    onPageChange={handlePageChange}
                    startIndex={startIndex}
                    endIndex={endIndex}
                  ></Pagination> */}
            </Tab.Content>
          </Tab>
          <Tab eventKey="addedituser" title={`${selectedUserId === 0 ? "Add" : "Edit"} User`} tabClassName="page-tab border border-left-0 border-top-0">
            <Tab.Content className="border p-3 border-top-0">
              <AddPartnerPerformer selectedObj={selectedObj} currentUser={currentUser} handleFormSubmit={handleFormSubmit} handlePermissionShowHide={handlePermissionShowHide} />
            </Tab.Content>
          </Tab>
          <Tab eventKey="status" title="Activate/Deactivate" tabClassName="page-tab border border-left-0 border-right-0 border-top-0">
            <Tab.Content className="border p-3 border-top-0">
              {selectedUserId > 0 ?
                <ActiveDeactiveHistory selectedObj={selectedObj} type="performer" typeName="user" actionType={actionType} handlePermissionShowHide2={handlePermissionShowHide2} /> :
                <div className="col-md-12">
                  <h3 className="text-danger mt-1"> Alert : Please select the User first</h3>
                </div>
              }
            </Tab.Content>
          </Tab>

        </Tabs>
      </div>


      {/* <AddPartnerPerformer
        showHide={showHide}
        handleModalShowHide={handleModalShowHide}
        handleFormSubmit={handleFormSubmit}
        selectedObj={selectedObj}
      ></AddPartnerPerformer>

      <ActiveDeactiveHistory
        logShowHide={logShowHide}
        handleLogShowHide={handleLogShowHide}
        selectedObj={selectedObj}
        type="performer"
        pageType="User"
        actionType={statusActionType}
      ></ActiveDeactiveHistory> */}
    </React.Fragment>
  );
}
