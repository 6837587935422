import React, { Component, useEffect, useState } from "react";
import Moment from "react-moment";
import { Popover, Button, OverlayTrigger, Tabs, Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import http from "../../services/HttpService";
import config from "../../config.json";
import TemplatePopup from "../TemplatePopup";
import ViewForm from "./ViewForm";
import { useDispatch, useSelector } from "react-redux";
import {
  addAppTemplate, getAppTemplateAddedStatus, getAppTemplateSentStatus, getAppTemplateUpdatedStatus,
  getAppTemplates, loadAppTemplates, sentAppTemplate, updateAppTemplate
} from "../../app/store/template";
import { getAlert } from "../../services/appAlerts";
import ViewFormModal from "../../ReviewTools/ViewFormModal";

const TemplateLibrary = (props) => {
  const { workflowTask, setViewBprmFormShowHide,handleUpdateComponent } = props;

  const [appTemplates, setAppTemplates] = useState([]);
  const [selectedKey, setSelectedKey] = useState("home");
  const [selectedTempId, setSelectedTempId] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [viewFormShowHide, setViewFormShowHide] = useState(false);
  const [updateCompStatus, setUpdateCompStatus] = useState(false);

  const appTemplatesSelector = useSelector(getAppTemplates);
  const appTemplateAddedStatus = useSelector(getAppTemplateAddedStatus);
  const appTemplateUpdatedStatusSelector = useSelector(getAppTemplateUpdatedStatus);
  const appTemplateSentStatusSelector = useSelector(getAppTemplateSentStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (workflowTask?.ciid) {
      dispatch(loadAppTemplates(workflowTask.ciid))
      setUpdateCompStatus(true);
    }

  }, []);

  useEffect(() => {
    if (appTemplatesSelector.length > 0) {
      setAppTemplates(appTemplatesSelector);
      let tempItems = "";
      appTemplatesSelector.map((x, key) => {
        if (key > 0) tempItems += ",";
        tempItems += x.id;
      })
      if (updateCompStatus) {
        handleUpdateComponent(tempItems);
        setUpdateCompStatus(false);
      }
    }
  }, [appTemplatesSelector]);

  useEffect(() => {
    if (appTemplateAddedStatus) {
      getAlert("", "Added Successfully", "success");
      if (workflowTask?.ciid) {
        dispatch(loadAppTemplates(workflowTask.ciid));
        setUpdateCompStatus(true);
      }

      setSelectedTempId(0);
      setSelectedTemplate(null);
      setSelectedKey("home");
    }
  }, [appTemplateAddedStatus]);

  useEffect(() => {
    if (appTemplateUpdatedStatusSelector) {
      getAlert("", "Updated Successfully", "success");
      if (workflowTask?.ciid)
        dispatch(loadAppTemplates(workflowTask.ciid));

      setSelectedTempId(0);
      setSelectedTemplate(null);
      setSelectedKey("home");
    }
  }, [appTemplateUpdatedStatusSelector]);

  useEffect(() => {
    if (appTemplateSentStatusSelector) {
      getAlert("", "Sent Successfully", "success");
      if (workflowTask?.ciid)
        dispatch(loadAppTemplates(workflowTask.ciid));
    }
  }, [appTemplateSentStatusSelector]);

  const handleSubmit = async (data) => {
    const frmData = new FormData();
    frmData.append("template", JSON.stringify(data));
    frmData.append("workflowTask", JSON.stringify(workflowTask));
    if (selectedTempId > 0) dispatch(updateAppTemplate(frmData))
    else dispatch(addAppTemplate(frmData));
  };

  const handleSelectTab = (tab) => {
    setSelectedKey(tab);
  }

  const handleResetSelection = () => {
    setSelectedTempId(0);
    setSelectedTemplate(null);
  }

  const handleSelectTemplate = (tmp) => {
    const obj = { ...tmp, formId: tmp.templateId };
    setSelectedTemplate(obj);
    setSelectedTempId(tmp.id);
  }

  const handlePrintTemplate = (item) => {
    navigate("/PrintTemplate", { state: { item: item } });
  }

  const handleViewFormShowHide = (tmp) => {
    setViewFormShowHide(!viewFormShowHide);
    setViewBprmFormShowHide(!viewFormShowHide);
    const obj = { ...tmp, formId: tmp.templateId };
    setSelectedTemplate(obj);
  };

  const getPopover = (notes) => {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h4">Notes</Popover.Title>
        <Popover.Content>{notes}</Popover.Content>
      </Popover>
    );
    return (
      <React.Fragment>
        <OverlayTrigger trigger="click" placement="top" overlay={popover}>
          <button className="btn btn-link btn-sm p-0 ">
            View
          </button>
        </OverlayTrigger>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {/* <button
        type="button"
        className="btn btn-sm btn-default mb-2"
        onClick={() => this.handleModalShowHide("Email")}
      >
        <i className="fa fa-plus mr-2" aria-hidden="true" />
        Email Template
      </button>
      <button
        type="button"
        className="btn btn-sm btn-default mb-2"
        onClick={() => this.handleModalShowHide("Letter")}
      >
        <i className="fa fa-plus mr-2" aria-hidden="true" />
        Letter Template
      </button> */}

      <Tabs
        activeKey={selectedKey}
        onSelect={handleSelectTab}
        id="uncontrolled-tab-example"
        className="h3 border border-right-0 border-left-0 border-top-0 font-weight-normal"
      >
        <Tab eventKey="home" title="Templates" tabClassName="page-tab border border-left-0 border-top-0">
          <Tab.Content className="border border-top-0">
            <div className="table-responsive">
              {/* <button className="btn btn-sm btn-link" onClick={handleResetSelection}>Reset Selection</button> */}
              <table className="table table-striped align-items-center table-flush">
                <thead className="thead-light-green">
                  <tr>
                    <th>Select<span style={{ cursor: "pointer" }} onClick={handleResetSelection}>
                      <i className="fa fa-refresh ml-2 text-primary" aria-hidden="true"></i>
                    </span></th>
                    <th>S.No</th>
                    <th>Date & Time</th>
                    <th>Notes</th>
                    <th>Type</th>
                    <th>Name</th>
                    <th>View</th>
                    <th>Added By</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {appTemplates.map((item, key) => (
                    <tr>
                      <td>
                        <div className="custom-control custom-radio">
                          <input
                            className="custom-control-input"
                            type="radio"
                            id={item.id}
                            name="subcontractor"
                            onChange={() => handleSelectTemplate(item)}
                            value={selectedTempId}
                            checked={selectedTempId === item.id}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={item.id}
                          >
                          </label>
                        </div>
                      </td>
                      <td>{key + 1}</td>
                      <td>
                        <Moment format="MMMM Do YYYY">
                          {item.addedDatetime}
                        </Moment>
                        <br />
                        <Moment format="h:mm a">
                          {item.addedDatetime}
                        </Moment>
                      </td>
                      <td>{getPopover(item.notes)}</td>
                      <td>{item.templateType}</td>
                      <td>{item.templateName}</td>
                      <td>
                        <button className="btn btn-link btn-sm p-0"
                          onClick={() => handleViewFormShowHide(item)}>
                          
                          View
                        </button>
                      </td>
                      <td>
                        {item.performer}
                        <br />
                        {item.email}
                        <br />
                        {item.role}
                        {item.department}
                        {item.partner}
                      </td>

                      <td>
                        <button
                          onClick={() => handlePrintTemplate(item)}
                          className="btn btn-link btn-sm p-0"
                        >
                          <i className="fa fa-download mr-1"></i>Download
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Tab.Content>
        </Tab>

        {/* <Tab eventKey="contact" title="View Template" tabClassName="page-tab border border-left-0 border-top-0">
          <Tab.Content className="border border-top-0 p-3">
            {selectedTempId === 0 && <div className="">
              <h3 className="text-danger mt-1"> Alert : Please select the Template first</h3>
            </div>}
            {selectedTempId > 0 &&
             <ViewForm
             workflowTask={workflowTask}
             selectedForm={selectedTemplate}
           ></ViewForm>}
          </Tab.Content>
        </Tab> */}

        <Tab eventKey="profile" title={`${selectedTempId > 0 ? "Edit" : "Add"} Template`} tabClassName="page-tab border border-left-0 border-right-0 border-top-0">
          <Tab.Content className="border border-top-0 p-3">
            <TemplatePopup
              selectedTemplate={selectedTemplate}
              handleSubmit={handleSubmit}
            ></TemplatePopup>
          </Tab.Content>

        </Tab>

      </Tabs>

      <ViewFormModal
        viewFormShowHide={viewFormShowHide}
        handleViewForm={handleViewFormShowHide}
        workflowTask={workflowTask}
        selectedForm={selectedTemplate}
      ></ViewFormModal>

    </React.Fragment>
  );
}

export default TemplateLibrary;
