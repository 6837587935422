import React, { useState } from "react"
import { getWfPhasesColor } from "../../services/appService"
import Moment from "react-moment"
import TaskHistory from "../../components/TaskHistory";
import TaskReAssignPopup from "../../components/TaskReAssignPopup";

export default function TaskInfo(props) {
    const { wfTask, app,handleReAssignSubmit,handleShowHideSection } = props;

    const [historyAppShowHide, setHistoryAppShowHide] = useState(false);
    const [reAssignShowHide, setReAssignShowHide] = useState(false);

    const handlehistoryAppModalShowHide = () => {
        if(!historyAppShowHide) setReAssignShowHide(false);
        setHistoryAppShowHide(!historyAppShowHide);
        handleShowHideSection(!historyAppShowHide)
    };

    const handleReAssignModalShowHide = () => {
        if(!reAssignShowHide) setHistoryAppShowHide(false);
        setReAssignShowHide(!reAssignShowHide);
        handleShowHideSection(!reAssignShowHide)
      };

    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table align-items-center table-hover table-striped border bg-white">
                    <thead className="thead-light">
                        <tr>
                            <th>Task</th>
                            <th>Start (Date & Time)</th>
                            <th>End (Date & Time)</th>
                            <th>Assigned To</th>
                            <th>Re-Assign Task</th>
                            <th>Task Status</th>
                            <th>Task History</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ whiteSpace: "pre-wrap" }}>
                                {wfTask.itemName}
                            </td>
                            <td>

                                <Moment format="MMM Do YYYY, h:mm a">
                                    {wfTask.startDate}
                                </Moment>
                            </td>
                            <td>
                                {wfTask?.endDate && <Moment format="MMM Do YYYY, h:mm a">
                                    {wfTask.endDate}
                                </Moment>}
                            </td>

                            <td>{wfTask?.performerName}</td>
                            <td>
                                <button
                                    className="btn btn-sm btn-link p-0"
                                    onClick={() => handleReAssignModalShowHide(wfTask)}
                                >
                                    Re-Assign
                                </button>
                            </td>
                            <td>
                                {wfTask.status ? (
                                    <>
                                        Done
                                    </>
                                ) : (
                                    <button
                                        className={`btn btn-sm btn-min-width ${getWfPhasesColor(
                                            wfTask.wfPhase
                                        )}`}
                                    >
                                        {wfTask.wfPhase}
                                    </button>
                                )}
                            </td>
                            <td>
                                <button
                                    className="btn btn-sm btn-link p-0"
                                    onClick={() =>
                                        handlehistoryAppModalShowHide(wfTask)
                                    }
                                    title="View Task History"
                                >
                                    View
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {historyAppShowHide &&
                <TaskHistory
                    historyAppShowHide={historyAppShowHide}
                    handlehistoryAppModalShowHide={handlehistoryAppModalShowHide}
                    selectedObj={wfTask}
                    doneTasks={app.workflowTasks}
                ></TaskHistory>
            }
             <TaskReAssignPopup
                    reAssignShowHide={reAssignShowHide}
                    handleReAssignModalShowHide={handleReAssignModalShowHide}
                    selectedObj={wfTask}
                    handleReAssignSubmit={handleReAssignSubmit}
                ></TaskReAssignPopup>
        </React.Fragment>
    )
}