
import React, { useEffect, useState } from "react";
import { addDataTypeMapping, getAllDataTypes, getMappingAddedStatus, loadAllDataTypeMappings, loadAllDataTypes } from "../../store/dataTypes";
import { useDispatch, useSelector } from "react-redux";
import { getFieldData } from "../../../services/appService";
import { getCurrentUser } from "../../store/auth";
import { getAlert } from "../../../services/appAlerts";

const AddDataTypeMapping = (props) => {
    const { app, applicantObj, handleAddNewMapping } = props;

    const [allMapObjects, setAllMapObjects] = useState([]);
    const [showHideNewItem, setShowHideNewItem] = useState(false);
    const [allDataTypes, setAllDataTypes] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [selectedDataType, setSelectedDataType] = useState("");
    const [selectedAttribute, setSelectedAttribute] = useState("");
    const [selectedAttributeValue, setSelectedAttributeValue] = useState("");
    const [mapObjectDataTypes, setMapObjectDateTypes] = useState([]);
    const [fieldName, setFieldName] = useState("")
    const [fieldMapWith, setFieldMapWith] = useState("dataType");
    const [mappingName, setMappingName] = useState("");
    const [mappingNotes, setMappingNotes] = useState("");

    const allDataTypesSelector = useSelector(getAllDataTypes);
    const mappingAddedStatusSelector = useSelector(getMappingAddedStatus);

    const dispatch = useDispatch();
    const currentUser = getCurrentUser();

    useEffect(() => {
        dispatch(loadAllDataTypes());
    }, [])

    useEffect(() => {
        setAllDataTypes(allDataTypesSelector);
    }, [allDataTypesSelector])

    const handleAddNewField = () => {
        setShowHideNewItem(true);
    }

    const handeSaveNewItem = () => {
        const mapObjects = [...allMapObjects];
        let mapDDObj = [...mapObjectDataTypes];
        if (fieldMapWith === "id" && applicantObj) {
            let att = "";
            let vl = "";
            if (applicantObj.partnerType) {
                att = applicantObj.partnerType;
                vl = applicantObj.fuid;
            }
            else {
                att = applicantObj.registrationType;
                vl = applicantObj.id;
            }

            const newObj = {
                attType: att,
                attObj: "id",
                attValue: vl
            }

            mapDDObj = [newObj];
        }

        if (fieldMapWith === "date") {
            const newObj = {
                attType: "currentDate",
                attObj: "date",
                attValue: new Date().toDateString()
            }

            mapDDObj = [newObj];
        }
        const mapObj = {
            field: fieldName,
            replaceDataTypes: mapDDObj,
            replaceValue: ""
        }
        mapObjects.push(mapObj);
        setAllMapObjects(mapObjects);
        setShowHideNewItem(false);
        setMapObjectDateTypes([]);
        setFieldName("");
        setSelectedAttribute("");
        setSelectedAttributeValue("");
        setSelectedDataType("");
        setFieldMapWith("dataType");
    }

    const handleDataTypeChange = ({ target }) => {
        const findDD = allDataTypes.filter(x => x.id === parseInt(target.value));
        if (findDD.length > 0) {
            const jsData = JSON.parse(findDD[0].xsd);
            if (jsData) {
                let attList = [];
                jsData.map(d => {
                    const dd = d.split(".");
                    attList.push({ id: dd[0], name: dd[1] })
                })
                setAttributeList(attList);
            }
            setSelectedDataType(target.value);
        }
    }

    const handleAtributeChange = ({ target }) => {
        const findDD = allDataTypes.filter(x => x.id === parseInt(selectedDataType));
        if (findDD.length > 0 && app?.applicationData) {
            const attValue = getFieldData(app.applicationData, findDD[0].name.toLowerCase(), target.value);
            setSelectedAttributeValue(attValue);
        }
        setSelectedAttribute(target.value);
    }

    const handleSaveDataTypes = () => {
        const findDD = allDataTypes.filter(x => x.id === parseInt(selectedDataType));
        const mapDds = [...mapObjectDataTypes];
        const newObj = {
            attType: findDD[0].name,
            attObj: selectedAttribute,
            attValue: selectedAttributeValue
        }
        mapDds.push(newObj);
        setMapObjectDateTypes(mapDds);
        setSelectedDataType("");
        setSelectedAttribute("");
        setSelectedAttributeValue("");
    }

    const handleDeleteMapObject = (item) => {
        const index = mapObjectDataTypes.findIndex(x => x.attType === item.attType && x.attObj === item.attObj);
        const mapDds = [...mapObjectDataTypes];
        mapDds.splice(index, 1);
        setMapObjectDateTypes(mapDds);
    }

    const handleClose = () => {
        setAllMapObjects([]);
        handleAddNewMapping()
    }

    const handleMapWithChange = ({ currentTarget: input }) => {
        setFieldMapWith(input.value);
    }

    const handleSaveMapping = () => {
        let perfId = 0;
        let perfType = "";
        if (applicantObj.partnerType) {
            perfType = applicantObj.partnerType;
            perfId = applicantObj.fuid;
        }
        else {
            perfType = applicantObj.registrationType;
            perfId = applicantObj.id;
        }
        const modifiedMappingData = allMapObjects.map(x => {
            x.replaceDataTypes.map(d => {
                d.attValue = "";
                return d;
            })
            return x;
        })
        const obj = {
            name: mappingName,
            type: perfType,
            notes: mappingNotes,
            mappingJson: JSON.stringify(modifiedMappingData),
            addedBy: currentUser.id,
            performerId: perfId,
            performerType: perfType
        }
        dispatch(addDataTypeMapping(obj))
    }

    useEffect(() => {
        if (mappingAddedStatusSelector) {
            getAlert("", "Added Successfully", "success");
            dispatch(loadAllDataTypeMappings())
            setAllMapObjects([]);
            handleAddNewMapping()
        }
    }, [mappingAddedStatusSelector]);

    const handleCloseNewItem=()=>{
        setMapObjectDateTypes([]);
        setFieldName("");
        setFieldMapWith("dataType");
        setShowHideNewItem(false);
    }

    const handleDeleteMappItem=(item)=>{
        const ddMapps=[...allMapObjects];
        const index=allMapObjects.findIndex(x=>x.field === item.field);
        if(index > -1){
            ddMapps.splice(index,1);
            setAllMapObjects(ddMapps);
        }
    }

    return <>
        {showHideNewItem && <div className="pt-3 border form-group" style={{ backgroundColor: "#f7fafc" }}>
            <div className="col-md-12 form-group">
                <label htmlFor="" className="form-control-label">Field</label>
                <input
                    id="txtField"
                    name="txtField"
                    type="text"
                    className="form-control"
                    value={fieldName}
                    onChange={({ currentTarget }) => setFieldName(currentTarget.value)}
                />
            </div>
            <div className="col-md-12 d-flex form-group">
                <div className="custom-control custom-radio mr-3">
                    <input
                        className="custom-control-input"
                        type="radio"
                        name="mapWith"
                        id="mapWithId"
                        onChange={handleMapWithChange}
                        value="id"
                        checked={fieldMapWith === "id"}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="mapWithId"
                    >
                        Map with ID
                    </label>
                </div>
                <div className="custom-control custom-radio mr-3">
                    <input
                        className="custom-control-input"
                        type="radio"
                        name="mapWith"
                        id="mapWithDate"
                        onChange={handleMapWithChange}
                        value="date"
                        checked={fieldMapWith === "date"}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="mapWithDate"
                    >
                        Map with Current Date
                    </label>
                </div>
                <div className="custom-control custom-radio">
                    <input
                        className="custom-control-input"
                        type="radio"
                        name="mapWith"
                        id="mapWithDataType"
                        onChange={handleMapWithChange}
                        value="dataType"
                        checked={fieldMapWith === "dataType"}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="mapWithDataType"
                    >
                        Map with DataType
                    </label>
                </div>
            </div>
            {fieldMapWith === "dataType" &&
                <div className="col-md-12">
                    <label htmlFor="" className="form-control-label">DataType Mapping</label>
                    <table className="table align-items-center table-flush table-hover table-striped border bg-white">
                        <thead className="thead-light">
                            <tr>
                                <th>DataType</th>
                                <th>Attribute</th>
                                <th>Value</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mapObjectDataTypes.map(item =>
                                <tr>
                                    <td>{item.attType}</td>
                                    <td>{item.attObj}</td>
                                    <td>{item.attValue}</td>
                                    <td>
                                        <button className="btn btn-sm btn-link"
                                            onClick={() => handleDeleteMapObject(item)}>Delete</button>
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td>
                                    <select
                                        name="ddType"
                                        id="ddType"
                                        className="form-control form-control-sm"
                                        onChange={handleDataTypeChange}
                                        value={selectedDataType}
                                    >
                                        <option value="">Select</option>
                                        {allDataTypes.map(x =>
                                            <option value={x.id}>{x.dataLabel}</option>
                                        )}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        name="ddAtt"
                                        id="ddAtt"
                                        className="form-control form-control-sm"
                                        onChange={handleAtributeChange}
                                        value={selectedAttribute}
                                    >
                                        <option value="">Select</option>
                                        {attributeList.map(x =>
                                            <option value={x.id}>{x.name}</option>
                                        )}
                                    </select>
                                </td>
                                <td>{selectedAttributeValue}</td>
                                <td>
                                    <button 
                                    className="btn btn-link btn-sm" 
                                    onClick={handleSaveDataTypes}>
                                        Save</button>
                                    <button 
                                    className="btn btn-link btn-sm" 
                                    onClick={handleSaveDataTypes}>
                                        Add New</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
            <div className="col-md-12 text-center form-group">
                
                <button className="btn btn-sm btn-outline-danger"
                    onClick={handleCloseNewItem}>
                    <i className="fa fa-times" aria-hidden="true"></i> Close</button>
                <button className="btn btn-sm btn-outline-default"
                    onClick={handeSaveNewItem}>
                    <i className="fa fa-check" aria-hidden="true"></i> Save</button>
            </div>
        </div>}
        <div className="row">
            {!showHideNewItem &&
                <div className="col-md-12">
                    <button className="btn btn-link btn-sm float-right mr-0 pr-0" onClick={handleAddNewField}>Add New Field</button>
                </div>}
            <div className="col-md-12 form-group">
                <div className="table-responsive bg-white">
                    <table className="table align-items-center table-flush table-hover table-striped border">
                        <thead className="thead-light">
                            <tr>
                                <th>Field</th>
                                <th>DataTypes</th>
                                <th>Replace Value</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allMapObjects.map((item) => (
                                <tr>
                                    <td>{item.field}</td>
                                    <td>
                                        {item.replaceDataTypes.map(m =>
                                            <p>{m.attType}.{m.attObj}</p>
                                        )}
                                    </td>
                                    <td>
                                        {item.replaceDataTypes.map(m =>
                                            <p>{m.attValue}</p>
                                        )}
                                    </td>
                                    <td>
                                        <button className="btn btn-link btn-sm"
                                            onClick={() => handleDeleteMappItem(item)}>Delete</button>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-md-12 form-group">
                <label htmlFor="mapName" className="form-control-label">Mapping Name</label>
                <input type="text"
                    id="mapName"
                    name="mapName"
                    className="form-control"
                    value={mappingName}
                    onChange={({ currentTarget }) => setMappingName(currentTarget.value)}
                />
            </div>
            <div className="col-md-12 form-group">
                <label htmlFor="mapNotes" className="form-control-label">Notes</label>
                <textarea cols={2} rows={2}
                    className="form-control"
                    id="mapNotes"
                    name="mapNotes"
                    value={mappingNotes}
                    onChange={({ currentTarget }) => setMappingNotes(currentTarget.value)}
                ></textarea>
            </div>
            <div className="col-md-12 text-center form-group">
                {/* <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={handleClose}>
                    <i className="fa fa-times" aria-hidden="true"></i> Close
                </button> */}
                <button className="btn btn-sm btn-outline-default"
                    disabled={allMapObjects.length === 0}
                    onClick={handleSaveMapping}>
                    <i className="fa fa-check" aria-hidden="true"></i> Save Mapping
                </button>
            </div>
        </div>
    </>
}

export default AddDataTypeMapping;