import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="col-12 row">
          <div className="col-lg-6">
            <div className="copyright text-center text-lg-left text-muted">
              © 2019{" "}
              <a
                href="https://www.creative-tim.com"
                className="font-weight-bold ml-1"
              >
                WorkFinity
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <ul className="nav nav-footer justify-content-center justify-content-lg-end">
              <li className="nav-item">
                <a href="https://www.creative-tim.com" className="nav-link">
                  WorkFinity
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.creative-tim.com/presentation"
                  className="nav-link"
                >
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a href="http://blog.creative-tim.com" className="nav-link">
                  Blog
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.creative-tim.com/license"
                  className="nav-link"
                >
                  License
                </a>
              </li>
            </ul>
          </div>
        </div>
    );
  }
}

export default Footer;
