import React, { Component } from "react";
import Form from "../../comman/form";
import { Modal, Button } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import Joi from "joi-browser";
import { toast, cssTransition } from "react-toastify";
import ToastTemplate from "../../comman/toastTemplate";
import Swal from "sweetalert2";
import { result } from "lodash";
import ViewForm from "../PortalForms/ViewForm";
import { Link } from "react-router-dom";
import PrintPurchaseOrder from "../PrintPurchaseOrder";
import Moment from "react-moment";

class PurchaseOrderForms extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      documents: [],
      stateList: [],
      engageDuration: [],
      engageMode: [],
      repositoryDocs: [],
      reqDocuments: [],
      formLibraryList: [],
      selectedDocs: [],
      selectedForms: [],
      documentTypes: [
        {
          id: 1,
          name: "ACH Information",
        },
        {
          id: 2,
          name: "Insurance Information",
        },
        {
          id: 3,
          name: "Purchase Order",
        },
      ],
      cannedMessages: [],
      formShowHide: false,
      selectedObj: "",
      genShowHide: false,
      poType: "",
      poAction: "",
      purchaseOrders: [],
    };
  }

  schema = {};

  async componentDidMount() {
    this.loadPurchaseOrder();
    // const { data: formLibraryList } = await http.get(
    //   config.GetFormsByType + "Library Form"
    // );
    // const { data: cannedMessages } = await http.get(
    //   config.GetCodemanager + "Canned Message"
    // );
    // this.setState({ formLibraryList, cannedMessages });
  }

  loadPurchaseOrder = async () => {
    const { candidateObj } = this.props;
    //console.log(candidateObj);
    if (candidateObj !== undefined && candidateObj !== "") {
      const { data: purchaseOrders } = await http.get(
        config.GetPurchaseOrders + candidateObj.performerid
      );
      this.setState({ purchaseOrders });
    }
  };

  doSubmit = () => {
    const { data, selectedForms } = this.state;
    const { workflowTask } = this.props;
    selectedForms.map(async (form) => {
      const frmData = new FormData();
      frmData.append("workflowTask", JSON.stringify(workflowTask));
      frmData.append("formId", form.id);
      frmData.append("formName", form.formTitle);
      frmData.append("remarks", data["remarks"]);
      frmData.append("id", form.id);
      frmData.append("formType", "Purchase Order");
      const { data: result } = await http.post(config.AddFormLibrary, frmData);
    });
    this.props.handleToolShowHide("");
    this.getAlert("Sent", "Purchase order sent", "success");
  };

  getAlert(title, message, type) {
    return Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: "Close",
      confirmButtonColor: "#d33",
    });
  }

  componentWillReceiveProps = async (nextProps) => {
    //const { candidateObj } = nextProps;
  };

  handleSelectDoc = ({ currentTarget }) => {
    const { selectedForms, formLibraryList } = this.state;
    const id = currentTarget.name.split("_")[1];
    const index = selectedForms.findIndex((x) => x.id === parseInt(id));
    if (currentTarget.checked) {
      const obj = formLibraryList.filter((x) => x.id === parseInt(id));
      if (obj.length > 0) selectedForms.push(obj[0]);
    } else {
      selectedForms.splice(index, 1);
    }
    //console.log(selectedDocs);
    this.setState({ selectedForms });
  };

  handleCannedMessage = ({ currentTarget }) => {
    const { data } = this.state;
    data["remarks"] = currentTarget.value;
    this.setState({ data });
  };

  handleFormShowHide = (obj, type) => {
    if (obj === "save") {
      this.setState({ formShowHide: false, selectedObj: "" });
    } else {
      this.setState({
        formShowHide: !this.state.formShowHide,
        selectedObj: obj,
      });
    }
  };

  handleGenShowHide = (type, action) => {
    if (type === "save") {
      this.setState({
        genShowHide: false,
        poType: "",
        poAction: "",
      });
      this.loadPurchaseOrder();
    } else {
      this.setState({
        genShowHide: !this.state.genShowHide,
        poType: type,
        poAction: action,
      });
    }
  };

  // handleDownloadFile = async (item, type) => {
  //   const { data } = await http.get(
  //     config.GetPoOrderDocument + item.id + "&type=" + type,
  //     {
  //       responseType: "blob",
  //     }
  //   );

  //   if (data.size > 0) {
  //     const url = window.URL.createObjectURL(data);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.target = "_blank";
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //   }
  // };

  handleDownloadFile = async (item, type) => {
    const { data } = await http.get(
      config.GetPoOrderDocument + item.id + "&type=" + type,
      {
        responseType: "blob",
      }
    );

    if (data.size > 0) {
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };

  handleSendPoOrder = async (id) => {
    const { data } = await http.get(config.SendPoOrder + id);
    this.getAlert("", "Sent Successfully", "success");
    this.loadPurchaseOrder();
  };

  handleDeletePoOrder = (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure you want to delete this purchase order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Yes",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data } = await http.delete(config.DeletePoOrder + id);
        this.getAlert("", "Deleted Successfully", "success");
        this.loadPurchaseOrder();
      }
    });
  };

  render() {
    const {
      formLibraryList,
      cannedMessages,
      formShowHide,
      selectedObj,
      genShowHide,
      purchaseOrders,
      poType,
      poAction,
    } = this.state;
    const { handleToolShowHide, workflowTask, candidateObj } = this.props;

    const allPoTypes = [];
    if (candidateObj.subcontractor !== null) {
      allPoTypes.push({
        id: candidateObj.subcontractor.fuid,
        name: "Subcontractor",
        value: "subcontractor",
      });
    }
    if (candidateObj.recruiter !== null) {
      allPoTypes.push({
        id: candidateObj.recruiter.fuid,
        name: "Recruiter",
        value: "recruiter",
      });
    }
    //console.log(purchaseOrders);
    return (
      <React.Fragment>
        <div className="col-md-12">
          <button
            className="btn btn-default mb-2"
            onClick={() => this.handleGenShowHide("", "generate")}
          >
            Add New Purchase Order
          </button>
          <button
            className="btn btn-default mb-2"
            onClick={() => this.handleGenShowHide("", "upload")}
          >
            Upload Purchase Order
          </button>
        </div>
        <div className="col-md-12">
          <div className="table-responsive bg-white">
            <table className="table align-items-center table-flush table-hover table-striped">
              <thead className="thead-light">
                <tr>
                  <th>S.No.</th>
                  <th>Id</th>

                  {/* <th>Type</th> */}
                  <th>Download/View</th>
                  <th>Signed</th>
                  <th>Action</th>
                  <th>Date & Time Sent</th>
                </tr>
              </thead>
              <tbody>
                {purchaseOrders.map((item, key) => (
                  <tr>
                    <td>{key + 1}</td>
                    <td>{item.id}</td>

                    {/* <td>{item.type}</td> */}
                    <td>
                      <button
                        className="btn btn-link btn-sm p-0 text-underline"
                        onClick={() => this.handleDownloadFile(item, "unSign")}
                      >
                        View
                      </button>
                    </td>
                    <td>
                      {item.partnerSign && (
                        <button
                          className="btn btn-link btn-sm p-0 text-underline"
                          onClick={() =>
                            this.handleDownloadFile(item, "signed")
                          }
                        >
                          Download/View
                        </button>
                      )}

                      {item.partnerSign === null && (
                        <button className="btn btn-link btn-sm p-0">
                          Pending
                        </button>
                      )}
                    </td>
                    <td>
                      {item.sendStatus ? (
                        <button className="btn btn-link btn-sm p-0">
                          Sent
                        </button>
                      ) : (
                        <button
                          className="btn btn-link btn-sm p-0 text-underline"
                          onClick={() => this.handleSendPoOrder(item.id)}
                        >
                          Send
                        </button>
                      )}
                      <button
                        className="btn btn-link btn-sm p-0 text-underline"
                        onClick={() => this.handleDeletePoOrder(item.id)}
                      >
                        Delete
                      </button>
                    </td>
                    <td>
                      <Moment format="MMM Do YYYY, h:mm a">
                        {item.addedDateTime}
                      </Moment>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="col-md-12 form-group">
          <label htmlFor="cannedMessage" className="form-control-label">
            Select Canned Message
          </label>
          <select
            name="cannedMessage"
            id="cannedMessage"
            className="form-control"
            onChange={this.handleCannedMessage}
          >
            <option value="">Select</option>
            {cannedMessages.map((item) => (
              <option value={item.name}>{item.name}</option>
            ))}
          </select>
        </div>
        <div className="col-md-12">
          {this.renderTextArea("remarks", "Remarks")}
        </div> */}
        <div className="col-md-12 text-right">
          <Button variant="danger" onClick={() => handleToolShowHide("")}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={this.handleSubmit}>
            Send
          </Button> */}
        </div>

        <ViewForm
          formShowHide={formShowHide}
          handleFormShowHide={this.handleFormShowHide}
          workflowTask={workflowTask}
          selectedForm={selectedObj}
          type="purchase"
        ></ViewForm>

        <PrintPurchaseOrder
          genShowHide={genShowHide}
          handleGenShowHide={this.handleGenShowHide}
          candidateObj={candidateObj}
          workflowTask={workflowTask}
          poType={poType}
          poAction={poAction}
          allPoTypes={allPoTypes}
        ></PrintPurchaseOrder>
      </React.Fragment>
    );
  }
}

export default PurchaseOrderForms;
