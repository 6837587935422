import React, { Component } from "react";
import auth from "../../services/authService";
import http from "../../services/HttpService";
import config from "../../config.json";

class PartnerDashbaord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDashboard: {},
    };
  }

  async componentDidMount() {
    // const { data: userDashboard } = await http.get(
    //   config.GetUserDashboard + 37
    // );
    // this.setState({ userDashboard });
  }

  render() {
    const { userDashboard } = this.state;
    const { showHide } = this.props;
    return (
      <div>
        <div
          className={`header border-bottom pt-4 ${
            showHide ? "d-block" : "d-none"
          }`}
          style={{ backgroundColor: "#fafafa" }}
        >
          <div className="container-fluid">
            <div className="header-body">
              <div className="row">
                <div className="col-xl-3 col-md-6">
                  <div className="card card-stats shadow-none border">
                    <div className="card-body p-2">
                      <div className="row">
                        <div className="col d-flex align-items-center">
                          <h4 className="card-title text-uppercase mb-0">
                            Patient Certify/Recertify
                          </h4>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-lighter border rounded-circle">
                            <span className="h2 font-weight-bold mb-0">
                              {userDashboard.totalEnrollment}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card card-stats shadow-none border">
                    <div className="card-body p-2">
                      <div className="row">
                        <div className="col d-flex align-items-center">
                          <h4 className="card-title text-uppercase mb-0">
                            Patient Enrolled
                          </h4>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-lighter border rounded-circle">
                            <span className="h2 font-weight-bold mb-0">
                              {userDashboard.approvedEnrollment}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card card-stats shadow-none border">
                    <div className="card-body p-2">
                      <div className="row">
                        <div className="col d-flex align-items-center">
                          <h4 className="card-title text-uppercase mb-0">
                            Patient Pending Enrollment
                          </h4>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-lighter border rounded-circle">
                            <span className="h2 font-weight-bold mb-0">
                              {userDashboard.pendingEnrollment}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card card-stats shadow-none border">
                    <div className="card-body p-2">
                      <div className="row">
                        <div className="col d-flex align-items-center">
                          <h4 className="card-title text-uppercase mb-0">
                            Patient Caregiver Allowed
                          </h4>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-lighter border rounded-circle">
                            <span className="h2 font-weight-bold mb-0">
                              {userDashboard.cregiverEnrollment}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card card-stats shadow-none border">
                    <div className="card-body p-2">
                      <div className="row">
                        <div className="col d-flex align-items-center">
                          <h4 className="card-title text-uppercase mb-0">
                            Survey Received
                          </h4>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape rounded-circle bg-lighter border">
                            <span className="h2 font-weight-bold mb-0">
                              {userDashboard.surveyReceived}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card card-stats shadow-none border">
                    <div className="card-body p-2">
                      <div className="row">
                        <div className="col d-flex align-items-center">
                          <h4 className="card-title text-uppercase mb-0">
                            Survey Completed
                          </h4>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape rounded-circle bg-lighter border">
                            <span className="h2 font-weight-bold mb-0">
                              {userDashboard.surveyCompleted}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card card-stats shadow-none border">
                    <div className="card-body p-2">
                      <div className="row">
                        <div className="col d-flex align-items-center">
                          <h4 className="card-title text-uppercase mb-0">
                            Alerts/Messages
                          </h4>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape rounded-circle bg-lighter border">
                            <span className="h2 font-weight-bold mb-0">
                              {userDashboard.alertsMessages}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card card-stats shadow-none border">
                    <div className="card-body p-2">
                      <div className="row">
                        <div className="col d-flex align-items-center">
                          <h4 className="card-title text-uppercase mb-0">
                            Total Users
                          </h4>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape rounded-circle bg-lighter border">
                            <span className="h2 font-weight-bold mb-0">
                              {userDashboard.totalUsers}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerDashbaord;
