import React, { Component } from "react";
import { Line } from "react-chartjs-2";

export default class LineChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      colorMap :{},
      selectedColors : {}
    }
  }


  generateColor=()=> {
    return "#" + Math.random().toString(16).substr(-6);
  }

  newColorFind = id => {
    const {colorMap,selectedColors}=this.state;
    // If already generated and assigned, return
    if (colorMap[id]) return colorMap[id];
  
    // Generate new random color
    let newColor;
  
    do {
      newColor = this.generateColor();
    } while(selectedColors[newColor]);
  
    // Found a new random, unassigned color
    colorMap[id] = newColor;
    selectedColors[newColor] = true;
  
    // Return next new color
    return newColor;
  }

  render() {
    const { graphData } = this.props;
    let dtObj = [];
    let dtlbl = [];
    if (graphData.length > 0) {
      graphData.map((item,key) => {
        dtlbl = item.label;
        const clr = this.newColorFind(key);
        const obj = {
          label: item.type,
          fill: false,
          backgroundColor: clr,
          borderColor: clr,
          data: item.data,
        };
        dtObj.push(obj);
      });
    }
    const data = {
      labels: dtlbl,
      datasets: dtObj,
    };

    return (
      <div className="chart">
        <Line data={data} options={{ maintainAspectRatio: false }} />
      </div>
    );
  }
}
