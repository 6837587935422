import React, { useState } from "react"
import Moment from "react-moment"
import { getFieldData } from "../../services/appService"
import Tools from "../../services/toolsJson.json";
import AddEditClient from "../../components/OMCRegistry/AddEditClient";
import AddEditNotes from "../../components/OMCRegistry/AddEditNotes";


export default function ClientAgencyInfo(props) {
    const { candidateObj, item, handleUpdateAgencyProjectInfo, appDocuments } = props;

    const [clientShowHide, setClientShowHide] = useState(false);
    const [noteShowHide, setNoteShowHide] = useState(false);
    const [editType, setEditType] = useState("");
    const [notesType, setNotesType] = useState("");
    const [timeLogObj, setTimeLogObj] = useState("");

    const handleClientShowHide = (type, objData) => {
        if (objData !== "" && objData !== "fail") {
            const objItem = { ...candidateObj };
            objItem.performerData = JSON.stringify(objData);
            //setSelectedItem(objItem);
            handleUpdateAgencyProjectInfo();
            setClientShowHide(false);
            setEditType("");
        } else {
            setClientShowHide(!clientShowHide);
            setEditType(type);
        }
    };

    const handleNoteShowHide = (type, obj) => {
        let tp = type;
        if (type === "save") {
            setNotesType("");
            setNoteShowHide(false);
        }else{
            setNoteShowHide(!noteShowHide);
            setNotesType(tp);
        }
    };

    const handleDownloadFile = async (item) => {
        // const { data } = await http.get(config.DownloadDocument + item.docId, {
        //   responseType: "blob",
        // });
        // if (data.size > 0) {
        //   const url = window.URL.createObjectURL(data);
        //   const link = document.createElement("a");
        //   link.href = url;
        //   link.target = "_blank";
        //   link.setAttribute("download", item.docName);
        //   document.body.appendChild(link);
        //   link.click();
        //   link.parentNode.removeChild(link);
        // }
      };
      let clientComp = "";
      const clientTools = Tools.filter(x => x.id === 39);
      if (clientTools.length > 0)
        clientComp = clientTools[0];

    return (
        <React.Fragment>
            <div className="card shadow-none border">
                <div className="card-header p-2">
                    <div className="row">
                        <div className="col-md-6">
                            <button className="btn btn-sm btn-link text-uppercase pl-0" data-toggle="collapse"
                                data-target="#clientCollapse">
                                Agency/Client Details
                            </button>
                        </div>

                        <div className="col-md-6 text-right">
                            {/* <button
                                className="btn btn-sm btn-link p-0"
                                onClick={() => handleToolShowHide(39, "client")}
                            >
                                Upload
                            </button> */}
                            <button
                                className="btn btn-sm btn-link p-0"
                                onClick={() => handleClientShowHide("client", "")}
                            >
                                Edit
                            </button>
                            {/* <button
                                className="btn btn-sm btn-link p-0"
                                onClick={() => handleNoteShowHide("client", "")}
                            >
                                Notes
                            </button> */}
                        </div>
                    </div>
                </div>
                <div className="card-body p-3 collapse" id="clientCollapse">
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <th style={{ width: "20%" }}>Name</th>
                                <td>
                                    {getFieldData(
                                        item.performerData,
                                        "projectengagement",
                                        "AgencyName"
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ width: "20%" }}>Address</th>
                                <td>
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "Address1"
                                    )}
                                    {", "}
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "Address2"
                                    )}
                                    {", "}
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "State"
                                    )}
                                    {", "}
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "City"
                                    )}
                                    {", "}
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "Zip"
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table table-bordered table-sm mt-2 mb-2">
                        <tbody>
                            <tr>
                                <th>Contact Details 1</th>
                                <th>Contact Details 2</th>
                            </tr>
                            <tr>
                                <td>
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "AgencyContact"
                                    )}
                                    <br />
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "AgencyTitle"
                                    )}
                                    <br />
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "AgencyEmail"
                                    )}
                                    <br />
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "AgencyPhone"
                                    )}
                                </td>
                                <td>
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "Contactname2"
                                    )}
                                    <br />
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "ContactTitle2"
                                    )}
                                    <br />
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "ContactEmail2"
                                    )}
                                    <br />
                                    {getFieldData(
                                        candidateObj.performerData,
                                        "projectengagement",
                                        "ContactPhone2"
                                    )}
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <ul
                        className="list-group"
                        style={{ maxHeight: "150px", overflow: "auto" }}
                    >
                        {appDocuments
                            .filter((x) => x.uploadType === "client")
                            .map((doc) => (
                                <li className="list-group-item p-1">
                                    <span className="p-2">
                                        <Moment format="MMM Do YYYY">
                                            {doc.dateTime}
                                        </Moment>
                                    </span>
                                    <button
                                        className="btn btn-sm btn-link p-0 text-underline"
                                        onClick={() => handleDownloadFile(doc)}
                                    >
                                        {doc.docName}
                                    </button>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
            <AddEditClient
                clientShowHide={clientShowHide}
                handleClientShowHide={handleClientShowHide}
                candidateObj={candidateObj}
                getFieldData={getFieldData}
                editType={editType}
            ></AddEditClient>
            <AddEditNotes
                noteShowHide={noteShowHide}
                handleNoteShowHide={handleNoteShowHide}
                notesType={notesType}
                candidateObj={candidateObj}
                timeLogObj={timeLogObj}
                selectedObj={item}
            ></AddEditNotes>
        </React.Fragment>
    )
}