import React, { Component, useEffect, useState } from "react";
import MsgWithApplicantPopup from "./MsgWithApplicantPopup";
import Moment from "react-moment";
import MessagingPopup from "./MessagingPopup";
import http from "../../services/HttpService";
import config from "../../config.json";
import auth from "../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { addAppNewMessage, getAddedMessageStatus, getAppMessages, loadAppMessages } from "../../app/store/messages";
import { Tab, Tabs } from "react-bootstrap";
import { getAlert } from "../../services/appAlerts";
import { getCurrentUser } from "../../app/store/auth";
import { getComponentUpdateStatus } from "../../app/store/tools";

const MessageWithApplicant = (props) => {
  const { workflowTask, handleUpdateComponent } = props;
  const [appMessages, setAppMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [selectedKey, setSelectedKey] = useState("home");
  const [selectedMsgId, setSelectedMsgId] = useState(0);
  const [updateCompStatus, setUpdateCompStatus] = useState(false);

  const appMessagesSelector = useSelector(getAppMessages);
  const addedMessageStatus = useSelector(getAddedMessageStatus);
  const componentUpdateStatusSelector=useSelector(getComponentUpdateStatus)

  const dispatch = useDispatch();
  const currentUser=getCurrentUser();

  useEffect(() => {
    if (workflowTask?.ciid > 0) {
      dispatch(loadAppMessages(workflowTask.ciid,currentUser.id));
    }
  }, []);

  useEffect(() => {
    if (appMessagesSelector.length > 0) {
      setAppMessages(appMessagesSelector);
      let msgItems = "";
      appMessagesSelector.filter(x=>x.senderRole === "new").map((com,pkey) => {
        if(pkey > 0) msgItems += ",";
        com.msgIds.map((x, key) => {
          if (key > 0) msgItems += ",";
          msgItems += x;
        })
      })
      if (updateCompStatus && msgItems !== "") {
        handleUpdateComponent(msgItems);
      }
      setUpdateCompStatus(false);
    }
  }, [appMessagesSelector]);

  useEffect(() => {
    if (addedMessageStatus && workflowTask?.ciid) {
      getAlert("", "Sent Successfully", "success");
      dispatch(loadAppMessages(workflowTask.ciid,currentUser.id));
      setUpdateCompStatus(true);
      setSelectedKey("home");
    }
  }, [addedMessageStatus])

  const handleAddNewMessage = async (Msg, docs, msgType) => {
    const wfTask = { ...workflowTask };
    wfTask.performerId = currentUser.id;
    const frmdata = new FormData();
    frmdata.append("task", JSON.stringify(wfTask));
    frmdata.append("Msg", Msg);
    frmdata.append("MsgType", msgType);
    docs.map((file, key) => frmdata.append("files[" + key + "]", file.file));
    dispatch(addAppNewMessage(frmdata));
  };

  const handleSelectTab = (tab) => {
    setSelectedKey(tab);
  }

  const handleResetSelection = () => {
    setSelectedMsgId(0);
    setSelectedMessage(null);
  }

  const handleSelectMessage = (msg) => {
    setSelectedMessage(msg);
    setSelectedMsgId(msg.id);
  }

  const handleReadStatus=(msgItems)=>{
    if(msgItems !== ""){
      handleUpdateComponent(msgItems);
      setUpdateCompStatus(true);
    }
  }

  useEffect(()=>{
    if(componentUpdateStatusSelector && updateCompStatus){
      if (workflowTask?.ciid > 0) {
        dispatch(loadAppMessages(workflowTask.ciid,currentUser.id));
        setUpdateCompStatus(false);
      }
    }
  },[componentUpdateStatusSelector])

  return (
    <React.Fragment>

      <Tabs
        activeKey={selectedKey}
        onSelect={handleSelectTab}
        id="uncontrolled-tab-example"
        className="h3 border border-right-0 border-left-0 border-top-0 font-weight-normal"
      >
        <Tab eventKey="home" title="Messages" tabClassName="page-tab border border-left-0 border-top-0">
          <Tab.Content className="border border-top-0">
            <div className="table-responsive">
              {/* <button className="btn btn-sm btn-link" onClick={handleResetSelection}>Reset Selection</button> */}
              <table className="table table-striped align-items-center table-flush">
                <thead className="thead-light-green">
                  <tr>
                    <th>Select <span style={{ cursor: "pointer" }} onClick={handleResetSelection}>
                      <i className="fa fa-refresh ml-2 text-primary" aria-hidden="true"></i>
                    </span></th>
                    <th>S.No.</th>
                    <th>Date & Time</th>
                    <th>Added By</th>
                    <th>Messages</th>
                  </tr>
                </thead>
                <tbody>
                  {appMessages.map((item) => (
                    <tr>
                      <td>
                        <td>
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input"
                              type="radio"
                              id={item.id}
                              name="subcontractor"
                              onChange={() => handleSelectMessage(item)}
                              value={selectedMsgId}
                              checked={selectedMsgId === item.id}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={item.id}
                            >
                            </label>
                          </div>
                        </td>
                      </td>
                      <td>{item.id}</td>
                      <td>
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.addedDateTime}
                        </Moment>
                      </td>
                      <td>
                        {item.createdBy}
                        <br />
                        {item.email}
                        <br />
                        {item.role}
                        {item.department}
                        {item.partner}
                      </td>
                      <td>
                        {item.unreadCount > 0 &&
                          <span className="badge badge-pill badge-danger">
                            {item.unreadCount}
                          </span>}
                        {item.readCount > 0 && item.unreadCount === 0 &&
                          <span className="badge badge-pill badge-success">
                            {item.readCount}
                          </span>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Tab.Content>
        </Tab>

        <Tab eventKey="msgList" title="View/Reply" tabClassName="page-tab border border-left-0 border-top-0">
          <Tab.Content className="border border-top-0 p-3">
            {selectedMsgId === 0 && <div className="">
              <h3 className="text-danger mt-1"> Alert : Please select the Message first</h3>
            </div>}
            {selectedMsgId > 0 && selectedKey === "msgList" &&
              <MessagingPopup
                message={selectedMessage}
                workflowTask={workflowTask}
                handleReadStatus={handleReadStatus}
              ></MessagingPopup>}
          </Tab.Content>
        </Tab>
        <Tab eventKey="profile" title="Add Message" tabClassName="page-tab border border-left-0 border-right-0 border-top-0">
          <Tab.Content className="border border-top-0 p-3">
            <MsgWithApplicantPopup
              handleAddNewMessage={handleAddNewMessage}
              workflowTask={workflowTask}
            ></MsgWithApplicantPopup>
          </Tab.Content>

        </Tab>
      </Tabs>

    </React.Fragment>
  );
}


export default MessageWithApplicant;
