import React, { Component } from "react";
import { Modal, Button, Card, Accordion } from "react-bootstrap";
import ScheduleMeeting from "../PortalForms/ScheduleMeeting";
import PhoneCallApplicant from "../PortalForms/PhoneCallApplicant";
import Document from "../PortalForms/Document";
import FormsLibrary from "../PortalForms/FormsLibrary";

class AdditionalCtrlPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: 0,
      accordianData: {},
      hideShowAll: false,
      documents: [],
    };
  }

  handleSelect = (index, name) => {
    const accordianData = { ...this.state.accordianData };
    const checkIndex = accordianData[name];
    if (checkIndex === index) {
      accordianData[name] = null;
      this.setState({ accordianData });
    } else {
      accordianData[name] = index;
      this.setState({ accordianData });
    }
  };

  render() {
    const { accordianData, notification, hideShowAll, documents } = this.state;
    const { ctrlShowHide, handleCtrlModalShowHide, selectedObj } = this.props;
    const bgColor = "bg-admin-light border-admin-left";
    const task = { ciid: 188 };
    return (
      <Modal show={ctrlShowHide} size="lg">
        <Modal.Header closeButton onClick={handleCtrlModalShowHide}>
          <Modal.Title>Additional Controls</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          <div className="row">
            <div className="col-md-12">
              <Accordion
                activeKey={
                  accordianData["mngDocuments"] === 2 || hideShowAll ? "2" : ""
                }
                onSelect={() => this.handleSelect(2, "mngDocuments")}
              >
                <Card className="shadow-none">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="2"
                    className={`p-3 card-header1 ${bgColor}`}
                    aria-expanded={
                      accordianData["mngDocuments"] === 2 || hideShowAll
                        ? "true"
                        : "false"
                    }
                  >
                    <h4 className="mb-0">
                      <i
                        className="fa fa-hand-o-right mr-2"
                        aria-hidden="true"
                      ></i>
                      Document - Send/Received
                      <span className="ml-3" style={{ position: "absolute" }}>
                        <i
                          class="fa fa-bell text-red"
                          aria-hidden="true"
                          style={{ fontSize: 20 }}
                        >
                          <span
                            className="text-white"
                            style={{
                              position: "absolute",
                              padding: "0px",
                              borderRadius: "10px",
                              fontSize: "12px",
                              bottom: "7px",
                              marginLeft: "-13px",
                              fontWeight: "bold",
                            }}
                          >
                            {notification}
                          </span>
                        </i>
                      </span>
                    </h4>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <Document documents={documents} workflowTask={task} />
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <hr />
              <Accordion
                activeKey={
                  accordianData["phoneCall"] === 4 || hideShowAll ? "4" : ""
                }
                onSelect={() => this.handleSelect(4, "phoneCall")}
              >
                <Card className="shadow-none">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="4"
                    className={`p-3 card-header1 ${bgColor}`}
                    aria-expanded={
                      accordianData["phoneCall"] === 4 || hideShowAll
                        ? "true"
                        : "false"
                    }
                  >
                    <h4 className="mb-0">
                      <i
                        className="fa fa-hand-o-right mr-2"
                        aria-hidden="true"
                      ></i>
                      Phone call recordings
                      <span className="ml-3" style={{ position: "absolute" }}>
                        <i
                          class="fa fa-bell text-red"
                          aria-hidden="true"
                          style={{ fontSize: 20 }}
                        >
                          <span
                            className="text-white"
                            style={{
                              position: "absolute",
                              padding: "0px",
                              borderRadius: "10px",
                              fontSize: "12px",
                              bottom: "7px",
                              marginLeft: "-13px",
                              fontWeight: "bold",
                            }}
                          >
                            {notification}
                          </span>
                        </i>
                      </span>
                    </h4>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4">
                    <PhoneCallApplicant workflowTask={task} />
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <hr />
              <Accordion
                activeKey={
                  accordianData["scheduleMeeting"] === 5 || hideShowAll
                    ? "5"
                    : ""
                }
                onSelect={() => this.handleSelect(5, "scheduleMeeting")}
              >
                <Card className="shadow-none">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="5"
                    className={`p-3 card-header1 ${bgColor}`}
                    aria-expanded={
                      accordianData["scheduleMeeting"] === 5 || hideShowAll
                        ? "true"
                        : "false"
                    }
                  >
                    <h4 className="mb-0">
                      <i
                        className="fa fa-hand-o-right mr-2"
                        aria-hidden="true"
                      ></i>
                      Schedule appointment
                      <span className="ml-3" style={{ position: "absolute" }}>
                        <i
                          class="fa fa-bell text-red"
                          aria-hidden="true"
                          style={{ fontSize: 20 }}
                        >
                          <span
                            className="text-white"
                            style={{
                              position: "absolute",
                              padding: "0px",
                              borderRadius: "10px",
                              fontSize: "12px",
                              bottom: "7px",
                              marginLeft: "-13px",
                              fontWeight: "bold",
                            }}
                          >
                            {notification}
                          </span>
                        </i>
                      </span>
                    </h4>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="5">
                    <ScheduleMeeting workflowTask={task} />
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <hr />
              <Accordion
                activeKey={
                  accordianData["formLibrary"] === 8 || hideShowAll ? "8" : ""
                }
                onSelect={() => this.handleSelect(8, "formLibrary")}
              >
                <Card className="shadow-none">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="8"
                    className={`p-3 card-header1 ${bgColor}`}
                    aria-expanded={
                      accordianData["formLibrary"] === 8 || hideShowAll
                        ? "true"
                        : "false"
                    }
                  >
                    <h4 className="mb-0">
                      <i
                        className="fa fa-hand-o-right mr-2"
                        aria-hidden="true"
                      ></i>
                      Forms Library
                      <span className="ml-3" style={{ position: "absolute" }}>
                        <i
                          class="fa fa-bell text-red"
                          aria-hidden="true"
                          style={{ fontSize: 20 }}
                        >
                          <span
                            className="text-white"
                            style={{
                              position: "absolute",
                              padding: "0px",
                              borderRadius: "10px",
                              fontSize: "12px",
                              bottom: "7px",
                              marginLeft: "-13px",
                              fontWeight: "bold",
                            }}
                          >
                            {notification}
                          </span>
                        </i>
                      </span>
                    </h4>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="8">
                    <FormsLibrary workflowTask={task}></FormsLibrary>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="danger" onClick={handleCtrlModalShowHide}>
            <i className="fa fa-times" aria-hidden="true"></i> Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AdditionalCtrlPopup;
