import React, { Component } from "react";
import Form from "../comman/form";
import auth from "../services/authService";
import http from "../services/HttpService";
import config from "../config.json";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
class AddEditYearlyRpt extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
    };
  }

  schema = {};
  componentWillUpdate() {}

  doSubmit = async () => {
    const {
      selectedObj: item,
      selectedData: data,
      selectedType: type,
      year,
      handleRptShowHide
    } = this.props;
    const user = auth.getCurrentUser();
    const objData = {
      id: item.reportId === null ? 0 : item.reportId,
      candidateId: item.candidateId,
      previousDue: item.previousDue,
      currentPaid: item.currentPaid,
      currentTotal: item.currentTotal,
      currentDue: item.currentDue,
      nextDue: item.nextDue,
      currentYear: year,
      previousYear: parseInt(year) - 1,
      nextYear: parseInt(year) + 1,
      addedBy: user.id,
      notes: this.state.data["notes"],
    };

    const { data: result } = await http.post(
      config.AddCandidateYearlyRpt,
      objData
    );
    this.getAlert("Saved Successfully", "", "success");
    handleRptShowHide("save");
  };

  getAlert(title, message, type) {
    return Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: "Close",
      confirmButtonColor: "#d33",
    });
  }

  render() {
    const { rptShowHide, handleRptShowHide,selectedObj } = this.props;
    console.log(selectedObj)
    return (
      <>
        <Modal show={rptShowHide} size="lg" onHide={handleRptShowHide}>
          <Modal.Header closeButton>
            <Modal.Title>Save Data</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-secondary">
            <div className="row">
              <div className="col-md-12">
                {this.renderTextArea("notes", "Notes")}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleRptShowHide}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={this.validate()}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddEditYearlyRpt;
