import React, { Component } from "react";
import ClientHeader from "../ClientHeader";
import Footer from "../Footer";
import PageHeader from "../PageHeader";
import MyPatientBody from "./MyPatientBody";

class ExistingPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: [{ name: "Quick Links", url: "/" }],
    };
  }

  render() {
    const { route } = this.state;
    return (
      <React.Fragment>
        <div className="container border p-0 mb-4 bg-white">
          <ClientHeader></ClientHeader>
          <PageHeader
            route={route}
            parentTitle="Existing Enrolled Patients"
            headerTitle="Existing Enrolled Patients"
          ></PageHeader>
          <div className="container-fluid">
            <MyPatientBody patientType="existing"></MyPatientBody>
          </div>
        </div>
        <Footer></Footer>
      </React.Fragment>
    );
  }
}

export default ExistingPatient;
