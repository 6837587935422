import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getAllMonths, getAllYears } from "../../../../../services/appService";
import { useDispatch, useSelector } from "react-redux";
import { getMonthlyPaySubcontractorCandidates, loadMonthlyPaySubcontractorCandidates } from "../../../../store/reports";
import { getSubcontractorCandidates, getSubcontractorPartners, loadSubcontractorCandidates, loadSubcontractorPartners } from "../../../../store/partner";
import GeneratePdf from "../../../../../components/GeneratePdf";

const SubcontractorCandidates = () => {

    const years = getAllYears();
    const months = getAllMonths();

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");

    const [pdfShowHide, setPdfShowHide] = useState(false);
    const [rptShowHide, setRptShowHide] = useState(false);
    const [selectedCol, setSelectedCol] = useState([]);
    const [selectedColData, setSelectedColData] = useState([]);

    const [partnerType,setPartnerType]=useState("Sub-Contractors");
    const [subcontractors, setSubcontractors] = useState([]);
    const [candidates, setCandidates] = useState([]);
    const [candidatePayRpt, setCandidatePayRpt] = useState([]);
    const [selectedCandidate,setSelectedCandidate]=useState(0);

    const subcontractorPartnersSelector=useSelector(getSubcontractorPartners);
    const subcontractorCandidatesSelector=useSelector(getSubcontractorCandidates);
    const monthlyPaySubcontractorCandidatesSelector=useSelector(getMonthlyPaySubcontractorCandidates);

    const dispatch=useDispatch();

    useEffect(()=>{
        dispatch(loadSubcontractorPartners(partnerType));
    },[]);

    useEffect(()=>{
        if(subcontractorPartnersSelector.length > 0)
        setSubcontractors(subcontractorPartnersSelector);
    },[subcontractorPartnersSelector])

    const handleCandidateChange = async ({ currentTarget }) => {
        dispatch(loadMonthlyPaySubcontractorCandidates(currentTarget.value));
        setSelectedCandidate(currentTarget.value);
    };

    useEffect(()=>{
        setCandidatePayRpt(monthlyPaySubcontractorCandidatesSelector);
    },[monthlyPaySubcontractorCandidatesSelector])

    const hanldeSubcontractorChange = async ({ currentTarget }) => {
        dispatch(loadSubcontractorCandidates(currentTarget.value));
        setCandidatePayRpt([]);
        setSelectedCandidate(0)
    };

    useEffect(()=>{
        setCandidates(subcontractorCandidatesSelector);
    },[subcontractorCandidatesSelector])

    const subcontractorPayExportPDF = () => {
        const headers = [
            [
                "Period",
                "Hours Worked",
                "Rate/hour",
                "Invoice Amount",
                "Invoice #",
                "Paid by ACH / CHECK",
                "Paid Amount",
                "Due Amount",
                "Date Paid",
            ],
        ];

        const colData = [];
        candidatePayRpt.map((item) => {
            const dt = [];
            dt.push(months.filter((x) => x.id === item.month)[0].name);
            dt.push(
                item.hours,
                item.rate,
                item.totalAmount,
                item.invoiceNo,
                item.paymentMode,
                item.paidAmount,
                item.totalAmount - item.paidAmount,
                item.paymentDate
            );
            colData.push(dt);
        });

        setSelectedCol(headers);
        setSelectedColData(colData);
        setPdfShowHide(true);
    };

    const getSubconMonPayTotal = () => {
        let amountT = 0;
        let paidT = 0;
        let dueT = 0;
        candidatePayRpt.map((item) => {
            amountT += item.totalAmount;
            paidT += item.paidAmount;
            dueT += item.totalAmount - item.paidAmount;
        });
        return (
            <tr className="font-weight-bold bg-lighter">
                <td colSpan={3}></td>
            <td className="text-right">Total</td>
            <td className="text-right">{ parseFloat(amountT).toFixed(2)}</td>
            <td colSpan={2}></td>
                <td className="text-right">{ parseFloat(paidT).toFixed(2)}</td>
                <td className="text-right">{ parseFloat(dueT).toFixed(2)}</td>
                <td></td>
        </tr>
        );
    };

    const handlepdfShowHide = () => {
        setPdfShowHide(false);
        setSelectedCol([]);
        setSelectedColData([]);
    };
    
    const handleYearChange = ({ currentTarget }) => {
        const year = currentTarget.value;
        if (year !== "") {
            const filterData = monthlyPaySubcontractorCandidatesSelector.filter(x => x.year === parseInt(year));
            setCandidatePayRpt(filterData);
        }
        else {
            setCandidatePayRpt(monthlyPaySubcontractorCandidatesSelector);
        }
        setYear(year);
    }

    const handleMonthChange = ({ currentTarget }) => {
        const month = currentTarget.value;
        if (month !== "") {
            const filterData = monthlyPaySubcontractorCandidatesSelector.
                                filter(x =>x.year === parseInt(year) && x.month === parseInt(month));

            setCandidatePayRpt(filterData);
        }
        else {
            setCandidatePayRpt(monthlyPaySubcontractorCandidatesSelector);
        }
        setMonth(month);
    }

    return <>
        <div className="card shadow-none border">
            <div className="card-header text-center p-3 bg-lighter">
                <h4 className="mb-0">
                    Monthly Payments to Subcontractor (Per
                    Candidate Basis)
                </h4>
            </div>
            <div className="card-body p-3">
                <div className="row">
                    <div className="col-md-4">
                        <select
                            name="subcontractorId"
                            id="subcontractorId"
                            className="form-control form-control-sm"
                            onChange={hanldeSubcontractorChange}
                        >
                            <option value="0">Select Subcontractor</option>
                            {subcontractors.map((item) => (
                                <option value={item.fuid}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <select
                            name="candidateId"
                            id="candidateId"
                            className="form-control form-control-sm"
                            onChange={handleCandidateChange}
                            value={selectedCandidate}
                        >
                            <option value="0">Select Candidate</option>
                            {candidates.map((item) => (
                                <option value={item.performerid}>
                                    {item.performername}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-1">
                        <select
                            name="year"
                            id="year"
                            className="form-control form-control-sm"
                            onChange={handleYearChange}
                            value={year}
                        >
                            <option value="">Year</option>
                            {years.map((y) => (
                                <option value={y.name}>
                                    {y.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* <div className="col-md-2">
                        <select
                            name="month"
                            id="month"
                            className="form-control form-control-sm"
                            onChange={handleMonthChange}
                            value={month}
                        >
                            <option value="">Month</option>
                            {year !== "" && months.map((m) => (
                                <option value={m.id}>{m.name}</option>
                            ))}
                        </select>
                    </div> */}
                    <div className="col-md-3 text-right">
                        <ReactHTMLTableToExcel
                            id="Subcontractor_Monthly_Pay"
                            className="btn btn-sm btn-outline-default"
                            table="sub_mon_pay"
                            filename="Subcontractor_Monthly_Pay"
                            sheet="Subcontractor_Monthly_Pay"
                            buttonText="Export to Excel"
                        />
                        <button
                            type="button"
                            className="btn btn-sm btn-outline-default"
                            onClick={subcontractorPayExportPDF}
                        >
                            Generate PDF
                        </button>
                    </div>
                    <div className="col-md-12 mt-2">
                        <div className="table-responsive">
                            <table
                                className="table align-items-center table-flush table-hover table-striped mb-2 border"
                                id="sub_mon_pay"
                            >
                                <thead className="thead-light">
                                    <th>Year</th>
                                    <th>Period</th>
                                    <th>Hours Worked</th>
                                    <th>Rate/hour</th>
                                    <th>Invoice Amount</th>
                                    <th>Invoice #</th>
                                    <th>Paid by ACH / CHECK</th>
                                    <th>Paid Amount</th>
                                    <th>Due Amount</th>
                                    <th>Date Paid</th>
                                </thead>
                                <tbody>
                                    {candidatePayRpt.map((item) => (
                                        <tr>
                                            <td>{item.year}</td>
                                            <td>
                                                {
                                                    months.filter(
                                                        (x) => x.id === item.month
                                                    )[0].name
                                                }
                                            </td>
                                            <td>{item.hours}</td>
                                            <td>{item.rate}</td>
                                            <td className="text-right">{item.totalAmount && parseFloat(item.totalAmount).toFixed(2)}</td>
                                            <td>{item.invoiceNo}</td>
                                            <td>{item.paymentMode}</td>
                                            <td className="text-right">{item.paidAmount && parseFloat(item.paidAmount).toFixed(2)}</td>
                                            <td className="text-right">
                                                {item.totalAmount &&
                                                 parseFloat(parseFloat(item.totalAmount).toFixed(2) -
                                                  item.paidAmount).toFixed(2)}
                                            </td>
                                            <td>
                                                {item.paymentDate && (
                                                    <Moment format="MMM Do YYYY, h:mm a">
                                                        {item.paymentDate}
                                                    </Moment>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                    {getSubconMonPayTotal()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <GeneratePdf
            handlepdfShowHide={handlepdfShowHide}
            pdfShowHide={pdfShowHide}
            selectedCol={selectedCol}
            selectedColData={selectedColData}
        ></GeneratePdf>
    </>
}

export default SubcontractorCandidates;