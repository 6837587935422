import React, { Component, useEffect, useState } from "react";
import { Link, Redirect, useNavigate } from "react-router-dom";
import { AddHostDetails, SendOTPEmail, VerifyOTPEmail, emailOtpReceived, getLoggedUser, getOtpSentStatus, getOtpVerifyStatus, validate, verifiedOtp, verifiedOtpFailed } from "../../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getAlert } from "../../services/appAlerts";
import http from "../../services/HttpService";
import config from "../../config.json";
import { verifyUser } from "../../services/authService";

const ValidateUser = () => {
  const state = {
    data: {},
    errors: {},
    btnEmailLoading: false,
    btnMobileLoading: false,
    verifyBtnLoding: false,
    reSendCodeBtnLoding: false,
    reSendCodeMsg: false,
  };

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [codeToEmail, setCodeToEmail] = useState(false);
  const [codeToMobile, setCodeToMobile] = useState(false);
  const [verifyBlock, setVerifyBlock] = useState(false);
  const [reSendCodeMsg, setReSendCodeMsg] = useState(false);

  const [btnEmailLoading, setBtnEmailLoading] = useState(false);
  const [btnMobileLoading, setBtnMobileLoading] = useState(false);
  const [verifyBtnLoding, setVerifyBtnLoding] = useState(false);
  const [reSendCodeBtnLoding, setReSendCodeBtnLoding] = useState(false);

  const [currentUser, setCurrentUser] = useState("");

  const usrType = localStorage.getItem("userType");

  const loggedUser = useSelector(getLoggedUser);
  const emailOtpSentSelector = useSelector(getOtpSentStatus);
  const verifyOtpStatusSelector = useSelector(getOtpVerifyStatus);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    //console.log(loggedUser);
    if (!loggedUser.user && !loggedUser.token) {
      navigate("/");
    }
    if (loggedUser.user && loggedUser.token) {
      navigate("/");
    }
    if (loggedUser.user && !loggedUser.token) {
      setCurrentUser(loggedUser.user);
    }
  }, [loggedUser]);

  useEffect(() => {
    if (emailOtpSentSelector === "success") {
      setCodeToEmail(true);
      setVerifyBlock(true);
      setCodeToMobile(false);
      setBtnEmailLoading(false);
      setReSendCodeBtnLoding(false);
      dispatch(emailOtpReceived(""));
    }
    if (emailOtpSentSelector === "fail") {
      getAlert("", "Authentication Failed", "error");
      setBtnEmailLoading(false);
      setReSendCodeBtnLoding(false);
      dispatch(emailOtpReceived(""));
    }
  }, [emailOtpSentSelector]);

  useEffect(() => {
    if (verifyOtpStatusSelector === "success") {
      const tempPwd = localStorage.getItem("tempPwd");
      dispatch(validate(currentUser.id, currentUser.email, tempPwd));
      dispatch(AddHostDetails({ userId: currentUser.id, email: currentUser.email }));
      setVerifyBtnLoding(false);
      getAlert("", "Authenticated Successfully", "success");
      setCode("");
      setCodeError("");
    }

    if (verifyOtpStatusSelector === "fail") {
      setCodeError("Invalid Code");
      setVerifyBtnLoding(false);
      dispatch(verifiedOtpFailed(""))
    }
  }, [verifyOtpStatusSelector]);

  const handleSendCodeToEmail = () => {
    dispatch(SendOTPEmail(currentUser.username, currentUser.email));
    setBtnEmailLoading(true);
  };

  const handleVerifyCode = () => {
    dispatch(VerifyOTPEmail(currentUser.email, code));
    setVerifyBtnLoding(true);
  };

  const handleSendCodeToMobile = async () => {
    // this.setState({ btnMobileLoading: true });
    // const { data: result } = await http.get(
    //   config.SendSecurityCodeToPhone +
    //     currentUser.username +
    //     "&phone=" +
    //     encodeURIComponent(currentUser.mobile)
    // );
    // if (result === "fail") {
    //   this.setState({ btnMobileLoading: false });
    //   this.getAlert("", "Authentication Failed", "error");
    // } else {
    //   this.setState({
    //     codeToEmail: false,
    //     codeToMobile: true,
    //     verifyBlock: true,
    //   });
    // }
  };

  const handleBack = () => {
    setVerifyBlock(false);
    setCodeToEmail(false);
    setCodeToMobile(false);
    setCode("");
    setCodeError("");
  };

  const handleResendCode = async () => {
    setReSendCodeBtnLoding(true);

    if (codeToEmail && !codeToMobile) {
      dispatch(SendOTPEmail(currentUser.username, currentUser.email));
    }

  };

  return (
    <React.Fragment>
      <div className="col-md-12">
        <div className="row align-items-center" style={{ height: "calc(100vh - 289px)" }}>
          <div className="col-md-3"></div>
          <div className="col-lg-6">
            <div
              className="spacecl card shadow-none border"
              style={{ backgroundColor: "#fafafa", marginTop: "30px" }}
            >
              {
                verifyBlock &&
                <div className="col-md-12">
                  <button
                    className="btn btn-link pl-0 btn-sm"
                    onClick={handleBack}
                  >
                    <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                    Back
                  </button>
                </div>
              }

              <h1 className="text-black text-uppercase text-center mt-2 mb-0">
                Verify Your Identity
              </h1>
              {!codeToEmail && !codeToMobile && (
                <p className="text-center">
                  You will now be required to enter a One-Time Security Code
                  to verify your identity
                </p>
              )}
              {codeToEmail && (
                <p className="text-center">
                  We've sent a security code to{" "}
                  {currentUser !== "" && currentUser.email.slice(0, 2)}
                  {".....@"}
                  {currentUser !== "" && currentUser.email.split("@")[1]}
                </p>
              )}
              {codeToMobile && (
                <p className="text-center">
                  We've sent a security code to ****-****-{" "}
                  {currentUser &&
                    currentUser.mobile.slice(
                      currentUser.mobile.length - 4,
                      currentUser.mobile.length
                    )}
                </p>
              )}
              <div className="card-body p-3">
                {!verifyBlock && (
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <h2>How would you like to receive the code?</h2>
                    </div>
                    <div className="col-md-12 text-center">
                      <button
                        className="btn btn-danger"
                        disabled={btnEmailLoading}
                        onClick={handleSendCodeToEmail}
                      >
                        {btnEmailLoading && (
                          <i className="fa fa-refresh fa-spin mr-2"></i>
                        )}
                        Send to my email
                      </button>
                      <button
                        className="btn btn-danger"
                        disabled={btnMobileLoading}
                        onClick={handleSendCodeToMobile}
                      >
                        {btnMobileLoading && (
                          <i className="fa fa-refresh fa-spin mr-2"></i>
                        )}
                        Send to my mobile
                      </button>
                    </div>
                  </div>
                )}
                {verifyBlock && (
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <label htmlFor="code" className="form-control-label">Enter your Security Code to continue</label>
                      <input
                        type="text"
                        id="code"
                        name="code"
                        className="form-control"
                        value={code}
                        placeholder="Code"
                        onChange={({ currentTarget }) => setCode(currentTarget.value)}
                      />
                      {Boolean(codeError) && <div className="alert alert-danger">{codeError}</div>}
                    </div>
                    <div className="col-md-12 form-group text-center">
                      <button
                        className="btn btn-success"
                        disabled={!Boolean(code)}
                        onClick={handleVerifyCode}
                      >
                        {verifyBtnLoding && (
                          <i className="fa fa-refresh fa-spin mr-2"></i>
                        )}
                        Verify
                      </button>
                      {/* <button
                        className="btn btn-danger customm-btn"

                        onClick={handleBack}
                      >
                        Back
                      </button> */}
                    </div>
                    <div className="col-md-12 text-center">
                      <p>Didn't receive the code?</p>
                      <button
                        className="btn btn-sm btn-link pl-0"
                        disabled={reSendCodeBtnLoding}
                        onClick={handleResendCode}
                      >
                        {reSendCodeBtnLoding && (
                          <i className="fa fa-refresh fa-spin mr-2"></i>
                        )}
                        Resend Code
                      </button>
                      {reSendCodeMsg && (
                        <span className="text-red">
                          Security Code Sent Successfully
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 mt-3">
            <div
              className="card shadow-none border"
              style={{ backgroundColor: "#fafafa" }}
            >
              <div className="card-body text-center p-2">
                <p>
                  Customer Support :{" "}
                  <a href="mailto:technicalsupport@workfinity.com">
                    Support1@workfinity.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
}

export default ValidateUser;
