import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Moment from "react-moment";
import http from "../services/HttpService";
import config from "../config.json";

class TaskHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      errors: {},
      selectedObj: {},
      subAllState: [],
    };
  }

  async componentDidMount() {}

  getWfPhasesColor(phase) {
    switch (phase) {
      case "Closed":
        return "btn-default w-100";
      case "Review":
        return "btn-secondary bg-yellow border-0 w-100";
      case "Application":
        return "btn-primary w-100";
      case "Pending":
        return "btn-info w-100";
      case "Approved":
        return "btn-success w-100";
      case "Denied":
        return "btn-danger w-100";
      case "Withdrawn":
        return "btn-warning w-100";
    }
  }

  async componentWillReceiveProps(nextProps) {
    //console.log(nextProps);
    // const { selectedObj } = nextProps
    // //console.log(selectedObj);
    // const { data: subAllState } = await http.get(
    //   config.GetApplicationTasks + selectedObj.ciid
    // );
    // //console.log(subAllState);
    // this.setState({ subAllState, selectedObj });
  }

  render() {
    const { subAllState, selectedObj } = this.state;
    const {
      historyAppShowHide,
      handlehistoryAppModalShowHide,
      selectedObj: task,
      doneTasks,
    } = this.props;
    console.log(doneTasks);
    return (
      <div className="col-md-12">
          <div className="row">
            <div className="col-md-6 pl-0">
            {task && (
                <h4>
                  Process ID - <span>{task.ciid}</span>, Task Name -
                  <span>{task.itemName}</span>
                </h4>
              )}
            </div>
            <div className="col-md-6 pr-0 text-right">
            <button
            className="btn btn-outline-danger btn-sm"
            onClick={handlehistoryAppModalShowHide}
          >
            <i className="fa fa-times mr-2" aria-hidden="true"></i>Close
          </button>
            </div>
            
          </div>
          <div className="row">
            <div className="table-responsive">
            <table className="table align-items-center table-flush table-striped mb-0 border">
              <thead className="thead-light">
                <tr>
                  {/* <th>APP ID</th> */}
                  <th>Task ID</th>
                  <th>Task Name</th>
                  <th>WF Performer</th>
                  <th>WF Phase</th>
                  <th>Task State</th>
                  <th>Start (Date & Time)</th>
                  <th>End (Date & Time)</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                {doneTasks &&
                  doneTasks.map((item) => (
                    <tr>
                      {/* <td>{item.ciid}</td> */}
                      <td>{item.wiid}</td>
                      <td style={{ whiteSpace: "pre-wrap" }}>
                        {item.itemName}
                      </td>
                      <th>
                        {item.performerName}
                        <br />
                        {item.performerEmail}
                        <br />
                        {item.performerDepartment}
                        {item.performerRole}
                        {item.performerPartner}
                      </th>
                      <td>{item.wfPhase}</td>
                      <td>
                        {item.taskDoneStatus === 4 && <><i
                          class="fa fa-check text-success mr-2"
                          aria-hidden="true"
                        ></i>
                        Done</>}
                      </td>
                      <td>
                        <Moment format="MMM Do YYYY">
                          {item.startDateTime}
                        </Moment>
                        <br />
                        <Moment format="h:mm a">{item.startDateTime}</Moment>
                      </td>
                      <td>
                        <Moment format="MMM Do YYYY">{item.endDateTime}</Moment>
                        <br />
                        <Moment format="h:mm a">{item.endDateTime}</Moment>
                      </td>
                      <td>{item.taskDoneDuration}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
            </div>
         
      </div>
    );
  }
}

export default TaskHistory;
