import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "dataTypes",
    initialState: {
        list: [],
        attributelist: [],
        loading: false,
        lastFetch: null,
        mappingAddedStatus: false,
        mappingDataTypes:[]
    },
    reducers: {
        allDataTypesReceived: (dataTypes, action) => {
            dataTypes.list = action.payload;
        },
        dataTypeAttributesReceived: (dataTypes, action) => {
            dataTypes.attributelist = action.payload;
        },
        dataTypeMappingAdded: (dataTypes, action) => {
            dataTypes.mappingAddedStatus = action.payload;
        },
        mappingDataTypesReceived: (dataTypes, action) => {
            dataTypes.mappingDataTypes = action.payload;
            dataTypes.mappingAddedStatus=false;
        },
    }
});

export const { allDataTypesReceived, dataTypeAttributesReceived,
    dataTypeMappingAdded,mappingDataTypesReceived
} = slice.actions;
export default slice.reducer;

export const loadAllDataTypes = () => apiCallBegan({
    url: config.GetAllDataTypes,
    onSuccess: allDataTypesReceived.type
});

export const loadAllDataTypeMappings = () => apiCallBegan({
    url: config.GetDataTypeMapping,
    onSuccess: mappingDataTypesReceived.type
});

export const addDataTypeMapping = (data) => apiCallBegan({
    url: config.AddDataTypeMapping,
    method: "post",
    data: data,
    onSuccess: dataTypeMappingAdded.type
});

export const getAllDataTypes = createSelector(
    state => state.entities.dataTypes,
    dataTypes => dataTypes.list
);

export const getDataTypeMappings = createSelector(
    state => state.entities.dataTypes,
    dataTypes => dataTypes.mappingDataTypes
);

export const getMappingAddedStatus = createSelector(
    state => state.entities.dataTypes,
    dataTypes => dataTypes.mappingAddedStatus
);
