import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "comments",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        updatedStatus:false,
        addedStatus:false,
        deletedStatus:false
    },
    reducers: {
        appCommentsReceived: (comments, action) => {
            comments.list = action.payload;
            comments.updatedStatus = false
            comments.addedStatus = false
            comments.deletedStatus = false
        },
        commentsAdded: (comments, action) => {
            comments.addedStatus = true;
        },
        commentUpdated: (comments, action) => {
            comments.updatedStatus = true;
        },
        commentDeleted: (comments, action) => {
            comments.deletedStatus = true;
        },
    }
});

export const { appCommentsReceived,commentsAdded,commentUpdated,commentDeleted } = slice.actions;
export default slice.reducer;

export const loadAppComments = (type,id) => apiCallBegan({
    url: config.GetReviewComments + type+"&ciid="+id,
    onSuccess: appCommentsReceived.type
});

export const addComment = (data) => apiCallBegan({
    url: config.AddNewComments,
    method:"post",
    data:data,
    onSuccess: commentsAdded.type
});

export const updateComment = (data) => apiCallBegan({
    url: config.UpdateComment,
    method:"post",
    data:data,
    onSuccess: commentUpdated.type
});

export const deleteComment = (data) => apiCallBegan({
    url: config.DeleteComment,
    method:"post",
    data:data,
    onSuccess: commentDeleted.type
});

export const getAppComments = createSelector(
    state => state.entities.comments,
    comments => comments.list
);

export const getCommentUpdatedStatus = createSelector(
    state => state.entities.comments,
    comments => comments.updatedStatus
);

export const getCommentAddedStatus = createSelector(
    state => state.entities.comments,
    comments => comments.addedStatus
);



