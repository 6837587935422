import React, { Component } from "react";
import { Link } from "react-router-dom";
import http from "../services/HttpService";
import config from "../config.json";

class PartnerManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerReports: [],
    };
  }

  async componentDidMount() {
    const { data: partnerReports } = await http.get(
      config.GetPartnerApplicationReports
    );
    this.setState({ partnerReports });
  }

  render() {
    const { partnerReports } = this.state;
    const { partners, partnerPerformer } = this.props;
    return (
      <div className="row">
        <div className="col-xl-4">
          <div className="card bg-gradient-danger">
            <div className="card-body">
              <div className="mb-2">
                <span className="h2 text-white">Total Subcontractors</span>
                <div className="text-white mt-2 text-sm">
                  Total :
                  <span className="text-white font-weight-bold ml-2">
                    {partners}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="card bg-gradient-success">
            <div className="card-body">
              <div className="mb-2">
                <span className="h2 text-white">Total Recruiters</span>
                <div className="text-white mt-2 text-sm">
                  Total :
                  <span className="text-white font-weight-bold ml-2">
                    {partnerPerformer}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table align-items-center table-flush">
              <thead className="thead-light">
                <tr>
                  <th>
                    Partner
                    <br />
                    Name
                  </th>
                  <th>
                    Total
                    <br />
                    Candidates
                  </th>
                  <th>
                    Billable
                    <br />
                    Candidates
                  </th>
                  <th>
                    Terminated
                    <br />
                    Candidates
                  </th>
                  <th>
                    Total
                    <br />
                    Invoice
                  </th>
                  <th>
                    Total
                    <br />
                    Project
                  </th>
                  <th>
                    Total <br />
                    Active Users
                  </th>
                </tr>
              </thead>
              <tbody>
                {partnerReports.map((p) => (
                  <tr>
                    <td>{p.name}</td>
                    <td>{p.appReceived}</td>
                    <td>{p.offerPresented}</td>
                    <td>{p.offerAccepted}</td>
                    <td>{p.offerPending}</td>
                    <td>{p.appRejected}</td>
                    <td>{p.performers}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerManagement;
