import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "templates",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        addedStatus:false,
        updatedStatus:false,
        sendStatus:false,
        templateForm:null
    },
    reducers: {
        appTemplatesReceived:(templates,action)=>{
            templates.list=action.payload;
            templates.addedStatus=false;
            templates.updatedStatus=false;
            templates.sendStatus=false;
        },
        appTemplateAdded:(templates,action)=>{
            templates.addedStatus=true;
        },
        appTemplateUpdated:(templates,action)=>{
            templates.updatedStatus=true;
        },
        appTemplateSent:(templates,action)=>{
            templates.sendStatus=true;
        },
        templateFormReceived:(templates,action)=>{
            templates.templateForm=action.payload;
        }
        
    }
});

export const { appTemplatesReceived,appTemplateAdded,
    appTemplateUpdated,templateFormReceived,appTemplateSent } = slice.actions;
export default slice.reducer;

export const loadAppTemplates = (id) => apiCallBegan({
    url: config.GetSentTemplates + id,
    onSuccess: appTemplatesReceived.type
});

export const loadTemplateForm = (id) => apiCallBegan({
    url: config.GetForm + id,
    onSuccess: templateFormReceived.type
});

export const sentAppTemplate = (id) => apiCallBegan({
    url: config.SentTemplate + id,
    onSuccess: appTemplateSent.type
});

export const addAppTemplate = (data) => apiCallBegan({
    url: config.AddTemplate,
    method:"post",
    data:data,
    onSuccess: appTemplateAdded.type
});

export const updateAppTemplate = (data) => apiCallBegan({
    url: config.UpdateTemplate,
    method:"post",
    data:data,
    onSuccess: appTemplateUpdated.type
});

export const getAppTemplates = createSelector(
    state => state.entities.templates,
    templates => templates.list
);

export const getTemplateForm = createSelector(
    state => state.entities.templates,
    templates => templates.templateForm
);

export const getAppTemplateAddedStatus = createSelector(
    state => state.entities.templates,
    templates => templates.addedStatus
);

export const getAppTemplateUpdatedStatus = createSelector(
    state => state.entities.templates,
    templates => templates.updatedStatus
);
export const getAppTemplateSentStatus = createSelector(
    state => state.entities.templates,
    templates => templates.sendStatus
);

