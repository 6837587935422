import axios from "axios";
import { Buffer } from "buffer";
import { apiCallBegan, apiCallFailed, apiCallSuccess } from "../api";

const username = "";
const password = "25eywzntdrkano5ypjphzl6ws6vcygzhhps4pnsb7ux2a37n6eua";
const token = `${username}:${password}`;
const encodedToken = Buffer.from(token).toString("base64");
//const API_BASE_URL="https://localhost:44334/api/" 
const API_BASE_URL = process.env.REACT_APP_API_BASEURL + "/api/";

const api =
  ({ getState, dispatch }) =>
    (next) =>
      async (action) => {
        const { auth } = getState();

        const token = localStorage.getItem("token");

        if (action.type !== apiCallBegan.type) return next(action);

        const { url, method, data, onStart, onSuccess, onError, withCredentials, responseType, baseURL } =
          action.payload;

        const headers = { Authorization: `Bearer ${token}` };

        if (onStart) dispatch({ type: onStart });
        let api_url = baseURL;
        if (!baseURL) api_url = API_BASE_URL;

        next(action);

        try {
          const result = await axios.request({
            baseURL: api_url,
            url,
            method,
            data,
            headers, //: { 'Authorization': 'Basic ' + encodedToken, 'content-type': 'application/json-patch+json' },
            //withCredentials, //: true,
            responseType
          });

          //console.log(result)

          dispatch(apiCallSuccess(result.data));

          if (onSuccess) dispatch({ type: onSuccess, payload: result.data });

        } catch (error) {
          dispatch(apiCallFailed(error.message));

          if (onError) dispatch({ type: onError, payload: error.message });
        }
      };

export default api;
