import React, { Component, useDebugValue } from "react";
import auth from "../services/authService";
class Logout extends Component {
  async componentDidMount() {
    // const user = auth.getCurrentUser();
    // if (user === null) window.location = "/AdminLogin";

    auth.logout();
    window.location = "/Login";
    // if (user.type !== "admin" && user.type !== "workflow")
    //   window.location = "/AdminLogin";
    // if (user.type === "admin" && user.type === "workflow")
    //   window.location = "/AdminLogin";
    // if (user.type === "workflow") window.location = "/PartnerLogin";

    // if (user.type === undefined) window.location = "/AdminLogin";
  }

  render() {
    return null;
  }
}

export default Logout;
