import React, { Component } from "react";
import { Modal, Button, Nav } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import Moment from "react-moment";
import ToolsPopup from "./ToolsPopup";
import { Link } from "react-router-dom";
import AddEditNotes from "./AddEditNotes";

class PartnerWorkHub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentList: [
        {
          id: 1,
          name: "Notes",
          group: "Review",
          display: true,
        },
        { id: 2, name: "Documents", group: "Review", display: true },
        { id: 9, name: "Messaging", group: "Message", display: true },
        {
          id: 11,
          name: "Schedule Appointment",
          group: "",
          display: true,
        },
        { id: 14, name: "DocuSign", group: "", display: true },
        { id: 18, name: "Forms Library", group: "", display: true },
        { id: 24, name: "Templates Library", group: "", display: true },
        { id: 7, name: "Timer Messages", group: "Message", display: true },
      ],
      showHide: false,
      noteShowHide: false,
      docShowHide: false,
      logShowHide: false,
      remainderMsgShowHide: false,
      candidateList: [],
      toolObj: "",
      aWorkfinityAppEntity: {},
      wfForm: {},
      aWorkfinityApp: {},
      subformDataType: [],
      workItem: {},
      data: {},
      errors: {},
      encryptionDecryptions: {},
      //appTask: [],
      bcChecks: [],
      appDocuments: [],
      workflowShowHide: false,
      startedChecklist: false,
      item: "",
      allNotes: [],
      notesType: "",
    };
  }

  componentDidMount() {}

  loadPartner = async (partnerObj, type) => {
    const frmBody = new FormData();
    frmBody.append("id", partnerObj.fuid);
    frmBody.append("name", "");
    frmBody.append("email", "");
    frmBody.append("type", type);
    const { data: partners } = await http.post(
      config.GetPartnersByFilter,
      frmBody
    );
    //console.log(partners);
    if (partners.length > 0) {
      this.loadComments(partners[0].contact1.performerid);
      this.loadApplication(partners[0].contact1);
      this.setState({ item: partners[0] });
    }
  };

  loadApplication = async (objItem) => {
    //console.log(objItem);
    const user = auth.getCurrentUser();
    var frm = new FormData();
    frm.append("selectedDepartment", "");
    frm.append("performerId", objItem.performerid);
    frm.append("ciid", "");
    frm.append("performerName", "");
    frm.append("wfPhase", "");
    frm.append("reviewer", "");
    frm.append("appStartDate", "");
    frm.append("appEndDate", "");
    frm.append("pageSize", "1");
    frm.append("currentPage", "1");
    frm.append("type", user.type);
    frm.append("userId", user.id);
    const { data: applicants } = await http.post(
      config.GetFilterApplications,
      frm
    );
    //console.log(applicants);
    if (applicants.length > 0) {
      const appTask =
        applicants.length > 0 &&
        applicants[0].applications.length > 0 &&
        applicants[0].applications[0];

      let wfTask =
        appTask && appTask.workflowTasks.length > 0 && appTask.workflowTasks[0];
      //console.log(appTask);
      // const { data: appDocuments } = await http.get(
      //   config.GetApplicationDocuments + appTask.ciid
      // );

      if (wfTask) {
        const revTask =
          appTask.workflowTasks.length > 0 &&
          appTask.workflowTasks.filter((x) => x.formType === "WF");
        if (revTask.length > 0) {
          const { data: wfForm } = await http.get(
            config.GetForm + revTask[0].formId
          );
          this.setState({ wfForm });
        }
      } else {
        wfTask =
          appTask.length > 0 &&
          appTask.doneWorkflowTasks.length > 0 &&
          appTask.doneWorkflowTasks[0];
      }

      const { data: application } = await http.get(
        config.GetApplication + appTask.ciid
      );

      this.schema = {};
      const aWorkfinityApp = JSON.parse(application.applicationData);
      const appShortName = Object.keys(aWorkfinityApp)[1];
      const aWorkfinityAppEntity = aWorkfinityApp[appShortName];
      const { data } = this.state;

      Object.keys(aWorkfinityAppEntity).forEach(function (keyValue) {
        if (
          keyValue !== "@xmlns:xsd" &&
          keyValue !== "@xmlns:xsi" &&
          keyValue !== "EntityId"
        ) {
          Object.keys(aWorkfinityAppEntity[keyValue]).forEach(function (
            keyValue1
          ) {
            if (keyValue1 !== "EntityId") {
              var item = aWorkfinityAppEntity[keyValue][keyValue1];
              if (!Array.isArray(item))
                data[keyValue + "." + keyValue1.replace("@", "")] = item;
              //  else data[keyValue + "." + keyValue1.replace("@", "")] = item;
            }
          });
        }
      });

      this.setState({
        applicants,
        appTask,
        data,
        aWorkfinityApp,
        aWorkfinityAppEntity,
        workItem: wfTask,
        //appDocuments,
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    const { partnerObj, module } = nextProps;
    if (partnerObj !== "" && partnerObj !== undefined) {
      //console.log(partnerObj);
      //this.loadApplication(partnerObj);
      if (module === "Subcontractor")
        this.loadPartner(partnerObj, "Sub-Contractors");
      if (module === "Recruiter") this.loadPartner(partnerObj, "Recruiter");
    }
  }

  handleToolShowHide = (r) => {
    this.setState({ toolShowHide: !this.state.toolShowHide, toolObj: r });
  };

  getFieldData = (aWorkfinityApp, attribue, filedName) => {
    const appEntity = JSON.parse(aWorkfinityApp);
    const appShortName = Object.keys(appEntity)[1];
    const aWorkfinityAppEntity = appEntity[appShortName];
    const obj = aWorkfinityAppEntity[attribue];
    if (obj === undefined) return "";
    return obj["@" + filedName] === undefined ? "" : obj["@" + filedName];
  };

  loadComments = async (perfid) => {
    const { data: allNotes } = await http.get(
      config.GetReviewNotes + "Internal" + "&id=" + perfid
    );
    //console.log(allNotes);
    this.setState({ allNotes });
  };

  handleNoteShowHide = (type) => {
    let tp = type;
    if (type === "save") tp = "";
    this.setState({
      noteShowHide: !this.state.noteShowHide,
      notesType: tp,
    });
    const { item } = this.state;
    //console.log(objItem);
    if (type === "save") this.loadComments(item.contact1.performerid);
  };

  handleDownloadFile = async (item) => {
    try {
      const { data } = await http.get(config.DownloadDocument + item.docId, {
        responseType: "blob",
      });
      if (data.size > 0) {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", item.docName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }

    // const { data } = await http.get(config.DownloadDocument + item.docId);
    // if (data !== "Fail") {
    //   this.download(item.docName.trim(), data);
    // }
  };

  render() {
    const {
      componentList,
      showHide,
      noteShowHide,
      candidateList: listItems,
      docShowHide,
      toolShowHide,
      toolObj,
      appTask,
      wfForm,
      workItem,
      route1,
      logShowHide,
      remainderMsgShowHide,
      appDocuments,
      workflowShowHide,
      startedChecklist,
      item,
      notesType,
      allNotes,
    } = this.state;
    const {
      partHubShowHide,
      handlePartHubShowHide,
      module,
      partnerObj,
      candidateObj,
    } = this.props;
    const elements = //htmlJsonString;
      wfForm.elementJson != undefined && JSON.parse(wfForm.elementJson);
    const user = auth.getCurrentUser();
    const candidateList = [candidateObj];
    //const mainItem = item;
    // console.log(candidateObj);
    return (
      <React.Fragment>
        <Modal show={partHubShowHide} size="xl">
          <Modal.Header
            closeButton
            onClick={() => handlePartHubShowHide("", "")}
          >
            <Modal.Title>Working hub - {module}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 form-group">
                <div className="table-responsive border">
                  <table className="table align-items-center table-flush table-hover table-striped mb-2">
                    <thead className="thead-light">
                      <tr>
                        <th>ID #</th>
                        <th>{module} Details</th>
                        <th>
                          Key Contact 1<br />
                          (Super-Admin)
                        </th>
                        {module !== "Recruiter" && <th>Entity Type</th>}
                        <th>
                          Sign-In Status
                          <br />
                          Enrollment Status
                        </th>
                        <th>
                          Activate/
                          <br />
                          Deactive
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {item !== "" && (
                        <tr>
                          <td>{item.fuid}</td>
                          <td>
                            <span className="text-uppercase">{item.label}</span>
                            <br />
                            <span className="text-primary">{item.email}</span>
                            <br />
                            <Moment format="MM-DD-YYYY, h:mm A">
                              {item.createdDate}
                            </Moment>
                          </td>
                          <td>
                            {item.contact1 !== null && (
                              <React.Fragment>
                                <span className="text-uppercase">
                                  {item.contact1.performername}
                                </span>
                                <br />
                                <span className="text-primary">
                                  {item.contact1.performeremail}
                                </span>
                                <br />
                                {item.contact1.contactNo &&
                                  item.contact1.contactNo.slice(0, 3)}
                                -
                                {item.contact1.contactNo &&
                                  item.contact1.contactNo.slice(3, 6)}
                                -
                                {item.contact1.contactNo &&
                                  item.contact1.contactNo.slice(6)}
                              </React.Fragment>
                            )}
                          </td>
                          {module !== "Recruiter" && <td>C2C Basis</td>}
                          <td>
                            {item.contact1 !== null &&
                              item.contact1.maxtasks > 0 && (
                                <button className="btn btn-primary btn-sm w-100">
                                  Done
                                </button>
                              )}
                            {((item.contact1 !== null &&
                              item.contact1.maxtasks === null) ||
                              item.contact1 === null) && (
                              <button className="btn btn-danger btn-sm w-100">
                                Pending
                              </button>
                            )}
                            <br />
                            {item.contact1 !== null && (
                              <React.Fragment>
                                <button className="btn btn-link btn-sm p-0 m-0 text-underline w-100">
                                  Send Reminder
                                </button>
                                <br />
                                <button
                                  className="btn btn-link btn-sm p-0 m-0 w-100"
                                  title="View message"
                                  //onClick={this.handleRemainderMessage}
                                >
                                  <span className="badge badge-warning rounded-0 text-red">
                                    <i
                                      class="fa fa-envelope mr-1"
                                      aria-hidden="true"
                                    ></i>
                                    {item.contact1.msgAlertCount === undefined
                                      ? 0
                                      : item.contact1.msgAlertCount}
                                  </span>
                                </button>
                              </React.Fragment>
                            )}
                          </td>
                          <td>
                            {item.contact1 !== null && (
                              <React.Fragment>
                                {item.contact1.isActive === true && (
                                  <button className="btn btn-sm btn-success w-100">
                                    <i
                                      className="fa fa-check-square"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Activated
                                  </button>
                                )}
                                {item.contact1.isActive === false && (
                                  <button className="btn btn-sm btn-danger w-100">
                                    Deactivated
                                  </button>
                                )}
                                {item.contact1.isActive === null && (
                                  <button className="btn btn-sm btn-default w-100">
                                    To Activate
                                  </button>
                                )}
                              </React.Fragment>
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-12 mt--3">
                <div className="card card-header text-center border shadow-none p-2 mb-2 bg-lighter">
                  <h3 className="mb-0">
                    WORKING HUB -{" "}
                    {module !== "Recruiter" ? "SUBCONTRACTOR" : "RECRUITER"}
                  </h3>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card shadow-none border">
                  <div className="card-header p-2 bg-admin-sub-light border-admin-sub-left">
                    <h4 className="mb-0 text-uppercase">TOOLS</h4>
                  </div>
                  <div className="card-body p-3 bg-info">
                    <Nav variant="pills" className="flex-row">
                      {componentList.map((r, key) => (
                        <Nav.Item>
                          <Nav.Link
                            eventKey={r.id}
                            className={`pt-1 pb-1 pl-2 pr-2 mr-3 mt-2`}
                            style={{ color: "black" }}
                            onClick={() => this.handleToolShowHide(r)}
                          >
                            {r.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="card shadow-none border">
                  <div className="card-header p-2 bg-admin-sub-light border-admin-sub-left">
                    <div className="row">
                      <div className="col-md-8">
                        <h4 className="mb-0 text-uppercase mt-1">
                          {module === "Recruiter"
                            ? "RECRUITER"
                            : "SUBCONTRACTOR"}{" "}
                          ENROLLMENT REVIEW
                        </h4>
                      </div>
                      <div className="col-md-4 text-right">
                        <img
                          src="/assets/Images/WFAssociationIcons/process.gif"
                          className="w-auto"
                          role="button"
                          //onClick={this.handleWorkflowModalShowHide}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-3">
                    {/* <ul className="list-group">
                    {wfDocs &&
                      wfDocs.map((doc) => (
                        <li className="list-group-item">
                          <button className="btn btn-sm btn-link">
                            {doc.name}
                          </button>
                        </li>
                      ))}
                  </ul> */}
                    <span className="h4">WF Phase : </span>
                    {appTask && (
                      <Link
                        to={{
                          pathname: "/ApplicationSummary",
                          state: { task: appTask },
                        }}
                        className="btn btn-outline-primary btn-sm text-left mb-2"
                      >
                        {appTask.applicationType}
                      </Link>
                    )}

                    {appTask &&
                      appTask.doneWorkflowTasks
                        .filter(
                          (x) =>
                            x.itemType === 1 &&
                            x.performerId !== appTask.applicantId
                        )
                        .map((doneTask) => (
                          <button
                            className="btn btn-sm btn-primary mb-2"
                            title="Done Review Task"
                            onClick={() => this.handleTaskChange(doneTask)}
                          >
                            <span class="badge badge-pill badge-light mr-2">
                              Done
                            </span>
                            {doneTask.itemName}
                          </button>
                        ))}
                    {appTask &&
                      appTask.workflowTasks
                        .filter((x) => x.itemType === 1)
                        .map((doneTask) => (
                          <button
                            className="btn btn-sm btn-primary mb-2"
                            title="In-Progress Review Task"
                            onClick={() => this.handleTaskChange(doneTask)}
                          >
                            <span class="badge badge-pill badge-light mr-2">
                              In-Progress
                            </span>
                            {doneTask.itemName}
                          </button>
                        ))}
                    {module === "Recruiter" ? (
                      <div className="text-red">
                        Once the recruiter is enrolled and activated, recruiter
                        will access the ‘Recruiter Portal’ to complete the
                        ‘enrollment process’ and upload the required documents.
                        The enrollment application form and completed documents
                        will be seen in this section by IPCS staff for review
                        and completeness.
                      </div>
                    ) : (
                      <div className="mt-2 text-red">
                        Once the subcontractor is enrolled and activated,
                        subcontractor will access the ‘Subcontractor Portal’ to
                        complete the ‘enrollment process’ and upload the
                        required documents. The enrollment application form and
                        completed documents will be seen in this section by IPCS
                        staff for review and completeness.
                      </div>
                    )}
                    <div className="border mt-3 pt-2 pb-2">
                      {elements &&
                        elements
                          .filter((x) => x.groupName !== "otherCriteria")
                          .map((col) => (
                            <div className={`col-` + col.width}>
                              {col.components.map((comp) => {
                                if (
                                  comp.dataType === "button" &&
                                  comp.buttonType !== undefined
                                ) {
                                  if (
                                    comp.buttonType.type !== undefined &&
                                    (comp.buttonType.type === "editForm" ||
                                      comp.buttonType.type === "subform")
                                  ) {
                                    return this.rendorHtmlComponent(comp);
                                  }
                                } else {
                                  return this.rendorHtmlComponent(comp);
                                }
                              })}
                            </div>
                          ))}
                      <div className="col-md-12 text-center">
                        {elements &&
                          elements.map((col) =>
                            col.components.map((comp) => {
                              if (comp.dataType === "button") {
                                return this.rendorHtmlComponent(comp);
                              }
                            })
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="card shadow-none border">
                  <div className="card-header p-2 bg-admin-light border-admin-left">
                    <div className="row">
                      <div className="col-md-7">
                        <h4 className="mb-0 text-uppercase mt-1">
                          Latest Notes
                        </h4>
                      </div>
                      <div className="col-md-5 text-right">
                        {/* <button
                          className="btn btn-default btn-sm"
                          onClick={() =>
                            this.handleToolShowHide(
                              componentList.filter((x) => x.id === 2)[0]
                            )
                          }
                        >
                          Upload
                        </button> */}
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => this.handleNoteShowHide("Internal")}
                        >
                          Notes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-3">
                    <ul
                      className="list-group"
                      style={{ maxHeight: "150px", overflow: "auto" }}
                    >
                      {allNotes &&
                        allNotes.map((x) => (
                          <li className="list-group-item p-2">{x.comments}</li>
                        ))}
                      {/* {appDocuments &&
                        appDocuments.map((doc) => (
                          <li className="list-group-item p-1">
                            <span className="p-2">
                              <Moment format="MMM Do YYYY">
                                {doc.dateTime}
                              </Moment>
                            </span>

                            <button
                              className="btn btn-sm btn-link"
                              onClick={() => this.handleDownloadFile(doc)}
                            >
                              {doc.docName}
                            </button>
                            {doc.uploaderId !== parseInt(user.id) ? (
                              <span
                                className="badge"
                                style={{
                                  backgroundColor: "#ffb74d",
                                  color: "black",
                                }}
                              >
                                Received
                              </span>
                            ) : (
                              <span className="badge badge-default">Sent</span>
                            )}
                          </li>
                        ))} */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card shadow-none border">
                  <div className="card-header p-2 bg-admin-light border-admin-left">
                    <h4 className="mb-0 text-uppercase">Candidates</h4>
                  </div>
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive">
                          <table className="table align-items-center table-flush table-hover table-striped mb-2">
                            <thead className="thead-light">
                              <tr>
                                <th>Candidate #</th>
                                <th>Candidate Details</th>
                                <th>Client</th>
                                <th>
                                  Duration
                                  <br />
                                  Start-End
                                </th>
                                <th>Onboarding Status</th>
                                {/* <th>Action</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {candidateList.map((item) => (
                                <tr>
                                  <td>{item.performerid}</td>
                                  <td>
                                    <span className="text-uppercase font-weight-bold">
                                      {item.performername} {item.lastName}
                                    </span>
                                    <br />
                                    <a href="#">{item.performeremail}</a>
                                    <br />
                                    {item.contactNo &&
                                      item.contactNo.slice(0, 3) +
                                        "-" +
                                        item.contactNo.slice(3, 6) +
                                        "-" +
                                        item.contactNo.slice(6)}
                                    <br />
                                    {this.getFieldData(
                                      item.performerData,
                                      "projectengagement",
                                      "PositionTitle"
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    {this.getFieldData(
                                      item.performerData,
                                      "projectengagement",
                                      "AgencyName"
                                    )}
                                  </td>
                                  <td>
                                    {this.getFieldData(
                                      item.performerData,
                                      "projectengagement",
                                      "EngagementDuration"
                                    )}{" "}
                                    Months
                                    <br />
                                    <Moment format="MMM Do YYYY">
                                      {this.getFieldData(
                                        item.performerData,
                                        "projectengagement",
                                        "StartDate"
                                      )}
                                    </Moment>
                                    -
                                    <Moment format="MMM Do YYYY">
                                      {this.getFieldData(
                                        item.performerData,
                                        "projectengagement",
                                        "EndDate"
                                      )}
                                    </Moment>
                                  </td>
                                  <td>
                                    {item.applicationStatus && (
                                      <button className="btn btn-primary btn-sm w-100">
                                        Done
                                      </button>
                                    )}
                                    {!item.applicationStatus && (
                                      <button className="btn btn-danger btn-sm w-100">
                                        Pending
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => handlePartHubShowHide("", "")}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <ToolsPopup
          toolShowHide={toolShowHide}
          handleToolShowHide={this.handleToolShowHide}
          toolObj={toolObj}
          workItem={workItem}
        ></ToolsPopup>
        <AddEditNotes
          noteShowHide={noteShowHide}
          handleNoteShowHide={this.handleNoteShowHide}
          notesType={notesType}
          candidateObj={item.contact1}
          //timeLogObj={timeLogObj}
        ></AddEditNotes>
      </React.Fragment>
    );
  }
}

export default PartnerWorkHub;
