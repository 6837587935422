import React, { Component } from "react";
import Form from "../../comman/form";
import { Modal, Button } from "react-bootstrap";
import http from "../../services/HttpService";
import config from "../../config.json";
import geo from "../../services/geoService";

class ViewRegistration extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      workflowUserTypes: [],
      category: [],
      subCategory: [],
      applications: [],
      stateList: [],
      countyList: [],
      cityList: [],
      hintQuestions: [],
      applications: [],
      titles: [
        {
          id: "1",
          name: "Dr.",
        },
        {
          id: "2",
          name: "Miss",
        },
        {
          id: "3",
          name: "Mr.",
        },
        {
          id: "4",
          name: "Mrs.",
        },
        {
          id: "5",
          name: "Mx.",
        },
      ],
      registration: "",
    };
  }

  schema = {};

  async componentDidMount() {
    const stateList = await geo.getStateList();
    const { data: hintQuestions } = await http.get(
      config.GetCodemanager + "Security Question 1"
    );
    const { data: workflowUserTypes } = await http.get(
      config.GetCodemanager + "Workflow User Type"
    );
    const { data: category } = await http.get(config.GetCodemanager + "SC");
    const { data: subCategory } = await http.get(config.GetCodemanager + "LT");
    this.setState({
      hintQuestions,
      workflowUserTypes,
      stateList,
      category,
      subCategory,
    });
  }

  handleOnChangeTrigger(id) {
    var element = document.getElementById(id);
    console.log(element);
    if (element !== null) {
      var event = new Event("change", { bubbles: true });
      element.dispatchEvent(event);
    }
  }

  handleStateChanged = async ({ target: input }) => {
    const selectedOption = input.selectedOptions[0];
    this.setState({
      selectedState: selectedOption.text,
      selectedStateId: selectedOption.value,
    });

    this.updateState(input.name, selectedOption.text);
    if (input.nodeName === "SELECT") this.handleDropdwonSpeech(input);
    await this.bindCountyDropdown(selectedOption.value);
  };

  handleCountyChanged = async ({ target: input }) => {
    const selectedOption = input.selectedOptions[0];
    this.setState({
      selectedCounty: selectedOption.text,
      selectedCountyId: selectedOption.value,
    });

    this.updateState(input.name, selectedOption.text);
    if (input.nodeName === "SELECT") this.handleDropdwonSpeech(input);
    await this.bindCityDropdown(selectedOption.value);
  };

  updateState(name, value) {
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  }

  async bindCountyDropdown(geonameId) {
    const countyList = geonameId > 0 ? await geo.getGeoData(geonameId) : [];
    this.setState({ countyList });
    this.handleOnChangeTrigger("US_County");
  }

  async bindCityDropdown(geonameId) {
    const cityList = geonameId > 0 ? await geo.getGeoData(geonameId) : [];
    this.setState({ cityList });
  }
  handleUserTypeChanged = async ({ currentTarget }) => {
    const { data } = this.state;
    data[currentTarget.name] = currentTarget.value;
    this.setState({ data });
  };

  componentWillReceiveProps = async (nextProps) => {
    const { regId } = nextProps;
    if (regId !== "" && regId !== undefined) {
      const { data } = this.state;
      const { data: registration } = await http.get(
        config.GetRegistrationData + regId
      );
      const aWorkfinityApp = JSON.parse(registration.message);
      const appShortName = Object.keys(aWorkfinityApp)[1];
      const aWorkfinityAppEntity = aWorkfinityApp[appShortName];

      Object.keys(aWorkfinityAppEntity).forEach(function (keyValue) {
        if (
          keyValue !== "@xmlns:xsd" &&
          keyValue !== "@xmlns:xsi" &&
          keyValue !== "EntityId"
        ) {
          Object.keys(aWorkfinityAppEntity[keyValue]).forEach(function (
            keyValue1
          ) {
            if (
              keyValue1 !== "EntityId" &&
              keyValue === "applicantregistration"
            ) {
              data[keyValue1.replace("@", "")] =
                aWorkfinityAppEntity[keyValue][keyValue1];
            }
          });
        }
      });

      data["userType"] = registration.type;
      data["question"] = registration.question;

      const { data: allChecklist } = await http.get(
        config.GetAllChecklist + registration.workflowId
      );

      const filterChk = allChecklist.filter((x) => x.workflowType === 1);
      this.setState({ applications: filterChk, data });
    }
  };

  render() {
    const {
      data,
      workflowUserTypes,
      hintQuestions,
      applications,
      stateList,
      countyList,
      cityList,
      orginationTypes,
      category,
      subCategory,
      titles,
    } = this.state;
    const { regShowHide, handleRegShowHide } = this.props;
    const stateId =
      data["US_State"] !== undefined &&
      stateList.length > 0 &&
      stateList.filter((x) => x.name === data["US_State"]).length > 0 &&
      stateList.filter((x) => x.name === data["US_State"])[0].geonameId;

    const countyId =
      data["US_County"] !== undefined &&
      countyList.length > 0 &&
      countyList.filter((x) => x.name === data["US_County"]).length > 0 &&
      countyList.filter((x) => x.name === data["US_County"])[0].geonameId;

    const orgStateId =
      data["Organisation_State"] !== undefined &&
      stateList.length > 0 &&
      stateList.filter((x) => x.name === data["Organisation_State"]).length >
        0 &&
      stateList.filter((x) => x.name === data["Organisation_State"])[0]
        .geonameId;

    const orgCountyId =
      data["Organisation_County"] !== undefined &&
      countyList.length > 0 &&
      countyList.filter((x) => x.name === data["Organisation_County"]).length >
        0 &&
      countyList.filter((x) => x.name === data["Organisation_County"])[0]
        .geonameId;

    const filterCategory = category.filter((x) => x.parentCode === 53);
    const selectedCategory =
      data["Grant_Program"] !== undefined
        ? filterCategory.filter((x) => x.name === data["Grant_Program"])
        : [];
    const filterSubCategory =
      selectedCategory.length > 0
        ? subCategory.filter(
            (x) => x.parentCode === parseInt(selectedCategory[0].code)
          )
        : [];
    const subCate =
      filterSubCategory.length > 0
        ? filterSubCategory[0].name.split(",").map((item, key) => {
            return { id: key + 1, name: item };
          })
        : [];
    return (
      <Modal show={regShowHide} size="xl">
        <Modal.Header closeButton onClick={handleRegShowHide}>
          <Modal.Title>Your Registraion Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          <div className="row">
            <div className="col-md-12">
              <div
                className="card card-body p-3"
                style={{
                  border: "1px solid #a8ce98",
                  backgroundColor: "#E5F8E5",
                }}
              >
                <p onFocus={this.handleParagraphFocus} tabIndex="-1">
                  Please complete the registration form below. You will be
                  required to upload an electronic copy of your
                  government-issued photo ID (state ID, driver’s license) and a
                  copy of your professional license(s) as applicable.
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="card card-body pl-3 pr-3 pt-3 pb-0"
                style={{
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #ccc",
                }}
              >
                <div className="row">
                  <div className="col-md-6">
                    {this.renderSelect(
                      "Grant_Program",
                      "Select the grant program",
                      filterCategory,
                      "name",
                      "name"
                    )}
                  </div>
                  <div className="col-md-6">
                    {this.renderSelect(
                      "Grant_Program_Subtype",
                      "Select the grant program type",
                      subCate,
                      "name",
                      "name"
                    )}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput("Organisation_Name", "Organization Name")}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="workflowId"
                        className="form-control-label"
                      >
                        Organization Type
                      </label>
                      <select
                        className="form-control"
                        name="userType"
                        id="userType"
                        value={data["userType"]}
                        onChange={this.handleUserTypeChanged}
                      >
                        <option value="0">Select</option>
                        {workflowUserTypes.map((s) => (
                          <option key={s.id} value={s.name}>
                            {s.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label
                        htmlFor="workflowId"
                        className="form-control-label"
                      >
                        Select the Application you are applying for
                      </label>
                      <select
                        className="form-control"
                        name="workflowId"
                        id="workflowId"
                        value={data["Application_Type"]}
                      >
                        <option value="0">Select</option>
                        {applications.map((s) => (
                          <option key={s.workflowId} value={s.workflowTitle}>
                            {s.workflowTitle}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="card card-body pl-3 pr-3 pt-3 pb-0"
                style={{
                  backgroundColor: "#D9EFFC",
                  border: "1px solid #92d8e1",
                }}
              >
                <div className="row">
                  <div className="col-md-6">
                    {this.renderInput(
                      "Organisation_Address1",
                      "Organisation Address Line 1"
                    )}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput(
                      "Organisation_Address2",
                      "Organisation Address Line 2"
                    )}
                  </div>
                  <div className="col-md-3">
                    {this.renderInput(
                      "Organisation_City",
                      "Organisation US City"
                    )}
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="Organisation_State"
                        className="form-control-label"
                      >
                        Organisation US State
                      </label>
                      <select
                        className="form-control"
                        name="Organisation_State"
                        id="Organisation_State"
                        value={orgStateId}
                        onChange={this.handleStateChanged}
                        aria-label="US State"
                      >
                        <option value="0">Select</option>
                        {stateList.map((s) => (
                          <option key={s.geonameId} value={s.geonameId}>
                            {s.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    {this.renderInput(
                      "Organisation_Zip",
                      "Organisation Zip Code"
                    )}
                  </div>
                  <div className="col-md-3">
                    {this.renderInput(
                      "Organisation_County",
                      "Organisation US County"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="card card-body pl-3 pr-3 pt-3 pb-0"
                style={{
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #ccc",
                }}
              >
                <div className="row">
                  <div className="col-md-6">
                    {this.renderInput(
                      "Duns",
                      "Data Universal Number System (DUNS) #"
                    )}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput("Federal_Ein", "Federal EIN")}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput(
                      "Organisation_Contact_Name",
                      "Organization’s Contact Name"
                    )}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput("Contact_Title", "Contact Title")}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput(
                      "Contact_Phone_Number",
                      "Contact Phone Number"
                    )}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput("Contact_Email", "Contact Email")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <h4 className="text-red">
                <i className="fa fa-hand-o-right" aria-hidden="true"></i>{" "}
                Individual filing the application
              </h4>
            </div>
            <div className="col-md-12">
              <div
                className="card card-body pl-3 pr-3 pt-3 pb-0"
                style={{
                  backgroundColor: "#D9EFFC",
                  border: "1px solid #92d8e1",
                }}
              >
                <div className="row">
                  <div className="col-md-2">
                    {this.renderSelect(
                      "Title",
                      "Title",
                      titles,
                      "name",
                      "name"
                    )}
                  </div>
                  <div className="col-md-3">
                    {this.renderInput(
                      "First_Name",
                      `${
                        data["userType"] !== "applicant"
                          ? "Your First Name"
                          : "Your First Name"
                      }`
                    )}
                  </div>
                  <div className="col-md-3">
                    {this.renderInput("Middle_Name", "Middle Name")}
                  </div>
                  <div className="col-md-4">
                    {this.renderInput("Last_Name", "Last Name")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="card card-body pl-3 pr-3 pt-3 pb-0"
                style={{
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #ccc",
                }}
              >
                <div className="row">
                  <div className="col-md-6">
                    {this.renderInput("Address_Line1", "Address Line 1")}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput("Address_Line2", "Address Line 2")}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3">
                    {this.renderInput("US_City", "US City")}
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="US_State" className="form-control-label">
                        US State
                      </label>
                      <select
                        className="form-control"
                        name="US_State"
                        id="US_State"
                        value={stateId}
                        onChange={this.handleStateChanged}
                        onFocus={this.handleDropdwonFocus}
                        aria-label="US State"
                      >
                        <option value="0">Select</option>
                        {stateList.map((s) => (
                          <option key={s.geonameId} value={s.geonameId}>
                            {s.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    {this.renderInput("Zip_Code", "Zip Code")}
                  </div>
                  <div className="col-md-3">
                    {this.renderInput("US_County", "US County")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="card card-body pl-3 pr-3 pt-3 pb-0"
                style={{
                  backgroundColor: "#D9EFFC",
                  border: "1px solid #92d8e1",
                }}
              >
                <div className="row">
                  <div className="col-md-6">
                    {this.renderInput("Email", "Email Address")}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput("Mobile", "Mobile No.")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {this.renderSelect(
                      "question",
                      "Password Hint Question",
                      hintQuestions,
                      "name",
                      "id"
                    )}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput("answer", "Password Hint Answer")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleRegShowHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ViewRegistration;
