import React, { Component } from "react";

class CallCenterHexagon extends Component {
  render() {
    return (
      <div id="content">
        <div id="page">
          <div id="data_wrapper">
            <div id="data">
              <div>
                <div className="item main r1 c2 even_row pre" rel="pre">
                  <a href="!#">&nbsp;</a>
                </div>
                <div className="item pre r2 c2">
                  <div className="image">
                    <a href="/ManagePartnerPerformer">
                      <img
                        src="../../assets/HexagonDesign/cells/1_1.png"
                        title="User Authorization Access"
                      />
                    </a>
                  </div>
                </div>
                <div className="item pre r2 c3">
                  <div className="image">
                    <a href="/HCPSurvey">
                      <img
                        src="../../assets/HexagonDesign/cells/1_20.png"
                        title="Manage Survey"
                      />
                    </a>
                  </div>
                </div>
                <div className="item even_row pre r3 c2">
                  <div className="image">
                    <a href="/ChatMsg">
                      <img
                        src="../../assets/HexagonDesign/cells/1_13.png"
                        title="Messaging"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="item main r1 c3 even_row create" rel="create">
                  <a href="!#">&nbsp;</a>
                </div>
                <div className="item create r2 c4">
                  <div className="image">
                    <a href="/ManageApplication">
                      <img
                        src="../../assets/HexagonDesign/cells/2_2.png"
                        title="Application Received"
                      />
                    </a>
                  </div>
                </div>
                <div className="item even_row create r3 c3">
                  <div className="image">
                    <a href="/CallCenterSearch">
                      <img
                        src="../../assets/HexagonDesign/cells/2_26.png"
                        title="Call Center Search"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CallCenterHexagon;
