import React, { useEffect, useState } from "react";
import { documentDownloaded, downloadDocument, getDownloadedDocumentData } from "../store/documents";
import { useDispatch, useSelector } from "react-redux";
import { getAlert } from "../../services/appAlerts";

const useDocDownload = () => {
    const [doc,setDoc]=useState(null);
    const downloadedDocumentDataSelector = useSelector(getDownloadedDocumentData);

    const dispatch=useDispatch();
    const handleDownloadFile = async (item) => {
        if (item.docId)
            dispatch(downloadDocument(item.docId));
        else
            dispatch(downloadDocument(item.id));

        setDoc(item);
    };

    useEffect(() => {
        if (downloadedDocumentDataSelector && doc) {
            try {
                const url = window.URL.createObjectURL(downloadedDocumentDataSelector);
                const link = document.createElement("a");
                link.href = url;
                link.target = "_blank";
                
                if (doc?.docName)
                    link.setAttribute("download", doc.docName);
                else if (doc?.documentName)
                    link.setAttribute("download", doc.documentName);
                else
                    link.setAttribute("download", "ipcs_document");

                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                dispatch(documentDownloaded(null));
            } catch (error) {
                getAlert("", error?.message, "error");
            }
        }
    }, [downloadedDocumentDataSelector]);

    // return <button
    //     className="btn btn-link btn-sm text-left"
    //     title="Download File"
    //     onClick={handleDownloadFile}
    // >
    //     {doc.docName}
    // </button>
    return [handleDownloadFile]

}

export default useDocDownload;