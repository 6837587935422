import React, { Component, useEffect, useState } from "react";
import PageHeader from "../PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { getCandidateApps, getFilterCandidates, loadCandidateApps, loadCandidatesByFilter } from "../../app/store/candidate";
import { useDispatch, useSelector } from "react-redux";
import { getFieldData, getFormatedNumber } from "../../services/appService";
import CandidateInfo from "../../app/components/CandidateInfo";
import Subcontractor from "../../app/components/Invoices/Subcontractor";
import Recruiter from "../../app/components/Invoices/Recruiter";
import Agency from "../../app/components/Invoices/Agency";
import { Tab, Tabs } from "react-bootstrap";

export default function CadidateDetails(props) {

  const { type } = props;

  let pgTitle = "Time Log Management";
  if (type === "invoice")
    pgTitle = "Invoics and Payments";

  const route = [
    { name: "Onboarding and Contracts", url: "" },
    {
      name: "Candidates",
      url: "/ListCondidate/" + type,
    },
    {
      name: "Working Hub",
      url: "/ListCondidate/" + type,
    }];

  const [selectedItem, setSelectedItem] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [candidateObj, setCandidateObj] = useState("");
  const [wfTask, setWfTask] = useState(null);

  const candidatesSelector = useSelector(getFilterCandidates);
  const candidateAppsSelector = useSelector(getCandidateApps);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const state = location.state;
    if (state?.item) {
      setSelectedItem(state.item);
      getCandidateDetails(state.item.id)
    }
    if (state?.type) {
      setSelectedType(state.type);
    }
  }, []);

  const getCandidateDetails = async (candidateId) => {
    const frmBody = new FormData();
    frmBody.append("id", candidateId);
    frmBody.append("name", "");
    frmBody.append("email", "");
    frmBody.append("entity", "");
    frmBody.append("type", "candidate");
    frmBody.append("filterType", "single");
    dispatch(loadCandidatesByFilter(frmBody));
  };

  useEffect(() => {
    if (candidatesSelector.length > 0){
      setCandidateObj(candidatesSelector[0]);
      const apps=candidatesSelector[0].applications;
      if(apps.length > 0){
        dispatch(loadCandidateApps(apps[0].applicantId, "admin", 1, apps[0].ciid));
      }
    }
  }, [candidatesSelector]);

  useEffect(() => {
    if (candidateAppsSelector.length > 0) {
      const latestApp = candidateAppsSelector[0];
    
      let wfTasks = latestApp.workflowTasks.filter(
        (x) => x.itemType === 1 && x.wiid > 0 && x.taskDoneStatus === 2 //&& x.formType === "WF"
      ).sort((a, b) => a.orderNumber - b.orderNumber);

      if (wfTasks.length === 0) {
        wfTasks = latestApp.workflowTasks.filter(
          (x) => x.itemType === 1 && x.wiid > 0 && x.taskDoneStatus === 4 //&& x.formType === "WF"
        ).sort((a, b) => b.orderNumber - a.orderNumber);
      }
      const latestTask = wfTasks[0];
      setWfTask(latestTask);
      
    }
  }, [candidateAppsSelector])

  let item = "";
  let entType = "";
  let dfKey = 0;
  const ptype = selectedType;
  if (candidateObj !== "") {
    item = candidateObj;
    const perfData = item?.performerData;
    entType = getFieldData(
      perfData,
      "candidateonboardingform",
      "CandidateEntity"
    );
    if (!(entType === "C2C Basis" || ptype === "timelog")) dfKey = 1;
  }
 
  return (
    <React.Fragment>

      <div className="col-md-12 text-right">
        <button
          className="btn btn-sm btn-outline-default btn-min-width mb-2"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-arrow-left mr-2"></i>Back
        </button>
      </div>

      {item !== "" &&
        <div className="col-md-12 form-group">
          <CandidateInfo candidate={item} />
        </div>
      }

      <div className="col-md-12">
        <Tabs defaultActiveKey={dfKey} id="uncontrolled-tab-example">
          <Tab
            eventKey={0}
            title={`${ptype === "invoice"
              ? "Subcontractor Invoices"
              : "Time Log Management"
              }`}
            className="border p-3 border-top-0"
            tabClassName="h4"
          >
            <Subcontractor candidate={item} ptype={ptype} entType={entType} wfTask={wfTask} />
          </Tab>
          {ptype === "invoice" && <Tab
            eventKey={1}
            title="Recruiter Invoices"
            className="border p-3 border-top-0"
            tabClassName="h4"
          >
            {item.recruiter && (
              <Recruiter candidate={item} ptype={ptype} entType={entType} wfTask={wfTask} />
            )}
          </Tab>}
          {ptype === "invoice" && <Tab
            eventKey={2}
            title="Agency/Client Invoices"
            className="border p-3 border-top-0"
            tabClassName="h4"
          >
            <Agency candidate={item} ptype={ptype} entType={entType} wfTask={wfTask} />
          </Tab>}
        </Tabs>
      </div>


      {/* 
      <ToolsPopup
        toolShowHide={toolShowHide}
        handleToolShowHide={this.handleToolShowHide}
        toolObj={toolObj}
        workItem={workItem}
        uploadType={uploadType}
        candidateObj={item}
      ></ToolsPopup>
      
       */}
    </React.Fragment>
  );
}
