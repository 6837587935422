import React, { Component, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import config from "../../config.json";
import Moment from "react-moment";
import { calculateIndex } from "./paginate"
import Help from "./Help";
import Pagination from "../../comman/pagination";
import PageSizeOptions from "../PageSizeOptions";
import { useDispatch, useSelector } from "react-redux";
import { getSupportTickets, loadSupportTickets, sendSupportTicketsMessage } from "../../app/store/support";
import { getCurrentUser } from "../../services/authService";
import { getStartedAppData, startNewApplication } from "../../app/store/application";
import { getCurrentWorkflowTask, loadCurrentWorkflowTask } from "../../app/store/WorkflowEngine";
import { getFieldData } from "../../services/appService";
import WorkflowElement from "../../app/components/WorkflowElement";
import useDocDownload from "../../app/hooks/useDocDownload";
import MessagingPopUpMod from "../PortalForms/MessagingPopUpMod"

const SupportPupup = (props) => {

  const { issueShowHide, handleIssueShowHide } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);
  const [totalCount, setTotalCount] = useState(5);

  const [supportTickets, setSupportTicketes] = useState([]);
  const [btnProcess, setBtnProcess] = useState(false);
  const [reqCount, setReqCount] = useState(0);
  const [workflowTask, setWorkflowTask] = useState(null);
  const [helpShowHide, setHelpShowHide] = useState(false);
  const [msgObj, setMsgObj] = useState(null);
  const [msgShowHide, setMsgShowHide] = useState(false);
  const [supObj, setSupObj] = useState(null);

  const supportTicketsSelector = useSelector(getSupportTickets);
  const appStartedDataSelector = useSelector(getStartedAppData);
  const currentWorkflowTaskSelector = useSelector(getCurrentWorkflowTask);

  const [handleDownloadFile] = useDocDownload();

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    //dispatch(loadSupportTickets(currentUser.id, pageSize, currentPage));
  }, []);

  useEffect(() => {
    if (supportTicketsSelector.length > 0) {
      setSupportTicketes(supportTicketsSelector);
      setTotalCount(supportTicketsSelector[0].documentCount);
    }
  }, [supportTicketsSelector]);

  const handleRunApplication = () => {
    setBtnProcess(true);
    const newApplication = {
      workflowId: config.WorkflowIds.SupportWorkfowId,
      performerId: parseInt(currentUser.id),
      applicationId: 1,
    };
    dispatch(startNewApplication(newApplication));
    setHelpShowHide(true);
  };

  useEffect(() => {
    if (appStartedDataSelector?.ciid > 0) {
      setTimeout(() => {
        dispatch(loadCurrentWorkflowTask(appStartedDataSelector));
        setReqCount(1);
      }, 3000);
    }
  }, [appStartedDataSelector]);

  useEffect(() => {
    //console.log(reqCount);
    if (currentWorkflowTaskSelector?.formId === null && reqCount <= 3) {
      setTimeout(async () => {
        dispatch(loadCurrentWorkflowTask(appStartedDataSelector));
        const rqCount = reqCount + 1;
        setReqCount(rqCount)
      }, 3000);
    }
    else if (currentWorkflowTaskSelector?.wiid > 0) {
      setWorkflowTask(currentWorkflowTaskSelector);
      setBtnProcess(false);
    }
    else if (reqCount == 0 || reqCount >= 3) {
      setReqCount(0);
      setWorkflowTask(null);
      dispatch(loadSupportTickets(currentUser.id, pageSize, 1));
      setBtnProcess(false);
    }
  }, [currentWorkflowTaskSelector]);

  const handleNextTask = () => {
    setTimeout(() => {
      dispatch(loadCurrentWorkflowTask(workflowTask));
      setReqCount(1);
    }, 3000);
  };


  const handleMessagePopup = (msg) => {
    if (!msg) {
      setMsgObj(null);
      setMsgShowHide(false);
    }
    else {
      setSupObj(msg);
      const obj = { ...msg, id: msg.commId };
      setMsgShowHide(!msgShowHide);
      setMsgObj(obj);
    }
  };

  const handleHelpShowHide = async () => {
    setHelpShowHide(!helpShowHide);
  }

  const handlePageSizeChanged = ({ currentTarget }) => {
    setPageSize(currentTarget.value);
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(5);
    dispatch(loadSupportTickets(currentUser.id, currentTarget.value, currentPage));
  };

  const handlePageChange = (page) => {
    const { startIndex: start, endIndex: end } = calculateIndex(page, startIndex, endIndex);
    setCurrentPage(page);
    setStartIndex(start);
    setEndIndex(end);
    dispatch(loadSupportTickets(currentUser.id, pageSize, page));
  };

  const handleSendMessage = () => {

  }

  const handleSupportMessage = (msg) => {
    // if (wfId > 0)
    //   dispatch(loadAppMessages(wfId, currentUser.id));
    if (msg !== "") {
      const suppportObj = {
        id: supObj.ciid,
        message: msg,
        userId: parseInt(currentUser.id),
        commId: supObj.commId,
        performerId: supObj.performerId,
        ciid: supObj.ciid,
        wiid: supObj.wiid,
        iiid: supObj.iiid,
        //status:supObj.appApproved
      };
      dispatch(sendSupportTicketsMessage(suppportObj));
      //setSupObj(null);
    }

  }

  return (
    <>
      <Modal show={issueShowHide} size="xl">
        <Modal.Header className="bg-danger" closeButton onHide={handleIssueShowHide}>
          <Modal.Title className="text-white text-uppercase mt-1">
            WorkFINITY® CUSTOMER SUPPORT PORTAL
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          {workflowTask &&
            <div className="col-md-12">
              <WorkflowElement wfTask={workflowTask}
                handleNextTask={handleNextTask}
                actionType={"workflow"}
              />
            </div>
          }

          {!workflowTask && (
            <div className="row">
              <div className="col-md-8">
                <PageSizeOptions
                  pageSize={pageSize}
                  onChange={handlePageSizeChanged}
                ></PageSizeOptions>
              </div>
              <div className="col-md-4 text-right">
                <button
                  className="btn btn-outline-default btn-sm mr-2"
                  onClick={handleRunApplication}
                  disabled={btnProcess}
                >

                  {btnProcess ? <i className="fa fa-spinner fa-spin"></i> : <i class="fa fa-plus" aria-hidden="true"></i>} Add Issue
                </button>
              </div>
              <div className="col-md-12 mt-3">
                <div className="table-responsive bg-white border border-top-0">
                  <table className="table align-items-center table-flush table-hover table-striped mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>S.No.</th>
                        <th>Date & Time</th>
                        <th>Issue/Defect Name</th>
                        <th>Attachment</th>
                        <th>Ticket ID</th>
                        <th>Discussion</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {supportTickets.map((item, key) => (
                        <tr>
                          <td>{key + 1}</td>
                          <td>
                            {item.startDateTime !== null && (
                              <Moment format="MMM Do YYYY h:mm a">
                                {item.startDateTime}
                              </Moment>
                            )}
                          </td>
                          <td>
                            {getFieldData(
                              item.stateData,
                              "customersupportportal",
                              "Issue_Name"
                            )}
                          </td>
                          <td>
                            {item.documents?.map((doc) => (
                              <li className="list-group-item p-1">
                                <button
                                  className="btn btn-sm btn-link"
                                  onClick={() => handleDownloadFile(doc)}
                                >
                                  {doc.documentName}
                                </button>
                              </li>
                            ))}
                          </td>
                          <td>{item.ciid}</td>
                          <td>
                            <button className="btn btn-link btn-sm" onClick={() => handleMessagePopup(item)}>
                              View/Reply
                              {item.unreadCount > 0 &&
                                <span className="badge badge-pill badge-danger position-absolute mt--1 ml-0">
                                  {item.unreadCount}
                                </span>}
                              {item.readCount > 0 && item.unreadCount === 0 &&
                                <span className="badge badge-pill badge-success text-dark position-absolute mt--1 ml-0">
                                  {item.readCount}
                                </span>}
                            </button>
                          </td>
                          <td>
                            {item.appApproved ? <span>
                              Fixed
                            </span> : <span>
                              Pending
                            </span>}

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={currentPage}
                    pageSize={pageSize}
                    itemsCount={totalCount}
                    onPageChange={handlePageChange}
                    startIndex={startIndex}
                    endIndex={endIndex}
                  ></Pagination>
                </div>

              </div>

            </div>
          )}

        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleIssueShowHide("")}>
            <i className="fa fa-times" aria-hidden="true"></i> Close
          </button>
        </Modal.Footer>
      </Modal>


      <Help helpShowHide={helpShowHide} handleHelpShowHide={handleHelpShowHide} module="Support"></Help>

      <MessagingPopUpMod
        msgShowHide={msgShowHide}
        message={msgObj}
        handleMsgModalShowHide={handleMessagePopup}
        handleSendMessage={handleSendMessage}
        handleSupportMessage={handleSupportMessage}
      ></MessagingPopUpMod>

    </>
  );
}

export default SupportPupup;
