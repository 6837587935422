import React, { Component } from "react";
import Form from "../comman/form";
import http from "../services/HttpService";
import auth from "../services/authService";
import config from "../config.json";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Joi from "joi-browser";
import { getAlert } from "../services/appAlerts";

class AddSecurityQuestion extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      securityQuestions1: [],
      securityQuestions2: [],
      securityQuestions3: [],
    };
  }

  async componentDidMount() {
    const { data: securityQuestions } = await http.get(
      config.GetCodemanager + "Security Question 1"
    );
    this.setState({
      securityQuestions1: securityQuestions,
      securityQuestions2: securityQuestions,
      securityQuestions3: securityQuestions,
    });
  }

  schema = {
    oldPassword: Joi.string().required().label("Existing Password"),
    // newPassword: Joi.string().required().label("New Password"),
    // confirmPassword: Joi.any()
    //   .valid(Joi.ref("newPassword"))
    //   .required()
    //   .options({ language: { any: { allowOnly: "must match new password" } } })
    //   .label("Confirm Password"),
    hintQuestion1: Joi.string().required().label("Security Question 1"),
    hintQuestion2: Joi.string().required().label("Security Question 2"),
    hintQuestion3: Joi.string().required().label("Security Question 3"),
    hintAnswer1: Joi.string().required().label("Answer 1"),
    hintAnswer2: Joi.string().required().label("Answer 2"),
    hintAnswer3: Joi.string().required().label("Answer 3"),
  };

  doSubmit = async () => {
    const { data } = this.state;
    const passResult = this.password_validate(data["newPassword"]);
    // if (passResult) {
    const { userDetails } = this.props;
    data["id"] = userDetails.performerid;
    const { data: result } = await http.post(
      config.AddSecurityQuestion,
      data
    );
    if (result === false) {
      getAlert("Existing Password Incorrect", "", "error");
    } else {
      getAlert("", "Saved Successfully", "success");
    }
    // } else {
    //   this.getAlert(
    //     "Password Strength",
    //     "Please enter strong password",
    //     "error"
    //   );
    // }
  };

  password_validate(password) {
    var re = {
      capital: /[A-Z]/,
      digit: /[0-9]/,
      except: /[aeiou]/,
      full: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][A-Za-z0-9]/,
    };
    //console.log("capital", re.capital.test(password));
    //console.log("digit", re.digit.test(password));
    //console.log("except", re.except.test(password));
    //console.log("full", re.full.test(password));
    return (
      re.capital.test(password) &&
      re.digit.test(password) &&
      re.except.test(password) &&
      re.full.test(password)
    );
  }

  getAlert(title, message, type) {
    return Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: "Close",
      confirmButtonColor: "#d33",
      // onClose: () => {
      //   if (type === "success") {
      //     auth.logout();
      //     window.location = "/Login";
      //   }
      // },
    });
  }

  render() {
    let { securityQuestions1, securityQuestions2, securityQuestions3, data } =
      this.state;
    const { securityShowHide, handleSecurityShowHide } = this.props;

    if (data["hintQuestion1"] !== undefined && data["hintQuestion1"] !== "") {
      securityQuestions2 = securityQuestions2.filter(
        (x) => x.id !== parseInt(data["hintQuestion1"])
      );
      securityQuestions3 = securityQuestions3.filter(
        (x) => x.id !== parseInt(data["hintQuestion1"])
      );
    }
    if (data["hintQuestion2"] !== undefined && data["hintQuestion2"] !== "") {
      securityQuestions1 = securityQuestions1.filter(
        (x) => x.id !== parseInt(data["hintQuestion2"])
      );
      securityQuestions3 = securityQuestions3.filter(
        (x) => x.id !== parseInt(data["hintQuestion2"])
      );
    }
    if (data["hintQuestion3"] !== undefined && data["hintQuestion3"] !== "") {
      securityQuestions1 = securityQuestions1.filter(
        (x) => x.id !== parseInt(data["hintQuestion3"])
      );
      securityQuestions2 = securityQuestions2.filter(
        (x) => x.id !== parseInt(data["hintQuestion3"])
      );
    }
    return (
      <Modal show={securityShowHide} size="xl">
        <Modal.Header closeButton onHide={handleSecurityShowHide}>
          <Modal.Title>Change Security Questions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border pb-0">
          <div className="row">
            <div className="col-md-12 pb-2">
              <p className="text-red">
                <i className="fa fa-hand-o-right" aria-hidden="true"></i> Choose
                your security questions for recovery of your User ID, Username
                and Password
              </p>
            </div>
            <div className="col-md-12">
              {this.renderInput("oldPassword", "Password", "password")}
            </div>
            {/* <div className="col-md-6"></div>
            <div className="col-md-12">
              {this.renderInput("newPassword", "New Password", "password")}
            </div> */}
            {/* <div className="col-md-6">
              {this.renderInput(
                "confirmPassword",
                "Confirm Password",
                "password"
              )}
            </div> */}

            <div className="col-md-12">
              {/* <p className="text-red">
                <i className="fa fa-hand-o-right" aria-hidden="true"></i> Choose
                your security questions for recovery of your User ID, Username
                and Password
              </p> */}
            </div>
            <div className="col-md-6">
              {this.renderSelect(
                "hintQuestion1",
                "Security Question 1",
                securityQuestions1,
                "name",
                "id"
              )}
            </div>
            <div className="col-md-6">
              {this.renderInput("hintAnswer1", "Enter Answer 1", "password")}
            </div>
            <div className="col-md-6">
              {this.renderSelect(
                "hintQuestion2",
                "Security Question 2",
                securityQuestions2,
                "name",
                "id"
              )}
            </div>
            <div className="col-md-6">
              {this.renderInput("hintAnswer2", "Enter Answer 2", "password")}
            </div>
            <div className="col-md-6">
              {this.renderSelect(
                "hintQuestion3",
                "Security Question 3",
                securityQuestions3,
                "name",
                "id"
              )}
            </div>
            <div className="col-md-6">
              {this.renderInput("hintAnswer3", "Enter Answer 3", "password")}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={handleSecurityShowHide}>
            <i class="fa fa-times" aria-hidden="true"></i> Close
          </button>
          <button
            className="btn btn-sm btn-outline-default"
            disabled={this.validate()}
            onClick={this.handleSubmit}
          >
            <i class="fa fa-check" aria-hidden="true"></i> Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddSecurityQuestion;
