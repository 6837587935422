import React, { Component, useEffect, useState } from "react";
import { Popover, Button, OverlayTrigger, Tabs, Tab } from "react-bootstrap";
import PhoneCallApplicantPopup from "./PhoneCallApplicantPopup";
import Moment from "react-moment";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import config from "../../config.json";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addAppPhoneCall, deleteAppPhoneCall, getAppPhoneCalls, getAppPhoneCallsAddedStatus, getAppPhoneCallsDeletedStatus, getAppPhoneCallsUpdatedStatus, loadAppPhoneCalls, updateAppPhoneCall } from "../../app/store/phoneCall";
import { getCurrentUser } from "../../app/store/auth";
import { getAlert, getConfirmAlert } from "../../services/appAlerts";

const PhoneCallApplicant = (props) => {
  const { workflowTask, handleUpdateComponent } = props;
  const [appPhoneCalls, setAppPhoneCalls] = useState([]);
  const [selectedPhoneCallId, setSelectedPhoneCallId] = useState(0);
  const [selectedPhoneCall, setSelectedPhoneCall] = useState(null);
  const [selectedKey, setSelectedKey] = useState("home");
  const [updateCompStatus, setUpdateCompStatus] = useState(false);

  const appPhoneCallsSelector = useSelector(getAppPhoneCalls);
  const appPhoneCallsAddedStatusSelector = useSelector(getAppPhoneCallsAddedStatus);
  const appPhoneCallsDeletedStatusSelector = useSelector(getAppPhoneCallsDeletedStatus);
  const appPhoneCallsUpdatedStatusSelector = useSelector(getAppPhoneCallsUpdatedStatus);

  const dispatch = useDispatch();
  const currentUser = getCurrentUser()

  useEffect(() => {
    if (workflowTask?.ciid) {
      dispatch(loadAppPhoneCalls(workflowTask.ciid))
      setUpdateCompStatus(true);
    }

  }, []);

  useEffect(() => {
    if (appPhoneCallsSelector.length > 0) {
      setAppPhoneCalls(appPhoneCallsSelector)
      let callItems = "";
      appPhoneCallsSelector.map((x, key) => {
        if (key > 0) callItems += ",";
        callItems += x.phoneId;
      })
      if (updateCompStatus) {
        handleUpdateComponent(callItems);
        setUpdateCompStatus(false);
      }
    }

  }, [appPhoneCallsSelector]);

  const handleSubmit = async (data) => {
    const phoneCell = {
      callDate: data["callDate"],
      callTime: data["callTime"],
      comments: data["comments"],
      duration: data["duration"],
      wiid: workflowTask.wiid,
      ciid: workflowTask.ciid,
      performerId: currentUser.id,
    };
    dispatch(addAppPhoneCall(phoneCell));
  };

  useEffect(() => {
    if (appPhoneCallsAddedStatusSelector && workflowTask?.ciid) {
      getAlert("", "Added Successfully", "success");
      dispatch(loadAppPhoneCalls(workflowTask.ciid));
      setUpdateCompStatus(true);
      setSelectedKey("home");
      setSelectedPhoneCallId(0);
    }
  }, [appPhoneCallsAddedStatusSelector]);

  useEffect(() => {
    if (appPhoneCallsUpdatedStatusSelector && workflowTask?.ciid) {
      getAlert("", "Updated Successfully", "success");
      dispatch(loadAppPhoneCalls(workflowTask.ciid));
      setSelectedKey("home");
      setSelectedPhoneCallId(0);
    }
  }, [appPhoneCallsUpdatedStatusSelector]);

  useEffect(() => {
    if (appPhoneCallsDeletedStatusSelector && workflowTask?.ciid) {
      getAlert("", "Deleted Successfully", "success");
      dispatch(loadAppPhoneCalls(workflowTask.ciid));
      setUpdateCompStatus(true);
    }
  }, [appPhoneCallsDeletedStatusSelector])


  const handleEditComment = (item) => {
    dispatch(updateAppPhoneCall(item));
  };

  const handleDeleteComment = async (item) => {
    const result = await getConfirmAlert("Warning", "Are you sure, you want to Delete this Phone Call Notes", "warning");
    if (result.isConfirmed) dispatch(deleteAppPhoneCall(item));
  };

  const handleSelectTab = (tab) => {
    setSelectedKey(tab);
  }

  const handleResetSelection = () => {
    setSelectedPhoneCallId(0);
    setSelectedPhoneCall(null);
  }

  const handleSelectPhoneCall = (phone) => {
    setSelectedPhoneCall(phone);
    setSelectedPhoneCallId(phone.phoneId);
  }

  return (
    <React.Fragment>
      <Tabs
        activeKey={selectedKey}
        onSelect={handleSelectTab}
        id="uncontrolled-tab-example"
        className="h3 border border-right-0 border-left-0 border-top-0 font-weight-normal"
      >
        <Tab eventKey="home" title="Phone Call Notes" tabClassName="page-tab border border-left-0 border-top-0">
          <Tab.Content className="border border-top-0">
            <div className="table-responsive">
              {/* <button className="btn btn-sm btn-link" onClick={handleResetSelection}>Reset Selection</button> */}
              <div className="table-responsive">
                <table className="table table-striped align-items-center table-flush">
                  <thead className="thead-light-green">
                    <tr>
                      <th>Select<span style={{ cursor: "pointer" }} onClick={handleResetSelection}>
                        <i className="fa fa-refresh ml-2 text-primary" aria-hidden="true"></i>
                      </span></th>
                      <th>S.No.</th>
                      <th>Date & Time</th>
                      <th>Call Date & Time</th>
                      <th>Duration (Minutes)</th>
                      <th>Notes</th>
                      <th>Added By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {appPhoneCalls.map((item, key) => (
                      <tr>
                        <td>
                          <div className="custom-control custom-radio">
                            <input
                              className="custom-control-input"
                              type="radio"
                              id={item.phoneId}
                              name="subcontractor"
                              onChange={() => handleSelectPhoneCall(item)}
                              value={selectedPhoneCallId}
                              checked={selectedPhoneCallId === item.phoneId}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={item.phoneId}
                            >
                            </label>
                          </div>
                        </td>
                        <td>{key + 1}</td>
                        <td>
                          <Moment format="MMM Do YYYY">{item.dateTime}</Moment>
                          <br />
                          <Moment format="h:mm a">{item.dateTime}</Moment>
                        </td>
                        <td>
                          <Moment format="MMM Do YYYY">{item.callDate}</Moment>
                          <br />
                          {moment(item.callTime?.replace(":00", ""), "hhmm").format(
                            "h:mm a"
                          )}
                        </td>
                        <td>{item.duration}</td>
                        <td>{item.comments}</td>
                        <td>
                          {item.performer}
                          <br />
                          {item.email}
                          <br />
                          {item.role}
                          {item.department}
                          {item.partner}
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-link p-0"
                            disabled={workflowTask.wiid === item.wiid ? false : true}
                            onClick={() => handleDeleteComment(item)}
                          >
                            <i className="fa fa-trash mr-1"></i>Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Tab.Content>
        </Tab>

        <Tab eventKey="contact" title={`${selectedPhoneCallId > 0 ? "Edit" : "Add"} Note`} tabClassName="page-tab border border-left-0 border-top-0">
          <Tab.Content className="border border-top-0 p-3">
            <PhoneCallApplicantPopup
              handleSubmit={handleSubmit}
              selectedItem={selectedPhoneCall}
              handleEditComment={handleEditComment}
            ></PhoneCallApplicantPopup>
          </Tab.Content>
        </Tab>

      </Tabs>


    </React.Fragment>
  );
}

export default PhoneCallApplicant;
