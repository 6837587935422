import React, { Component, useEffect, useState } from "react";
import Hexagon from "./PartnerManage/Hexagon";
import DashboardPageHeader from "./DashboardPageHeader";
import http from "../services/HttpService";
import config from "../config.json";
import loadjs from "loadjs";
import LineChart from "../Charts/line";
import BarChart from "../Charts/bar";
import PieChart from "../Charts/pie";
import { Link } from "react-router-dom";
import { Card, Accordion } from "react-bootstrap";
import PartnerManagement from "./PartnerManagement";
import AgencyManagement from "./AgencyManagement";
import ApplicationManagement from "./ApplicationManagement";
import ApplicationStatics from "./ApplicationStatics";
import auth from "../services/authService";
import PageHeader from "./PageHeader";
import TaskHistory from "./TaskHistory";
import Header from "./Header";
import AddSecurityQuestion from "./AddSecurityQuestion";
import { getCurrentUser } from "../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getAllAppTasks, getDashboardReportByMonth, getDashboardReportData, getWfPhases, loadAllAppTasks, loadDashboardReportByMonth, loadDashboardReportData, loadWfPhases } from "../app/store/dashboard";
import { setCurrentPageTitle, setCurrentRoute } from "../app/store/layoutUI";

const Dashboard = () => {

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     route: [],
  //     application: 50,
  //     users: 53,
  //     documents: 63,
  //     closed: 36,
  //     Application: 32,
  //     review: 56,
  //     approved: 96,
  //     pending: 65,
  //     withdrawn: 69,
  //     latestApplication: [],
  //     applicants: 62,
  //     fees: 653,
  //     workflowPerformer: 54,
  //     partnerPerformer: 63,
  //     dashboardData: {},
  //     applicationByMonth: {},
  //     selectedKey: -1,
  //     user: {},
  //     cipReports: [],
  //     workflowPhases: [],
  //     withdrawFundData: {},
  //     fundedData: {},
  //     securityShowHide: false,
  //     userDetails: "",
  //   };
  // }

  const [wfPhaseType, setWfPhaseType] = useState("WF Phases");
  const [workflowPhases, setWorkflowPhases] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);
  const [applicationByMonth, setApplicationByMonth] = useState(null);
  const [allAppTasks, setAllAppTasks] = useState([]);

  const wfPhasesSelector = useSelector(getWfPhases);
  const dashboardReportDataSelector = useSelector(getDashboardReportData);
  const dashboardReportByMonthSelector = useSelector(getDashboardReportByMonth);
  const allAppTasksSelector = useSelector(getAllAppTasks);

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();
  useEffect(() => {
    dispatch(setCurrentPageTitle("Dashboard"));
    dispatch(setCurrentRoute([]));
    dispatch(loadWfPhases(wfPhaseType));
    dispatch(loadDashboardReportData());
    dispatch(loadDashboardReportByMonth());
    dispatch(loadAllAppTasks());
  }, []);

  useEffect(() => {
    setWorkflowPhases(wfPhasesSelector);
  }, [wfPhasesSelector]);

  useEffect(() => {
    setDashboardData(dashboardReportDataSelector);
  }, [dashboardReportDataSelector]);

  useEffect(() => {
    setApplicationByMonth(dashboardReportByMonthSelector);
  }, [dashboardReportByMonthSelector]);

  useEffect(() => {
    setAllAppTasks(allAppTasksSelector);
  }, [allAppTasksSelector]);

  // async componentDidMount() {
  //   const user = auth.getCurrentUser();
  //   if (user?.id) {
  //     const userDetails = await auth.getCurrentUserDetails(user?.id);
  //     //console.log(userDetails);
  //     if (!userDetails?.hintQuestion) {
  //       this.setState({ securityShowHide: true, userDetails });
  //     }
  //   }

  // }

  const handleSelect = (e) => {
    //this.setState({ selectedKey: e });
  };

  const getWfPhasesItem = (name) => {
    const count = allAppTasks.filter(
      (x) => x.workflowPhase === name
    ).length;
    const color = getWfPhaseClassName(name);
    return (
      <li className="list-group-item px-0">
        <div className="row align-items-center">
          <div className="col-auto">
            <a href="!#" className={`avatar rounded-circle bg-${color}`}></a>
          </div>
          <div className="col">
            <h5>
              {name} ({count})
            </h5>
            <div className="progress progress-sm mb-0">
              <div
                className={`progress-bar bg-${color}`}
                role="progressbar"
                aria-valuenow={60}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: "60%" }}
              />
            </div>
          </div>
        </div>
      </li>
    );
  }

  const getWfPhaseClassName = (name) => {
    switch (name) {
      case "Application":
        return "primary";
      case "Onboarding":
        return "primary";
      case "Review":
        return "yellow";
      case "Pending":
        return "info";
      case "Approved":
        return "success";
      case "Denied":
        return "danger";
      case "Withdrawn":
        return "warning";
      case "Closed":
        return "default";
      case "Underwriting Phase":
        return "lighter";
    }
  }

  // handleSecurityShowHide = () => {
  //   this.setState({ securityShowHide: !this.state.securityShowHide });
  // };
  //console.log(applicationByMonth);
  return (
    <React.Fragment>
      <div className="col-md-12 mt-3">
        {dashboardData &&
          <DashboardPageHeader
            dashboardData={dashboardData}
          ></DashboardPageHeader>}

        <div className="row mb-4 mt-3">
          <div className="col-xl-7">
            <div className="card shadow-none border mb-0">
              <div className="card-header bg-transparent">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-muted text-uppercase ls-1 mb-1">
                      Overview
                    </h6>
                    <h5 className="h3 text-black mb-0">
                      Subcontractor Enrollment / Recruiter Enrollment /
                      Candidate Onboarding
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {applicationByMonth &&
                  <LineChart
                    graphData={applicationByMonth}
                    label={"Candidates"}
                  ></LineChart>}
              </div>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="card shadow-none border mb-0">
              <div className="card-header bg-transparent">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Overview
                    </h6>
                    <h5 className="h3 mb-0">
                      Subcontractor Enrollment / Recruiter Enrollment /
                      Candidate Onboarding
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {applicationByMonth &&
                  <BarChart
                    graphData={applicationByMonth}
                    label={"Candidates"}
                  ></BarChart>}
              </div>
            </div>
          </div>
        </div>

        {currentUser.type === "admin" && (
          <div className="row">
            <div className="col-md-12">
              <Accordion onSelect={handleSelect}>
                <Card className="shadow-none">
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="5"
                    className={"p-3 card-header bg-lighter"}
                  >
                    <h4 className="mb-0">
                      Real-time Application Statistics (Run-Time)
                    </h4>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="5">
                    <div className="col-md-12 pb-4 border">
                      <ApplicationStatics></ApplicationStatics>
                    </div>
                  </Accordion.Collapse>
                </Card>
                <hr />
              </Accordion>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-xl-5">
            <div className="card shadow-none border">
              <div className="card-header">
                <h5 className="h3 mb-0">
                  Onboarding and Project/Staffing Engagement Status - Current
                </h5>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush list my--3">
                  {workflowPhases.map((item) =>
                    getWfPhasesItem(item.name)
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-7">
            <div className="card shadow-none border mb-0">
              <div className="card-header bg-transparent">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Overview
                    </h6>
                    <h5 className="h3 mb-0">
                      Onboarding and Project/Staffing Engagement Status -
                      Current
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {applicationByMonth &&
                  <BarChart
                    graphData={applicationByMonth}
                    label={"Workflow Phases"}
                  ></BarChart>}
              </div>
            </div>
          </div>
        </div>

        {window.location.pathname === "/" && (
          <div className="row">
            <div className="col-xl-12 text-center mb-3 mt-3">
              <a href="/QuickLinks" className="btn btn-outline-default btn-sm mt-1">
                <i
                  className="fa fa-angle-double-right mr-1"
                  aria-hidden="true"
                ></i>
                Continue
              </a>
            </div>
          </div>
        )}
      </div>
      {/* <AddSecurityQuestion
        securityShowHide={securityShowHide}
        handleSecurityShowHide={this.handleSecurityShowHide}
        userDetails={userDetails}
      ></AddSecurityQuestion> */}
    </React.Fragment>
  );
}

export default Dashboard;
