import React, { Component, useEffect, useState } from "react";
import Joi from "joi-browser";
import { Modal, Button } from "react-bootstrap";
import Form from "../../../comman/form";
import BprmForm from "../../../comman/BprmForm";
import http from "../../../services/HttpService";
import auth, { getCurrentUser } from "../../../services/authService";
import config from "../../../config.json";
import ToastTemplate from "../../../comman/toastTemplate";
import { v4 as uuidv4 } from "uuid";
import geo from "../../../services/geoService";
import Swal from "sweetalert2";
import SubForm from "../../PortalForms/SubForm";
import countryCodes from "../../../services/countryCodes.json";
import WorkflowElement from "../../../app/components/WorkflowElement";
import { getAppDefaultData, getStartedAppData, loadAppDefaultData, startNewApplication } from "../../../app/store/application";
import { useDispatch, useSelector } from "react-redux";
import { addNewRecruiter, addNewRecruiterUser, getNewRecruiterId, getNewRecruiterUser, recruiterAdded } from "../../../app/store/recruiter";
import { getAlert } from "../../../services/appAlerts";
import { currentWfTaskReceived, getCurrentWorkflowTask, loadCurrentWorkflowTask } from "../../../app/store/WorkflowEngine";
import { addFormLibrary } from "../../../app/store/forms";

const EditPartner = (props) => {
  const { selectedObj,handleAddEditRecruiter } = props;
  const [wfTask, setWfTask] = useState({ formId: config.formIds.recruiter, ciid: 0 });
  const [appDefaultData, setAppDefaultData] = useState("");
  const [stateData, setStateData] = useState(null);
  const [updatedStateData, setUpdatedStateData] = useState(null);
  const [saveBtnProcessStatus, setSaveBtnProcessStatus] = useState(false);
  const [uuid, setUuid] = useState("");
  const [wfId,setWfId]=useState(config.workflowsIds.recruiter);
  const [reqCount,setReqCount]=useState(0);
  const [newRecruiterAddedStatus,setNewRecruiterAddedStatus]=useState(false);

  const appDefaultDataSelector = useSelector(getAppDefaultData);
  const newRecruiterIdSelector=useSelector(getNewRecruiterId)
  const newRecruiterUserSelector = useSelector(getNewRecruiterUser);
  const appStartedDataSelector = useSelector(getStartedAppData);
  const currentWorkflowTaskSelector = useSelector(getCurrentWorkflowTask);

  const currentUser = getCurrentUser();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAppDefaultData(1))
  }, []);

  useEffect(() => {
    if (appDefaultDataSelector !== "") {
      setAppDefaultData(appDefaultDataSelector);
    }
  }, [appDefaultDataSelector]);

  const handleSubmit = (stData) => {
    const aWorkfinityApp = { ...appDefaultData };
    const appShortName = Object.keys(aWorkfinityApp)[1];
    let aWorkfinityAppEntity = { ...aWorkfinityApp[appShortName] };

    let recruiterenrollment = {};
    const data = {};
    Object.keys(stData).forEach(function (keyValue) {
      const dd = keyValue.split(".");
      if (dd.length > 1) {
        if (dd[1] !== "undefined" && aWorkfinityAppEntity !== undefined)
          recruiterenrollment["@" + dd[1]] = stData[keyValue];
        data[dd[1]] = stData[keyValue];
      }
    });

    const guid = uuidv4();
    recruiterenrollment["EntityId"] = guid;
    data["EntityId"] = guid;
    const usrDdtype = "recruiterenrollmentform";
    aWorkfinityAppEntity[usrDdtype] = recruiterenrollment;
    aWorkfinityApp[appShortName] = aWorkfinityAppEntity;

    setStateData(data);
    setUuid(guid)
    setAppDefaultData(aWorkfinityApp);

    saveRecruiter(data);
  }

  const saveRecruiter = (data) => {
    let fuid = 0;
    if (selectedObj?.fuid)
      fuid = selectedObj.fuid;
    const partnerObj = {
      fuid: fuid,
      label: data["Company_Name"],
      state: data["Company_State"],
      city: data["Company_City"],
      zip: data["Company_Zip_Code"],
      partnerType: "Recruiter",
      contactName:
        data["Key_Contact1_First_Name"] +
        " " +
        data["Key_Contact1_Middle_Name"] +
        " " +
        data["Key_Contact1_Last_Name"],
      entryStartNumber: 1,
      entryEndNumber: 100,
      totalEntryRecords: 100,
      email: data["Key_Contact1_Email"],
      accessKey: data["Company_Address_Line1"],
      portalUrl: data["Company_Address_Line2"],
    };
    console.log(partnerObj);
    dispatch(addNewRecruiter(partnerObj));
  }

  useEffect(() => {
    if (newRecruiterIdSelector > 0) {
      if (newRecruiterIdSelector === -1) {
        getAlert("Warning", "Email already exists", "error");
        setSaveBtnProcessStatus(false);
      }
      else {
        saveRecruiterUser(newRecruiterIdSelector);
      }
    }
  }, [newRecruiterIdSelector]);

  const saveRecruiterUser=(partId)=>{
    const data = { ...stateData };
    let regId = 0;
    if (selectedObj?.id)
      regId = selectedObj?.id;

    const reg = {
      id: regId,
      first_Name: data["Key_Contact1_First_Name"],
      middle_Name: data["Key_Contact1_Middle_Name"],
      last_Name: data["Key_Contact1_Last_Name"],
      email: data["Key_Contact1_Email"],
      mobile: data["Key_Contact1_Mobile"],
      workflowId: wfId,
      entityId: uuid,
      question: "3151",
      answer: "Miami",
      type: "recruiter",
      address_Line1: data["Company_Address_Line1"],
      address_Line2: data["Company_Address_Line2"],
      us_State: data["Company_State"],
      us_County: data["Company_County"],
      us_City: data["Company_City"],
      zip_Code: data["Company_Zip_Code"],
      title: data["Key_Contact1_Title"],
      isTeamLead: true,
      partnerId: partId,
    };
    //console.log(reg);
    const aWorkfinityApp = { ...appDefaultData };
    const formData = new FormData();
    formData.append("appEntity", JSON.stringify(aWorkfinityApp));
    formData.append("registration", JSON.stringify(reg));
    dispatch(addNewRecruiterUser(formData));
    setSaveBtnProcessStatus(true);
  }

  useEffect(() => {
    if (newRecruiterUserSelector) {
      setSaveBtnProcessStatus(false);
      let regId = 0;
      if (selectedObj?.id)
        regId = selectedObj?.id;
      
      if (newRecruiterUserSelector.performerid === -1) {
        getAlert("Warning", newRecruiterUserSelector.performername, "error");
        setSaveBtnProcessStatus(false);
        handleAddEditRecruiter();
      }
      else{
        if (regId > 0) {
          getAlert("", "Updated Successfully", "success");
          setNewRecruiterAddedStatus(false);
        } else {
          getAlert("", "Saved Successfully", "success");
          handleRunApplication(newRecruiterUserSelector);
        }
        handleAddEditRecruiter()
      }
      
    }
  }, [newRecruiterUserSelector]);

  const handleRunApplication = async (userObj) => {
    const newApplication = {
      workflowId: userObj.workflowId,
      performerId: parseInt(userObj.performerid),
      applicationId: userObj.applicationId,
    };
    dispatch(startNewApplication(newApplication));
    setNewRecruiterAddedStatus(true);
    handleAddEditRecruiter();
  };

  useEffect(() => {
    if (appStartedDataSelector?.ciid > 0) {
      setTimeout(async () => {
        dispatch(loadCurrentWorkflowTask(appStartedDataSelector));
        setReqCount(1);
      }, 3000);
    }
  }, [appStartedDataSelector]);

  useEffect(() => {
    if (currentWorkflowTaskSelector?.formId === null && reqCount <= 2 && newRecruiterAddedStatus) {
      setTimeout(async () => {
        dispatch(loadCurrentWorkflowTask(appStartedDataSelector));
        const rqCount = reqCount + 1;
        setReqCount(rqCount)
      }, 3000);
    }
    // else if(currentWorkflowTaskSelector?.wiid > 0 && newRecruiterAddedStatus){
    //   const formsObj = [
    //     {
    //       formId: 47,
    //       formName: "ACH Payment Information",
    //       remarks:
    //         "This information is captured for making Payments to your company by ACH",
    //       formType: "ACH Information",
    //     },
    //     {
    //       formId: 22,
    //       formName: "Insurance Details",
    //       remarks:
    //         "Upload your company's insurance certificate(s) as per the contract requirements",
    //       formType: "Insurance Information",
    //     },
    //   ];
    //   setNewRecruiterAddedStatus(false);
    //   dispatch(currentWfTaskReceived(""));
    //   formsObj.map((item) => addReqFormLibrary(currentWorkflowTaskSelector, item));
    // }
  }, [currentWorkflowTaskSelector]);

  const addReqFormLibrary = async (currentTask, frmObj) => {
    let workflowTask = { ...currentTask };
    workflowTask["ApplicantId"] = workflowTask.performerId;
    workflowTask["performerId"] = currentUser.id;
    const frmData = new FormData();
    frmData.append("workflowTask", JSON.stringify(workflowTask));
    frmData.append("formId", frmObj.formId);
    frmData.append("formName", frmObj.formName);
    frmData.append("remarks", frmObj.remarks);
    frmData.append("id", "");
    frmData.append("formType", frmObj.formType);
    frmData.append("status", "1");
    dispatch(addFormLibrary(frmData));
  };

  useEffect(()=>{
    if (selectedObj?.contact1){
      setUpdatedStateData(selectedObj.contact1.performerData)
    }
    else{
      setUpdatedStateData(null);
    }
  },[selectedObj]);

  const handleNextTask = (data) => {
    handleSubmit(data);
  }

  return (
    <div className="col-md-12">
      <div className="row">
        
        {wfTask?.formId > 0 && <WorkflowElement
          wfTask={wfTask}
          handleNextTask={handleNextTask}
          viewType={false}
          appSummary={false}
          codeVerified={true}
          wfTaskType={"form"}
          saveBtnProcessStatus={saveBtnProcessStatus}
          setSaveBtnProcessStatus={setSaveBtnProcessStatus}
          updatedStateData={updatedStateData}
        />
        }
        <div className="col-md-12 text-center">
          {/* <Button
          variant="primary"
          disabled={btnProcess}
          onClick={this.doSubmit}
        >
          {btnProcess && (
            <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
          )}{" "}
          Save
        </Button> */}
        </div>
      </div>
    </div>
  );
}

export default EditPartner;
