import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllMonths, getAllYears, getTotalHours } from "../../../../../services/appService";
import { getMonthlyAchSubcontractor, loadMonthlyAchSubcontractor } from "../../../../store/reports";
import GeneratePdf from "../../../../../components/GeneratePdf";

const Subcontractor = () => {

    const years = getAllYears();
    const months = getAllMonths();

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [pdfShowHide, setPdfShowHide] = useState(false);
    const [selectedCol, setSelectedCol] = useState([]);
    const [selectedColData, setSelectedColData] = useState([]);
    const [subcontractorMonRpt, setSubcontractorMonRpt] = useState([]);

    const monthlyAchSubcontractorSelector = useSelector(getMonthlyAchSubcontractor);

    const dispatch = useDispatch();

    const handleGenerateRpt = async () => {
        if (year && month) dispatch(loadMonthlyAchSubcontractor(year, month));
    };

    useEffect(() => {
        setSubcontractorMonRpt(monthlyAchSubcontractorSelector);
    }, [monthlyAchSubcontractorSelector])

    const subcontMonthTotal = () => {
        let total = 0;
        subcontractorMonRpt.map((item) => {
            total = total + parseFloat(getTotalHours(item.workingHours) * item.rate);
        });
        return parseFloat(total).toFixed(2);
    };

    const subcontractorExportPDF = () => {
        const headers = [
            [
                "Subcontractor Name",
                "Candidate Name",
                "StartDate",
                "Client Name",
                "Invoice #",
                "Hours",
                "Rate/Hour",
                "Amount $",
                "Bank Name",
                "Routing #",
                "Account #",
                "Contact Email Address",
            ],
        ];
        let data = [];
        subcontractorMonRpt.map((item) =>
            data.push([
                item.name,
                item.candidate,
                item.startDate,
                item.client,
                item.invoice,
                getTotalHours(item.workingHours),
                item.rate,
                getTotalHours(item.workingHours) * item.rate,
                item.bank,
                item.rounting,
                item.account,
                item.achEmail,
            ])
        );
        setSelectedCol(headers);
        setSelectedColData(data);
        setPdfShowHide(true);

    };

    const handlepdfShowHide = () => {
        setPdfShowHide(false);
        setSelectedCol([]);
        setSelectedColData([]);
    };

    const btnDisabled = !(year && month)

    return <>
        <div className="card shadow-none border">
            <div className="card-header text-center p-3 bg-lighter">
                <h4 className="mb-0">
                    Monthly ACH - Payments to Subcontractors
                </h4>
            </div>
            <div className="card-body p-3">
                <div className="row">
                    <div className="col-md-3">
                        <select
                            name="year"
                            id="year"
                            className="form-control form-control-sm"
                            onChange={({ currentTarget }) => setYear(currentTarget.value)}
                            value={year}
                        >
                            <option value="">Year</option>
                            {years.map((y) => (
                                <option value={y.name}>
                                    {y.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <select
                            name="month"
                            id="month"
                            className="form-control form-control-sm"
                            onChange={({ currentTarget }) => setMonth(currentTarget.value)}
                            value={month}
                        >
                            <option value="">Month</option>
                            {months.map((m) => (
                                <option value={m.id}>{m.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button
                            className="btn btn-sm btn-outline-default"
                            disabled={btnDisabled}
                            onClick={handleGenerateRpt}
                        >
                            Generate
                        </button>
                    </div>
                    <div className="col-md-3 text-right">
                        <ReactHTMLTableToExcel
                            id="subcontractorTable"
                            className="btn btn-sm btn-outline-default"
                            table="sub_ach_pay"
                            filename="Subcontractor_ACH_Payment"
                            sheet="Subcontractor_ACH_Payment"
                            buttonText="Export to Excel"
                        />
                        <button
                            type="button"
                            className="btn btn-sm btn-outline-default"
                            disabled={subcontractorMonRpt.length === 0}
                            onClick={subcontractorExportPDF}
                        >
                            Generate PDF
                        </button>
                    </div>
                    <div className="col-md-12 mt-2">
                        <div className="table-responsive">
                            <table
                                className="table align-items-center table-flush table-hover table-striped mb-2 border"
                                id="sub_ach_pay"
                            >
                                <thead className="thead-light">
                                    <th>Subcontractor Name</th>
                                    <th>Candidate Name</th>
                                    <th>Start Date</th>
                                    <th>Client Name</th>
                                    <th>Invoice #</th>
                                    <th>Hours</th>
                                    <th>Rate/hour</th>
                                    <th>Amount $</th>
                                    <th>Bank Name</th>
                                    <th>Routing #</th>
                                    <th>Account #</th>
                                    <th>Contact Email Address</th>
                                </thead>
                                <tbody>
                                    {subcontractorMonRpt.map((item) => (
                                        <tr>
                                            <td>{item.name}</td>
                                            <td>{item.candidate}</td>
                                            <td>
                                                <Moment format="MMM Do YYYY">
                                                    {item.startDate}
                                                </Moment>
                                            </td>
                                            <td>{item.client}</td>
                                            <td>{item.invoice}</td>
                                            <td>
                                                {getTotalHours(item.workingHours)}
                                            </td>
                                            <td>{item.rate}</td>
                                            <td className="text-right">
                                                {parseFloat(
                                                    getTotalHours(item.workingHours) *
                                                    item.rate
                                                ).toFixed(2)}
                                            </td>
                                            <td>{item.bank}</td>
                                            <td>{item.rounting}</td>
                                            <td>{item.account}</td>
                                            <td>{item.achEmail}</td>
                                        </tr>
                                    ))}
                                    <tr className="bg-lighter">
                                        <td
                                            colSpan={7}
                                            className="text-right font-weight-bold"
                                        >
                                            Total
                                        </td>
                                        <td
                                            className="text-right font-weight-bold"
                                        >
                                            {subcontMonthTotal()}
                                        </td>
                                        <td colSpan={4}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <GeneratePdf
            handlepdfShowHide={handlepdfShowHide}
            pdfShowHide={pdfShowHide}
            selectedCol={selectedCol}
            selectedColData={selectedColData}
        ></GeneratePdf>
    </>
}

export default Subcontractor