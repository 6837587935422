import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "workflowEngine",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        ruleAppData: "",
        currentWfTask: "",
        taskAssignHistory: [],
        reAssignStatus: false,
        tskDoneStatus: false
    },
    reducers: {
        taskReceived: (workflowEngine, action) => {
            workflowEngine.list.push(action.payload);
        },
        taskDone: (workflowEngine, action) => {
            console.log(action.payload);
            workflowEngine.tskDoneStatus = false;
            workflowEngine.currentWfTask = ""

            if (action.payload?.status) {
                workflowEngine.tskDoneStatus = true;
            }

            if (action.payload?.id > 0) {
                workflowEngine.currentWfTask = action.payload
            }
        },
        ruleAppDataReceived: (workflowEngine, action) => {
            workflowEngine.ruleAppData = action.payload;
        },
        currentWfTaskReceived: (workflowEngine, action) => {
            workflowEngine.currentWfTask = action.payload;
        },
        taskAssignHistoryReceived: (workflowEngine, action) => {
            workflowEngine.taskAssignHistory = action.payload;
            workflowEngine.reAssignStatus = false;
        },
        taskReAssigned: (workflowEngine, action) => {
            workflowEngine.reAssignStatus = true;
        }
    }
});

export const { taskReceived, taskDone, ruleAppDataReceived,
    currentWfTaskReceived, taskAssignHistoryReceived, taskReAssigned } = slice.actions;
export default slice.reducer;

export const workflowTaskDone = (data) => apiCallBegan({
    url: config.SetWorkItemToDone,
    method: "post",
    data: data,
    onSuccess: taskDone.type
});

export const loadCurrentWorkflowTask = (data) => apiCallBegan({
    url: config.GetCurrentWorkflowTask,
    method: "post",
    data: data,
    onSuccess: currentWfTaskReceived.type
});

export const executeBprmFormRule = (data) => apiCallBegan({
    url: config.ExcuteFormRule,
    method: "post",
    data: data,
    onSuccess: ruleAppDataReceived.type
});

export const loadTaskReAssignHistory = (wiid) => apiCallBegan({
    url: config.GetTaskReAssignHistory + wiid,
    onSuccess: taskAssignHistoryReceived.type
});

export const taskReAssign = (data) => apiCallBegan({
    url: config.ReAssignTask,
    method: "post",
    data: data,
    onSuccess: taskReAssigned.type
});

export const getExecuteRuleAppData = createSelector(
    state => state.entities.workflowEngine,
    workflowEngine => workflowEngine.ruleAppData
);

export const getCurrentWorkflowTask = createSelector(
    state => state.entities.workflowEngine,
    workflowEngine => workflowEngine.currentWfTask
);

export const getTaskAssignHistory = createSelector(
    state => state.entities.workflowEngine,
    workflowEngine => workflowEngine.taskAssignHistory
);
export const getTaskAssignStatus = createSelector(
    state => state.entities.workflowEngine,
    workflowEngine => workflowEngine.reAssignStatus
);

export const getTaskDoneStatus = createSelector(
    state => state.entities.workflowEngine,
    workflowEngine => workflowEngine.tskDoneStatus
);
