import React, { Component, useEffect, useState } from "react";
import { Popover, Button, OverlayTrigger, Tab, Tabs } from "react-bootstrap";
import ScheduleMeetingPopup from "./ScheduleMeetingPopup";
import Moment from "react-moment";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import config from "../../config.json";
import { useDispatch, useSelector } from "react-redux";
import { addAppSchedule, deleteAppSchedule, getAppScheduleAddedStatus, getAppScheduleDeletedStatus, getAppScheduleUpdatedStatus, getAppSchedules, loadAppSchedules, updateAppSchedule } from "../../app/store/schedule";
import { getCurrentUser } from "../../app/store/auth";
import { getAlert, getConfirmAlert } from "../../services/appAlerts";
import { updateAppPhoneCall } from "../../app/store/phoneCall";

const ScheduleMeeting = (props) => {
  const { workflowTask,handleUpdateComponent, partitem } = props;
  
  const [appSchedules, setAppSchedules] = useState([]);
  const [selectedKey, setSelectedKey] = useState("home");
  const [selectedScheduleId, setSelectedScheduleId] = useState(0);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [updateCompStatus, setUpdateCompStatus] = useState(false);

  const appSchedulesSelector = useSelector(getAppSchedules);
  const appScheduleAddedStatus = useSelector(getAppScheduleAddedStatus);
  const appScheduleDeletedStatusSelector=useSelector(getAppScheduleDeletedStatus);
  const appScheduleUpdatedStatusSelector=useSelector(getAppScheduleUpdatedStatus);

  const dispatch = useDispatch();

  const currentUser = getCurrentUser();

  useEffect(() => {
    if (workflowTask?.ciid){
      dispatch(loadAppSchedules(workflowTask.ciid))
      setUpdateCompStatus(true);
    }
      
  }, []);

  useEffect(() => {
    if (appSchedulesSelector.length > 0) {
      console.log(appSchedulesSelector)
      setAppSchedules(appSchedulesSelector);
      let schItems = "";
      appSchedulesSelector.map((x, key) => {
        if (key > 0) schItems += ",";
        schItems += x.meetingId;
      })
      if (updateCompStatus) {
        handleUpdateComponent(schItems);
        setUpdateCompStatus(false);
      }
    }
  }, [appSchedulesSelector]);

  useEffect(() => {
    if (appScheduleAddedStatus) {
      getAlert("", "Sent Successfully", "success");
      if (workflowTask?.ciid){
        dispatch(loadAppSchedules(workflowTask.ciid));
        setUpdateCompStatus(true);
      }

      setSelectedScheduleId(0);
      setSelectedSchedule(null);
      setSelectedKey("home");
    }
  }, [appScheduleAddedStatus]);

  useEffect(() => {
    if (appScheduleUpdatedStatusSelector && workflowTask?.ciid) {
      getAlert("","Updated Successfully","success");
      dispatch(loadAppSchedules(workflowTask.ciid));
      setSelectedKey("home");
      setSelectedScheduleId(0);
    }
  }, [appScheduleUpdatedStatusSelector]);

  useEffect(() => {
    if (appScheduleDeletedStatusSelector && workflowTask?.ciid) {
      getAlert("","Deleted Successfully","success");
      if(workflowTask?.ciid){
        dispatch(loadAppSchedules(workflowTask.ciid));
        setUpdateCompStatus(true);
      }
    }
  }, [appScheduleDeletedStatusSelector])


  const getAcceptedMeetingDate = (meetingOptions) => {
    const meeting = meetingOptions.filter((x) => x.status === true);
    if (meeting.length > 0) {
      return (
        <ul className="list-group p-0">
          <li
            className="list-group-item p-2"
            style={{ backgroundColor: "#ffecb3", fontWeight: "bold" }}
          >
            <Moment format="MMM Do YYYY">{meeting[0].meetingDate}</Moment>
            &nbsp; {meeting[0].startTime}-{meeting[0].endTime}
          </li>
        </ul>
      );
    }

    return "";
  };

  const getMeetingStatus = (meetingOptions) => {
    const meeting = meetingOptions.filter((x) => x.status === true);
    if (meeting.length > 0) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (data, scheduleList, meetingJson, meetingProvider) => {
    const frmData = new FormData();
    frmData.append("comment", data["remarks"]);
    frmData.append("meetingJson", meetingJson);
    frmData.append("userId", currentUser.id);
    frmData.append("schedule", JSON.stringify(scheduleList));
    frmData.append("workflowTask", JSON.stringify(workflowTask));
    frmData.append("meetingProvider", meetingProvider);
    dispatch(addAppSchedule(frmData));
  };

  const handleEditAppointment = (item) => {
    dispatch(updateAppSchedule(item));
  };

  const handleDeleteAppointment = async (item) => {
    const result = await getConfirmAlert("Warning", "Are you sure, you want to Delete this Schedule Appointment?", "warning");
    if (result.isConfirmed) dispatch(deleteAppSchedule(item));
  };

  const handleSelectTab = (tab) => {
    setSelectedKey(tab);
  }

  const handleResetSelection = () => {
    setSelectedScheduleId(0);
    setSelectedSchedule(null);
  }

  const handleSelectSchedule = (sch) => {
    setSelectedSchedule(sch);
    setSelectedScheduleId(sch.meetingId);
  }

  return (
    <React.Fragment>
     
      <Tabs
        activeKey={selectedKey}
        onSelect={handleSelectTab}
        id="uncontrolled-tab-example"
        className="h3 border border-right-0 border-left-0 border-top-0 font-weight-normal"
      >
        <Tab eventKey="home" title="Appointments" tabClassName="page-tab border border-left-0 border-top-0">
          <Tab.Content className="border border-top-0">
            <div className="table-responsive">
              <table className="table table-striped align-items-center table-flush">
                <thead className="thead-light-green">
                  <tr>
                    <th>Select<span style={{cursor:"pointer"}} onClick={handleResetSelection}>
                              <i className="fa fa-refresh ml-2 text-primary" aria-hidden="true"></i>
                              </span></th>
                    <th>S.No.</th>
                    <th>Date & Time</th>
                    <th>Meeting Date & Time (Options)</th>
                    <th>Meeting Date & Time (Accepted)</th>
                    <th>Notes</th>
                    <th>Added By</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {appSchedules.map((item, key) => (
                    <tr>
                      <td>
                      <div className="custom-control custom-radio">
                          <input
                            className="custom-control-input"
                            type="radio"
                            id={item.meetingId}
                            name="schedule"
                            onChange={() => handleSelectSchedule(item)}
                            value={selectedScheduleId}
                            checked={selectedScheduleId === item.meetingId}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={item.meetingId}
                          >
                          </label>
                        </div>
                      </td>
                      <td>{key + 1}</td>
                      <td>
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.createdDateTime}
                        </Moment>
                      </td>

                      <td>
                        <ul className="list-group p-0">
                          {item.meetingOptions.map((o) => (
                            <li className="list-group-item p-2">
                              <Moment format="MMM Do YYYY">{o.meetingDate}</Moment>
                              &nbsp; {o.startTime}-{o.endTime}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>{getAcceptedMeetingDate(item.meetingOptions)}

                      {item.meetingProvider?.split(",")
                          .map((m, key) => {
                            return (
                              <>
                                {item.meetingLinks &&
                                  <a
                                    target="_blank"
                                    href={
                                      item.meetingLinks.split(",")[key]
                                    }
                                  >
                                    Join {m === "meet" && "Google Meet "}
                                    {m === "team" && "Microsoft Team "}
                                    {m === "zoom" && "Zoom "}
                                    Meeting
                                  </a>}
                                <br />
                              </>
                            );
                          })}
                      </td>
                      <td>{item.remarks}</td>

                      <td>
                        {item.performer}
                        <br />
                        {item.email}
                        <br />
                        {item.role}
                        {item.department}
                        {item.partner}
                      </td>
                      <td>
                        {getMeetingStatus(item.meetingOptions) ? (
                          <i class="fa fa-check text-green" aria-hidden="true"></i>
                        ) : (
                          <i class="fa fa-times text-red" aria-hidden="true"></i>
                        )}
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-link p-0"
                          disabled={workflowTask.wiid !== item.wiid}
                          onClick={() => handleDeleteAppointment(item)}
                        >
                          <i className="fa fa-trash mr-1"></i>Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Tab.Content>
        </Tab>
        <Tab eventKey="profile" title={`${selectedScheduleId > 0 ? "Edit" : "Add"} Appointment`} tabClassName="page-tab border border-left-0 border-right-0 border-top-0">
          <Tab.Content className="border border-top-0 p-3">
            <ScheduleMeetingPopup
            partitem={partitem}
              handleSubmit={handleSubmit}
              selectedItem={selectedSchedule}
              handleEditAppointment={handleEditAppointment}
            ></ScheduleMeetingPopup>
          </Tab.Content>
        </Tab>
      </Tabs>


    </React.Fragment>
  );
}

export default ScheduleMeeting;
