import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getPageRoute, getPageTitle } from "../../store/layoutUI";

const Breadcrumb=()=>{

  const usrType = localStorage.getItem("userType");

  const [route, setRoute] = useState([]);
  const [pageTitle, setPageTitle] = useState("");

  const pageTitleSelector = useSelector(getPageTitle);
  const pageRouteSelector = useSelector(getPageRoute);

  useEffect(() => {
      setPageTitle(pageTitleSelector);
  }, [pageTitleSelector]);

  useEffect(() => {
      setRoute(pageRouteSelector);
  }, [pageRouteSelector]);

    return <div className="col-md-12">
      <div className="row">
        <div className="col-md-10">
        <nav
      aria-label="breadcrumb"
      className="bg-white p-2"
    >
      <ol className="breadcrumb breadcrumb-links">
        <li className="breadcrumb-item">
          <a href="/">
            <i className="fa fa-home" />
          </a>
        </li>
        {pageTitle !== "Dashboard" &&
          <li className="breadcrumb-item">
            <a href="/">Dashboard</a>
          </li> }

        {pageTitle !== "Dashboard" && pageTitle !== "Quick Links" &&
          <li className="breadcrumb-item">
            <a href="/QuickLinks">Quick Links</a>
          </li> }

        {route?.map((obj) => (
          <li key={obj.name} className="breadcrumb-item">
            {obj.url === "" ? (
              <span className="text-blue">{obj.name}</span>
            ) : (
              <Link
                to={{ pathname: obj.url, state: obj.state }}
              >
                {obj.name}
              </Link>
            )}
          </li>
        ))}
        {pageTitle && (
          <li className="breadcrumb-item font-weight-bold" aria-current="page">
            {pageTitle}
          </li>
        )}
      </ol>
    </nav>
        </div>
        <div className="col-md-2 text-right">
        {pageTitle === "Dashboard" &&
        <a href="/QuickLinks" className="btn btn-outline-default btn-sm mt-1">
        <i
                  className="fa fa-angle-double-right mr-1"
                  aria-hidden="true"
                ></i>
        Continue
      </a>}
        </div>
      </div>
    

   
      
  </div>
}

export default Breadcrumb;