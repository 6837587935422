import React, { Component, useEffect, useState } from "react";
import Form from "../../../comman/form";
import http from "../../../services/HttpService";
import config from "../../../config.json";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import AddSubcontractor from "../AddSubcontractor";
import { useDispatch, useSelector } from "react-redux";
import { getSubcontractorPartners, loadSubcontractorPartners } from "../../../app/store/partner";
import { getApplicationData } from "../../../app/store/application";
import { updateNewCandidate } from "../../../app/store/candidate";
import { getRateUpdatedStatus, ratesReceived, updateRate } from "../../../app/store/rates";
import { getAlert } from "../../../services/appAlerts";

const Subcontractor=(props)=> {

  const {candidateObj,actType,handleNextTab,updateSelectedObject}=props;
  
  const [subcontractors,setSubcontractors]=useState([]);
  const [selectedSubcontractorId,setSelectedSubcontractorId]=useState(0);
  const [subcontractorRate,setSubcontractorRate]=useState(0);
  const [partnerType,setPartnerType]=useState("Sub-Contractors");
  const [aworkfinityApp,setAworkfinityApp]=useState(null);

  const subcontractorPartnersSelector=useSelector(getSubcontractorPartners);
  const rateUpdatedStatusSelector=useSelector(getRateUpdatedStatus)

  const dispatch=useDispatch();

  useEffect(()=>{
    dispatch(loadSubcontractorPartners(partnerType))
  },[]);

  useEffect(()=>{
    setSubcontractors(subcontractorPartnersSelector);
  },[subcontractorPartnersSelector]);

  useEffect(()=>{

    if(!candidateObj){
      setSelectedSubcontractorId(0);
      setSubcontractorRate(0);
    }

    if(candidateObj?.subcontractor)
      setSelectedSubcontractorId(candidateObj.validId);

    if(candidateObj?.rates)
      setSubcontractorRate(candidateObj.rates.subcontractorRate ?? 0);

    if (candidateObj?.performerData) {
      const obj = getApplicationData(candidateObj.performerData);
      if (obj?.aWorkfinityApp)
        setAworkfinityApp(obj.aWorkfinityApp);
    }

  },[candidateObj]);


 const handleSubmit = (btnType) => {
    const NewAWorkfinityApp={...aworkfinityApp};
    const appName = Object.keys(NewAWorkfinityApp)[1];
    const aWorkfinityAppEntity = NewAWorkfinityApp[appName];
    if(selectedSubcontractorId > 0){
      const subs=subcontractors.filter(x=>x.fuid === parseInt(selectedSubcontractorId));
      if(subs.length > 0){
        const subDetails = JSON.parse(subs[0].createdBy);
        const appEntity=subDetails[appName];
        aWorkfinityAppEntity["subcontractorenrollmentform"]=appEntity["subcontractorenrollmentform"];
        NewAWorkfinityApp[appName] = aWorkfinityAppEntity;

        const formData = new FormData();
        formData.append("appEntity", JSON.stringify(NewAWorkfinityApp));
        formData.append("subcontractor", selectedSubcontractorId);
        formData.append("recruiter", "");
        formData.append("perfId", candidateObj.performerid);
        dispatch(updateNewCandidate(formData));
        handleSaveRates();

        // if (actType === "edit"){
        //   getAlert("", "Updated Successfully", "success");
        // }
        // else{
        //   getAlert("", "Saved Successfully", "success");
        // }
        //updateSelectedObject(candidateObj.performerid);
        dispatch(ratesReceived([]));
        handleNextTab(btnType);

      }
    }
    
  };

 const handleSaveRates = () => {
    const frmData = new FormData();
    frmData.append("id", candidateObj.performerid);
    frmData.append("type", "subcontractor");
    frmData.append("subRate", subcontractorRate);
    dispatch(updateRate(frmData));
  };

  // useEffect(()=>{
  //   if(rateUpdatedStatusSelector){
  //     if (actType === "edit"){
  //       getAlert("", "Updated Successfully", "success");
  //     }
  //     else{
  //       getAlert("", "Saved Successfully", "success");
  //     }
  //     updateSelectedObject(candidateObj.performerid);
  //     dispatch(ratesReceived([]));
  //     handleNextTab(saveBtnType);
  //   }
  // },[rateUpdatedStatusSelector]);

    const btnDisabled=selectedSubcontractorId > 0 && subcontractorRate > 0;

    return (
      <div className="col-md-12">
        <div className="row">
        <div className="col-md-12 form-group">
          <p className="text-red">
            <i className="fa fa-hand-o-right mr-2" aria-hidden="true"></i>
            If existing subcontractor, Select subcontractor and enter the rate
            to be paid
          </p>
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="subcontractor" className="form-control-label">
          Select Subcontractor
          </label>
          <select
            name="subcontractor"
            id="subcontractor"
            className="form-control"
            onChange={({currentTarget})=>setSelectedSubcontractorId(parseInt(currentTarget.value))}
            value={selectedSubcontractorId}
          >
            <option value="">Select</option>
            {subcontractors.map((item) => (
              <option value={item.fuid}>{item.label}</option>
            ))}
          </select>
        </div>

        <div className="col-md-6 form-group">
          <label htmlFor="subcontractor" className="form-control-label">
            Rate/Hour-Paid to Subcontractor $
          </label>
          <input 
            className="form-control" 
            type="number" 
            name="rate" 
            id="rate"
            value={subcontractorRate}
            onChange={({currentTarget})=>setSubcontractorRate(currentTarget.value)}
           />
        </div>
        <div className="col-md-12 text-center form-group">
          <button
            className="btn btn-sm btn-outline-default"
            disabled={!btnDisabled}
            onClick={()=>handleSubmit("save")}
          >
            <i className="fa fa-check" aria-hidden="true"></i> Save
          </button>
          <button
            className="btn btn-sm btn-outline-default"
            disabled={!btnDisabled}
            onClick={()=>handleSubmit("saveAndContinue")}
          >
            <i className="fa fa-check" aria-hidden="true"></i> Save and Continue
          </button>
        </div>
        </div>
      </div>
    );
}

export default Subcontractor;
