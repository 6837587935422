import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import http from "../services/HttpService";
import config from "../config.json";
class AddEditPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionData: [],
      mainMenu: [],
      systemAdminstration: [
        "Authorized Users",
        "Main Users",
        "Manage Entites",
        "Subcontractors",
        "Recruiters",
        "Candidates",
        "Access To External System",
      ],
      menuObjects: [
        {
          id: 1,
          name: "SYSTEM ADMINISTRATION",
          menus: [
            {
              id: 1,
              name: "Authorized Users",
              menus: [{ id: 1, name: "Main Users", isChecked: false }],
              isChecked: false,
            },
            {
              id: 2,
              name: "Manage Entites",
              menus: [
                { id: 1, name: "Subcontractors", isChecked: false },
                { id: 2, name: "Recruiters", isChecked: false },
                { id: 3, name: "Candidates", isChecked: false },
              ],
              isChecked: false,
            },
          ],
          isChecked: false,
        },
        {
          id: 2,
          name: "ONBOARDING AND CONTRACTS",
          menus: [
            { id: 1, name: "Subcontractors" },
            { id: 2, name: "Recruiters" },
            { id: 3, name: "Candidates" },
          ],
        },
        {
          id: 3,
          name: "INVOICES",
          menus: [
            { id: 1, name: "Time Log Management" },
            { id: 2, name: "Invoices and Payments" },
          ],
        },
        {
          id: 4,
          name: "ANALYTICS",
          menus: [
            { id: 1, name: "Dashboard" },
            { id: 2, name: "Entity Data" },
            { id: 3, name: "Business Reports" },
            // { id: 4, name: "Query Based Reports" },
            { id: 5, name: "Compliance Reports / Docs" },
          ],
        },
        {
          id: 5,
          name: "SECURITY",
          menus: [
            { id: 1, name: "Monitoring Logs" },
            // { id: 2, name: "Single Sign On" },
          ],
        },
        {
          id: 6,
          name: "COMMUNICATION",
          menus: [
            {
              id: 1,
              name: "Emails",
              menus: [
                { id: 1, name: "Subcontractors", isChecked: false },
                { id: 2, name: "Recruiters", isChecked: false },
                { id: 3, name: "Candidates", isChecked: false },
              ],
              isChecked: false,
            },
            {
              id: 2,
              name: "Text Messages",
              menus: [
                { id: 1, name: "Subcontractors", isChecked: false },
                { id: 2, name: "Recruiters", isChecked: false },
                { id: 3, name: "Candidates", isChecked: false },
              ],
              isChecked: false,
            },
            {
              id: 3,
              name: "Surveys",
              menus: [
                { id: 1, name: "Surveys", isChecked: false },
              ],
              isChecked: false,
            },
          ],
          isChecked: false,
        },
      ],
      refMenuObjects: [
        {
          id: 1,
          name: "SYSTEM ADMINISTRATION",
          menus: [
            {
              id: 1,
              name: "Authorized Users",
              menus: [{ id: 1, name: "Main Users", isChecked: false }],
              isChecked: false,
            },
            {
              id: 2,
              name: "Manage Entites",
              menus: [
                { id: 1, name: "Subcontractors", isChecked: false },
                { id: 2, name: "Recruiters", isChecked: false },
                { id: 3, name: "Candidates", isChecked: false },
              ],
              isChecked: false,
            },
          ],
          isChecked: false,
        },
        {
          id: 2,
          name: "ONBOARDING AND CONTRACTS",
          menus: [
            { id: 1, name: "Subcontractors" },
            { id: 2, name: "Recruiters" },
            { id: 3, name: "Candidates" },
          ],
        },
        {
          id: 3,
          name: "INVOICES",
          menus: [
            { id: 1, name: "Time Log Management" },
            { id: 2, name: "Invoices and Payments" },
          ],
        },
        {
          id: 4,
          name: "ANALYTICS",
          menus: [
            { id: 1, name: "Dashboard" },
            { id: 2, name: "Entity Data" },
            { id: 3, name: "Business Reports" },
            // { id: 4, name: "Query Based Reports" },
            { id: 5, name: "Compliance Reports / Docs" },
          ],
        },
        {
          id: 5,
          name: "SECURITY",
          menus: [
            { id: 1, name: "Monitoring Logs" },
            // { id: 2, name: "Single Sign On" },
          ],
        },
        {
          id: 6,
          name: "COMMUNICATION",
          menus: [
            {
              id: 1,
              name: "Emails",
              menus: [
                { id: 1, name: "Subcontractors", isChecked: false },
                { id: 2, name: "Recruiters", isChecked: false },
                { id: 3, name: "Candidates", isChecked: false },
              ],
              isChecked: false,
            },
            {
              id: 2,
              name: "Text Messages",
              menus: [
                { id: 1, name: "Subcontractors", isChecked: false },
                { id: 2, name: "Recruiters", isChecked: false },
                { id: 3, name: "Candidates", isChecked: false },
              ],
              isChecked: false,
            },
            {
              id: 3,
              name: "Surveys",
              menus: [
                { id: 1, name: "Surveys", isChecked: false },
              ],
              isChecked: false,
            },
          ],
          isChecked: false,
        },
      ],
    };
  }

  renderCheckBox = (label, name, handleOnChange, isChecked) => {
    return (
      <div className="">
        <div className="custom-control custom-checkbox">
          <input
            className="custom-control-input"
            id={name}
            type="checkbox"
            name={name}
            value="Yes"
            onChange={handleOnChange}
            checked={isChecked}
          />

          <label
            className="custom-control-label form-control-label"
            htmlFor={name}
          >
            {label}
          </label>
        </div>
      </div>
    );
  };

  handleSystemAdminChange = ({ currentTarget }) => {
    const { menuObjects } = this.state;
    const mid = currentTarget.id.split("_");
    if (mid[0] === "m") {
      menuObjects
        .filter((x) => x.id === parseInt(mid[1]))
        .map((menu) => {
          menu.isChecked = currentTarget.checked;
          menu.menus.map((smenu) => {
            smenu.isChecked = currentTarget.checked;
            if (smenu.menus) {
              smenu.menus.map((ssmenu) => {
                ssmenu.isChecked = currentTarget.checked;
                return ssmenu;
              });
            }
            return smenu;
          });
          return menu;
        });
      this.setState({ menuObjects });
    }
    if (mid[0] === "sm") {
      menuObjects
        .filter((x) => x.id === parseInt(mid[2]))
        .map((menu) => {
          menu.menus
            .filter((x) => x.id === parseInt(mid[1]))
            .map((smenu) => {
              smenu.isChecked = currentTarget.checked;
              if (smenu.menus) {
                smenu.menus.map((ssmenu) => {
                  ssmenu.isChecked = currentTarget.checked;
                  return ssmenu;
                });
              }
              return smenu;
            });
        });
      this.setState({ menuObjects });
    }
    if (mid[0] === "ssm") {
      menuObjects
        .filter((x) => x.id === parseInt(mid[3]))
        .map((menu) => {
          menu.menus
            .filter((x) => x.id === parseInt(mid[2]))
            .map((smenu) => {
              console.log(smenu);
              smenu.menus
                .filter((x) => x.id === parseInt(mid[1]))
                .map((ssmenu) => {
                  ssmenu.isChecked = currentTarget.checked;
                  return ssmenu;
                });
              return smenu;
            });
          return menu;
        });
      this.setState({ menuObjects });
    }
  };

  handleSubmit = async () => {
    const { menuObjects } = this.state;
    const { roleid } = this.props;
    const permissionObj = {
      roleId: roleid,
      permissionRights: JSON.stringify(menuObjects),
    };
    const { data } = await http.post(
      config.AddEditRolePermission,
      permissionObj
    );
    this.props.handlePermissionShowHide();
  };

  componentDidMount = async () => {
    const { roleid } = this.props;
    console.log(roleid);
    const { refMenuObjects } = this.state;
    if (roleid > 0) {
      const { data } = await http.get(
        config.GetRolePermission + roleid
      );
      if (data !== "") {
        this.setState({ menuObjects: data });
      } else {
        this.setState({ menuObjects: refMenuObjects });
      }
    } else {
      this.setState({ menuObjects: refMenuObjects });
    }
  }

  componentWillReceiveProps = async (nextProps) => {

  };

  render() {
    const { menuObjects } = this.state;
    return (
      <React.Fragment>
        <div className="card-columns">
          {menuObjects.map((menu) => (
            <div className="card shadow-none border">
              <div className="card-header p-2 bg-secondary">
                <h3 className="mb-0">
                  {this.renderCheckBox(
                    menu.name,
                    `m_${menu.id}`,
                    this.handleSystemAdminChange,
                    menu.isChecked
                  )}
                </h3>
              </div>
              <div className="card-body p-3">
                {menu.menus?.map((smenu) => (
                  <>
                    <h4>
                      {this.renderCheckBox(
                        smenu.name,
                        `sm_${smenu.id}_${menu.id}`,
                        this.handleSystemAdminChange,
                        smenu.isChecked
                      )}
                    </h4>
                    {smenu.menus?.map((ssmenu) => (
                      <ul className="list-group">
                        <li className="list-group-item p-0 pl-3 border-0">
                          <h5>
                            {this.renderCheckBox(
                              ssmenu.name,
                              `ssm_${ssmenu.id}_${smenu.id}_${menu.id}`,
                              this.handleSystemAdminChange,
                              ssmenu.isChecked
                            )}
                          </h5>
                        </li>
                      </ul>
                    ))}
                  </>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="col-md-12 text-center">
          <button className="btn btn-sm btn-outline-default" onClick={this.handleSubmit}>
            <i class="fa fa-check" aria-hidden="true"></i> Save
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default AddEditPermission;
