import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPageRoute, getPageTitle } from "../../store/layoutUI";
import LeftMenu from "../../../components/Menu";
import Menu from "../../components/Header/Menu";
import TopMenu from "../../components/Header/TopMenu";
import Breadcrumb from "./Bredcrumb";

const Layout = () => {
    const [scriptLoadingState, setScriptLoadingState] = useState('IDLE');
    useEffect(() => {
        var script = document.createElement('script');
        script.async = true;
        script.type = 'text/javascript';
        script.src = '/assets/js/argon.js';
        script.onload = function () {
            setScriptLoadingState('LOADED');
        };
        script.onerror = function () {
            setScriptLoadingState('FAILED');
        };
        document.body.appendChild(script);
    }, []);

    return <>
        <div className="col-md-12">
            <TopMenu />
        </div>
        <LeftMenu />

        <div className="main-content h-100" id="panel">

            <div
                style={{
                    height: "79vh", 
                    overflowY: "auto", 
                    paddingRight: "15px", 
                }}
            >
                <div className="col-md-12">
                    <div className="d-flex justify-content-center"
                        style={{
                            //backgroundColor: "#fdc300",
                            borderBottomLeftRadius: "0px",
                            borderBottomRightRadius: "50px",
                        }}
                    >
                            <Menu />
                    </div>

                </div>
                <Breadcrumb />
                <Outlet />
                <br />
                <br />
                <br />
            </div>
        </div>
    </>

}


export default Layout;
