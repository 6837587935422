import React, { Component, useEffect, useState } from "react";
import Form from "../../comman/form";
import { Modal, Button } from "react-bootstrap";
import config from "../../config.json";
import http from "../../services/HttpService";
import auth from "../../services/authService";
import Swal from "sweetalert2";
import UploadDocument from "../../comman/UploadDocument";
import UploadRepository from "../../app/components/Document/UploadRepository";
import { getCurrentUser } from "../../app/store/auth";
import { getAlert } from "../../services/appAlerts";
import { useDispatch, useSelector } from "react-redux";
import { ddrmsRepoDocumentUploaded, getDdrmsRepoUploadStatus, UploadDdrmsRepositoryDocs } from "../../app/store/ddrmsDocument";
import { getLocalDocUploadStatus, getUploadDocumentId, localDocumentUploaded, uploadDocument, uploadLocalDocument } from "../../app/store/documents";
import UploadLocal from "../../app/components/Document/UploadLocal";

const CandidateReqDoc = (props) => {
  const { docShowHide, handleDocShowHide, 
    sendType, workItem,uploadType } = props;

  const [submitProcess, setSubmitProcess] = useState(false);
  const [useType, setUseType] = useState("sharing");
  const [isPwd, setIsPwd] = useState("");
  const [message, setMessage] = useState("");
  const [selectedRepoDocs,setSelectedRepoDocs]=useState([]);
  const [localDocs,setLocalDocs]=useState([]);

  const ddrmsRepoUploadStatus=useSelector(getDdrmsRepoUploadStatus);
  const localDocUploadStatusSelector=useSelector(getLocalDocUploadStatus);
  
  const currentUser = getCurrentUser();

  const dispatch = useDispatch();

  useEffect(()=>{
    setMessage("");
    setLocalDocs([]);
    setSelectedRepoDocs([]);
    setIsPwd("");
    setUseType("");
  },[docShowHide])


  const doSubmit = () => {
    setSubmitProcess(true);
    const wfTask = { ...workItem };
    let docType = "";
    if (uploadType) docType = uploadType;
    if (sendType === "repository") saveRepoDocs(docType, wfTask);
    if (sendType === "upload") saveLocalDocs(docType, wfTask);
  };

  const saveRepoDocs = (docType, wfTask) => {
    wfTask.performerId = currentUser.id;
    const frmData = new FormData();
    frmData.append("message", message);
    frmData.append("workflowTask", JSON.stringify(wfTask));
    frmData.append("updType", useType);
    frmData.append("isPwd", isPwd);
    const updDocs = selectedRepoDocs.map((doc) => {
      const newDoc = { ...doc };
      newDoc.docType = docType;
      return newDoc;
    });
    frmData.append("doc", JSON.stringify(updDocs));
    dispatch(UploadDdrmsRepositoryDocs(frmData));
  }

  useEffect(()=>{
    if(ddrmsRepoUploadStatus){
      setSubmitProcess(false);
      handleDocShowHide("save");
      getAlert("", "Saved Successfully", "success");
      ddrmsRepoDocumentUploaded(false);
    }
  },[ddrmsRepoUploadStatus]);

  useEffect(() => {
    if (localDocUploadStatusSelector > 0) {
      setSubmitProcess(false);
      handleDocShowHide("save");
      getAlert("", "Saved Successfully", "success");
      localDocumentUploaded(null);
    }
  }, [localDocUploadStatusSelector]);

  const saveLocalDocs = (docType, wfTask) => {
    wfTask.performerId = wfTask.applicantId;
    const frmData = new FormData();
    frmData.append("message", message);
    frmData.append("workflowTask", JSON.stringify(wfTask));
    frmData.append("userId", currentUser.id);
    localDocs.map((file, key) =>
      frmData.append("files[" + key + "]", file.file)
    );
    frmData.append("docType", docType);
    frmData.append("updType", useType);
    frmData.append("isPwd", isPwd);
    dispatch(uploadLocalDocument(frmData));
  }

  const handlePwdChecked = ({ currentTarget }) => {
    if (currentTarget.checked) {
      const pfId = workItem.applicantId;
      const pfName = workItem.performerName.substring(0, 3).toLowerCase();
      setIsPwd(pfName + pfId)
    } else {
      setIsPwd("");
    }
  };

  const handleUseTypeSelect = ({ currentTarget }) => {
    if (currentTarget.checked) setUseType("internal");
    else setUseType("sharing");
  }

  return (
    <Modal show={docShowHide} size="xl">
      <Modal.Header closeButton onHide={() => handleDocShowHide("")}>
        <Modal.Title>
        {sendType === "repository"
              ? "Upload from DDRMS Repository"
              : "Upload from Local Drive"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        <div className="row">
          {sendType === "repository" && <UploadRepository workItem={workItem} setSelectedRepoDocs={setSelectedRepoDocs} />}

          {sendType === "upload" && <div className="col-md-12"><UploadLocal setLocalDocs={setLocalDocs} /></div> }

          <div className="col-md-12 form-inline form-group">

            <div className="custom-control custom-checkbox">
              <input
                name="useType"
                className="custom-control-input"
                id="internal"
                value="internal"
                type="checkbox"
                onChange={handleUseTypeSelect}
                checked={useType === "internal"}
              />
              <label className="custom-control-label" htmlFor="internal">
                For Internal Purposes Only
              </label>
            </div>
          </div>
          <div className="col-md-12">
            <div className="custom-control custom-checkbox mb-3">
              <input
                className="custom-control-input"
                id="isPwd"
                name="isPwd"
                type="checkbox"
                onChange={handlePwdChecked}
              />
              <label className="custom-control-label" htmlFor="isPwd">
                Check Box if you want the document to sent as password
                protected
              </label>
              <p className="text-danger">
                (Example : First 3 letters of applicant's name and starting 3
                charactors of the applicant's ID in lower case) (abc123).
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <label htmlFor="message" className="form-control-label">
              Notes
              <span className="font-weight-normal ml-2">
                (Add any additional message you may want to send)
              </span>
            </label>

            <textarea
              className="form-control"
              id="message"
              name="massage"
              placeholder="Notes"
              onChange={({ currentTarget }) => setMessage(currentTarget.value)}
              value={message}
            ></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-danger" onClick={() => handleDocShowHide("")}>
          <i class="fa fa-times" aria-hidden="true"></i> Close
        </button>
        <button
          className="btn btn-sm btn-outline-default"
          disabled={submitProcess}
          onClick={doSubmit}
        >
          {submitProcess ?
            <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
            : <i class="fa fa-check" aria-hidden="true"></i>} Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default CandidateReqDoc;
