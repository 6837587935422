import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "../../comman/form";
import Joi from "joi-browser";

class InternalReviewPopup extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
    };
  }

  schema = {
    comments: Joi.string().required().label("Comment"),
  };

  doSubmit = () => {
    const { data } = this.state;
    if (data["commentId"] !== null && data["commentId"] !== undefined) {
      this.props.handleEditComment(data);
    } else {
      this.props.handleSubmit(data);
    }
  };

  componentWillReceiveProps(nextProps) {
    const { selectedItem } = nextProps;
    if (selectedItem && selectedItem !== "") {
      //console.log(selectedItem);
      this.setState({ data: selectedItem });
    } else {
      this.setState({ data: {}, errors: {} });
    }
  }

  render() {
    const { data, errors } = this.state;
    const { showHide, handleModalShowHide } = this.props;
    return (
      <Modal show={showHide} size="xl">
        <Modal.Header closeButton onHide={handleModalShowHide}>
          <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              {this.renderTextArea("comments", "Notes")}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleModalShowHide()}>
            <i class="fa fa-times" aria-hidden="true"></i> Close
          </button>
          <button
            className="btn btn-sm btn-outline-default"
            disabled={this.validate()}
            onClick={this.handleSubmit}
          >
            <i class="fa fa-check" aria-hidden="true"></i> Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default InternalReviewPopup;
