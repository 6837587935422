import React, { Component } from "react";
import auth from "../../services/authService";
import http from "../../services/HttpService";
import config from "../../config.json";
import Swal from "sweetalert2";
class Hexagon extends Component {
  constructor(props) {
    super(props);
    this.state = { currentUser: "", user: "", rolePermission: [] };
  }

  async componentDidMount() {
    const user = auth.getCurrentUser();
    let currentUser = "";
    if (user.type === "admin")
      currentUser = await auth.getAdminDetails(user.id);
    else currentUser = await auth.getCurrentUserDetails(user.id);
    this.setState({ user, currentUser });
    //console.log(currentUser);
    if (currentUser?.registrationType === "WP") {
      const { data: permission } = await http.get(
        config.GetRolePermission + currentUser.roleId
      );
      //console.log(permission);
      if (permission !== "") {
        this.setState({ rolePermission: permission });
      }
    }
  }

  checkMenuPermission = (mid) => {
    const { rolePermission } = this.state;
    const user = auth.getCurrentUser();
    if (user.type === "admin") return true;
    const mm = mid.split("_");
    let chk = false;
    if (mm[0] === "m") {
      const mR = rolePermission.filter(
        (x) => x.id === parseInt(mm[1]) && x.isChecked === true
      );
      if (mR.length > 0) {
        chk = true;
      } else {
        rolePermission
          .filter((x) => x.id === parseInt(mm[1]))
          .map((menu) => {
            const sMenu = menu.menus.filter((x) => x.isChecked === true);
            if (sMenu.length > 0) {
              chk = true;
            } else {
              menu.menus.map((ssmenu) => {
                if (ssmenu?.menus) {
                  const ssMenu = ssmenu.menus.filter(
                    (x) => x.isChecked === true
                  );
                  if (ssMenu.length > 0) {
                    chk = true;
                  }
                }
              });
            }
          });
      }
    }
    if (mm[0] === "sm") {
      rolePermission
        .filter((x) => x.id === parseInt(mm[2]))
        .map((menu) => {
          const sMenu = menu.menus.filter(
            (x) => x.id === parseInt(mm[1]) && x.isChecked === true
          );
          if (sMenu.length > 0) {
            chk = true;
          } else {
            menu.menus.map((smenu) => {
              if (smenu?.menus) {
                const ssMenu = smenu.menus.filter((x) => x.isChecked === true);
                if (ssMenu.length > 0) {
                  chk = true;
                }
              } else {
                if (smenu?.isChecked) {
                  chk = true;
                }
              }
            });
          }
        });
    }
    if (mm[0] === "ssm") {
      rolePermission
        .filter((x) => x.id === parseInt(mm[3]))
        .map((menu) =>
          menu.menus
            .filter((x) => x.id === parseInt(mm[2]))
            .map((ssmenu) => {
              if (ssmenu?.menus) {
                const ssMenu = ssmenu.menus.filter(
                  (x) => x.id === parseInt(mm[1]) && x.isChecked === true
                );
                if (ssMenu.length > 0) {
                  chk = true;
                }
              } else {
                if (ssmenu?.isChecked) chk = true;
              }
            })
        );
    }
    return chk;
  };

  permissionAlert = () => {
    Swal.fire("Your are not authorized", "", "error");
  };

  render() {
    const { user, currentUser } = this.state;
    let loggedUser = user;
    if (
      loggedUser.type !== "admin" &&
      currentUser.roleType === "Complete Application Ownership"
    ) {
      loggedUser.type = "admin";
    }

    return (
      <div id="content">
        <div id="page">
          <div id="data_wrapper">
            <div id="data">
              <div>
                <div className="item main r1 c2 even_row pre" rel="pre">
                  <a href="!#">&nbsp;</a>
                </div>
                <div className="item pre r2 c2">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_1_1_1")
                          ? "/WorkflowUser"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/1_1.png"
                        title="Authorized Users"
                      />
                    </a>
                  </div>
                </div>
                <div className="item pre r2 c3">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("sm_2_1")
                          ? "/ManagePartner"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/1_2.png"
                        title="Manage Entities"
                      ></img>
                    </a>
                  </div>
                </div>
                {/* <div className="item even_row pre r3 c1">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("sm_3_1")
                          ? "/ChatMsg"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/1_3.png"
                        title="Access to external system communication"
                      />
                    </a>
                  </div>
                </div> */}
              </div>
              <div>
                <div className="item main r1 c3 even_row create" rel="create">
                  <a href="!#">&nbsp;</a>
                </div>
                <div className="item create r2 c4">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_3_3_2")
                          ? "/ListCondidate/application"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/2_3.png"
                        title="List of Candidate"
                      />
                    </a>
                  </div>
                </div>
                <div className="item create even_row r3 c3">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_1_1_2")
                          ? "/ListSubcontractor/application"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/2_1.png"
                        title="List of Subcontractor"
                      />
                    </a>
                  </div>
                </div>
                <div className="item create even_row r3 c2">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_2_2_2")
                          ? "/Recruiter/application"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/2_2.png"
                        title="List of recruiter"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="item main r1 c4 even_row build" rel="build">
                  <a href="!#">&nbsp;</a>
                </div>
                <div className="item build r2 c5">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_1_1_3")
                          ? "/ListCondidate/timelog"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/3_1.png"
                        title="Time Log Management"
                      />
                    </a>
                  </div>
                </div>
                <div className="item build even_row r3 c4">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_2_2_3")
                          ? "/ListCondidate/invoice"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/3_2.png"
                        title="Invoices and Payments"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="item main r1 c5 even_row test" rel="test">
                  <a href="!#">&nbsp;</a>
                </div>
                <div className="item test r2 c6">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_1_1_4")
                          ? "/Dashboard"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/4_1.png"
                        title="Dashboard"
                      />
                    </a>
                  </div>
                </div>
                <div className="item test even_row r3 c5">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_2_2_4")
                          ? "/ApplicationReport"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/4_2.png"
                        title="Entity Data"
                      />
                    </a>
                  </div>
                </div>
                <div className="item test even_row r3 c6">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_3_3_4")
                          ? "/CustomReport/report"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/4_3.png"
                        title="Business Reports"
                      />
                    </a>
                  </div>
                </div>
                {/* <div className="item test r4 c4">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_4_4_4")
                          ? "/StandardReport"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/4_4.png"
                        title="Query Based Reports"
                      />
                    </a>
                  </div>
                </div> */}
                <div className="item test r4 c5">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_5_5_4")
                          ? "/CustomReport/compliance"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/4_5.png"
                        title="Compliance Reports / Docs"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="item main r1 c6 even_row deploy" rel="deploy">
                  <a href="!#">&nbsp;</a>
                </div>
                <div className="item deploy r2 c7">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_1_1_5")
                          ? "/MonitoringLogs"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/5_1.png"
                        title="Monitoring Logs"
                      />
                    </a>
                  </div>
                </div>
                {/* <div className="item deploy even_row r3 c7">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_2_2_5")
                          ? "/SsoManager"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/5_2.png"
                        title="Single Sign On"
                      />
                    </a>
                  </div>
                </div> */}
              </div>
              <div>
                <div className="item main r1 c7 even_row monitor" rel="monitor">
                  <a href="!#">&nbsp;</a>
                </div>
                {/* <div className="item monitor r2 c8">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_1_1_6")
                          ? "/EmailSmtpSetup"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/6_1.png"
                        title="Email Setup"
                      />
                    </a>
                  </div>
                </div> */}
                <div className="item monitor r2 c8">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_2_2_6")
                          ? "/ApplicantEmails"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/6_2.png"
                        title="Candidates Mail"
                      />
                    </a>
                  </div>
                </div>
                <div className="item monitor even_row r3 c7">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_3_3_6")
                          ? "/PartnerEmails"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/6_3.png"
                        title="Subcontractors Mail"
                      />
                    </a>
                  </div>
                </div>
                <div className="item monitor r4 c7">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_4_4_6")
                          ? "/AgencyEmails"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/6_4.png"
                        title="Recruiters Mail"
                      />
                    </a>
                  </div>
                </div>
                <div className="item monitor r4 c6">
                  <div className="image">
                    <a
                      href={`${
                        this.checkMenuPermission("ssm_5_5_6")
                          ? "/SurveyFeedback"
                          : "javascript:alert('Access Denied')"
                      }`}
                    >
                      <img
                        src="../../assets/HexagonDesign/cells/6_5.png"
                        title="Surveys"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hexagon;
