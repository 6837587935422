import { filter } from "draft-js/lib/DefaultDraftBlockRenderMap";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import TaskHistory from "../components/TaskHistory";
import config from "../config.json";
import http from "../services/HttpService";

class DropdownPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: "",
      filterId: 0,
      filterName: "",
      addedFunds: [],
    };
  }

  async componentDidMount() {
    // const { data: addedFunds } = await http.get(config.GetAllReceivedFund);
    // this.setState({ addedFunds });
  }

  getFundAmount = (fundId) => {
    const { addedFunds } = this.state;
    //console.log(addedFunds);
    let aAmt = 0;
    let wAmt = 0;
    addedFunds
      .filter((x) => x.federalId === fundId.toString())
      .map((item) => {
        aAmt += item.fundAmount;
        wAmt += item.withdrawAmount;
      });
    return aAmt - wAmt;
  };

  handeOnChange = ({ currentTarget }) => {
    this.setState({ selectedItem: currentTarget.value });
  };

  handleFilterId = ({ currentTarget }) => {
    this.setState({ filterId: currentTarget.value });
  };

  handleFilterName = ({ currentTarget }) => {
    this.setState({ filterName: currentTarget.value });
  };

  render() {
    const { selectedItem, filterId, filterName } = this.state;
    const {
      dropdownShowHide,
      handleDropdownShowHide,
      dropdwonTitle,
      selectedDropdwonData,
      handleDropdownSubmit,
    } = this.props;
    let optData =
      selectedDropdwonData.length > 0 ? selectedDropdwonData[0].optionData : [];
    const controlId =
      selectedDropdwonData.length > 0 ? selectedDropdwonData[0].controlId : 0;
    //console.log(controlId);
    if (parseInt(filterId) > 0)
      optData = optData.filter((x) => x.id === parseInt(filterId));

    if (filterName !== "")
      optData = optData.filter(
        (x) => x.name.toLowerCase() === filterName.toLowerCase()
      );

    let cmFund = controlId !== 0 && controlId.split(".")[1];
    //console.log(cmFund);
    if (cmFund === "Fund_Source" || cmFund === "FundSource") cmFund = true;
    else cmFund = false;
    return (
      <Modal show={dropdownShowHide} size="lg">
        <Modal.Header>
          <Modal.Title>{dropdwonTitle}</Modal.Title>
          <button className="btn btn-sm" onClick={handleDropdownShowHide}>
            <i className="fa fa-times"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 text-right form-group">
              <button
                className="btn btn-warning btn-sm"
                data-toggle="collapse"
                data-target="#filter"
              >
                Filters
              </button>
            </div>
            <div className="collapse col-md-12 form-group" id="filter">
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="cmId" className="form-control-label">
                    ID
                  </label>
                  <input
                    type="text"
                    name="cmId"
                    id="cmId"
                    className="form-control"
                    placeholder="ID"
                    onChange={this.handleFilterId}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="cmId" className="form-control-label">
                    Name
                  </label>
                  <input
                    type="text"
                    name="cmName"
                    id="cmName"
                    className="form-control"
                    placeholder="Name"
                    onChange={this.handleFilterName}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-md-12"
              style={{ maxHeight: "400px", overflow: "auto" }}
            >
              <table className="table align-items-center table-flush table-striped">
                <thead className="thead-light">
                  <tr>
                    <th>Select</th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Description</th>
                    {cmFund && <th>Available Fund</th>}
                  </tr>
                </thead>
                <tbody>
                  {optData.map((item) => {
                    let fAmt = 0;
                    if (cmFund) {
                      fAmt = this.getFundAmount(item.id);
                      return (
                        fAmt > 0 && (
                          <tr>
                            <td>
                              <div className="custom-control custom-radio">
                                <input
                                  name={controlId}
                                  className="custom-control-input"
                                  id={item.id}
                                  type="radio"
                                  onChange={this.handeOnChange}
                                  value={`${item.name} (Available Fund ${fAmt})`}
                                  groupName={controlId}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={item.id}
                                ></label>
                              </div>
                            </td>
                            <td>{item.id}</td>
                            <td style={{ whiteSpace: "pre-wrap" }}>
                              {item.name}
                            </td>
                            <td style={{ whiteSpace: "pre-wrap" }}>
                              {item.description}
                            </td>
                            <td>{fAmt}</td>
                          </tr>
                        )
                      );
                    } else {
                      return (
                        <tr>
                          <td>
                            <div className="custom-control custom-radio">
                              <input
                                name={controlId}
                                className="custom-control-input"
                                id={item.id}
                                type="radio"
                                onChange={this.handeOnChange}
                                value={item.name}
                                groupName={controlId}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={item.id}
                              ></label>
                            </div>
                          </td>
                          <td>{item.id}</td>
                          <td style={{ whiteSpace: "pre-wrap" }}>
                            {item.name}
                          </td>
                          <td style={{ whiteSpace: "pre-wrap" }}>
                            {item.description}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            disabled={selectedItem === ""}
            onClick={() => handleDropdownSubmit(controlId, selectedItem)}
          >
            Save
          </button>
          <button className="btn btn-danger" onClick={handleDropdownShowHide}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DropdownPrompt;
