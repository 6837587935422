import React, { Component } from "react";
import { Link } from "react-router-dom";
import http from "../../services/HttpService";
import config from "../../config.json";
import auth from "../../services/authService";
import Progress from "../../comman/Progress";

class ChecklistItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workItem: {},
      showHide: false,
      formProcess: [
        
        {
          id: 3,
          formProcessId: 1,
          processItem: "Getting Ready.",
        },
        {
          id: 2,
          formProcessId: 1,
          processItem: "CIP Planning Process",
        },
        {
          id: 1,
          formProcessId: 1,
          processItem: "Starting your",
        },
       
      ],
    };
  }

  handleRunApplication = async () => {
    const { Checklist } = this.props;
    const user = auth.getCurrentUser();
    const newApplication = {
      workflowId: Checklist.workflowId,
      performerId: parseInt(user.id),
      applicationId: Checklist.applicationId,
    };

    this.setState({ showHide: true });

    const { data } = await http.post(
      config.StartNewApplication,
      newApplication
    );
    //console.log(data);

    const { data: workItemTask } = await http.post(
      config.GetCurrentWorkflowTask,
      data
    );

    //console.log(workItemTask);

    if (workItemTask.formId === null) {
      setTimeout(async () => {
        const { data: workItemTask } = await http.post(
          config.GetCurrentWorkflowTask,
          data
        );
        this.handleModalShowHide();
        this.props.handleWorkflowTask(workItemTask);
      }, 3000);
    } else {
      this.handleModalShowHide();
      this.props.handleWorkflowTask(workItemTask);
    }
  };

  

  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  render() {
    const { Checklist, color, type, page,approveId } = this.props;
    //console.log(AllChecklist);
    const {showHide,formProcess}=this.state;
    //console.log(formProcess);
    return (
      <li
        className="list-group-item p-3"
        style={{
          backgroundColor: color,
          borderLeft: `${
            type !== "header" && color !== "white" && "6px solid #ffbf00"
          }`,
        }}
      >
        <span className="font-weight-bold d-flex align-items-center" style={{ color: "black" }}>
          <i className="fa fa-hand-o-right mr-2" aria-hidden="true"></i>
          {Checklist.name}
          <i
            className="fa fa-angle-double-right mr-1 ml-1"
            aria-hidden="true"
          ></i>
          {Checklist.category}
          <i
            className="fa fa-angle-double-right mr-1 ml-1"
            aria-hidden="true"
          ></i>
          {type === "ApplicationStatus" ? (
            ""
          ) : (
            <React.Fragment>
              <button
                className="btn btn-sm btn-success"
                // disabled={
                //   approveId !== "" && approveId !== undefined ? true : false
                // }
                style={{
                  color: `${type === "header" && page === "status" && "black"}`,
                }}
                onClick={type !== "header" && this.handleRunApplication}
              >
                Start
              </button>
              <i
                className="fa fa-angle-double-right mr-1 ml-1"
                aria-hidden="true"
              ></i>
            </React.Fragment>
          )}
          {Checklist.workflowTitle}{" "}
          {page === "status" && (
            <React.Fragment>
              <i
                className="fa fa-angle-double-right mr-1"
                aria-hidden="true"
              ></i>
              <span className="text-blue">Check Your Application Status</span>
            </React.Fragment>
          )}
        </span>
        
        <Progress
          showHide={showHide}
          handleModalShowHide={this.handleModalShowHide}
          formProcess={formProcess}
        ></Progress>
      </li>
    );
  }
}

export default ChecklistItem;
