import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
    const layoutStyle = {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", 
    };

    const contentWrapperStyle = {
        flexGrow: 1, 
        overflowY: "auto", 
        height: "calc(100vh - 276px)", 
    };

    return (
        <div style={layoutStyle}>
            <Header />
            <div style={contentWrapperStyle}>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
