import Swal from "sweetalert2";

export const getAlert = (title, message, type) => {
    Swal.fire({
        title: title,
        text: message,
        icon: type,
        confirmButtonText: "Close",
        customClass: {
            confirmButton: 'swal2-outline-red'
        },
        didOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            confirmButton.innerHTML = `
                <i class="fa fa-times" style="margin-right: 8px; margin-left:3px; font-size:"6px"></i> Close`;
        }
    });
}

export const getConfirmAlert = (title, message, type) => {
    return Swal.fire({
        title: title,
        text: message,
        icon: type,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
            confirmButton: 'swal2-outline-black',
            cancelButton: 'swal2-outline-red'
        },
        didOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            confirmButton.innerHTML = `
                <i class="fa fa-check" style="margin-right: 8px;"></i> Yes`;

            const cancelButton = Swal.getCancelButton();
            cancelButton.innerHTML = `
                <i class="fa fa-times" style="margin-right: 8px; margin-left:3px;"></i> No`;
        }
    }).then((result) => {
        return result;
    });
};