import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../PageHeader";
import PageSizeOptions from "../PageSizeOptions";
import AddSubcontractor from "./AddSubcontractor";
import http from "../../services/HttpService";
import config from "../../config.json";
import Moment from "react-moment";
import ActiveDeactiveHistory from "../SystemAdministration/ManagePartner/ActiveDeactiveHistory";
import { paginate, calculateIndex } from "../../utlis/paginate";
import ReminderAlertMessage from "../ReminderAlertMessage";
import Swal from "sweetalert2";
import Pagination from "../../comman/pagination";
import authService from "../../services/authService";
import TableLoader from "../../comman/TableLoader";
import {
  loadSubcontractors, getAllSubcontractors,
  getFilterAllSubcontractors,
  loadSubcontractorsByFilter, SendReminder, getReminderStatus
} from "../../app/store/subcontractor";
import { getCurrentUser } from "../../app/store/auth";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";
import { Tab, Tabs } from "react-bootstrap";
import { getFormatedNumber } from "../../services/appService";
import { getAlert } from "../../services/appAlerts";

export default function ListSubcontractor() {

  let route = [{ name: "Onboarding and Contracts", url: "" }];

  const param = useParams();
  const type = param?.type;

  if (type === "partner") {
    route = [
      { name: "System Administration", url: "" },
      { name: "Manage Entities", url: "" },
    ];
  } else {
    route = [
      { name: "Onboarding and Contracts", url: "" },
    ];
  }

  const currentUser = getCurrentUser();

  const [partnerType, setPartnerType] = useState("Sub-Contractors");
  const [actionType, setActionType] = useState("action");
  const [subcontractors, setSubcontractors] = useState([]);
  const [rowsCount, setRowsCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(5);

  const [showHide, setShowHide] = useState(false);
  const [logShowHide, setLogShowHide] = useState(false);
  const [remainderMsgShowHide, setRemainderMsgShowHide] = useState(false);

  const [selectedObj, setSelectedObj] = useState(null);
  const [subId, setSubId] = useState("");
  const [subName, setSubName] = useState("");
  const [subEmail, setSubEmail] = useState("");
  const [dataType, setDataType] = useState("");
  const [selectedSubcontractorId, setSelectedSubcontractorId] = useState(0);
  const [selectedTab, setSelectedTab] = useState("home")

  const allSubcontractors = useSelector(getAllSubcontractors);
  const filterAllSubcontractors = useSelector(getFilterAllSubcontractors);
  const remiderStatus = useSelector(getReminderStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setCurrentRoute(route));
    dispatch(setCurrentPageTitle("Subcontractors"))
  }, [])

  useEffect(() => {
    dispatch(loadSubcontractors(pageSize, currentPage, partnerType));
  }, []);

  useEffect(() => {
    if (allSubcontractors.length > 0) {
      if (allSubcontractors.length > 0)
        setRowsCount(allSubcontractors[0].totalPartners);
      setSubcontractors(allSubcontractors);
    }
  }, [allSubcontractors]);


  useEffect(() => {
    setCurrentPage(1);
    setPageSize(5);
    getPagedData(filterAllSubcontractors, 1, 5);
  }, [filterAllSubcontractors]);

  useEffect(() => {
    if (remiderStatus === "success") {
      getAlert("", "Reminder Sent Successfully", "success");
      if (dataType === "filter")
        getPagedData(filterAllSubcontractors, currentPage, pageSize);
      else
        dispatch(loadSubcontractors(pageSize, currentPage, partnerType));
    }
  }, [remiderStatus])

  const handlePageChange = (page) => {
    const { startIndex: start, endIndex: end } = calculateIndex(page, startIndex, endIndex);
    setCurrentPage(page);
    setStartIndex(start);
    setEndIndex(end);
    if (dataType === "filter")
      getPagedData(filterAllSubcontractors, page, pageSize)
    else
      dispatch(loadSubcontractors(pageSize, page, partnerType));
  };


  const handlePageSizeChanged = ({ currentTarget }) => {
    setPageSize(currentTarget.value);
    setCurrentPage(1);
    setStartIndex(0);
    setEndIndex(5);
    if (dataType === "filter")
      getPagedData(filterAllSubcontractors, currentPage, currentTarget.value);
    else
      dispatch(loadSubcontractors(currentTarget.value, currentPage, partnerType));
  };

  const handleModalShowHide = () => {
    //const obj = subcontractors.filter(x => x.fuid === selectedSubcontractorId);
    //if (obj.length > 0)
    // setSelectedObj(obj[0])
  };


  const handleLogShowHide = (item, actionType) => {
    if (item === "save") {
      setLogShowHide(false);
      setSelectedObj("");
      setActionType(actionType);
      if (dataType === "filter")
        getPagedData(filterAllSubcontractors, currentPage, pageSize);
      else
        dispatch(loadSubcontractors(pageSize, currentPage, partnerType));
    } else {
      setLogShowHide(!logShowHide);
      setSelectedObj(item);
      setActionType(actionType);
    }
  };

  const handleFilter = () => {
    setDataType("filter");
    var frmBody = new FormData();
    frmBody.append("id", subId);
    frmBody.append("name", subName);
    frmBody.append("email", subEmail);
    frmBody.append("type", partnerType);
    dispatch(loadSubcontractorsByFilter(frmBody));
  };

  const handleFilterCancel = () => {
    setDataType("");
    setSubId("");
    setSubName("");
    setSubEmail("");
    setCurrentPage(1);
    setPageSize(5);
    dispatch(loadSubcontractors(5, 1, partnerType));

  };

  const getPagedData = (list, currentPage, pageSize) => {
    const items = paginate(list, currentPage, pageSize);
    if (items.length > 0)
      setRowsCount(items[0].totalPartners);

    setSubcontractors(items);
  };

  const handleRemainder = async (id) => {
    dispatch(SendReminder(id));
  };

  const handleRemainderMessage = () => {
    setRemainderMsgShowHide(!remainderMsgShowHide);
  };

  const handlePartnerPerformer = () => {
    if (selectedSubcontractorId > 0)
      navigate("/ManagePartnerPerformer", {
        state: {
          obj: selectedObj,
          partner: "Subcontractors",
          url: "ListSubcontractor/partner",
        }
      })
  }

  const handlePartnerApplication = (item, app) => {
    navigate("/CustomerApps", {
      state: {
        item: item,
        currentUser: currentUser,
        type: type,
        selectedApp: app.applicationId,
        appType: "partner",
        module: "Subcontractor",
      }
    })
  }
  const handleSelectSubcontrator = (item) => {
    setSelectedSubcontractorId(item.fuid)
    setSelectedObj(item);
  }

  const handleResetSelection = () => {
    setSelectedSubcontractorId(0);
    setSelectedObj(null);
  }

  const handlePermissionShowHide2 = () => {
    dispatch(loadSubcontractors(pageSize, currentPage, partnerType));
    setSelectedTab("home")
  };

  const handleAddEditSubcontractor = () => {
    setSelectedTab("home");
    setTimeout(() => {
      setSelectedObj(null);
      setSelectedSubcontractorId(0);
      if (dataType === "filter")
        getPagedData(filterAllSubcontractors, currentPage, pageSize);
      else
        dispatch(loadSubcontractors(pageSize, currentPage, partnerType));
    }, 3000);
  }

  const handleTabOnSelect = (tab) => {
    setSelectedTab(tab)
  }

  return (
    <React.Fragment>

      <div className="col-md-12">
        <div className="card mb-4 shadow-none">
          <div className="card-header border-0 p-2">
            <div className="row">
              <div className="col-md-10">
                <PageSizeOptions
                  pageSize={pageSize}
                  onChange={handlePageSizeChanged}
                ></PageSizeOptions>
              </div>
              <div className="col-md-2 text-right">
                <button
                  className="btn btn-sm btn-outline-default btn-min-width"
                  data-toggle="collapse"
                  data-target="#collapseExample"
                >
                  <i className="fa fa-search" aria-hidden="true"></i> Search
                </button>
                {/* {type === "application" && (
                  <button
                    className="btn btn-primary btn-sm mr-2"
                    onClick={() => handleModalShowHide("")}
                  >
                    Add Subcontractor
                  </button>
                )} */}
              </div>
              <div className="col-md-12">
                <div className="collapse p-2 bg-secondary border mt-2" id="collapseExample">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label
                          htmlFor="subcontractorId"
                          className="form-control-label"
                        >
                          ID
                        </label>
                        <input
                          type="text"
                          name="subId"
                          id="subId"
                          className="form-control"
                          placeholder="ID"
                          value={subId}
                          onChange={({ currentTarget }) => setSubId(currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label
                          htmlFor="subcontractorName"
                          className="form-control-label"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          name="subName"
                          id="subName"
                          className="form-control"
                          placeholder="Name"
                          value={subName}
                          onChange={({ currentTarget }) => setSubName(currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label
                          htmlFor="subcontractorEmail"
                          className="form-control-label"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          name="subEmail"
                          id="subEmail"
                          className="form-control"
                          placeholder="Email"
                          value={subEmail}
                          onChange={({ currentTarget }) => setSubEmail(currentTarget.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 text-center ">
                      <button
                        className="btn-sm btn-outline-default mr-2"
                        onClick={() => handleFilter()}
                      >
                        <i className="fa fa-search mr-2" aria-hidden="true"></i>Filter
                      </button>
                      <button
                        className="btn-sm btn-outline-default mr-2"
                        onClick={handleFilterCancel}
                      >
                        <i className="fa fa-refresh mr-2" aria-hidden="true"></i>Refresh
                      </button>
                      <button
                        className="btn-sm btn-outline-danger mr-2"
                        data-toggle="collapse"
                        data-target="#collapseExample"
                        onClick={handleFilterCancel}
                      >
                        <i className="fa fa-close mr-2" aria-hidden="true"></i>Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-2">
            <Tabs
              activeKey={selectedTab}
              onSelect={handleTabOnSelect}
              id="uncontrolled-tab-example"
              className="h3 border border-right-0 border-left-0 border-top-0 font-weight-normal"
            >
              <Tab eventKey="home" title="Subcontractors"
                tabClassName="page-tab border border-left-0 border-top-0">
                <Tab.Content className="border border-top-0">
                  <div className="table-responsive">
                    {/* <button className="btn btn-sm btn-link" onClick={handleResetSelection}>Reset Selection</button> */}
                    <table className="table align-items-center table-flush table-hover table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>Select
                            <span style={{ cursor: "pointer" }} onClick={handleResetSelection}>
                              <i className="fa fa-refresh ml-2 text-primary" aria-hidden="true"></i>
                            </span>
                          </th>
                          <th>ID</th>
                          <th>Subcontractor Details</th>
                          <th>ENROLLMENT</th>
                          {type !== "partner" && <th>Key Contact</th>}
                          <th>Enrollment Status</th>
                          {type === "partner" && <th>Active Users</th>}
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subcontractors.map((item) => (
                          <tr>
                            <td>
                              <div className="custom-control custom-radio">
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  id={item.fuid}
                                  name="subcontractor"
                                  onChange={() => handleSelectSubcontrator(item)}
                                  value={selectedSubcontractorId}
                                  checked={selectedSubcontractorId === item.fuid}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={item.fuid}
                                >
                                </label>
                              </div>
                            </td>
                            <td>
                              {item.fuid}
                            </td>
                            <td>
                              <span className="text-uppercase">
                                {item.label}
                              </span>
                              <br />
                              <span>{item.email}</span>
                              <br />
                              <Moment format="MMM Do YYYY h:mm">
                                {item.createdDate}
                              </Moment>
                            </td>
                            <td>
                              <table className="table table-bordered">
                                {item.applications.map((app) => (
                                  <React.Fragment key={app.applicationId}>
                                    <tr>
                                      <td className="p-1">
                                        {type === "application" ? (
                                          <button
                                            onClick={() => handlePartnerApplication(item, app)}
                                            className="btn btn-sm btn-link p-0"
                                            title="Application"
                                          >
                                            {app.applicationId} - {app.applicationType}
                                          </button>
                                        ) : (
                                          <span className="text-dark">
                                            {app.applicationId} - {app.applicationType}
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width={"10%"}>
                                        {app.appSubmitted && (
                                          <span className="badge badge-success rounded-0 btn-min-width">
                                            Complete
                                          </span>
                                        )}
                                        {(app.appSubmitted === "" || app.appSubmitted === null) && (
                                          <span className="badge badge-success rounded-0 bg-yellow btn-min-width">
                                            Review
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </table>

                            </td>

                            {type !== "partner" && (
                              <td>
                                {item.contact1 !== null && (
                                  <React.Fragment>
                                    <span className="text-uppercase">
                                      {item.contact1.performername}{" "}
                                      {item.contact1.lastName}
                                    </span>
                                    <br />
                                    <span>
                                      {item.contact1.performeremail}
                                    </span>
                                    <br />
                                    {item.contact1?.contactNo && getFormatedNumber(item.contact1.contactNo)}
                                  </React.Fragment>
                                )}
                              </td>
                            )}

                            {/* <td>C2C</td> */}
                            <td>
                              {item.enrollmentStatus && "Complete"}
                              {!item.enrollmentStatus && "Pending"}
                              <br />
                              {item.contact1 !== null && type === "partner" && (
                                <React.Fragment>
                                  <button
                                    className="btn btn-sm btn-link p-0"
                                    onClick={() =>
                                      handleRemainder(
                                        item.contact1.performerid
                                      )
                                    }
                                  >
                                    Send Reminder
                                  </button>{" "}
                                  <button
                                    className="btn btn-sm btn-link p-0 mt-1"
                                    title="View message"
                                    onClick={handleRemainderMessage}
                                  >
                                    <span className="badge badge-warning rounded-0 text-red"
                                      style={{ minWidth: "70px", fontSize: "12px" }}>
                                      <i
                                        class="fa fa-envelope mr-1"
                                        aria-hidden="true"
                                      ></i>
                                      {!item.contact1?.msgAlertCount ? 0
                                        : item.contact1.msgAlertCount}
                                    </span>
                                  </button>
                                </React.Fragment>
                              )}
                            </td>
                            {type === "partner" && (
                              <td>
                                {item.activeUsers}
                              </td>
                            )}
                            {/* <td>
                              {item.contact1?.isActive === true && (
                                <span>
                                  <i
                                    className="fa fa-check text-success"
                                    style={{ fontSize: "15px" }}
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Activated
                                </span>
                              )}
                              {item.contact1?.isActive === false && (
                                <span>
                                  <i
                                    className="fa fa-times text-danger"
                                    style={{ fontSize: "15px" }}
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Deactivated
                                </span>
                              )}
                              {item.contact1?.isActive === null && (
                                <span>
                                  To Activate
                                </span>
                              )}
                              {item.contact1?.isActive === null && (
                                <span>
                                  To Activate
                                </span>
                              )}
                            </td> */}
                            <td>
                              {item.contact1?.isActive === true && (
                                <span>
                                  <i
                                    className="fa fa-check text-success"
                                    style={{ fontSize: "15px" }}
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Activated
                                  {item.contact1.addedDateTime && (
                                    <>
                                      <br />
                                      {new Date(item.contact1.addedDateTime).toLocaleString()}
                                    </>
                                  )}
                                </span>
                              )}
                              {item.contact1?.isActive === false && (
                                <span>
                                  <i
                                    className="fa fa-times text-danger"
                                    style={{ fontSize: "15px" }}
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Deactivated
                                  {item.contact1.addedDateTime && (
                                    <>
                                      <br />
                                      {new Date(item.contact1.addedDateTime).toLocaleString()}
                                    </>
                                  )}
                                </span>
                              )}
                              {item.contact1?.isActive === null && (
                                <span>
                                  To Activate
                                  {item.contact1.addedDateTime && (
                                    <>
                                      <br />
                                      {new Date(item.contact1.addedDateTime).toLocaleString()}
                                    </>
                                  )}
                                </span>
                              )}
                            </td>


                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    pageSize={pageSize}
                    itemsCount={rowsCount}
                    onPageChange={handlePageChange}
                    startIndex={startIndex}
                    endIndex={endIndex}
                  ></Pagination>
                </Tab.Content>
              </Tab>
              {type === "application" &&
                <Tab eventKey="addEditSubcontractor" title={`${selectedSubcontractorId === 0 ? "Add" : "Edit"} Subcontractor`}
                  tabClassName="page-tab border border-left-0 border-top-0">
                  <Tab.Content className="border p-3 border-top-0">
                    <AddSubcontractor selectedObj={selectedObj}
                      handleAddEditSubcontractor={handleAddEditSubcontractor}
                      selectedTab={selectedTab}
                    />
                  </Tab.Content>
                </Tab>}
              {type === "partner" &&
                <Tab eventKey="status" title="Activate/Deactivate" tabClassName="page-tab border border-left-0 border-top-0">
                  <Tab.Content className="border p-3 border-top-0">
                    {selectedSubcontractorId === 0 && <div className="">
                      <h3 className="text-danger mt-1"> Alert : Please select the Subcontractor first</h3>
                    </div>}

                    {selectedSubcontractorId > 0 &&
                      <ActiveDeactiveHistory selectedObj={selectedObj} type="partner" typeName="Subcontractor" actionType={actionType} handlePermissionShowHide2={handlePermissionShowHide2} />}
                  </Tab.Content>
                </Tab>}
              {type === "partner" &&
                <Tab eventKey="users" title={<span onClick={handlePartnerPerformer}>Assign/Manage Users</span>}
                  tabClassName="page-tab border-0">
                  <Tab.Content className="border p-3 border-top-0">
                    {selectedSubcontractorId === 0 &&
                      <div className="col-md-12">
                        <h3 className="text-danger mt-1"> Alert : Please select the Subcontractor first</h3>
                      </div>
                    }
                  </Tab.Content>
                </Tab>}
            </Tabs>
          </div>
        </div>
      </div>


      {/* {showHide &&
        <AddSubcontractor
          showHide={showHide}
          handleModalShowHide={handleModalShowHide}
          selectedObj={selectedObj}
        //getFieldData={getFieldData}
        ></AddSubcontractor>} */}

      {/* {logShowHide &&
        <ActiveDeactiveHistory
          logShowHide={logShowHide}
          handleLogShowHide={handleLogShowHide}
          selectedObj={selectedObj}
          type="partner"
          pageType="Subcontractor"
          actionType={actionType}
        ></ActiveDeactiveHistory>} */}
      {remainderMsgShowHide &&
        <ReminderAlertMessage
          remainderMsgShowHide={remainderMsgShowHide}
          handleRemainderMessage={handleRemainderMessage}
        ></ReminderAlertMessage>}
    </React.Fragment>
  );
}

//export default ListSubcontractor;
