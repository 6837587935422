import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import Subcontractor from "../app/components/Invoices/Subcontractor";
import Recruiter from "../app/components/Invoices/Recruiter";
import Agency from "../app/components/Invoices/Agency";
import { getFieldData } from "../services/appService";
import { getFilterCandidates, loadCandidatesByFilter } from "../app/store/candidate";

export default function TimeLogManagement(props) {
  const { item, candidateObj, pType } = props;
  //const [selectedItem, setSelectedItem] = useState("");
  //const [selectedType, setSelectedType] = useState("");
  //const [candidateObj, setCandidateObj] = useState("");

  //const candidatesSelector = useSelector(getFilterCandidates);

  const dispatch = useDispatch();
  useEffect(() => {

    //console.log(state);
    // if (state?.item) {
    //   setSelectedItem(state.item);
    //   getCandidateDetails(state.item.id)
    // }
    // if (state?.pType) {
    //   setSelectedType(state.pType);
    // }
  }, []);

  // const getCandidateDetails = async (candidateId) => {
  //   const frmBody = new FormData();
  //   frmBody.append("id", candidateId);
  //   frmBody.append("name", "");
  //   frmBody.append("email", "");
  //   frmBody.append("entity", "");
  //   frmBody.append("type", "candidate");
  //   frmBody.append("filterType", "single");
  //   dispatch(loadCandidatesByFilter(frmBody));
  // };

  // useEffect(() => {
  //   if (candidatesSelector.length > 0)
  //     setCandidateObj(candidatesSelector[0]);
  // }, [candidatesSelector]);

  let dfKey = 0;
  let entType = "";
  if (candidateObj) {
    const perfData = candidateObj?.performerData;
    entType = getFieldData(
      perfData,
      "candidateonboardingform",
      "CandidateEntity"
    );
    if (!(entType === "C2C Basis" || pType === "timelog")) dfKey = 1;
  }


  return (
    <>
      <Tabs defaultActiveKey={dfKey} id="uncontrolled-tab-example">
        {(entType === "C2C Basis" || pType !== "timelog") && (
          <Tab
            eventKey={0}
            title={`${entType === "C2C Basis" && pType === "invoice"
              ? "Subcontractor Invoices"
              : "Time Log Management"
              }`}
            className="p-2 border"
            tabClassName="h4"
          >
            <Subcontractor candidate={candidateObj} ptype={pType} entType={entType} />
          </Tab>
        )}

        {candidateObj?.recruiter !== null && pType !== "timelog" && (
          <Tab
            eventKey={1}
            title="Recruiter Invoices"
            className="p-2 border"
            tabClassName="h4"
          >
            <Recruiter candidate={candidateObj} ptype={pType} entType={entType} />
          </Tab>
        )}
        {pType !== "timelog" && (
          <Tab
            eventKey={2}
            title="Agency/Client Invoices"
            className="p-2 border"
            tabClassName="h4"
          >
            <Agency candidate={candidateObj} ptype={pType} entType={entType} />
          </Tab>
        )}
      </Tabs>
    </>
  );
}
